export default {
  head: "Performance report",
  overallProgress: "Overall progress",
  emergency: "Emergency",
  high: "High",
  medium: "Medium",
  low: "Low",
  assigned: "Assigned",
  completed: "Completed",
  inprogress: "In progress",
  resolved: "Resolved",
  reopened: "Reopened",
  overdue: "Overdue",
  all: "All",
  byTask: "By task",
  byProject: "By project",
  byGoal: "By goal",
  progress: "Progress",
  totalAssigned: "Total assigned",
  onTime: "On time",
  before: "Before",
  after: "After",
  sortByPlaceHolder: "Select sort by",
  sortByLabel: "Sort by",
  staffPlaceHolder: "Select staff",
  staffLabel: "Staff",
  performanceDetailsHeader: "Performance report detail",
  name: "Name",
  companyName: "Company name",
  role: "Role",
  isIn: " is in ",
  zone: " zone",
  viewPerformanceLabel: "View actual performance",
  pointsEarnedTitle: "Points earned",
  totalEmailLabel: "Total number of emails",
  receivedEmailLabel: "Emails received",
  sentEmailLabel: "Emails sent",
  ratioEmailLabel: "Ratio",
  taskCompletionLabel: "Task completion",
  totalAssignedLabel: "Total assigned",
  finalResultLabel: "Final result",
  definitionResultLabel: "Definition of result",
  excellentResultLabel: "80 - 100% : Excellent",
  goodResultLabel: "70 - 80% : Good",
  satisfactoryResultLabel: "60 - 70% : Satisfactory",
  avgResultLabel: "50 - 60% : Average",
  belowAvgResultLabel: "Upto 50% : Below average",
  beforeTime: "Before time",
  afterTime: "After time",
  completedonTime: "Completed (On time)",
  completedbeforeTime: "Completed (Before)",
  completedafterTime: "Completed (After)",
  inRedZone: "You are in red zone",
  totalTasks: "Total tasks"
};

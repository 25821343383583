export default {
  addCompanyHeading: "Add company",
  name: "Company name",
  URL: "Company URL",
  contact: "Office contact",
  officeTelephone: "Office telephone",
  officeTelephonePlaceHolder: "0000 0000 00",
  contactPlaceholder: "+00 0000 0000 00",
  address: "Registered address ",
  landmark: "Landmark",
  landmarkPlaceholder: "Landmark",
  country: "Country",
  state: "State",
  city: "City",
  zipcode: "Zip code",
  countryDropdownPlaceholder: "Country",
  stateDropdownPlaceholder: "State",
  cityDropdownPlaceholder: "City",
  nameError: "Company name is required",
  nameError_1: "Company name is not valid",
  nameError_2: "Company name must be at least 3 characters",
  nameError_3: "Company name must be at most 100 characters",
  urlError: "Company URL is required",
  urlError_1: "Company URL must be a valid URL",
  numberError_1: "Office contact number is not valid",
  numberError_2: "Office telephone is required",
  AlternateNumberError: "Alternate contact number is required",
  contactNumberError: "Office contact is required",
  numberError_3: "Office contact number must be at least 5 characters",
  numberError_4: "Office contact number must be at most 12 characters",
  officeTelephoneError_1: "Office telephone number is not valid",
  officeTelephoneError_2: "Office telephone number is required",
  officeTelephoneError_3:
    "Office telephone number must be at least 6 characters",
  officeTelephoneError_4:
    "Office telephone number must be at most 15 characters",

  addressError: "Registered address is required",
  landmarkError: "Landmark is required",
  landmarkError_1: "Landmark is not Valid",
  countryError: "Country is required",
  stateError: "State is required",
  cityError: "City is required",
  cityError_1: "City is not valid",
  zipcodeError: "Zip code is required",
  zipcodeError_1: "Zip code is not valid",
  zipcodeError_2: "Zip code must be at least 5 characters",
  zipcodeError_3: "Zip code must be at least 6 characters",
  logo: "Company logo",
  save: "Save",
  addMore: "Add more",
  addLogo: "Company logo",
  editCompany: "Edit office location",
  deleteCompanyAlterMsg: "Are you sure you want to delete theis company",
  viewOfficeHeader: "View office location",
  alertHeder: "Domain URL",
};

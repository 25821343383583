import { Methods, Urls } from "../../common/Utils/ApiConstants";
import { CreateApi } from "../CreateApi";

const calendarcollection = CreateApi.injectEndpoints({
  endpoints: (builder) => ({
    createEvent: builder.mutation({
      query: (body) => ({
        url: Urls.createEvent,
        method: Methods.post,
        body: body,
      }),
    }),
    getDayCalendar: builder.mutation({
      query: (body) => {
        return {
          url: Urls.dayEventList,
          method: Methods.post,
          body: body,
        };
      },
    }),
    getCalendarInviteeList: builder.mutation({
      query: (body) => {
        return {
          url: Urls.getCalendarInviteeList,
          method: Methods.post,
          body: body,
        };
      },
    }),
    getMarkedEventList: builder.mutation({
      query: (body) => {
        return {
          url: Urls.getMarkedEventList,
          method: Methods.post,
          body: body,
        };
      },
    }),
    eventDetails: builder.query({
      query: (eventId) => ({
        url: `${Urls.eventDetail}${eventId}`,
        method: Methods.get,
      }),
      providesTags: ["Event"],
    }),
    editEvent: builder.mutation({
      query: (body) => ({
        url: Urls.editEvent,
        method: Methods.post,
        body: body,
      }),
      invalidatesTags: ["Event"],
    }),
    getRemindToList: builder.mutation({
      query: (body) => ({
        url: Urls.reminderToList,
        method: Methods.post,
        body: body,
      }),
    }),
    getInviteeList: builder.mutation({
      query: (body) => ({
        url: Urls.eventInvitee,
        method: Methods.post,
        body: body,
      }),
    }),
    deleteEvent: builder.mutation({
      query: (body) => ({
        url: Urls.deleteEvent,
        method: Methods.delete,
        body: body,
      }),
    }),
    setInviteeSelect: builder.mutation({
      query: (body) => ({
        url: Urls.inviteeSelect,
        method: Methods.post,
        body: body,
      }),
    }),
  }),
});
export const {
  useCreateEventMutation,
  useGetDayCalendarMutation,
  useGetCalendarInviteeListMutation,
  useGetMarkedEventListMutation,
  useLazyEventDetailsQuery,
  useEditEventMutation,
  useGetRemindToListMutation,
  useGetInviteeListMutation,
  useDeleteEventMutation,
  useSetInviteeSelectMutation,
} = calendarcollection;

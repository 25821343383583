export default {
  createGroup: 'انشاء مجموعة',
  addMember: 'إضافة عضو',
  groupSubject: "موضوع المجموعة",
  groupSubjectPlaceholder: 'عنوان المجموعة',
  provideTitle: 'ادخل عنوان المجموعة وأيقونة',
  groupSubjectError: "موضوع المجموعة مطلوب",
  members: 'الأعضاء',
  typeSomethingPlaceholder: 'اكتب شيء...',
  message: 'رسالة',
  view: 'عرض',
  remove: 'حذف',
  removeUserAlert_1: 'حذف',
  removeUserAlert_2: 'من',
  removeUserAlert_3: 'مجموعة؟',
  groupIcon: 'أيقونة المجموعة',
  chooseFrom: 'اختر من',
  camera: 'الكاميرا',
  gallery: 'الاستوديو',
  removeIconAlert: 'حذف ايقونة المحموعة؟',
  enterNewSubject: 'ادخل عنوان مجموعة جديد',
  groupNamePlaceholder: 'اسم المجموعة',
  contacts: 'جهات الإتصال',
  addMemberToolTip: "إضافة عضو",
  removeMemberAlert: "هل أنت متأكد أنك تريد إزالة هذا العضو؟",
  searchPlaceholder: "يبحث...",
  removeMemberValidationError: "مطلوب ثلاثة أعضاء على الأقل في المجموعة"
};

import React from "react";
import Translate from "../../../translation/utils/translateFunction";

export const getAccessLogColumns = (customSrCellRender) => {
  const columns = [
    {
      field: "lineNo",
      headerName: Translate("common:srNoTitle"),
      align: "center",
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      renderCell: customSrCellRender,
    },
    {
      field: "name",
      headerName: Translate("document:userNameTableHeading"),
      flex: 0.4,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "designation",
      headerName: Translate("document:designationTableHeading"),
      flex: 0.4,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "company",
      headerName: Translate("document:companyNameTableHeading"),
      flex: 0.4,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "dueDate",
      headerName: Translate("document:dateTimeTableHeading"),
      flex: 0.4,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <td>
          <div className="text-info">{params?.row?.formattedDate}</div>
          <div className="text-pink">{params?.row?.formattedTime}</div>
        </td>
      ),
    },
  ];
  return columns;
};

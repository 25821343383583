import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { colors } from "../../../../common/Theme/Colors";
import { StyledTypography } from "../../../../common/Utils/StyledComponents";
import { showToast } from "../../../../common/Utils/ToastMessage";
import { useAccessLogsDataMutation } from "../../../../request/Document/AddDocument";
import Translate from "../../../../translation/utils/translateFunction";
import ListView from "../../../ListView";
import { getAccessLogColumns } from "../../components/column.data";
import { StyledCard } from "../../../../common/Utils/StyledComponents";
import { Grid, Stack } from "@mui/material";
import { useSelector } from "react-redux";
import PageHeader from "../../../common/pageHeader";

const AccessLogs = ({ fixNavbar }) => {
  const navigate = useNavigate();
  const { isRtl } = useSelector((state) => state.formanagement);
  const { state } = useLocation();
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [lastPageNo, setLastPageNo] = useState(0);
  const [accessLogs, setAccessLogs] = useState([]);

  const [
    getAccessLogsApi,
    {
      data: accessLogList,
      isLoading: isAccessLogLoading,
      isError: isAccessLogError,
      error: accessLogError,
    },
  ] = useAccessLogsDataMutation();

  useEffect(() => {
    if (state) {
      getAccessLogsApi({
        documentId: state,
        action: "READ",
        pageNo: currentPage,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAccessLogsApi, state, currentPage]);

  useEffect(() => {
    if (accessLogList?.data?.nodes) {
      const { pageInfo, nodes } = accessLogList.data;
      setAccessLogs(nodes);
      setLastPageNo(pageInfo.lastPageNo);
      setIsLoading(false);
    }
    if (accessLogError && isAccessLogError) {
      showToast({
        type: "error",
        message: JSON.stringify(accessLogError),
      });
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessLogList, accessLogError]);

  const customSrCellRender = (params) => {
    return (
      <StyledTypography component="span" sx={{ color: colors.primary_009 }}>
        {params?.api?.getRowIndexRelativeToVisibleRows(params?.row?._id) +
          1 +
          (currentPage - 1) * 10}
      </StyledTypography>
    );
  };
  return (
    <Grid
      item
      xs={12}
      className={`section-body ${isRtl ? "rtlMargin" : "ltrMargin"} ${
        fixNavbar ? "marginTop" : ""
      } `}>
      <StyledCard variant="outlined">
        <div className="container-fluid p-4">
          <div className="tab-content">
            <div>
              <div className="card">
                <PageHeader
                  title={
                    <h5 className="page-title">
                      {Translate("document:accessLogHeading")}
                    </h5>
                  }
                  onClick={() => {
                    navigate("/documentRepository/sharedWithMe/view", {
                      state,
                    });
                  }}
                  containerClass="card-header card-header-spacing formTitle"
                />
                <ListView
                  columns={getAccessLogColumns(customSrCellRender)}
                  rows={accessLogs}
                  autoHeight
                  sortingMode="server"
                  onPaginationChange={setCurrentPage}
                  currentPage={currentPage}
                  showShimmer={isAccessLogLoading}
                  showLoader={isLoading || isAccessLogLoading}
                  totalPageCount={lastPageNo}
                />
              </div>
            </div>
          </div>
        </div>
      </StyledCard>
    </Grid>
  );
};

export default AccessLogs;

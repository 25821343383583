import {
  jordanCountryCode,
  jordanCountryShortCode,
} from "../../../../common/Utils/ApiConstants";

export const InitialValues = {
  companyId: "",
  name: "",
  dob: "",
  designation: "",
  department: "",
  email: "",
  mobile: "",
  alternateMobile: "",
  alternateMobileCountryCode: jordanCountryCode,
  alternateMobileShortCode: jordanCountryShortCode,
  countryCode: jordanCountryCode,
  hrMobile: "",
  hrMobileCountryCode: jordanCountryCode,
  mobileShortCode: jordanCountryShortCode,
  hrMobileShortCode: jordanCountryShortCode,
  companyNumber: "",
  companyExtension: "",
  gender: "MALE",
  reportTo: "",
  resFlat: "",
  resState: "",
  resCountry: "",
  resCity: "",
  resZipCode: "",
  resAddress: "",
  resLat: "",
  resLng: "",
  workFlat: "",
  workLat: "",
  workLng: "",
  workAddress: "",
  workState: "",
  workCountry: "",
  workCity: "",
  workZipCode: "",
  Male: false,
  Female: false,
};
export const options = [
  {
    label: "www.flipkart.com",
    value: "www.flipkart.com",
  },
  {
    label: "www.google.com",
    value: "www.google.com",
  },
  {
    label: "www.amazon.in",
    value: "www.amazon.in",
  },
  {
    label: "www.Myntra.com",
    value: "www.Myntra.com",
  },
];

export const radioOptions = ["Male", "Female"];
export const centerContent = {
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  alignContent: "center",
  fontWeight: "500",
  flex: 1,
};
export const EmployeeUser = "EMPLOYEE";

import { Methods, Urls } from "../../common/Utils/ApiConstants";
import { CreateApi } from "../CreateApi";

const VendorCollection = CreateApi.injectEndpoints({
  endpoints: (builder) => ({
    addVendorData: builder.mutation({
      query: (body) => {
        return {
          url: Urls.addVendor,
          method: Methods.post,
          body: body,
        };
      },
    }),
    getVendorListing: builder.mutation({
      query: (body) => {
        return {
          url: Urls.getVendorList,
          method: Methods.post,
          body: body,
        };
      },
    }),
    viewVendorData: builder.mutation({
      query: (id) => {
        return {
          url: `${Urls.viewVendor}${id}`,
          method: Methods.get,
        };
      },
    }),
    editVendorData: builder.mutation({
      query: (body) => {
        return {
          url: `${Urls.editVendor}${body?.id}`,
          method: Methods.put,
          body: body?.body,
        };
      },
    }),
    deleteVendorData: builder.mutation({
      query: (body) => {
        return {
          url: `${Urls.deleteVendor}`,
          method: Methods.delete,
          body,
        };
      },
    }),
    getVendorsReportingList: builder.query({
      query: (data) => ({
        url: Urls.vendorsReportingList,
        method: Methods.post,
        body: data,
      }),
      transformResponse: (response) => {
        return response.data;
      },
    }),
  }),
});

export const {
  useAddVendorDataMutation,
  useGetVendorListingMutation,
  useViewVendorDataMutation,
  useEditVendorDataMutation,
  useDeleteVendorDataMutation,
  useLazyGetVendorsReportingListQuery,
} = VendorCollection;

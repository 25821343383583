export default {
  addnote: "أضف ملاحظات",
  addpoint: "أضف نقطة",
  maxfilemsg: "يمكن إضافة 10 نقاط كحد أقصى",
  detailHeader: "ميام تفاصيل",
  editMom: "يحرر ميام",
  alert: "هل أنت متأكد من حذف ميام",
  viewLinkedEvents: "الأحداث المرتبطة",
  sharedUsersTitle: "المستخدمون المشتركون",
  eventNameTitle: "اسم الحدث",
  eventDescriptionTitle: "وصف الحدث",
  creatorName: "اسم المُنشئ",
  dateTitle: "التاريخ",
  timeTitle: "الوقت",
};

export default {
  head: "تفاصيل بطاقة العمل",
  createHead: "انشاء جهة اتصال",
  editHead: "تعديل جهة اتصال",
  contactName: "اسم جهة الإتصال",
  userName: "اسم المستخدم",
  designation: "الوظيفة",
  industry: "الصناعة\/القطاع",
  workEmail: "ايميل العمل ",
  workEmailPlaceholder: "workemail@mail.com",
  contactNumber: "رقم جهة الإتصال",
  contactNumberPlaceholder: "00 0000 0000 00",
  alternateNumberPlaceholder: "00 0000 0000 00",
  address: "العنوان",
  nameError: "اسم جهة الإتصال حقل مطلوب",
  designationError: "الوظيفة حقل مطلوب",
  emailError: "ايميل العمل حقل مطلوب",
  emailValid: "ايميل العمل يجب أن يكون فعّال",
  contactNumberError_1: "يجب أن يكون رقم جهة الإتصال فعّال",
  contactNumberError_2: "رقم جهة الإتصال حقل مطلوب",
  addressError: "العنوان حقل مطلوب",
  view: "عرض بطاقة العمل",
  companyName: "اسم الشركة",
  department: "القسم",
  sector: "القطاع",
  field: "الحقل",
  fieldPlaceHolder: "اسم الحقل",
  alternateNumber: "رقم جهة اتصال بديل",
  companyNameError: "اسم الشركة حقل مطلوب",
  departmentError: "القسم حقل مطلوب",
  sectorError: "القطاع حقل مطلوب",
  fieldError: "الحقل مطلوب",
  industryError: "الصناعة حقل مطلوب",
  alternateContactNumberError_1: "يجب أن يكون بديل جهة الإتصال فعّال",
  alternateContactNumberError_2: "الرقم البديل لجهة الإتصال حقل مطلوب",
  businessCard: "بطاقة العمل",
  attachmentTitle: "مرفق",
  attachmentErrMsg: "لم يتم العثور على أي مرفقات",
};

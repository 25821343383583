import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { formatDateYYYYMMDD } from "../../../../common/Utils/DateFormater";
import { showToast } from "../../../../common/Utils/ToastMessage";
import { useAddStaffEmployeeDataMutation } from "../../../../request/Staff/Employee";
import { useLazyGetCompanyListQuery } from "../../../../request/company";
import EmployeeForm from "./EmployeeForm";
import { InitialValues } from "./constants";
import { AddEmployeeSchema } from "./utils";
import { getAddressError } from "../../../../common/Utils/commonFunction";
import AlertPermission from "../../../AlertPermission";
import Translate from "../../../../translation/utils/translateFunction";
import { StyledTypography } from "../../../../common/Utils/StyledComponents";
import { colors } from "../../../../common/Theme/Colors";
const textStyle = {
  fontSize: "1.1rem",
  color: colors.black,
};
const AddEmployee = () => {
  const formikRef = useRef(null);
  const navigate = useNavigate();
  const [reportTo, setReportTo] = useState({});
  const [allCompanyData, setAllCompanyData] = useState([]);
  const [addMore, setAddMore] = useState(false);
  const [filesAdded, setfilesAdded] = useState([]);
  const [images, setImages] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedCompanyLabel, setSelectedCompanyLabel] = useState();

  const [getCompanyList, { data: companyResponse }] =
    useLazyGetCompanyListQuery();

  const openModal = () => setShowModal(true);
  const hideModal = () => setShowModal(false);
  useEffect(() => {
    getCompanyList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (companyResponse) {
      let companyData = [];
      companyResponse.map((item) =>
        companyData.push({
          label: item.name,
          value: `${item._id}`,
        })
      );
      setAllCompanyData(companyData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyResponse]);

  const [
    addEmployeeApi,
    {
      data: employeeData,
      isSuccess: successfullyAddedEmployee,
      isError: errorAddingEmployee,
      error: errorEmployee,
      isLoading: isLoadingAddEmployee,
    },
  ] = useAddStaffEmployeeDataMutation();

  useEffect(() => {
    if (errorAddingEmployee && errorEmployee) {
      errorEmployee.data.error
        ? errorEmployee.data.error.length > 0 &&
          errorEmployee.data.error.map((errorItem) => {
            const isPresent = getAddressError(
              errorItem.param,
              formikRef.current?.setFieldError
            );
            !isPresent &&
              formikRef.current.setFieldError(errorItem.param, errorItem.msg);
          })
        : showToast({ message: errorEmployee?.data?.message, type: "error" });
    }

    if (successfullyAddedEmployee && employeeData) {
      showToast({ message: employeeData.message, type: "success" });
      formikRef.current.resetForm();
      InitialValues.dob = "";
      setReportTo({});
      formikRef.current.setFieldValue("dob", "");
      formikRef.current.setFieldValue("gender", "MALE");
      setfilesAdded([]);
      setImages([]);
      if (addMore) {
        formikRef.current.resetForm();
      } else {
        navigate("/employeeListing");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeData, errorEmployee]);

  useEffect(() => {
    if (filesAdded.length > 0) {
      const newImages = filesAdded.map((imageElement) =>
        imageElement.preview ? imageElement.preview : imageElement?.url
      );
      setImages(newImages);
    }
  }, [filesAdded]);

  const onSubmit = (companyFormValues) => {
    hideModal();
    const countryCode = companyFormValues.countryCode
      ? companyFormValues.countryCode
      : "962";
    const alternateCountryCode = companyFormValues.alternateMobile
      ? companyFormValues.alternateMobileCountryCode.slice(1)
        ? companyFormValues.alternateMobileCountryCode.slice(1)
        : "91"
      : "";
    const HRcountryCode = companyFormValues.hrMobile
      ? companyFormValues.hrMobileCountryCode.slice(1)
        ? companyFormValues.hrMobileCountryCode.slice(1)
        : "91"
      : "";
    let requestObjectEmployee = {
      companyId: [companyFormValues?.companyId],
      name: companyFormValues.name,
      gender: companyFormValues.gender.toUpperCase(),
      dob: formatDateYYYYMMDD(companyFormValues.dob),

      designation: companyFormValues.designation,
      department: companyFormValues.department,
      countryCode: countryCode,
      // mobile: companyFormValues.mobile,
      mobile: companyFormValues.mobile?.split("-")[1],

      mobileShortCode: companyFormValues?.mobileShortCode,

      companyExtension: companyFormValues.companyExtension,
      companyNumber: companyFormValues.companyNumber,

      email: companyFormValues.email,

      residenceAddress: {
        latitude: companyFormValues.resLat,
        longitude: companyFormValues.resLng,
        latlong: {
          latitude: companyFormValues.resLat,
          longitude: companyFormValues.resLng,
        },
        flat: companyFormValues.resFlat,
        address: companyFormValues.resAddress,
        country: companyFormValues.resCountry,
        state: companyFormValues.resState,
        city: companyFormValues.resCity,
        zipCode: companyFormValues.resZipCode,
      },
      workAddress: {
        latitude: companyFormValues.workLat,
        longitude: companyFormValues.workLng,
        latlong: {
          latitude: companyFormValues.workLat,
          longitude: companyFormValues.workLng,
        },

        flat: companyFormValues.workFlat,
        address: companyFormValues.workAddress,
        country: companyFormValues.workCountry,
        state: companyFormValues.workState,
        countryISO: "IN",
        city: companyFormValues.workCity,
        zipCode: companyFormValues.workZipCode,
      },
      role: {
        type: "EMPLOYEE",
      },
      reportTo,
    };

    if (filesAdded.length > 0) {
      const b64Object = {
        ...filesAdded.map((ele) => ({
          url: ele?.b64,
          type: ele?.type,
          profileFileName: ele?.name,
          profileFileExt: ele?.name.split(".").pop(),
        }))[0],
      };

      requestObjectEmployee = {
        ...requestObjectEmployee,
        webProfileUrl: b64Object,
      };
    } else {
      requestObjectEmployee = {
        ...requestObjectEmployee,
      };
    }

    if (companyFormValues.alternateMobile.length > 0) {
      requestObjectEmployee = {
        ...requestObjectEmployee,
        alternateMobileCountryCode:
          companyFormValues.alternateMobileCountryCode,
        alternateMobile: companyFormValues.alternateMobile?.split("-")[1],
        alternateMobileShortCode: companyFormValues?.alternateMobileShortCode,
      };
    }
    if (companyFormValues.hrMobile.length > 0) {
      requestObjectEmployee = {
        ...requestObjectEmployee,
        hrMobileCountryCode: companyFormValues.hrMobileCountryCode,

        hrMobile: companyFormValues.hrMobile?.split("-")[1],
        hrMobileShortCode: companyFormValues?.hrMobileShortCode,
      };
    }
    addEmployeeApi(requestObjectEmployee);
  };

  return (
    <Formik
      initialValues={InitialValues}
      innerRef={formikRef}
      validateOnMount
      onSubmit={showModal ? onSubmit : openModal}
      validationSchema={AddEmployeeSchema}
    >
      {({ values, handleSubmit }) => {
        return (
          <>
            <EmployeeForm
              values={values}
              handleSubmit={handleSubmit}
              filesAdded={filesAdded}
              images={images}
              setfilesAdded={setfilesAdded}
              setImages={setImages}
              isLoading={isLoadingAddEmployee}
              formikRef={formikRef}
              onAddMore={(value) => setAddMore(value)}
              shoModal={showModal}
              isAdd
              setReportTo={setReportTo}
              setSelectedCompanyLabel={setSelectedCompanyLabel}
            />
            {showModal && (
              <AlertPermission
                show={showModal}
                title={Translate("addManager:confirmation")}
                okTitle={Translate("common:confirm")}
                subTitle={
                  <StyledTypography
                    variant="subtitle1"
                    sx={{
                      fontWeight: 500,
                      ...textStyle,
                    }}
                  >
                    {Translate("addManager:alertText1")}
                    {selectedCompanyLabel && (
                      <StyledTypography
                        variant="span"
                        sx={{
                          fontWeight: 600,
                          ...textStyle,
                        }}
                      >
                        {selectedCompanyLabel}
                      </StyledTypography>
                    )}
                    {Translate("addManager:alertText2")}
                    {values.name && (
                      <StyledTypography
                        variant="span"
                        sx={{
                          fontWeight: 600,
                          ...textStyle,
                        }}
                      >
                        {values.name}
                      </StyledTypography>
                    )}
                    {Translate("addManager:alertText3")}
                  </StyledTypography>
                }
                onOk={handleSubmit}
                onCanel={hideModal}
              />
            )}
          </>
        );
      }}
    </Formik>
  );
};
export default AddEmployee;

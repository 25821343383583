import { Stack } from "@mui/material";
import moment from "moment";
import React from "react";
import { colors } from "../../../common/Theme/Colors";
import { StyledTypography } from "../../../common/Utils/StyledComponents";
const RenderTimeline = ({ data }) => {
  return (
    <Stack style={styles.container}>
      {data.map((time, index) => {
        return (
          <Stack key={index} style={styles.mainView}>
            <StyledTypography variant="subtitle1" style={styles.timeText}>
              {moment(time, "LT").format("hh:mm A")}
            </StyledTypography>
            {data.length !== index + 1 && <Stack style={styles.straightLine} />}
          </Stack>
        );
      })}
    </Stack>
  );
};
export default RenderTimeline;

const styles = {
  container: {
    width: "20%",
  },
  mainView: {
    alignItems: "center",
    maxHeight: 104,
  },
  timeText: {
    textAlignVertical: "center",
    height: 24,
  },
  straightLine: {
    height: 84,
    width: 0.5,
    border: `0.5px solid ${colors.orange}`,
    justifyContent: "center",
  },
};

import * as yup from "yup";
import Translate from "../../../translation/utils/translateFunction";

export const CreateEvenetValidationSchema = () => {
  const schema = yup.object().shape({
    companyId: yup
      .string()
      .trim()
      .nullable()
      .required(Translate("addTask:companyReqError")),
    subject: yup
      .string()
      .trim()
      .nullable()
      .required(Translate("createEvent:subjectError")),
    description: yup
      .string()
      .trim()
      .nullable()
      .required(Translate("createEvent:descriptionError")),
    timeZone: yup
      .string()
      .trim()
      .nullable()
      .required(Translate("createEvent:timezoneError")),
    fromDate: yup
      .string()
      .trim()
      .nullable()
      .required(Translate("createEvent:fromDateError")),
    toDate: yup
      .string()
      .trim()
      .nullable()
      .required(Translate("createEvent:toDateError")),
    startTime: yup
      .string()
      .trim()
      .nullable()
      .required(Translate("createEvent:startTimeError")),
    endtime: yup
      .string()
      .trim()
      .nullable()
      .required(Translate("createEvent:endTimeError")),
    repeatEvent: yup
      .string()
      .trim()
      .nullable()
      .required(Translate("createEvent:repeatEventError")),
    reminder: yup
      .string()
      .trim()
      .nullable()
      .required(Translate("createEvent:reminderError")),
    // venu: yup.string().trim(),
  });

  return schema;
};

import * as yup from "yup";
import Translate from "../../../../../translation/utils/translateFunction";

export const RejectedReasonSchema = () => {
  const schema = yup.object().shape({
    rejectedReason: yup.string().trim().required(Translate("taskDetails:addReasonError")),
  });

  return schema;
};

import { Formik } from "formik";
import React, { useEffect, useRef, useState, useMemo } from "react";
import Button from "react-bootstrap/Button";
import { Modal } from "react-bootstrap";
import * as yup from "yup";
import { showToast } from "../../../common/Utils/ToastMessage";
import { useRenameVoiceNoteMutation } from "../../../request/VoiceNotes";
import Translate from "../../../translation/utils/translateFunction";
import { StyledTypography } from "../../../common/Utils/StyledComponents";
import { colors } from "../../../common/Theme/Colors";
import { handleEventKeySubmit } from "../../../common/Utils/commonFunction";

export const RenameVoiceNoteSchema = () => {
  const schema = yup.object().shape({
    title: yup
      .string()
      .required(Translate("VoiceNotes:requiredValidation"))
      .nullable(),
  });

  return schema;
};
export const initialValue = {
  title: "",
};

const RenameVoiceNote = ({
  handleModal,
  showModal,
  renameId,
  onSuccessFulRename,
  isOccasion = false,
  title = Translate("VoiceNotes:renameTitle"),
  placeholder = "Title",
  onSubmit,
}) => {
  const formikRef = useRef(null);
  const pattern = /[~`!@#$₹%^&*+=\-[\]\\';,._©®™✓°¥€¢£√π÷¶•∆/{}()|\\":<>?\s]/;

  const [renamedValue, setRenamedValue] = useState("");
  const [isTitleError, setIsTitleError] = useState(false);
  const [
    renameVoiceNoteApi,
    {
      data: renameVoiceNote,
      isSuccess: successfulRename,
      isError: errorInRename,
      error: errorRename,
    },
  ] = useRenameVoiceNoteMutation();

  useEffect(() => {
    if (errorRename && errorInRename) {
      errorRename.data.error &&
        showToast({
          message: errorRename?.data?.message,
          type: "error",
        });
    }

    if (successfulRename && renameVoiceNote) {
      showToast({ message: renameVoiceNote.message, type: "success" });

      onSuccessFulRename(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [renameVoiceNote, errorRename, errorInRename]);
  useEffect(() => {
    if (renameId && showModal) {
      formikRef.current.setFieldValue("title", renameId?.title);
      setRenamedValue(renameId?.title);
    }
    return () => setIsTitleError(false);
  }, [renameId, showModal]);

  const handleSubmit = async (values) => {
    if (!isOccasion) {
      const requestObject = {
        title: renamedValue,
      };
      await renameVoiceNoteApi({
        body: requestObject,
        id: renameId._id,
      });
    } else {
      onSubmit && onSubmit(renamedValue);
    }
    handleModal && handleModal(false);
  };

  const handleClose = () => {
    handleModal && handleModal(false);
  };

  const isBtnDisabled = useMemo(() => {
    return (
      renamedValue === "" || renamedValue === renameId?.title || isTitleError
    );
  }, [renamedValue, renameId?.title, isTitleError]);

  const onEventSubmit = (submitForm) => {
    if (!isBtnDisabled) {
      submitForm();
    }
  };

  return (
    <>
      <Modal
        show={showModal}
        onHide={handleClose}
        onBackdropClick={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
        className="col-span-3 col-md-12"
      >
        <Formik
          initialValues={initialValue}
          validateOnMount
          onSubmit={handleSubmit}
          innerRef={formikRef}
          validationSchema={RenameVoiceNoteSchema}
        >
          {({ values, setFieldValue, submitForm, errors }) => {
            return (
              <>
                <Modal.Header closeButton>
                  <Modal.Title>{title}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                  <div
                    style={{
                      marginLeft: "7%",
                      marginRight: "7%",
                      marginTop: "4%",
                    }}
                    onKeyDown={(e) =>
                      handleEventKeySubmit(e, () => onEventSubmit(submitForm))
                    }
                  >
                    <input
                      type="text"
                      className="form-control"
                      placeholder={placeholder}
                      style={{
                        border: isTitleError && `0.5px solid ${colors.red_001}`,
                      }}
                      name="title"
                      value={values.title}
                      onChange={(e) => {
                        if (isOccasion) {
                          setRenamedValue(e.target.value);
                          setFieldValue("title", e.target.value);
                        } else {
                          setRenamedValue(e.target.value.trim());
                          setFieldValue("title", e.target.value.trim());
                          e.target.value.trim().match(pattern) ||
                          e.target.value.trim() === ""
                            ? setIsTitleError(true)
                            : setIsTitleError(false);
                        }
                      }}
                      // value={values.rename}
                      autoComplete="off"
                      maxLength={50}
                    />
                    {errors.rename && (
                      <div className="error">{errors.rename}</div>
                    )}
                    {isTitleError && (
                      <StyledTypography
                        sx={{ color: "red", fontSize: 11 }}
                        component="span"
                      >
                        {Translate("VoiceNotes:errorMessage")}
                      </StyledTypography>
                    )}
                  </div>
                </Modal.Body>

                <Modal.Footer>
                  <Button
                    variant="btn btn-white addButton"
                    onClick={handleClose}
                  >
                    {isOccasion
                      ? Translate("common:cancel")
                      : Translate("common:close")}
                  </Button>
                  <Button
                    variant={"btn btn-primary saveButton"}
                    disabled={isBtnDisabled}
                    onClick={() => {
                      submitForm();
                    }}
                    type="submit"
                  >
                    {Translate("common:save")}
                  </Button>
                </Modal.Footer>
              </>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};
export default RenameVoiceNote;

import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import NotFound from "../Authentication/404";
import InternalServer from "../Authentication/500";
import Forgotpassword from "../Authentication/components/ForgotPassword";
import Login from "../Authentication/login";
import SignUp from "../Authentication/signup";
import TaskDependancyMobile from "../Authentication/taskDependancyMobile";
import Privacy from "../Privacy";
import TermsCondition from "../TermsCondition";

const UnAuthRoutes = () => {
  return (
    // <Routes>
    //   <Route path="/signup" element={<SignUp />} />
    //   <Route path="/" element={<Login />} />
    //   <Route path="/forgotpassword" element={<ForgotPassword />} />
    //   <Route path="/notfound" element={<NotFound />} />
    //   <Route path="/internalserver" element={<InternalServer />} />
    //   <Route path="*" element={<Navigate to="/" replace />} />
    //   <Route path="/termsConditions" element={<TermsCondition />} />
    //   <Route path="/privacy" element={<Privacy />} />
    // </Routes>
    <Routes>
      <Route path="/signup" element={<SignUp />} />
      <Route path="/" element={<Login />} />
      {/* <Route path="/forgotpassword" element={<ForgotPassword />} /> */}
      <Route path="/forgotPassword" element={<Forgotpassword />} />

      <Route path="/notfound" element={<NotFound />} />
      <Route path="/internalserver" element={<InternalServer />} />
      <Route path="*" element={<Navigate to="/" replace />} />
      <Route path="/termsOfServices" element={<TermsCondition />} />
      <Route path="/privacyPolicy" element={<Privacy />} />
      <Route path="/taskdependancy" element={<TaskDependancyMobile />} />
    </Routes>
  );
};
export default UnAuthRoutes;

import * as yup from "yup";
import Translate from "../../../../translation/utils/translateFunction";
import { validateMobileSchema } from "../../../../common/Utils/commonFunction";

export const AddEmployeeSchema = () => {
  const schema = yup.object().shape({
    companyId: yup.string().trim().required(Translate("addOwner:companyError")),
    name: yup.string().trim().required(Translate("addEmployee:nameError")),
    dob: yup.string().trim().required(Translate("addEmployee:dobError")),
    designation: yup
      .string()
      .trim()
      .required(Translate("addEmployee:designationError")),
    department: yup
      .string()
      .trim()
      .required(Translate("addEmployee:departmentError")),
    ...validateMobileSchema("mobile", Translate("addEmployee:contactError")),
    reportTo: yup.string().trim().required(Translate("addTask:reportToError")),
    // .matches(/^[0-9]*$/, "Number should not have special character"),
    email: yup
      .string()
      .email()
      .trim()
      .required(Translate("addEmployee:workError")),
    // residenceAddress: yup.object().shape({
    resAddress: yup
      .string()
      .trim()
      .required(Translate("addEmployee:registerAddError")),
    workAddress: yup
      .string()
      .trim()
      .required(Translate("addEmployee:workAddressError")),
    resCountry: yup
      .string()
      .trim()
      .required(Translate("addManager:countryError")),
    resState: yup.string().trim().required(Translate("addManager:stateError")),
    workCountry: yup
      .string()
      .trim()
      .required(Translate("addManager:countryError")),
    workState: yup.string().trim().required(Translate("addManager:stateError")),
  });

  return schema;
};

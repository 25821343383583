import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as BrandLogo } from "../../../assets/images/FoM_logo.svg";
import { StyledTypography } from "../../../common/Utils/StyledComponents";
import Translate from "../../../translation/utils/translateFunction";
import { FormikTextField } from "../../FormikForm/component/FormikTextField";
import { Stack } from "@mui/material";
import Fro1 from "../../../assets/images/Illustration_1.svg";
import Fro2 from "../../../assets/images/Illustration_2.svg";
import Fro3 from "../../../assets/images/Illustration_3.svg";
import { colors } from "../../../common/Theme/Colors";
import Loader from "../../staff/Owner/LoadingComp";
import { InitialValues } from "../constants";
import { LoginSchema } from "../utils";
import { handleEventKeySubmit } from "../../../common/Utils/commonFunction";

const LoginwithEmail = ({
  setLoginWithOTP,
  loginUser,
  resetForm,
  isLoading,
  consent,
}) => {
  var platform = require("platform");
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const formikRef = useRef(null);
  const { fcmToken } = useSelector((state) => state.formanagement);
  useEffect(() => {
    if (resetForm) {
      formikRef.current.resetForm();
    }
  }, [resetForm]);
  const onSubmit = (companyFormValues) => {
    localStorage.clear();
    const requestObj = {
      isLoginByEmail: true,
      email: companyFormValues.email,
      password: companyFormValues.password,
      isLocationEnabled: false,
      isConsent: consent,
      latlong: {
        latitude: "",
        longitude: "",
      },
      loginFor: "WEB",
      device: {
        deviceId: "6797279a44145cf2",
        osType: "WEB",
        osVersion: platform.parse(platform.ua).version || "",
        deviceUniqueId: "",
        deviceBrand: platform.name || "",
        deviceModel: "",
        deviceType: platform.product || "",
        appVersion: platform.version || "",
        fcmToken: fcmToken,
      },
      language: "english",
    };
    loginUser(requestObj);
  };

  const handleLoginWithOtp = () => setLoginWithOTP(true);

  return (
    <Formik
      initialValues={InitialValues}
      innerRef={formikRef}
      validateOnMount
      onSubmit={onSubmit}
      validationSchema={LoginSchema}
      validateOnChange>
      {({ handleSubmit }) => {
        return (
          <div className="auth">
            <div className="auth_left">
              <div className="card" style={{ zIndex: 999 }}>
                <div className="text-center mb-30">
                  <Link className="header-brand" to="/">
                    <BrandLogo className="forLogo"></BrandLogo>
                  </Link>
                </div>

                <div className="card-body">
                  <div onKeyDown={(e) => handleEventKeySubmit(e, handleSubmit)}>
                    <div className="form-group col-md-12 ">
                      <FormikTextField
                        name="email"
                        label={Translate("login:email")}
                        placeholder={Translate("login:emailPlaceholder")}
                        autoComplete={"off"}
                        autoFocus
                      />
                    </div>
                    <div className="form-group col-md-12 ">
                      <FormikTextField
                        name="password"
                        label={Translate("login:password")}
                        placeholder={Translate("login:passwordPlaceholder")}
                        autoComplete={"off"}
                        type={showPassword ? "text" : "password"}
                        RenderRightContainer={
                          showPassword ? (
                            <i
                              className="fe fe-eye-off cursor-pointer"
                              onClick={() => setShowPassword(false)}
                            />
                          ) : (
                            <i
                              className="fe fe-eye cursor-pointer"
                              onClick={() => setShowPassword(true)}
                            />
                          )
                        }
                        showPassword={showPassword}
                      />
                    </div>
                    <div
                      className=" text-primary cursor-pointer"
                      style={{ textAlign: "end", marginRight: 7 }}
                      onClick={() => {
                        navigate("/forgotPassword");
                      }}>
                      {Translate("login:forgotPassword")}
                    </div>
                    {/* <span>Forgot Password?</span> */}
                    <div className="form-footer">
                      <button
                        type="submit"
                        className="btn btn-primary btn-block"
                        onClick={() => !isLoading && handleSubmit()}>
                        {isLoading ? (
                          <Loader
                            loading={isLoading}
                            isListLoader
                            message={""}
                          />
                        ) : (
                          Translate("login:login")
                        )}
                      </button>
                    </div>
                  </div>

                  <div className="row orStyling">
                    <div className="line"></div>
                    <div> {Translate("login:or")} </div>
                    <div className="line"></div>
                  </div>

                  <div
                    className="text-center text-primary cursor-pointer"
                    tabIndex="0"
                    onKeyDown={(e) => handleEventKeySubmit(e, handleLoginWithOtp)}
                    onClick={handleLoginWithOtp}>
                    {Translate("login:otp")}
                  </div>

                  <Stack mt={"1.5rem"}>
                    <StyledTypography variant="subtitle2">
                      {Translate("login:termsAndConditions1")}
                    </StyledTypography>
                    <Stack flexDirection={"row"}>
                      <StyledTypography
                        className="cursor-pointer"
                        variant="subtitle2"
                        sx={{ color: colors.primary_010 }}
                        // onClick={() => navigate("/termsConditions")}
                        onClick={() => navigate("/termsOfServices")}>
                        {Translate("login:termsAndConditions2")}
                      </StyledTypography>
                      <StyledTypography variant="subtitle2" mx={"4px"}>
                        {Translate("login:termsAndConditions3")}
                      </StyledTypography>
                      <StyledTypography
                        className="cursor-pointer"
                        variant="subtitle2"
                        sx={{ color: colors.primary_010 }}
                        // onClick={() => navigate("/privacy")}
                        onClick={() => navigate("/privacyPolicy")}>
                        {Translate("login:termsAndConditions4")}
                      </StyledTypography>
                    </Stack>
                  </Stack>
                </div>
              </div>
            </div>
            <div className="auth_right">
              <div
                className="carousel slide"
                data-ride="carousel"
                data-interval={3000}>
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <img src={Fro1} className="img-fluid" alt="login page" />

                    <div className="px-4 mt-4">
                      {/* <h4>Fully Responsive</h4>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit.
                      </p> */}
                    </div>
                  </div>
                  <div className="carousel-item">
                    <img src={Fro2} className="img-fluid" alt="login page" />
                    <div className="px-4 mt-4">
                      {/* <h4>Quality Code and Easy Customizability</h4>
                      <p>
                        There are many variations of passages of Lorem Ipsum
                        available.
                      </p> */}
                    </div>
                  </div>
                  <div className="carousel-item">
                    <img src={Fro3} className="img-fluid" alt="login page" />
                    <div className="px-4 mt-4">
                      {/* <h4>Cross Browser Compatibility</h4>
                      <p>
                        Overview We're a group of women who want to learn
                        JavaScript.
                      </p> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }}
    </Formik>
  );
};
export default LoginwithEmail;

import { Grid } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDebounce } from "use-debounce";
import { colors } from "../../../common/Theme/Colors";
import {
  StyledCard,
  StyledTypography,
} from "../../../common/Utils/StyledComponents";
import { showToast } from "../../../common/Utils/ToastMessage";
import {
  useDeleteVoiceNoteMutation,
  useGetAddAccessLogsMutation,
  useGetMyVoiceNotesListingQuery,
  useGetShareVoiceNotesMutation,
} from "../../../request/VoiceNotes";
import Translate from "../../../translation/utils/translateFunction";
import AlertPermission from "../../AlertPermission";
import ListView from "../../ListView";
import ShareWithUser from "../../ShareWithUser";
import { shareModuleType } from "../../ShareWithUser/components/constants";
import Searchbar from "../../common/Searchbar";
import RenameVoiceNote from "../Components/RenameVoiceNote";
import VoiceNotes from "../Components/VoiceNotes";
import {
  actionType,
  createdByPA,
  documentCollection,
  documentDescriptionMyVoiceNotes,
  ownList,
} from "../Components/constants";
import { getMyVoiceNoteColumns } from "./column.data";
import { getMyVoiceNotePAColumns } from "./pacolumn.data";

const VoiceNotesListing = ({ fixNavbar }) => {
  const { userData, isRtl } = useSelector((state) => state.formanagement);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setLoading] = useState(true);
  const [lastPageNo, setLastPageNo] = useState(0);
  const [myVoiceNotes, setMyVoiceNotes] = useState([]);
  const [show, setshow] = useState(false);

  const [id, setId] = useState("");
  const [type, setType] = useState("OWN");
  const [renameId, setRenameId] = useState("");
  const [debouncedSearch] = useDebounce(search, 300);
  const isMyVoiceNotes = true;

  const [showShareModal, setShowShareModal] = useState(false);
  const [selectedVoiceNoteId, setSelectedVoiceNoteId] = useState();
  const [voiceNotePlay, setVoiceNotePlay] = useState(false);
  const [playedVoiceNoteId, setPlayedVoiceNoteId] = useState();
  const [isSharedByMe, setIsSharedByMe] = useState(false);
  const [selectedVoiceNote, setSelectedVoiceNote] = useState("");
  const ref = useRef(null);
  const [
    addAccessLogsApi,
    {
      data: addAccessLogsVoiceNote,
      isError: isErrorAddAccessLogs,
      error: errorAddAccessLogs,
      isSuccess: isSuccessAddAccessLogs,
    },
  ] = useGetAddAccessLogsMutation();

  useEffect(() => {
    let requestObject = {
      documentId: playedVoiceNoteId,
      typeOfAction: actionType,
      documentCollection: documentCollection,
      documentDescription: documentDescriptionMyVoiceNotes,
    };
    if (voiceNotePlay) {
      addAccessLogsApi(requestObject);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [voiceNotePlay, playedVoiceNoteId]);

  useEffect(() => {
    if (addAccessLogsVoiceNote && isSuccessAddAccessLogs) {
      setVoiceNotePlay(false);
    }
    if (errorAddAccessLogs && isErrorAddAccessLogs) {
      errorAddAccessLogs?.originalStatus !== 500 &&
        showToast({
          message:
            errorAddAccessLogs?.message ||
            Translate("document:apiFailedMessage"),
          type: "error",
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addAccessLogsVoiceNote, isSuccessAddAccessLogs, isErrorAddAccessLogs]);

  const {
    data: myVoiceNotesList,
    isLoading: isloadingMyVoiceNotes,
    isFetching: isFetchingMyVoiceNote,
    isError: myVoiceNotesIsError,
    error: myVoiceNotesError,
    refetch: refetchMyListing,
    isSuccess: successfullVoiceNoteList,
  } = useGetMyVoiceNotesListingQuery(
    { currentPage, debouncedSearch, type, isSharedByMe },
    { refetchOnMountOrArgChange: true }
  );

  const [
    deleteVoiceNoteApi,
    {
      data: deleteVoiceNote,
      isSuccess: successfullyDeleteVoiceNote,
      isLoading: isVoiceNoteDeleteLoading,
    },
  ] = useDeleteVoiceNoteMutation();

  const [
    shareVoiceNoteApi,
    {
      data: shareVoiceNote,
      isError: isErrorShareVoiceNote,
      error: errorShareVoiceNote,
      isSuccess: isSuccessShareVoiceNote,
    },
  ] = useGetShareVoiceNotesMutation();

  useEffect(() => {
    if (shareVoiceNote && isSuccessShareVoiceNote) {
      showToast({ message: shareVoiceNote?.message, type: "success" });

      refetchMyListing();
    }

    if (errorShareVoiceNote && isErrorShareVoiceNote) {
      errorShareVoiceNote?.originalStatus !== 500 &&
        showToast({
          message:
            errorShareVoiceNote?.message ||
            Translate("document:apiFailedMessage"),
          type: "error",
        });
    }
  }, [shareVoiceNote, isSuccessShareVoiceNote, isErrorShareVoiceNote]);

  useEffect(() => {
    if (successfullVoiceNoteList && myVoiceNotesList?.data) {
      const { nodes, pageInfo } = myVoiceNotesList.data;
      setLastPageNo(pageInfo?.lastPageNo);
      setMyVoiceNotes(nodes);
      setLoading(false);
    }
    if (myVoiceNotesIsError && myVoiceNotesError) {
      setLoading(false);
      myVoiceNotesError?.originalStatus !== 500 &&
        showToast({
          message: Translate("VoiceNotes:apiFailedMessage"),
          type: "error",
        });
    }
  }, [myVoiceNotesList, successfullVoiceNoteList]);

  useEffect(() => {
    if (successfullyDeleteVoiceNote && deleteVoiceNote) {
      showToast({ message: deleteVoiceNote.message, type: "success" });
      refetchMyListing();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteVoiceNote]);

  const getVoiceNoteID = (value) => {
    if (value) {
      setId(value);
    }
  };
  const deleteVoiceNotes = () => {
    const requestBody = {
      voiceNotes: [id],
    };

    deleteVoiceNoteApi(requestBody);

    setshow(false);
  };
  const onCancel = () => setshow(false);

  const deleteVoice = (value) => {
    if (value) {
      setshow(true);
    }
  };

  const showRenameModal = (value) => {
    if (value) {
      setShowModal(true);
    }
  };

  const filters = ["OWN", "PA"];

  const getFilterNavigate = (selectedFilter) => {
    switch (selectedFilter) {
      case 0: {
        setType(ownList);
        break;
      }
      case 1: {
        setType(createdByPA);
        break;
      }

      default:
        break;
    }
  };

  const toogleList = (e) => {
    if (isSharedByMe) {
      setIsSharedByMe(false);
    } else {
      setIsSharedByMe(true);
    }
  };

  const onSearch = (value) => {
    setCurrentPage(1);

    setSearch(value);
  };
  const onCloseShareModal = () => setShowShareModal(false);

  const handleShareVoiceNote = (users) => {
    const reqObj = {
      voicenoteId: [selectedVoiceNoteId],
      users: users?.map((ele) => ele._id),
    };

    shareVoiceNoteApi(reqObj);
  };
  const customSrCellRender = (params) => {
    return (
      <StyledTypography component="span" sx={{ color: colors.primary_009 }}>
        {params?.api?.getRowIndexRelativeToVisibleRows(params?.row?._id) +
          1 +
          (currentPage - 1) * 10 || ""}
      </StyledTypography>
    );
  };
  const customCellRender = (params) => {
    return (
      <VoiceNotes
        voiceObj={{
          byteCode: params?.row?.buffer,
          timeLength: params?.row?.timeLength,
          src: params?.row?.link,
          createdAt: params?.row?.createdAt,
          timeLengthInSec: params?.row?.timeLengthInSec?.toString(),
        }}
        className={"voiceNote_spacing"}
        isShared={params?.row?.hasShared}
        isVoiceNotesList={false}
        onVoiceNotePlay={(data) => {
          if (data) {
            setVoiceNotePlay(data);
            setPlayedVoiceNoteId(params?.row?._id);
          }
        }}
        params={params?.row?._id}
        currentVoiceNote={selectedVoiceNote && selectedVoiceNote}
      />
    );
  };

  const customActionCellRender = (params) => {
    return (
      <>
        <div className="headerMargin">
          <a
            href="/#"
            className="nav-link dropdown-toggle"
            data-toggle="dropdown"
            role="button"
            aria-haspopup="true"
            aria-expanded="false">
            <i className="fa fa-ellipsis-v cursor-pointer manageIconColor" />
          </a>
          <div className="dropdown-menu dropdownAlignment dropdownIconMenu">
            <div
              className="manageIconColor ml-3 cursor-pointer dropdown-item"
              onClick={() => {
                showRenameModal(true);
                setRenameId(params?.row);
              }}>
              <span className="manageIconColor fa fa-pencil cursor-pointer" />
              {Translate("VoiceNotes:editIcon")}
            </div>

            <div
              className="manageIconColor ml-3 cursor-pointer dropdown-item"
              onClick={() => {
                getVoiceNoteID(params?.row?._id);
                deleteVoice(true);
              }}>
              <span className="manageIconColor fa fa-trash" />
              {Translate("VoiceNotes:deleteIcon")}
            </div>

            <div
              className="manageIconColor ml-3 cursor-pointer dropdown-item"
              onClick={() => {
                setShowShareModal(true);
                setSelectedVoiceNoteId(params?.row?._id);
              }}>
              <span className="manageIconColor fe fe-share-2" />
              {Translate("VoiceNotes:shareIcon")}
            </div>

            <div
              className="manageIconColor ml-3 cursor-pointer dropdown-item"
              onClick={() =>
                navigate("/setReminder", {
                  state: {
                    isVoiceNote: true,
                    voiceNoteId: params?.row?._id,
                    voiceNoteName: params?.row?.title,
                  },
                })
              }>
              <span className="manageIconColor fa fa-clock-o" />
              {Translate("VoiceNotes:reminderIcon")}
            </div>

            <div
              className="manageIconColor ml-3 cursor-pointer dropdown-item"
              onClick={() =>
                navigate("/voiceNotes/myVoiceNotes/accessLogs", {
                  state: params?.row?._id,
                })
              }>
              <span className="manageIconColor fa fa-file-text" />
              {Translate("VoiceNotes:accesslogsIcon")}
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <Grid
        item
        xs={12}
        className={`section-body ${isRtl ? "rtlMargin" : "ltrMargin"} ${
          fixNavbar ? "marginTop" : ""
        } `}>
        <StyledCard variant="outlined">
          <div className="container-fluid p-4" ref={ref}>
            <div className="tab-content">
              <div>
                <div
                  className="card "
                  id="voicenoteContainer"
                  style={{ minHeight: 700, maxHeight: 900 }}>
                  <div className="card-header card-header-spacing">
                    <div
                      className={`${
                        isRtl
                          ? "voicenotesSearchbarDivRtl"
                          : "voicenotesSearchbarDiv"
                      }`}>
                      <div>
                        <div className="input-group">
                          <Searchbar
                            handleSearchText={onSearch}
                            setSearch={setSearch}
                          />
                        </div>
                      </div>
                      {userData?.role?.type === "OWNER" ||
                      userData?.role?.type === "GM" ? (
                        <div className="d-flex ml-2">
                          <div className="ml-2 headerMargin">
                            <a
                              href="/#"
                              className="nav-link dropdown-toggle"
                              data-toggle="dropdown"
                              role="button"
                              aria-haspopup="true"
                              aria-expanded="false">
                              <i className="fa fa-ellipsis-v manageIconColor" />
                            </a>
                            <div className="dropdown-menu dropdownAlignment">
                              {filters.map((filterItem, index) => (
                                <>
                                  <div onClick={() => getFilterNavigate(index)}>
                                    <a
                                      className={`dropdown-item cursor-pointer ${
                                        type === filterItem
                                          ? "selected unclickable"
                                          : ""
                                      }`}>
                                      {Translate(`VoiceNotes:${filterItem}`)}
                                    </a>
                                  </div>
                                  {filters.length - 1 !== index && (
                                    <div className="dropdown-divider" />
                                  )}
                                </>
                              ))}
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="d-flex sharedByMe">
                    <input
                      type="checkbox"
                      className="cursor-pointer"
                      checked={isSharedByMe}
                      style={{ color: colors.primary }}
                      onClick={toogleList}
                    />
                    <StyledTypography
                      variant="subtitle1"
                      className="sharedByMeLable ml-2 cursor-pointer">
                      {Translate("VoiceNotes:sharedByMe")}
                    </StyledTypography>
                  </div>

                  <ListView
                    columns={
                      type !== "PA"
                        ? getMyVoiceNoteColumns(
                            customSrCellRender,
                            customCellRender,
                            customActionCellRender,
                            ref.current?.offsetWidth
                          )
                        : getMyVoiceNotePAColumns(
                            customSrCellRender,
                            customCellRender,
                            ref.current?.offsetWidth
                          )
                    }
                    showDynamicRowHeight
                    rows={myVoiceNotes}
                    autoHeight
                    sortingMode="server"
                    onPaginationChange={setCurrentPage}
                    currentPage={currentPage}
                    showShimmer={
                      isLoading ||
                      isloadingMyVoiceNotes ||
                      isFetchingMyVoiceNote
                    }
                    showLoader={
                      isLoading ||
                      isFetchingMyVoiceNote ||
                      isVoiceNoteDeleteLoading
                    }
                    totalPageCount={lastPageNo}
                    onCellClick={(params) => {
                      if (params?.field === "voiceNote") {
                        setSelectedVoiceNote(params?.row?._id);
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </StyledCard>
      </Grid>
      <AlertPermission
        show={show}
        subTitle={Translate("VoiceNotes:deleteAlertMsg")}
        okTitle={Translate("common:delete")}
        onOk={deleteVoiceNotes}
        onCanel={onCancel}
      />
      <RenameVoiceNote
        handleModal={setShowModal}
        showModal={showModal}
        renameId={renameId}
        onSuccessFulRename={(value) => value && refetchMyListing()}
      />
      {showShareModal && (
        <ShareWithUser
          currentModule={shareModuleType?.MY_VOICE_NOTE}
          showModal={showShareModal}
          closeModal={onCloseShareModal}
          onSubmit={handleShareVoiceNote}
        />
      )}
    </>
  );
};
export default VoiceNotesListing;

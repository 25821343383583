import React from "react";
import Modal from "react-bootstrap/Modal";
import Translate from "../../translation/utils/translateFunction";
import { colors } from "../../common/Theme/Colors";
import { StyledTypography } from "../../common/Utils/StyledComponents";
import { ReactComponent as NotificationIllustration } from "../../assets/images/notification-illustration.svg";
import { Stack } from "@mui/material";
import IconifyIcon from "../Icon";
import "./notification.css";

const NotificationAlertPermission = ({
  show = false,
  subTitle = "",
  onCanel,
  link,
}) => {
  return (
    <Stack
      p={2}
      className="notificationBar"
      flexDirection={"row"}
      justifyContent={"center"}>
      <Stack flexDirection={"row"} alignItems={"center"}>
        <IconifyIcon icon={"ph:bell"} fontSize="28px" color={colors.black} />
        <StyledTypography
          variant="subtitle1"
          color={colors.black}
          flexDirection={"row"}
          fontWeight={500}
          ml={1}>
          {subTitle}
        </StyledTypography>
        {link && (
          <a
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "underline" }}>
            <StyledTypography
              variant="subtitle1"
              sx={{
                fontSize: "0.9rem",
                color: colors.black,
                textDecoration: "underline",
                fontWeight: 500,
              }}>
              {Translate("common:referThis")}
            </StyledTypography>
          </a>
        )}
      </Stack>
      <IconifyIcon
        icon={"carbon:close"}
        className={"cursor-pointer"}
        fontSize="24px"
        alignItems={"right"}
        color={colors.black}
        onClick={onCanel}
      />
    </Stack>
    // <Modal
    //   show={show}
    //   size="m"
    //   aria-labelledby="contained-modal-title-vcenter"
    //   centered
    //   style={{
    //     alignItems: "center",
    //     justifyContent: "center",
    //   }}
    //   onHide={onCanel}>
    //   <Modal.Header closeButton className="modal-header">
    //     <h4> {"Permission - Notification "} </h4>
    //   </Modal.Header>
    //   <Modal.Body>
    //     <Stack alignItems={"center"}>
    //       <NotificationIllustration
    //         width={"100%"}
    //         height={"auto"}
    //         style={{
    //           alignItems: "center",
    //           justifyContent: "center",
    //           marginTop: "-10%",
    //           marginLeft: "10%",
    //         }}
    //       />
    //       <Stack flexDirection={"row"} mt={2}>
    //         <StyledTypography
    //           variant="subtitle1"
    //           sx={{
    //             fontSize: "1.4rem",
    //             color: colors.black,
    //           }}>
    //           {Translate("common:click")}
    //         </StyledTypography>
    //         <StyledTypography
    //           variant="subtitle1"
    //           ml={1}
    //           sx={{
    //             fontSize: "1.4rem",
    //             fontWeight: 600,
    //             color: colors.black,
    //           }}>
    //           {Translate("common:allow")}
    //         </StyledTypography>
    //       </Stack>
    //       <div className="mb-3 mt-2">
    //         <StyledTypography
    //           variant="subtitle1"
    //           sx={{
    //             fontSize: "0.9rem",
    //             fontWeight: 500,
    //             color: colors.grey_004,
    //             display: "inline",
    //           }}>
    //           {subTitle}
    //           {link && (
    //             <a
    //               href={link}
    //               target="_blank"
    //               rel="noopener noreferrer"
    //               style={{ textDecoration: "underline" }}>
    //               <StyledTypography
    //                 variant="subtitle1"
    //                 sx={{ fontSize: "0.9rem", color: colors.primary }}>
    //                 {Translate("common:referThis")}
    //               </StyledTypography>
    //             </a>
    //           )}
    //         </StyledTypography>
    //       </div>
    //     </Stack>
    //   </Modal.Body>
    // </Modal>
  );
};
export default NotificationAlertPermission;

import { Methods, Urls } from "../../common/Utils/ApiConstants";
import { CreateApi } from "../CreateApi";

const OrgStructureCollection = CreateApi.injectEndpoints({
  endpoints: (builder) => ({
    getCompanyStructureDetails: builder.mutation({
      query: (body) => {
        return {
          url: Urls.getCompanyStructureDetails,
          method: Methods.post,
          body: body,
        };
      },
    }),
    getReportingStructureDetails: builder.mutation({
      query: (body) => {
        return {
          url: Urls.getReportingStructureDetails,
          method: Methods.post,
          body: body,
        };
      },
    }),
  }),
});

export const {
  useGetCompanyStructureDetailsMutation,
  useGetReportingStructureDetailsMutation,
} = OrgStructureCollection;

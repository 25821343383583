import React, { memo } from "react";
import { Persona } from "../../../Persona";
import IconifyIcon from "../../../Icon";
import Translate from "../../../../translation/utils/translateFunction";

const MemberProfileList = ({
  members,
  onClick,
  isIcon,
  profileClassName = "",
}) => {
  return (
    <div className="groupMemberProfileBox" style={{ marginTop: "8px" }}>
      {members.map((item) => (
        <div key={item?._id}>
          <div className="groupMemberProfileHeader">
            <div style={{ position: "relative" }}>
              <Persona
                name={item?.username || item?.name || item?.groupName}
                image={item?.userImage || item?.profileUrl || item?.groupImage}
                size={40}
              />
              {isIcon && (
                <span
                  data-toggle="tooltip"
                  title={Translate("common:removeToolTip")}
                >
                  <IconifyIcon
                    onClick={() => onClick(item)}
                    className={`groupMemberProfileIcon ${profileClassName}`}
                    icon="vaadin:close-circle"
                  />
                </span>
              )}
            </div>

            <span className="groupMemberProfileName">{item?.name}</span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default memo(MemberProfileList);

import {
  jordanCountryCode,
  jordanCountryShortCode,
} from "../../../../common/Utils/ApiConstants";

export const InitialValues = {
  companyId: [],
  name: "",
  dob: "",
  designation: "",
  department: "",
  mobile: "",
  countryCode: jordanCountryCode,
  companyNumber: "",
  hrMobile: "",
  hrMobileCountryCode: jordanCountryCode,
  email: "",
  registerAddress: "",
  resApartment: "",
  resState: "",
  resCountry: "",
  resCity: "",
  resZipCode: "",
  workCity: "",
  workZipCode: "",
  workState: "",
  workCountry: "",
  zipCode: "",
  workAddress: "",
  workApartment: "",
  Male: false,
  Female: false,
  gender: "MALE",

  mobileShortCode: jordanCountryShortCode,

  hrMobileShortCode: jordanCountryShortCode,
  alternateMobile: "",
  alternateMobileCountryCode: jordanCountryCode,
  alternateMobileShortCode: jordanCountryShortCode,
};
export const options = [
  {
    label: "www.flipkart.com",
    value: "www.flipkart.com",
  },
  {
    label: "www.google.com",
    value: "www.google.com",
  },
  {
    label: "www.amazon.in",
    value: "www.amazon.in",
  },
  {
    label: "www.Myntra.com",
    value: "www.Myntra.com",
  },
];

export const radioOptions = ["Male", "Female"];

import { Stack, TextField } from "@mui/material";
import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Translate from "../../translation/utils/translateFunction";
import { StyledTypography } from "../Utils/StyledComponents";

const ShareTaskModal = ({ show, onCancel, data }) => {
  const [isCopy, setIsCopy] = useState(false);
  return (
    <Modal
      show={show}
      onHide={onCancel}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      scrollable={true}
      className="col-span-4 col-md-12">
      <Modal.Header closeButton>
        <Modal.Title>{Translate("taskDetails:copyTitle")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Stack
          className="form-group"
          flexDirection={"row"}
          alignItems={"center"}>
          <TextField value={data} disabled className="form-control" />
          <CopyToClipboard text={data} onCopy={() => setIsCopy(true)}>
            <button
              type="submit"
              style={{ height: "40px" }}
              className={`${
                isCopy
                  ? "btn btn-primary saveButton"
                  : "btn btn-white addButton"
              } mx-2`}>
              <StyledTypography variant="span">
                {isCopy
                  ? Translate("common:copyMsg")
                  : Translate("common:copy")}
              </StyledTypography>
            </button>
          </CopyToClipboard>
        </Stack>
      </Modal.Body>
    </Modal>
  );
};
export default ShareTaskModal;

export default {
  docName: "Document name",
  docNamePlaceholder: "Document name",
  docCategory: "Document category",
  title: "Title",
  docCategoryPlaceholder: "Select document category",
  registrationDate: "Registration date",
  expiryDate: "Expiry date",
  addDoc: "Add",
  editDoc: "Save",
  attach: "Attach file",
  attachHeading: "Attachment",
  nameError: "Document name is required",
  categoryError: "Document category is required",
  registrationDateError: "Registration date is required",
  renewalHeading: "Add renewal",
  renewalCheckBox: "This document has no expiry",
  companyError: "Company name is required",
  editRenewalHeading: "Edit renewal",
  viewRenewalHeading: "Renewal details",
  expiryDateError: "Expiry date is required",
  default: "Default",
  atoz: "A to Z",
};

import { useField } from "formik";
import React from "react";
import { Stack } from "@mui/material";
import { usePlacesWidget } from "react-google-autocomplete";
import {
  FontFamily,
  StyledTextField,
  StyledTypography,
  ErroLabelStyle,
} from "../../../common/Utils/StyledComponents";
import { disableColor } from "./FormikNumberField";

export const FormikTextField = ({
  name,
  autoComplete,
  type,
  label,
  icon,
  innerRef,
  className,
  disabled,
  isLocation = false,
  required,
  locationValue,
  multiline,
  noOfRows,
  RenderRightContainer,
  showPassword,
  maxLength,
  autoFocus,
  ...props
}) => {
  const [field, meta] = useField(name);
  const { error, touched } = { ...meta };

  const { ref } = usePlacesWidget({
    apiKey: "AIzaSyCAuvo_l-egs78Y2DEvuH0_LwEiepXEUnk",

    onPlaceSelected: (places) => {
      locationValue(places);
    },
    options: { types: ["geocode", "establishment"] },
  });
  return (
    <Stack>
      <StyledTypography className="labelStyling" component="span">
        {label}
        {required && (
          <StyledTypography sx={{ color: "red" }} component="span">
            {" *"}
          </StyledTypography>
        )}
      </StyledTypography>
      <StyledTextField
        {...field}
        sx={disabled ? disableColor : null}
        autoFocus={autoFocus}
        inputProps={{
          style: {
            fontFamily: FontFamily,
            /*  padding: "8.5px 14px", */
          },
          maxLength: maxLength,
        }}
        InputProps={{ endAdornment: RenderRightContainer || undefined }}
        type={type || "text"}
        disabled={disabled}
        multiline={multiline}
        rows={noOfRows}
        size="small"
        value={field.value || ""}
        {...props}
        inputRef={isLocation ? ref : undefined}
        variant="outlined"
        autoComplete="off"
        error={touched && error !== undefined}
      />
      {touched && error && (
        <StyledTypography sx={ErroLabelStyle} component="span">
          {error}
        </StyledTypography>
      )}
    </Stack>
  );
};

import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Fro1 from "../../../assets/images/Illustration_1.svg";
import Fro2 from "../../../assets/images/Illustration_2.svg";
import Fro3 from "../../../assets/images/Illustration_3.svg";
import { showToast } from "../../../common/Utils/ToastMessage";
import { useLazyLoginOTPQuery } from "../../../request/Authentication";
import { InitialValues } from "../constants";
import { LoginOTPSchema } from "../utils";
import LoginWithOTPMobile from "./LoginOTPMobile";
import VerifyOTP from "./VerifyOtp";

const LoginWithOTP = ({ loginUser, isLoading, setIsLoginWithOTP, consent }) => {
  var platform = require("platform");

  const [isMobileNumber, setIsMobileNumber] = useState(false);
  const [country, setCountry] = useState();
  const [mobileNumberWithoutCountry, setMobileNumberwithoutcountry] =
    useState();
  const [otp, setOtp] = useState("");
  const formikRef = useRef(null);
  const { fcmToken } = useSelector((state) => state.formanagement);

  const [
    getOTP,
    {
      isLoading: isOtpLoading,
      isError: isErrorLoginUser,
      isSuccess,
      error: LoginUserError,
      data: loginUserData,
      isFetching,
    },
  ] = useLazyLoginOTPQuery();
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = "";
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (isSuccess && loginUserData?.success && !isFetching) {
      setIsMobileNumber(true);
      showToast({ message: loginUserData?.message });
    } else if (isSuccess && !loginUserData?.success && !isFetching) {
      showToast({ message: loginUserData?.message, type: "info" });
    } else if (isErrorLoginUser && !isFetching) {
      showToast({ message: JSON.stringify(LoginUserError), type: "error" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [LoginUserError, isErrorLoginUser, isSuccess, loginUserData, isFetching]);
  const onGoBack = () => setIsLoginWithOTP?.(false);
  const onSubmit = (values) => {
    if (!isMobileNumber) {
      if (values.phoneNumber !== country?.dialCode) {
        getOTP(values.phoneNumber);
      }
    } else {
      const requestObj = {
        isLoginByEmail: false,
        isLocationEnabled: false,
        mobile: mobileNumberWithoutCountry,
        countryCode: country?.dialCode,
        isConsent: consent,
        otp: otp,
        latlong: {
          latitude: "",
          longitude: "",
        },
        loginFor: "WEB",
        device: {
          deviceId: "6797279a44145cf2",
          osType: "WEB",
          osVersion: platform.parse(platform.ua).version || "",
          deviceUniqueId: "",
          deviceBrand: platform.name || "",
          deviceModel: "",
          deviceType: platform.product || "",
          appVersion: platform.version || "",
          fcmToken: fcmToken,
        },
        language: "english",
      };
      loginUser(requestObj);
    }
  };
  return (
    <Formik
      initialValues={InitialValues}
      innerRef={formikRef}
      validateOnMount
      onSubmit={onSubmit}
      validationSchema={LoginOTPSchema}
      validateOnChange>
      {({ handleSubmit, values }) => {
        return (
          <div className="auth">
            <div className="auth_left">
              {isMobileNumber ? (
                <VerifyOTP
                  handleSubmit={handleSubmit}
                  otp={otp}
                  setOtp={setOtp}
                  resendOtp={() => getOTP(values.phoneNumber)}
                  mobileNo={values.phoneNumber}
                  isLoading={isLoading}
                  onGoBack={onGoBack}
                />
              ) : (
                <LoginWithOTPMobile
                  handleSubmit={handleSubmit}
                  setCountry={setCountry}
                  setMobileNumber={setMobileNumberwithoutcountry}
                  isLoading={isOtpLoading || isFetching}
                  onGoBack={onGoBack}
                />
              )}
            </div>
            <div className="auth_right">
              <div
                className="carousel slide"
                data-ride="carousel"
                data-interval={3000}>
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <img src={Fro1} className="img-fluid" alt="login page" />
                    <div className="px-4 mt-4">
                      {/* <h4>Fully Responsive</h4>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit.
                      </p> */}
                    </div>
                  </div>
                  <div className="carousel-item">
                    <img src={Fro2} className="img-fluid" alt="login page" />
                    <div className="px-4 mt-4">
                      {/* <h4>Quality Code and Easy Customizability</h4>
                      <p>
                        There are many variations of passages of Lorem Ipsum
                        available.
                      </p> */}
                    </div>
                  </div>
                  <div className="carousel-item">
                    <img src={Fro3} className="img-fluid" alt="login page" />
                    <div className="px-4 mt-4">
                      {/* <h4>Cross Browser Compatibility</h4>
                      <p>
                        Overview We're a group of women who want to learn
                        JavaScript.
                      </p> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }}
    </Formik>
  );
};
export default LoginWithOTP;

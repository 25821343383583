import { Icon } from "@iconify/react";
import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Persona } from "../../Persona";
import Translate from "../../../translation/utils/translateFunction";
import { getTaskStatusColor } from "./utils";
import { useSelector } from "react-redux";
import { formatTime } from "../../../common/Utils/DateFormater";

const InboxItem = ({
  item,
  emailFilter,
  inboxList,
  newDate,
  onClickLabelAs,
}) => {
  const { taskStatusColor } = useSelector((state) => state.formanagement);
  const navigate = useNavigate();

  return (
    <>
      <div
        className="emailParentSection"
        onClick={() =>
          navigate("/inbox/viewEmailDetails", {
            state: {
              id: item?._id,
              type: emailFilter
            },
          })
        }
      >
        <div className="emailSectionOne">
          <div>
            {emailFilter === "INBOX" ? (
              <Persona
                name={
                  (item?.name > 0 && item?.name ? item?.name : item?.name) || ""
                }
                size={28}
              />
            ) : (
              <Persona
                name={
                  (item?.name > 0 && item?.name ? item?.name : item?.name) || ""
                }
                size={28}
              />
            )}
          </div>
          <div className="emailUserName">
            <span className=" ml-2 emailUserNameSpan">
              <span className=" ml-2 mt-1">{item?.name}</span>
            </span>
          </div>
          <div className="ml-2 emailSubject">
            <span className="emailUserNameSpan">
              <span className=" ml-3 mt-1">
                {item?.subject}
                {item?.body[0] && (
                  <span className="ml-1" style={{ color: "grey" }}>
                    - {item?.body[0]?.replace(/\0/g, "")}
                  </span>
                )}
              </span>
            </span>
          </div>
        </div>
        <div className="emailActions">
          <div>
            {item?.isActionable && !item?.isInformative && (
              <div className="taskStatusStyling taskCardContentStyling emailActionsColor">
                <span>{Translate("inboxPage:actionable")}</span>
              </div>
            )}
            {item?.isInformative && !item?.isActionable && (
              <div>
                <div className="taskStatusStyling taskCardContentStyling emailActionsColor">
                  <span>{Translate("inboxPage:informative")}</span>
                </div>
              </div>
            )}
          </div>

          <div
            className={`emailActionsIcons ${
              item?.taskStatus ? "displayTaskStatus" : "hideTaskStatus"
            }`}
          >
            <Icon
              icon="solar:check-circle-bold"
              style={{
                fontSize: 22,
                borderRadius: "50%",

                color: getTaskStatusColor(item.taskStatus, taskStatusColor),
              }}
            />
          </div>

          <div className="emailActionsDate">
            {moment(item?.date).format("YYYY-MM-DD") === newDate ? (
              <div>{formatTime(item?.date)}</div>
            ) : moment(item?.date).isSame(
                moment(newDate).subtract(1, "day"),
                "day"
              ) ? (
              <div>Yesterday</div>
            ) : (
              <div>{item?.formattedDate}</div>
            )}
          </div>
        </div>
      </div>
      {/* <div>
        {emailFilter === "INBOX" && (
          <div className="mb-1">
            <a
              href="/#"
              className="nav-link dropdown-toggle "
              data-toggle="dropdown"
              role="button"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i
                className="fa fa-ellipsis-v manageIconColor filterIconSize"
                data-toggle="tooltip"
                title={Translate("inboxPage:filter")}
              />
            </a>
            <div className="dropdown-menu dropdownAlignment">
              <div onClick={() => onClickLabelAs(item)}>
                <a className="dropdown-item">
                  {Translate(`inboxPage:labelAs`)}
                </a>
              </div>
            </div>
          </div>
        )}
      </div> */}
      {/* {emailFilter === "INBOX" && (
        <div className="mb-1">
          <a
            href="/#"
            className="nav-link dropdown-toggle "
            data-toggle="dropdown"
            role="button"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i
              className="fa fa-ellipsis-v manageIconColor filterIconSize"
              data-toggle="tooltip"
              title={Translate("inboxPage:filter")}
            />
          </a>
          <div className="dropdown-menu dropdownAlignment">
            <div onClick={() => onClickLabelAs(item)}>
              <a className="dropdown-item">{Translate(`inboxPage:labelAs`)}</a>
            </div>
          </div>
        </div>
      )} */}
    </>
  );
};
export default InboxItem;

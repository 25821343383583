export default {
  documentRepository: "بيانات الوثائق",
  viewFile: "عرض الملف",
  title: "العنوان",
  taskName: "اسم المهمة",
  description: "الوصف",
  attachment: "مرفق",
  assign: "تكليف مهمة",
  tooltip: "تحميل ملف",
  addDocument: "انشاء ملف",
  viewDocument: "عرض المستند",
  editDocument: "تعديل المستند",
  accessLogs: "السجلات",
  titleError: "العنوان حقل مطلوب",
  descriptionError: "الوصف حقل مطلوب",
  scan: "انشاء ملف",
  copy: "نسخ/لصق النص",
  save: "حفظ",
  createButton: "انشاء مهمة",
  accessButton: "سجلات الدخول",
  selectedCompanyMessage: "تم اختيار جميع الشركات",
  selectCompany: "اختر الشركة",
  selectCompanyMessage: "الرجاء اختيار شركة واحدة على الأقل",
  apiFailedMessage: "فشل في جلب البيانات",
  srNoTableHeading: "الأب رقم",
  documentTableHeading: "وثيقة",
  dateTimeTableHeading: "التاريخ و الوقت",
  documentSizeTableHeading: "حجم الوثيقة",
  sharedDocumentTableHeading: "وثيقة مشتركة",
  actionTableHeading: "فعل",
  userNameTableHeading: "اسم المستخدم",
  senderNameTableHeading: "اسم المرسل",
  designationTableHeading: "الوظيفة",
  companyNameTableHeading: "اسم الشركة",
  dateTableHeading: "التاريخ",
  timeStampsTableHeading: "الطابع الزمني",
  searchPlaceholder: "ابحث عن شيء...",
  myDocumentsSectionHeading: "ملفاتي",
  sharedWithMeSectionHeading: "التى تم مشاركتها معي",
  sharedByMeSectionHeading: "تمت مشاركته بواسطتي",
  fileLimit: "مسموح بملف واحد فقط.",
  addSuccess: "تمت إضافة المستند بنجاح.",
  attach: "أرفق ملف",
  accessLogHeading: "سجلات الدخول",
  dateAndTimeTableHeading: "التاريخ و الوقت",
  titleCharactersError:
    "يجب أن يحتوي العنوان على أبجدية رقمية مع واصلة ونقطة.",
  modalTitle: "مشاركة مع",
};

import React from "react";
import { StyledTypography } from "../../../common/Utils/StyledComponents";
import Translate from "../../../translation/utils/translateFunction";
import { Stack } from "@mui/material";

export const getMOMmyListingColumns = (
  CustomSrCellRender,
  CustomCellActionRender
) => {
  const columns = [
    {
      field: "lineNo",
      headerName: Translate("common:srNoTitle"),
      align: "center",
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      renderCell: CustomSrCellRender,
    },
    {
      field: "eventSubject",
      headerName: Translate("momPage:eventNameTitle"),
      flex: 0.3,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "eventDescription",
      headerName: Translate("momPage:eventDescriptionTitle"),
      flex: 0.3,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "creatorName",
      headerName: Translate("momPage:creatorName"),
      flex: 0.3,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "formattedDate",
      headerName: Translate("momPage:dateTitle"),
      flex: 0.3,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "eventTime",
      headerName: Translate("momPage:timeTitle"),
      flex: 0.3,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <Stack flexDirection={"row"} alignItems={"center"}>
            <StyledTypography component={"span"} sx={styles.textStyle}>
              {params?.row?.eventStartTime}
            </StyledTypography>
            -
            <StyledTypography component={"span"} sx={styles.textStyle}>
              {params?.row?.eventEndTime}
            </StyledTypography>
          </Stack>
        );
      },
    },
    {
      field: "Action",
      headerName: Translate("common:actionTitle"),
      flex: 0.4,
      sortable: false,
      disableColumnMenu: true,
      renderCell: CustomCellActionRender,
    },
  ];
  return columns;
};
const styles = {
  textStyle: {
    fontFamily: "Montserrat, sans-serif",
    color: "#2c2945",
    whiteSpace: "nowrap",
    fontSize: "0.875rem",
    paddingInline: "3px",
  },
};

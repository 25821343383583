import { Methods, Urls } from "../../common/Utils/ApiConstants";
import { CreateApi } from "../CreateApi";

const LeaveRequestCollection = CreateApi.injectEndpoints({
  endpoints: (builder) => ({
    addLeaveRequest: builder.mutation({
      query: (requestBody) => {
        return {
          url: Urls.applyLeave,
          method: Methods.post,
          body: requestBody,
        };
      },
    }),
    getLeaveRequest: builder.mutation({
      query: (requestBody) => {
        return {
          url: Urls.leaveList,
          method: Methods.post,
          body: requestBody,
        };
      },
    }),
    getMyLeaveRequest: builder.mutation({
      query: (requestBody) => {
        return {
          url: Urls.myLeaveRequest,
          method: Methods.post,
          body: requestBody,
        };
      },
    }),
    getMyLeaveDetail: builder.query({
      query: (requestBody) => {
        return {
          url: `${Urls.getMyLeaveDetail}${requestBody}`,
          method: Methods.get,
        };
      },
    }),
    approveDisapproveleave: builder.mutation({
      query: (requestBody) => {
        return {
          url: Urls.approveDisapproveleave,
          method: Methods.post,
          body: requestBody,
        };
      },
    }),
    disapproveleave: builder.mutation({
      query: (requestBody) => {
        return {
          url: Urls.disapproveleave,
          method: Methods.post,
          body: requestBody,
        };
      },
    }),
  }),
});

export const {
  useAddLeaveRequestMutation,
  useGetLeaveRequestMutation,
  useGetMyLeaveRequestMutation,
  useLazyGetMyLeaveDetailQuery,
  useApproveDisapproveleaveMutation,
  useDisapproveleaveMutation,
} = LeaveRequestCollection;

import { Card, Select, TextField, Typography, styled } from "@mui/material";
import { DataGrid, GridPagination } from "@mui/x-data-grid";
import { colors } from "../Theme/Colors";
export const FontFamily = "Montserrat,sans-serif";
export const StyledCard = styled(Card)({
  marginTop: "10px",
});
const isRtl = localStorage.getItem("isRtl");
export const StyledTypography = styled(Typography)({
  fontFamily: FontFamily,
});
export const StyledTextField = styled(TextField)({
  fontFamily: FontFamily,
  /* "& .css-1qxw4jt-MuiInputBase-root-MuiOutlinedInput-root": {
    padding: 0,
  }, */
  "& .MuiInputBase-root": {
    padding: 0,
  },
  "& .MuiInputBase-root > textarea:first-child": {
    padding: "8.5px 14px",
  },
  '& .MuiInputBase-input[type="number"]::-webkit-inner-spin-button, & .MuiInputBase-input[type="number"]::-webkit-outer-spin-button':
    {
      display: "none",
    },
});
export const StyledDataGrid = styled(DataGrid)({
  "&, [class^=css-2ybops-MuiDataGrid-root]": {
    borderWidth: "0px",
    borderStyle: "none",
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    whiteSpace: "normal",
    lineHeight: "normal",
    textTransform: "none",
    fontSize: 16,
    fontWeight: "500",
    fontFamily: FontFamily,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  "& .MuiDataGrid-cellContent": {
    fontFamily: FontFamily,
    color: colors.primary_009,
    whiteSpace: "nowrap",
  },
  "& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus": {
    outline: "none",
  },
});
export const StyledDropDown = styled(Select)({
  "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input": {
    padding: "7.5px 14px",
  },
  "& .css-qiwgdb": {
    padding: "7.5px 14px",
  },
});
export const StyledGridPagination = styled(GridPagination)({
  "& .MuiTablePagination-displayedRows": { display: "none" },
  "& .MuiPaginationItem-root.Mui-selected": {
    backgroundColor: colors.primary,
  },
  "& .css-1to7aaw-MuiButtonBase-root-MuiPaginationItem-root": {
    borderRadius: "0px",
    fontFamily: FontFamily,
  },
  "& .css-g2z002-MuiSvgIcon-root-MuiPaginationItem-icon": {
    transform: isRtl === "true" ? "rotate(180deg)" : "none",
  },
});

export const StyledFontAndColor = {
  fontFamily: FontFamily,
  color: colors.primary_009,
};

export const ErroLabelStyle = {
  color: "red",
  fontSize: 11,
  direction: "ltr",
};

import React from "react";
var crypto = require("crypto-js");

export const EncryptData = ({ reqObj }) => {
  const encryptionKey = crypto.enc.Base64.parse(
    process.env.REACT_APP_ENCRYPTION_KEY
  );
  const iv = crypto.enc.Base64.parse(process.env.REACT_APP_IV);
  const encryptedData = crypto.AES.encrypt(
    JSON.stringify(reqObj),
    encryptionKey,
    {
      iv: iv,
    }
  );
  return encryptedData.toString();
};

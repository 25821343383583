import React from "react";
import { colors } from "../../../../common/Theme/Colors";
import { styles } from "../../../TextField/TextField/index.Style";

export const InputTextField = ({
  autoComplete,
  RenderRightContainer,
  style,
  mode,
  containerStyles,
  placeholder,
  editable = true,
  enableBackground = false,
  innerRef,
  className,
  maxLength,
  ...props
}) => {
  const TextFieldWidth = 50;

  const textInputText = {
    color: editable || enableBackground ? colors.black : colors.primary_003,
  };

  return (
    <div>
      <div>
        <input
          className={className}
          ref={innerRef}
          {...props}
          placeholder={placeholder}
          style={(TextFieldWidth, styles.textInput, textInputText, style)}
          maxLength={maxLength}
        />
      </div>
    </div>
  );
};

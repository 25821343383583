import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { ReactComponent as UKanFlag } from "../../assets/images/flags/gb.svg";
import { ReactComponent as JordanFlag } from "../../assets/images/flags/jo.svg";
import { StyledTypography } from "../../common/Utils/StyledComponents";
import { useLazyLogoutQuery } from "../../request/Authentication";
import {
  rtlAction,
  selectCompanyAction,
  setFcmTokenAction,
  setNotificationBody,
  setNotificationLogout,
  userDataAction,
  userTypeAction,
} from "../../store/Reducer";
import Translate from "../../translation/utils/translateFunction";
import { useTrackChangeLanguageMutation } from "../../request/Header";
import { showToast } from "../../common/Utils/ToastMessage";
import Loader from "../staff/Owner/LoadingComp";
import { languageType } from "../../common/Utils/ApiConstants";

const Header = (props) => {
  const { isFixNavbar, isDarkHeader, isRtl, userData } = useSelector(
    (state) => state.formanagement
  );
  const [currentLanguage, setCurrentLanguage] = useState("");
  const [isLanguageLoading, setIsLanguageLoading] = useState(false);
  const dispatch = useDispatch();
  const [logout] = useLazyLogoutQuery();

  const logOut = async () => {
    logout({ _id: userData?._id }).then(async (res) => {
      if (res.isSuccess) {
        localStorage.removeItem("userData");
        dispatch(userDataAction(undefined));
        dispatch(userTypeAction(undefined));
        dispatch(setNotificationLogout(false));
        dispatch(setNotificationBody(undefined));
        dispatch(selectCompanyAction([]));
        dispatch(setFcmTokenAction(""));
      }
    });
  };

  const [trackChangeLanguage, { isLoading, isSuccess, isError, error }] =
    useTrackChangeLanguageMutation();

  useEffect(() => {
    if (isSuccess) {
      if (currentLanguage === languageType.english) {
        dispatch(rtlAction(false));
        localStorage.setItem("isRtl", "false");
      } else if (currentLanguage === languageType.arabic) {
        dispatch(rtlAction(true));
        localStorage.setItem("isRtl", "true");
      }
      window.location.reload();
      setIsLanguageLoading(false);
    }

    if (isError && error) {
      showToast({
        message:
          JSON.stringify(error?.data) || Translate("common:unknownServerError"),
        type: "error",
      });
      setIsLanguageLoading(false);
    }
  }, [isSuccess, isError, error]);

  const handleChangeLanguage = (lang) => {
    setIsLanguageLoading(true);
    setCurrentLanguage(lang);
    trackChangeLanguage({ language: lang });
  };

  return (
    <div
      id="page_top"
      className={`section-body ${isRtl ? "rtlMargin" : "ltrMargin"} ${
        isFixNavbar ? "sticky-top" : ""
      } ${isDarkHeader ? "top_dark" : ""}`}
      style={{
        marginBottom: props.dataFromSubParent == "Message" ? "0%" : "2%",
      }}>
      <div className="container-fluid">
        <div className="page-header">
          <div className="left">
            <StyledTypography
              variant="h5"
              sx={{ fontWeight: "500" }}
              color={"#02005b"}>
              {props.dataFromSubParent}
            </StyledTypography>
            {/* <h1 className="page-title">{this.props.dataFromSubParent}</h1>
                <select className="custom-select">
                  <option>Year</option>
                  <option>Month</option>
                  <option>Week</option>
                </select>
                <div className="input-group xs-hide">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search..."
                  />
                </div> */}
          </div>
          <div className="right">
            <ul className="nav nav-pills">
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  data-toggle="dropdown"
                  role="button"
                  aria-haspopup="true"
                  aria-expanded="false">
                  {Translate("common:language")}
                </a>
                <div className="dropdown-menu dropdownIconMenu">
                  <a
                    className={`dropdown-item ${!isRtl && "selectedItemStyle"}`}
                    onClick={() => {
                      isRtl && handleChangeLanguage(languageType.english);
                    }}>
                    <UKanFlag className="w20" />

                    {Translate("common:english")}
                  </a>
                  <div className="dropdown-divider" />
                  <a
                    className="dropdown-item"
                    onClick={() => {
                      !isRtl && handleChangeLanguage(languageType.arabic);
                    }}>
                    <JordanFlag className="w20" />

                    {Translate("common:arabic")}
                  </a>
                </div>
              </li>
              {/* <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    data-toggle="dropdown"
                    role="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Reports
                  </a>
                  <div className="dropdown-menu">
                    <a className="dropdown-item">
                      <i className="dropdown-icon fa fa-file-excel-o" /> MS
                      Excel
                    </a>
                    <a className="dropdown-item">
                      <i className="dropdown-icon fa fa-file-word-o" /> MS
                      Word
                    </a>
                    <a className="dropdown-item">
                      <i className="dropdown-icon fa fa-file-pdf-o" /> PDF
                    </a>
                  </div>
                </li>
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    data-toggle="dropdown"
                    role="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Project
                  </a>
                  <div className="dropdown-menu">
                    <a className="dropdown-item">Graphics Design</a>
                    <a className="dropdown-item">Angular Admin</a>
                    <a className="dropdown-item">PSD to HTML</a>
                    <a className="dropdown-item">iOs App Development</a>
                    <div className="dropdown-divider" />
                    <a className="dropdown-item">Home Development</a>
                    <a className="dropdown-item">New Blog post</a>
                  </div>
                </li> */}
            </ul>
            <div className="notification d-flex">
              {/* <div className="dropdown d-flex">
                        <a
                          href="/#"
                          className="nav-link icon d-none d-md-flex btn btn-default btn-icon ml-1"
                          data-toggle="dropdown"
                        >
                          <i className="fa fa-envelope" />
                          <span className="badge badge-success nav-unread" />
                        </a>
                        <div className="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                          <ul className="right_chat list-unstyled w250 p-0">
                            <li className="online">
                              <a href="fake_url">
                                <div className="media">
                                  <img
                                    className="media-object "
                                    src="../assets/images/xs/avatar4.jpg"
                                    alt="fake_url"
                                  />
                                  <div className="media-body">
                                    <span className="name">Donald Gardner</span>
                                    <span className="message">
                                      Designer, Blogger
                                    </span>
                                    <span className="badge badge-outline status" />
                                  </div>
                                </div>
                              </a>
                            </li>
                            <li className="online">
                              <a href="fake_url">
                                <div className="media">
                                  <img
                                    className="media-object "
                                    src="../assets/images/xs/avatar5.jpg"
                                    alt="fake_url"
                                  />
                                  <div className="media-body">
                                    <span className="name">Wendy Keen</span>
                                    <span className="message">Java Developer</span>
                                    <span className="badge badge-outline status" />
                                  </div>
                                </div>
                              </a>
                            </li>
                            <li className="offline">
                              <a href="fake_url">
                                <div className="media">
                                  <img
                                    className="media-object "
                                    src="../assets/images/xs/avatar2.jpg"
                                    alt="fake_url"
                                  />
                                  <div className="media-body">
                                    <span className="name">Matt Rosales</span>
                                    <span className="message">CEO, Epic Theme</span>
                                    <span className="badge badge-outline status" />
                                  </div>
                                </div>
                              </a>
                            </li>
                            <li className="online">
                              <a href="fake_url">
                                <div className="media">
                                  <img
                                    className="media-object "
                                    src="../assets/images/xs/avatar3.jpg"
                                    alt="fake_url"
                                  />
                                  <div className="media-body">
                                    <span className="name">Phillip Smith</span>
                                    <span className="message">
                                      Writter, Mag Editor
                                    </span>
                                    <span className="badge badge-outline status" />
                                  </div>
                                </div>
                              </a>
                            </li>
                          </ul>
                          <div className="dropdown-divider" />
                          <a className="dropdown-item text-center text-muted-dark readall">
                            Mark all as read
                          </a>
                        </div>
                      </div> */}
              {/* <div className="dropdown d-flex">
                  <a
                    href="/#"
                    className="nav-link icon d-none d-md-flex btn btn-default btn-icon ml-1"
                    data-toggle="dropdown">
                    <i className="fa fa-bell" />
                    <span className="badge badge-primary nav-unread" />
                  </a>
                  <div className="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                    <ul className="list-unstyled feeds_widget">
                      <li>
                        <div className="feeds-left">
                          <i className="fa fa-check" />
                        </div>
                        <div className="feeds-body">
                          <h4 className="title text-danger">
                            Issue Fixed{" "}
                            <small className="float-right text-muted">
                              11:05
                            </small>
                          </h4>
                          <small>
                            WE have fix all Design bug with Responsive
                          </small>
                        </div>
                      </li>
                      <li>
                        <div className="feeds-left">
                          <i className="fa fa-user" />
                        </div>
                        <div className="feeds-body">
                          <h4 className="title">
                            New User{" "}
                            <small className="float-right text-muted">
                              10:45
                            </small>
                          </h4>
                          <small>I feel great! Thanks team</small>
                        </div>
                      </li>
                      <li>
                        <div className="feeds-left">
                          <i className="fa fa-thumbs-o-up" />
                        </div>
                        <div className="feeds-body">
                          <h4 className="title">
                            7 New Feedback{" "}
                            <small className="float-right text-muted">
                              Today
                            </small>
                          </h4>
                          <small>
                            It will give a smart finishing to your site
                          </small>
                        </div>
                      </li>
                      <li>
                        <div className="feeds-left">
                          <i className="fa fa-question-circle" />
                        </div>
                        <div className="feeds-body">
                          <h4 className="title text-warning">
                            Server Warning{" "}
                            <small className="float-right text-muted">
                              10:50
                            </small>
                          </h4>
                          <small>Your connection is not private</small>
                        </div>
                      </li>
                      <li>
                        <div className="feeds-left">
                          <i className="fa fa-shopping-cart" />
                        </div>
                        <div className="feeds-body">
                          <h4 className="title">
                            7 New Orders{" "}
                            <small className="float-right text-muted">
                              11:35
                            </small>
                          </h4>
                          <small>You received a new oder from Tina.</small>
                        </div>
                      </li>
                    </ul>
                    <div className="dropdown-divider" />
                    <a
                      href="fake_url"
                      className="dropdown-item text-center text-muted-dark readall">
                      Mark all as read
                    </a>
                  </div>
                </div> */}
              <div className="dropdown d-flex">
                <a
                  href="/#"
                  className="nav-link icon d-none d-md-flex btn btn-default btn-icon ml-1"
                  data-toggle="dropdown">
                  <i className="fe fe-user fontSize-18" />
                </a>
                <div className="dropdown-menu dropdown-menu-right dropdown-menu-arrow ">
                  <NavLink
                    to="/profile"
                    className="dropdown-item profileActive">
                    <i className="dropdown-icon fe fe-user" />
                    {Translate("common:account")}
                  </NavLink>
                  {/* <a className="dropdown-item">
                          <span className="float-right">
                            <span className="badge badge-primary">6</span>
                          </span>
                          <i className="dropdown-icon fe fe-mail" /> Inbox
                        </a>
                        <a className="dropdown-item">
                          <i className="dropdown-icon fe fe-send" /> Message
                        </a> */}
                  <div className="dropdown-divider" />
                  {/* <a className="dropdown-item">
                      <i className="dropdown-icon fe fe-help-circle" /> Need
                      help?
                    </a> */}
                  <NavLink
                    to="#"
                    className="dropdown-item logoutActive"
                    onClick={logOut}>
                    <i className="dropdown-icon fe fe-log-out" />
                    {Translate("common:logOut")}
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Loader loading={isLoading || isLanguageLoading} />
    </div>
  );
};

export default Header;

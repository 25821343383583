import * as yup from "yup";
import Translate from "../../../../translation/utils/translateFunction";
import { validateMobileSchema } from "../../../../common/Utils/commonFunction";

export const AddHrOfficerSchema = () => {
  const schema = yup.object().shape({
    companyId: yup
      .array()
      .of(yup.string().trim())
      .required(Translate("addOwner:companyError"))
      .min(1, Translate("addOwner:companyError")),
    name: yup
      .string()
      .trim()
      .required(Translate("addPersonalAssistant:nameError")),
    staff: yup.string().trim().required(Translate("addHrOfficer:staffError")),
    dob: yup
      .string()
      .trim()
      .required(Translate("addPersonalAssistant:dobError")),
    designation: yup
      .string()
      .trim()
      .required(Translate("addPersonalAssistant:designationError")),
    department: yup
      .string()
      .trim()
      .required(Translate("addPersonalAssistant:departmentError")),
    ...validateMobileSchema(
      "mobile",
      Translate("addPersonalAssistant:mobileError")
    ),
    email: yup
      .string()
      .email(Translate("addPersonalAssistant:workEmailErrorInvalid"))
      .trim()
      .required(Translate("addPersonalAssistant:workEmailError")),
    resAddress: yup
      .string()
      .trim()
      .required(Translate("addPersonalAssistant:addressError")),
    workAddress: yup
      .string()
      .trim()
      .required(Translate("addPersonalAssistant:workAddressError")),
    resCountry: yup
      .string()
      .trim()
      .required(Translate("addManager:countryError")),
    resState: yup.string().trim().required(Translate("addManager:stateError")),
    workCountry: yup
      .string()
      .trim()
      .required(Translate("addManager:countryError")),
    workState: yup.string().trim().required(Translate("addManager:stateError")),
  });

  return schema;
};

import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as BrandLogo } from "../../../assets/images/FoM_logo.svg";
import Translate from "../../../translation/utils/translateFunction";
import { FormikTextField } from "../../FormikForm/component/FormikTextField";

import Fro1 from "../../../assets/images/Illustration_1.svg";
import Fro2 from "../../../assets/images/Illustration_2.svg";
import Fro3 from "../../../assets/images/Illustration_3.svg";
import Loader from "../../staff/Owner/LoadingComp";
import { ForgotPasswordInitialValues } from "../constants";
import { ForgotPasswordSchema } from "../utils";
import VerifyOTP from "./VerifyOtp";
import SetNewPassword from "./SetNewPassword";
import {
  useGetVerifyEmailOTPMutation,
  useSendEmailOTPMutation,
} from "../../../request/Authentication";
import { showToast } from "../../../common/Utils/ToastMessage";
import { colors } from "../../../common/Theme/Colors";
import { StyledTypography } from "../../../common/Utils/StyledComponents";
import { Stack } from "@mui/material";
import { handleEventKeySubmit } from "../../../common/Utils/commonFunction";

const Forgotpassword = ({
  setLoginWithOTP,
  loginUser,
  resetForm,
  isLoading,
  consent,
}) => {
  const navigate = useNavigate();
  const [verifyEmail, setVerifyEmail] = useState(false);
  const [isVerifedEmail, setIsVerifiedEmail] = useState(false);
  const [otp, setOtp] = useState("");
  const formikRef = useRef(null);

  const [
    sendEmailOTPApi,
    {
      isLoading: issendEamilOtpLoading,
      isError: isErrorsendEamilOtp,
      isSuccess: isSuccessSendEamilOtp,
      error: errorSendEamilOtp,
      data: sendEamilOTP,
    },
  ] = useSendEmailOTPMutation();

  useEffect(() => {
    if (isSuccessSendEamilOtp && sendEamilOTP?.success) {
      showToast({ message: sendEamilOTP?.message, type: "success" });
      setVerifyEmail(true);
    } else if (isSuccessSendEamilOtp && !sendEamilOTP?.success) {
      showToast({ message: sendEamilOTP?.message, type: "info" });
    } else if (isErrorsendEamilOtp) {
      showToast({ message: JSON.stringify(errorSendEamilOtp), type: "error" });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessSendEamilOtp, sendEamilOTP, errorSendEamilOtp]);

  const [
    verifyEmailOTPApi,
    {
      isLoading: isVerifyEamilOtpLoading,
      isError: isErrorVerifysendEamilOtp,
      isSuccess: isSuccessVerifyEamilOtp,
      error: errorVerifyEamilOtp,
      data: verifyEamilOTP,
      // isFetching,
    },
  ] = useGetVerifyEmailOTPMutation();

  useEffect(() => {
    if (isSuccessVerifyEamilOtp && verifyEamilOTP?.success) {
      showToast({ message: verifyEamilOTP?.message, type: "success" });
      setIsVerifiedEmail(true);
      setVerifyEmail(false);
      setOtp("");
    } else if (isSuccessVerifyEamilOtp && !verifyEamilOTP?.success) {
      showToast({ message: verifyEamilOTP?.message, type: "info" });
    } else if (isErrorVerifysendEamilOtp && errorVerifyEamilOtp) {
      showToast({
        message: errorVerifyEamilOtp?.data?.error?.[0].msg,
        type: "error",
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessVerifyEamilOtp, verifyEamilOTP, errorVerifyEamilOtp]);

  useEffect(() => {
    if (resetForm) {
      console.log("resetForm", resetForm);
      formikRef.current.resetForm();
    }
  }, [resetForm]);
  const onSubmit = (companyFormValues) => {
    if (companyFormValues?.existingemail && !verifyEmail) {
      sendEmailOTPApi({
        email: companyFormValues?.existingemail.trim(),
      });
    }
    if (otp && verifyEmail) {
      verifyEmailOTPApi({
        email: companyFormValues?.existingemail.trim(),
        otp: otp,
      });
    }
  };

  const handleBackNavigation = () => navigate("/");

  return (
    <Formik
      initialValues={ForgotPasswordInitialValues}
      innerRef={formikRef}
      validateOnMount
      onSubmit={onSubmit}
      validationSchema={ForgotPasswordSchema}
      validateOnChange
    >
      {({ handleSubmit, values }) => {
        return (
          <div className="auth">
            <div className="auth_left">
              {verifyEmail && (
                <VerifyOTP
                  isForgotPassword
                  handleSubmit={handleSubmit}
                  otp={otp}
                  setOtp={setOtp}
                  resendOtp={() =>
                    sendEmailOTPApi({ email: values?.existingemail })
                  }
                  mobileNo={values.existingemail}
                  isLoading={isVerifyEamilOtpLoading}
                  onGoBack={(value) => {
                    setVerifyEmail(value);
                    setOtp("");
                  }}
                />
              )}
              {!verifyEmail && !isVerifedEmail && (
                <div className="card" style={{ zIndex: 999 }}>
                  <div className="text-center mb-30">
                    <Link className="header-brand" to="/">
                      <BrandLogo className="forLogo"></BrandLogo>
                    </Link>
                  </div>

                  <div className="card-body">
                    <div className="card-title" style={{ padding: "0px 7px" }}>
                      {Translate("forgotPassword:forgotPassword")}
                    </div>
                    <p className="text-muted" style={{ padding: "0px 7px" }}>
                      {Translate("forgotPassword:titleText_1")}
                    </p>
                    <div
                      className="form-group col-md-12"
                      onKeyDown={(e) => handleEventKeySubmit(e, handleSubmit)}
                    >
                      <FormikTextField
                        name="existingemail"
                        label={Translate("login:email")}
                        placeholder={Translate("login:emailPlaceholder")}
                        autoComplete={"off"}
                        autoFocus
                      />
                    </div>

                    <div style={{ marginTop: "3rem" }}>
                      <button
                        type="submit"
                        className="btn btn-primary btn-block"
                        onClick={() => {
                          !isLoading && handleSubmit();
                        }}
                      >
                        {isLoading ? (
                          <Loader
                            loading={isLoading}
                            isListLoader
                            message={""}
                          />
                        ) : (
                          Translate("forgotPassword:next")
                        )}
                      </button>
                    </div>
                    <StyledTypography
                      mt={"30px"}
                      fontWeight={"500"}
                      tabIndex="0"
                      onKeyDown={(e) => handleEventKeySubmit(e, handleBackNavigation)}
                      onClick={handleBackNavigation}
                      className="text-center text-primary cursor-pointer"
                    >
                      {Translate("login:emailLogin")}
                    </StyledTypography>

                    <Stack mt={"1.5rem"}>
                      <StyledTypography variant="subtitle2">
                        {Translate("login:termsAndConditions5")}
                      </StyledTypography>
                      <Stack flexDirection={"row"}>
                        <StyledTypography
                          className="cursor-pointer"
                          variant="subtitle2"
                          sx={{ color: colors.primary_010 }}
                          // onClick={() => navigate("/termsConditions")}
                          onClick={() => navigate("/termsOfServices")}
                        >
                          {Translate("login:termsAndConditions2")}
                        </StyledTypography>
                        <StyledTypography variant="subtitle2" mx={"4px"}>
                          {Translate("login:termsAndConditions3")}
                        </StyledTypography>
                        <StyledTypography
                          className="cursor-pointer"
                          variant="subtitle2"
                          sx={{ color: colors.primary_010 }}
                          // onClick={() => navigate("/privacy")}
                          onClick={() => navigate("/privacyPolicy")}
                        >
                          {Translate("login:termsAndConditions4")}
                        </StyledTypography>
                      </Stack>
                    </Stack>
                  </div>
                </div>
              )}
              {isVerifedEmail && !verifyEmail && (
                <SetNewPassword
                  email={values?.existingemail}
                  goBack={setIsVerifiedEmail}
                  setVerifyEmail={setVerifyEmail}
                />
              )}
            </div>

            <div className="auth_right">
              <div
                className="carousel slide"
                data-ride="carousel"
                data-interval={3000}
              >
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <img src={Fro1} className="img-fluid" alt="login page" />

                    <div className="px-4 mt-4">
                      {/* <h4>Fully Responsive</h4>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit.
                      </p> */}
                    </div>
                  </div>
                  <div className="carousel-item">
                    <img src={Fro2} className="img-fluid" alt="login page" />
                    <div className="px-4 mt-4">
                      {/* <h4>Quality Code and Easy Customizability</h4>
                      <p>
                        There are many variations of passages of Lorem Ipsum
                        available.
                      </p> */}
                    </div>
                  </div>
                  <div className="carousel-item">
                    <img src={Fro3} className="img-fluid" alt="login page" />
                    <div className="px-4 mt-4">
                      {/* <h4>Cross Browser Compatibility</h4>
                      <p>
                        Overview We're a group of women who want to learn
                        JavaScript.
                      </p> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }}
    </Formik>
  );
};
export default Forgotpassword;

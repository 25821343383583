import React, { memo, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Translate from "../../translation/utils/translateFunction";
import NoDataFound from "../ManageTask/NoDataFound";

const SortingFilter = ({
  showModal,
  submitModal,
  handleClose,
  sortingOptions,
  showClearAll,
  selectedSortOption,
}) => {
  const [selectedSort, setSelectedSort] = useState(
    selectedSortOption || sortingOptions[0]?.value || ""
  );
  const handleSortingChange = (value) => {
    setSelectedSort(value);
  };

  const Checkbox = ({ item }) => {
    return (
      <>
        <div style={{ display: "inline-block" }}>
          <input
            type="checkbox"
            name="role"
            id={item.value}
            checked={selectedSort === item.value}
            onChange={() => handleSortingChange(item.value)}
          />
          <label className="form-check-label">{item.label}</label>
        </div>
      </>
    );
  };
  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      scrollable={true}
      className="col-span-3 col-md-12">
      <Modal.Header closeButton>
        <Modal.Title>{Translate("common:filterTitle")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="sortingFilterTitleBox">
          <p>{Translate("common:sortByTitle")}</p>
          {showClearAll && (
            <p
              className="sortingClearAll cursor-pointer"
              onClick={() => setSelectedSort(sortingOptions[0]?.value)}>
              {Translate("common:clearAllTitle")}
            </p>
          )}
        </div>
        <div
          className="table-responsive todo_list over-flow-md scrollBox"
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}>
          <table className="table table-hover table-striped table-vcenter mb-0">
            <tbody>
              {sortingOptions?.length > 0 ? (
                sortingOptions.map((item, index) => (
                  <tr key={`${index}_${item.value}`}>
                    <td key={index} className="tdStyling">
                      <Checkbox item={item} />
                    </td>
                  </tr>
                ))
              ) : (
                <div className="NoDataDisplayStyling mt-4">
                  <tr>
                    <td className="displayNodata tdStyling">
                      <span>
                        <NoDataFound
                          message={Translate("manageTask:noCompany")}
                        />
                      </span>
                    </td>
                  </tr>
                </div>
              )}
            </tbody>
          </table>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="btn btn-white addButton"
          onClick={() => {
            setSelectedSort(selectedSortOption || sortingOptions[0]?.value);
            handleClose();
          }}>
          {Translate("common:close")}
        </Button>
        <Button
          variant={`primary saveButton`}
          onClick={() => submitModal(selectedSort)}>
          {Translate("common:apply")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default memo(SortingFilter);

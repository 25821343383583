export default {
    skip: "تخطي",
    walkthrough: "إدارة المهام بكل سهولة",
    enablePermission: "وافق على منح الإذن",
    contacts: "جهات الإتصال",
    storage: "التخزين",
    camera: "الكاميرا",
    gallery: "الاستوديو",
    text: "أنشئ عددًا غير محدود من المشاريع والمهام والأهداف لفريقك واعرض بسهولة الشخص المسؤول عن كل مبادرة.",
    allow: "متابعة",
    welcome: "مرحباً بك!",
    signIn: "تسجيل دخول",
    email: "ايميل",
    emailPlaceholder: "ادخل الإيميل الخاص بك",
    password: "كلمة السر",
    passwordPlaceholder: "ادخل كلمة السر الخاصة بك",
    forgotPassword: "نسيت كلمة السر؟",
    login: "تسجيل دخول",
    emailError: "الايميل حقل مطلوب",
    emailError1: "الايميل غير فعّال",
    emailError2: "بيانات الاعتماد غير صحيحة",
    mobileError1: "رقم الموبايل حقل مطلوب",
    mobileError2: "رقم الموبايل غير صحيح",
    passwordError: "كلمة السر حقل مطلوب",
    passwordError1: "كلمة السر غير صحيحة",
    termsAndConditions1: "عند نقر تسجيل فأنت توافق على",
    termsAndConditions2: "أحكام الخدمة",
    termsAndConditions3: "و",
    termsAndConditions4: "سياسة الخصوصية",
    termsAndConditions5: "عند النقر على متابعة فأنت توافق على",
    or: "أو",
    loginWithOTP: "تسجيل الدخول من خلال رمز ال OTP",
    selectLanguage: "اختر لغة",
    proceed: "متابعة",
    mobileNo: "رقم الموبايل",
    mobileNoPlaceholder: "ادخل رقم الموبايل الخاص بك",
    loginTo: "مرحباً بك!",
    otp: "تسجيل الدخول من خلال رمز ال OTP",
    emailLogin: "تسجيل الدخول باستخدام البريد الإلكتروني",
    consentTitle: "تسجيل الدخول الحالي فعّال",
    consentDesc1: "أنت استخدمت مسبقاُ هذا التطبيق او الجهاز",
    consentDesc2:
      "هل ترغب في البقاء مسجلاً الدخول باستخدام نفس الجهاز أم الاستمرار باستخدام هذا الجهاز؟ إذا اخترت المتابعة، فسيتم تسجيل خروجك من الجهاز الآخر",
  };
  
import dayjs from "dayjs";
import { Formik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { DateTimeFormats } from "../../../common/Utils/DateFormater";
import { showToast } from "../../../common/Utils/ToastMessage";
import { useEditEventMutation } from "../../../request/Calendar";
import {
  InitialEventRecurrence,
  InitialValues,
} from "../CreateEvent/constants";
import { CreateEvenetValidationSchema } from "../CreateEvent/validation";
import CreateEventForm from "../components/CreateEventform";

const EditEvent = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { userData } = useSelector((state) => state?.formanagement);
  const [eventRecurrence, setEventRecurrence] = useState(
    InitialEventRecurrence
  );
  const [offsetValue, setOffsetValue] = useState();
  const [timeZoneValue, setTimezoneValue] = useState();
  const [startDateUTCObject, setStartDateUTCObject] = useState();
  const [endDateUTCObject, setEndDateUTCObject] = useState();
  const [startTimeUTCObject, setStartTimeUTCObject] = useState();
  const [endTimeUTCObject, setEndTimeUTCObject] = useState();
  const [privateToggle, setPrivateToggle] = useState(false);
  const [inviteeMembers, setInviteeMembers] = useState([]);
  const [timeZoneName, setTimeZoneName] = useState();

  const [
    editEvent,
    {
      data: editEventData,
      isSuccess: isEditEventSuccess,
      isLoading: isEditEventLoading,
      error: editEventError,
      isError: isEditEventError,
    },
  ] = useEditEventMutation();
  useEffect(() => {
    if (isEditEventSuccess) {
      showToast({ message: editEventData?.message, type: "success" });
      setEventRecurrence(InitialEventRecurrence);
      //   dispatch(setInviteeMembers([]));
      navigate(-2);
    } else {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditEventSuccess]);

  const convertDueDateUTC = (startDate, dueDate) => {
    let startDatePostFix = startDate.toString().split("T")[0];
    let dueDatePostFix = dueDate.toString().split("T")[1];
    let lastDueDate = dueDatePostFix.slice(0, 5) + ':00.000Z';
    let finalDueDate = startDatePostFix + "T" + lastDueDate;
    return finalDueDate;
  };
  const onSubmit = (values) => {
    let bodyObj = {
      companyId: [values?.companyId],
      userId: userData?._id,
      subject: values.subject.replace(/\n/g, ""),
      description: values.description,
      timezone: timeZoneName,
      utcOffset: offsetValue,
      startDate: moment(values.fromDate).format(DateTimeFormats.YearMonthDay),
      endDate: moment(values.toDate).format(DateTimeFormats.YearMonthDay),
      startTime: dayjs(values.startTime).format(DateTimeFormats.Time),
      endTime: dayjs(values.endtime).format(DateTimeFormats.Time),
      startDateUTCObject: startTimeUTCObject
        ? convertDueDateUTC(startDateUTCObject, startTimeUTCObject)
        : startDateUTCObject,
      endDateUTCObject: endTimeUTCObject
        ? convertDueDateUTC(endDateUTCObject, endTimeUTCObject)
        : endDateUTCObject,
      venue: values.venu,
      reminderType: values.reminder,
      repeatEvent: values.repeatEvent,
      isPrivate: privateToggle,
      invitedUsers:
        inviteeMembers !== undefined || inviteeMembers?.length > 0
          ? inviteeMembers
          : [],
      repeatEveryNumber:
        eventRecurrence?.repeatEveryNumber && values.repeatEvent === "CUSTOM"
          ? eventRecurrence?.repeatEveryNumber
          : "",
      repeatEvery:
        eventRecurrence?.repeatEvery && values.repeatEvent === "CUSTOM"
          ? eventRecurrence?.repeatEvery
          : "",
      repeatWeek:
        eventRecurrence?.repeatWeek &&
        eventRecurrence?.repeatEvery === "WEEK" &&
        values.repeatEvent === "CUSTOM"
          ? eventRecurrence?.repeatWeek
          : {
              sunday: false,
              monday: false,
              tuesday: false,
              wednesday: false,
              thursday: false,
              friday: false,
              saturday: false,
            },
      repeatFrom:
        eventRecurrence?.repeatEvery !== "WEEK" &&
        values.repeatEvent === "CUSTOM"
          ? eventRecurrence?.repeatFromUTCObject
          : "",
      repeatEnd:
        eventRecurrence?.repeatEnd && values.repeatEvent === "CUSTOM"
          ? {
              never: eventRecurrence?.repeatEnd?.never,
              on: eventRecurrence?.repeatEnd?.on
                ? convertDueDateUTC(
                    eventRecurrence?.repeatEnd?.on,
                    endTimeUTCObject
                  )
                : "",
              occurance: eventRecurrence?.repeatEnd?.occurance,
            }
          : {
              never: false,
              on: "",
              occurance: "",
            },
    };
    if (state.edit) {
      bodyObj = { ...bodyObj, eventId: state.eventData._id };
    }
    if (bodyObj.repeatEvent === "CUSTOM" && bodyObj.repeatEveryNumber <= 0) {
      showToast({ message: "Please add custom values", type: "error" });
    } else {
      editEvent(bodyObj);
    }
  };
  return (
    <Formik
      initialValues={InitialValues}
      validateOnMount
      onSubmit={onSubmit}
      validationSchema={CreateEvenetValidationSchema}>
      {({ handleSubmit }) => {
        return (
          <CreateEventForm
            handleSubmit={handleSubmit}
            eventData={state.eventData}
            setEventRecurrence={setEventRecurrence}
            eventRecurrence={eventRecurrence}
            setOffsetValue={setOffsetValue}
            setStartDateUTCObject={setStartDateUTCObject}
            setEndDateUTCObject={setEndDateUTCObject}
            setStartTimeUTCObject={setStartTimeUTCObject}
            startTimeUTCObject={startTimeUTCObject}
            setEndTimeUTCObject={setEndTimeUTCObject}
            setPrivateToggle={setPrivateToggle}
            privateToggle={privateToggle}
            isLoading={isEditEventLoading}
            setInviteeList={setInviteeMembers}
            eventInviteeList={inviteeMembers}
            setTimezoneValue={setTimezoneValue}
            timeZoneValue={timeZoneValue}
            setTimeZoneName={setTimeZoneName}
            error={editEventError}
            isError={isEditEventError}
          />
        );
      }}
    </Formik>
  );
};
export default EditEvent;

import { Grid, Stack } from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import {
  StyledCard,
  StyledTypography,
} from "../../../common/Utils/StyledComponents";
import { showToast } from "../../../common/Utils/ToastMessage";
import { userTypes } from "../../../common/users/userTypes";
import { useGetStaffManagerListingMutation } from "../../../request/Staff/Manager";
import Translate from "../../../translation/utils/translateFunction";
import CompanyListing from "../../ContactRepository/component";
import ListView from "../../ListView";
import { getManagerColumns } from "./Components/column.data";
import CompanyHeader from "../../../common/CompanyHeader";
import Searchbar from "../../common/Searchbar";
import { checkModuleAccess } from "../../../common/Utils/commonFunction";

const ManagerListing = ({ fixNavbar }) => {
  const navigate = useNavigate();
  const { selectedCompanyList, userData, isRtl } = useSelector(
    (state) => state.formanagement
  );
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const [managerDataFetched, setmanagerDataFetched] = useState([]);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [selectedColumn, setSelectedColumn] = useState("name");
  const [sortBy, setSortBy] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const lastPage = useRef(0);

  const [
    getManagerListApi,
    {
      data: managerDataList,
      isLoading: isLoadingManagerData,
      isSuccess: successManagerList,
      isError: isManagerError,
      error: managerListError,
    },
  ] = useGetStaffManagerListingMutation();

  const requestObj = useMemo(() => {
    const companies = selectedCompanyList?.length
      ? selectedCompanyList?.map((item) => item?.value)
      : [];
    return companies?.length
      ? sortBy !== null && sortBy !== undefined
        ? {
            companies,
            searchValue: search,
            sortCoulmn: selectedColumn,
            sortDirection: sortBy ? 1 : -1,
            pageNo: currentPageNo,
          }
        : {
            companies,
            searchValue: search,
            sortCoulmn: "name",
            sortDirection: 1,
            pageNo: currentPageNo,
          }
      : null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageNo, search, sortBy, selectedCompanyList]);

  const debouncedSearch = useDebouncedCallback(() => {
    sortBy !== undefined &&
      getManagerListApi({ requestBody: requestObj, pageNo: currentPageNo });
  }, 500);

  useEffect(() => {
    setmanagerDataFetched([]);

    if (selectedCompanyList?.length) {
      sortBy === null && setLoading(true);

      if (requestObj?.searchValue?.length) {
        debouncedSearch();
      } else {
        const finalRequestObj = {
          requestBody: requestObj,
          pageNo: currentPageNo,
        };

        sortBy !== undefined && getManagerListApi(finalRequestObj);
      }
    } else {
      showToast({
        type: "info",
        message: Translate("addManager:selectCompanyMessage"),
      });
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestObj]);

  useEffect(() => {
    if (managerDataList?.data?.nodes) {
      const { nodes, pageInfo } = managerDataList.data;
      setmanagerDataFetched(nodes);
      lastPage.current = pageInfo.lastPageNo;
      setLoading(false);
    }
    if (managerListError && isManagerError) {
      managerListError?.originalStatus !== 500 &&
        showToast({
          message: JSON.stringify(managerListError.data),
          type: "error",
        });
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [managerDataList, successManagerList]);

  const debounced = useDebouncedCallback((columnName) => {
    selectedColumn !== columnName ? setSortBy(true) : setSortBy(!sortBy);
  }, 500);

  const getSort = (columnName = "") => {
    setLoading(true);
    selectedColumn !== columnName && setSortBy(undefined);
    debounced(columnName);
    setSelectedColumn(columnName);
  };

  const CustomActionCellRender = (params) => {
    return (
      <td>
        <i
          className="m-2 fa fa-eye cursor-pointer manageIconColor"
          onClick={() =>
            navigate("/viewManager", { state: params?.row?.userId })
          }
          data-toggle="tooltip"
          title={Translate("common:viewToolTip")}
        ></i>
      </td>
    );
  };

  const rowsPerPage = 10;
  const startSerialNumber = (currentPageNo - 1) * rowsPerPage + 1;

  const rowsWithSerialNumbers = managerDataFetched.map((row, index) => ({
    ...row,
    serialNumber: startSerialNumber + index,
  }));

  const handleSearchText = (value) => {
    setCurrentPageNo(1);
    setSearch(value);
  };

  return (
    <Grid
      item
      xs={12}
      className={`section-body ${isRtl ? "rtlMargin" : "ltrMargin"} ${
        fixNavbar ? "marginTop" : ""
      } `}
    >
      <StyledCard variant="outlined">
        <div className="container-fluid p-4">
          <div className="tab-content">
            <div>
              <div className="card">
                <div className="card-header card-header-spacing">
                  <CompanyHeader setShowModal={setShowModal} />
                  <div className="card-options">
                    <div className="input-group">
                      <Searchbar
                        handleSearchText={handleSearchText}
                        setSearch={setSearch}
                      />
                    </div>
                    {checkModuleAccess(userData?.add, userTypes?.Manager) && (
                      <div className="header-action pull-right ml-2">
                        <button
                          type="button"
                          className="btn btn-primary py-2"
                          onClick={() => navigate("/addManager")}
                        >
                          <i className="fe fe-plus" />
                          <StyledTypography className="mx-1" component="span">
                            {Translate("common:add")}
                          </StyledTypography>
                        </button>
                      </div>
                    )}
                  </div>
                </div>
                <ListView
                  columns={getManagerColumns(CustomActionCellRender)}
                  rows={rowsWithSerialNumbers}
                  autoHeight
                  sortingMode="server"
                  sortBy={sortBy}
                  onPaginationChange={setCurrentPageNo}
                  currentPage={currentPageNo}
                  showShimmer={isLoadingManagerData}
                  showLoader={loading || isLoadingManagerData}
                  totalPageCount={lastPage.current}
                  onSortModelChange={(data) =>
                    data?.length && getSort(data[0]?.field)
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <CompanyListing
          handleModal={(value) => {
            setCurrentPageNo(1);
            setShowModal(value);
          }}
          showModal={showModal}
          selectedCompanyList={selectedCompanyList}
        />
      </StyledCard>
    </Grid>
  );
};
export default ManagerListing;

export default {
  head: "انشاء حدث",
  subject: "العنوان",
  subjectPlaceholder: "إضافة عنوان",
  description: "الوصف",
  descriptionPlaceholder: "إضافة وصف",
  date: "التاريخ",
  from: "من تاريخ",
  to: "الى تاريخ",
  time: "الوقت",
  startTime: "توقيت البدء",
  endtime: "وقت النهاية",
  venue: "المكان",
  invited: "تمت الدعوة",
  venuePlaceholder: "إضافة وصف",
  dropdownPlaceholder: "إختر المنطقة الزمنية",
  subjectError: "العنوان حقل مطلوب",
  descriptionError: "الوصف حقل مطلوب",
  fromDateError: "من التاريخ مطلوب",
  toDateError: "مطلوب حتى الآن",
  timezoneError: "المنطقة الزمنية حقل مطلوب",
  startTimeError: "توقيت البدء حقل مطلوب",
  endTimeError: "وقت الانتهاء مطلوب",
  venueError: "المكان حقل مطلوب",
  edit: "تحرير تفاصيل الحدث",
  alert: "هل أنت متأكد من حذف الحدث",
  timezone: "المنطقة الزمنية",
  repeatEvent: "تكرار الحدث",
  reminder: "تذكير",
  dropdownPlaceholder_1: "اختر",
  repeatEventError: "تكرار الحدث حقل مطلوب",
  reminderError: "التذكير حقل مطلوب",
  allDay: "كل اليوم",
  customRecurrence: "تخصيص التكرار",
  repeatsEvery: "كرر يومياً",
  repeatsOn: "كرر في",
  ends: "ينتهي",
  never: "لا",
  on: "في",
  after: "بعد",
  occurrence: "الحدوث",
  forOneTime: "لمرة واحدة",
  setReminder: "وضع تذكير",
  markPrivate: "نعيين كخاص",
  eventList: "قائمة الأحداث",
  viewHead: "عرض المناسبة",
  invitee: "المدعو",
  select: "اختر",
  participants: "المشاركين",
  timeSlotMsg: "الفترة الزمنية المحددة أقل من الوقت الحالي.",
  eventRepeatOption1: "يكرر لا يفعل",
  eventRepeatOption2: "يوم كل",
  eventRepeatOption3: "أسبوع كل",
  eventRepeatOption4: "شهر كل",
  eventRepeatOption5: "سنة كل",
  eventRepeatOption6: "مخصص",
  eventReminder1: "قبل دقائق 15",
  eventReminder2: "قبل دقائق 30",
  eventReminder3: "قبل ساعة 1",
  eventReminder4: "قبل يوم 1",
  invitePlaceholder: "دعوة المشاركين",
  showParticipants: "إظهار المشاركين",
  invitedHeader: "عضو مدعو",
};

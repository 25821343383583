import { Methods, Urls } from "../../common/Utils/ApiConstants";
import { CreateApi } from "../CreateApi";

const ReportCollection = CreateApi.injectEndpoints({
  endpoints: (builder) => ({
    getTaskReportGraphDetails: builder.mutation({
      query: (body) => {
        return {
          url: Urls.getTaskReportGraphDetails,
          method: Methods.post,
          body: body,
        };
      },
    }),
    getTaskReportList: builder.mutation({
      query: (body) => {
        return {
          url: Urls.getTaskReportList,
          method: Methods.post,
          body: body,
        };
      },
    }),
    exportTaskReportList: builder.mutation({
      query: (body) => {
        return {
          url: Urls.exportTaskReportList,
          method: Methods.post,
          body: body,
          responseHandler: (response) => response.text(),
        };
      },
    }),
    getWorkloadReportList: builder.mutation({
      query: (body) => {
        return {
          url: Urls.getWorkloadReportList,
          method: Methods.post,
          body: body,
        };
      },
    }),
    getWorkloadReportDetails: builder.mutation({
      query: (body) => {
        return {
          url: Urls.getWorkloadReportDetails,
          method: Methods.post,
          body: body,
        };
      },
    }),
    getPerformanceReport: builder.mutation({
      query: (body) => {
        return {
          url: Urls.performanceReport,
          method: Methods.post,
          body: body,
        };
      },
    }),
    getPerformanceReportDetails: builder.mutation({
      query: (body) => {
        return {
          url: Urls.performanceReportDetails,
          method: Methods.post,
          body: body,
        };
      },
    }),
    getStaffReportList: builder.mutation({
      query: (body) => {
        return {
          url: Urls.getStaffReportList,
          method: Methods.post,
          body: body,
        };
      },
    }),
    getAttendanceReportList: builder.mutation({
      query: (body) => {
        return {
          url: Urls.getAttendanceReportList,
          method: Methods.post,
          body: body,
        };
      },
    }),
    addAttendanceConfigure: builder.mutation({
      query: (body) => {
        return {
          url: Urls.addConfiguration,
          method: Methods.post,
          body: body,
        };
      },
    }),
    getStaffReportDetails: builder.mutation({
      query: (body) => {
        return {
          url: Urls.getStaffReportDetails,
          method: Methods.post,
          body: body,
        };
      },
    }),
    getExportStaffReports: builder.mutation({
      query: (body) => {
        return {
          url: Urls.exportStaffReports,
          method: Methods.post,
          body: body,
          responseHandler: (response) => response.text(),
        };
      },
    }),
    getAttendanceDetail: builder.mutation({
      query: (body) => ({
        url: Urls.attendanceDetail,
        method: Methods.post,
        body: body,
      }),
    }),
    addLeave: builder.mutation({
      query: (body) => ({
        url: Urls.addLeave,
        method: Methods.post,
        body: body,
      }),
    }),
    exportWorkloadReport: builder.mutation({
      query: (body) => ({
        url: Urls.workLoadExports,
        method: Methods.post,
        body: body,
        responseHandler: (response) => response.text(),
      }),
    }),
    exportAttendanceReport: builder.mutation({
      query: (body) => ({
        url: Urls.attendanceExports,
        method: Methods.post,
        body: body,
        responseHandler: (response) => response.text(),
      }),
    }),
    exportPerformanceReport: builder.mutation({
      query: (body) => ({
        url: Urls.performanceExports,
        method: Methods.post,
        body: body,
        responseHandler: (response) => response.text(),
      }),
    }),
    getViewAttendance: builder.mutation({
      query: (body) => ({
        url: Urls.viewAttendance,
        method: Methods.post,
        body: body,
      }),
    }),
    exportAttendanceDetailsReport: builder.mutation({
      query: (body) => ({
        url: Urls.attendanceDetailExport,
        method: Methods.post,
        body: body,
        responseHandler: (response) => response.text(),
      }),
    }),
    getLeaveRequestList: builder.mutation({
      query: (pageNo) => {
        return {
          url: `${Urls.getLeaveRequestList}${pageNo}`,
          method: Methods.get,
        };
      },
    }),
    processLeaveRequest: builder.mutation({
      query: (body) => ({
        url: Urls.processLeaveRequest,
        method: Methods.put,
        body: body,
      }),
    }),
  }),
});

export const {
  useGetTaskReportGraphDetailsMutation,
  useGetTaskReportListMutation,
  useExportTaskReportListMutation,
  useGetWorkloadReportListMutation,
  useGetWorkloadReportDetailsMutation,
  useGetPerformanceReportMutation,
  useGetPerformanceReportDetailsMutation,
  useGetAttendanceReportListMutation,
  useAddAttendanceConfigureMutation,
  useGetStaffReportListMutation,
  useGetStaffReportDetailsMutation,
  useGetExportStaffReportsMutation,
  useGetAttendanceDetailMutation,
  useAddLeaveMutation,
  useExportWorkloadReportMutation,
  useExportAttendanceReportMutation,
  useExportPerformanceReportMutation,
  useGetViewAttendanceMutation,
  useExportAttendanceDetailsReportMutation,
  useGetLeaveRequestListMutation,
  useProcessLeaveRequestMutation,
} = ReportCollection;

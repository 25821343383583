export default {
  "&multiLine": {
    input: {
      /* paddingInline: "10px", */
      border: "1px solid silver",
      /*  maxHeight: "50px", */
      maxHeight: "47px",
      lineHeight: "20px",
      overflowY: "scroll",
      padding: "11px",
      "&placeholder": {
        lineHeight: "40px",
        fontSize: "16px",
      },
    },
  },
  "&singleLine": {
    input: {
      padding: "10px",
      border: "1px solid silver",
    },
  },

  suggestions: {
    list: {
      backgroundColor: "white",
      border: "1px solid rgba(0,0,0,0.15)",
      fontSize: 14,
      position: "absolute",
      bottom: "0px",
      width: "170px",
      /* height: "150px", */
      height: "auto",
      maxHeight: "161px",
      overflowY: "auto"
    },
    item: {
      padding: "5px 15px",
      borderBottom: "1px solid rgba(0,0,0,0.15)",
      /*  marginBottom: "5px", */
      marginBottom: "0px",
      "&focused": {
        backgroundColor: "#cee4e5",
      },
    },
  },
};

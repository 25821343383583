import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { colors } from "../../../../common/Theme/Colors";
import { StyledTypography } from "../../../../common/Utils/StyledComponents";
import { showToast } from "../../../../common/Utils/ToastMessage";
import { useGetWorkloadReportDetailsMutation } from "../../../../request/Report";
import Translate from "../../../../translation/utils/translateFunction";
import ListView from "../../../ListView";
import { getManagetTaskColumns } from "../../../ManageTask/column.data";
import { SUBTASK_PREFIX } from "../../../ManageTask/constants";
import Searchbar from "../../../common/Searchbar";
import { useDebouncedCallback } from "use-debounce";

const WorkloadDetails = () => {
  const { selectedCompanyList, isRtl } = useSelector(
    (state) => state.formanagement
  );
  const navigate = useNavigate();
  const { state } = useLocation();
  const { id, nameValue } = state;
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [lastPage, setLastPage] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [workloadDetailsResponse, setWorkloadDetailsResponse] = useState([]);

  const [
    workloadReportDetails,
    {
      data: workloadReportDetailsData,
      isLoading: isLoadingReportDetails,
      isError: isErrorReportDetails,
      error: errorReportDetails,
    },
  ] = useGetWorkloadReportDetailsMutation();

  const requestObj = useMemo(() => {
    const companies = selectedCompanyList?.length
      ? selectedCompanyList?.map((item) => item?.value)
      : [];

    const finalReq = {
      userId: id,
      companyId: companies,
      searchText: searchText,
      pageNo: currentPage,
    };

    return companies?.length ? finalReq : null;
  }, [currentPage, selectedCompanyList, searchText, id]);

  const debouncedSearch = useDebouncedCallback(() => {
    workloadReportDetails(requestObj);
  }, 500);

  useEffect(() => {
    if(requestObj) {
      debouncedSearch();
    } else {
      workloadReportDetails(requestObj);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestObj]);

  useEffect(() => {
    if (workloadReportDetailsData?.data?.nodes) {
      const { nodes, pageInfo } = workloadReportDetailsData.data;
      setWorkloadDetailsResponse(nodes);
      setLastPage(pageInfo.lastPageNo);
      setIsLoading(false);
    }

    if (errorReportDetails && isErrorReportDetails) {
      showToast({
        message:
          JSON.stringify(errorReportDetails.data) ||
          Translate("common:unknownServerError"),
        type: "error",
      });
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workloadReportDetailsData, errorReportDetails, isErrorReportDetails]);
  
  const customSrCellRender = (params) => {
    return (
      <StyledTypography component="span" sx={{ color: colors.primary_009 }}>
        {params?.api?.getRowIndexRelativeToVisibleRows(params?.row?._id) +
          1 +
          (currentPage - 1) * 10}
      </StyledTypography>
    );
  };

  const customActionCellRender = (params) => {
    const { taskNumber } = params?.row;
    const isSubtask = taskNumber?.startsWith(SUBTASK_PREFIX);

    return (
      <i
        className="manageIconColor ml-3 fa fa-eye cursor-pointer"
        onClick={() =>
          navigate(isSubtask ? "/subtask/view" : "/taskDetails", {
            state: {
              taskId: params?.row?.taskId,
              isWorkloadReports: true,
              workloadDetails: state
            },
          })
        }
        data-toggle="tooltip"
        title={Translate("common:viewToolTip")}></i>
    );
  };
  const handleSearchText = (value) => {
    selectedCompanyList?.length > 0 && setCurrentPage(1);
    setSearchText(value);
  };

  return (
    <div className={`section-body ${isRtl ? "rtlMargin" : "ltrMargin"} mt-3`}>
      <div className="container-fluid">
        <div className="tab-content">
          <div className="col-12">
            <div className="card">
              <div className="row">
                <div className="col-12">
                  <div className="card-header fixedHeight">
                    <div className="d-flex align-items-center">
                      <i
                        className="icon fe fe-arrow-left ml-3 mr-2 icon-size backnavigation-iconColor cursor-pointer"
                        onClick={() => navigate("/reports/workload")}
                        title={Translate("common:backToolTip")}
                      />
                      <h5 className="page-title">
                        {Translate("workloadReport:workloadDetailsHeader")}
                        <i
                          className="icon fa fa-angle-right mx-2 arrow-icon-size  cursor-pointer"
                          title={Translate("common:backToolTip")}
                        />
                        {nameValue}
                      </h5>
                    </div>
                    <div className="card-options">
                      <div className="sort_stat commentTextField">
                        <form className="headerMargin">
                          <div className="input-group">
                            <Searchbar
                              handleSearchText={handleSearchText}
                              setSearch={setSearchText}
                            />
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <ListView
                  columns={getManagetTaskColumns(
                    customSrCellRender,
                    customActionCellRender,
                    false
                  )}
                  rows={workloadDetailsResponse}
                  autoHeight
                  sortingMode="server"
                  onPaginationChange={setCurrentPage}
                  currentPage={currentPage}
                  showShimmer={isLoading || isLoadingReportDetails}
                  showLoader={isLoading || isLoadingReportDetails}
                  totalPageCount={lastPage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkloadDetails;

import React, { useRef, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Translate from "../../../../../translation/utils/translateFunction";
import { handleEventKeySubmit } from "../../../../../common/Utils/commonFunction";

const PermissionModal = ({ show, title = "", onOk, onCancel }) => {
  const defaultBtnRef = useRef(null);

  useEffect(() => {
    if (show) {
      defaultBtnRef.current.focus();
    }
  }, [show]);

  return (
    <Modal
      show={show}
      onHide={onCancel}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      scrollable={true}
      className="permissionModal"
    >
      <Modal.Header closeButton>
        <Modal.Body>
          <div className="subtaskPermissionBox">
            <h5 className="taskHeading">{title}</h5>
            <button
              className="btn btn-primary saveButton"
              onClick={onOk}
              ref={defaultBtnRef}
              onKeyDown={(e) => handleEventKeySubmit(e, onOk)}
            >
              {Translate("addTask:AddSubtaskAlertButton")}
            </button>
          </div>
        </Modal.Body>
      </Modal.Header>
    </Modal>
  );
};
export default PermissionModal;

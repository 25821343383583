import { Stack } from "@mui/material";
import React from "react";
import { StyledTypography } from "../../../common/Utils/StyledComponents";
import Translate from "../../../translation/utils/translateFunction";
import populateEvents, { timelineArray } from "../utils";
import EventCard from "./EventCard";
import RenderTimeline from "./RenderTimeLine";
import ShowMore from "./ShowMore";

/** Calendar Relates css styling */
import "../calendar.css";

const EventList = ({
  showMoreData,
  dayEventData,
  screenWidth,
  setShowEventsPopup,
  onCardClick,
  eventCount = 0,
}) => {
  const width =
    screenWidth <= 991
      ? screenWidth / 2
      : screenWidth <= 1300
      ? 210
      : screenWidth <= 1450
      ? 230
      : screenWidth <= 1700
      ? 270
      : 310;
  const modifiedData = populateEvents(dayEventData, width, 0);
  return (
    <>
      <Stack
        flexDirection={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}>
        <StyledTypography
          variant="subtitle1"
          fontWeight={"500"}
          mb={1}
          className="card-title">
          {Translate("createEvent:eventList")}
        </StyledTypography>
        <StyledTypography>
          {`${eventCount} ${Translate("calendarPage:events")}`}
        </StyledTypography>
      </Stack>
      <Stack flexDirection={"row"}>
        <RenderTimeline data={timelineArray} />
        <div id="event-list" className="fc event_list">
          {modifiedData.map((event, index) => {
            return (
              <EventCard
                key={index}
                event={event}
                onCardClick={onCardClick}
                eventCardWidth={width}
              />
            );
          })}
        </div>
        <ShowMore data={showMoreData} onShowMorePress={setShowEventsPopup} />
      </Stack>
    </>
  );
};
export default EventList;

import React from "react";
import Translate from "../../../../translation/utils/translateFunction";
import NoDataFound from "../../../ManageTask/NoDataFound";
import { useSelector } from "react-redux";

const TaskHistory = ({ data = [] }) => {
  let prevDate = "";
  const { isRtl } = useSelector((state) => state.formanagement);

  return data?.length <= 0 ? (
    <NoDataFound message={Translate("manageTask:noHistory")} isCenter />
  ) : (
    <div className="commentContainer">
      <div className="card">
        <div className="card-body">
          <ul className="new_timeline mt-3">
            {data?.map((historyItem, idx) => {
              const { formattedStartDate, title, formattedTime, username } =
                historyItem;

              let showDate = formattedStartDate !== prevDate;
              prevDate = formattedStartDate;

              return (
                <li key={idx}>
                  <div className="time">
                    {showDate ? formattedStartDate : null}
                  </div>
                  <div>
                    <span
                      className="time text-pink leftDirection"
                      style={{
                        position: "absolute",
                        marginTop: 9,
                        left: !isRtl && -30,
                        right: isRtl && -23
                      }}>
                      {formattedTime || ""}
                    </span>
                    <div className="bullet primary" />

                    <div className="desc pt-2">
                      <h3>
                        {title || ""}
                        {!title.includes("by") && !title.includes("to") && ` by ${username || ""}`}
                      </h3>
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};
export default TaskHistory;

export const InitialValues = {
  companyId: null,
  fromDate: null,
  toDate: null,
  leaveReason: null,
};
export const leaveTypeEnums = { halfDay: "Half day", fullDay: "Full day" };
export const leaveStatusEnum = {
  disapproved: "Disapproved",
  pending: "Pending",
  approved: "Approved",
};
export const leaveRadioOptions = [
  leaveTypeEnums.halfDay,
  leaveTypeEnums.fullDay,
];
export const acceptedFileType = {
  "image/png": [".png", ".jpg", ".jpeg", ".pdf", ".xls", ".xlsx", ".doc"],
};

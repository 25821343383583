import Translate from "../../../translation/utils/translateFunction";

const MONTH = "MONTH",
  WEEK = "WEEK",
  PREV = "prev",
  NEXT = "next",
  TODAY = Translate("calendarPage:today");

export const eventViewType = {
  MONTH,
  WEEK,
};

export const calendarButtonType = {
  PREV,
  NEXT,
  TODAY,
};

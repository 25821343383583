import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import { colors } from "../../common/Theme/Colors";
import {
  formatDateYYYYMMDD,
  formatDiffDuration,
} from "../../common/Utils/DateFormater";
import { StyledTypography } from "../../common/Utils/StyledComponents";
import { showToast } from "../../common/Utils/ToastMessage";
import { getMoment } from "../../common/Utils/commonFunction";
import {
  useDeleteProfileImageMutation,
  useEditProfileMutation,
  useEditVendorProfileMutation,
  useGetOfficeLocationListingMutation,
  useLazyGetProfileDataQuery,
  useUploadProfileMutation,
} from "../../request/Account";
import { useGetShareContactRequestMutation } from "../../request/ContactRepo";
import { userDataAction } from "../../store/Reducer";
import Translate from "../../translation/utils/translateFunction";
import AlertPermission from "../AlertPermission";
import Dropzone from "../CommonFileUpload/Dropzone";
import FormikDatePicker from "../FormikForm/component/FormikDatePicker";
import { FormikTextField } from "../FormikForm/component/FormikTextField";
import ListView from "../ListView";
import { Persona } from "../Persona";
import ShareWithUser from "../ShareWithUser";
import { shareModuleType } from "../ShareWithUser/components/constants";
import Searchbar from "../common/Searchbar";
import Loader from "../staff/Owner/LoadingComp";
import { getOfficeLocationColumns } from "./column.data";
import { PersonalDetailsSchema, PersonalDetailsVendorSchema } from "./utils";
import { userTypes } from "../../common/users/userTypes";
import { handleEventKeySubmit } from "../../common/Utils/commonFunction";

const Profile = () => {
  const { fixNavbar, isRtl } = useSelector((state) => state?.formanagement);

  const [isEditable, setIsEditable] = useState(false);
  const { validations, userData } = useSelector(
    (state) => state?.formanagement
  );

  const formikRef = useRef(null);
  const [filesAdded, setfilesAdded] = useState([]);
  const [show, setshow] = useState(false);
  const [lastPage, setLastPage] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [officeLocation, setOfficeLocation] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const acceptedFileType = {
    "image/png": [".png", ".jpg", ".jpeg"],
  };
  const [isAuthorised, setIsAuthorised] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let InitialValues = {
    name: "",
    workEmail: "",
    contactNumber: "",
    dob: "",
  };

  // For Update profile data
  const [
    trigger,
    {
      data: profileData,
      isSuccess: isProfileDataSuccess,
      isLoading: isLoadingProfileData,
    },
  ] = useLazyGetProfileDataQuery();

  // For Update profile data
  const [
    updateProfile,
    {
      data: updatedProfiledata,
      isSuccess: updateProfileSuccess,
      isError: isUpdateProfileError,
      error: errorUpdateProfile,
      isLoading: isLoadingUpdateProfileData,
    },
  ] = useEditProfileMutation();

  // For Update vendor profile
  const [
    updateVendorProfile,
    {
      data: updatedVendorProfiledata,
      isSuccess: updateVendorProfileSuccess,
      isError: isUpdateVendorProfileError,
      error: errorUpdateVendorProfile,
      isLoading: isLoadingUpdateVendorProfileData,
    },
  ] = useEditVendorProfileMutation();

  useEffect(() => {
    if (errorUpdateVendorProfile && isUpdateVendorProfileError) {
      errorUpdateVendorProfile.data.error &&
        errorUpdateVendorProfile.data.error.length > 0 &&
        errorUpdateVendorProfile.data.error.map((errorItem) => {
          // eslint-disable-next-line no-unused-expressions
          formikRef.current?.setFieldError(errorItem.param, errorItem.msg);
        });
    }
    if (updateVendorProfileSuccess && updatedVendorProfiledata) {
      setIsEditable(false);
      trigger();
      if (updatedVendorProfiledata.success) {
        showToast({
          message: updatedVendorProfiledata?.message,
          type: "success",
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateVendorProfileSuccess, errorUpdateVendorProfile]);

  // For upload profile Image
  const [
    updateProfileImage,
    {
      data: updatedProfileImagedata,
      isSuccess: updateProfileImageSuccess,
      isLoading: isLoadingUpdateProfilImageeData,
      isError: updateProfileImageError,
      error: errorUpdateProfileImage,
    },
  ] = useUploadProfileMutation();

  useEffect(() => {
    if (updateProfileImageSuccess && updatedProfileImagedata) {
      showToast({ message: updatedProfileImagedata?.message, type: "success" });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateProfileImageSuccess, updatedProfileImagedata]);

  //For Delete Profile Image
  const [
    deleteImage,
    {
      isSuccess: deleteProfileImageSuccess,
      data: deleteProfileImageData,
      isLoading: isLoadingDeleteProfilImageeData,
    },
  ] = useDeleteProfileImageMutation();
  useEffect(() => {
    if (deleteProfileImageSuccess && deleteProfileImageData) {
      showToast({ message: deleteProfileImageData?.message, type: "success" });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteProfileImageSuccess, deleteProfileImageData]);

  // useEffect(() => {
  //   trigger();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [searchText]);
  useEffect(() => {
    trigger();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  //For Get office locations
  const [
    getOfficeLocations,
    {
      data: officeLocationData,
      isSuccess: successfulOfficeLoations,
      isError: isErrorOfficeLocation,
      error: errorOfficeLocation,
      isLoading: isLoadingOfficeLocationData,
    },
  ] = useGetOfficeLocationListingMutation();

  // share contact
  const [
    shareContactApi,
    {
      data: shareContactData,
      isSuccess: isShareContactSuccess,
      isLoading: isShareContactLoading,
      isError: isShareContactError,
      error: shareContactError,
    },
  ] = useGetShareContactRequestMutation();

  useEffect(() => {
    if (shareContactData && isShareContactSuccess) {
      showToast({ message: shareContactData?.message, type: "success" });
    }

    if (shareContactError && isShareContactError) {
      showToast({
        message:
          shareContactError?.message || Translate("document:apiFailedMessage"),
        type: "error",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shareContactData, isShareContactSuccess, isShareContactError]);

  useEffect(() => {
    if (successfulOfficeLoations && officeLocationData) {
      const { nodes, pageInfo } = officeLocationData.data;
      setOfficeLocation(nodes);
      setLastPage(pageInfo.lastPageNo);
      setLoading(false);
      setIsAuthorised(officeLocationData.data.isAuthorisedToDelete);
    }
    if (isErrorOfficeLocation && errorOfficeLocation) {
      showToast({ message: "Failed to fetch the data", type: "error" });
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successfulOfficeLoations, officeLocationData]);

  const debouncedSearch = useDebouncedCallback(() => {
    let requestObject = {
      searchText: searchText,
      pageNo: currentPageNo,
    };
    getOfficeLocations(requestObject);
  }, 500);

  useEffect(() => {
    let requestObject = {
      searchText: searchText,
      pageNo: currentPageNo,
    };

    if (requestObject?.searchText?.length) {
      debouncedSearch();
    } else {
      let requestObject = {
        searchText: searchText,
        pageNo: currentPageNo,
      };

      getOfficeLocations(requestObject);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, currentPageNo]);

  // For Get profile data
  useEffect(() => {
    if (isProfileDataSuccess) {
      // eslint-disable-next-line no-unused-expressions
      formikRef?.current?.setValues((prevValues) => ({
        ...prevValues,
        name: profileData?.foundUser?.name,
        workEmail: profileData?.foundUser?.email,
        contactNumber: profileData?.foundUser?.mobile,
        dob: profileData?.foundUser?.dob || "",
      }));
      const tempUserData = { ...userData, name: profileData?.foundUser?.name };
      setIsEditable(false);
      localStorage.setItem("userData", JSON.stringify(tempUserData));
      dispatch(userDataAction(tempUserData));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProfileDataSuccess, profileData]);

  // For Update profile data
  useEffect(() => {
    if (errorUpdateProfile && isUpdateProfileError) {
      errorUpdateProfile.data.error &&
        errorUpdateProfile.data.error.length > 0 &&
        errorUpdateProfile.data.error.map((errorItem) => {
          // eslint-disable-next-line no-unused-expressions
          formikRef.current?.setFieldError(errorItem.param, errorItem.msg);
        });
    }
    if (updateProfileSuccess && updatedProfiledata) {
      setIsEditable(false);
      trigger();
      if (updatedProfiledata.success) {
        showToast({ message: updatedProfiledata?.message, type: "success" });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateProfileSuccess, errorUpdateProfile]);

  // For upload profile Image
  useEffect(() => {
    if (errorUpdateProfileImage && updateProfileImageError) {
      errorUpdateProfileImage.data.error &&
        errorUpdateProfileImage.data.error.length > 0 &&
        errorUpdateProfileImage.data.error.map((errorItem) => {
          // eslint-disable-next-line no-unused-expressions
          formikRef.current?.setFieldError(errorItem.param, errorItem.msg);
        });
    }

    if (errorUpdateProfileImage && updateProfileImageError) {
      errorUpdateProfileImage.data.error
        ? errorUpdateProfileImage.data.error.length > 0 &&
          errorUpdateProfileImage.data.error.map((errorItem) =>
            formikRef.current?.setFieldError(errorItem.param, errorItem.msg)
          )
        : showToast({
            message: errorUpdateProfileImage?.data?.message,
            type: "error",
          });
    }

    if (updateProfileImageSuccess && updatedProfileImagedata) {
      setIsEditable(false);
      setfilesAdded([]);
      trigger();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateProfileImageSuccess, errorUpdateProfileImage]);

  //For Delete Profile Image
  useEffect(() => {
    if (deleteProfileImageSuccess) {
      trigger();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteProfileImageSuccess]);

  const onSubmit = (values) => {
    if (userData?.role?.type === userTypes?.Vendor?.toUpperCase()) {
      let bodyObjVendor = {
        name: values.name,
      };

      updateVendorProfile(bodyObjVendor);
    } else {
      let bodyObj = {
        name: values.name,
        dob: formatDateYYYYMMDD(values.dob),
      };
      updateProfile(bodyObj);
    }
  };

  const onDobChange = (value) => {
    const todaysDate = getMoment().toDate();
    const diff = formatDiffDuration(todaysDate, value);
    if (diff >= 18) {
      formikRef.current.setFieldValue("dob", formatDateYYYYMMDD(value));
      InitialValues.dob = new Date(value);
    } else {
      showToast({ message: Translate("addManager:ageAlert"), type: "error" });
      formikRef.current.setFieldValue("dob", profileData?.foundUser?.dob);
      // InitialValues.dob = profileData?.foundUser?.dob;
    }
  };

  const removeFile = () => {
    setshow(false);
    deleteImage();
  };

  const addFiles = (file) => {
    const url =
      file.type === "image/png" ||
      file.type === "image/jpg" ||
      file.type === "image/jpeg"
        ? URL.createObjectURL(file)
        : null;
    if (url) {
      file.preview = url;
    }
    if (filesAdded.length < 1) {
      filesAdded.push(file);
      setfilesAdded([file]);

      setTimeout(() => {
        let bodyObj = {
          webProfileImage: {
            url: filesAdded[0]?.b64,
            type: filesAdded[0]?.type,
            profileFileName: filesAdded[0]?.name,
            profileFileExt: filesAdded[0]?.name.split(".").pop(),
          },
        };
        updateProfileImage(bodyObj);
      }, 500);
    } else {
      showToast({
        message: Translate("common:fileAddLimitMsg"),
        type: "error",
      });
    }
  };

  const onCancel = () => setshow(false);

  const customSrCellRender = (params) => {
    return (
      <StyledTypography component="span" sx={{ color: colors.primary_009 }}>
        {params?.api?.getRowIndexRelativeToVisibleRows(params?.row?._id) +
          1 +
          (currentPageNo - 1) * 10}
      </StyledTypography>
    );
  };
  const customActionCellRender = (params) => {
    return (
      <i
        className={`manageIconColor ml-3 fa fa-eye cursor-pointer`}
        onClick={() =>
          navigate("/viewOfficeLocation", {
            state: { _id: params?.row?._id, isAuthorised: isAuthorised },
          })
        }
      />
    );
  };

  const onCloseShareModal = () => setShowShareModal(false);

  const handleShareMyContact = (users) => {
    const reqObj = {
      contactId: [userData?.contactId],
      users: users?.map((ele) => ele._id),
    };

    shareContactApi(reqObj);
  };
  const handleSearchText = (value) => {
    setCurrentPageNo(1);
    setSearchText(value);
  };
  return (
    <Formik
      initialValues={InitialValues}
      innerRef={formikRef}
      enableReinitialize
      // validateOnMount
      onSubmit={onSubmit}
      validationSchema={
        userData?.role?.type !== userTypes?.Vendor?.toUpperCase()
          ? PersonalDetailsSchema
          : PersonalDetailsVendorSchema
      }>
      {({ values, handleSubmit, errors }) => {
        return (
          <>
            <div
              className={`section-body ${isRtl ? "rtlMargin" : "ltrMargin"} ${
                fixNavbar ? "marginTop" : ""
              } `}>
              <div className="container-fluid">
                <div className="row clearfix">
                  <div className="col-md-6">
                    <div className="card card-profile">
                      <div className="card-body profileTopDetails">
                        <div
                          className="paddingTop-20"
                          style={{ position: "relative" }}>
                          <Persona
                            name={profileData?.foundUser?.name}
                            image={profileData?.foundUser?.profileUrl}
                            size={96}
                          />
                          <Dropzone
                            className="profileCameraIcon"
                            onfileSelect={addFiles}
                            acceptedImages={acceptedFileType}
                            maximumFiles={1}
                            fileLength={filesAdded.length}
                            maxFileSize={50 * 1000000}
                            isProfile
                            deleteAlert={() => {
                              setshow(true);
                            }}
                            isShowCameraDeleteIcon={
                              profileData?.foundUser?.profileUrl
                            }
                          />
                        </div>

                        <h4>{profileData?.foundUser?.name}</h4>
                        {/* <ul className="social-links list-inline mb-3 mt-2">
                                                <li className="list-inline-item"><a href="fake_url" title="Facebook" data-toggle="tooltip"><i className="fa fa-facebook" /></a></li>
                                                <li className="list-inline-item"><a href="fake_url" title="Twitter" data-toggle="tooltip"><i className="fa fa-twitter" /></a></li>
                                                <li className="list-inline-item"><a href="fake_url" title={1234567890} data-toggle="tooltip"><i className="fa fa-phone" /></a></li>
                                                <li className="list-inline-item"><a href="fake_url" title="@skypename" data-toggle="tooltip"><i className="fa fa-skype" /></a></li>
                                            </ul> */}
                        {userData?.role?.type !==
                          userTypes?.Vendor?.toUpperCase() && (
                          <div className="btn-width">
                            <button
                              className="btn btn-outline-primary btn-sm"
                              onClick={() => setShowShareModal(true)}>
                              {Translate("accountPage:shareContact")}
                            </button>
                            <button
                              className="btn btn-outline-primary btn-sm mx-3"
                              onClick={() => navigate("/profile/applyLeave")}>
                              {Translate("accountPage:leaveRequest")}
                            </button>
                          </div>
                        )}
                      </div>
                    </div>

                    <div
                      className="col-lg-12 col-md-12 mt-4"
                      style={{ padding: 0 }}>
                      <div className="card">
                        <div className="card-header">
                          <h4>{Translate("accountPage:personalDetails")}</h4>
                          <div className="card-options">
                            <i
                              onClick={() => {
                                setIsEditable(!isEditable);
                              }}
                              className={`cursor-pointer ${
                                !isEditable
                                  ? "fa fa-pencil-square-o"
                                  : "fe fe-x"
                              }`}
                              style={{ color: colors?.primary_010 }}
                              data-toggle="tooltip"
                              title={Translate(
                                `common:${
                                  !isEditable ? "editToolTip" : "cancel"
                                }`
                              )}
                            />
                          </div>
                        </div>
                        <div
                          className="card-body"
                          onKeyDown={(e) =>
                            handleEventKeySubmit(e, handleSubmit)
                          }>
                          <div className="row clearfix">
                            <div className="col-sm-6">
                              <div className="form-group">
                                <FormikTextField
                                  name="name"
                                  placeholder={Translate("accountPage:name")}
                                  label={Translate("accountPage:name")}
                                  autoComplete="off"
                                  disabled={!isEditable}
                                  maxLength={validations?.nameLength.MAX}
                                />
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group">
                                <FormikTextField
                                  type="email"
                                  name="workEmail"
                                  label={Translate("accountPage:email")}
                                  placeholder={Translate("accountPage:email")}
                                  disabled={true}
                                  autoComplete="off"
                                  maxLength={validations?.email.MAX}
                                />
                              </div>
                            </div>
                            <div className="col-sm-6 col-md-6">
                              <div className="form-group">
                                <FormikTextField
                                  type="contactNumber"
                                  name="contactNumber"
                                  label={Translate("accountPage:contact")}
                                  placeholder={Translate("accountPage:contact")}
                                  disabled={true}
                                  autoComplete="off"
                                  maxLength={validations?.email.MAX}
                                />
                              </div>
                            </div>
                            {userData?.role?.type !==
                              userTypes?.Vendor?.toUpperCase() && (
                              <div className="col-sm-6 col-md-6">
                                <div className="form-group">
                                  {!isEditable ? (
                                    <FormikTextField
                                      name="dob"
                                      label={Translate("accountPage:dob")}
                                      placeholder={Translate("accountPage:dob")}
                                      disabled={true}
                                      autoComplete="off"
                                      maxLength={validations?.email.MAX}
                                    />
                                  ) : (
                                    <FormikDatePicker
                                      label={Translate("accountPage:dob")}
                                      containerStyle="datePickerContainer"
                                      className="form-control bw-0 "
                                      format="yyyy-MM-dd"
                                      name="dob"
                                      value={values.dob}
                                      // typeof values.dob === "string" ? new Date(values.dob): values.dob
                                      onChange={onDobChange}
                                      isprofile
                                    />
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        {isEditable && (
                          <div className="card-footer text-right">
                            <button
                              onClick={() => handleSubmit()}
                              type="submit"
                              className="btn btn-primary">
                              {Translate("addEmployee:UpdateProfile")}
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="section-body ">
                      <div className="container-fluid">
                        <div className="tab-content">
                          <div className="card fixedCardHeight">
                            <div className="card-header fixedHeight">
                              <div className="card-options">
                                <div className="input-group">
                                  <Searchbar
                                    handleSearchText={handleSearchText}
                                    setSearch={setSearchText}
                                  />
                                </div>
                              </div>
                            </div>
                            <div>
                              <div className="card-body">
                                <ListView
                                  columns={getOfficeLocationColumns(
                                    customSrCellRender,
                                    customActionCellRender
                                  )}
                                  rows={officeLocationData?.data?.nodes}
                                  autoHeight
                                  sortingMode="server"
                                  onPaginationChange={setCurrentPageNo}
                                  currentPage={currentPageNo}
                                  showShimmer={isLoadingOfficeLocationData}
                                  showLoader={
                                    loading || isLoadingOfficeLocationData
                                  }
                                  totalPageCount={lastPage}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Loader
              loading={
                isLoadingProfileData ||
                isLoadingUpdateProfileData ||
                isLoadingUpdateVendorProfileData ||
                isLoadingDeleteProfilImageeData ||
                isLoadingUpdateProfilImageeData ||
                isShareContactLoading
              }
            />
            <AlertPermission
              show={show}
              subTitle={Translate("addEmployee:alertProfileDel")}
              onOk={removeFile}
              onCanel={onCancel}
              okTitle={Translate("addEmployee:delete")}
              isAlertText
            />

            {showShareModal && (
              <ShareWithUser
                currentModule={shareModuleType?.CONTACT_REPO}
                showModal={showShareModal}
                closeModal={onCloseShareModal}
                onSubmit={handleShareMyContact}
              />
            )}
          </>
        );
      }}
    </Formik>
  );
};

export default Profile;

import { Grid } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import { colors } from "../../../common/Theme/Colors";
import {
  StyledCard,
  StyledTypography,
} from "../../../common/Utils/StyledComponents";
import { showToast } from "../../../common/Utils/ToastMessage";
import {
  useGetMyDocumentListingMutation,
  useShareDocumentMutation,
} from "../../../request/Document/DocumentListing";
import Translate from "../../../translation/utils/translateFunction";
import CompanyListing from "../../ContactRepository/component";
import IconifyIcon from "../../Icon";
import ListView from "../../ListView";
import ShareWithUser from "../../ShareWithUser";
import { shareModuleType } from "../../ShareWithUser/components/constants";
import SortingFilter from "../../SortingFilter";
import { getMyDocumentColumns } from "./Components/column.data";
import CompanyHeader from "../../../common/CompanyHeader";
import Searchbar from "../../common/Searchbar";
import { sortingOptions } from "../components/constants";
import { EncryptData } from "../../../common/Utils/EncryptData";

const MyDocuments = ({ fixNavbar }) => {
  const navigate = useNavigate();
  const { selectedCompanyList, isRtl } = useSelector(
    (state) => state.formanagement
  );
  const [isLoading, setIsLoading] = useState(false);
  const [sortBy, setSortBy] = useState("default");
  const [lastPage, setLastPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [myDocuments, setMyDocuments] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [selectedDocumentId, setSelectedDocumentId] = useState("");
  const [sharedByMe, setSharedByMe] = useState(false);
  const [showSortingFilter, setShowSortingFilter] = useState(false);

  const [
    getMyDocumentListingApi,
    {
      data: myDocumentList,
      isLoading: isMyDocumentLoading,
      isError: isMyDocumentError,
      error: myDocumentError,
    },
  ] = useGetMyDocumentListingMutation();

  const [
    shareDocumentApi,
    {
      data: shareDocumentData,
      isLoading: isShareDocumentLoading,
      isError: isShareDocumentError,
      error: shareDocumentError,
    },
  ] = useShareDocumentMutation();

  const requestObj = useMemo(() => {
    const companyIds = selectedCompanyList?.length
      ? selectedCompanyList?.map((item) => item?.value)
      : [];

    const finalReq = {
      companyId: companyIds,
      searchText: search,
      sortBy,
      pageNo: currentPage,
      isSharedByMe: sharedByMe,
    };

    return companyIds?.length ? finalReq : null;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, sortBy, currentPage, selectedCompanyList, sharedByMe]);

  const debouncedSearch = useDebouncedCallback(() => {
    getMyDocumentListingApi(requestObj);
  }, 500);

  useEffect(() => {
    setMyDocuments([]);
    if (selectedCompanyList?.length) {
      setIsLoading(true);

      if (requestObj?.searchText?.length) {
        debouncedSearch();
      } else {
        getMyDocumentListingApi(requestObj);
      }
    } else {
      showToast({
        type: "info",
        message: Translate("document:selectCompanyMessage"),
      });
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestObj]);

  useEffect(() => {
    if (myDocumentList?.data?.nodes) {
      const { nodes, pageInfo } = myDocumentList.data;
      setMyDocuments(nodes);
      setLastPage(pageInfo.lastPageNo);
      setIsLoading(false);
    }

    if (myDocumentError && isMyDocumentError) {
      myDocumentError?.originalStatus !== 500 &&
        showToast({
          message: Translate("document:apiFailedMessage"),
          type: "error",
        });
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myDocumentList, isMyDocumentError]);

  useEffect(() => {
    if (shareDocumentData) {
      showToast({ message: shareDocumentData?.message, type: "success" });
    }

    if (shareDocumentError && isShareDocumentError) {
      shareDocumentError?.originalStatus !== 500 &&
        showToast({
          message:
            shareDocumentError?.message ||
            Translate("document:apiFailedMessage"),
          type: "error",
        });
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shareDocumentData, isShareDocumentError]);

  const debounced = useDebouncedCallback((sortValue) => {
    setSortBy(sortValue);
  }, 500);

  const getSort = (sortValue = "") => {
    if (sortValue !== sortBy) {
      setIsLoading(true);
      debounced(sortValue);
    }
  };

  const CustomSrCellRender = (params) => {
    return (
      <StyledTypography component="span" sx={{ color: colors.primary_009 }}>
        {params?.api?.getRowIndexRelativeToVisibleRows(params?.row?._id) +
          1 +
          (currentPage - 1) * 10}
      </StyledTypography>
    );
  };

  const CustomDateCellRender = (params) => {
    const { date, time } = params?.row;
    return (
      <td>
        <div className="text-info">{date || ""}</div>
        <div className="text-pink">{time || ""}</div>
      </td>
    );
  };

  const CustomActionCellRender = (params) => {
    return (
      <td>
        <i
          className="fa fa-eye cursor-pointer manageIconColor"
          onClick={() =>
            navigate("/documentRepository/myDocuments/view", {
              state: params?.row?._id,
            })
          }
          data-toggle="tooltip"
          title={Translate("common:viewToolTip")}></i>
        <i
          className="fe fe-share-2 cursor-pointer manageIconColor"
          onClick={() => {
            setShowShareModal(true);
            setSelectedDocumentId(params?.row?._id);
          }}
          data-toggle="tooltip"
          title={Translate("common:shareToolTip")}></i>
      </td>
    );
  };

  const handleShareDocument = (users) => {
    const reqObj = {
      documentId: selectedDocumentId,
      users: users?.map((ele) => ele._id),
    };

    shareDocumentApi(reqObj);
  };

  const onCloseShareModal = () => setShowShareModal(false);

  const getSharedByMeList = () => {
    if (sharedByMe) {
      setSharedByMe(false);
    } else {
      setSharedByMe(true);
    }
  };

  const onCloseSortingModal = () => setShowSortingFilter(false);

  const handleSortingModal = (value) => {
    getSort(value);
    setShowSortingFilter(false);
  };
  const handleSearchText = (value) => {
    setCurrentPage(1);
    setSearch(value);
  };
  return (
    <Grid
      item
      xs={12}
      className={`section-body ${isRtl ? "rtlMargin" : "ltrMargin"} ${
        fixNavbar ? "marginTop" : ""
      } `}>
      <StyledCard variant="outlined">
        <div className="container-fluid p-4">
          <div className="tab-content">
            <div>
              <div className="card">
                <div className="card-header card-header-spacing">
                  <CompanyHeader setShowModal={setShowModal} />
                  <div className="card-options">
                    <div className="ellipsisIcon">
                      <ul className="nav nav-pills">
                        <li className="nav-item dropdown cursor-pointer">
                          <a
                            href
                            data-toggle="dropdown"
                            role="button"
                            aria-haspopup="true"
                            aria-expanded="false">
                            <div className="mt-2 mr-3">
                              <label className="selectColor mr-2 cursor-pointer">
                                {Translate("document:scan")}
                              </label>
                              <i className="fe fe-upload selectColor icon-size"></i>
                            </div>
                          </a>
                          <div className="dropdown-menu dropdown-menuStyling">
                            <a
                              className="dropdown-item dropdown-itemStyling"
                              href="#!"
                              onClick={(e) => {
                                e.preventDefault();
                                navigate("/documentRepository/myDocuments/add");
                              }}>
                              <i className="fe fe-copy selectColor icon-size mr-2"></i>
                              {Translate("document:copy")}
                            </a>
                          </div>
                        </li>
                      </ul>
                    </div>

                    <div className="card-options">
                      <div className="input-group centerElementsFlex">
                        <span
                          className="mr-2"
                          data-toggle="tooltip"
                          title={Translate("common:showFilterToolTip")}
                          onClick={() => setShowSortingFilter(true)}>
                          <IconifyIcon
                            icon="bx:filter"
                            width="28"
                            className="dateRangeFilterIcon"
                          />
                        </span>

                        <Searchbar
                          handleSearchText={handleSearchText}
                          setSearch={setSearch}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex sharedByMe">
                  <input
                    type="checkbox"
                    className="cursor-pointer"
                    style={{ color: colors.primary }}
                    onClick={getSharedByMeList}
                  />
                  <StyledTypography
                    variant="subtitle1"
                    className="sharedByMeLable ml-2 cursor-pointer">
                    {Translate("VoiceNotes:sharedByMe")}
                  </StyledTypography>
                </div>
                <ListView
                  columns={getMyDocumentColumns(
                    CustomSrCellRender,
                    CustomDateCellRender,
                    CustomActionCellRender
                  )}
                  rows={myDocuments}
                  autoHeight
                  sortingMode="server"
                  sortBy={sortBy}
                  onPaginationChange={setCurrentPage}
                  currentPage={currentPage}
                  showShimmer={isLoading || isMyDocumentLoading}
                  showLoader={isLoading || isShareDocumentLoading}
                  totalPageCount={lastPage}
                />
              </div>
            </div>
          </div>
        </div>
      </StyledCard>
      <CompanyListing
        handleModal={(value) => {
          setCurrentPage(1);
          setShowModal(value);
        }}
        showModal={showModal}
        selectedCompanyList={selectedCompanyList}
      />
      {showShareModal && (
        <ShareWithUser
          currentModule={shareModuleType?.DOCUMENT_REPO}
          showModal={showShareModal}
          closeModal={onCloseShareModal}
          onSubmit={handleShareDocument}
        />
      )}
      <SortingFilter
        showModal={showSortingFilter}
        handleClose={onCloseSortingModal}
        submitModal={handleSortingModal}
        sortingOptions={sortingOptions}
        selectedSortOption={sortBy}
      />
    </Grid>
  );
};

export default MyDocuments;

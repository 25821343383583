export default {
  addnote: "Add notes",
  addpoint: "Add point",
  maxfilemsg: "Maximum 10 points can be added",
  detailHeader: "MOM details",
  editMom: "Edit MOM",
  alert: "Are you sure you want to delete this MOM?",
  viewLinkedEvents: "Linked events",
  sharedUsersTitle: "Shared users",
  eventNameTitle: "Event name",
  eventDescriptionTitle:"Event description",
  creatorName: "Creator name",
  dateTitle: "Date",
  timeTitle: "Time",
};

export default {
  titleTodaysEvents: 'रियेक्ट तथा रियेक्ट-आई १८ एन  में आपका स्वागत है',
  inProgressTasks: 'مهمات قيد التنفيذ',
  events: 'الأحداث',
  inProgressTaskHead: 'مهمة قيد التنفيذ',
  addOwner: 'إضافة مالك',
  addManager: 'إضافة مدير',
  addCompany: 'أضف شركة',
  addTask: 'اختر مهمة',
  addSubTask: 'أضف مهمة فرعية',
  eventDetails: 'تفاصيل الأحداث',
  taskDetails: 'تفاصيل المهمة',
  selectLocation: 'اختر موقع',
};

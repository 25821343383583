import React, { memo, useRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Translate from "../../translation/utils/translateFunction";

function DateRangePicker({
  startDate,
  endDate,
  setDateRange,
  isRange = true,
  className = "form-control",
  placeholder = Translate("common:dateRangePlaceholder"),
  ...props
}) {
  const datepickerRef = useRef(null);

  const handleClickDatepickerIcon = () => {
    const datepickerElement = datepickerRef.current;
    datepickerElement.setFocus(true);
  };

  const handleUnwantedDateValue = () => {
    if (startDate && !endDate && isRange) {
      setDateRange([null, null]);
    } else if (!isRange && !startDate) {
      setDateRange(null);
    }
  };

  return (
    <DatePicker
      {...props}
      showIcon
      className={className}
      selectsRange={isRange}
      dateFormat="yyyy/MM/dd"
      selected={isRange ? null : startDate}
      startDate={isRange ? startDate : null}
      endDate={isRange ? endDate : null}
      onChange={(update) => {
        setDateRange(update);
      }}
      isClearable={true}
      placeholderText={placeholder}
      ref={datepickerRef}
      onClickOutside={() => handleUnwantedDateValue()}
      onKeyDown={(e) => {
        e.preventDefault();
      }}
      icon={
        <div className="dateRangeIcon">
          <svg
            onClick={() => handleClickDatepickerIcon()}
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="19"
            viewBox="0 0 19 19"
            stroke="#495057"
            strokeWidth="2">
            <rect fill="none" height="15" width="15" x="2" y="2"></rect>
            <line x1="6" x2="6" y1="0" y2="4"></line>
            <line x1="13" x2="13" y1="0" y2="4"></line>
          </svg>
        </div>
      }
    />
  );
}

export default memo(DateRangePicker);

export default {
  employeeTitle: "إضافة موظف",
  employee: "الموظف",
  companyError: "الشركة حقل مطلوب",
  name: "اسم الموظف",
  nameError: "اسم الموظف حقل مطلوب",
  company: "اختر الشركة",
  selectCompanyMessage: "الرجاء اختيار شركة واحدة على الأقل",
  selectedCompanyMessage: "تم اختيار جميع الشركات",
  address1: "موقع الشركة",
  email: "ايميل",
  nameOfEmployee: "اسم الموظف",
  employeeName: "اسم الموظف",
  numberPlaceholder: "+00 0000 0000 00",
  dob: "تاريخ الميلاد",
  dobError: "تاريخ الميلاد حقل مطلوب",
  designation: "الوظيفة",
  designationError: "الوظيفة حقل مطلوب",
  departmentError: "القسم حقل مطلوب",
  department: "القسم",
  gender: "الجنس",
  workEmail: "ايميل العمل",
  contactNumber: "رقم جهة الإتصال",
  contactError: "رقم جهة الإتصال حقل مطلوب",
  workError: "ايميل العمل حقل مطلوب",
  registerAddError: "عنوان التسجيل مطلوب",
  contactNumberPlaceholder: "00 0000 0000",
  resCountryError: "الدولة حقل مطلوب",
  workCountryError: "الدولة حقل مطلوب",
  resStateError: "الولاية/المنطقة مطلوبة",
  workStateError: "الولاية/المنطقة مطلوبة",
  workAddressError: "عنوان العمل حقل مطلوب",
  hrContact: "جهة اتصال الموارد البشرية",
  alternateNumber: "رقم جهة اتصال بديل",
  address: "عنوان السكن",

  residenceAddressPlaceholder: "رقم المنزل/الشقة/البلوك",
  residenceAddressPlaceholder2: "الشقة/الطريق/المنطقة",
  countryPlaceholder: "الدولة",
  statePlaceholder: "الولاية/المنطقة",
  city: "المدينة",
  zipcode: "الرمز البريدي",
  workAddress: "عنوان العمل",
  workAddressPlaceholder: "عنوان/رقم البلوك",
  workAddressPlaceholder1: "المبنى/الطريق/المنطقة",
  companyPhoneExtensionPlaceholder: "000",
  extensionNumber: "الرقم الفرعي للشركة",
  extensionNumberPlaceholder: "00 0000 0000",
  uploadPhoto: "تحميل صورة",
  save: "حفظ",
  addMore: "إضافة المزيد",
  ageAlert: "يجب أن يكون العمر أكبر من 18 سنة",
  alertProfileDel: "هل أنت متأكد أنك تريد حذف هذه الصورة الشخصية؟",
  delete: "حذف",
  UpdateProfile: "تحديث الملف",
  WorkEmail: "ايميل العمل",
  srNoTableHeading: "الأب رقم",
  actionTableHeading: "فعل",
  nameTableHeading: "الإسم",
  mobileTableHeading: "رقم الموبايل",
  companyNameTableHeading: "اسم الشركة",
  emailTableHeading: "ايميل",
  searchPlaceholder: "ابحث عن شيء...",
  addButtonLabel: "يضيف",
  viewEmployee: "عرض الموظف",
  editEmployee: "تحرير الموظف",
  deleteErrorMsg: "هل أنت متأكد أنك تريد حذف هذا الموظف؟",
};

import { Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { colors } from "../../../common/Theme/Colors";
import { StyledTypography } from "../../../common/Utils/StyledComponents";
import { showToast } from "../../../common/Utils/ToastMessage";
import { useDeleteMessageMutation } from "../../../request/Message";
import Translate from "../../../translation/utils/translateFunction";
import AlertPermission from "../../AlertPermission";
import { Persona } from "../../Persona";
import { AudioMessageView } from "./Audio";
import { DocxMessageView } from "./Docx";
import { ImageMessageView } from "./ImageView";
import { PdfMessageView } from "./Pdf";
import { RenderMessageContent } from "../MessageDetail/utils";
import PDFViewer from "../../common/PdfViewer";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { userTypeOptions } from "../../../common/Utils/ApiConstants";
import { tabType } from "./constants";

const MessageDetailItem = ({
  currentMessage,
  type,
  isLastMessage,
  onDeleteMessage,
  currentChat,
  selectedChat,
}) => {
  const navigate = useNavigate();
  const { isRtl, userType } = useSelector((state) => state.formanagement);

  const [showMore, setShowMore] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [show, setShow] = useState(false);
  const [b64Array, setb64] = useState([]);

  const handleClose = () => setShow(false);

  const onClick = (message) => {
    setShow(true);
    setb64([
      {
        uri: message?.attachmentUrlDetails?.url,
        fileName: message?.attachmentUrlDetails?.messageFileName,
        fileType: message?.attachmentUrlDetails?.messageFileExt,
      },
    ]);
  };
  const [
    deleteMessage,
    { isSuccess: isDeleteMsgSuccess, data: deleteMsgData },
  ] = useDeleteMessageMutation();

  useEffect(() => {
    if (isDeleteMsgSuccess && deleteMsgData) {
      onDeleteMessage();
      showToast({ message: deleteMsgData.message });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDeleteMsgSuccess]);

  const deleteSelectedMsg = () => {
    const bodyObj = {
      chatId: currentMessage?._id,
      body: {
        isLastMessage: isLastMessage,
        deletedAttachments: [],
        taskId: currentMessage?.taskId || [],
      },
    };
    deleteMessage(bodyObj);
    cancelDelete();
  };
  const cancelDelete = () => setShowDeleteAlert(false);

  const MoreLessComponent = ({ truncatedText, isDeleted, showBackgroud }) => {
    return (
      <Stack>
        <p
          className={`${
            showBackgroud
              ? "bg-light-gray"
              : currentMessage?.isAttachment
              ? ""
              : "bg-light-gray"
          } mr-2`}
          style={{
            whiteSpace: "pre-wrap",
            padding: `${
              showBackgroud
                ? `8px ${truncatedText?.length >= 120 ? "25px" : "15px"}`
                : currentMessage?.isAttachment
                ? "8px 10px 0px 10px"
                : `8px ${truncatedText?.length >= 120 ? "25px" : "15px"}`
            }`,
            textAlign: "start",
            width: "auto",
          }}
        >
          {isDeleted ? (
            <span style={{ fontStyle: "italic" }}>
              {Translate("messagePage:deleteMessage")}
            </span>
          ) : (
            <>
              {RenderMessageContent(truncatedText)}
              {!isDeleted && truncatedText.length >= 250 && (
                <StyledTypography
                  style={{ color: colors.blue_001, marginLeft: "3px" }}
                  className="cursor-pointer"
                  variant="span"
                  onClick={() => setShowMore(!showMore)}
                >
                  {showMore
                    ? Translate("messagePage:readLess")
                    : Translate("messagePage:readMore")}
                </StyledTypography>
              )}
            </>
          )}
          {/*  {!isDeleted && truncatedText.length >= 250 && (
            <StyledTypography
              style={{ color: colors.blue_001, textAlign: "right" }}
              className="cursor-pointer"
              variant="subtitle2"
              onClick={() => setShowMore(!showMore)}
            >
              {showMore
                ? Translate("messagePage:readLess")
                : Translate("messagePage:readMore")}
            </StyledTypography>
          )} */}
        </p>
      </Stack>
    );
  };
  const RenderMessageType = () => {
    const imageType = currentMessage?.attachmentUrl
      ?.split(".")
      .pop()
      .toLowerCase();

    switch (imageType) {
      case "docx":
      case "xls":
      case "xlsx":
        return (
          <DocxMessageView
            currentMessage={currentMessage}
            type={type}
            onClick={onClick}
          />
        );
      case "pdf":
        return (
          <PdfMessageView
            currentMessage={currentMessage}
            type={type}
            onClick={onClick}
          />
        );
      case "png":
      case "jpeg":
      case "jpg":
      case "gif":
      case "heif":
      case "heic":
        return (
          <ImageMessageView
            currentMessage={currentMessage}
            type={type}
            imageType={imageType}
          />
        );
      case "mp3":
      case "mp4":
        return <AudioMessageView currentMessage={currentMessage} type={type} />;
    }
  };

  const renderMessageOptions = () => {
    return (
      (currentMessage?.message || currentMessage?.isAttachment) && (
        <>
          {((!currentMessage?.isOwn && currentMessage?.message) ||
            currentMessage.isOwn) && (
            <a
              href="/#"
              data-toggle="dropdown"
              className={
                currentMessage?.isOwn
                  ? "mt-2"
                  : currentMessage?.isAttachment
                  ? "ml-2"
                  : "messageOptionIcon"
              }
              style={{ maxHeight: "30px", marginRight: isRtl && "0.5rem" }}
              role="button"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i
                className={`fa fa-ellipsis-v cursor-pointer ${
                  currentMessage?.isAttachment ? "mr-1" : "mr-1"
                }`}
                title="options"
                style={{ color: colors.grey_003 }}
              ></i>
            </a>
          )}
          <div className="dropdown-menu dropdown-menuStyling">
            {currentMessage?.isOwn && (
              <a
                href="/#"
                className="dropdown-item"
                onClick={(e) => {
                  e.preventDefault();
                  setShowDeleteAlert(true);
                }}
              >
                {Translate("common:delete")}
              </a>
            )}
            {!currentMessage?.isAttachment &&
              userType !== userTypeOptions?.VENDOR && (
                <a
                  className="dropdown-item"
                  href="/#"
                  onClick={handleTaskNavigation}
                >
                  {Translate("messagePage:addToTask")}
                </a>
              )}
            {currentMessage?.message && (
              <CopyToClipboard
                text={currentMessage?.message}
                onCopy={() =>
                  showToast({
                    message: Translate("common:copyMsg"),
                    type: "info",
                  })
                }
              >
                <a className="dropdown-item cursor-pointer">
                  <StyledTypography variant="span">
                    {Translate("common:copy")}
                  </StyledTypography>
                </a>
              </CopyToClipboard>
            )}
          </div>
        </>
      )
    );
  };

  const handleTaskNavigation = (event) => {
    event.preventDefault();

    const msgStr =
      type === tabType?.GROUP_LIST
        ? RenderMessageContent(currentMessage?.message, true)
        : currentMessage?.message;

    const reqObj = {
      isMessage: true,
      messageId: currentMessage?._id,
      chatId:
        selectedChat?._id ||
        currentChat?.chatId ||
        currentChat?.groupId ||
        currentChat?._id,
      title: msgStr,
      moduleType: type,
    };

    return navigate("/addTask", { state: reqObj });
  };

  return (
    <Stack flexDirection={"row"}>
      <li className="other-message mb-2" style={{ width: "48%" }}>
        {!currentMessage?.isOwn && (
          <>
            <Persona
              className={"avatar mr-3"}
              name={currentMessage?.senderName}
              image={currentMessage?.senderProfile}
              size={35}
            />

            {currentMessage?.isDeleted && (
              <div className={"message "}>
                <MoreLessComponent isDeleted={true} showBackgroud={true} />
              </div>
            )}

            {!currentMessage?.message &&
              !currentMessage?.isDeleted &&
              currentMessage?.isAttachment && (
                <div className="message">
                  <RenderMessageType />
                </div>
              )}

            {currentMessage?.message && !currentMessage?.isDeleted && (
              <div
                className={`message ${
                  currentMessage?.isAttachment ? "bg-light-gray pl-2 pt-2" : ""
                }`}
                style={{ maxWidth: "100%" }}
              >
                <div>{<RenderMessageType />}</div>
                <MoreLessComponent
                  truncatedText={
                    showMore
                      ? currentMessage?.message
                      : currentMessage?.message.substring(0, 250)
                  }
                />
                <span
                  className={`time chatOtherUserTime leftDirection ${
                    currentMessage?.isAttachment ? "pr-1" : "mr-2"
                  } `}
                >
                  {currentMessage?.formattedTime}
                </span>
              </div>
            )}
            {!currentMessage?.isDeleted && renderMessageOptions()}
          </>
        )}
      </li>
      <li
        className={"my-message mr-1 mb-2"}
        style={{ width: "48%", display: "flex" }}
      >
        {currentMessage?.isOwn && (
          <>
            {currentMessage?.isDeleted && (
              <div className={"message "}>
                <MoreLessComponent isDeleted={true} showBackgroud={true} />
              </div>
            )}

            {!currentMessage?.message &&
              !currentMessage?.isDeleted &&
              currentMessage?.isAttachment && (
                <div className="message">
                  <RenderMessageType />
                </div>
              )}

            {currentMessage?.message && !currentMessage?.isDeleted && (
              <div
                className={`message ${
                  currentMessage?.isAttachment
                    ? "bg-light-gray pl-2 pt-2 mr-2"
                    : ""
                }`}
                style={{ maxWidth: "100%" }}
              >
                <div>{<RenderMessageType />}</div>
                <MoreLessComponent
                  truncatedText={
                    showMore
                      ? currentMessage?.message
                      : currentMessage?.message.substring(0, 250)
                  }
                />
                <span
                  className={`time chatOwnerTime leftDirection ${
                    currentMessage?.isAttachment ? "pr-1" : "mr-2"
                  }`}
                >
                  {currentMessage?.formattedTime}
                </span>
              </div>
            )}

            <Persona
              className={`avatar ${isRtl ? "ml-2" : "mr-2"}`}
              name={currentMessage?.senderName}
              image={currentMessage?.senderProfile}
              size={35}
            />
            {!currentMessage?.isDeleted ? (
              renderMessageOptions()
            ) : (
              <div className="mr-2"></div>
            )}
          </>
        )}
      </li>
      <AlertPermission
        show={showDeleteAlert}
        subTitle={Translate("messagePage:deleteAlertTitle")}
        okTitle={Translate("common:delete")}
        onOk={deleteSelectedMsg}
        onCanel={cancelDelete}
      />
      <PDFViewer
        b64Array={b64Array}
        files={b64Array.length && b64Array[0].fileName}
        handleClose={handleClose}
        show={show}
      />
    </Stack>
  );
};
export default MessageDetailItem;

import { Grid } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import { colors } from "../../common/Theme/Colors";
import {
  StyledCard,
  StyledTypography,
} from "../../common/Utils/StyledComponents";
import { showToast } from "../../common/Utils/ToastMessage";
import { useGetRenewalListMutation } from "../../request/Renewals";
import Translate from "../../translation/utils/translateFunction";
import CompanyListing from "../ContactRepository/component";
import IconifyIcon from "../Icon";
import ListView from "../ListView";
import SortingFilter from "../SortingFilter";
import { getRenewalsColumns } from "./Components/column.data";

import CompanyHeader from "../../common/CompanyHeader";
import { EncryptData } from "../../common/Utils/EncryptData";
import Searchbar from "../common/Searchbar";
import { sortingOptions } from "./Components/constants";

const RenewalsListing = ({ fixNavbar }) => {
  const navigate = useNavigate();
  const { selectedCompanyList, isRtl } = useSelector(
    (state) => state.formanagement
  );

  const [isLoading, setIsLoading] = useState(false);
  const [sortBy, setSortBy] = useState("default");
  const [lastPage, setLastPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [renewals, setRenewals] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showSortingFilter, setShowSortingFilter] = useState(false);

  const [
    fetchRenewalListApi,
    {
      data: renewalList,
      isLoading: isRenewalLoading,
      isError: isRenewalError,
      error: renewalError,
    },
  ] = useGetRenewalListMutation();

  const requestObj = useMemo(() => {
    const companyId = selectedCompanyList?.length
      ? selectedCompanyList?.map((item) => item?.value)
      : [];

    const finalReq = {
      companyId,
      searchText,
      pageNo: currentPage,
      sortBy,
    };

    return companyId?.length ? finalReq : null;
  }, [currentPage, searchText, selectedCompanyList, sortBy]);

  const debouncedSearch = useDebouncedCallback(() => {
    fetchRenewalListApi(requestObj);
  }, 500);

  useEffect(() => {
    if (requestObj?.searchText?.length) {
      debouncedSearch();
    } else {
      fetchRenewalListApi(requestObj);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestObj]);

  useEffect(() => {
    if (renewalList?.data?.nodes) {
      const { nodes, pageInfo } = renewalList.data;
      setRenewals(nodes);
      setLastPage(pageInfo.lastPageNo);
      setIsLoading(false);
    }
    if (renewalError && isRenewalError) {
      renewalError?.originalStatus !== 500 &&
        showToast({
          message:
            JSON.stringify(renewalError.data) ||
            Translate("common:unknownServerError"),
          type: "error",
        });
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [renewalList, renewalError]);

  const debounced = useDebouncedCallback((sortValue) => {
    setSortBy(sortValue);
  }, 500);

  const getSort = (sortValue = "") => {
    if (sortValue !== sortBy) {
      setIsLoading(true);
      debounced(sortValue);
    }
  };

  const CustomSrCellRender = (params) => {
    return (
      <StyledTypography component="span" sx={{ color: colors.primary_009 }}>
        {params?.api?.getRowIndexRelativeToVisibleRows(params?.row?._id) +
          1 +
          (currentPage - 1) * 10}
      </StyledTypography>
    );
  };

  const CustomActionCellRender = (params) => {
    return (
      <i
        data-toggle="tooltip"
        title={Translate("common:viewToolTip")}
        className="m-2 fa fa-eye cursor-pointer manageIconColor"
        onClick={() =>
          navigate("/renewals/view", {
            state: { renewalId: params?.row?._id },
          })
        }></i>
    );
  };

  const handleSearchText = (value) => {
    setCurrentPage(1);
    setSearchText(value);
  };

  const onCloseSortingModal = () => setShowSortingFilter(false);

  const handleSortingModal = (value) => {
    getSort(value);
    setShowSortingFilter(false);
  };

  return (
    <Grid
      item
      xs={12}
      className={`section-body ${isRtl ? "rtlMargin" : "ltrMargin"} ${
        fixNavbar ? "marginTop" : ""
      } `}>
      <StyledCard variant="outlined">
        <div className="container-fluid p-4">
          <div className="tab-content">
            <div>
              <div className="card">
                <div className="card-header card-header-spacing">
                  <CompanyHeader setShowModal={setShowModal} />
                  <div className="card-options">
                    <div className="input-group centerElementsFlex">
                      <span
                        className="mr-2"
                        data-toggle="tooltip"
                        title={Translate("common:showFilterToolTip")}
                        onClick={() => setShowSortingFilter(true)}>
                        <IconifyIcon
                          icon="bx:filter"
                          width="28"
                          className="dateRangeFilterIcon"
                        />
                      </span>
                      <Searchbar
                        handleSearchText={handleSearchText}
                        setSearch={setSearchText}
                      />
                    </div>

                    <div className="header-action pull-right ml-2">
                      <button
                        type="button"
                        className="btn btn-primary py-2"
                        onClick={() => navigate("/renewals/add")}>
                        <i className="fe fe-plus" />
                        <StyledTypography className="mx-1" component="span">
                          {Translate("renewals:addDoc")}
                        </StyledTypography>
                      </button>
                    </div>
                  </div>
                </div>
                <ListView
                  columns={getRenewalsColumns(
                    CustomSrCellRender,
                    CustomActionCellRender
                  )}
                  rows={renewals}
                  autoHeight
                  sortingMode="server"
                  onPaginationChange={setCurrentPage}
                  currentPage={currentPage}
                  showShimmer={isLoading || isRenewalLoading}
                  showLoader={isLoading}
                  totalPageCount={lastPage}
                />
              </div>
            </div>
          </div>
        </div>
      </StyledCard>
      <CompanyListing
        handleModal={(value) => {
          setCurrentPage(1);
          setShowModal(value);
        }}
        showModal={showModal}
        selectedCompanyList={selectedCompanyList}
      />
      <SortingFilter
        showModal={showSortingFilter}
        handleClose={onCloseSortingModal}
        submitModal={handleSortingModal}
        sortingOptions={sortingOptions}
      />
    </Grid>
  );
};

export default RenewalsListing;

import { MenuItem } from "@mui/material";
import React, { useState } from "react";
import { colors } from "../../../common/Theme/Colors";
import { FontSizes } from "../../../common/Theme/Fonts";
import {
  StyledDropDown,
  StyledTypography,
  StyledFontAndColor,
} from "../../../common/Utils/StyledComponents";
import Translate from "../../../translation/utils/translateFunction";

const DropDownField = ({
  label,
  value,
  touched,
  error,
  isError,
  disabled,
  options,
  radioOptions,
  optionsFontsize = FontSizes.regular,
  fontSize = FontSizes.medium,
  className,
  textPrimaryColor,
  onChange,
  optionsIconSize = 22,
  required,
  customMenuItemStyle,
  ...props
}) => {
  const [inFocus, setInFocus] = useState(false);
  const onFocus = () => setInFocus(true);
  const onBlur = () => setInFocus(false);
  const borderStyle = {
    borderWidth: 1,
    borderColor: inFocus
      ? colors.grey_003
      : isError
      ? colors.red
      : disabled
      ? colors.grey_002
      : colors.white,
  };
  const backgroundColorStyle = {
    backgroundColor: disabled ? colors.grey_012 : colors.white,
  };
  const placeHolder = { color: value ? colors.black : colors.placeHolder };

  const menuItemStyle = {
    whiteSpace: "unset",
    wordBreak: "break-all",
    textOverflow: "ellipsis",
  };

  return (
    <div key={`${label}_#${props?.placeholder}`}>
      {label !== undefined && label?.length > 0 && (
        <StyledTypography component="span">
          {label}
          {required && (
            <StyledTypography sx={{ color: "red" }} component="span">
              {" *"}
            </StyledTypography>
          )}
        </StyledTypography>
      )}
      <StyledDropDown
        name="country"
        id="select-countries"
        onChange={onChange}
        className={className}
        disabled={disabled}
        onFocus={onFocus}
        onBlur={onBlur}
        value={value}
        placeholder={props.placeholder}
        sx={{ padding: 0, ...StyledFontAndColor }}
        style={(backgroundColorStyle, borderStyle, placeHolder)}
        {...props}
        renderValue={!!value ? undefined : () => label || props?.placeholder}
      >
        {options?.length ? (
          options?.map(({ value, label }) => (
            <MenuItem
              key={value}
              value={value}
              sx={{
                ...(customMenuItemStyle || menuItemStyle),
                ...StyledFontAndColor,
              }}
            >
              {label}
            </MenuItem>
          ))
        ) : (
          <MenuItem disabled>{Translate("common:noDataFoundMessage")}</MenuItem>
        )}
      </StyledDropDown>
      {touched && error && (
        <StyledTypography sx={{ color: "red", fontSize: 11 }} component="span">
          {error}
        </StyledTypography>
      )}
    </div>
  );
};
export default DropDownField;

import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

const Donutchart = (props) => {
  const defaultSeries = [44, 55, 41, 17];
  const defaultOptions = {
    chart: {
      type: "donut",
    },
    states: {
      active: {
        filter: { type: "none", value: 0 },
      },
      hover: {
        filter: {
          type: "none",
        },
      },
    },
    labels: ["USA", "UK", "Australia ", "Europe"],
    fill: {
      // colors: ["#5a5278", "#6f6593", "#8075aa", "#a192d9"],
    },
    legend: {
      position: "bottom",
      // markers: {
      //   fillColors: ["#5a5278", "#6f6593", "#8075aa", "#a192d9"],
      // },
    },
    dataLabels: {
      enabled: false,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };
  const [series, setSeries] = useState();
  const [options, setOptions] = useState();
  useEffect(() => {
    if (props) {
      setSeries(props?.series || defaultSeries);
      setOptions(Object.assign(defaultOptions, props.customOptions || {}));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);
  return (
    <div>
      {options && series && (
        <div id="chart">
          <ReactApexChart
            options={options}
            series={series}
            height={props.height}
            type="donut"
          />
        </div>
      )}
    </div>
  );
};

export default Donutchart;

import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { showToast } from "../../../common/Utils/ToastMessage";
import { useEditofficeLocationMutation } from "../../../request/Account";
import { useLazyGetCompanyUrlQuery } from "../../../request/company";
import Translate from "../../../translation/utils/translateFunction";
import CompanyForm from "../components/CompanyForm";
import { InitialValues } from "../constants";
import { addCompanySchema } from "../utils";
import { getAddressError } from "../../../common/Utils/commonFunction";

const EditCompany = () => {
  const formikRef = useRef(null);
  const { state } = useLocation();
  const navigate = useNavigate();
  const [filesAdded, setfilesAdded] = useState([]);
  const [images, setImages] = useState([]);
  const [companyurl, setCompanyUrl] = useState([]);
  const [getCompanyUrl, { data: companyUrl }] = useLazyGetCompanyUrlQuery();
  const initialImage = [state?.viewCompanyData?.logo];

  const [
    editOfficeLocations,
    {
      data: editOfficeLocation,
      isSuccess: successfulEditOfficeLocation,
      isError: errorInEditOfficeLocation,
      error: errorEditOfficeLocation,
      isLoading: isEditingOfficeLocation,
    },
  ] = useEditofficeLocationMutation();

  useEffect(() => {
    getCompanyUrl();
  }, []);
  useEffect(() => {
    if (companyUrl?.data && !state) {
      let companyData = [];
      companyUrl.data.domains.map((item) =>
        companyData.push({
          label: item,
          value: item,
        })
      );
      setCompanyUrl(companyData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyUrl]);

  useEffect(() => {
    if (errorEditOfficeLocation && errorInEditOfficeLocation) {
      errorEditOfficeLocation.data.error
        ? errorEditOfficeLocation.data.error.length > 0 &&
          errorEditOfficeLocation.data.error.map((errorItem) => {
            const isPresent = getAddressError(
              errorItem.param,
              formikRef.current?.setFieldError
            );
            !isPresent &&
              formikRef.current.setFieldError(errorItem.param, errorItem.msg);
          })
        : showToast({
            message: errorEditOfficeLocation?.data?.message,
            type: "error",
          });
    }

    if (successfulEditOfficeLocation && editOfficeLocation) {
      showToast({ message: editOfficeLocation.message, type: "success" });
      formikRef.current.resetForm();
      setfilesAdded([]);
      setImages([]);
      formikRef.current.resetForm();

      // navigate("/viewOfficeLocation", {
      //   state: { _id: state?.viewCompanyData?._id },
      // });
      navigate("/viewOfficeLocation", {
        state: {
          _id: state?.viewCompanyData?._id,
          isAuthorised: state.isAuthorised,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    editOfficeLocation,
    errorEditOfficeLocation,
    errorInEditOfficeLocation,
    successfulEditOfficeLocation,
  ]);

  // edit company
  useEffect(() => {
    if (state) {
      formikRef.current.setFieldValue("name", state?.viewCompanyData?.name);
      formikRef.current.setFieldValue(
        "website",
        state?.viewCompanyData?.website
      );
      // formikRef.current.setFieldValue(
      //   "contact",
      //   state?.viewCompanyData?.contact
      // );
      formikRef.current.setFieldValue(
        "contact",
        `${state?.viewCompanyData?.countryCode}-${state?.viewCompanyData?.contact}`
      );
      formikRef.current.setFieldValue(
        "countryCode",
        state?.viewCompanyData?.countryCode
      );
      formikRef.current.setFieldValue(
        "officeTelephone",
        state?.viewCompanyData?.officeTelephone
      );
      formikRef.current.setFieldValue(
        "address",
        state?.viewCompanyData?.officeAddress?.address
      );
      formikRef.current.setFieldValue(
        "landmark",
        state?.viewCompanyData?.officeAddress?.landmark
      );
      formikRef.current.setFieldValue(
        "country",
        state?.viewCompanyData?.officeAddress?.country
      );
      formikRef.current.setFieldValue(
        "state",
        state?.viewCompanyData?.officeAddress?.state
      );
      formikRef.current.setFieldValue(
        "city",
        state?.viewCompanyData?.officeAddress?.city
      );
      formikRef.current.setFieldValue(
        "zipcode",
        state?.viewCompanyData?.officeAddress?.zipcode
      );
      formikRef.current.setFieldValue(
        "lat",
        state?.viewCompanyData?.officeAddress?.latlong?.latitude
      );
      formikRef.current.setFieldValue(
        "lng",
        state?.viewCompanyData?.officeAddress?.latlong?.longitude
      );
      formikRef.current.setFieldValue(
        "mobileShortCode",
        state?.viewCompanyData?.mobileShortCode
      );

      if (state?.viewCompanyData?.logo) {
        setfilesAdded([state?.viewCompanyData?.logo]);
        setImages([state?.viewCompanyData?.logo]);
      }

      if (state?.viewCompanyData?.website?.length) {
        let companyData = [state?.viewCompanyData?.website];

        let dropDownData = companyData?.map((item) => ({
          label: item,
          value: item,
        }));
        setCompanyUrl(...companyurl, dropDownData);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (filesAdded.length > 0) {
      const newImages = filesAdded.map((imageElement) =>
        imageElement.preview
          ? imageElement.preview
          : imageElement?.url ?? imageElement
      );
      setImages(newImages);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filesAdded]);

  const onSubmit = async (companyFormValues, { resetForm }) => {
    // ************************Request Object****************************************
    let requestObject = {
      clientId: state?.viewCompanyData?.clientId,

      name: companyFormValues.name,
      website: companyFormValues.website,

      countryCode: companyFormValues.countryCode,
      contact: companyFormValues.contact?.split("-")[1],
      officeTelephone: companyFormValues.officeTelephone,
      contactWithCountryCode: companyFormValues.countryCode.concat(
        companyFormValues.contact?.split("-")[1]
      ),
      officeAddress: {
        latlong: {
          latitude: companyFormValues.lat,
          longitude: companyFormValues.lng,
        },
        address: companyFormValues.address,
        country: companyFormValues.country,
        state: companyFormValues.state,
        city: companyFormValues.city,
        landmark: companyFormValues.landmark,
        zipcode: companyFormValues.zipcode,
      },
      mobileShortCode: companyFormValues?.mobileShortCode,

      companyId: state && state?.viewCompanyData?._id,
    };

    if (filesAdded.length > 0) {
      if (filesAdded.every((value) => initialImage?.includes(value))) {
        requestObject = {
          ...requestObject,
          logo: state?.viewCompanyData?.logo,
        };
      } else {
        let b64Array = filesAdded[0].b64;

        requestObject = {
          ...requestObject,
          webLogo: b64Array,
        };
      }
    } else {
      requestObject = {
        ...requestObject,
      };
    }

    await editOfficeLocations(requestObject);
  };

  return (
    <Formik
      initialValues={InitialValues}
      innerRef={formikRef}
      validateOnMount
      onSubmit={onSubmit}
      validationSchema={addCompanySchema}
    >
      {({ values, handleSubmit }) => {
        return (
          <>
            <CompanyForm
              isEdit
              values={values}
              handleSubmit={handleSubmit}
              filesAdded={filesAdded}
              images={images}
              setfilesAdded={setfilesAdded}
              setImages={setImages}
              isLoading={isEditingOfficeLocation}
              companyDataState={state}
              setDeletedAttachments={() => {}}
            />
          </>
        );
      }}
    </Formik>
  );
};
export default EditCompany;

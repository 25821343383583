import Translate from "../../../../translation/utils/translateFunction";

export const getAccessLogsColumns = (customCellRender) => {
  const columns = [
    {
      field: "serialNumber",

      headerName: Translate("VoiceNotes:srNo"),
      sortable: false,
      disableColumnMenu: true,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "name",

      headerName: Translate("VoiceNotes:userName"),
      flex: 0.3,
      sortable: true,
      disableColumnMenu: true,
    },
    {
      field: "designation",

      headerName: Translate("VoiceNotes:designation"),
      flex: 0.3,
      sortable: true,
      disableColumnMenu: true,
    },
    {
      field: "company",

      headerName: Translate("VoiceNotes:companyName"),

      flex: 0.3,
      sortable: true,
      disableColumnMenu: true,
    },
    {
      field: "formattedDate",

      headerName: Translate("VoiceNotes:DateTime"),

      flex: 0.3,
      sortable: true,
      disableColumnMenu: true,
      renderCell: customCellRender,
    },
  ];
  return columns;
};

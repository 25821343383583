export default {
  head: "Create event",
  subject: "Subject",
  subjectPlaceholder: "Add subject",
  description: "Description",
  descriptionPlaceholder: "Add description",
  date: "Date",
  from: "From date",
  to: "To date",
  time: "Time",
  startTime: "Start time",
  endtime: "End time",
  venue: "Venue",
  invited: "Invited",
  venuePlaceholder: "Add venue",
  dropdownPlaceholder: "Select time zone",
  subjectError: "Subject is required",
  descriptionError: "Description is required",
  fromDateError: "From date is required",
  toDateError: "To date is required",
  timezoneError: "Timezone is required",
  startTimeError: "Start time is required",
  endTimeError: "End time is required",
  venueError: "Venue is required",
  edit: "Edit event details",
  alert: "Are you sure you want to delete this event?",
  timezone: "Time zone",
  repeatEvent: "Repeat event",
  reminder: "Reminder",
  dropdownPlaceholder_1: "Select item",
  repeatEventError: "Repeat event is required",
  reminderError: "Reminder is required",
  allDay: "For all day",
  customRecurrence: "Custom recurrence",
  repeatsEvery: "Repeats every",
  repeatsOn: "Repeats on",
  ends: "Ends",
  never: "Never",
  on: "On",
  after: "After",
  occurrence: "occurrence",
  forOneTime: "For one time",
  setReminder: "Set reminder",
  markPrivate: "Mark private",
  eventList: "Events list",
  viewHead: "View Event",
  invitee: "Invitee",
  select: "Select",
  participants: "Participants",
  timeSlotMsg: "Selected time slot is lesser than current time.",
  eventRepeatOption1: "Does not repeat",
  eventRepeatOption2: "Every day",
  eventRepeatOption3: "Every week",
  eventRepeatOption4: "Every month",
  eventRepeatOption5: "Every year",
  eventRepeatOption6: "Custom",
  eventReminder1: "15 minutes before",
  eventReminder2: "30 minutes before",
  eventReminder3: "1 hour before",
  eventReminder4: "1 day before",
  invitePlaceholder: "Invite participants",
  showParticipants: "Show participants",
  invitedHeader: "Invited member",
};

import Translate from "../../../translation/utils/translateFunction";
import { CustomTitleCellRender } from "../../common/CustomCellRender";

export const getRenewalsColumns = (
  customSrCellRender,
  customActionCellRender
) => {
  const columns = [
    {
      field: "lineNo",
      headerName: Translate("common:srNoTitle"),
      align: "center",
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      renderCell: customSrCellRender,
    },
    {
      field: "name",
      headerName: Translate("renewals:docName"),
      flex: 0.5,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => CustomTitleCellRender(params?.row?.name),
    },
    {
      field: "formattedDate",
      headerName: Translate("renewals:expiryDate"),
      flex: 0.5,
      sortable: false,
      disableColumnMenu: true,
      valueGetter: (params) => params.row?.formattedDate,
    },
    {
      field: "Action",
      headerName: Translate("common:actionTitle"),
      flex: 0.2,
      sortable: false,
      disableColumnMenu: true,
      hide: true,
      renderCell: customActionCellRender,
    },
  ];
  return columns;
};

import { Grid } from "@mui/material";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { StyledCard } from "../../../common/Utils/StyledComponents";
import { showToast } from "../../../common/Utils/ToastMessage";
import {
  useGetInboxListMutation,
  useSetEmailLableAsMutation,
} from "../../../request/InboxModule";
import Translate from "../../../translation/utils/translateFunction";
import ListView from "../../ListView";
import InboxItem from "../Components/InboxItem";
import LabelAsModal from "../Components/LabelAsModal";
import { getInboxListColumns } from "../Components/column.data";
import { ACTIONABLE, INFORMATIVE } from "../Components/constants";
import Searchbar from "../../common/Searchbar";
import { getMoment } from "../../../common/Utils/commonFunction";

const InboxList = ({ fixNavbar }) => {
  const { userData, isRtl } = useSelector((state) => state.formanagement);

  const [loading, setLoading] = useState(true);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [search, setSearch] = useState("");
  const [inboxList, setInboxList] = useState();
  const date = getMoment().toDate();
  const newDate = moment(date).format("YYYY-MM-DD");
  const lastPage = useRef(0);
  const [emailFilter, setEmailFilter] = useState("INBOX");
  const [showLabelAsModal, setShowLabelAsModal] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState("");
  const [selectedItem, setSelectedItem] = useState();
  const [label, setLabel] = useState(false);
  const navigate = useNavigate();
  const [showMailSyncMessage, setShowMailSyncMessage] = useState(false);

  const [
    getInboxListApi,
    {
      data: inboxListData,
      isLoading: isloadinginboxList,
      isSuccess: isSuccessInboxList,
      isError: isErrorInboxList,
      error: errorInboxList,
    },
  ] = useGetInboxListMutation();

  const [
    setEmailLableAs,
    { data: emailLableAsData, isSuccess: isEmailLableAsSuccess },
  ] = useSetEmailLableAsMutation();

  useEffect(() => {
    if (inboxListData && isSuccessInboxList) {
      const { nodes, pageInfo } = inboxListData.data;
      setInboxList(nodes);

      lastPage.current = pageInfo.lastPageNo;
      setLoading(false);
      if (
        (userData?.isGmailSettings || userData?.isOutlookSettings) &&
        !inboxListData?.data.isGmailSync &&
        !inboxListData?.data.isOutlookSync
      ) {
        setShowMailSyncMessage(true);
      }
    }
    if (isErrorInboxList && errorInboxList) {
      showToast({ message: "Failed to fetch the data", type: "error" });
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessInboxList, inboxListData]);

  useEffect(() => {
    let requestObject = {
      pageNo: currentPageNo,
      action: emailFilter,
      searchText: search,
    };
    getInboxListApi(requestObject);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageNo, emailFilter, search]);
  useEffect(() => {
    if (isEmailLableAsSuccess && emailLableAsData?.success) {
      getInboxListApi({
        pageNo: currentPageNo,
        action: emailFilter,
        searchText: search,
      });
      setLabel(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEmailLableAsSuccess]);

  const rowsWithSerialNumbers =
    inboxList &&
    inboxList.map((row, index) => ({
      ...row,

      name: row?.from?.name || row?.from?.name,
    }));

  const handleSearchText = (value) => {
    setCurrentPageNo(1);
    setSearch(value);
  };
  const showLabelModal = (item) => {
    setSelectedItem(item);
    setSelectedLabel(
      item?.isActionable
        ? "actionable"
        : item?.isInformative
        ? "informative"
        : ""
    );
    setShowLabelAsModal(true);
  };
  const hideLabelModal = () => setShowLabelAsModal(false);
  const onOkClick = () => {
    const ids = [selectedItem?._id];

    let obj;
    if (selectedLabel === ACTIONABLE) {
      obj = {
        emailId: ids,
        isActionable: true,
      };
    } else if (selectedLabel === INFORMATIVE) {
      obj = {
        emailId: ids,
        isInformative: true,
      };
    } else if (selectedLabel === null && label) {
      obj = {
        emailId: ids,
        isInformative: false,
        isActionable: false,
      };
    }
    label && setEmailLableAs(obj);
    hideLabelModal();
  };
  const CustomCellRender = (params) => {
    return (
      <InboxItem
        item={params?.row}
        emailFilter={emailFilter}
        inboxList={inboxList}
        newDate={newDate}
        onClickLabelAs={showLabelModal}
      />
    );
  };

  const customLabelCellRender = (params) => {
    return (
      <>
        <div className="mb-1">
          <a
            href="/#"
            className="nav-link dropdown-toggle "
            data-toggle="dropdown"
            role="button"
            aria-haspopup="true"
            aria-expanded="false">
            <i
              className="fa fa-ellipsis-v manageIconColor filterIconSize"
              data-toggle="tooltip"
              title={Translate("inboxPage:filter")}
            />
          </a>
          <div className="dropdown-menu dropdownAlignment">
            <div onClick={() => showLabelModal(params.row)}>
              <a className="dropdown-item">{Translate(`inboxPage:labelAs`)}</a>
            </div>
          </div>
        </div>
      </>
    );
  };

  const filters = ["Inbox", "sentFilter"];

  const getFilterNavigate = (selectedFilter) => {
    if (selectedFilter === Translate("inboxPage:sentFilter")) {
      setCurrentPageNo(1);
      setEmailFilter("SENT");
      navigate("/inbox/sentMail");
    }
  };

  return (
    <Grid
      item
      xs={12}
      className={`section-body ${isRtl ? "rtlMargin" : "ltrMargin"} ${
        fixNavbar ? "marginTop" : ""
      } `}>
      <StyledCard variant="outlined">
        <div className="container-fluid p-4">
          <div className="tab-content">
            <div>
              <div className="card">
                <div className="card-header card-header-spacing">
                  <div className="page-title ml-4">
                    {Translate("inboxPage:head")}
                  </div>
                  <div className="card-options">
                    <div className="input-group">
                      <Searchbar
                        handleSearchText={handleSearchText}
                        setSearch={setSearch}
                        disabled={showMailSyncMessage}
                      />
                    </div>
                    {!showMailSyncMessage && (
                      <div className="d-flex ml-2">
                        <div className="mt-2 headerMargin">
                          <a
                            href="/#"
                            className="nav-link dropdown-toggle "
                            data-toggle="dropdown"
                            role="button"
                            aria-haspopup="true"
                            aria-expanded="false">
                            <i
                              className="fa fa-ellipsis-v manageIconColor filterIconSize"
                              data-toggle="tooltip"
                              title={Translate("inboxPage:filter")}
                            />
                          </a>
                          <div className="dropdown-menu dropdownAlignment">
                            {filters.map((filterItem, index) => (
                              <>
                                <div
                                  onClick={() =>
                                    getFilterNavigate(
                                      Translate(`inboxPage:${filterItem}`)
                                    )
                                  }>
                                  <a
                                    // href="!#"
                                    className={`dropdown-item cursor-pointer ${
                                      filterItem === "Inbox"
                                        ? "selected unclickable"
                                        : ""
                                    }`}>
                                    {Translate(`inboxPage:${filterItem}`)}
                                  </a>
                                </div>
                                {filters.length - 1 !== index && (
                                  <div className="dropdown-divider" />
                                )}
                              </>
                            ))}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="card-body">
                  <ListView
                    columns={getInboxListColumns(
                      CustomCellRender,
                      customLabelCellRender
                    )}
                    rows={rowsWithSerialNumbers}
                    autoHeight
                    onPaginationChange={setCurrentPageNo}
                    currentPage={currentPageNo}
                    showShimmer={isloadinginboxList}
                    showLoader={loading || isloadinginboxList}
                    totalPageCount={lastPage.current}
                    hideColumnHeaders={true}
                    className="cursor-pointer"
                    isInbox={showMailSyncMessage}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {showLabelAsModal && (
          <LabelAsModal
            show={showLabelAsModal}
            onCancel={hideLabelModal}
            onOkClick={onOkClick}
            selectedLabel={selectedLabel}
            setSelectedLabel={setSelectedLabel}
            onLabelSelect={(event) => setLabel(event)}
          />
        )}
      </StyledCard>
    </Grid>
  );
};
export default InboxList;

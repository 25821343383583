import Translate from "../../../../translation/utils/translateFunction";
import {
  CustomTitleCellRender,
  CustomTagCellRender,
} from "../../../common/CustomCellRender";

export const getStaffReportColumns = (
  customSrCellRender,
  customActionCellRender
) => {
  const columns = [
    {
      field: "lineNo",
      headerName: Translate("common:srNoTitle"),
      align: "center",
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      renderCell: customSrCellRender,
    },
    {
      field: "name",
      headerName: Translate("staffReport:nameHeading"),
      flex: 0.6,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => CustomTitleCellRender(params?.row?.name),
    },
    {
      field: "companyName",
      headerName: Translate("staffReport:companyNameHeading"),
      flex: 0.5,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => CustomTitleCellRender(params?.row?.companyName),
    },
    {
      field: "totalAssigned",
      headerName: Translate("staffReport:totalAssignedHeading"),
      flex: 0.3,
      sortable: false,
      disableColumnMenu: true,
      headerAlign: "center",
      align: "center",
      renderCell: (params) =>
        CustomTagCellRender(
          params?.row?.taskStatus?.totalAssigned,
          "tag-theme-blue"
        ),
    },
    {
      field: "inProgress",
      headerName: Translate("staffReport:inProgressHeading"),
      flex: 0.3,
      sortable: false,
      disableColumnMenu: true,
      headerAlign: "center",
      align: "center",
      renderCell: (params) =>
        CustomTagCellRender(params?.row?.taskStatus?.inProgress, "tag-blue"),
    },
    {
      field: "completed",
      headerName: Translate("staffReport:completedHeading"),
      flex: 0.3,
      sortable: false,
      disableColumnMenu: true,
      headerAlign: "center",
      align: "center",
      renderCell: (params) =>
        CustomTagCellRender(params?.row?.taskStatus?.completed, "tag-green"),
    },
    {
      field: "action",
      headerName: Translate("common:actionTitle"),
      flex: 0.3,
      sortable: false,
      disableColumnMenu: true,
      hide: true,
      headerAlign: "center",
      align: "center",
      renderCell: customActionCellRender,
    },
  ];
  return columns;
};

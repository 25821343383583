import React from "react";
import WorkChat from "../../../assets/images/work_chat_svg.svg";
import { StyledTypography } from "../../../common/Utils/StyledComponents";
import { Stack } from "@mui/material";
import Translate from "../../../translation/utils/translateFunction";
import { colors } from "../../../common/Theme/Colors";
const InitialScreen = () => {
  return (
    <div className="container-fluid" style={{ width: "75%" }}>
      <div className="row justify-content-center">
        <div className="col-lg-9 col-md-12">
          <div
            className="card bg-none b-none"
            style={{
              marginTop: "10%",
              display: "flex",
              alignItems: "center",
            }}>
            <img
              src={WorkChat}
              style={{
                width: "60%",
                height: "38vh",
              }}
              alt=""
            />
            <Stack mt={8} alignItems={"center"}>
              <StyledTypography
                variant="h5"
                sx={{
                  fontWeight: "500",
                  color: colors.primary,
                }}>
                {Translate("messagePage:initialScreenBtnTitl")}
              </StyledTypography>
              <StyledTypography
                variant="subtitle1"
                sx={{ fontWeight: "500", marginTop: "0.8rem" }}>
                {Translate("messagePage:initialScreenSubtitle")}
              </StyledTypography>
            </Stack>
          </div>
        </div>
      </div>
    </div>
  );
};
export default InitialScreen;

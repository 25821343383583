import React, { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ReactComponent as LocationIcon } from "../../../../assets/images/Location.svg";
import {
  formatDiffDuration,
  formatGetMonth,
  formatGetMonthWithValue,
  formatGetTime,
  formatGetTimeWithValue,
  formatGetYearsDiff,
} from "../../../../common/Utils/DateFormater";
import { StyledTypography } from "../../../../common/Utils/StyledComponents";
import { showToast } from "../../../../common/Utils/ToastMessage";
import {
  convertToB64,
  getMoment,
} from "../../../../common/Utils/commonFunction";
import { useLazyGetCompanyListQuery } from "../../../../request/company";
import Translate from "../../../../translation/utils/translateFunction";
import AlertPermission from "../../../AlertPermission";
import Dropzone from "../../../CommonFileUpload/Dropzone";
import FormikDatePicker from "../../../FormikForm/component/FormikDatePicker";
import FormikMultiSelectDropDown from "../../../FormikForm/component/FormikMultiSelectDropDown";
import { FormikPhoneInput } from "../../../FormikForm/component/FormikPhoneInput";
import { FormikPhoneInputWithExtField } from "../../../FormikForm/component/FormikPhoneInputWithExt";
import { FormikTextField } from "../../../FormikForm/component/FormikTextField";
import { Persona } from "../../../Persona";
import { VALIDATION_BUCKETURL } from "../../../Persona/constants";
import RadioButtons from "../../../RadioButtons";
import PageHeader from "../../../common/pageHeader";
import Loader from "../../Owner/LoadingComp";
import { InitialValues, radioOptions } from "./constants";
import { handleEventKeySubmit } from "../../../../common/Utils/commonFunction";
import FormikSearchableDropDown from "../../../FormikForm/component/FormikSearchableDropDown";
import { CustomUserItem } from "../../../common/CustomCellRender";
import { FormikDropdownPicker } from "../../../FormikForm/component/FormikDropDownPicker";

const HrOfficerForm = ({
  isAdd,
  isView,
  isEdit,
  isLoading,
  hrOfficerData,
  formikRef,
  values,
  setFieldValue,
  handleSubmit,
  setAddMoreHrOfficer,
  setReportTo,
  filesAdded,
  setFilesAdded,
  images,
  setImages,
  selectedStaff,
  setSelectedStaff,
}) => {
  const navigate = useNavigate();
  const { validations, userData } = useSelector(
    (state) => state?.formanagement
  );
  const [allCompanyData, setAllCompanyData] = useState([]);
  const [showRemoveProfileAlert, setShowRemoveProfileAlert] = useState(false);
  const [base64Img, setBase64Imge] = useState("");
  const [reportToObj, setReportToObj] = useState({});
  const [staffOptions, setStaffOptions] = useState([
    {
      label: "HR officer",
      value: "HROFFICER",
    },
  ]);
  const [
    getCompanyList,
    { data: companyResponse, isLoading: isCompanyLoading },
  ] = useLazyGetCompanyListQuery();

  const acceptedFileType = {
    "image/png": [".png", ".jpg", ".jpeg"],
  };

  useEffect(() => {
    getCompanyList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (companyResponse) {
      const companyData = [];
      const companyIds = [];

      companyResponse.map((item) => {
        companyData.push({
          label: item.name,
          value: `${item._id}`,
        });
        companyIds.push(item?._id);
      });

      setAllCompanyData(companyData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyResponse]);

  useEffect(() => {
    if (userData?._id && isAdd) {
      const { _id, name, role, profileUrl, login } = userData;

      const reportTo = {
        id: _id,
        name,
        role: role?.type,
        profileUrl,
      };

      setReportTo(reportTo);
      setReportToObj({
        value: name,
        label: (
          <CustomUserItem
            userName={name}
            title={role?.type ? `${name} | ${role?.type}` : name}
            profileUrl={profileUrl}
            email={login?.email}
          />
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  useEffect(() => {
    if (hrOfficerData && (isEdit || isView)) {
      const { name, role, email, profileUrl } = hrOfficerData?.reportTo;

      if (isEdit && (!email || !role)) {
        setReportTo({
          ...hrOfficerData?.reportTo,
          role: userData?.role?.type,
        });
      }
      setReportToObj({
        value: name,
        label: (
          <CustomUserItem
            userName={name}
            title={role ? `${name} | ${role}` : name}
            profileUrl={profileUrl}
            email={email}
          />
        ),
      });
    }
  }, [hrOfficerData]);

  const currentLocationData = (places, addressType) => {
    if (addressType) {
      formikRef.current.setFieldValue("resAddress", places.formatted_address);
      formikRef.current.setFieldValue("resLat", places.geometry.location.lat());
      formikRef.current.setFieldValue("resLng", places.geometry.location.lng());

      places.address_components.map((ele) => {
        if (ele.types[0] === "country") {
          formikRef.current.setFieldValue("resCountry", ele.long_name);
        }
        if (ele.types[0] === "administrative_area_level_1") {
          formikRef.current.setFieldValue("resState", ele.long_name);
        }
        if (ele.types[0] === "locality") {
          formikRef.current.setFieldValue("resCity", ele.long_name);
        }
        if (ele.types[0] === "postal_code") {
          formikRef.current.setFieldValue("resZipCode", ele.long_name);
        } else {
          formikRef.current.setFieldValue("resZipCode", "");
        }
      });
    } else {
      formikRef.current.setFieldValue("workAddress", places.formatted_address);
      formikRef.current.setFieldValue(
        "workLat",
        places.geometry.location.lat()
      );
      formikRef.current.setFieldValue(
        "workLng",
        places.geometry.location.lng()
      );

      places.address_components.map((ele) => {
        if (ele.types[0] === "country") {
          formikRef.current.setFieldValue("workCountry", ele.long_name);
        }
        if (ele.types[0] === "administrative_area_level_1") {
          formikRef.current.setFieldValue("workState", ele.long_name);
        }
        if (ele.types[0] === "locality") {
          formikRef.current.setFieldValue("workCity", ele.long_name);
        }
        if (ele.types[0] === "postal_code") {
          formikRef.current.setFieldValue("workZipCode", ele.long_name);
        } else {
          formikRef.current.setFieldValue("workZipCode", "");
        }
      });
    }
  };

  const onDobChange = (value) => {
    const todaysDate = getMoment().toDate();
    const diff = formatDiffDuration(todaysDate, value);
    const getDate = formatGetTime();
    const getSelectedDate = formatGetTimeWithValue(value);
    const getMonth = formatGetMonth();
    const getSelectedMonth = formatGetMonthWithValue(value);
    const getYearsDiff = formatGetYearsDiff(value);
    if (
      diff >= 18 ||
      (getYearsDiff >= 18 &&
        getDate === getSelectedDate &&
        getMonth === getSelectedMonth)
    ) {
      formikRef.current.setFieldValue("dob", new Date(value));
      InitialValues.dob = new Date(value);
    } else {
      showToast({
        message: Translate("addPersonalAssistant:ageAlert"),
        type: "error",
      });
      formikRef.current.setFieldValue("dob", "");
      InitialValues.dob = "";
    }
  };

  const addFiles = async (file) => {
    if (file) {
      const base64 = await convertToB64(file);
      setBase64Imge(base64);
    }

    const { type } = file;
    const fileTypes = ["image/png", "image/png", "image/jpeg"];

    const url = fileTypes.includes(type) ? URL.createObjectURL(file) : null;

    if (url) file.preview = url;

    if (filesAdded.length < 1) {
      filesAdded.push(file);
      setFilesAdded([...filesAdded]);
      const newImages = filesAdded.map((imageElement) => imageElement.preview);
      setImages(newImages);
    } else {
      showToast({
        message: Translate("common:fileAddLimitMsg"),
        type: "error",
      });
    }
  };

  const removeFile = () => {
    setFilesAdded([]);
    setBase64Imge("");
    onCancelProfile();
  };

  const onCancelProfile = () => setShowRemoveProfileAlert(false);

  const getPageTitle = () => {
    if (isView) {
      return Translate("addHrOfficer:viewHrOfficerTitle");
    }

    if (isEdit) {
      return Translate("addHrOfficer:editHrOfficerTitle");
    }

    return Translate("addHrOfficer:addHrOfficerTitle");
  };

  const navigateToList = () => {
    formikRef.current.setFieldValue("companyId", []);
    return navigate("/staff/hrOfficer");
  };

  useEffect(() => {
    return () => {
      InitialValues.dob = "";
      InitialValues.companyId = [];
    };
  }, []);
  return (
    <>
      <div className="row container_spacing">
        <div className="card card_spacing">
          <PageHeader
            title={getPageTitle()}
            onClick={() => {
              isEdit
                ? navigate("/staff/hrOfficer/view", {
                    state: hrOfficerData._id,
                  })
                : navigateToList();
            }}
            RightContainer={() => (
              <>
                {isView && hrOfficerData?.isEditable && (
                  <div className="card-options">
                    <i
                      className="icon-color ml-3 fa fa-pencil-square-o cursor-pointer"
                      onClick={() =>
                        navigate("/staff/hrOfficer/edit", {
                          state: hrOfficerData,
                        })
                      }
                      data-toggle="tooltip"
                      title={Translate("common:editToolTip")}></i>
                  </div>
                )}
              </>
            )}
          />
          <div
            className="card-body"
            onKeyDown={(e) => handleEventKeySubmit(e, handleSubmit)}>
            <div className="row imageDisplay mb-2">
              <div className="form-group col-md-4 col-sm-8">
                <Persona
                  isProfile={isAdd}
                  name={hrOfficerData?.name}
                  isBase64={base64Img ? true : false}
                  image={
                    filesAdded?.length > 0 && images?.length > 0
                      ? images[0]?.includes(VALIDATION_BUCKETURL)
                        ? images[0]
                        : base64Img
                      : ""
                  }
                  size={96}
                />
                {!isView && (
                  <Dropzone
                    onfileSelect={(filesArray) => addFiles(filesArray)}
                    acceptedImages={acceptedFileType}
                    maximumFiles={1}
                    fileLength={filesAdded.length}
                    maxFileSize={50 * 1000000}
                    className="staffProfileIcon"
                    isProfile
                    deleteAlert={() => {
                      setShowRemoveProfileAlert(true);
                    }}
                    isShowCameraDeleteIcon={
                      (isAdd || isEdit) && filesAdded?.length
                    }
                  />
                )}
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-4 col-sm-12">
                <FormikMultiSelectDropDown
                  options={allCompanyData}
                  name="companyId"
                  label={Translate("addPersonalAssistant:selectCompany")}
                  placeholder={Translate(
                    "addPersonalAssistant:dropdownPlaceholder"
                  )}
                  onSelect={(value) => {
                    setFieldValue("companyId", value);
                  }}
                  disabled={!isAdd}
                  required={isAdd || isEdit}
                  autoFocus={isAdd}
                />
              </div>
              <div className="form-group col-md-4 col-sm-12">
                <FormikDropdownPicker
                  options={staffOptions}
                  value={selectedStaff}
                  name="staff"
                  label={Translate("addHrOfficer:staff")}
                  placeholder={Translate("addHrOfficer:staffplaceholder")}
                  onSelect={(value) => {
                    setSelectedStaff(value);
                  }}
                  required={isAdd || isEdit}
                  disabled={!isAdd}
                />
              </div>
              <div className="form-group col-md-4 col-sm-12">
                <FormikTextField
                  name="name"
                  label={Translate("addHrOfficer:hrOfficerName")}
                  placeholder={Translate("addHrOfficer:hrOfficerName")}
                  autoComplete={"off"}
                  maxLength={validations?.nameLength.MAX}
                  disabled={isView}
                  required={isAdd || isEdit}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-4 col-sm-12">
                <FormikDatePicker
                  label={Translate("addPersonalAssistant:dob")}
                  containerStyle="datePickerContainer"
                  className="form-control bw-0 "
                  format="yyyy-MM-dd"
                  name="dob"
                  value={values.dob}
                  onChange={onDobChange}
                  disabled={isView}
                  required={isAdd || isEdit}
                />
              </div>
              <div className="form-group col-md-4 col-sm-12">
                <FormikTextField
                  name="designation"
                  label={Translate("addPersonalAssistant:designation")}
                  placeholder={Translate("addPersonalAssistant:designation")}
                  autoComplete={"off"}
                  maxLength={validations?.designationLength.MAX}
                  disabled={isView}
                  required={isAdd || isEdit}
                />
              </div>
              <div className="form-group col-md-4 col-sm-12">
                <FormikTextField
                  name="department"
                  label={Translate("addPersonalAssistant:department")}
                  placeholder={Translate("addPersonalAssistant:department")}
                  autoComplete={"off"}
                  maxLength={validations?.departmentLength.MAX}
                  disabled={isView}
                  required={isAdd || isEdit}
                />
              </div>
            </div>

            <div className="row">
              <div className="form-group col-md-4 col-sm-12">
                <FormikSearchableDropDown
                  selected={reportToObj}
                  options={[]}
                  placeholder={Translate("addPersonalAssistant:reportTo")}
                  label={Translate("addPersonalAssistant:reportTo")}
                  name="reportTo"
                  disabled
                />
              </div>
              <div className="form-group col-md-4 col-sm-12">
                <FormikTextField
                  name="email"
                  label={Translate("addPersonalAssistant:email")}
                  placeholder={Translate(
                    "addPersonalAssistant:emailPlaceholder"
                  )}
                  autoComplete={"off"}
                  maxLength={validations?.email.MAX}
                  disabled={isView}
                  required={isAdd || isEdit}
                />
              </div>
            </div>

            <div className="row">
              <div className="form-group col-md-4 col-sm-12" id="paContact">
                {isAdd || isEdit ? (
                  <FormikPhoneInput
                    label={Translate("addPersonalAssistant:contactNumber")}
                    name="mobile"
                    value={values.mobile}
                    placeholder={Translate(
                      "addPersonalAssistant:contactNumberPlaceHolder"
                    )}
                    maxLength={validations?.contactPhoneLength.MAX}
                    onChange={(value, country) => {
                      setFieldValue("mobile", value);
                      setFieldValue(
                        "mobileShortCode",
                        country?.countryCode?.toUpperCase()
                      );
                      setFieldValue("countryCode", country?.dialCode);
                    }}
                    autoComplete={"off"}
                    required={isAdd || isEdit}
                    // disabled={isView}
                    isLogin
                  />
                ) : (
                  <FormikTextField
                    name="mobile"
                    value={hrOfficerData?.login?.mobileWithCountryCode}
                    label={Translate("addPersonalAssistant:contactNumber")}
                    placeholder={Translate(
                      "addPersonalAssistant:contactNumberPlaceHolder"
                    )}
                    autoComplete={"off"}
                    maxLength={validations?.contactPhoneLength.MAX}
                    disabled={true}
                  />
                )}
              </div>
              <div className="form-group col-md-4 col-sm-12" id="paContact">
                {isAdd || isEdit ? (
                  <FormikPhoneInput
                    label={Translate(
                      "addPersonalAssistant:alternateContactNumber"
                    )}
                    name="alternateMobile"
                    value={values.alternateMobile}
                    placeholder={Translate(
                      "addPersonalAssistant:alternateContactNumberPlaceHolder"
                    )}
                    maxLength={validations?.contactPhoneLength.MAX}
                    onChange={(value, country) => {
                      setFieldValue("alternateMobile", value);
                      setFieldValue(
                        "alternateMobileShortCode",
                        country?.countryCode?.toUpperCase()
                      );
                      setFieldValue(
                        "alternateMobileCountryCode",
                        country?.dialCode
                      );
                    }}
                    autoComplete={"off"}
                    disabled={isView}
                    isLogin
                  />
                ) : (
                  <FormikTextField
                    name="alternateMobile"
                    value={
                      hrOfficerData?.alternateMobileWithCountryCode
                        ? hrOfficerData?.alternateMobileWithCountryCode
                        : "NA"
                    }
                    label={Translate(
                      "addPersonalAssistant:alternateContactNumber"
                    )}
                    placeholder={Translate(
                      "addPersonalAssistant:alternateContactNumberPlaceHolder"
                    )}
                    autoComplete={"off"}
                    maxLength={validations?.contactPhoneLength.MAX}
                    disabled={true}
                  />
                )}
              </div>

              <div className="form-group col-md-4 col-sm-12">
                <FormikPhoneInputWithExtField
                  name="companyNumber"
                  extName="companyExtension"
                  label={Translate(
                    "addPersonalAssistant:companyExtensionNumber"
                  )}
                  placeholder={Translate(
                    "addPersonalAssistant:extensionNumberPlaceholder"
                  )}
                  extPlaceholder={Translate(
                    "addPersonalAssistant:companyPhoneExtensionPlaceholder"
                  )}
                  autoComplete={"off"}
                  maxLength={validations?.telephoneLength.MAX}
                  extMaxLength={validations?.companyExtension.MAX}
                  disabled={isView}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-12 col-sm-12">
                <div className="AddressLabel">
                  <StyledTypography
                    style={{ fontWeight: 500 }}
                    variant="subtitle1">
                    {Translate("addPersonalAssistant:address")}
                    {(isAdd || isEdit) && (
                      <span style={{ color: "red" }}>{" *"}</span>
                    )}
                  </StyledTypography>
                </div>
                <FormikTextField
                  name="resAddress"
                  placeholder={Translate(
                    "addPersonalAssistant:addressPlaceholder_1"
                  )}
                  autoComplete={"off"}
                  isLocation={true}
                  RenderRightContainer={
                    !isView && <LocationIcon className="formikLocationIcon" />
                  }
                  locationValue={(currentLocation) => {
                    currentLocationData(currentLocation, true);
                  }}
                  maxLength={validations?.address.MAX}
                  disabled={isView}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-12 col-sm-12">
                <FormikTextField
                  name="resFlat"
                  placeholder={Translate(
                    "addPersonalAssistant:addressPlaceholder_2"
                  )}
                  autoComplete={"off"}
                  maxLength={validations?.landmarkLimit.MAX}
                  disabled={isView}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-4 col-sm-8">
                <FormikTextField
                  name="resCountry"
                  placeholder={Translate(
                    "addPersonalAssistant:countryPlaceholder"
                  )}
                  disabled={isView}
                  maxLength={validations?.cityLimit.MAX}
                />
              </div>
              <div className="form-group col-md-4 col-sm-8">
                <FormikTextField
                  name="resState"
                  placeholder={Translate(
                    "addPersonalAssistant:statePlaceholder"
                  )}
                  disabled={isView}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-4 col-sm-8">
                <FormikTextField
                  name="resCity"
                  placeholder={Translate(
                    "addPersonalAssistant:cityPlaceholder"
                  )}
                  maxLength={validations?.cityLimit.MAX}
                  disabled={isView}
                />
              </div>
              <div className="form-group col-md-4 col-sm-8">
                <FormikTextField
                  name="resZipCode"
                  placeholder={Translate(
                    "addPersonalAssistant:zipcodePlaceholder"
                  )}
                  disabled={isView}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-12 col-sm-12">
                <div className="AddressLabel">
                  <StyledTypography
                    style={{ fontWeight: 500 }}
                    variant="subtitle1">
                    {Translate("addPersonalAssistant:workAddress")}
                    {(isAdd || isEdit) && (
                      <span style={{ color: "red" }}>{" *"}</span>
                    )}
                  </StyledTypography>
                </div>
                <FormikTextField
                  name="workAddress"
                  placeholder={Translate(
                    "addPersonalAssistant:addressPlaceholder_3"
                  )}
                  autoComplete={"off"}
                  isLocation={true}
                  RenderRightContainer={
                    !isView && <LocationIcon className="formikLocationIcon" />
                  }
                  locationValue={(currentLocation) => {
                    currentLocationData(currentLocation, false);
                  }}
                  maxLength={validations?.address.MAX}
                  disabled={isView}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-12 col-sm-12">
                <FormikTextField
                  name="workFlat"
                  placeholder={Translate(
                    "addPersonalAssistant:addressPlaceholder_4"
                  )}
                  autoComplete={"off"}
                  maxLength={validations?.landmarkLimit.MAX}
                  disabled={isView}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-4 col-sm-8">
                <FormikTextField
                  name="workCountry"
                  disabled={isView}
                  placeholder={Translate(
                    "addPersonalAssistant:countryPlaceholder"
                  )}
                  maxLength={validations?.cityLimit.MAX}
                />
              </div>
              <div className="form-group col-md-4 col-sm-8">
                <FormikTextField
                  name="workState"
                  disabled={isView}
                  placeholder={Translate(
                    "addPersonalAssistant:statePlaceholder"
                  )}
                  maxLength={validations?.cityLimit.MAX}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-4 col-sm-8">
                <FormikTextField
                  name="workCity"
                  placeholder={Translate(
                    "addPersonalAssistant:cityPlaceholder"
                  )}
                  maxLength={validations?.cityLimit.MAX}
                  disabled={isView}
                />
              </div>
              <div className="form-group col-md-4 col-sm-8">
                <FormikTextField
                  name="workZipCode"
                  placeholder={Translate(
                    "addPersonalAssistant:zipcodePlaceholder"
                  )}
                  disabled={isView}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-4 col-sm-8">
                {(isAdd || isEdit || (isView && hrOfficerData?.gender)) && (
                  <RadioButtons
                    radioOptions={radioOptions}
                    defaultChecked={hrOfficerData?.gender === "FEMALE" ? 1 : 0}
                    label={Translate("addPersonalAssistant:gender")}
                    onPress={(value) => {
                      setFieldValue("gender", value.target.value);
                    }}
                    disabled={isView}
                    required={isAdd || isEdit}
                  />
                )}
              </div>
            </div>

            {(isAdd || isEdit) && (
              <div className="card-footer text-right">
                <button
                  type="submit"
                  className="btn btn-primary saveButton"
                  onClick={() => {
                    handleSubmit();
                    isAdd && setAddMoreHrOfficer(false);
                  }}>
                  {Translate("addPersonalAssistant:save")}
                </button>
                {isAdd && (
                  <button
                    type="submit"
                    className="btn btn-white addButton"
                    onClick={() => {
                      handleSubmit();
                      isAdd && setAddMoreHrOfficer(true);
                    }}>
                    {Translate("addPersonalAssistant:addMore")}
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <Loader loading={isLoading || isCompanyLoading} />
      <AlertPermission
        show={showRemoveProfileAlert}
        subTitle={Translate("common:deleteProfileAlert")}
        onOk={removeFile}
        onCanel={onCancelProfile}
      />
    </>
  );
};

export default memo(HrOfficerForm);

import { Methods, Urls } from "../../common/Utils/ApiConstants";
import { CreateApi } from "../CreateApi";

const profileCollection = CreateApi.injectEndpoints({
  endpoints: (builder) => ({
    getProfileData: builder.query({
      query: (search) => ({
        url: Urls.viewProfile,

        // url: `https://qa.formanageapi.5dtesting.com/server/api/v1.0/users/viewprofile?search=${search}`,
        method: Methods.get,
      }),
      transformResponse: (response) => {
        return response.data;
      },
      providesTags: ["profile"],
    }),
    editProfile: builder.mutation({
      query: (bodyObj) => ({
        url: Urls.editProfile,
        method: Methods.put,
        body: bodyObj,
      }),
      invalidatesTags: ["profile"],
    }),
    editVendorProfile: builder.mutation({
      query: (bodyObj) => ({
        url: Urls.editVendorProfile,
        method: Methods.put,
        body: bodyObj,
      }),
      invalidatesTags: ["profile"],
    }),
    uploadProfile: builder.mutation({
      query: (bodyObj) => ({
        url: Urls.uploadImage,
        method: Methods.put,
        body: bodyObj,
      }),
      invalidatesTags: ["profile"],
    }),
    deleteProfileImage: builder.mutation({
      query: () => ({
        url: Urls.deleteImage,
        method: Methods.delete,
      }),
      invalidatesTags: ["profile"],
    }),
    viewCompanyDetails: builder.mutation({
      query: (bodyObj) => {
        return {
          url: Urls.companyDetails,
          method: "POST",
          body: bodyObj,
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
    }),

    getOfficeLocationListing: builder.mutation({
      query: (requestBody) => {
        return {
          url: Urls.getOfficeLocations,

          method: Methods.post,
          body: requestBody,
        };
      },
    }),
    editofficeLocation: builder.mutation({
      query: (body) => {
        return {
          url: Urls.editOfficeLocations,
          method: Methods.put,
          body: body,
        };
      },
    }),
  }),
});

export const {
  useLazyGetProfileDataQuery,
  useEditProfileMutation,
  useViewCompanyDetailsMutation,
  useUploadProfileMutation,
  useDeleteProfileImageMutation,
  useGetOfficeLocationListingMutation,
  useEditofficeLocationMutation,
  useEditVendorProfileMutation,
} = profileCollection;

/* eslint-disable array-callback-return */
/* eslint-disable default-case */
import React from "react";
import { Menu, MenuItem, Sidebar, SubMenu } from "react-pro-sidebar";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { ReactComponent as AboutUs } from "../../../src/assets/images/drawer-menu-svg/aboutus.svg";
import { ReactComponent as ActivityLogs } from "../../../src/assets/images/drawer-menu-svg/activity_logs.svg";
import { ReactComponent as AttendanceReport } from "../../../src/assets/images/drawer-menu-svg/attendance_report.svg";
import { ReactComponent as Settings } from "../../../src/assets/images/drawer-menu-svg/configure.svg";
import { ReactComponent as DocumentRepository } from "../../../src/assets/images/drawer-menu-svg/document_repository.svg";
import { ReactComponent as Home } from "../../../src/assets/images/drawer-menu-svg/home.svg";
import { ReactComponent as ManageTask } from "../../../src/assets/images/drawer-menu-svg/manage_task.svg";
import { ReactComponent as Notifications } from "../../../src/assets/images/drawer-menu-svg/notifications.svg";
import { ReactComponent as Organization } from "../../../src/assets/images/drawer-menu-svg/organization.svg";
import { ReactComponent as ChangePassword } from "../../../src/assets/images/drawer-menu-svg/password_change.svg";
import { ReactComponent as PerformanceReport } from "../../../src/assets/images/drawer-menu-svg/performance_report.svg";
import { ReactComponent as Staff } from "../../../src/assets/images/drawer-menu-svg/person.svg";
import { ReactComponent as Privacy } from "../../../src/assets/images/drawer-menu-svg/privacy.svg";
import { ReactComponent as ContactRepository } from "../../../src/assets/images/drawer-menu-svg/public_mark.svg";
import { ReactComponent as Renewals } from "../../../src/assets/images/drawer-menu-svg/renewals.svg";
import { ReactComponent as Report } from "../../../src/assets/images/drawer-menu-svg/report.svg";
import { ReactComponent as StaffReport } from "../../../src/assets/images/drawer-menu-svg/staff_report.svg";
import { ReactComponent as TaskReport } from "../../../src/assets/images/drawer-menu-svg/task_report.svg";
import { ReactComponent as TermsCondition } from "../../../src/assets/images/drawer-menu-svg/terms_condition.svg";
import { ReactComponent as Document } from "../../../src/assets/images/drawer-menu-svg/text_snippet.svg";
import { ReactComponent as ChangeTimezone } from "../../../src/assets/images/drawer-menu-svg/timezone_change.svg";
import { ReactComponent as Vendor } from "../../../src/assets/images/drawer-menu-svg/vendors.svg";
import { ReactComponent as VoiceNotes } from "../../../src/assets/images/drawer-menu-svg/voice_note.svg";
import { ReactComponent as WorkloadReport } from "../../../src/assets/images/drawer-menu-svg/workload_report.svg";
import { ReactComponent as CompanyStructure } from "../../../src/assets/images/drawer-menu-svg/company_structure.svg";
import { ReactComponent as ReportingStructure } from "../../../src/assets/images/drawer-menu-svg/reporting_structure.svg";
import { ReactComponent as MomModule } from "../../../src/assets/images/drawer-menu-svg/Mom.svg";
import { ReactComponent as Project } from "../../../src/assets/images/drawer-menu-svg/activity_logs.svg";
import { colors } from "../../common/Theme/Colors";
import Translate from "../../translation/utils/translateFunction";
import DocumentShare from "./DocumentShareIcon";

const SidebarDrawer = () => {
  const { userData, isRtl } = useSelector((state) => state.formanagement);
  const location = useLocation();

  const handleIconComponent = (componentName) => {
    switch (componentName) {
      case "Home":
        return <Home fill="#02005B" />;
      case "ManageTask":
        return <ManageTask fill="#02005B" />;
      case "Organization":
      case "CompanyReport":
        return <Organization fill="#02005B" />;
      case "Project":
        return <Project fill="#02005B" />;
      case "MomModule":
        return <MomModule fill="#02005B" />;
      case "ContactRepository":
        return <ContactRepository fill="#02005B" />;
      case "DocumentRepository":
        return <DocumentRepository fill="#02005B" />;
      case "Document":
        return <Document fill="#02005B" />;
      case "DocumentShare":
        return <DocumentShare fill="#02005B" />;
      case "Report":
        return <Report fill="#02005B" />;
      case "TaskReport":
        return <TaskReport fill="#02005B" />;
      case "PerformanceReport":
      case "CustomReport":
        return <PerformanceReport fill="#02005B" />;
      case "WorkloadReport":
        return <WorkloadReport fill="#02005B" />;
      case "StaffReport":
        return <StaffReport fill="#02005B" />;
      case "AttendanceReport":
        return <AttendanceReport fill="#02005B" />;
      case "Vendor":
        return <Vendor fill="#02005B" />;
      case "Staff":
        return <Staff fill="#02005B" />;
      case "ActivityLogs":
        return <ActivityLogs fill="#02005B" />;
      case "Renewals":
        return <Renewals fill="#02005B" />;
      case "CompanyStructure":
        return <CompanyStructure fill="#02005B" />;
      case "ReportingStructure":
        return <ReportingStructure fill="#02005B" />;
      case "VoiceNotes":
        return <VoiceNotes fill="#02005B" />;
      case "Notifications":
        return <Notifications fill="#02005B" />;
      case "Settings":
        return <Settings fill="#02005B" />;
      case "ChangePassword":
        return <ChangePassword fill="#02005B" />;
      case "ChangeTimezone":
        return <ChangeTimezone fill="#02005B" />;
      case "AboutUs":
        return <AboutUs fill="#02005B" />;
      case "Privacy":
        return <Privacy fill="#02005B" />;
      case "TermsCondition":
        return <TermsCondition fill="#02005B" />;
      default:
        return <Home fill="#02005B" />;
    }
  };

  return (
    <Sidebar rtl={isRtl}>
      <Menu
        menuItemStyles={{
          label: ({ level, active }) => {
            return {
              color: active ? colors.white : colors.primary_009,
              fontWeight: active ? "500" : "400",
            };
          },
          SubMenuExpandIcon: { marginBottom: "5px" },
          subMenuContent: ({ level, active }) => {
            return {
              color: active ? colors.white : colors.primary_009,
              fontWeight: active ? "500" : "400",
            };
          },
          icon: ({ level, active }) => {
            return {
              width: 10,
              height: 10,
              // color: active ? colors.white : colors.primary_009,
              filter: !active
                ? "none"
                : "invert(94%) sepia(0%) saturate(24%) hue-rotate(24deg) brightness(105%) contrast(108%)",
            };
          },
          button: ({ level, active }) => {
            return {
              background: active ? colors.primary : colors.white,
              "&:hover": {
                color: active ? colors.white : colors.primary_009,
                backgroundColor: active ? colors.primary : colors.grey_001,
              },
            };
          },
        }}>
        {userData?.menuAccess?.map((item, idx) => {
          const menu = Object.values(item)[0];
          const { isEnable, parent, children, isLeftMenu, isDrawerMenu } = menu;

          return isEnable && !isLeftMenu && isDrawerMenu ? (
            children?.length ? (
              <SubMenu
                key={parent?.label}
                label={Translate(`drawer:${parent.label}`)}
                active={location.pathname === parent?.navigation}
                icon={handleIconComponent(parent?.icon)}>
                {children.map(
                  (subMenuItem) =>
                    subMenuItem?.isEnable && (
                      <MenuItem
                        key={subMenuItem?.label}
                        component={<Link to={subMenuItem?.navigation} />}
                        active={
                          location.pathname === subMenuItem?.navigation ||
                          subMenuItem?.additionalPath?.find(
                            (routeName) => routeName === location.pathname
                          )
                        }
                        icon={handleIconComponent(subMenuItem?.icon)}
                        onClick={subMenuItem?.onclick}>
                        {Translate(`drawer:${subMenuItem?.label}`)}
                      </MenuItem>
                    )
                )}
              </SubMenu>
            ) : (
              <MenuItem
                key={`${idx}-${parent?.label}`}
                component={<Link to={parent?.navigation} />}
                active={
                  location.pathname === parent?.navigation ||
                  parent?.additionalPath?.find(
                    (routeName) => routeName === location.pathname
                  )
                }
                icon={handleIconComponent(parent?.icon)}>
                {Translate(`drawer:${parent?.label}`)}
              </MenuItem>
            )
          ) : null;
        })}
      </Menu>
    </Sidebar>
  );
};
export default SidebarDrawer;

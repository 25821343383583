export default {
  addCompanyHeading: "أضف اسم",
  name: "اسم الشركة",
  URL: "موقع الشركة الالكتروني URL",
  contact: "جهة اتصال الشركة",
  officeTelephone: "رقم تلفون الشركة",
  officeTelephonePlaceHolder: "0000 0000 00",
  contactPlaceholder: "+00 0000 0000 00",
  address: " العنوان المسجّل",
  landmark: "معلم قريب",
  landmarkPlaceholder: "معلم قريب",
  country: "الدولة",
  state: "الولاية",
  city: "المدينة",
  zipcode: "الرمز البريدي",
  countryDropdownPlaceholder: "الدولة",
  stateDropdownPlaceholder: "الولاية",
  cityDropdownPlaceholder: "المدينة",
  nameError: "اسم الشركة حقل مطلوب",
  nameError_1: "اسم الشركة غير فعّال",
  nameError_2: "يجب أن يتكون اسم الشركة من 3 حروف على الأقل",
  nameError_3: "يجب أن يتكون اسم الشركة من 100 حرف على الأكثر",
  urlError: "موقع الشركة الإلكتروني URL",
  urlError_1: "يجب أن يكون ال URL فعّال",
  numberError_1: "رقم جهة اتصال الشركة غير فعّال",
  numberError_2: "رقم تلفون حقل مطلوب",
  AlternateNumberError: "الرقم البديل لجهة الإتصال حقل مطلوب",
  contactNumberError: "رقم جهة اتصال الشركة حقل مطلوب",
  numberError_3: "يجب أن يتكون رقم جهة اتصال الشركة من 5 حروف على الأقل",
  numberError_4: "يجب أن يتكون رقم جهة اتصال الشركة من 12 حرف على الأكثر",
  officeTelephoneError_1: "رقم تلفون الشركة غير فعّال",
  officeTelephoneError_2: "رقم تلفون الشركة حقل مطلوب",
  officeTelephoneError_3: "يجب أن يتكون رقم تلفون الشركة من 6 أرقام على الأقل",
  officeTelephoneError_4: "يجب أن يتكون رقم تلفون الشركة من 15 رقم على الأكثر",
  addressError: "العنوان المسجّل حقل مطلوب",
  landmarkError: "المعلم حقل مطلوب",
  landmarkError_1: "المعلم غير فعّال",
  countryError: "الدولة حقل مطلوب",
  stateError: "الولاية حقل مطلوب",
  cityError: "المدينة حقل مطلوب",
  cityError_1: "اسم المدينة غير فعّال",
  zipcodeError: "الرمز البريدي حقل مطلوب",
  zipcodeError_1: "الرمز البريدي غير فعّال",
  zipcodeError_2: "يجب أن يتكون الرمز البريدي من 5 أرقام على الأقل",
  zipcodeError_3: "يجب أن يتكون الرمز البريدي من 6 أرقام على الأكثر",
  logo: "شعار الشركة",
  save: "حفظ",
  addMore: "أضف المزيد",
  addLogo: "شعار الشركة",
  editCompany: "الشركة رقم تعديل",
  deleteCompanyAlterMsg: "هل أنت متأكد من حذف الشركة؟",
  viewOfficeHeader: "عرض موقع الشركة",
  alertHeder: "Domain URL",
};

export default {
    head: "الحضور",
    report: "تقرير الحضور",
    markAttendance: "تأكيد الحضور",
    checkIn: "تسجيل الوصول",
    checkOut: "تسجيل المغادرة",
    workingHours: "ساعات العمل",
    totalDays: "مجموع عدد الأيام",
    workingDays: "أيام العمل",
    presentDays: "أيام الحضور",
    absentDays: "أيام الغياب",
    own: "املك",
    manager: "المالك",
    employee: "ذات",
    configure: "تهيئة",
    holiday: "العُطَل",
    onLeave: "في مغادرة",
    absent: "غائب",
    date: "التاريخ",
    configuration: "الاعدادات",
    attended: "مجموع عدد الأيام",
    addOccasion: "أضف مناسبة",
    dateLabel: "التاريخ",
    role: "دور"
  };
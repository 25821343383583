import React, { useEffect, useState } from "react";
import Pagination from "../Pagination";
import { SUBTASK_PREFIX, filterHeders } from "../constants";
import Table from "../Table";
import { useNavigate } from "react-router-dom";
import { showToast } from "../../../common/Utils/ToastMessage";
import { useLazyGetAssignedByPATaskDataQuery } from "../../../request/ManageTaskFilters/AssignedByPATask";
import { useSelector } from "react-redux";
import ListView from "../../ListView";
import { getManagetTaskColumns } from "../column.data";
import {
  StyledCard,
  StyledTypography,
} from "../../../common/Utils/StyledComponents";
import { colors } from "../../../common/Theme/Colors";
import Translate from "../../../translation/utils/translateFunction";
import {
  useCriticalTaskMutation,
  usePinTaskMutation,
} from "../../../request/Task";
import PageHeader from "../../common/pageHeader";
import { Grid } from "@mui/material";
import { taskStatusType } from "../../AddTask/constants";

const AssignedByPATaskListing = () => {
  const { isRtl, userData, isFixNavbar } = useSelector(
    (state) => state.formanagement
  );
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPageNo, setLastPageNo] = useState(0);
  const [assignedByPATaskListingData, setAssignedByPATaskListingData] =
    useState([]);

  const [
    getAssignedPAListApi,
    {
      data: rejectedTaskData,
      isLoading: loadingAssignedByPATask,
      isFetching: fetchingAssignedByPATaskData,
      isError: assignedByPATaskIsError,
      error: assignedByPATaskError,
    },
  ] = useLazyGetAssignedByPATaskDataQuery();
  const [trigger, { data: pinTaskData }] = usePinTaskMutation({
    fixedCacheKey: "pinnedTask",
  });
  const [markCritical, { data: markCriticalData }] = useCriticalTaskMutation({
    fixedCacheKey: "criticalTask",
  });
  useEffect(() => {
    setLoading(true);
    getAssignedPAListApi(currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, markCriticalData, pinTaskData]);

  useEffect(() => {
    if (rejectedTaskData?.nodes) {
      const { nodes, pageInfo } = rejectedTaskData;
      setAssignedByPATaskListingData(nodes);
      setLastPageNo(pageInfo?.lastPageNo);
      setLoading(false);
    }
    if (assignedByPATaskIsError && assignedByPATaskError) {
      showToast({
        message: JSON.stringify(assignedByPATaskError),
        type: "error",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rejectedTaskData, assignedByPATaskError]);
  const customActionCellRender = (params) => {
    const { taskNumber } = params?.row;
    const isSubtask = taskNumber?.startsWith(SUBTASK_PREFIX);
    const isCompleted = params?.row?.taskStatus === taskStatusType?.COMPLETED;

    const showCriticalFlag = isCompleted
      ? false
      : params?.row?.userDetails?.some(
          (details) => details?._id === userData?._id
        );

    return (
      <>
        <div className="headerMargin">
          <a
            href="/#"
            className="nav-link dropdown-toggle"
            data-toggle="dropdown"
            role="button"
            aria-haspopup="true"
            aria-expanded="false">
            <i className="fa fa-ellipsis-v manageIconColor" />
          </a>
          <div className="dropdown-menu dropdownAlignment">
            {showCriticalFlag && (
              <div
                className="manageIconColor ml-3 cursor-pointer dropdown-item"
                onClick={() => {
                  let body = {
                    taskId: params?.row?.taskId || params?.row?._id,
                    isCritical: params?.row?.hasFlagged ? false : true,
                  };
                  markCritical(body);
                }}>
                <span className={`manageIconColor mr-2 fa fa-flag-o`} />
                {params?.row?.hasFlagged
                  ? Translate("manageTask:removeCritical")
                  : Translate("manageTask:markasCritical")}
              </div>
            )}
            {!isCompleted && (
              <div
                className="manageIconColor ml-3 cursor-pointer dropdown-item"
                onClick={() => {
                  let body = {
                    taskId: params?.row?.taskId || params?.row?._id,
                  };
                  trigger(body);
                }}>
                <span className={`iconPinColorStyle mr-3 fa fa-thumb-tack`} />
                {params?.row?.hasPinned
                  ? Translate("manageTask:unPinTask")
                  : Translate("manageTask:pinTask")}
              </div>
            )}
            <div
              className="manageIconColor ml-3 cursor-pointer dropdown-item"
              onClick={() =>
                navigate(isSubtask ? "/subtask/view" : "/taskDetails", {
                  state: {
                    taskId: params?.row?.taskId,
                    isTaskFilter: true,
                    navigateFrom: "/relatedTaskListing",
                  },
                })
              }>
              <span className={`manageIconColor mr-2 fa fa-eye `} />
              {Translate("manageTask:viewTask")}
            </div>
          </div>
        </div>
      </>
    );
  };
  const customSrCellRender = (params) => {
    return (
      <StyledTypography component="span" sx={{ color: colors.primary_009 }}>
        {params?.api?.getRowIndexRelativeToVisibleRows(params?.row?._id) +
          1 +
          (currentPage - 1) * 10}
      </StyledTypography>
    );
  };
  return (
    <Grid
      item
      xs={12}
      className={`section-body ${isRtl ? "rtlMargin" : "ltrMargin"} ${
        isFixNavbar ? "marginTop" : ""
      } `}>
      <StyledCard variant="outlined">
        <div className="container-fluid">
          <div className="tab-content">
            <div className="card">
              <PageHeader
                containerClass="card-header card-header-spacing formTitle"
                title={
                  <h5 className="page-title">
                    {Translate("manageTask:assignedByPA")}
                  </h5>
                }
                onClick={() => navigate("/manageTask")}
              />
              <div className="card-body">
                <ListView
                  columns={getManagetTaskColumns(
                    customSrCellRender,
                    customActionCellRender,
                    false
                  )}
                  rows={assignedByPATaskListingData}
                  autoHeight
                  sortingMode="server"
                  onPaginationChange={setCurrentPage}
                  currentPage={currentPage}
                  showShimmer={loadingAssignedByPATask || loading}
                  showLoader={fetchingAssignedByPATaskData}
                  totalPageCount={lastPageNo}
                />
              </div>
            </div>
          </div>
        </div>
      </StyledCard>
    </Grid>
  );
};
export default AssignedByPATaskListing;

import React, { memo, useCallback, useEffect, useState } from "react";
import ImageViewer from "react-simple-image-viewer";
import Translate from "../../translation/utils/translateFunction";
import PDFViewer from "../common/PdfViewer";
import {
  imageExtensions,
  excelFormats,
  excelFileType,
  documentExtensions,
} from "./constants";
import { useSelector } from "react-redux";
import FOMImage from "../../assets/images/common/Image1 152_158.png";
import { iosImgTypes } from "../../common/Utils/ApiConstants";
import B64Alert from "../AlertPermission";

const CommonImage = ({
  files = [],
  removeFile,
  images,
  icon,
  disabled,
  commentIcon = false,
  containerStyle,
  isCompany = false,
}) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [isCompanyImage, setIsCompanyImage] = useState();
  const { isRtl } = useSelector((state) => state.formanagement);
  const [isIos, setIsIos] = useState(false);
  const [isXlsB64, setIsXlsB64] = useState(false);
  const [b64Array, setb64] = useState();
  const [selectedImages, setSelectedImages] = useState([]);
  const [imageIndexes, setImageIndexes] = useState({});

  useEffect(() => {
    if (images?.length) {
      // filter falsy values from images
      const filteredImages = images?.filter(
        (img) =>
          img &&
          typeof img === "string" &&
          !(
            img?.endsWith(".xls") ||
            img?.endsWith(".xlsx") ||
            img?.endsWith(".pdf")
          )
      );

      setSelectedImages(filteredImages);
    }
  }, [images]);

  useEffect(() => {
    if (files?.length) {
      // track image indexes separately
      generateImageIndex(files);
    }
  }, [files]);

  const generateImageIndex = (filesArr) => {
    let imgIdx = 0;
    const imgObj = {};

    for (let i = 0; i < filesArr?.length; i++) {
      const docType = validateDocument(filesArr[i]);

      if (!docType?.success) {
        imgObj[i] = imgIdx;
        imgIdx++;
      }
    }

    setImageIndexes(imgObj);
  };

  useEffect(() => {
    if (isCompany) {
      if (files[0].path) {
        files.map((file) => setIsCompanyImage(file?.type?.split("/")[1]));
      } else {
        const url = new URL(files[0]);
        const path = url.pathname;
        setIsCompanyImage(
          path.substring(path.lastIndexOf(".") + 1).toLocaleLowerCase()
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  const openImageViewer = useCallback((index, fileExtension) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
    setIsIos(iosImgTypes.includes(fileExtension));
  }, []);

  const openPdf = (file, fileExt) => {
    setShow(true);
    setb64([
      {
        uri: file?.b64 || file?.url,
        fileName: file?.taskFileName || file?.path,
        fileType:
          excelFormats.includes(fileExt) && file?.type === "application"
            ? excelFileType
            : file?.type,
        ...file,
      },
    ]);
  };
  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const validateDocument = (file) => {
    const isDoc = file?.type?.startsWith("application");

    if (!isDoc) return false;
    const docExt = file?.taskFileExt
      ? file?.taskFileExt
      : file?.path?.split(".").pop().toLocaleLowerCase();

    const result = {
      success: documentExtensions.includes(docExt),
      isXls: excelFormats.includes(docExt),
    };

    return result;
  };

  const closeXlsB64 = () => setIsXlsB64(false);

  const storedFiles = () => {
    return files.map((file, index) => {
      let fileExtension = file?.name
        ? file?.name?.split(".")
        : file?.type?.split("/")[1];
      fileExtension =
        !!fileExtension?.length && file?.name
          ? fileExtension[fileExtension?.length - 1]
          : fileExtension;
      if (files.length <= 10) {
        return (
          <div
            key={file?.name || file?.url || index}
            className={`${!isRtl && "commonImageNameDiv"}`}
          >
            <div
              style={containerStyle}
              onClick={() => {
                const docType = validateDocument(file);

                if (docType?.isXls && file?.b64) {
                  setIsXlsB64(true);
                  return false;
                }

                if (docType?.success) {
                  openPdf(file, fileExtension);
                } else {
                  openImageViewer(imageIndexes[index], fileExtension);
                }
              }}
              className={`${
                disabled ? "viewDocumentAttchment" : "heightImageli mb-2"
              } cursor-pointer`}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                {fileExtension === "pdf" || file?.type === "application/pdf" ? (
                  <i
                    className={
                      disabled
                        ? "fa fa-file-pdf-o  imageColor cursor-pointer"
                        : "fa fa-file-pdf-o imageIconColor cursor-pointer"
                    }
                  />
                ) : excelFormats.includes(fileExtension) ? (
                  <i
                    className={
                      disabled
                        ? "fa fa-file-excel-o  imageColor cursor-pointer"
                        : "fa fa-file-excel-o imageIconColor cursor-pointer"
                    }
                  />
                ) : commentIcon ? (
                  <i className="fe fe-paperclip EyeIconColor pr-2 cursor-pointer" />
                ) : (
                  <i
                    className={
                      disabled
                        ? "fa fa-photo imageColor cursor-pointer"
                        : "fa fa-photo imageIconColor cursor-pointer"
                    }
                  />
                )}

                {isCompany === false ? (
                  imageExtensions?.includes(fileExtension) ? (
                    <span className={disabled ? "pathName" : "fileNameColor"}>
                      {`${Translate("addOwner:image")}.${fileExtension}`}
                    </span>
                  ) : (
                    <span className={disabled ? "pathName" : "fileNameColor"}>
                      {`${Translate("addOwner:file")}.${fileExtension}`}
                    </span>
                  )
                ) : (
                  <span className={disabled ? "pathName" : "fileNameColor"}>
                    {`${Translate("addOwner:image")}.${isCompanyImage}`}
                  </span>
                )}
              </div>
              {icon ? (
                <i className="fa fa-eye icon EyeIconColor cursor-pointer"></i>
              ) : (
                <i
                  className="fe fe-x imageIconColor cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    removeFile(file);
                  }}
                />
              )}
            </div>
          </div>
        );
      }
    });
  };
  return (
    <>
      {storedFiles()}
      <div style={{ zIndex: 1100 }}>
        {isViewerOpen && (
          <ImageViewer
            src={isIos ? [FOMImage] : selectedImages}
            currentIndex={currentImage}
            onClose={closeImageViewer}
            closeOnClickOutside
            disableScroll={false}
            backgroundStyle={{
              backgroundColor: "rgba(0,0,0,0.9)",
            }}
          />
        )}
      </div>
      <PDFViewer
        b64Array={b64Array}
        files={b64Array?.length && b64Array[0]?.fileName}
        handleClose={handleClose}
        show={show}
      />
      {isXlsB64 && (
        <B64Alert
          show={isXlsB64}
          subTitle={Translate("common:xlsB64PreviewError")}
          onOk={closeXlsB64}
          isBtnAlignLeft={true}
          hideCancel={true}
        />
      )}
    </>
  );
};
export default memo(CommonImage);

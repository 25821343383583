import React, { useEffect, useState } from "react";
import PageHeader from "../../common/pageHeader";
import { useNavigate } from "react-router-dom";
import { Stack } from "@mui/material";
import { StyledTypography } from "../../../common/Utils/StyledComponents";
import CommonImage from "../../ImageGallery/CommonImage";
import NoDataFound from "../../ManageTask/NoDataFound";
import Translate from "../../../translation/utils/translateFunction";
import VoiceNotes from "../../VoiceNotes/Components/VoiceNotes";
import {
  useDeleteMomMutation,
  useMomDetailMutation,
} from "../../../request/Mom";
import { useLocation } from "react-router-dom";
import Loader from "../../staff/Owner/LoadingComp";
import { showToast } from "../../../common/Utils/ToastMessage";
import AlertPermission from "../../AlertPermission";
import { colors } from "../../../common/Theme/Colors";

const MomDetailView = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [points, setPoints] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [images, setImages] = useState([]);
  const [voiceNoteObj, setVoiceNoteObj] = useState();
  const [getMomDetail, { data: serverResponse, isSuccess, isLoading }] =
    useMomDetailMutation();

  useEffect(() => {
    if (state) {
      getMomDetail({ eventMomId: state?.eventid });
    }
  }, [state]);
  useEffect(() => {
    if (isSuccess && serverResponse?.data) {
      const { notes, attachment, isAttachment, voiceNote } =
        serverResponse?.data;
      setPoints(notes);
      isAttachment &&
        attachment?.length > 0 &&
        attachment.map((attachmentItem) => {
          const { eventMomFileName } = attachmentItem;
          const obj = {
            name: eventMomFileName,
            path: eventMomFileName,
            ...attachmentItem,
          };
          attachments.push(obj);
          images.push(attachmentItem?.url);
        });
      setAttachments([...attachments]);
      setImages([...images]);
      const voiceObj = {
        byteCode: voiceNote.buffer,
        timeLength: voiceNote.timeLength,
        // timeLengthInSec: voiceNote.timeLengthInSec?.toString(),
        src: voiceNote.url,
        createdAt: voiceNote.recordedTimeDateObject,
      };
      !!voiceNote?.buffer?.length && setVoiceNoteObj(voiceObj);
    }
  }, [isSuccess]);

  const EditAndLinkedItem = () => (
    <>
      <div
        className="cursor-pointer dropdown-item"
        onClick={() =>
          navigate("/mom/myevent/editEvent", {
            state: {
              data: serverResponse?.data,
              isEdit: true,
              isMyMom: state?.isMyMom,
            },
          })
        }>
        {Translate("common:editToolTip")}
      </div>
      <div className="dropdown-divider" />
      <div
        className="cursor-pointer dropdown-item"
        onClick={() =>
          navigate("/calendar/viewEvent", {
            state: {
              eventId: serverResponse?.data?.eventId,
              isMOM: true,
            },
          })
        }>
        {Translate("momPage:viewLinkedEvents")}
      </div>
      <div className="dropdown-divider" />
    </>
  );

  const SharedUserItem = () => (
    <div
      className="cursor-pointer dropdown-item"
      onClick={() =>
        navigate(
          `/mom/${state?.isShared ? "sharedWithMe" : "myevent"}/sharedUsers`,
          {
            state: serverResponse?.data?._id,
          }
        )
      }>
      {Translate("momPage:sharedUsersTitle")}
    </div>
  ); //sharedWithMe

  return (
    <div className="row container_spacing">
      <div className="card card_spacing">
        <PageHeader
          title={Translate("momPage:detailHeader")}
          onClick={() => navigate(-1)}
          RightContainer={() => (
            <Stack className="card-options">
              <a
                href="/#"
                className="nav-link dropdown-toggle"
                data-toggle="dropdown"
                role="button"
                aria-haspopup="true"
                aria-expanded="false">
                <i className="fa fa-ellipsis-v manageIconColor" />
              </a>
              <div className="dropdown-menu dropdownAlignment">
                {serverResponse?.data?.hasEditPermission ? (
                  <>
                    <EditAndLinkedItem />
                    <SharedUserItem />
                  </>
                ) : (
                  <SharedUserItem />
                )}
              </div>
            </Stack>
          )}
        />
        <div className="card-body">
          <Stack sx={{ backgroundColor: "#e9ecef" }} p={3}>
            {points.map((item) => (
              <Stack flexDirection={"row"} alignItems={"center"}>
                <div
                  style={{
                    width: 5,
                    height: 5,
                    borderRadius: 2.5,
                    marginRight: 5,
                    backgroundColor: colors.black,
                  }}
                />
                <StyledTypography sx={{ width: "98%" }}>
                  {item}
                </StyledTypography>
              </Stack>
            ))}
          </Stack>

          <StyledTypography className="taskHeading font-16" pt={3}>
            {Translate("manageTask:voiceNoteTitle")}
          </StyledTypography>

          <Stack
            className="CommentCard_spacing"
            sx={{ backgroundColor: "#e9ecef" }}
            p={3}>
            {voiceNoteObj?.src || voiceNoteObj?.timeLength ? (
              <div style={{ width: 450 }}>
                <VoiceNotes
                  voiceObj={voiceNoteObj}
                  className={"voiceNote_spacing"}
                />
              </div>
            ) : (
              !isLoading && (
                <NoDataFound
                  message={Translate("manageTask:noVoiceNote")}
                  isCenter
                  className="NoDataStyle"
                />
              )
            )}
          </Stack>

          <StyledTypography className="taskHeading font-16" pt={3}>
            {Translate("manageTask:attacheMenttitle")}
          </StyledTypography>

          <Stack
            className="CommentCard_spacing"
            sx={{ backgroundColor: "#e9ecef" }}
            p={3}>
            {attachments.length > 0 ? (
              <div className="AttachmentBoxStyling">
                <CommonImage images={images} files={attachments} icon={true} />
              </div>
            ) : (
              !isLoading && (
                <NoDataFound
                  message={Translate("manageTask:noAttachmentMsg")}
                  isCenter
                  className="NoDataStyle"
                />
              )
            )}
          </Stack>
        </div>
        <Loader loading={isLoading} />
      </div>
    </div>
  );
};
export default MomDetailView;

import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import { showToast } from "../../../common/Utils/ToastMessage";
import { userTypes } from "../../../common/users/userTypes";
import { useGetStaffGeneralManagerListingMutation } from "../../../request/Staff/Manager";
import Translate from "../../../translation/utils/translateFunction";
import CompanyListing from "../../ContactRepository/component";
import ListView from "../../ListView";
import {
  StyledCard,
  StyledTypography,
} from "../../../common/Utils/StyledComponents";
import { Grid, Stack } from "@mui/material";
import { getGMColumns } from "./Components/column.data";
import Searchbar from "../../common/Searchbar";
import { colors } from "../../../common/Theme/Colors";
import CompanyHeader from "../../../common/CompanyHeader";
import { checkModuleAccess } from "../../../common/Utils/commonFunction";

const GeneralManagerListing = ({ fixNavbar }) => {
  const navigate = useNavigate();

  const { selectedCompanyList, userData, isRtl } = useSelector(
    (state) => state.formanagement
  );

  const [search, setSearch] = useState("");

  const [loading, setLoading] = useState(false);

  const [managerDataFetched, setManagerDataFetched] = useState([]);

  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [selectedColumn, setSelectedColumn] = useState("name");
  const [sortBy, setSortBy] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const lastPage = useRef(0);
  const [
    getManagerListApi,
    {
      data: managerDataList,
      isLoading: loadingManagerData,
      isSuccess: successManagerList,
      isError: isManagerError,
      error: Error,
    },
  ] = useGetStaffGeneralManagerListingMutation();

  let requestObject = useMemo(() => {
    const companyIdArray = [];
    selectedCompanyList?.length > 0 &&
      selectedCompanyList.map((idItem) => companyIdArray.push(idItem.value));
    return companyIdArray?.length > 0
      ? sortBy !== null && sortBy !== undefined
        ? {
            companies: companyIdArray,
            searchValue: search,
            sortCoulmn: selectedColumn,
            sortDirection: sortBy ? 1 : -1,
            pageNo: currentPageNo,
          }
        : {
            companies: companyIdArray,
            searchValue: search,
            pageNo: currentPageNo,
          }
      : null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, sortBy, currentPageNo, selectedCompanyList]);

  useEffect(() => {
    if (successManagerList && managerDataList) {
      const { nodes, pageInfo } = managerDataList.data;
      setManagerDataFetched(nodes);

      lastPage.current = pageInfo.lastPageNo;
      setLoading(false);
    }
    if (isManagerError && Error) {
      Error?.originalStatus !== 500 &&
        showToast({
          message: Translate("common:apiFailedMessage"),
          type: "error",
        });
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successManagerList, managerDataList]);

  useEffect(() => {
    setCurrentPageNo(1);
  }, [selectedCompanyList]);

  useEffect(() => {
    const finalRequestObject = {
      requestBody: requestObject,
      pageNo: currentPageNo,
    };
    if (selectedCompanyList?.length > 0) {
      sortBy === null && setLoading(true);
      if (requestObject?.searchValue?.length) {
        debouncedSearch();
      } else {
        sortBy !== undefined && getManagerListApi(finalRequestObject);
      }
    } else {
      showToast({
        type: "info",
        message: Translate("manageTask:selectCompanyMessage"),
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestObject, currentPageNo]);

  const debouncedSearch = useDebouncedCallback(() => {
    const finalRequestObject = {
      requestBody: requestObject,
      pageNo: currentPageNo,
    };
    sortBy !== undefined && getManagerListApi(finalRequestObject);
  }, 500);

  const getSort = (columnName = "") => {
    setLoading(true);
    selectedColumn !== columnName && setSortBy(undefined);
    setTimeout(() => {
      selectedColumn !== columnName ? setSortBy(true) : setSortBy(!sortBy);
    }, 500);
    setSelectedColumn(columnName);
  };
  // const onSearch = () => {
  //   if (requestObject.searchValue.length) {
  //     const finalRequestObject = {
  //       requestBody: requestObject,
  //       pageNo: currentPageNo,
  //     };
  //     getManagerListApi(finalRequestObject);
  //   }
  // };

  const CustomActionCellRender = (params) => {
    return (
      <td>
        <i
          className="m-2 fa fa-eye cursor-pointer manageIconColor"
          onClick={() =>
            navigate("/viewGeneralManager", { state: params?.row?.userId })
          }
          data-toggle="tooltip"
          title={Translate("common:viewToolTip")}
        ></i>
      </td>
    );
  };
  const handleSearchText = (value) => {
    setCurrentPageNo(1);
    setSearch(value);
  };

  const CustomSrCellRender = (params) => {
    return (
      <StyledTypography component="span" sx={{ color: colors.primary_009 }}>
        {params?.api?.getRowIndexRelativeToVisibleRows(params?.row?.userId) +
          1 +
          (currentPageNo - 1) * 10}
      </StyledTypography>
    );
  };

  return (
    <>
      {/* *************************************************************************************************************************** */}
      <Grid
        item
        xs={12}
        className={`section-body ${isRtl ? "rtlMargin" : "ltrMargin"} ${
          fixNavbar ? "marginTop" : ""
        } `}
      >
        <StyledCard variant="outlined">
          <div className="container-fluid p-4">
            <div className="tab-content">
              <div>
                <div className="card">
                  <div className="card-header card-header-spacing">
                    <CompanyHeader setShowModal={setShowModal} />
                    <div className="card-options">
                      <div className="input-group">
                        <Searchbar
                          handleSearchText={handleSearchText}
                          setSearch={setSearch}
                        />
                      </div>

                      {checkModuleAccess(
                        userData?.add,
                        userTypes?.GeneralManager
                      ) && (
                        <div className="header-action pull-right ml-2">
                          <button
                            type="button"
                            className="btn btn-primary py-2"
                            onClick={() => navigate("/addGeneralManager")}
                          >
                            <i className="fe fe-plus" />
                            <StyledTypography className="mx-1" component="span">
                              {Translate("common:add")}
                            </StyledTypography>
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                  <ListView
                    columns={getGMColumns(
                      CustomSrCellRender,
                      CustomActionCellRender
                    )}
                    rows={managerDataFetched}
                    autoHeight
                    sortingMode="server"
                    sortBy={sortBy}
                    onPaginationChange={setCurrentPageNo}
                    currentPage={currentPageNo}
                    showShimmer={loadingManagerData}
                    showLoader={loading}
                    totalPageCount={lastPage.current}
                    onSortModelChange={(data) =>
                      data?.length && getSort(data[0]?.field)
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <CompanyListing
            handleModal={(value) => {
              setCurrentPageNo(1);
              setShowModal(value);
            }}
            showModal={showModal}
            selectedCompanyList={selectedCompanyList}
          />
        </StyledCard>
      </Grid>
    </>
  );
};
export default GeneralManagerListing;

import React, { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ReactComponent as LocationIcon } from "../../../assets/images/Location.svg";
import {
  userDefaultDropdownItem,
  userType,
} from "../../../common/Utils/ApiConstants";
import { StyledTypography } from "../../../common/Utils/StyledComponents";
import { showToast } from "../../../common/Utils/ToastMessage";
import {
  convertToB64,
  handleEventKeySubmit,
} from "../../../common/Utils/commonFunction";
import { useReportToListStaffMutation } from "../../../request/Staff/Manager";
import { useLazyGetCompanyListQuery } from "../../../request/company";
import Translate from "../../../translation/utils/translateFunction";
import AlertPermission from "../../AlertPermission";
import Dropzone from "../../CommonFileUpload/Dropzone";
import { FormikDropdownPicker } from "../../FormikForm/component/FormikDropDownPicker";
import { FormikPhoneInput } from "../../FormikForm/component/FormikPhoneInput";
import { FormikPhoneInputWithExtField } from "../../FormikForm/component/FormikPhoneInputWithExt";
import FormikSearchableDropDown from "../../FormikForm/component/FormikSearchableDropDown";
import { FormikTextField } from "../../FormikForm/component/FormikTextField";
import { Persona } from "../../Persona";
import { VALIDATION_BUCKETURL } from "../../Persona/constants";
import RadioButtons from "../../RadioButtons";
import { CustomUserItem } from "../../common/CustomCellRender";
import PageHeader from "../../common/pageHeader";
import Loader from "../../staff/Owner/LoadingComp";
import { radioOptions } from "./constants";

const VendorForm = ({
  isAdd,
  isView,
  isEdit,
  isLoading,
  vendorData,
  formikRef,
  values,
  setFieldValue,
  handleSubmit,
  setAddMoreVendor,
  filesAdded,
  setFilesAdded,
  images,
  setImages,
  deleteVendor,
  showAlert,
  setShowAlert,
  reportTo,
  setReportTo,
  selectedCompany,
  setSelectedCompany,
  setSelectedCompanyLabel,
}) => {
  const navigate = useNavigate();
  const { validations, userData } = useSelector(
    (state) => state?.formanagement
  );
  const [allCompanyData, setAllCompanyData] = useState([]);
  const [reportToList, setReportToList] = useState([]);
  const [searchTextReport, setSearchTextReport] = useState("");
  const [isReportOptionLoading, setIsReportOptionLoading] = useState(false);
  const [showRemoveProfileAlert, setShowRemoveProfileAlert] = useState(false);
  const [base64Img, setBase64Imge] = useState("");
  const [showReportToError, setShowReportToError] = useState(false);

  const [
    getCompanyList,
    { data: companyResponse, isLoading: isCompanyLoading },
  ] = useLazyGetCompanyListQuery();

  const [getReportToApi, { data: reportToData, isSuccess: isReportToSuccess }] =
    useReportToListStaffMutation();

  const acceptedFileType = {
    "image/png": [".png", ".jpg", ".jpeg"],
  };

  useEffect(() => {
    getCompanyList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (companyResponse) {
      const companyData = [];
      companyResponse.map((item) =>
        companyData.push({
          label: item.name,
          value: `${item._id}`,
        })
      );

      setAllCompanyData(companyData);
    }
    setReportToMe();
    return () => {
      setAllCompanyData([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyResponse]);

  useEffect(() => {
    if (selectedCompany && !isView) setIsReportOptionLoading(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCompany]);

  useEffect(() => {
    if (selectedCompany && !isView) {
      getReportToApi({
        companyId: selectedCompany,
        searchText: searchTextReport,
        role: "VENDOR",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCompany, searchTextReport]);

  useEffect(() => {
    if (reportToData && isReportToSuccess) {
      /* if (!reportToData?.success) {
        setShowReportToError(true);
        setReportToMe();
        setIsReportOptionLoading(false);
      } else {
        setSearchableData(reportToData?.data);
      } */
      setSearchableData(reportToData?.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportToData, isReportToSuccess]);

  useEffect(() => {
    if (vendorData?._id && isView && vendorData?.reportTo) {
      const { name, profileUrl, role, email } = vendorData?.reportTo;
      setReportTo({
        value: name,
        label: (
          <CustomUserItem
            userName={name}
            title={role ? `${name} | ${role}` : name}
            profileUrl={profileUrl}
            email={email}
          />
        ),
      });
    }
  }, [vendorData, isView]);

  const setReportToMe = () => {
    const reportToObj = {
      id: userData?._id,
      name: userData?.name,
      profileUrl: userData?.profileUrl,
      email: userData?.login?.email,
      role: userData?.role?.type,
      value: userDefaultDropdownItem.REPORT_TO_ME,
      label: (
        <CustomUserItem
          userName={userData?.name}
          title={userDefaultDropdownItem.REPORT_TO_ME}
          profileUrl={userData?.profileUrl}
        />
      ),
    };

    if (
      userData.role.type === userType.GeneralManager ||
      (userData.role.type === userType.PersonalAssistant &&
        userData.addedByRole === userType.GeneralManager)
    ) {
    } else {
      setReportToList([reportToObj]);
    }
  };

  const setSearchableData = (res) => {
    const reportToList = [];

    const reportToObj = {
      id: userData?._id,
      name: userData?.name,
      profileUrl: userData?.profileUrl,
      email: userData?.login?.email,
      role: userData?.role?.type,
      value: userDefaultDropdownItem.REPORT_TO_ME,
      label: (
        <CustomUserItem
          userName={userData?.name}
          title={userDefaultDropdownItem.REPORT_TO_ME}
          profileUrl={userData?.profileUrl}
        />
      ),
    };

    if (
      userData.role.type === userType.GeneralManager ||
      (userData.role.type === userType.PersonalAssistant &&
        userData.addedByRole === userType.GeneralManager)
    ) {
    } else {
      reportToList.push(reportToObj);
    }

    res?.length > 0 &&
      res.map((item) => {
        const { name, _id, profileUrl, role, email } = item;
        const obj = {
          id: _id,
          value: name,
          name,
          profileUrl,
          role: role,
          label: (
            <CustomUserItem
              userName={name}
              title={role ? `${name} | ${role}` : name}
              profileUrl={profileUrl}
              email={email}
            />
          ),
        };
        userData?._id !== _id && reportToList.push(obj);
      });

    setReportToList([...reportToList]);

    if (isView || isEdit) {
      const { reportTo } = vendorData;

      const assigneeOption = reportToList?.find(
        (item) => item?.id === reportTo?._id
      );

      if (assigneeOption) {
        formikRef.current.setFieldValue("reportTo", assigneeOption?.id);
        setReportTo({ ...assigneeOption });
      }
    }

    setIsReportOptionLoading(false);
  };

  const resetOnCompanyChange = () => {
    formikRef.current.setFieldValue("reportTo", "");
    setReportTo("");
  };

  const currentLocationData = (places) => {
    formikRef.current.setFieldValue("workAddress", places.formatted_address);
    formikRef.current.setFieldValue("latitude", places.geometry.location.lat());
    formikRef.current.setFieldValue(
      "longitude",
      places.geometry.location.lng()
    );

    places.address_components.map((ele) => {
      if (ele.types[0] === "country") {
        formikRef.current.setFieldValue("country", ele.long_name);
      }

      if (ele.types[0] === "administrative_area_level_1") {
        formikRef.current.setFieldValue("state", ele.long_name);
      }

      if (ele.types[0] === "locality") {
        formikRef.current.setFieldValue("city", ele.long_name);
      }

      if (ele.types[0] === "postal_code") {
        formikRef.current.setFieldValue("zipCode", ele.long_name);
      } else {
        formikRef.current.setFieldValue("zipCode", "");
      }
    });
  };

  const addFiles = async (file) => {
    if (file) {
      const base64 = await convertToB64(file);
      setBase64Imge(base64);
    }

    const { type } = file;
    const fileTypes = ["image/png", "image/png", "image/jpeg"];

    const url = fileTypes.includes(type) ? URL.createObjectURL(file) : null;

    if (url) file.preview = url;

    if (filesAdded.length < 1) {
      filesAdded.push(file);
      setFilesAdded([...filesAdded]);
      const newImages = filesAdded.map((imageElement) => imageElement.preview);
      setImages(newImages);
    } else {
      showToast({
        message: Translate("common:fileAddLimitMsg"),
        type: "error",
      });
    }
  };

  const removeFile = () => {
    setFilesAdded([]);
    setBase64Imge("");
    onCancelProfile();
  };

  const onCancel = () => setShowAlert(false);

  const onCancelProfile = () => setShowRemoveProfileAlert(false);

  const getPageTitle = () => {
    if (isView) {
      return Translate("vendors:viewVendorHeading");
    }

    if (isEdit) {
      return Translate("vendors:editVendorHeading");
    }

    return Translate("vendors:addVendorHeading");
  };

  return (
    <>
      <div className="row container_spacing">
        <div className="card card_spacing">
          <PageHeader
            title={getPageTitle()}
            onClick={() => {
              isEdit
                ? navigate("/vendors/view", { state: vendorData._id })
                : navigate("/vendors");
            }}
            RightContainer={() => (
              <>
                {isView && vendorData?.isEditable && (
                  <div className="card-options">
                    <i
                      className="icon-color ml-3 fa fa-pencil-square-o cursor-pointer"
                      onClick={() =>
                        navigate("/vendors/edit", {
                          state: vendorData,
                        })
                      }
                      data-toggle="tooltip"
                      title={Translate("common:editToolTip")}></i>
                  </div>
                )}

                {isEdit && vendorData?.isDeleteable && (
                  <div className="card-options">
                    <i
                      className="fe fe-trash-2 cursor-pointer"
                      onClick={() => setShowAlert(true)}
                      data-toggle="tooltip"
                      title={Translate("common:deleteToolTip")}></i>
                  </div>
                )}
              </>
            )}
          />
          <div
            className="card-body"
            onKeyDown={(e) => handleEventKeySubmit(e, handleSubmit)}>
            <div className="row imageDisplay mb-2">
              <div className="form-group col-md-4 col-sm-8">
                <Persona
                  isProfile={isAdd}
                  name={vendorData?.name}
                  isBase64={base64Img ? true : false}
                  image={
                    filesAdded?.length && images?.length
                      ? images[0].includes(VALIDATION_BUCKETURL)
                        ? images[0]
                        : base64Img
                      : ""
                  }
                  size={96}
                />
                {!isView && (
                  <Dropzone
                    onfileSelect={(filesArray) => addFiles(filesArray)}
                    acceptedImages={acceptedFileType}
                    maximumFiles={1}
                    fileLength={filesAdded.length}
                    maxFileSize={50 * 1000000}
                    className="staffProfileIcon"
                    isProfile
                    deleteAlert={() => {
                      setShowRemoveProfileAlert(true);
                    }}
                    isShowCameraDeleteIcon={
                      (isAdd || isEdit) && filesAdded?.length
                    }
                  />
                )}
              </div>
            </div>

            <div className="row">
              <div className="form-group col-md-4 col-sm-12">
                <FormikDropdownPicker
                  options={allCompanyData}
                  value={selectedCompany}
                  name="companyId"
                  label={Translate("vendors:selectCompany")}
                  placeholder={Translate("vendors:dropdownPlaceholder")}
                  onSelect={(value, label) => {
                    setSelectedCompanyLabel(label);
                    setSelectedCompany(value);
                    value !== selectedCompany && resetOnCompanyChange();
                  }}
                  disabled={isView || isEdit}
                  required={isAdd || isEdit}
                  autoFocus={isAdd}
                />
              </div>
              <div className="form-group col-md-4 col-sm-12">
                <FormikTextField
                  name="companyName"
                  label={Translate("vendors:companyName")}
                  placeholder={Translate("vendors:companyName")}
                  autoComplete={"off"}
                  maxLength={validations?.companyNameLimit.MAX}
                  disabled={isView}
                  required={isAdd || isEdit}
                />
              </div>
              <div className="form-group col-md-4 col-sm-12">
                <FormikTextField
                  name="name"
                  label={Translate("vendors:vendorName")}
                  placeholder={Translate("vendors:vendorName")}
                  autoComplete={"off"}
                  maxLength={100}
                  disabled={isView}
                  required={isAdd || isEdit}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-4 col-sm-12">
                <FormikTextField
                  name="designation"
                  label={Translate("vendors:designation")}
                  placeholder={Translate("vendors:designation")}
                  autoComplete={"off"}
                  maxLength={validations?.designationLength.MAX}
                  disabled={isView}
                />
              </div>
              <div className="form-group col-md-4 col-sm-12">
                <FormikTextField
                  name="department"
                  label={Translate("vendors:department")}
                  placeholder={Translate("vendors:department")}
                  autoComplete={"off"}
                  maxLength={validations?.departmentLength.MAX}
                  disabled={isView}
                />
              </div>
            </div>

            <div className="row">
              <div className="form-group col-md-4 col-sm-12">
                <FormikSearchableDropDown
                  selected={reportTo}
                  onSelect={(value) => {
                    setReportTo(value);
                    setFieldValue("reportTo", value.id);
                  }}
                  options={reportToList}
                  onChange={(text) => setSearchTextReport(text)}
                  placeholder={Translate("vendors:reportTo")}
                  label={Translate("vendors:reportTo")}
                  name="reportTo"
                  disabled={isView}
                  required={isAdd || isEdit}
                />
              </div>
              <div className="form-group col-md-4 col-sm-12">
                <FormikTextField
                  name="email"
                  label={Translate("vendors:workEmail")}
                  placeholder={Translate("vendors:workEmailPlaceholder")}
                  autoComplete={"off"}
                  maxLength={validations?.email.MAX}
                  disabled={isView}
                  required={isAdd || isEdit}
                />
              </div>
            </div>

            <div className="row">
              <div className="form-group col-md-4 col-sm-12">
                {isAdd || isEdit ? (
                  <FormikPhoneInput
                    label={Translate("vendors:contactNumber")}
                    name="mobile"
                    value={values.mobile}
                    placeholder={Translate("vendors:contactNumberPlaceHolder")}
                    maxLength={validations?.contactPhoneLength.MAX}
                    onChange={(value, country) => {
                      setFieldValue("mobile", value);
                      setFieldValue(
                        "mobileShortCode",
                        country?.countryCode?.toUpperCase()
                      );
                      setFieldValue("countryCode", country?.dialCode);
                    }}
                    autoComplete={"off"}
                    disabled={isView}
                    required={isAdd || isEdit}
                    isLogin
                  />
                ) : (
                  <FormikTextField
                    name="mobile"
                    value={vendorData?.login?.mobileWithCountryCode}
                    label={Translate("vendors:contactNumber")}
                    placeholder={Translate("vendors:contactNumberPlaceHolder")}
                    autoComplete={"off"}
                    maxLength={validations?.contactPhoneLength.MAX}
                    disabled={isView}
                  />
                )}
              </div>
              <div className="form-group col-md-4 col-sm-12">
                {isAdd || isEdit ? (
                  <FormikPhoneInput
                    label={Translate("vendors:alternateContactNumber")}
                    name="alternateMobile"
                    value={values.alternateMobile}
                    placeholder={Translate(
                      "vendors:alternateContactNumberPlaceHolder"
                    )}
                    maxLength={validations?.contactPhoneLength.MAX}
                    onChange={(value, country) => {
                      setFieldValue("alternateMobile", value);
                      setFieldValue(
                        "alternateMobileShortCode",
                        country?.countryCode?.toUpperCase()
                      );
                      setFieldValue(
                        "alternateMobileCountryCode",
                        country?.dialCode
                      );
                    }}
                    autoComplete={"off"}
                    disabled={isView}
                    isLogin
                  />
                ) : (
                  <FormikTextField
                    name="alternateMobile"
                    value={
                      vendorData?.alternateMobileWithCountryCode
                        ? vendorData?.alternateMobileWithCountryCode
                        : "NA"
                    }
                    label={Translate("vendors:alternateContactNumber")}
                    placeholder={Translate(
                      "vendors:alternateContactNumberPlaceHolder"
                    )}
                    maxLength={validations?.contactPhoneLength.MAX}
                    autoComplete={"off"}
                    disabled={isView}
                  />
                )}
              </div>

              <div className="form-group col-md-4 col-sm-12">
                <FormikPhoneInputWithExtField
                  name="companyNumber"
                  extName="companyExtension"
                  label={Translate("vendors:companyExtensionNumber")}
                  placeholder={Translate("vendors:extensionNumberPlaceholder")}
                  extPlaceholder={Translate(
                    "vendors:companyPhoneExtensionPlaceholder"
                  )}
                  autoComplete={"off"}
                  maxLength={validations?.telephoneLength.MAX}
                  extMaxLength={validations?.companyExtension.MAX}
                  disabled={isView}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-12 col-sm-12">
                <div className="AddressLabel">
                  <StyledTypography variant="subtitle1">
                    {Translate("vendors:workAddress")}
                    {(isAdd || isEdit) && (
                      <span style={{ color: "red" }}>{" *"}</span>
                    )}
                  </StyledTypography>
                </div>
                <FormikTextField
                  name="workAddress"
                  placeholder={Translate("vendors:addressPlaceholder_3")}
                  autoComplete={"off"}
                  isLocation={true}
                  RenderRightContainer={
                    !isView && <LocationIcon className="formikLocationIcon" />
                  }
                  locationValue={(currentLocation) => {
                    currentLocationData(currentLocation);
                  }}
                  maxLength={validations?.address.MAX}
                  disabled={isView}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-12 col-sm-12">
                <FormikTextField
                  name="flat"
                  placeholder={Translate("vendors:addressPlaceholder_4")}
                  autoComplete={"off"}
                  maxLength={validations?.landmarkLimit.MAX}
                  disabled={isView}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-4 col-sm-8">
                <FormikTextField
                  name="country"
                  disabled={isView}
                  placeholder={Translate("vendors:countryPlaceholder")}
                  maxLength={validations?.cityLimit.MAX}
                />
              </div>
              <div className="form-group col-md-4 col-sm-8">
                <FormikTextField
                  name="state"
                  disabled={isView}
                  placeholder={Translate("vendors:statePlaceholder")}
                  maxLength={validations?.cityLimit.MAX}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-4 col-sm-8">
                <FormikTextField
                  name="city"
                  placeholder={Translate("vendors:cityPlaceholder")}
                  maxLength={validations?.cityLimit.MAX}
                  disabled={isView}
                />
              </div>
              <div className="form-group col-md-4 col-sm-8">
                <FormikTextField
                  name="zipCode"
                  placeholder={Translate("vendors:zipcodePlaceholder")}
                  disabled={isView}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-4 col-sm-8">
                {(isAdd || isEdit || (isView && vendorData?.gender)) && (
                  <RadioButtons
                    radioOptions={radioOptions}
                    defaultChecked={vendorData?.gender === "FEMALE" ? 1 : 0}
                    label={Translate("addPersonalAssistant:gender")}
                    onPress={(value) => {
                      setFieldValue("gender", value.target.value);
                    }}
                    disabled={isView}
                    required={isAdd || isEdit}
                  />
                )}
              </div>
            </div>

            {(isAdd || isEdit) && (
              <div className="card-footer text-right">
                <button
                  type="submit"
                  className="btn btn-primary saveButton"
                  onClick={() => {
                    handleSubmit();
                    isAdd && setAddMoreVendor(false);
                  }}>
                  {Translate("vendors:save")}
                </button>
                {isAdd && (
                  <button
                    type="submit"
                    className="btn btn-white addButton"
                    onClick={() => {
                      handleSubmit();
                      setAddMoreVendor(true);
                    }}>
                    {Translate("vendors:addMore")}
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <Loader
        loading={isLoading || isCompanyLoading || isReportOptionLoading}
      />
      <AlertPermission
        show={showAlert}
        subTitle={Translate("vendors:deleteAlert")}
        onOk={deleteVendor}
        onCanel={onCancel}
      />
      <AlertPermission
        show={showRemoveProfileAlert}
        subTitle={Translate("common:deleteProfileAlert")}
        onOk={removeFile}
        onCanel={onCancelProfile}
      />
      {showReportToError && (
        <AlertPermission
          show={showReportToError}
          subTitle={reportToData?.message}
          onOk={() => setShowReportToError(false)}
          isBtnAlignLeft
          hideCancel
        />
      )}
    </>
  );
};

export default memo(VendorForm);

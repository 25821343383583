import { Methods, Urls } from "../../../common/Utils/ApiConstants";

import { CreateApi } from "../../CreateApi";
const staffemployeecollection = CreateApi.injectEndpoints({
  endpoints: (builder) => ({
    addStaffEmployeeData: builder.mutation({
      query: (body) => {
        return {
          url: Urls.addStaffEmployee,
          method: Methods.post,
          body: body,
        };
      },
    }),
    getEmployeeListing: builder.mutation({
      query: ({ requestBody, pageNo }) => {
        return {
          url: Urls.getEmployeeList,
          method: Methods.post,
          body: requestBody,
          params: { pageNo },
        };
      },
    }),
    viewEmployeeData: builder.mutation({
      query: (body) => {
        return {
          url: `${Urls.viewEmployee}${body}`,
          method: Methods.get,
        };
      },
    }),
    rateEmployee: builder.mutation({
      query: (body) => {
        return {
          url: `${Urls.rateEmployee}${body.id}`,
          method: Methods.put,
          body: body?.body,
        };
      },
    }),
    editEmployee: builder.mutation({
      query: (body) => {
        return {
          url: `${Urls.editEmployee}${body.id}`,
          method: Methods.put,
          body: body?.body,
        };
      },
    }),
    deleteEmployeeData: builder.mutation({
      query: (body) => {
        return {
          url: `${Urls.deleteEmployee}${body}`,
          method: Methods.delete,
        };
      },
    }),
  }),
});
export const {
  useAddStaffEmployeeDataMutation,
  useGetEmployeeListingMutation,
  useViewEmployeeDataMutation,
  useRateEmployeeMutation,
  useEditEmployeeMutation,
  useDeleteEmployeeDataMutation,
} = staffemployeecollection;

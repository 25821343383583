import { Stack } from "@mui/material";
import React from "react";
import { StyledTypography } from "../../../common/Utils/StyledComponents";
import Translate from "../../../translation/utils/translateFunction";
import IconifyIcon from "../../Icon";
import { tabType } from "./constants";
import Searchbar from "../../common/Searchbar";

const { CHAT_LIST, GROUP_LIST } = tabType;

const MessageHeader = ({
  handleSelectTab,
  selectedTab,
  setSearchText,
  onAddNewChat,
  onAddNewGroup,
  isNewChat = false,
  isNewGroup,
  onGoBack,
  searchText = "",
  isAddGroup,
}) => {
  const handleSearchText = (value) => {
    setSearchText && setSearchText(value);
  };
  return (
    <>
      <a href="/#" className="chat_list_btn float-right">
        <i className="fa fa-align-right" />
      </a>
      {isNewChat || isNewGroup ? (
        <Stack flexDirection={"row"} alignItems={"center"} paddingY={1}>
          <i
            className="icon fe fe-arrow-left mr-3 icon-size backnavigation-iconColor cursor-pointer"
            title={Translate("common:backToolTip")}
            onClick={() => onGoBack()}
          />
          <StyledTypography variant="h6">
            {isNewChat
              ? Translate("contacts:selectContacts")
              : Translate("group:createGroup")}
          </StyledTypography>
        </Stack>
      ) : (
        <Stack
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}>
          <ul
            className="nav nav-tabs"
            style={{ margin: "0 -0.25rem" }}
            role="tablist">
            <li className="nav-item" onClick={() => handleSelectTab(CHAT_LIST)}>
              <a
                className={`nav-link ${selectedTab === CHAT_LIST && "active"}`}
                id="users-tab"
                data-toggle="tab"
                href={`#${selectedTab}`}
                role="tab"
                aria-controls={selectedTab}
                aria-selected="true">
                {Translate("messagePage:people")}
              </a>
            </li>
            <li
              className="nav-item"
              onClick={() => handleSelectTab(GROUP_LIST)}>
              <a
                className={`nav-link ${selectedTab === GROUP_LIST && "active"}`}
                id="groups-tab"
                data-toggle="tab"
                href={`#${selectedTab}`}
                role="tab"
                aria-controls={selectedTab}
                aria-selected="false">
                {Translate("messagePage:groups")}
              </a>
            </li>
          </ul>

          {selectedTab === CHAT_LIST && (
            <i
              className="fa fa-plus cursor-pointer mr-2"
              title="Add new chat"
              style={{ fontSize: "20px" }}
              onClick={onAddNewChat}
            />
          )}

          {selectedTab === GROUP_LIST && (
            <span data-toggle="tooltip" title={Translate("group:createGroup")}>
              <IconifyIcon
                icon="material-symbols:group-add"
                style={{ fontSize: "20px" }}
                className="cursor-pointer mr-2"
                onClick={onAddNewGroup}
              />
            </span>
          )}
        </Stack>
      )}
      {!isAddGroup && (
        <div className="input-group" style={{ width: "98%" }}>
          <Searchbar
            handleSearchText={handleSearchText}
            setSearch={setSearchText}
            searchText={searchText}
            isMessageModule={true}
          />
          {searchText && (
            <IconifyIcon
              icon="ion:close-outline"
              className="cursor-pointer"
              onClick={() => setSearchText?.("")}
              style={{
                position: "absolute",
                right: "10px",
                top: "50%",
                transform: "translateY(-50%)",
                zIndex: 9,
              }}
              fontSize={25}
            />
          )}
        </div>
      )}
    </>
  );
};
export default MessageHeader;

import Lottie from "lottie-react";
import React from "react";
import greenZoneAnimation from "../../../../../src/assets/gif/greenZone.json";
import redZoneAnimation from "../../../../../src/assets/gif/redZone.json";
import { colors } from "../../../../common/Theme/Colors";
import Translate from "../../../../translation/utils/translateFunction";
import { convertToSentenceCase } from "../../../../common/Utils/commonFunction";

const PerformanceCardView = ({ data }) => {
  const renderKeyValuePairs = (pairs) => {
    return pairs.map((pair) => {
      const value =
        typeof pair.value === "string"
          ? convertToSentenceCase(pair?.value)
          : pair?.value;
      return (
        <li>
          <label>{pair.label}</label> - <span>{value || ""}</span>
        </li>
      );
    });
  };

  const leftSectionData = [
    { label: Translate("performanceReport:name"), value: data?.name },
    {
      label: Translate("performanceReport:companyName"),
      value: data?.companyName,
    },
  ];

  const rightSectionData = [
    { label: Translate("performanceReport:role"), value: data?.role },
    {
      label: Translate("performanceReport:totalAssigned"),
      value: data?.totalAssignedNumber,
    },
  ];

  const [zoneColor, zoneAnimation, zoneName] =
    data?.zone === "GREEN"
      ? [colors.green, greenZoneAnimation, "green"]
      : [colors.red, redZoneAnimation, "red"];

  return (
    <div className="card">
      <div className="card-header">
        <Lottie
          animationData={zoneAnimation}
          loop={true}
          style={{ width: 25, height: 25, marginRight: 10 }}
        />
        <h6 className="performanceInfoTitle" style={{ color: zoneColor }}>
          {data?.name +
            Translate("performanceReport:isIn") +
            Translate(
              `common:${zoneName === "green" ? "greenZone" : "redZone"}`
            )}
        </h6>
      </div>
      <div className="card-body">
        <ul className="list-group">
          {renderKeyValuePairs(leftSectionData)}
          {renderKeyValuePairs(rightSectionData)}
        </ul>
      </div>
    </div>
  );
};

export default PerformanceCardView;

import { CircularProgress, Grid, Stack } from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useDebouncedCallback } from "use-debounce";
import { colors } from "../../../common/Theme/Colors";
import {
  StyledCard,
  StyledTypography,
} from "../../../common/Utils/StyledComponents";
import { showToast } from "../../../common/Utils/ToastMessage";
import {
  useGetEmployeeLogsExportsMutation,
  useGetEmployeeLogsMutation,
} from "../../../request/ActivityLogs";
import Translate from "../../../translation/utils/translateFunction";
import CompanyListing from "../../ContactRepository/component";
import DateRangePicker from "../../DateRangePicker";
import IconifyIcon from "../../Icon";
import ListView from "../../ListView";
import { getUserColumns } from "../Components/column.data";
import { formatDateYYYYMMDD } from "../../../common/Utils/DateFormater";
import CompanyHeader from "../../../common/CompanyHeader";
import Searchbar from "../../common/Searchbar";
import { getMoment } from "../../../common/Utils/commonFunction";
import { CSVLink } from "react-csv";

const EmployeeActivityLogs = ({ fixNavbar }) => {
  const { selectedCompanyList, isRtl } = useSelector(
    (state) => state.formanagement
  );

  const [isLoading, setIsLoading] = useState(false);
  const [lastPage, setLastPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [employeeLogs, setEmployeeLogs] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [csvData, setCSVData] = useState([]);
  const csvLink = useRef();
  const [
    fetchEmployeeLogApi,
    {
      data: employeeLogList,
      isLoading: isEmployeeLogLoading,
      isError: isEmployeeLogError,
      error: employeeLogError,
    },
  ] = useGetEmployeeLogsMutation();

  const [
    getEmployeeActivityLogsExports,
    {
      data: employeeLogListExports,
      isLoading: isEmployeeLogLoadingExports,
      isError: isEmployeeLogErrorExports,
      error: employeeLogErrorExports,
    },
  ] = useGetEmployeeLogsExportsMutation();
  const parseCSV = (csvData) => {
    const rows =
      csvData &&
      csvData
        .split(/\r?\n(?=(?:(?:[^"]*"){2})*[^"]*$)/)
        .map((row) =>
          row
            .split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/)
            .map((cell) => cell.replace(/^"|"$/g, ""))
        );

    return rows;
  };

  useEffect(() => {
    if (employeeLogListExports?.length > 0) {
      const rowArray = parseCSV(employeeLogListExports);
      if (rowArray) {
        setCSVData(rowArray);
      }
    }

    if (employeeLogErrorExports) {
      const errorResponse = JSON.parse(employeeLogErrorExports?.data);

      showToast({
        message:
          JSON.stringify(errorResponse?.message) ||
          Translate("common:unknownServerError"),
        type: "error",
      });
    }
  }, [employeeLogListExports, employeeLogErrorExports]);

  const requestObj = useMemo(() => {
    const companies = selectedCompanyList?.length
      ? selectedCompanyList?.map((item) => item?.value)
      : [];

    const finalReq = {
      companyId: companies,
      searchText,
      from: startDate ? formatDateYYYYMMDD(startDate) : "",
      to: endDate ? formatDateYYYYMMDD(endDate) : "",
      pageNo: currentPage,
    };

    return companies?.length ? finalReq : null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, searchText, selectedCompanyList, endDate]);

  const debouncedSearch = useDebouncedCallback(() => {
    fetchEmployeeLogApi(requestObj);
  }, 500);

  useEffect(() => {
    setEmployeeLogs([]);

    if (selectedCompanyList?.length) {
      setIsLoading(true);

      if (requestObj?.searchText?.length) {
        debouncedSearch();
      } else {
        fetchEmployeeLogApi(requestObj);
      }
    } else {
      showToast({
        type: "info",
        message: Translate("common:selectCompanyMessage"),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestObj]);

  useEffect(() => {
    if (employeeLogList?.data?.nodes) {
      const { nodes, pageInfo } = employeeLogList.data;
      setEmployeeLogs(nodes);
      setLastPage(pageInfo.lastPageNo);
      setIsLoading(false);
    }

    if (employeeLogError && isEmployeeLogError) {
      employeeLogError?.originalStatus !== 500 &&
        showToast({
          message:
            JSON.stringify(employeeLogError.data) ||
            Translate("common:unknownServerError"),
          type: "error",
        });
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeLogList, employeeLogError]);

  const handleExportTask = () => {
    getEmployeeActivityLogsExports(requestObj);
  };
  useEffect(() => {
    if (csvData?.length > 0) {
      // eslint-disable-next-line no-unused-expressions
      csvLink?.current?.link?.click();
    }
  }, [csvData]);

  const customSrCellRender = (params) => {
    return (
      <StyledTypography component="span" sx={{ color: colors.primary_009 }}>
        {params?.api?.getRowIndexRelativeToVisibleRows(params?.row?._id) +
          1 +
          (currentPage - 1) * 10}
      </StyledTypography>
    );
  };

  const customDueDateCellRender = (params) => {
    const { date, time } = params?.row;
    return (
      <td>
        <div className="text-info">{date || ""}</div>
        <div className="text-pink">{time || ""}</div>
      </td>
    );
  };

  const showFilters = () => {
    setShowFilter(!showFilter);
    setDateRange([null, null]);
  };
  const handleSearchText = (value) => {
    setCurrentPage(1);
    setSearchText(value);
  };

  return (
    <Grid
      item
      xs={12}
      className={`section-body ${isRtl ? "rtlMargin" : "ltrMargin"} ${
        fixNavbar ? "marginTop" : ""
      } `}>
      <StyledCard variant="outlined">
        <div className="container-fluid p-4">
          <div className="tab-content">
            <div>
              <div className="card">
                <div className="card-header card-header-spacing">
                  <CompanyHeader setShowModal={setShowModal} />

                  <div className="card-options">
                    <div className="input-group">
                      <div className="filterContainer">
                        <div className="dateRangeBox">
                          {showFilter && (
                            <DateRangePicker
                              setDateRange={(value) => {
                                setCurrentPage(1);
                                setDateRange(value);
                              }}
                              startDate={startDate}
                              endDate={endDate}
                              className="dateRangeFormControl"
                              maxDate={getMoment().toDate()}
                            />
                          )}
                        </div>
                        <div className="filterIconBox" onClick={showFilters}>
                          {showFilter ? (
                            <span
                              data-toggle="tooltip"
                              title={Translate("common:clearFilterToolTip")}>
                              <IconifyIcon
                                icon="mdi:filter-off"
                                width="19"
                                className="dateRangeFilterIcon"
                              />
                            </span>
                          ) : (
                            <span
                              data-toggle="tooltip"
                              title={Translate("common:showFilterToolTip")}>
                              <IconifyIcon
                                icon="fa:filter"
                                width="14"
                                className="dateRangeFilterIcon"
                              />
                            </span>
                          )}
                        </div>
                      </div>

                      <Searchbar
                        handleSearchText={handleSearchText}
                        setSearch={setSearchText}
                      />
                      <div className="header-action pull-right ml-2">
                        <button
                          type="button"
                          className="btn btn-primary saveButton py-2"
                          onClick={handleExportTask}
                          disabled={!employeeLogs?.length}>
                          {isEmployeeLogLoadingExports ? (
                            <div className="align-self-center">
                              <CircularProgress size={22} color="inherit" />
                            </div>
                          ) : (
                            <>
                              <i className="fe fe-download" />
                              <StyledTypography
                                className="mx-1"
                                component="span">
                                {Translate("common:exportLabel")}
                              </StyledTypography>
                            </>
                          )}
                        </button>

                        {csvData?.length > 0 && (
                          <CSVLink
                            data={csvData}
                            ref={csvLink}
                            filename={`${Translate(
                              "activityLog:exportEmployeeActivityLogsTitle"
                            )}_${formatDateYYYYMMDD(new Date())}.csv`}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <ListView
                    columns={getUserColumns(
                      customSrCellRender,
                      customDueDateCellRender
                    )}
                    rows={employeeLogs}
                    autoHeight
                    sortingMode="server"
                    onPaginationChange={setCurrentPage}
                    currentPage={currentPage}
                    showShimmer={isLoading || isEmployeeLogLoading}
                    showLoader={isLoading}
                    totalPageCount={lastPage}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </StyledCard>
      <CompanyListing
        handleModal={(value) => {
          setCurrentPage(1);
          setShowModal(value);
        }}
        showModal={showModal}
        selectedCompanyList={selectedCompanyList}
      />
    </Grid>
  );
};

export default EmployeeActivityLogs;

import * as yup from "yup";
import Translate from "../../../translation/utils/translateFunction";

export const DisapprovedReasonSchema = () => {
  const schema = yup.object().shape({
    disapproveReason: yup
      .string()
      .trim()
      .required(Translate("taskDetails:addReasonError")),
  });

  return schema;
};
export const InitialValuesForRejected = {
  disapproveReason: "",
};

import React, { useEffect, useRef } from "react";
import ProjectForm from "../components/ProjectForm";
import { Formik } from "formik";
import { addProjectSchema } from "../components/utils";
import { InitialValues } from "../components/constants";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useAddProjectMutation } from "../../../request/Project";
import { showToast } from "../../../common/Utils/ToastMessage";
import Translate from "../../../translation/utils/translateFunction";

const AddProject = () => {
  const navigate = useNavigate();
  const formikRef = useRef(null);

  const { selectedCompanyList } = useSelector((state) => state?.formanagement);

  const [
    addProjectApi,
    {
      data: projectData,
      isSuccess: isAddSuccess,
      isError: isAddError,
      error: addError,
      isLoading: isAddLoading,
    },
  ] = useAddProjectMutation();

  useEffect(() => {
    if (selectedCompanyList?.length) {
      InitialValues.companyId = selectedCompanyList[0].value;
      formikRef.current.setFieldValue("companyId", selectedCompanyList[0].value);
    }
  }, [selectedCompanyList]);

  useEffect(() => {
    if (isAddSuccess && projectData) {
      showToast({ message: projectData?.message, type: "success" });
      formikRef.current.resetForm();
      navigate("/");
    }

    if (isAddError && addError) {
      addError.data.error && addError.data.error.length > 0
        ? addError.data.error.map((errorItem) => {
            if (!InitialValues.hasOwnProperty(errorItem?.param)) {
              return showToast({ message: errorItem.msg, type: "error" });
            }
            return formikRef.current?.setFieldError(errorItem.param, errorItem.msg);
          })
        : showToast({
            message: addError?.data.message || Translate("common:unknownServerError"),
            type: "error",
          });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAddSuccess, isAddError, addError, projectData]);

  const onSubmit = (formValues) => {
    const payload = {
      companyId: [formValues?.companyId],
      title: formValues.title,
      description: formValues.description,
    };

    addProjectApi(payload);
  };

  return (
    <Formik
      initialValues={InitialValues}
      innerRef={formikRef}
      validateOnMount
      onSubmit={onSubmit}
      validationSchema={addProjectSchema}
    >
      {({ values, handleSubmit }) => {
        return (
          <ProjectForm isAdd values={values} handleSubmit={handleSubmit} isLoading={isAddLoading} />
        );
      }}
    </Formik>
  );
};

export default AddProject;

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Translate from "../../../translation/utils/translateFunction";
import PageHeader from "../../common/pageHeader";
import AddMomRenderer from "../components/addMomRenderer";
import {
  useAddMomMutation,
  useDeleteMomMutation,
  useEditMomMutation,
} from "../../../request/Mom";
import { Button } from "react-bootstrap";
import { getNameAndExtFromFile } from "../../../common/Utils/commonFunction";
import { showToast } from "../../../common/Utils/ToastMessage";
import Loader from "../../staff/Owner/LoadingComp";
import AlertPermission from "../../AlertPermission";
import { Stack } from "@mui/material";

const MomEdit = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [pointArray, setPointArray] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [images, setImages] = useState([]);
  const [voiceNoteObj, setVoiceNoteObj] = useState();
  const [show, setShow] = useState(false);
  const [editMom, { data: serverResponse, isSuccess, isLoading }] =
    useEditMomMutation();
  const [
    deleteMom,
    {
      data: serverResponseDelete,
      isSuccess: isDeleteSuccess,
      isLoading: isDeleteLoading,
    },
  ] = useDeleteMomMutation();
  const [
    addMomDetail,
    {
      data: serverResponseMom,
      isSuccess: isMomSuccess,
      isLoading: isMomLoading,
    },
  ] = useAddMomMutation();
  useEffect(() => {
    if (isSuccess && serverResponse) {
      showToast({ message: serverResponse?.message });
      onCancel();
    }
  }, [isSuccess]);
  useEffect(() => {
    if (isMomSuccess && serverResponseMom?.data) {
      showToast({ message: serverResponseMom?.message, type: "success" });
      onCancel();
    }
  }, [isMomSuccess]);
  const showDeleteModal = () => setShow(true);
  const hideDeleteModal = () => setShow(false);
  const onCancel = () => navigate(-1);
  useEffect(() => {
    if (isDeleteSuccess && serverResponseDelete?.success == true) {
      hideDeleteModal();
      showToast({ message: serverResponseDelete?.message });
      state?.isMyMom ? navigate("/mom/myevent") : navigate("/mom/sharedWithMe");
    }
  }, [isDeleteSuccess]);
  const onDelete = () => {
    const requestObj = { eventMomId: state?.data?._id };
    deleteMom(requestObj);
  };
  const onSave = () => {
    const fileArray = [];
    attachments.length &&
      attachments.map((fileItem) => {
        const obj = {
          url: fileItem?.b64 ? fileItem?.b64 : fileItem?.url,
          type: fileItem?.type,
          eventMomFileName: fileItem?.name || fileItem?.path,
          eventMomFileExt: fileItem?.eventMomFileName
            ? fileItem?.eventMomFileName
            : getNameAndExtFromFile(fileItem?.name)?.length &&
              getNameAndExtFromFile(fileItem?.name)[1],
        };
        fileArray.push(obj);
      });

    const requestObj = state?.isEdit
      ? {
          notes: pointArray,
          webAttachment: fileArray,
          isAttachment: !!fileArray.length,
          voiceNote: voiceNoteObj?.src ? state?.data?.voiceNote : {},
          eventId: state?.data?.eventId,
          eventMomId: state?.data?._id,
        }
      : {
          notes: pointArray,
          webAttachment: fileArray,
          isAttachment: !!fileArray.length,
          voiceNote: {},
          eventId: state?.data?._id,
          eventSubject: state?.data?.subject,
          eventDescription: state?.data?.description,
          eventaddedBy: state?.data?.addedBy,
        };
    state?.isEdit ? editMom(requestObj) : addMomDetail(requestObj);
  };
  useEffect(() => {
    if (state?.data && state?.isEdit) {
      const { notes, attachment, isAttachment, voiceNote } = state?.data;
      setPointArray(notes);
      isAttachment &&
        attachment?.length > 0 &&
        attachment.map((attachmentItem) => {
          const { eventMomFileName } = attachmentItem;
          const obj = {
            name: eventMomFileName,
            path: eventMomFileName,
            ...attachmentItem,
          };
          attachments.push(obj);
          images.push(attachmentItem?.url);
        });
      setAttachments([...attachments]);
      setImages([...images]);
      const voiceObj = {
        byteCode: voiceNote.buffer,
        timeLength: voiceNote.timeLength,
        src: voiceNote.url,
        createdAt: voiceNote.recordedTimeDateObject,
      };
      !!voiceNote?.buffer?.length && setVoiceNoteObj(voiceObj);
    }
  }, [state]);
  return (
    <div className="row container_spacing">
      <div className="card card_spacing">
        <PageHeader
          title={
            state?.isEdit
              ? Translate("momPage:editMom")
              : Translate("common:addmom")
          }
          onClick={onCancel}
          RightContainer={() =>
            state?.data?.hasEditPermission && (
              <Stack className="card-options">
                <a
                  href="/#"
                  className="nav-link dropdown-toggle"
                  data-toggle="dropdown"
                  role="button"
                  aria-haspopup="true"
                  aria-expanded="false">
                  <i className="fa fa-ellipsis-v manageIconColor" />
                </a>
                <div className="dropdown-menu dropdownAlignment">
                  <div
                    className="cursor-pointer dropdown-item"
                    onClick={showDeleteModal}>
                    {Translate("common:delete")}
                  </div>
                </div>
              </Stack>
            )
          }
        />
        <div className="card-body col-lg-5">
          <AddMomRenderer
            filesAdded={attachments}
            pointArray={pointArray}
            setFilesAdded={setAttachments}
            setPointArray={setPointArray}
            imagesArray={images}
            voiceNote={voiceNoteObj}
            setVoiceNote={setVoiceNoteObj}
            isEdit={state?.isEdit}
          />
        </div>
        <div className="card-footer text-right">
          <Button
            variant="btn btn-white"
            className="addButton mx-3"
            onClick={onCancel}>
            {Translate("common:cancel")}
          </Button>
          <Button
            variant="btn btn-primary"
            className="saveButton"
            onClick={onSave}
            disabled={pointArray.length < 1}>
            {Translate("common:save")}
          </Button>
        </div>
        <Loader loading={isLoading || isMomLoading || isDeleteLoading} />
        {show && (
          <AlertPermission
            show={show}
            subTitle={Translate("momPage:alert")}
            okTitle={Translate("common:delete")}
            onOk={onDelete}
            onCanel={hideDeleteModal}
          />
        )}
      </div>
    </div>
  );
};
export default MomEdit;

import { Methods, Urls } from "../../common/Utils/ApiConstants";
import { CreateApi } from "../CreateApi";

const NotificationCollection = CreateApi.injectEndpoints({
  endpoints: (builder) => ({
    getNotificationList: builder.mutation({
      query: ({ pageNo }) => {
        return {
          url: Urls.getNotificationList,
          method: Methods.post,
          body: {},
          params: { pageNo },
        };
      },
    }),
  }),
});

export const { useGetNotificationListMutation } = NotificationCollection;

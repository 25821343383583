import { Grid, Stack } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { StyledCard } from "../../../../common/Utils/StyledComponents";
import ListView from "../../../ListView";

import { useLocation, useNavigate } from "react-router-dom";
import Translate from "../../../../translation/utils/translateFunction";

import { showToast } from "../../../../common/Utils/ToastMessage";
import { useGetAccessLogsMutation } from "../../../../request/ContactRepo";

import { getContactRepoAccessLogsColumn } from "../../PrivateListing/AccessLogs/column.data";
import { useSelector } from "react-redux";
import PageHeader from "../../../common/pageHeader";

const PublicListAccessLogs = ({ fixNavbar }) => {
  const { isRtl } = useSelector((state) => state.formanagement);
  const [loading, setLoading] = useState(true);
  const [accessLogs, setAccessLogs] = useState([]);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const navigate = useNavigate();

  const lastPage = useRef(0);
  const { state } = useLocation();
  const sortBy = null;
  const [
    getAccessLogsApi,
    {
      data: accesslogsData,
      isSuccess: successfullAccessLogsList,
      isLoading: loadingAccessLogsList,
      isError: isErrorAccessLogs,
      error: errorAccessLogs,
    },
  ] = useGetAccessLogsMutation();

  useEffect(() => {
    if (successfullAccessLogsList && accesslogsData) {
      const { nodes, pageInfo } = accesslogsData?.data;

      lastPage.current = pageInfo.lastPageNo;
      setAccessLogs(nodes);
      setLoading(false);
    }
    if (isErrorAccessLogs && errorAccessLogs) {
      setLoading(false);
      showToast({
        message: Translate("contacts:apiFailedMessage"),
        type: "error",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accesslogsData, successfullAccessLogsList]);

  useEffect(() => {
    setLoading(true);
    if (state) {
      const finalRequestObject = {
        pageNo: currentPageNo,
        documentId: state,
        action: "READ",
      };
      getAccessLogsApi(finalRequestObject);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageNo, state, getAccessLogsApi]);

  const CustomCellRender = (params) => {
    return (
      <td>
        <>
          <div className="text-info">{params?.row?.formattedDate}</div>
          <div className="text-pink">{params?.row?.formattedTime}</div>
        </>
      </td>
    );
  };

  return (
    <Grid
      item
      xs={12}
      className={`section-body ${isRtl ? "rtlMargin" : "ltrMargin"} ${
        fixNavbar ? "marginTop" : ""
      } `}>
      <StyledCard variant="outlined">
        <div className="container-fluid p-4">
          <div className="tab-content">
            <div>
              <div className="card">
                <PageHeader
                  title={
                    <h5 className="page-title">
                      {Translate("contacts:accessLogsHeading")}
                    </h5>
                  }
                  onClick={() => {
                    navigate("/contactRepository/publicListing");
                  }}
                  containerClass="card-header card-header-spacing formTitle"
                />
                <ListView
                  columns={getContactRepoAccessLogsColumn(CustomCellRender)}
                  rows={accessLogs}
                  autoHeight
                  sortingMode="server"
                  sortBy={sortBy}
                  onPaginationChange={setCurrentPageNo}
                  currentPage={currentPageNo}
                  showShimmer={loadingAccessLogsList}
                  showLoader={loading}
                  totalPageCount={lastPage.current}
                />
              </div>
            </div>
          </div>
        </div>
      </StyledCard>
    </Grid>
  );
};
export default PublicListAccessLogs;

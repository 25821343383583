import React from "react";
import Translate from "../../../../translation/utils/translateFunction";
import MessageItem from "../../Components/MessageItem";
import NoDataFound from "../../../ManageTask/NoDataFound";

const ChatList = ({ chatList, onClick, selectedUser, ...props }) => {
  return chatList?.length > 0 ? (
    <div
      className="tab-pane fade show active"
      id="users-list"
      role="tabpanel"
      aria-labelledby="users-tab">
      <ul className="right_chat list-unstyled list">
        {chatList?.map((item) => (
          <MessageItem
            key={item?._id}
            item={item}
            onClick={onClick}
            selectedUser={selectedUser}
            {...props}
          />
        ))}
      </ul>
    </div>
  ) : (
    <NoDataFound isCenter message={Translate("messagePage:noChatFound")} />
  );
};
export default ChatList;

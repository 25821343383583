import { Methods, Urls } from "../../common/Utils/ApiConstants";
import { CreateApi } from "../CreateApi";

const CustomReportCollection = CreateApi.injectEndpoints({
  endpoints: (builder) => ({
    generateCustomReport: builder.mutation({
      query: (body) => {
        return {
          url: Urls.generateCustomReport,
          method: Methods.post,
          body,
        };
      },
    }),
    getAssignedByOwnerList: builder.mutation({
      query: (body) => {
        return {
          url: Urls.getAssignedByOwnerList,
          method: Methods.post,
          body,
        };
      },
    }),
    getAssignedByGmList: builder.mutation({
      query: (body) => {
        return {
          url: Urls.getAssignedByGmList,
          method: Methods.post,
          body,
        };
      },
    }),
  }),
});

export const {
  useGenerateCustomReportMutation,
  useGetAssignedByOwnerListMutation,
  useGetAssignedByGmListMutation,
} = CustomReportCollection;

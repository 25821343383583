import { Grid, Stack } from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import { useGetSelectMemberListMutation } from "../../../../request/ContactRepo";
import { showToast } from "../../../../common/Utils/ToastMessage";
import Translate from "../../../../translation/utils/translateFunction";
import { Persona } from "../../../Persona";
import { StyledCard } from "../../../../common/Utils/StyledComponents";
import ListView from "../../../ListView";
import { getContactRepoSelectMemeberColumns } from "./column.data";
import { VALIDATION_BUCKETURL } from "../../../Persona/constants";
import Searchbar from "../../../common/Searchbar";
import { useSelector } from "react-redux";
import PageHeader from "../../../common/pageHeader";

const SelectMember = ({ fixNavbar }) => {
  const { isRtl } = useSelector((state) => state.formanagement);
  const [loading, setLoading] = useState(true);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [search, setSearch] = useState("");
  const [selectMember, setSelectMember] = useState();

  const lastPage = useRef(0);

  const navigate = useNavigate();
  const { state } = useLocation();

  const [
    getSelectMemberListApi,
    {
      data: selectMemberData,
      isSuccess: successSelectMember,
      isLoading: isLoadingSelectMemberData,
      isError: isErrorSelectMember,
      error: errorSelectMember,
    },
  ] = useGetSelectMemberListMutation();

  useEffect(() => {
    if (selectMemberData && successSelectMember) {
      const { nodes, pageInfo } = selectMemberData.data;
      setSelectMember(nodes);

      lastPage.current = pageInfo.lastPageNo;
      setLoading(false);
    }
    if (isErrorSelectMember && errorSelectMember) {
      showToast({ message: "Failed to fetch the data", type: "error" });
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    successSelectMember,
    isErrorSelectMember,
    selectMemberData,
    errorSelectMember,
  ]);

  let requestObject = useMemo(() => {
    if (state) {
      let requestObj = {
        companies: state?.selectedCompany,
        filterRole: state?.userType,
        pageNo: currentPageNo,
        searchValue: search,
      };
      return requestObj;
    }
  }, [search, currentPageNo, state]);

  const debouncedSearch = useDebouncedCallback(() => {
    if (state) {
      getSelectMemberListApi(requestObject);
    }
  }, 500);

  useEffect(() => {
    setLoading(true);
    if (requestObject?.searchValue?.length) {
      debouncedSearch();
    } else {
      getSelectMemberListApi(requestObject);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, currentPageNo, search, requestObject, getSelectMemberListApi]);

  const CustomCellActionRender = (params) => {
    return (
      <td>
        <i
          className={`m-2 fe fe-eye cursor-pointer manageIconColor 
          
          `}
          onClick={() =>
            state?.isShare
              ? navigate("/contactRepository/sharedListing/roles", {
                  state: {
                    contactId: params?.row?._id,
                    currentMember: state,
                    isShare: state?.isShare,
                  },
                })
              : navigate("/contactRepository/privateContacts", {
                  state: {
                    contactId: params?.row?._id,
                    currentMember: state,
                  },
                })
          }
          data-toggle="tooltip"
          title={Translate("contacts:view")}
        ></i>
      </td>
    );
  };
  const handleSearchText = (value) => {
    setCurrentPageNo(1);
    setSearch(value);
  };

  const CustomCellRender = (params) => {
    return (
      <td>
        <div style={{ display: "flex" }}>
          <Persona
            name={params?.row?.name}
            image={
              params?.row?.profileUrl?.includes(VALIDATION_BUCKETURL)
                ? params?.row?.profileUrl
                : ""
            }
            size={28}
          />
          <span className="text-info ml-2 mt-1">{params?.row?.name}</span>
        </div>
      </td>
    );
  };

  const rowsPerPage = 10;
  const startSerialNumber = (currentPageNo - 1) * rowsPerPage + 1;

  const rowsWithSerialNumbers =
    selectMember &&
    selectMember.map((row, index) => ({
      ...row,
      serialNumber: startSerialNumber + index,
    }));

  return (
    <Grid
      item
      xs={12}
      className={`section-body ${isRtl ? "rtlMargin" : "ltrMargin"} ${
        fixNavbar ? "marginTop" : ""
      } `}
    >
      <StyledCard variant="outlined">
        <div className="container-fluid p-4">
          <div className="tab-content">
            <div>
              <div className="card">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <PageHeader
                    title={
                      <h5 className="page-title">
                        {Translate("contacts:selectMemberHeading")}
                      </h5>
                    }
                    onClick={() => {
                      state?.isShare
                        ? navigate("/contactRepository/sharedListing")
                        : navigate("/contactRepository/privateListing");
                    }}
                    containerClass="card-header card-header-spacing"
                  />
                  <div className="card-options">
                    <div className="input-group">
                      <Searchbar
                        handleSearchText={handleSearchText}
                        setSearch={setSearch}
                      />
                    </div>
                  </div>
                </div>
                <ListView
                  columns={getContactRepoSelectMemeberColumns(
                    CustomCellRender,
                    CustomCellActionRender
                  )}
                  rows={rowsWithSerialNumbers}
                  autoHeight
                  onPaginationChange={setCurrentPageNo}
                  currentPage={currentPageNo}
                  showShimmer={isLoadingSelectMemberData}
                  showLoader={loading || isLoadingSelectMemberData}
                  totalPageCount={lastPage.current}
                />
              </div>
            </div>
          </div>
        </div>
      </StyledCard>
    </Grid>
  );
};

export default SelectMember;

import { MenuItem, Select } from "@mui/material";
import React from "react";
import { colors } from "../../../common/Theme/Colors";
import { FontFamily } from "../../../common/Utils/StyledComponents";
import Translate from "../../../translation/utils/translateFunction";
const DashboardDropDown = ({
  label,
  required,
  disabled,
  displayEmpty,
  options,
  onSelect,
  placeholder,
  value,
  selectedValue,
  showCloseIcon = true,
  ...props
}) => {
  return (
    <Select
      {...props}
      size="small"
      disabled={disabled}
      style={{
        background: disabled ? "#e5e5e58f" : "",
        color: !value && colors.grey_003,
        whiteSpace: "normal",
      }}
      value={value}
      onChange={(e) => {}}
      sx={{ fontFamily: FontFamily }}
      displayEmpty
      renderValue={!!value ? undefined : () => placeholder || label}>
      {options?.length ? (
        options?.map(({ value, label }) => (
          <MenuItem
            key={value}
            value={value}
            onClick={() => onSelect?.(value, label)}
            style={{
              paddingLeft: 10,
              paddingRight: 10,
              whiteSpace: "unset",
              wordBreak: "break-all",
              textOverflow: "ellipsis",
              fontFamily: FontFamily,
            }}>
            {label}
          </MenuItem>
        ))
      ) : (
        <MenuItem disabled>{Translate("common:noDataFoundMessage")}</MenuItem>
      )}
    </Select>
  );
};
export default DashboardDropDown;

export default {
  documentRepository: "Document repository",
  viewFile: "View document",
  title: "Title",
  taskName: "Task name",
  description: "Description",
  attachment: "Attachment",
  assign: "Assign task",
  tooltip: "Create document",
  addDocument: "Create document",
  viewDocument: "View document",
  editDocument: "Edit document",
  accessLogs: "Logs",
  titleError: "Title is required",
  descriptionError: "Description is required",
  scan: "Create document",
  copy: "Copy/paste text",
  save: "Save",
  createButton: "Create task",
  accessButton: "Access logs",
  selectedCompanyMessage: "All company selected",
  selectCompany: "Select company",
  selectCompanyMessage: "Please select at least one company",
  apiFailedMessage: "Failed to fetch the data",
  srNoTableHeading: "Sr.no",
  documentTableHeading: "Document",
  dateTimeTableHeading: "Date and time",
  documentSizeTableHeading: "Document size",
  sharedDocumentTableHeading: "Shared document",
  actionTableHeading: "Action",
  userNameTableHeading: "User name",
  senderNameTableHeading: "Sender name",
  designationTableHeading: "Designation",
  companyNameTableHeading: "Company name",
  dateTableHeading: "Date",
  timeStampsTableHeading: "Timestamp",
  searchPlaceholder: "Search something...",
  myDocumentsSectionHeading: "My documents",
  sharedWithMeSectionHeading: "Shared with me",
  sharedByMeSectionHeading: "Shared by me",
  fileLimit: "Only 1 file is allowed.",
  addSuccess: "Document added successfully.",
  attach: "Attach file",
  accessLogHeading: "Access logs",
  dateAndTimeTableHeading: "Date and time",
  titleCharactersError:
    "Title should contain alphanumeric with hyphen and period.",
  modalTitle: "Share to",
};

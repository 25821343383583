import { Formik } from "formik";
import React, { useRef, useState } from "react";
import Datepicker from "../common/datepicker";
import { Persona } from "../Persona";
import RadioButtons from "../RadioButtons";
import SearchableDropDown from "../TextField/SearchDropDown";
import { FormikDropdownPicker } from "./component/FormikDropDownPicker";
import { FormikTextField } from "./component/FormikTextField";
import { getFormSchema } from "./component/utils";
import { getMoment } from "../../common/Utils/commonFunction";

const InitialValues = {
  company: "",
  type: "",
  title: "",
  description: "",
  assignTo: "",
  reportTo: "",
  relatedTask: "",
  startDate: "",
  dueDate: "",
  startTime: "",
  dueTime: "",
  priority: "",
  markAsCritical: false,
};
const options = [
  "www.flipkart.com",
  "www.google.com",
  "www.amazon.in",
  "www.Myntra.com",
];
const centerContent = {
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  alignContent: "center",
};
const assignToOptions = [
  {
    value: "Assign to me",
    label: (
      <div style={centerContent}>
        <Persona name={"Assign to me"} size={35} />
        Assign to me
      </div>
    ),
  },
  {
    value: "jenny Wilson",
    label: (
      <div style={centerContent}>
        <Persona name={"jenny Wilson"} size={35} />
        jenny Wilson
      </div>
    ),
  },
  {
    value: "Kristin Watson",
    label: (
      <div style={centerContent}>
        <Persona name={"Kristin Watson"} size={35} />
        Kristin Watson
      </div>
    ),
  },
  {
    value: "Jane Cooper",
    label: (
      <div style={centerContent}>
        <Persona name={"Jane Cooper"} size={35} />
        Jane Cooper
      </div>
    ),
  },
];
const checkBoxOption = ["Mark as critical"];

const priorities = ["Emergency", "High", "Medium", "Low"];
const GetForms = () => {
  const formikRef = useRef(null);
  const [selected, setSelected] = useState("");
  const onSubmit = (companyFormValues) => {};
  return (
    <Formik
      initialValues={InitialValues}
      innerRef={formikRef}
      validateOnMount
      onSubmit={onSubmit}
      validationSchema={getFormSchema}>
      {({ values, setFieldValue, handleSubmit }) => {
        return (
          <div className="row mt-100">
            <div className="container card">
              <div className="page-title formTitle p-3">Add task</div>
              <div className="card-body">
                <div className="row">
                  <div className="form-group col-md-4 col-sm 12">
                    <FormikDropdownPicker
                      options={options}
                      value={"india"}
                      name="company"
                      label={"Select company"}
                      placeholder={"Select company"}
                    />
                  </div>
                  <div className="form-group col-md-4 col-sm 12">
                    <FormikDropdownPicker
                      options={options}
                      value={"india"}
                      name="type"
                      label={"Type"}
                      placeholder={"Select type"}
                    />
                  </div>
                  <div className="form-group col-md-4 col-sm 12">
                    <FormikTextField
                      name="title"
                      label={"Title"}
                      placeholder={"Enter title"}
                      autoComplete={"off"}
                      // RenderRightContainer={() => (
                      //   <div>
                      //     <i className="fe fe-speaker" />
                      //   </div>
                      // )}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-4 col-sm 12">
                    <SearchableDropDown
                      selected={selected}
                      onSelect={setSelected}
                      options={assignToOptions}
                      placeholder={"Assign to"}
                      label={"Assign to"}
                    />
                  </div>
                  <div className="form-group col-md-4 col-sm 12">
                    <SearchableDropDown
                      selected={selected}
                      onSelect={setSelected}
                      options={assignToOptions}
                      placeholder={"Report to"}
                      label={"Report to"}
                    />
                  </div>
                  <div className="form-group col-md-4 col-sm 12">
                    <FormikDropdownPicker
                      options={options}
                      value={"india"}
                      name="relatedTask"
                      label={"Related task"}
                      placeholder={"Select related task"}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-3 col-sm 6">
                    <Datepicker
                      label={"Start date"}
                      containerStyle="datePickerContainer"
                      className="form-control bw-0"
                      format="MM dd, yyyy"
                      onChange={(value) => setFieldValue("startDate", value)}
                      minDate={getMoment().toDate()}
                      maxDate={getMoment().toDate()}
                    />
                  </div>
                  <div className="form-group col-md-3 col-sm 6">
                    <Datepicker
                      label={"Due date"}
                      containerStyle="datePickerContainer"
                      className="form-control bw-0"
                      format="MM dd, yyyy"
                      onChange={(value) => setFieldValue("dueDate")}
                      minDate={getMoment().toDate()}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-3 col-sm 6">
                    <Datepicker
                      label={"Start time"}
                      containerStyle="datePickerContainer"
                      className="form-control bw-0"
                      calendarIcon
                      format="HH:mm a"
                      onChange={(value) => setFieldValue("startTime", value)}
                    />
                  </div>
                  <div className="form-group col-md-3 col-sm 6">
                    <Datepicker
                      label={"Due time"}
                      containerStyle="datePickerContainer"
                      className="form-control bw-0"
                      calendarIcon
                      format="HH:mm a"
                      onChange={(value) => setFieldValue("dueTime", value)}
                    />
                  </div>
                </div>
                {/* <div className="form-group">
                  <FormikTextField
                    name="name"
                    label={"Name"}
                    placeholder={"Enter name"}
                    autoComplete={"off"}
                  />
                </div>
                <div className="form-group col-md-6 col-sm 12">
                  <FormikTextField
                    name="contact"
                    label={"Contact"}
                    placeholder={"Enter Contact"}
                    autoComplete={"off"}
                  />
                </div> */}
                <div className="form-group">
                  <FormikTextField
                    name="description"
                    label={"Description"}
                    placeholder={"Add description"}
                    autoComplete={"off"}
                    multiline
                  />
                </div>
                <div className="row">
                  {priorities.map((item) => {
                    const isSelected = values.priority === item;
                    return (
                      <div
                        className={
                          isSelected
                            ? `priority ${item.toLowerCase()}Selected`
                            : `priority ${item.toLowerCase()}`
                        }
                        onClick={() => setFieldValue("priority", item)}>
                        {item}
                      </div>
                    );
                  })}
                </div>
                {/* <div>
                  <RadioButtons
                    radioOptions={radioOptions}
                    defaultChecked={1}
                    onPress={(value) => {
                      setFieldValue("gender", value.target.value);
                    }}
                  />
                </div> */}
                <div className="form-group">
                  <RadioButtons
                    radioOptions={checkBoxOption}
                    onPress={() => {
                      setFieldValue("markAsCritical", !values.markAsCritical);
                    }}
                    isCheckBox
                    defaultCheck={values.markAsCritical}
                  />
                </div>
                <div className="card-footer text-right">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={handleSubmit}>
                    Add
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
      }}
    </Formik>
  );
};

export default GetForms;

import { Grid } from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { getMOMmyListingColumns } from "../column.data";
import { colors } from "../../../../common/Theme/Colors";
import ListView from "../../../ListView";
import {
  StyledCard,
  StyledTypography,
} from "../../../../common/Utils/StyledComponents";
import { data } from "../EventMockData";
import Searchbar from "../../../common/Searchbar";
import { useNavigate } from "react-router-dom";
import {
  useMomListingMutation,
  useShareMomMutation,
} from "../../../../request/Mom";
import Translate from "../../../../translation/utils/translateFunction";
import ShareWithUser from "../../../ShareWithUser";
import { shareModuleType } from "../../../ShareWithUser/components/constants";
import { showToast } from "../../../../common/Utils/ToastMessage";

const MyeventsList = ({ fixNavbar }) => {
  const navigate = useNavigate();
  const { isRtl } = useSelector((state) => state.formanagement);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedColumn, setSelectedColumn] = useState("name");
  const [sortBy, setSortBy] = useState(true);
  const [myEventsListData, setMyEventListData] = useState([]);
  const [search, setSearch] = useState();
  const lastPage = useRef(1);
  const [selectedMom, setSelectedMom] = useState("");
  const [showShareModal, setShowShareModal] = useState(false);
  const [sharedByMe, setSharedByMe] = useState(false);

  const [
    getMomList,
    { data: serverResponse, isSuccess, isLoading, isError, error },
  ] = useMomListingMutation();

  const [
    shareMomApi,
    {
      data: shareMomData,
      isLoading: isShareMomLoading,
      isError: isShareMomError,
      error: shareMomError,
    },
  ] = useShareMomMutation();

  const requestObj = useMemo(() => {
    return {
      pageNo: currentPage,
      searchText: search,
      isSharedByMe: sharedByMe,
    };
  }, [currentPage, search, sharedByMe]);

  useEffect(() => {
    getMomList(requestObj);
  }, [requestObj]);

  useEffect(() => {
    if (isSuccess && serverResponse?.data) {
      const { record, pageInfo } = serverResponse?.data;
      setMyEventListData(record);
      lastPage.current = pageInfo?.lastPageNo;
    }
  }, [isSuccess]);

  useEffect(() => {
    if (shareMomData) {
      showToast({ message: shareMomData?.message, type: "success" });
    }

    if (shareMomError && isShareMomError) {
      shareMomError?.originalStatus !== 500 &&
        showToast({
          message:
            shareMomError?.message || Translate("document:apiFailedMessage"),
          type: "error",
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shareMomData, isShareMomError]);

  const getSort = (columnName = "") => {
    selectedColumn !== columnName && setSortBy(undefined);
    setTimeout(() => {
      selectedColumn !== columnName ? setSortBy(true) : setSortBy(!sortBy);
    }, 500);
    setSelectedColumn(columnName);
  };

  const handleSearchText = (value) => setSearch(value?.trim());

  const customSrCellRender = (params) => {
    return (
      <StyledTypography component="span" sx={{ color: colors.primary_009 }}>
        {params?.api?.getRowIndexRelativeToVisibleRows(params?.row?._id) +
          1 +
          (currentPage - 1) * 10}
      </StyledTypography>
    );
  };

  const customActionCellRender = (params) => {
    return (
      <div>
        <i
          className={`manageIconColor ml-3 fa fa-eye cursor-pointer`}
          onClick={() =>
            navigate("/mom/myevent/view", {
              state: { eventid: params?.row?._id, isMyMom: true },
            })
          }
        />
        <i
          className="fe fe-share-2 cursor-pointer manageIconColor"
          onClick={() => setShowShareModal(true)}
          data-toggle="tooltip"
          title={Translate("common:shareToolTip")}></i>
      </div>
    );
  };

  const onCloseShareModal = () => setShowShareModal(false);

  const handleShareMom = (users) => {
    const reqObj = {
      momId: selectedMom,
      userId: users?.map((ele) => ele._id),
    };

    shareMomApi(reqObj);
  };

  const handleShareByMe = () => setSharedByMe(!sharedByMe);

  return (
    <Grid
      item
      xs={12}
      className={`section-body ${isRtl ? "rtlMargin" : "ltrMargin"} ${
        fixNavbar ? "marginTop" : ""
      } `}>
      <StyledCard variant="outlined">
        <div className="container-fluid p-4">
          <div className="tab-content">
            <div>
              <div className="card">
                {/* {!!myEventsListData.length && (
                  <div className="card-header card-header-spacing">
                    <div className="card-options">
                      <div className="input-group">
                        <Searchbar
                          handleSearchText={handleSearchText}
                          setSearch={setSearch}
                        />
                      </div>
                    </div>
                  </div>
                )} */}
                {!!myEventsListData.length && (
                  <div className="d-flex sharedByMe">
                    <input
                      type="checkbox"
                      className="cursor-pointer"
                      style={{ color: colors.primary }}
                      onClick={handleShareByMe}
                    />
                    <StyledTypography
                      variant="subtitle1"
                      className="sharedByMeLable ml-2 cursor-pointer">
                      {Translate("VoiceNotes:sharedByMe")}
                    </StyledTypography>
                  </div>
                )}
                <ListView
                  columns={getMOMmyListingColumns(
                    customSrCellRender,
                    customActionCellRender
                  )}
                  rows={myEventsListData}
                  autoHeight
                  sortingMode="server"
                  sortBy={sortBy}
                  onPaginationChange={setCurrentPage}
                  currentPage={currentPage}
                  showShimmer={isLoading}
                  showLoader={isLoading || isShareMomLoading}
                  totalPageCount={lastPage.current}
                  onSortModelChange={(data) =>
                    data?.length && getSort(data[0]?.field)
                  }
                  onCellClick={(params) => {
                    setSelectedMom(params?.row?._id);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </StyledCard>
      {showShareModal && (
        <ShareWithUser
          currentModule={shareModuleType?.MOM}
          showModal={showShareModal}
          closeModal={onCloseShareModal}
          onSubmit={handleShareMom}
        />
      )}
    </Grid>
  );
};
export default MyeventsList;

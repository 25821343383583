import { LinearProgress } from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import {
  useChatListMutation,
  useGetChatInviteeMutation,
} from "../../../request/Message";
import MessageListLoader from "../Components/MessageLoader/MessageListLoader";
import ChatList from "./ChatList";

const ChatScreen = ({
  onClick,
  selectedUser,
  isNewChat,
  selectedTab,
  currentPage,
  setCurrentPage,
  newChatCurrentPage,
  setNewChatCurrentPage,
  searchText,
}) => {
  const [chatList, setChatList] = useState([]);
  const [newChatListData, setNewChatListData] = useState([]);
  const isChatLastPage = useRef(false);
  const isNewChatLastPage = useRef(false);

  /********************************************************Api Hooks Declaration************************************************************/
  const [
    getChatList,
    {
      data: serverResponseChatList,
      isSuccess: isChatListSuccess,
      isLoading: isChatListLoading,
    },
  ] = useChatListMutation();

  const [
    getChatInviteeList,
    {
      data: chatInviteeData,
      isSuccess: isChatInviteeSuccess,
      isLoading: isChatInviteeLoading,
    },
  ] = useGetChatInviteeMutation();

  /********************************************************Api request obj ************************************************************************/
  const requestObj = useMemo(() => {
    return {
      pageNo: currentPage,
      searchText: searchText,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, selectedTab, searchText]);

  const chatInviteeRequestObj = useMemo(() => {
    return {
      pageNo: newChatCurrentPage,
      searchText: searchText,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, newChatCurrentPage]);
  useEffect(() => {
    setNewChatListData([]);
    setChatList([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNewChat]);

  useEffect(() => {
    if (isNewChat) {
      searchText
        ? debouncedSearch()
        : getChatInviteeList(chatInviteeRequestObj);
    } else {
      searchText ? debouncedSearch() : getChatList(requestObj);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestObj, selectedTab, chatInviteeRequestObj, isNewChat]);

  const debouncedSearch = useDebouncedCallback(() => {
    isNewChatLastPage.current = false;
    isChatLastPage.current = false;
    isNewChat
      ? getChatInviteeList(chatInviteeRequestObj)
      : getChatList(requestObj);
  }, 500);

  /********************************************************Api hooks result useeffect************************************************************/
  useEffect(() => {
    if (serverResponseChatList && isChatListSuccess) {
      currentPage !== 1
        ? setChatList((prev) =>
            prev.concat(serverResponseChatList?.data?.record)
          )
        : setChatList(serverResponseChatList?.data?.record);
      isChatLastPage.current =
        serverResponseChatList?.data?.pageInfo?.hasNextPage;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChatListSuccess]);

  useEffect(() => {
    if (isChatInviteeSuccess && chatInviteeData) {
      newChatCurrentPage !== 1
        ? setNewChatListData((prev) =>
            prev.concat(chatInviteeData?.data?.nodes)
          )
        : setNewChatListData(chatInviteeData?.data?.nodes);
      isNewChatLastPage.current = chatInviteeData?.data?.pageInfo?.hasNextPage;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChatInviteeSuccess]);

  const handleOnClick = (item) => {
    onClick(item);
    // update seen count
    if (item?.messageUnseenCount > 0) {
      setChatList(
        chatList.map((chat) =>
          chat._id === item._id ? { ...chat, messageUnseenCount: 0 } : chat
        )
      );
    }
  };

  return (
    <>
      {(isChatListLoading && currentPage === 1) ||
      (isChatInviteeLoading && newChatCurrentPage === 1) ? (
        <>
          <LinearProgress style={{ height: 2 }} />
        </>
      ) : isNewChat ? (
        <ChatList
          chatList={newChatListData}
          onClick={onClick}
          selectedUser={selectedUser}
          isNewChat={isNewChat}
        />
      ) : (
        <ChatList
          chatList={chatList}
          onClick={handleOnClick}
          selectedUser={selectedUser}
          isNewChat={isNewChat}
        />
      )}
      {(isNewChat
        ? isNewChatLastPage.current && newChatListData.length > 0
        : isChatLastPage.current && chatList.length > 0) && (
        <MessageListLoader
          isLoading={isChatListLoading || isChatInviteeLoading}
          onLoadMoreClick={() =>
            isNewChat
              ? setNewChatCurrentPage((prev) => prev + 1)
              : setCurrentPage((prev) => prev + 1)
          }
          hideMargin
        />
      )}
    </>
  );
};

export default ChatScreen;

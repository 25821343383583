import { Methods, Urls } from "../../common/Utils/ApiConstants";
import { CreateApi } from "../CreateApi";

const AuthenticationCollection = CreateApi.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (body) => ({
        url: Urls.login,
        method: Methods.post,
        body: body,
      }),
    }),
    loginOTP: builder.query({
      query: (mobileNumber) => ({
        url: `${Urls.loginGetOtp}${mobileNumber}`,
        method: Methods.get,
      }),
    }),
    verifyEmailOtp: builder.mutation({
      query: (body) => ({
        url: Urls.verifyOtp,
        method: Methods.post,
        body: body,
      }),
    }),

    changePassword: builder.mutation({
      query: (body) => {
        return {
          url: Urls.changePassword,
          method: Methods.put,
          body: body,
        };
      },
    }),
    logout: builder.query({
      query: (body) => ({
        url: `${Urls.logOut}${body._id}`,
        method: Methods.get,
      }),
      transformResponse: (response) => {
        return response.data;
      },
    }),
    updateFcmToken: builder.mutation({
      query: (body) => {
        return {
          url: Urls.updateFmcToken,
          method: Methods.post,
          body: body,
        };
      },
    }),
    sendEmailOTP: builder.mutation({
      query: (body) => {
        return {
          url: Urls.sendEamilOTP,
          method: Methods.post,
          body: body,
        };
      },
    }),
    getVerifyEmailOTP: builder.mutation({
      query: (body) => {
        return {
          url: Urls.verifyEmailOTP,
          method: Methods.post,
          body: body,
        };
      },
    }),
    setNewPassword: builder.mutation({
      query: (body) => {
        return {
          url: Urls.setNewPassword,
          method: Methods.put,
          body: body,
        };
      },
    }),
  }),
});
export const {
  useLoginMutation,
  useLazyLoginOTPQuery,
  useVerifyEmailOtpMutation,
  useChangePasswordMutation,
  useLazyLogoutQuery,
  useUpdateFcmTokenMutation,
  useSendEmailOTPMutation,
  useGetVerifyEmailOTPMutation,
  useSetNewPasswordMutation,
} = AuthenticationCollection;

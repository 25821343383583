import React, { useEffect, useState, useMemo } from "react";
import { Grid, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getManagetTaskColumns } from "../column.data";
import ListView from "../../ListView";
import { colors } from "../../../common/Theme/Colors";
import {
  StyledCard,
  StyledTypography,
} from "../../../common/Utils/StyledComponents";
import { showToast } from "../../../common/Utils/ToastMessage";
import { useLazyGetPendingAprrovalsDataQuery } from "../../../request/ManageTaskFilters/PendindApprovals";
import Translate from "../../../translation/utils/translateFunction";
import { SUBTASK_PREFIX } from "../constants";
import DropDownField from "../../TextField/DropdownField";
import { moduleTypeOptions } from "../../Task/Components/constants";
import { useSelector } from "react-redux";
import { MarkCriticalAndPinTask } from "../Components/utils";
import { taskStatusType } from "../../AddTask/constants";
import {
  useCriticalTaskMutation,
  usePinTaskMutation,
} from "../../../request/Task";

const PendingApprovalTaskListing = ({ fixNavbar }) => {
  const { userData, isRtl } = useSelector((state) => state.formanagement);
  const navigate = useNavigate();
  const [pendingApprovalData, setPendingApprovalData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setLoading] = useState(false);
  const [lastPageNo, setLastPageNo] = useState(0);
  const [moduleType, setModuleType] = useState("TASK");

  const [
    getPendingApprovalApi,
    {
      data: pendingApprovalList,
      isLoading: loadingPendingApprovalData,
      isError: pendingApprovalIsError,
      error: pendingApprovalError,
      isFetching: fetchingPendingApprovalList,
    },
  ] = useLazyGetPendingAprrovalsDataQuery();

  const [pinTask, { data: pinTaskData }] = usePinTaskMutation({
    fixedCacheKey: "pinnedTask",
  });

  const [markCritical, { data: markCriticalData }] = useCriticalTaskMutation({
    fixedCacheKey: "criticalTask",
  });

  const requestBody = useMemo(() => {
    const reqObj = {
      pageNo: currentPage,
      moduleType,
    };

    return reqObj;
  }, [currentPage, moduleType]);

  useEffect(() => {
    if (requestBody?.moduleType) {
      setLoading(true);
      getPendingApprovalApi(requestBody);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestBody, pinTaskData, markCriticalData]);

  useEffect(() => {
    if (pendingApprovalList?.nodes) {
      const { pageInfo, nodes } = pendingApprovalList;
      setPendingApprovalData(nodes);
      setLastPageNo(pageInfo.lastPageNo);
      setLoading(false);
    }
    if (pendingApprovalIsError && pendingApprovalError) {
      showToast({
        message: JSON.stringify(pendingApprovalError),
        type: "error",
      });
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pendingApprovalList, pendingApprovalError]);

  const CustomActionCellRender = (params) => {
    const { taskNumber } = params?.row;
    const isSubtask = taskNumber?.startsWith(SUBTASK_PREFIX);

    const isCompleted = params?.row?.taskStatus === taskStatusType?.COMPLETED;

    const showCriticalFlag = isCompleted
      ? false
      : params?.row?.userDetails?.some(
          (details) => details?._id === userData?._id
        );

    return (
      <div className="headerMargin">
        <a
          href="/#"
          className="nav-link dropdown-toggle"
          data-toggle="dropdown"
          role="button"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <i className="fa fa-ellipsis-v manageIconColor" />
        </a>
        <div className="dropdown-menu dropdownAlignment dropdownIconMenu">
          <MarkCriticalAndPinTask
            taskId={params?.row?.taskId || params?.row?._id}
            showCriticalFlag={showCriticalFlag}
            hasFlagged={params?.row?.hasFlagged}
            isCompleted={isCompleted}
            hasPinned={params?.row?.hasPinned}
            markAsCritical={markCritical}
            pinTask={pinTask}
          />
          <div
            className="manageIconColor ml-3 cursor-pointer dropdown-item"
            onClick={() =>
              navigate(isSubtask ? "/subtask/view" : "/taskDetails", {
                state: {
                  taskId: params?.row?.taskId,
                  isTaskFilter: true,
                  navigateFrom: "/pendingApprovalTask",
                },
              })
            }
          >
            <span className="manageIconColor fa fa-eye" />
            {Translate("manageTask:viewTask")}
          </div>
        </div>
      </div>
    );
  };

  const CustomSrCellRender = (params) => {
    return (
      <StyledTypography component="span" sx={{ color: colors.primary_009 }}>
        {params?.api?.getRowIndexRelativeToVisibleRows(params?.row?._id) +
          1 +
          (currentPage - 1) * 10}
      </StyledTypography>
    );
  };
  return (
    <Grid
      item
      xs={12}
      className={`section-body ${isRtl ? "rtlMargin" : "ltrMargin"} ${
        fixNavbar ? "marginTop" : ""
      } `}
    >
      <StyledCard variant="outlined">
        <div className="container-fluid p-4">
          <div className="tab-content">
            <div>
              <div className="card">
                <div className="card-header card-header-spacing formTitle">
                  <Stack flexDirection={"row"} alignItems={"center"}>
                    <i
                      className="icon fe fe-arrow-left mr-3 icon-size backnavigation-iconColor cursor-pointer"
                      onClick={() => navigate("/manageTask")}
                      title={Translate("common:backToolTip")}
                    />
                    <h5 className="page-title">
                      {Translate("manageTask:pendingApproval")}
                    </h5>
                  </Stack>
                </div>

                <div className="card-header">
                  <div className="card-options">
                    <div className="sort_stat commentTextField">
                      <div className="row">
                        <div className="col-12" style={{ marginTop: "9px" }}>
                          <StyledTypography
                            sx={{ color: colors.primary_009 }}
                            component="span"
                          >
                            {Translate("manageTask:moduleTypePlaceholder")}
                          </StyledTypography>
                          <div className="selectWidth">
                            <div className="form-group cursor-pointer">
                              <DropDownField
                                options={moduleTypeOptions}
                                className={
                                  "form-control textFieldHeight cursor-pointer"
                                }
                                placeholder={Translate(
                                  "manageTask:moduleTypePlaceholder"
                                )}
                                onChange={(event) => {
                                  const { value } = event?.target;

                                  if (value !== moduleType) {
                                    setCurrentPage(1);
                                  }
                                  setModuleType(value);
                                }}
                                value={moduleType}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <ListView
                    columns={getManagetTaskColumns(
                      CustomSrCellRender,
                      CustomActionCellRender,
                      false
                    )}
                    rows={pendingApprovalData}
                    autoHeight
                    sortingMode="server"
                    onPaginationChange={setCurrentPage}
                    currentPage={currentPage}
                    showShimmer={isLoading || loadingPendingApprovalData}
                    showLoader={fetchingPendingApprovalList}
                    totalPageCount={lastPageNo}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </StyledCard>
    </Grid>
  );
};
export default PendingApprovalTaskListing;

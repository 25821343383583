import Translate from "../../translation/utils/translateFunction";
import { CustomTitleCellRender } from "../common/CustomCellRender";

export const getOfficeLocationColumns = (
  CustomSrCellRender,
  CustomCellActionRender
) => {
  const columns = [
    {
      field: "lineNo",
      headerName: Translate("common:srNoTitle"),
      align: "center",
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      renderCell: CustomSrCellRender,
    },
    {
      field: "name",
      headerName: Translate("accountPage:officeLocation"),
      flex: 0.5,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => CustomTitleCellRender(params?.row?.name),
    },
    {
      field: "Action",
      headerName: Translate("common:actionTitle"),
      flex: 0.4,
      sortable: false,
      disableColumnMenu: true,
      renderCell: CustomCellActionRender,
    },
  ];
  return columns;
};

import { Grid } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import CompanyHeader from "../../../common/CompanyHeader";
import { colors } from "../../../common/Theme/Colors";
import {
  StyledCard,
  StyledTypography,
} from "../../../common/Utils/StyledComponents";
import { showToast } from "../../../common/Utils/ToastMessage";
import { userTypes } from "../../../common/users/userTypes";
import { useGetHrOfficerListMutation } from "../../../request/Staff/HrOfficer";
import Translate from "../../../translation/utils/translateFunction";
import CompanyListing from "../../ContactRepository/component";
import ListView from "../../ListView";
import Searchbar from "../../common/Searchbar";
import { getHrOfficerColumns } from "./Components/column.data";
import {
  checkModuleAccess,
  checkModuleAccessOfficer,
} from "../../../common/Utils/commonFunction";

const HrOfficerListing = ({ fixNavbar }) => {
  const navigate = useNavigate();
  const { selectedCompanyList, userData, isRtl } = useSelector(
    (state) => state.formanagement
  );

  const [isLoading, setIsLoading] = useState(false);
  const [sortBy, setSortBy] = useState(true);
  const [selectedColumn, setSelectedColumn] = useState("name");
  const [lastPage, setLastPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [hrOfficers, setHrOfficers] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const [
    fetchHrOfficerList,
    {
      data: hrOfficerList,
      isLoading: isHrOfficerLoading,
      isError: isHrOfficerError,
      error: hrOfficerError,
    },
  ] = useGetHrOfficerListMutation();

  const requestObj = useMemo(() => {
    const companies = selectedCompanyList?.length
      ? selectedCompanyList?.map((item) => item?.value)
      : [];

    const finalReq = {
      companies,
      searchValue: searchText,
      sortCoulmn: selectedColumn,
      sortDirection: sortBy ? 1 : -1,
    };

    return companies?.length ? finalReq : null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, searchText, sortBy, selectedCompanyList]);

  const debouncedSearch = useDebouncedCallback(() => {
    fetchHrOfficerList({ requestBody: requestObj, pageNo: currentPage });
  }, 500);

  useEffect(() => {
    setHrOfficers([]);

    if (selectedCompanyList?.length) {
      setIsLoading(true);

      if (requestObj?.searchValue?.length) {
        debouncedSearch();
      } else {
        sortBy !== undefined &&
          fetchHrOfficerList({ requestBody: requestObj, pageNo: currentPage });
      }
    } else {
      showToast({
        type: "info",
        message: Translate("addPersonalAssistant:selectCompanyMessage"),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestObj]);

  useEffect(() => {
    if (hrOfficerList?.data?.nodes) {
      const { nodes, pageInfo } = hrOfficerList.data;

      setHrOfficers(nodes);
      setLastPage(pageInfo.lastPageNo);
      setIsLoading(false);
    }

    if (hrOfficerError && isHrOfficerError) {
      hrOfficerError?.originalStatus !== 500 &&
        showToast({
          message:
            JSON.stringify(hrOfficerError.data) ||
            Translate("common:unknownServerError"),
          type: "error",
        });
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hrOfficerList, isHrOfficerError]);

  const debounced = useDebouncedCallback((columnName) => {
    selectedColumn !== columnName
      ? setSortBy(true)
      : setSortBy((current) => !current);
  }, 500);

  const getSort = (columnName = "") => {
    setIsLoading(true);
    selectedColumn !== columnName && setSortBy(undefined);
    debounced(columnName);
    setSelectedColumn(columnName);
  };

  const CustomSrCellRender = (params) => {
    return (
      <StyledTypography component="span" sx={{ color: colors.primary_009 }}>
        {params?.api?.getRowIndexRelativeToVisibleRows(params?.row?.userId) +
          1 +
          (currentPage - 1) * 10}
      </StyledTypography>
    );
  };

  const CustomActionCellRender = (params) => {
    return (
      <div>
        <i
          className="m-2 fa fa-eye cursor-pointer manageIconColor"
          onClick={() =>
            navigate("/staff/hrOfficer/view", {
              state: params?.row?.userId,
            })
          }
          data-toggle="tooltip"
          title={Translate("common:viewToolTip")}></i>
      </div>
    );
  };

  const handleSearchText = (value) => {
    setCurrentPage(1);
    setSearchText(value);
  };

  return (
    <Grid
      item
      xs={12}
      className={`section-body ${isRtl ? "rtlMargin" : "ltrMargin"} ${
        fixNavbar ? "marginTop" : ""
      } `}>
      <StyledCard variant="outlined">
        <div className="container-fluid p-4">
          <div className="tab-content">
            <div>
              <div className="card">
                <div className="card-header card-header-spacing">
                  <CompanyHeader setShowModal={setShowModal} />
                  <div className="card-options">
                    <div className="input-group">
                      <Searchbar
                        handleSearchText={handleSearchText}
                        setSearch={setSearchText}
                      />
                    </div>

                    {checkModuleAccessOfficer(
                      userData?.add,
                      userTypes?.addHrOfficer,
                      userTypes?.officer
                    ) && (
                      <div className="header-action pull-right ml-2">
                        <button
                          type="button"
                          className="btn btn-primary py-2"
                          onClick={() => navigate("/staff/hrOfficer/add")}>
                          <i className="fe fe-plus" />
                          <StyledTypography className="mx-1" component="span">
                            {Translate("addPersonalAssistant:addButtonLabel")}
                          </StyledTypography>
                        </button>
                      </div>
                    )}
                  </div>
                </div>
                <ListView
                  columns={getHrOfficerColumns(
                    CustomActionCellRender,
                    CustomSrCellRender
                  )}
                  rows={hrOfficers}
                  autoHeight
                  sortingMode="server"
                  sortBy={sortBy}
                  onPaginationChange={setCurrentPage}
                  currentPage={currentPage}
                  showShimmer={isLoading || isHrOfficerLoading}
                  showLoader={isLoading}
                  totalPageCount={lastPage}
                  onSortModelChange={(data) =>
                    data?.length && getSort(data[0]?.field)
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </StyledCard>
      <CompanyListing
        handleModal={(value) => {
          setCurrentPage(1);
          setShowModal(value);
        }}
        showModal={showModal}
        selectedCompanyList={selectedCompanyList}
      />
    </Grid>
  );
};

export default HrOfficerListing;

export default {
  createGroup: 'Create group',
  addMember: 'Add member',
  groupSubject: "Group subject",
  groupSubjectPlaceholder: 'Type group subject',
  provideTitle: 'Provide a group subject and group icon',
  groupSubjectError: "Group subject is required",
  members: 'Members',
  typeSomethingPlaceholder: 'Type Something...',
  message: 'Message',
  view: 'View',
  remove: 'Remove',
  removeUserAlert_1: 'Remove',
  removeUserAlert_2: 'from',
  removeUserAlert_3: 'group?',
  groupIcon: 'Group icon',
  chooseFrom: 'Choose from',
  camera: 'Camera',
  gallery: 'Gallery',
  removeIconAlert: 'Remove group icon?',
  enterNewSubject: 'Enter new subject',
  groupNamePlaceholder: 'Group name',
  contacts: 'Contacts',
  addMemberToolTip: "Add member",
  removeMemberAlert: "Are you sure want to remove this member?",
  searchPlaceholder: "Search...",
  removeMemberValidationError: "Minimum three members are required in the group",
  addMemberError: "Please select any new member to continue"
};

import { Stack } from "@mui/material";
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import {
  convertDateFormat,
  convertTo12Hr,
} from "../../../../common/Utils/DateFormater";
import { getMoment } from "../../../../common/Utils/commonFunction";
import Translate from "../../../../translation/utils/translateFunction";
import CommonImage from "../../../ImageGallery/CommonImage";
import NoDataFound from "../../../ManageTask/NoDataFound";
import { Persona } from "../../../Persona";
import { styles } from "../../../TextField/TextField/index.Style";

const AddListing = ({ data, onEdit, onDelete }) => {
  const { userData } = useSelector((state) => state.formanagement);

  const date = getMoment().toDate();
  const newDate = moment(date).format("YYYY-MM-DD");

  return data.length <= 0 ? (
    <NoDataFound message={Translate("common:errorMessage")} isCenter />
  ) : (
    <Stack className="commentContainer">
      {data.map((item, idx) => {
        const {
          profileUrl,
          username,
          comment,
          createdAt,
          role,
          attachment,
          addedBy,
        } = item;

        const attachmentArray = [];
        const images = [];
        attachment?.length > 0 &&
          attachment.map((attachmentItem) => {
            const { commentFileName } = attachmentItem;
            const obj = {
              name: commentFileName,
              path: commentFileName,
              ...attachmentItem,
            };
            attachmentArray.push(obj);
            images.push(attachmentItem?.url);
          });

        return (
          <div className="row" key={idx}>
            <div className="userProfile">
              <Persona name={username} image={profileUrl} />
            </div>
            <div
              className="card main-card"
              style={{ marginBottom: attachment?.length ? "0px" : "" }}>
              <div className="card-contents">
                <span style={styles.name}>{username || ""}</span>
                <span className="messageHeading">|</span>
                <span style={styles.message}>{role || ""}</span>
                {addedBy === userData?._id && (
                  <div className="ellipsisIcon">
                    <ul className="nav nav-pills">
                      <li className="nav-item dropdown">
                        <a
                          data-toggle="dropdown"
                          role="button"
                          aria-haspopup="true"
                          aria-expanded="false">
                          <i className="fa fa-ellipsis-v p-2"></i>
                        </a>
                        <div className="dropdown-menu dropdown-menuStyling">
                          <a
                            className="dropdown-item"
                            onClick={() => onEdit?.(item)}>
                            {Translate("common:edit")}
                          </a>
                          <div className="dropdown-divider" />
                          <a
                            className="dropdown-item"
                            onClick={() => onDelete?.(item)}>
                            {Translate("common:delete")}
                          </a>
                        </div>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
              {moment(createdAt).format("YYYY-MM-DD") < newDate ? (
                <div style={styles.dateTime}>
                  {`${convertDateFormat(createdAt)}  ${convertTo12Hr(
                    createdAt
                  )}`}
                </div>
              ) : (
                <div style={{ ...styles.dateTime, direction: "ltr" }}>
                  {`  ${convertTo12Hr(createdAt)}` || ""}
                </div>
              )}
              <div style={styles.message}>{comment || ""}</div>
            </div>
            <div className="PhoneExtensionInput pl-5 scroll">
              <CommonImage
                images={images}
                files={attachmentArray}
                icon
                commentIcon
              />
            </div>
          </div>
        );
      })}
    </Stack>
  );
};

export default AddListing;

import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Translate from "../../../translation/utils/translateFunction";
import { Persona } from "../../Persona";
import {
  formatDateByToday,
  formatTimeTo12Hour,
} from "../../../common/Utils/DateFormater";
import PageHeader from "../../common/pageHeader";
import { useSelector } from "react-redux";

const ViewNotification = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { isRtl } = useSelector((state) => state.formanagement);
  const [notificationData, setNotificationData] = useState("");

  useEffect(() => {
    if (state) {
      setNotificationData(state);
    }
  }, [state]);

  return (
    <div className="row container_spacing">
      <div className="card card_spacing">
        <PageHeader
          title={Translate("notificationPage:viewHeading")}
          onClick={() => navigate("/notifications")}
        />
        <div class="card-body mt-4">
          <div className="notificationDetails">
            <Persona
              name={notificationData?.senderName}
              image={notificationData?.senderImages}
            />
            <div className="notificationContent">
              <div className="notificationHeader">
                <div className="notificationTitleBox">
                  <h3 className="card-title">{notificationData?.senderName}</h3>
                  <h3 className="card-subtitle">
                    {notificationData?.senderRole}
                  </h3>
                </div>
                <div className="notificationDateBox">
                  <div className="text-info">
                    {formatDateByToday(notificationData?.createdAt) || ""}
                  </div>
                  <div className={`text-pink ${isRtl && "leftDirection"}`}>
                    {formatTimeTo12Hour(notificationData?.formattedTime) || ""}
                  </div>
                </div>
              </div>
              <div className="notificationDesc">
                <p>{notificationData?.message}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewNotification;

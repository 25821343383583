import {
  jordanCountryCode,
  jordanCountryShortCode,
} from "../../../common/Utils/ApiConstants";
import Translate from "../../../translation/utils/translateFunction";

export const InitialValues = {
  companyName: "",
  contactName: "",

  contactDesignation: "",
  contactIndustry: "",
  contactDepartment: "",
  contactField: "",
  contactSector: "",
  workEmail: "",
  // contactPhoneCountryCode: "91",
  contactPhoneCountryCode: jordanCountryCode,

  contactPhone: "",
  // contactPhoneShortCode: "IN",
  contactPhoneShortCode: jordanCountryShortCode,

  // alternateContactCountryCode: "91",
  alternateContactCountryCode: jordanCountryCode,

  alternateContact: "",
  // alternateContactShortCode: "IN",
  alternateContactShortCode: jordanCountryShortCode,

  contactAddress: "",

  contactType: "",
};
export const radioOptions = [
  Translate("contacts:markPrivate"),
  Translate("contacts:markPublic"),
];

const OWNER = "OWNER",
  PA = "PA",
  GM = "GM",
  MANAGER = "MANAGER",
  EMPLOYEE = "EMPLOYEE";
export const UserTypes = {
  OWNER,
  PA,
  GM,
  MANAGER,
  EMPLOYEE,
};

export const PrivateListType = "PRIVATE";
export const PublicListType = "PUBLIC";
export const RequestContactInitialvalues = {
  requestTo: "",
  contactNeeded: "",
};
export const centerContent = {
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  alignContent: "center",
  fontWeight: "500",
  flex: 1,
};
export const contactypePrivateList = "PRIVATE";
export const contactypePublicList = "PUBLIC";

export default {
  head: "Attendance",
  report: "Attendance report",
  markAttendance: "Mark attendance",
  checkIn: "Check in",
  checkOut: "Check out",
  workingHours: "Working hours",
  totalDays: "Total no. of days",
  workingDays: "Working days",
  presentDays: "Present days",
  absentDays: "Absent days",
  own: "Own",
  manager: "Manager",
  employee: "Employee",
  configure: "Configure",
  holiday: "Holiday",
  onLeave: "On leave",
  absent: "Absent",
  date: "Date",
  configuration: "Configuration",
  attended: "Attended",
  addOccasion: "Add occasion",
  dateLabel: "Date",
  role: "Role",
  consentMsg: "Are you sure, wants to overwrite holiday with weekend?",
};

import { Formik } from "formik";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as LocationIcon } from "../../../../assets/images/Location.svg";
import {
  formatDateYYYYMMDD,
  formatDiffDuration,
  formatGetMonth,
  formatGetMonthWithValue,
  formatGetTime,
  formatGetTimeWithValue,
  formatGetYearsDiff,
} from "../../../../common/Utils/DateFormater";
import { StyledTypography } from "../../../../common/Utils/StyledComponents";
import { showToast } from "../../../../common/Utils/ToastMessage";
import {
  convertToB64,
  getMoment,
  getSentenseCaseforSingleWord,
} from "../../../../common/Utils/commonFunction";
import {
  useAddStaffManagerMutation,
  useDeleteStaffManagerMutation,
  useEditStaffManagerDataMutation,
  useReportToListStaffMutation,
} from "../../../../request/Staff/Manager";
import { useLazyGetCompanyListQuery } from "../../../../request/company";
import Translate from "../../../../translation/utils/translateFunction";
import AlertPermission from "../../../AlertPermission";
import Dropzone from "../../../CommonFileUpload/Dropzone";
import FormikDatePicker from "../../../FormikForm/component/FormikDatePicker";
import { FormikDropdownPicker } from "../../../FormikForm/component/FormikDropDownPicker";
import { FormikPhoneInput } from "../../../FormikForm/component/FormikPhoneInput";
import { FormikTextField } from "../../../FormikForm/component/FormikTextField";
import { Persona } from "../../../Persona";
import { VALIDATION_BUCKETURL } from "../../../Persona/constants";
import RadioButtons from "../../../RadioButtons";
import Loader from "../../Owner/LoadingComp";
import { InitialValues, ManagerUser, radioOptions } from "./constants";
import { AddManagerSchema } from "./utils";
import { colors } from "../../../../common/Theme/Colors";
import PageHeader from "../../../common/pageHeader";
import FormikSearchableDropDown from "../../../FormikForm/component/FormikSearchableDropDown";
import { getAddressError } from "../../../../common/Utils/commonFunction";
import { FormikPhoneInputWithExtField } from "../../../FormikForm/component/FormikPhoneInputWithExt";
import { handleEventKeySubmit } from "../../../../common/Utils/commonFunction";
import { CustomUserItem } from "../../../common/CustomCellRender";

const textStyle = {
  fontSize: "1.1rem",
  color: colors.black,
};
export const AddManager = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const formikRef = useRef(null);
  const { validations } = useSelector((state) => state?.formanagement);

  const [addMoreManager, setAddMoreManager] = useState(false);
  const [allCompanyData, setAllCompanyData] = useState([]);
  const [show, setshow] = useState(false);
  const [filesAdded, setfilesAdded] = useState([]);
  const [images, setImages] = useState([]);
  const initialImage = [state?.profileImageDetails];
  const [showRemoveProfileAlert, setShowRemoveProfileAlert] = useState(false);
  const [base64Img, setBase64Imge] = useState("");
  const [isHRManager, setIsHRManager] = useState(false);
  const [requestTo, setrequestTo] = useState("");
  const [requestToList, setRequestToList] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [selectedCompanyLabel, setSelectedCompanyLabel] = useState();
  const [reportToSearch, setReportToSearch] = useState("");
  const [reporter, setReporter] = useState({});
  const [onSelect, setOnSelect] = useState(false);
  const [showReportToError, setShowReportToError] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [
    addManager,
    {
      data: managerData,
      isSuccess: successfullyAddedManager,
      isError: errorAddingManager,
      isLoading: isAddManagerLoading,
      error: errorManager,
    },
  ] = useAddStaffManagerMutation();

  const [
    editManagerApi,
    {
      data: managerEditData,
      isSuccess: successfullyEditManager,
      isLoading: isEditManagerLoading,
      isError: isErrorEditManager,
      error: errorEditManager,
    },
  ] = useEditStaffManagerDataMutation();

  const [
    deleteManagerApi,
    {
      data: deleteManagerData,
      isSuccess: successfullyDeleteManager,
      isLoading: isManagerDeleteLoading,
      isError: isErrorDeleteManager,
      error: errorDeleteManager,
    },
  ] = useDeleteStaffManagerMutation();

  const [getCompanyList, { data: companyResponse }] =
    useLazyGetCompanyListQuery();

  useEffect(() => {
    getCompanyList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (companyResponse && !state) {
      let companyData = [];
      companyResponse.map((item) =>
        companyData.push({
          label: item.name,
          value: `${item._id}`,
        })
      );
      setAllCompanyData(companyData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyResponse]);

  const [
    reportToListApi,
    {
      data: reportToList,
      isSuccess: isreportToListSuccess,
      isLoading: isreportToListLoading,
      isError: isreportToListError,
      error: reportToListError,
    },
  ] = useReportToListStaffMutation();

  useEffect(() => {
    if (selectedCompany) {
      let requestObject = {
        searchText: reportToSearch,
        companyId: selectedCompany && selectedCompany,
        role: ManagerUser,
      };
      reportToListApi(requestObject);
    }
    if (state) {
      let requestObject = {
        searchText: reportToSearch,
        companyId: state?.company[0]?._id,
        role: ManagerUser,
      };
      reportToListApi(requestObject);
    }
  }, [reportToSearch, selectedCompany, state]);

  useEffect(() => {
    if (reportToList && isreportToListSuccess) {
      /* if(!reportToList?.success) {
        setShowReportToError(true);
        setRequestToList([]);
      } else {
        setSearchableData(reportToList);
      } */
      setSearchableData(reportToList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportToList, isreportToListSuccess]);

  const openModal = () => setShowModal(true);
  const hideModal = () => setShowModal(false);

  const setSearchableData = (res) => {
    const requestToData = [];

    res?.data.length > 0 &&
      res.data.map((item) => {
        const { name, _id, profileUrl, role, email } = item;
        const obj = {
          id: _id,
          value: name,
          name,
          profileUrl,
          role,
          label: (
            <CustomUserItem
              userName={name}
              title={role ? `${name} | ${role}` : name}
              profileUrl={profileUrl}
              email={email}
            />
          ),
        };
        requestToData.push(obj);
      });

    setRequestToList([...requestToData]);
  };

  useEffect(() => {
    if (state && !onSelect) {
      setOnSelect(false);
      const { reportTo } = state;

      const requestOptions = requestToList.find(
        (item) => reportTo && item.id === reportTo.id
      );
      if (requestOptions) {
        if (reportTo) {
          formikRef.current.setFieldValue(
            "reportTo",
            reportTo?.id || reportTo?._id
          );
          setrequestTo({ ...requestOptions });
        }
      }
      setReporter({
        id: reportTo?.id,
        name: reportTo?.name,
        role: reportTo?.role,
        profileUrl: reportTo?.profileUrl ? reportTo?.profileUrl : "",
      });
    }
  }, [requestToList, state, onSelect]);

  useEffect(() => {
    if (errorEditManager && isErrorEditManager) {
      errorEditManager.data.error
        ? errorEditManager.data.error.length > 0 &&
          errorEditManager.data.error.map((errorItem) => {
            const isPresent = getAddressError(
              errorItem.param,
              formikRef.current?.setFieldError
            );
            !isPresent &&
              formikRef.current.setFieldError(errorItem.param, errorItem.msg);
          })
        : showToast({
            message: errorEditManager?.data?.message,
            type: "error",
          });
    }

    if (successfullyEditManager && managerEditData) {
      showToast({ message: managerEditData.message, type: "success" });
      formikRef.current.resetForm();
      setfilesAdded([]);
      setImages([]);
      formikRef.current.resetForm();
      navigate("/managerListing");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [managerEditData, errorEditManager]);

  useEffect(() => {
    if (errorDeleteManager && isErrorDeleteManager) {
      errorDeleteManager.data.error
        ? errorDeleteManager.data.error.length > 0 &&
          errorDeleteManager.data.error.map((errorItem) =>
            formikRef.current?.setFieldError(errorItem.param, errorItem.msg)
          )
        : showToast({
            message: errorDeleteManager?.data?.message,
            type: "error",
          });
    }

    if (successfullyDeleteManager && deleteManagerData) {
      showToast({ message: deleteManagerData.message, type: "success" });
      navigate("/managerListing");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorDeleteManager, deleteManagerData]);

  useEffect(() => {
    getCompanyList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (errorAddingManager && errorManager) {
      errorManager?.data?.error
        ? errorManager?.data?.error.length > 0 &&
          errorManager.data.error.map((errorItem) => {
            const isPresent = getAddressError(
              errorItem.param,
              formikRef.current?.setFieldError
            );
            !isPresent &&
              formikRef.current.setFieldError(errorItem.param, errorItem.msg);
          })
        : showToast({ message: errorManager?.data?.message, type: "error" });
    }

    if (successfullyAddedManager && managerData) {
      showToast({ message: managerData.message, type: "success" });
      formikRef.current.resetForm();
      setfilesAdded([]);
      setImages([]);
      if (addMoreManager) {
        formikRef.current.resetForm();
        InitialValues.dob = "";
        setReporter({});
        setrequestTo("");
        formikRef.current.setFieldValue("dob", "");
        formikRef.current.setFieldValue("gender", "Male");
      } else {
        navigate("/managerListing");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [managerData, errorManager]);

  useEffect(() => {
    if (state) {
      formikRef.current.setFieldValue("dob", new Date(state?.dob));

      formikRef.current.setFieldValue("companyId", state?.company[0]?._id);

      formikRef.current.setFieldValue("name", state?.name);
      formikRef.current.setFieldValue("designation", state?.designation);
      formikRef.current.setFieldValue("department", state?.department);
      formikRef.current.setFieldValue("email", state?.login.email);

      formikRef.current.setFieldValue(
        "mobile",
        `${state?.login?.countryCode}-${state?.login?.mobile}`
      );

      formikRef.current.setFieldValue("countryCode", state?.login.countryCode);
      formikRef.current.setFieldValue(
        "mobileShortCode",
        state?.login.mobileShortCode
      );

      if (state?.hrMobile) {
        formikRef.current.setFieldValue(
          "hrMobile",

          `${state?.hrMobileCountryCode}-${state?.hrMobile}`
        );
      }

      formikRef.current.setFieldValue(
        "alternateMobileShortCode",
        state?.alternateMobileShortCode ? state?.alternateMobileShortCode : "JO"
      );

      formikRef.current.setFieldValue(
        "hrMobileCountryCode",
        state?.hrMobileCountryCode ? state?.hrMobileCountryCode : "962"
      );

      if (state?.alternateMobile) {
        formikRef.current.setFieldValue(
          "alternateMobile",

          `${state?.alternateMobileCountryCode}-${state?.alternateMobile}`
        );
      }

      formikRef.current.setFieldValue(
        "alternateMobileCountryCode",
        state?.alternateMobileCountryCode
          ? state?.alternateMobileCountryCode
          : "962"
      );

      formikRef.current.setFieldValue(
        "hrMobileShortCode",
        state?.hrMobileShortCode ? state?.hrMobileShortCode : "JO"
      );
      formikRef.current.setFieldValue("companyNumber", state?.companyNumber);
      formikRef.current.setFieldValue(
        "companyExtension",
        state?.companyExtension
      );
      formikRef.current.setFieldValue("gender", state?.gender);
      formikRef.current.setFieldValue("resFlat", state?.residenceAddress?.flat);
      formikRef.current.setFieldValue(
        "resState",
        state?.residenceAddress?.state
      );
      formikRef.current.setFieldValue(
        "resCountry",
        state?.residenceAddress?.country
      );
      formikRef.current.setFieldValue("resCity", state?.residenceAddress?.city);
      formikRef.current.setFieldValue(
        "resZipCode",
        state?.residenceAddress.zipCode
      );
      formikRef.current.setFieldValue(
        "resAddress",
        state?.residenceAddress.address
      );
      formikRef.current.setFieldValue(
        "resLat",
        state?.residenceAddress.latlong.latitude
      );
      formikRef.current.setFieldValue(
        "resLng",
        state?.residenceAddress.latlong.longitude
      );
      formikRef.current.setFieldValue("workFlat", state?.workAddress.flat);
      formikRef.current.setFieldValue("workState", state?.workAddress.state);
      formikRef.current.setFieldValue(
        "workCountry",
        state?.workAddress.country
      );
      formikRef.current.setFieldValue("workCity", state?.workAddress.city);
      formikRef.current.setFieldValue(
        "workZipCode",
        state?.workAddress.zipCode
      );
      formikRef.current.setFieldValue(
        "workAddress",
        state?.workAddress.address
      );
      formikRef.current.setFieldValue(
        "workLat",
        state?.workAddress.latlong.latitude
      );
      formikRef.current.setFieldValue(
        "workLng",
        state?.workAddress.latlong.longitude
      );
      formikRef.current.setFieldValue(
        "reportTo",
        state?.reportTo?.id || state?.reportTo?._id
      );

      if (
        state?.profileImageDetails &&
        Object.keys(state?.profileImageDetails).includes("url")
      ) {
        setfilesAdded([state?.profileImageDetails]);
        setImages([state?.profileImageDetails?.url]);
      }
      if (state?.company.length) {
        let companyData = [...state?.company];
        let dropDownData = companyData?.map((item) => ({
          label: item.name,
          value: item._id,
        }));
        setAllCompanyData(dropDownData);
      }
      if (state.isHrManager) {
        setIsHRManager(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);
  const addFiles = async (file) => {
    if (file) {
      const base64 = await convertToB64(file);
      setBase64Imge(base64);
    }

    const url =
      file.type === "image/png" ||
      file.type === "image/jpg" ||
      file.type === "image/jpeg"
        ? URL.createObjectURL(file)
        : null;
    if (url) {
      file.preview = url;
    }
    if (filesAdded.length < 1) {
      filesAdded.push(file);
      setfilesAdded([...filesAdded]);
    } else {
      showToast({
        message: Translate("common:fileAddLimitMsg"),
        type: "error",
      });
    }
  };

  useEffect(() => {
    if (filesAdded.length > 0) {
      const newImages = filesAdded.map((imageElement) =>
        imageElement.preview ? imageElement.preview : imageElement?.url
      );
      setImages(newImages);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filesAdded]);
  const acceptedFileType = {
    "image/png": [".png", ".jpg", ".jpeg"],
  };

  const removeFile = () => {
    setfilesAdded([]);
    setBase64Imge("");
    onCancelProfile();
  };

  const onCancelProfile = () => setShowRemoveProfileAlert(false);

  const currentLocationData = (places, addressType) => {
    if (addressType) {
      formikRef.current.setFieldValue("resLat", places.geometry.location.lat());
      formikRef.current.setFieldValue("resLng", places.geometry.location.lng());
      formikRef.current.setFieldValue("resAddress", places.formatted_address);

      const resAddressFields = {
        country: "resCountry",
        administrative_area_level_1: "resState",
        locality: "resCity",
        postal_code: "resZipCode",
      };

      Object.values(resAddressFields).forEach((fieldName) => {
        formikRef.current.setFieldValue(fieldName, "");
      });

      places.address_components.forEach((ele) => {
        const fieldType = ele.types[0];
        const fieldName = resAddressFields[fieldType];

        if (fieldName) {
          formikRef.current.setFieldValue(fieldName, ele.long_name);
        }
      });
    } else {
      formikRef.current.setFieldValue(
        "workLat",
        places.geometry.location.lat()
      );
      formikRef.current.setFieldValue(
        "workLng",
        places.geometry.location.lng()
      );
      formikRef.current.setFieldValue("workAddress", places.formatted_address);

      const workAddressFields = {
        country: "workCountry",
        administrative_area_level_1: "workState",
        locality: "workCity",
        postal_code: "workZipCode",
      };

      Object.values(workAddressFields).forEach((fieldName) => {
        formikRef.current.setFieldValue(fieldName, "");
      });

      places.address_components.forEach((ele) => {
        const fieldType = ele.types[0];
        const fieldName = workAddressFields[fieldType];

        if (fieldName) {
          formikRef.current.setFieldValue(fieldName, ele.long_name);
        }
      });
    }
  };

  const onDobChange = (value) => {
    const todaysDate = getMoment().toDate();
    const diff = formatDiffDuration(todaysDate, value);
    const getDate = formatGetTime();
    const getSelectedDate = formatGetTimeWithValue(value);
    const getMonth = formatGetMonth();
    const getSelectedMonth = formatGetMonthWithValue(value);
    const getYearsDiff = formatGetYearsDiff(value);
    if (
      diff >= 18 ||
      (getYearsDiff >= 18 &&
        getDate === getSelectedDate &&
        getMonth === getSelectedMonth)
    ) {
      InitialValues.dob = formatDateYYYYMMDD(value);
    } else {
      showToast({ message: Translate("addManager:ageAlert"), type: "error" });
      formikRef.current.setFieldValue("dob", "");
      InitialValues.dob = "";
    }
  };
  const deleteManager = () => {
    setshow(false);
    deleteManagerApi(state._id);
  };
  const onCancel = () => setshow(false);
  useEffect(() => {
    return () => {
      InitialValues.dob = "";
    };
  }, []);
  const onSubmit = async (companyFormValues) => {
    hideModal();
    const { dob, countryCode, alternateMobile, alternateMobileCountryCode } =
      companyFormValues;
    const HRcountryCode = companyFormValues.hrMobile
      ? companyFormValues.hrMobileCountryCode
        ? companyFormValues.hrMobileCountryCode
        : "962"
      : "";
    let requestObjectManager = {
      companyId: state
        ? [state.company[0]?._id]
        : [companyFormValues.companyId],
      name: companyFormValues?.name,
      gender: companyFormValues?.gender?.toUpperCase(),
      dob:
        moment(dob).creationData().format === "YYYY-MM-DD"
          ? dob
          : formatDateYYYYMMDD(dob),
      designation: companyFormValues?.designation,
      department: companyFormValues?.department,
      countryCode: countryCode ? countryCode : "962",
      mobile: companyFormValues?.mobile?.split("-")[1],
      mobileVerified: "YES",
      companyExtension: companyFormValues?.companyExtension,
      companyNumber: companyFormValues?.companyNumber,
      email: companyFormValues?.email,
      emailVerified: "YES",
      residenceAddress: {
        latitude: companyFormValues?.resLat,
        longitude: companyFormValues?.resLng,
        latlong: {
          latitude: companyFormValues?.resLat,
          longitude: companyFormValues?.resLng,
        },
        flat: companyFormValues?.resFlat,
        address: companyFormValues?.resAddress,
        country: companyFormValues?.resCountry,
        state: companyFormValues?.resState,
        city: companyFormValues?.resCity,
        zipCode: companyFormValues?.resZipCode,
      },
      workAddress: {
        latitude: companyFormValues?.workLat,
        longitude: companyFormValues?.workLng,
        latlong: {
          latitude: companyFormValues?.workLat,
          longitude: companyFormValues?.workLng,
        },
        flat: companyFormValues?.workFlat,
        address: companyFormValues?.workAddress,
        country: companyFormValues?.workCountry,
        state: companyFormValues?.workState,
        city: companyFormValues?.workCity,
        zipCode: companyFormValues?.workZipCode,
        countryISO: "IN",
      },
      // reportTo,
      reportTo: reporter,
      mobileShortCode: companyFormValues?.mobileShortCode,
      role: state
        ? state?.role
        : {
            type: "MANAGER",
          },
      primary: "NO",
      system: "NO",

      businessCard: {
        type: "",
        fileURL: "",
        desc: "",
      },
    };

    if (filesAdded.length > 0) {
      if (filesAdded.every((value) => initialImage?.includes(value))) {
        requestObjectManager = {
          ...requestObjectManager,
          profileUrl: state?.profileUrl,
          profileImageDetails: state?.profileImageDetails,
        };
      } else {
        const b64Object = {
          ...filesAdded.map((ele) => ({
            url: ele?.b64,
            type: ele?.type,
            profileFileName: ele?.name,
            profileFileExt: ele?.name.split(".").pop(),
          }))[0],
        };

        requestObjectManager = {
          ...requestObjectManager,
          webProfileUrl: b64Object,
        };
      }
    } else {
      requestObjectManager = {
        ...requestObjectManager,
      };
    }

    if (
      companyFormValues?.alternateMobile?.length > 0 &&
      !companyFormValues?.alternateMobile?.endsWith("-")
    ) {
      requestObjectManager.alternateMobile =
        companyFormValues?.alternateMobile?.split("-")[1];
      requestObjectManager.alternateMobileCountryCode = alternateMobile
        ? alternateMobileCountryCode
          ? alternateMobileCountryCode
          : "962"
        : "";
      requestObjectManager.alternateMobileShortCode =
        companyFormValues.alternateMobileShortCode;
    } else {
      requestObjectManager.alternateMobile = "";
      requestObjectManager.alternateMobileCountryCode = "";
      requestObjectManager.alternateMobileShortCode = "";
    }
    if (
      companyFormValues?.hrMobile?.length > 0 &&
      !companyFormValues?.hrMobile?.endsWith("-")
    ) {
      requestObjectManager.hrMobile =
        companyFormValues?.hrMobile?.split("-")[1];
      requestObjectManager.hrMobileCountryCode = HRcountryCode;
      requestObjectManager.hrMobileShortCode =
        companyFormValues.hrMobileShortCode;
    } else {
      requestObjectManager.hrMobile = "";
      requestObjectManager.hrMobileCountryCode = "";
      requestObjectManager.hrMobileShortCode = "";
    }
    if (isHRManager) {
      requestObjectManager = {
        ...requestObjectManager,
        isHrManager: isHRManager,
      };
    } else {
      requestObjectManager = {
        ...requestObjectManager,
        isHrManager: false,
      };
    }

    if (state) {
      await editManagerApi({
        body: requestObjectManager,
        managerId: state._id,
      });
    } else {
      await addManager(requestObjectManager);
    }
  };

  const addAsHRManager = () => {
    if (isHRManager === false) {
      setIsHRManager(true);
    } else {
      setIsHRManager(false);
    }
  };

  const resetOnCompanyChange = () => {
    setrequestTo("");
    setRequestToList([]);
    formikRef.current.setFieldValue("reportTo", "");
  };

  return (
    <Formik
      initialValues={InitialValues}
      innerRef={formikRef}
      validateOnMount
      onSubmit={showModal || state ? onSubmit : openModal}
      validationSchema={AddManagerSchema}>
      {({ values, setFieldValue, handleSubmit, submitForm }) => {
        return (
          <div className="row container_spacing">
            <div className="card card_spacing">
              <PageHeader
                title={
                  state
                    ? Translate("addManager:editManagerHeading")
                    : Translate("addManager:addManagerHeading")
                }
                onClick={() => {
                  state
                    ? navigate("/viewManager", { state: state._id })
                    : navigate("/managerListing");
                }}
                RightContainer={() =>
                  state?.isDeleteable && (
                    <div className="card-options">
                      <i
                        className="fe fe-trash-2 cursor-pointer"
                        onClick={() => setshow(true)}
                        data-toggle="tooltip"
                        title={Translate("common:deleteToolTip")}></i>
                    </div>
                  )
                }
              />
              <div
                className="card-body"
                onKeyDown={(e) => handleEventKeySubmit(e, handleSubmit)}>
                <div className="row imageDisplay mb-2">
                  <div className="form-group col-md-4 col-sm-8">
                    <Persona
                      isProfile={!state}
                      name={state?.name}
                      isBase64={base64Img ? true : false}
                      image={
                        filesAdded?.length && images?.length
                          ? images[0].includes(VALIDATION_BUCKETURL)
                            ? images[0]
                            : base64Img
                          : ""
                      }
                      size={96}
                    />
                    <Dropzone
                      onfileSelect={(filesArray) => addFiles(filesArray)}
                      acceptedImages={acceptedFileType}
                      maximumFiles={1}
                      fileLength={filesAdded.length}
                      maxFileSize={50 * 1000000}
                      className="staffProfileIcon"
                      isProfile
                      deleteAlert={() => {
                        setShowRemoveProfileAlert(true);
                      }}
                      isShowCameraDeleteIcon={filesAdded?.length}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-4 col-sm-12">
                    <FormikDropdownPicker
                      options={allCompanyData}
                      value={
                        state?.company
                          ? allCompanyData[0]?.value
                          : values.companyId
                      }
                      name="companyId"
                      label={Translate("addManager:selectCompany")}
                      placeholder={Translate("addManager:dropdownPlaceholder")}
                      onSelect={(value, label) => {
                        setSelectedCompanyLabel(label);
                        setFieldValue("companyId", value);
                        setSelectedCompany(value);
                        selectedCompany !== value && resetOnCompanyChange();
                      }}
                      disabled={state?.isEditable}
                      required
                      autoFocus={!state}
                    />
                  </div>
                  <div className="form-group col-md-4 col-sm-12">
                    <FormikTextField
                      name="name"
                      label={Translate("addManager:name")}
                      placeholder={Translate("addManager:name")}
                      autoComplete={"off"}
                      maxLength={validations?.nameLength.MAX}
                      required
                    />
                  </div>
                  <div className="form-group col-md-4 col-sm-12">
                    <FormikDatePicker
                      label={Translate("addManager:dob")}
                      containerStyle="datePickerContainer"
                      className="form-control bw-0 "
                      format="yyyy-MM-dd"
                      name="dob"
                      value={values.dob}
                      onChange={onDobChange}
                      required
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-4 col-sm-12">
                    <FormikTextField
                      name="designation"
                      label={Translate("addManager:designation")}
                      placeholder={Translate("addManager:designation")}
                      autoComplete={"off"}
                      maxLength={validations?.designationLength.MAX}
                      required
                    />
                  </div>
                  <div className="form-group col-md-4 col-sm-12">
                    <FormikTextField
                      name="department"
                      label={Translate("addManager:department")}
                      placeholder={Translate("addManager:department")}
                      autoComplete={"off"}
                      maxLength={validations?.departmentLength.MAX}
                      required
                    />
                  </div>

                  <div
                    className="form-group col-md-4 col-sm-12"
                    style={{ marginTop: "0.3rem" }}>
                    <FormikSearchableDropDown
                      selected={requestTo}
                      onSelect={(value) => {
                        setrequestTo(value);
                        setFieldValue("reportTo", value.id);

                        setReporter({
                          id: value.id,
                          name: value.name,
                          profileUrl: value.profileUrl,
                          role: value.role,
                        });
                        setOnSelect(true);
                      }}
                      options={requestToList}
                      onChange={(text) => setReportToSearch(text.trim())}
                      placeholder={Translate("addManager:reportTo")}
                      label={Translate("addManager:reportTo")}
                      name="reportTo"
                      required
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-4 col-sm-12">
                    <FormikPhoneInput
                      label={Translate("addManager:contactNumber")}
                      name="mobile"
                      value={values.mobile}
                      placeholder={Translate(
                        "addManager:contactNumberPlaceHolder"
                      )}
                      maxLength={validations?.contactPhoneLength.MAX}
                      onChange={(value, country) => {
                        setFieldValue("mobile", value);
                        setFieldValue(
                          "mobileShortCode",
                          country?.countryCode?.toUpperCase()
                        );
                        setFieldValue("countryCode", country?.dialCode);
                      }}
                      autoComplete={"off"}
                      required
                      isLogin
                    />
                  </div>
                  <div className="form-group col-md-4 col-sm-12">
                    <FormikPhoneInput
                      label={Translate("addEmployee:alternateNumber")}
                      name="alternateMobile"
                      value={values.alternateMobile}
                      placeholder={Translate(
                        "addEmployee:contactNumberPlaceholder"
                      )}
                      maxLength={validations?.contactPhoneLength.MAX}
                      onChange={(value, country) => {
                        setFieldValue("alternateMobile", value);
                        setFieldValue(
                          "alternateMobileShortCode",
                          country?.countryCode?.toUpperCase()
                        );
                        setFieldValue(
                          "alternateMobileCountryCode",
                          country?.dialCode
                        );
                      }}
                      autoComplete={"off"}
                      isLogin
                    />
                  </div>
                  <div className="form-group col-md-4 col-sm-12">
                    <FormikPhoneInput
                      label={Translate("addManager:hrContactNumber")}
                      name="hrMobile"
                      value={values.hrMobile}
                      placeholder={Translate(
                        "addManager:hrContactNumberPlaceHolder"
                      )}
                      maxLength={validations?.contactPhoneLength.MAX}
                      onChange={(value, country) => {
                        setFieldValue("hrMobile", value);
                        setFieldValue(
                          "hrMobileShortCode",
                          country?.countryCode?.toUpperCase()
                        );
                        setFieldValue("hrMobileCountryCode", country?.dialCode);
                      }}
                      autoComplete={"off"}
                      isLogin
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="form-group col-md-4 col-sm-12">
                    <FormikPhoneInputWithExtField
                      name="companyNumber"
                      extName="companyExtension"
                      label={Translate("addManager:extensionNumber")}
                      placeholder={Translate(
                        "addManager:extensionNumberPlaceholder"
                      )}
                      extPlaceholder={Translate(
                        "addManager:companyPhoneExtensionPlaceholder"
                      )}
                      autoComplete={"off"}
                      maxLength={validations?.telephoneLength.MAX}
                      extMaxLength={validations?.companyExtension.MAX}
                    />
                  </div>
                  <div className="form-group col-md-4 col-sm-12">
                    <FormikTextField
                      name="email"
                      label={Translate("addManager:email")}
                      placeholder={Translate("addManager:emailPlaceholder")}
                      autoComplete={"off"}
                      required
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-12 col-sm-12">
                    <div className="AddressLabel">
                      <StyledTypography variant="subtitle1">
                        {Translate("addManager:address")}
                        <StyledTypography
                          sx={{ color: "red" }}
                          component="span">
                          {" *"}
                        </StyledTypography>
                      </StyledTypography>
                    </div>
                    <FormikTextField
                      name="resAddress"
                      placeholder={Translate("addManager:addressPlaceholder_1")}
                      autoComplete={"off"}
                      isLocation={true}
                      RenderRightContainer={
                        <LocationIcon className="formikLocationIcon" />
                      }
                      locationValue={(currentLocation) => {
                        currentLocationData(currentLocation, true);
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-12 col-sm-12">
                    <FormikTextField
                      name="resFlat"
                      placeholder={Translate("addManager:addressPlaceholder_2")}
                      autoComplete={"off"}
                      maxLength={validations?.landmarkLimit.MAX}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-4 col-sm 8">
                    <FormikTextField
                      name="resCountry"
                      placeholder={Translate("addManager:countryPlaceholder")}
                      maxLength={validations?.cityLimit.MAX}
                    />
                  </div>
                  <div className="form-group col-md-4 col-sm 8">
                    <FormikTextField
                      name="resState"
                      placeholder={Translate("addManager:statePlaceholder")}
                      maxLength={validations?.cityLimit.MAX}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-4 col-sm 8">
                    <FormikTextField
                      name="resCity"
                      placeholder={Translate("addManager:cityPlaceholder")}
                      maxLength={validations?.cityLimit.MAX}
                    />
                  </div>
                  <div className="form-group col-md-4 col-sm 8">
                    <FormikTextField
                      name="resZipCode"
                      maxLength={6}
                      placeholder={Translate("addManager:zipcodePlaceholder")}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="form-group col-md-12 col-sm-12">
                    <div className="AddressLabel">
                      <StyledTypography variant="subtitle1">
                        {Translate("addManager:workAddress")}
                        <StyledTypography
                          sx={{ color: "red" }}
                          component="span">
                          {" *"}
                        </StyledTypography>
                      </StyledTypography>
                    </div>
                    <FormikTextField
                      name="workAddress"
                      placeholder={Translate("addManager:addressPlaceholder_3")}
                      autoComplete={"off"}
                      isLocation={true}
                      RenderRightContainer={
                        <LocationIcon className="formikLocationIcon" />
                      }
                      locationValue={(currentLocation) => {
                        currentLocationData(currentLocation, false);
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-12 col-sm-12">
                    <FormikTextField
                      name="workFlat"
                      placeholder={Translate("addManager:addressPlaceholder_4")}
                      autoComplete={"off"}
                      maxLength={validations?.landmarkLimit.MAX}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-4 col-sm 8">
                    <FormikTextField
                      name="workCountry"
                      maxLength={validations?.cityLimit.MAX}
                      placeholder={Translate("addManager:countryPlaceholder")}
                    />
                  </div>
                  <div className="form-group col-md-4 col-sm 8">
                    <FormikTextField
                      name="workState"
                      maxLength={validations?.cityLimit.MAX}
                      placeholder={Translate("addManager:statePlaceholder")}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-4 col-sm 8">
                    <FormikTextField
                      name="workCity"
                      placeholder={Translate("addManager:cityPlaceholder")}
                      maxLength={validations?.cityLimit.MAX}
                    />
                  </div>
                  <div className="form-group col-md-4 col-sm 8">
                    <FormikTextField
                      name="workZipCode"
                      maxLength={6}
                      placeholder={Translate("addManager:zipcodePlaceholder")}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-4 col-sm 8">
                    <RadioButtons
                      radioOptions={radioOptions}
                      defaultChecked={
                        state && state?.gender === "FEMALE" ? 1 : 0
                      }
                      label={Translate("addManager:gender")}
                      onPress={(value) => {
                        setFieldValue("gender", value.target.value);
                      }}
                      required
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-4 col-sm 8">
                    <StyledTypography
                      className="labelStyling"
                      component="span"
                      style={{ marginRight: 10 }}>
                      {Translate("addManager:marskAsHrManager")}
                    </StyledTypography>
                    <input
                      type="checkbox"
                      checked={isHRManager}
                      className="cursor-pointer"
                      style={{ color: colors.primary }}
                      label={Translate("addManager:marskAsHrManager")}
                      onClick={() => addAsHRManager()}
                    />
                  </div>
                </div>
                <div className="card-footer text-right">
                  <button
                    type="submit"
                    className="btn btn-primary saveButton"
                    onClick={() => {
                      handleSubmit();
                      setAddMoreManager(false);
                    }}>
                    {Translate("addManager:save")}
                  </button>
                  {!state && (
                    <button
                      type="submit"
                      className="btn btn-white addButton"
                      onClick={() => {
                        submitForm();
                        setAddMoreManager(true);
                      }}>
                      {Translate("addManager:addMore")}
                    </button>
                  )}
                </div>
              </div>
              <Loader
                loading={
                  isAddManagerLoading ||
                  isEditManagerLoading ||
                  isManagerDeleteLoading /* ||
                  isreportToListLoading */
                }
              />
              <AlertPermission
                show={show}
                subTitle={Translate("addManager:alertManager")}
                onOk={deleteManager}
                onCanel={onCancel}
              />
              <AlertPermission
                show={showRemoveProfileAlert}
                subTitle={Translate("common:deleteProfileAlert")}
                onOk={removeFile}
                onCanel={onCancelProfile}
              />
              {showReportToError && (
                <AlertPermission
                  show={showReportToError}
                  subTitle={reportToList?.message}
                  onOk={() => setShowReportToError(false)}
                  isBtnAlignLeft
                  hideCancel
                />
              )}
            </div>
            {!state && (
              <AlertPermission
                show={showModal}
                title={Translate("addManager:confirmation")}
                okTitle={Translate("common:confirm")}
                subTitle={
                  <StyledTypography
                    variant="subtitle1"
                    sx={{
                      fontWeight: 500,
                      ...textStyle,
                    }}>
                    {Translate("addManager:alertText1")}
                    {selectedCompanyLabel && (
                      <StyledTypography
                        variant="span"
                        sx={{
                          fontWeight: 600,
                          ...textStyle,
                        }}>
                        {selectedCompanyLabel}
                      </StyledTypography>
                    )}
                    {Translate("addManager:alertText2")}
                    {values.name && (
                      <StyledTypography
                        variant="span"
                        sx={{
                          fontWeight: 600,
                          ...textStyle,
                        }}>
                        {values.name}
                      </StyledTypography>
                    )}
                    {Translate("addManager:alertText3")}
                  </StyledTypography>
                }
                onOk={handleSubmit}
                onCanel={hideModal}
              />
            )}
          </div>
        );
      }}
    </Formik>
  );
};

export default AddManager;

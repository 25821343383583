import Translate from "../../../translation/utils/translateFunction";

export const isSeriesAllZeros = (array) => {
  return array?.every((item) => item === 0);
};

export const truncateHolidayText = (text, targetText, maxWidth) => {
  if (!targetText || !targetText?.length) return text;

  const holidayText = `${Translate("reports:holiday")} (`;
  const reasonText = `${targetText})`;
  const maxReasonLength = maxWidth - holidayText.length - 3; // 3 for ellipsis

  if (reasonText.length > maxReasonLength) {
    const truncatedReason = reasonText.substring(0, maxReasonLength) + "...";
    return holidayText + truncatedReason + ")";
  } else {
    return text;
  }
};

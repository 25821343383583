export default {
  head: "Activity logs",
  activity: "Activity",
  userTitle: "User name",
  companyTitle: "Company name",
  dateTimeTitle: "Date and time",
  activityTitle: "Activity name",
  exportOwnerActivityLogsTitle: "FOM_OWNER_ACTIVITY_LOGS",
  exportPAActivityLogsTitle: "FOM_PA_ACTIVITY_LOGS",
  exportGMActivityLogsTitle: "FOM_GM_ACTIVITY_LOGS",
  exportManagerActivityLogsTitle: "FOM_MANAGER_ACTIVITY_LOGS",
  exportEmployeeActivityLogsTitle: "FOM_EMPLOYEE_ACTIVITY_LOGS",
  exportVendorActivityLogsTitle: "FOM_VENDOR_ACTIVITY_LOGS",
};

export default {
  head: "Staff report",
  owner: "Owner",
  manager: "Manager",
  employees: "Employees",
  vendor: "Vendor",
  viewDetailsTitle: "Staff report detail",
  nameHeading: "Name",
  companyNameHeading: "Company name",
  totalAssignedHeading: "Total assigned",
  inProgressHeading: "In progress",
  completedHeading: "Completed",
  exportStaffFileName: "FOM_STAFF_REPORT",
};

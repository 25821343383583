import { Stack } from "@mui/material";
import React from "react";
import { colors } from "../../../../common/Theme/Colors";
import { convertTo12Hr } from "../../../../common/Utils/DateFormater";
import { StyledTypography } from "../../../../common/Utils/StyledComponents";
import Translate from "../../../../translation/utils/translateFunction";

export const PdfMessageView = ({ currentMessage, type, onClick }) => {
  return (
    <Stack
      onClick={() => onClick(currentMessage)}
      sx={{ marginRight: "8px", width: "100%" }}
      className="cursor-pointer"
    >
      <div
        style={styles.docxFileName}
       /*  onClick={() => {
          navigation("ViewPDF", {
            data: currentMessage?.attachmentUrl,
          });
        }} */
      >
        <Stack flexDirection={"row"} verticalAlign="center" padding={"5px"}>
          <i className="fa fa-file-pdf-o" style={styles.pdfIcon} />
          <StyledTypography variant={"subtitle1"} className="ellipsisStyle">
            {/*  {currentMessage?.attachmentUrlDetails?.messageFileName ||
              decodeURIComponent(currentMessage?.attachmentUrl)
                .split("/")
                .pop()} */}
            {`${Translate("addOwner:file")}.${
              currentMessage?.attachmentUrlDetails?.messageFileExt
            }`}
          </StyledTypography>
        </Stack>
      </div>
      <Stack
        flexDirection={"row"}
        alignItems="space-between"
        justifyContent="space-between"
        style={styles.docxFooter}
      >
        <Stack flexDirection={"row"} verticalAlign="center">
          <StyledTypography
            variant={"subtitle2"}
            style={styles.receiverFileSize}
          >
            {currentMessage.size}
          </StyledTypography>
          {/* <div style={styles.dot} /> */}
          {/* <StyledTypography
            variant={"subtitle2"}
            style={styles.receiverFileSize}>
            PDF
          </StyledTypography> */}
        </Stack>
        {type !== "People" && !currentMessage?.message && (
          <Stack flexDirection={"row"} alignItems={"flex-end"}>
            <span className="time leftDirection chatDocTime">
              {convertTo12Hr(currentMessage?.updatedAt)}
            </span>
            {/* <Icon
            name="sent_tick"
            size={16}
            color={colors.primary_003}
            style={styles.tickIcon}
          /> */}
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};
const styles = {
  docxFileName: {
    height: 35,
    /* width: "90%", */
    width: 136,
    backgroundColor: colors.grey_013,
    justifyContent: "center",
    alignItems: "center",
    /* marginLeft: 10, */
    marginBottom: 3,
    /* marginTop: 5, */
    marginTop: 1,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    borderBottomLeftRadius: 6,
  },
  pdfIcon: { marginInline: 10, alignSelf: "center", color: colors.red },
  wordFileName: { width: 170 },
  docxFooter: { /* marginLeft: 10 */ marginRight: 10 },
  receiverFileSize: { color: colors.black },
  dot: {
    height: 3,
    width: 3,
    borderRadius: 3,
    backgroundColor: colors.black,
  },
  tickIcon: {
    marginLeft: 3,
    marginRight: 3,
  },
};

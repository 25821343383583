import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { showToast } from "../../../../common/Utils/ToastMessage";
import { convertToSentenceCase } from "../../../../common/Utils/commonFunction";
import { useGetPerformanceReportDetailsMutation } from "../../../../request/Report";
import Translate from "../../../../translation/utils/translateFunction";
import ListView from "../../../ListView";
import Donutchart from "../../../common/donutchart";
import Loader from "../../../staff/Owner/LoadingComp";
import PerformanceCardView from "./PerformanceCardView";
import { getPerformanceReportDetailsColumns } from "./column.data";
import { isSeriesAllZeros } from "../../components/utils";

const PerformanceDetails = () => {
  const { selectedCompanyList, isRtl } = useSelector(
    (state) => state.formanagement
  );
  const navigate = useNavigate();
  const { state } = useLocation();
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [lastPage, setLastPage] = useState(0);
  const [priorityFinalArray, setPriorityFinalArray] = useState([]);
  const [graphDetails, setGraphDetails] = useState("");
  const [isGraphLoading, setIsGraphLoading] = useState(true);

  const [
    performanceReportDetails,
    {
      data: performanceReportDetailsData,
      isLoading: isLoadingReportDetails,
      isError: isErrorReportDetails,
      error: errorReportDetails,
    },
  ] = useGetPerformanceReportDetailsMutation();

  const requestObj = useMemo(() => {
    const companies = selectedCompanyList?.length
      ? selectedCompanyList?.map((item) => item?.value)
      : [];

    const finalReq = {
      userId: state?._id,
      companyId: companies,
      searchText: "",
      pageNo: currentPage,
      // projectId:
      //   !state?.projectId || state?.projectId === "null"
      //     ? null
      //     : state?.projectId,
    };

    return companies?.length ? finalReq : null;
  }, [currentPage, selectedCompanyList, state]);

  useEffect(() => {
    performanceReportDetails(requestObj);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestObj]);

  useEffect(() => {
    if (performanceReportDetailsData?.data?.nodes) {
      const { nodes, pageInfo, legendsColor } =
        performanceReportDetailsData.data;

      let arr = Object.keys(nodes);
      let finalArr = [];
      arr.forEach((item, index) => {
        if (
          item.match(
            /^(EmergencyDetails|HighDetails|LowDetails|MediumDetails)$/
          )
        ) {
          const {
            totalAssigned,
            inProgress,
            overDue,
            onTime,
            beforeTime,
            afterTime,
            reopened,
            resolved,
            assigned,
          } = nodes[item];

          var priority = item.replace("Details", "");
          let currentObj = {
            name: priority,
            assigned,
            totalAssigned,
            inProgress,
            overDue,
            onTime,
            beforeTime,
            afterTime,
            reopened,
            resolved,
            _id: index + 1,
          };
          finalArr.push(currentObj);
        }
        setPriorityFinalArray(finalArr);
      });
      setLastPage(pageInfo.lastPageNo);
      setIsLoading(false);
      handleGraphData(nodes?.PerformanceDetails, legendsColor);
    }

    if (errorReportDetails && isErrorReportDetails) {
      showToast({
        message:
          JSON.stringify(errorReportDetails.data) ||
          Translate("common:unknownServerError"),
        type: "error",
      });
      setIsLoading(false);
    }
  }, [performanceReportDetailsData, errorReportDetails, isErrorReportDetails]);

  const handleGraphData = (PerformanceDetails, legendsColor) => {
    const seriesData = [];
    const labelData = [];
    const colorsData = [];
    const updatedGraphData = [];

    const labels = Object.keys(legendsColor);

    for (const item of labels) {
      let performanceVal = 0;
      let updatedLabel = "";

      if (item === "inprogress") {
        performanceVal = parseFloat(PerformanceDetails["inProgress"]);
      } else {
        performanceVal = parseFloat(PerformanceDetails[item]);
      }

      seriesData.push(performanceVal);
      colorsData.push(legendsColor[item]);

      if (item === "onTime" || item === "beforeTime" || item === "afterTime") {
        updatedLabel = Translate(`performanceReport:completed${item}`);
      } else if (item === "totalAssigned") {
        // updatedLabel = Translate("performanceReport:assigned");
      } else {
        updatedLabel = Translate(`performanceReport:${item.toLowerCase()}`);
      }

      labelData.push(updatedLabel);
      updatedGraphData.push({ type: updatedLabel, count: performanceVal });
    }

    const graphObj = {
      series: isSeriesAllZeros(seriesData) ? [] : seriesData,
      options: {
        states: {
          active: {
            filter: { type: "none", value: 0 },
          },
          hover: {
            filter: {
              type: "none",
            },
          },
        },
        colors: colorsData,
        labels: labelData,
        fill: {
          colors: colorsData,
        },
        legend: {
          position: isRtl ? "left" : "right",
          horizontalAlign: "center",
          fontSize: "14px",
          fontWeight: 400,
          offsetX: 100,
          offsetY: 0,
          markers: {
            fillColors: colorsData,
          },
          itemMargin: {
            horizontal: 10,
            vertical: 10,
          },
        },
        tooltip: {
          y: {
            formatter: (val) => {
              return "";
            },
            fillSeriesColor: true,
            title: {
              formatter: (seriesName) => {
                const hoveredItem = updatedGraphData?.find(
                  (item) => item?.type === seriesName
                );

                let hoveredVal = isRtl
                  ? `% ${hoveredItem?.count}`
                  : `${hoveredItem?.count} %`;

                return `${hoveredItem?.type}: ${hoveredVal}`;
              },
            },
          },
        },
        dataLabels: {
          textAnchor: "middle",
          style: {
            fontSize: "14px",
            fontWeight: "bold",
          },
          formatter: function (val, opts) {
            return seriesData[opts?.seriesIndex] + "%";
          },
        },
        stroke: {
          width: updatedGraphData.find((item) => item?.count == "100") ? 0 : 2,
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "right",
              },
            },
          },
        ],
      },
    };
    setGraphDetails(graphObj);
    setIsGraphLoading(false);
    return;
  };

  return (
    <div className={`section-body ${isRtl ? "rtlMargin" : "ltrMargin"} mt-3`}>
      <div className="container-fluid">
        <div className="tab-content">
          <div className="d-flex col-12">
            <div className="card" style={{ marginBottom: 8 }}>
              <div className="card-header">
                <i
                  className="icon fe fe-arrow-left mr-3 icon-size backnavigation-iconColor cursor-pointer"
                  onClick={() => navigate(-1)}
                  title={Translate("common:backToolTip")}
                />
                <h5 className="page-title">
                  {Translate("performanceReport:performanceDetailsHeader")}
                </h5>
              </div>
            </div>
          </div>
          <div className="d-flex">
            <div className="col-md-3 col-sm-12">
              {isLoadingReportDetails ? (
                <Loader loading={isLoadingReportDetails} />
              ) : (
                <PerformanceCardView
                  data={performanceReportDetailsData?.data?.nodes}
                />
              )}
            </div>
            <div className="col-md-9 col-sm-12" style={{ width: "100%" }}>
              {isLoadingReportDetails || isGraphLoading ? (
                <Loader isListLoader />
              ) : (
                <div className="card performanceReportGraph">
                  {graphDetails?.series?.length ? (
                    <Donutchart
                      customOptions={graphDetails?.options}
                      series={graphDetails?.series}
                      height={"370px"}
                    />
                  ) : (
                    <div className="taskReportGraphNoData">
                      <p>{Translate("taskReport:graphDataNotFound")}</p>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="col-md-12">
            <div className="card">
              <div className="card-body" style={{ height: "300px" }}>
                <ListView
                  columns={getPerformanceReportDetailsColumns()}
                  rows={priorityFinalArray}
                  autoHeight
                  sortingMode="server"
                  showShimmer={isLoading || isLoadingReportDetails}
                  showLoader={isLoading}
                  isExpandedTable
                  hideFooterPagination
                  hideFooter
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PerformanceDetails;

import Translate from "../../../../translation/utils/translateFunction";
import {
  CustomTitleCellRender,
  CustomTagCellRender,
  CustomDateTimeCellRender,
} from "../../../common/CustomCellRender";

export const getProjectReportColumns = (CustomSrCellRender) => {
  const columns = [
    {
      field: "lineNo",
      headerName: Translate("common:srNoTitle"),
      align: "center",
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      renderCell: CustomSrCellRender,
    },
    {
      field: "projectNumber",
      headerName: Translate("project:projectIdHeading"),
      flex: 0.3,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) =>
        CustomTitleCellRender(params?.row?.projectNumber || ""),
    },
    {
      field: "title",
      headerName: Translate("project:projectNameHeading"),
      flex: 0.6,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) =>
        CustomTitleCellRender(params?.row?.title, "maxwidth-350"),
    },
    {
      field: "companyName",
      headerName: Translate("staffReport:companyNameHeading"),
      flex: 0.5,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => CustomTitleCellRender(params?.row?.companyName),
    },
    {
      field: "formattedDate",
      headerName: Translate("document:dateTimeTableHeading"),
      flex: 0.3,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) =>
        CustomDateTimeCellRender(
          params?.row?.formattedDate,
          params?.row?.projectStartTime
        ),
    },
    {
      field: "tasksAllocated",
      headerName: Translate("project:taskCountHeading"),
      flex: 0.3,
      sortable: false,
      disableColumnMenu: true,
      headerAlign: "center",
      align: "center",
      renderCell: (params) =>
        CustomTagCellRender(params?.row?.tasksAllocated, "tag-theme-blue"),
    },
  ];

  return columns;
};

import * as yup from "yup";
import Translate from "../../../../translation/utils/translateFunction";
import { validateMobileSchema } from "../../../../common/Utils/commonFunction";

export const AddOwnerSchema = () => {
  const schema = yup.object().shape({
    companyId: yup
      .array()
      .of(yup.string().trim())
      .required(Translate("addOwner:companyError"))
      .min(1, Translate("addOwner:companyError")),
    name: yup.string().trim().required(Translate("addOwner:nameError")),
    dob: yup.string().trim().required(Translate("addOwner:dateOfBirthError")),
    designation: yup
      .string()
      .trim()
      .required(Translate("addOwner:designationError")),
    department: yup
      .string()
      .trim()
      .required(Translate("addOwner:departmentError")),
    ...validateMobileSchema("mobile", Translate("addOwner:numberError_2")),
    email: yup
      .string()
      .email()
      .trim()
      .required(Translate("addOwner:workEmailError")),
    registerAddress: yup
      .string()
      .trim()
      .required(Translate("addOwner:addressError")),
    resCountry: yup
      .string()
      .trim()
      .required(Translate("addManager:countryError")),
    resState: yup.string().trim().required(Translate("addManager:stateError")),
    workCountry: yup
      .string()
      .trim()
      .required(Translate("addManager:countryError")),
    workState: yup.string().trim().required(Translate("addManager:stateError")),
    workAddress: yup
      .string()
      .trim()
      .required(Translate("addOwner:workAddressError")),
  });

  return schema;
};

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { ProSidebarProvider } from "react-pro-sidebar";
import { BrowserRouter as Router } from "react-router-dom";
import { store } from "./store/store";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import { SnackbarProvider } from "notistack";
import { Slide } from "@mui/material";
const persistor = persistStore(store);
ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <ProSidebarProvider>
        <Router>
          <React.StrictMode>
            <SnackbarProvider
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              TransitionComponent={Slide}
              maxSnack={3}>
              <App />
            </SnackbarProvider>
          </React.StrictMode>
        </Router>
      </ProSidebarProvider>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);
// registerServiceWorker();
// ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import { Methods, Urls } from "../../../common/Utils/ApiConstants";

import { CreateApi } from "../../CreateApi";
const mydocumentListingcollection = CreateApi.injectEndpoints({
  endpoints: (builder) => ({
    getMyDocumentListing: builder.mutation({
      query: (body) => {
        return {
          url: Urls.getMyDocumentList,
          method: Methods.post,
          body: body,
        };
      },
    }),
    getSharedDocumentListing: builder.mutation({
      query: (body) => {
        return {
          url: Urls.getSharedDocumentList,
          method: Methods.post,
          body: body,
        };
      },
    }),
    getShareDocInviteeList: builder.mutation({
      query: (body) => {
        return {
          url: Urls.getShareDocumentInvitee,
          method: Methods.post,
          body: body,
        };
      },
    }),
    shareDocument: builder.mutation({
      query: (body) => {
        return {
          url: Urls.shareDocument,
          method: Methods.post,
          body: body,
        };
      },
    }),
  }),
});
export const { useGetMyDocumentListingMutation, useGetSharedDocumentListingMutation, useGetShareDocInviteeListMutation, useShareDocumentMutation } = mydocumentListingcollection;

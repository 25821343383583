import React from "react";
import Loader from "../../../../staff/Owner/LoadingComp";
import Translate from "../../../../../translation/utils/translateFunction";
const MessageListLoader = ({
  isLoading,
  onLoadMoreClick,
  hideMargin,
  message = Translate("manageTask:loadMoreTitle") + "...",
  styleProps = {},
  isShareModal = false,
}) => {
  return (
    <div
      className="loaderAlignment"
      style={{
        marginBottom: hideMargin && "0px",
        marginTop: hideMargin && "25px",
        ...styleProps,
      }}
    >
      {isLoading ? (
        <Loader message={message} isListLoader />
      ) : (
        <span
          // className="loadMoreStyle cursor-pointer"
          className={` cursor-pointer ${
            "loadMoreStyleShareButton"
            /* isShareModal ? "loadMoreStyleShareButton" : "loadMoreStyle" */
          }`}
          onClick={() => onLoadMoreClick?.()}
        >
          {Translate("manageTask:loadMoreTitle")}
        </span>
      )}
    </div>
  );
};
export default MessageListLoader;

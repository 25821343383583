export const InitialValues = {
  email: "",
  password: "",
  phoneNumber: "",
};

export const ChangePassInitialValues = {
  oldPass: "",
  newPass: "",
  confirmPass: "",
};

export const ForgotPasswordInitialValues = {
  existingemail: "",
};
export const SetNewPasswordInitialValues = {
  oldPass: "",
  newPass: "",
  confirmPass: "",
};

export default {
    head: 'الفلتر',
    clearAll: 'مسح',
    apply: 'تنفيذ',
    close: 'اغلاق',
    title: 'العنوان',
    priority: 'الأولوية',
    status: 'الحالة',
    companies: 'الشركات',
    staff: 'طاقم العمل',
    sortBy: 'ترتيب',
    all: 'الجميع',
    task: 'المهمة',
    project: 'المشروع',
    goal: 'الهدف',
    emergency: 'طارئ',
    high: 'مرتفع',
    medium: 'متوسط',
    low: 'منخفض',
    completed: 'مكتمل',
    inProgress: 'قيد التنفيذ',
    resolved: 'تم الحل',
    reOpened: 'مُعاد فتحه',
    overdue: 'تأخرت',
    managers: 'المدراء',
    employees: 'الموظفين',
    vendors_Suppliers: 'الموردين',
    recentTask: 'مهمة حديثة',
    allTask: 'جميع المهام',
    ascending: 'تصاعدي',
    descending: 'تنازلي',
    calendar: 'التقويم',
    fromDate: 'من تاريخ',
    toDate: 'الى تاريخ',
    self: 'ذات',
  };
  
import { LinearProgress, Stack } from "@mui/material";
import { Formik } from "formik";
import React, { memo, useEffect, useMemo, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useDebouncedCallback } from "use-debounce";
import { ReactComponent as List } from "../../assets/images/list.svg";
import { StyledTypography } from "../../common/Utils/StyledComponents";
import { showToast } from "../../common/Utils/ToastMessage";
import {
  useGetCalendarInviteeListMutation,
  useGetInviteeListMutation,
  useSetInviteeSelectMutation,
} from "../../request/Calendar";
import {
  useGetContcatInviteeTreeListMutation,
  useGetShareContactInviteeLiteMutation,
} from "../../request/ContactRepo";
import { useGetShareDocInviteeListMutation } from "../../request/Document/DocumentListing";
import { useGetShareVoiceNotesInviteeListMutation } from "../../request/VoiceNotes";
import { useLazyGetCompanyListQuery } from "../../request/company";
import Translate from "../../translation/utils/translateFunction";
import { FormikDropdownPicker } from "../FormikForm/component/FormikDropDownPicker";
import IconifyIcon from "../Icon";
import NoDataFound from "../ManageTask/NoDataFound";
import MessageListLoader from "../Message/Components/MessageLoader/MessageListLoader";
import { Persona } from "../Persona";
import { VALIDATION_BUCKETURL } from "../Persona/constants";
import Searchbar from "../common/Searchbar";
import { shareModuleType } from "./components/constants";
import { useGetMOMInviteeListMutation } from "../../request/Mom";
import { handleEventKeySubmit } from "../../common/Utils/commonFunction";
import FormikMultiSelectDropDown from "../FormikForm/component/FormikMultiSelectDropDown";
import TextWrapWithTooltip from "../TextWrapWithTooltip";

const ShareWithUser = ({
  closeModal,
  showModal,
  selectedCompanyList = [],
  currentModule,
  onSubmit,
  isSingleSelect,
  showRoleFilter,
  roleOptions,
  showMobile,
}) => {
  const formikRef = useRef(null);
  const defaultBtnRef = useRef(null);

  const { selectedCompanyList: companyList, userData } = useSelector(
    (state) => state.formanagement
  );
  const [checkedAll, setCheckedAll] = useState(false);
  const [checkedIdList, setCheckedIdList] = useState([...selectedCompanyList]);
  const [uncheckedIdList, setUncheckedIdList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [selectedRoles, setSelectedRoles] = useState([]);

  const handleClose = (isOpen = false) => {
    currentModule === shareModuleType.EVENT && onSubmit(checkedIdList);
    const requestObj = {
      moduleInvitee: {
        companyId: selectedCompany,
        users: checkedIdList?.map((item) => item?._id) || [],
      },
      moduleName: "EVENT",
      deselectUsers: uncheckedIdList,
    };
    currentModule === shareModuleType.EVENT && setInviteeSelect(requestObj);
    currentModule !== shareModuleType.EVENT && setCheckedIdList([]);
    setCheckedAll(false);
    !isOpen && closeModal();
  };

  const [companyFilter, setCompanyFilter] = useState([]);
  const [inviteeList, setInviteeList] = useState([]);
  const [currentPageNo, setCurrentPage] = useState(1);
  const [selectedCompany, setSelectedCompany] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isTreeView, setIsTreeView] = useState(false);
  const lastPageNo = useRef(0);
  const initialValue = {
    companyFilter:
      currentModule === shareModuleType.EVENT
        ? []
        : companyFilter && companyFilter[0]?.value,
  };

  const [getCompanyList, { data: companyResponse }] =
    useLazyGetCompanyListQuery();
  const [setInviteeSelect] = useSetInviteeSelectMutation();

  useEffect(() => {
    getCompanyList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (showRoleFilter && roleOptions?.length) {
      // by default first role was selected
      setSelectedRoles([roleOptions[0]?.value]);
    }
  }, [showRoleFilter, roleOptions]);

  useEffect(() => {
    if (companyResponse) {
      let companyData = [];
      companyResponse.map((item) =>
        companyData.push({
          label: item.name,
          value: item._id,
        })
      );

      setCompanyFilter(companyData);
      setSelectedCompany([companyData[0]?.value]);
      formikRef.current.setFieldValue(
        "companyFilter",
        currentModule === shareModuleType.EVENT
          ? [companyData[0]?.value]
          : companyData[0]?.value
      );
    }
  }, [companyResponse]);

  const handleOnSubmit = () => {
    if (checkedIdList.length > 0 || currentModule === shareModuleType?.EVENT) {
      currentModule !== shareModuleType.EVENT && onSubmit(checkedIdList);
      handleClose();
    }
  };

  useEffect(() => {
    if (showModal && checkedIdList?.length > 0) {
      defaultBtnRef.current.focus();
    }
  }, [showModal, checkedIdList]);

  const [
    getShareInviteeListApi,
    {
      data: inviteeListData,
      isLoading: isShareInviteeLoading,
      isSuccess: isSuccessInviteeList,
      isError: isErrorInviteeList,
      error: errorInviteeList,
    },
  ] = useGetShareContactInviteeLiteMutation();
  const [
    getContactTreeInvitee,
    {
      data: getContactTreeInviteeData,
      isLoading: isLoadingContactTreeInvitee,
      isSuccess: isContactTreeSuccess,
    },
  ] = useGetContcatInviteeTreeListMutation();
  const [
    getDocInviteeListApi,
    {
      data: docInviteeList,
      isLoading: isDocInviteeLoading,
      isSuccess: isDocInviteeSuccess,
      isError: isDocInviteeError,
      error: docInviteeError,
    },
  ] = useGetShareDocInviteeListMutation();

  const [
    getCalendarInviteeListApi,
    {
      data: calendarInviteeList,
      isLoading: isCalendarInviteeLoading,
      isSuccess: isCalendarInviteeSuccess,
      isError: isCalendarInviteeError,
      error: calendarInviteeError,
    },
  ] = useGetCalendarInviteeListMutation();

  const [
    getVoiceNotesInviteeListApi,
    {
      data: voicenoteInviteeList,
      isLoading: isloadingVoiceNoteInviteeList,
      isSuccess: isSuccessVoiceNoteInviteeList,
      isError: isErrorVoiceNoteInviteeList,
      error: errorVoiceNoteInviteeList,
    },
  ] = useGetShareVoiceNotesInviteeListMutation();
  const [
    getInvitee,
    {
      data: getInviteeData,
      isLoading: isLoadingGetInvitee,
      isSuccess,
      isError: isEventInviteeError,
      error: errorEventInviteeList,
    },
  ] = useGetInviteeListMutation();

  const [
    getMOMInviteeListApi,
    {
      data: momInviteeList,
      isLoading: isMOMInviteeLoading,
      isSuccess: isMOMInviteeSuccess,
      isError: isMOMInviteeError,
      error: momInviteeError,
    },
  ] = useGetMOMInviteeListMutation();

  const updateInviteeList = (data) => {
    setIsLoading(false);

    if (isTreeView) {
      setInviteeList(data);
      if (checkedAll) {
        setCheckedIdList(
          data?.map((item) => {
            item.users.map((user) => ({ _id: user?._id }));
          })
        );
      }
    } else {
      const { nodes, pageInfo } = data;
      lastPageNo.current = pageInfo.lastPageNo;
      const updatedInviteeList =
        currentPageNo === 1 ? nodes : inviteeList?.concat(nodes);
      setInviteeList(updatedInviteeList);
      if (currentModule === shareModuleType.EVENT && !searchText?.length) {
        updatedInviteeList.map((item) => {
          if (item.isChecked) {
            const ispresent = checkedIdList.find(
              (data) => data?._id === item?._id
            );
            !ispresent && setCheckedIdList([...checkedIdList, item]);
          }
        });
      }

      if (checkedAll) {
        setCheckedIdList(
          updatedInviteeList.map((item) => ({ _id: item?._id }))
        );
      }
    }

    return;
  };

  useEffect(() => {
    if (inviteeListData?.data && isSuccessInviteeList && !isTreeView) {
      updateInviteeList(inviteeListData?.data);
    }
    if (getContactTreeInviteeData?.data && isContactTreeSuccess && isTreeView) {
      updateInviteeList(getContactTreeInviteeData?.data);
    }

    if (isErrorInviteeList && errorInviteeList) {
      setIsLoading(false);
      showToast({
        message:
          errorInviteeList?.message || Translate("common:apiFailedMessage"),
        type: "error",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessInviteeList, isErrorInviteeList, isContactTreeSuccess]);

  useEffect(() => {
    if (docInviteeList && isDocInviteeSuccess) {
      updateInviteeList(docInviteeList?.data);
    }
    if (isDocInviteeError && docInviteeError) {
      setIsLoading(false);
      showToast({
        message:
          docInviteeError?.message || Translate("common:apiFailedMessage"),
        type: "error",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDocInviteeSuccess, isDocInviteeError, docInviteeList, docInviteeError]);

  useEffect(() => {
    if (voicenoteInviteeList && isSuccessVoiceNoteInviteeList) {
      updateInviteeList(voicenoteInviteeList?.data);
    }
    if (isErrorVoiceNoteInviteeList && errorVoiceNoteInviteeList) {
      setIsLoading(false);
      showToast({
        message:
          errorVoiceNoteInviteeList?.message ||
          Translate("common:apiFailedMessage"),
        type: "error",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isSuccessVoiceNoteInviteeList,
    isErrorVoiceNoteInviteeList,
    voicenoteInviteeList,
    errorVoiceNoteInviteeList,
  ]);

  useEffect(() => {
    if (calendarInviteeList && isCalendarInviteeSuccess) {
      updateInviteeList(calendarInviteeList?.data);
    }
    if (isCalendarInviteeError && calendarInviteeError) {
      setIsLoading(false);
      showToast({
        message:
          calendarInviteeError?.message || Translate("common:apiFailedMessage"),
        type: "error",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isCalendarInviteeSuccess,
    isCalendarInviteeError,
    calendarInviteeList,
    calendarInviteeError,
  ]);
  useEffect(() => {
    if (getInviteeData && isSuccess) {
      updateInviteeList(getInviteeData?.data);
    }
    if (isEventInviteeError && errorEventInviteeList) {
      setIsLoading(false);
      showToast({
        message:
          errorEventInviteeList?.message ||
          Translate("common:apiFailedMessage"),
        type: "error",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, isEventInviteeError, getInviteeData, errorEventInviteeList]);

  useEffect(() => {
    if (momInviteeList && isMOMInviteeSuccess) {
      updateInviteeList(momInviteeList?.data);
    }

    if (isMOMInviteeError && momInviteeError) {
      setIsLoading(false);
      showToast({
        message:
          momInviteeError?.message || Translate("common:apiFailedMessage"),
        type: "error",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMOMInviteeSuccess, isMOMInviteeError, momInviteeList, momInviteeError]);

  const requestObj = useMemo(() => {
    if (selectedCompany?.length) {
      let requestObject = {
        pageNo: currentPageNo,
      };

      if (currentModule === shareModuleType?.CALENDAR) {
        requestObject = {
          ...requestObject,
          searchValue: searchText,
          companies: selectedCompany,
          role: selectedRoles,
        };
      } else if (currentModule === shareModuleType?.EVENT) {
        requestObject = {
          ...requestObject,
          companyId: selectedCompany,
          searchText: searchText,
        };
      } else {
        requestObject = {
          ...requestObject,
          companyId: selectedCompany,
          searchText: searchText,
        };
      }

      return requestObject;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, currentPageNo, selectedCompany, selectedRoles, isTreeView]);

  const requestObjDocRepo = useMemo(() => {
    if (selectedCompany?.length) {
      let requestObject = {
        pageNo: currentPageNo,
      };

      requestObject = {
        ...requestObject,
        companyId: selectedCompany,
        searchText: searchText,
      };

      return requestObject;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, currentPageNo, selectedCompany, selectedRoles, isTreeView]);

  const fetchInviteeListByModule = () => {
    switch (currentModule) {
      case shareModuleType?.DOCUMENT_REPO:
        getDocInviteeListApi(requestObjDocRepo);
        break;
      case shareModuleType?.CONTACT_REPO:
        isTreeView
          ? getContactTreeInvitee(requestObj)
          : getShareInviteeListApi(requestObj);
        break;
      case shareModuleType?.CALENDAR:
        getCalendarInviteeListApi(requestObj);
        break;
      case shareModuleType?.MY_VOICE_NOTE:
        getVoiceNotesInviteeListApi(requestObj);
        break;
      case shareModuleType?.EVENT:
        getInvitee(requestObj);
        break;
      case shareModuleType?.MOM:
        getMOMInviteeListApi(requestObj);
        break;
      default:
        break;
    }
  };

  const debouncedSearch = useDebouncedCallback(() => {
    if (selectedCompany.length) {
      fetchInviteeListByModule();
    }
  }, 500);

  useEffect(() => {
    if (selectedCompany?.length) {
      if (requestObj?.searchText?.length || requestObj?.searchValue?.length) {
        debouncedSearch();
      } else {
        fetchInviteeListByModule();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestObj]);

  // *************************************SelectAll Checkbox***********************************
  const selectAll = (value) => {
    setCheckedAll(value);
    const inviteeCheckedArray = [];
    const updatedCheckedIdList = value
      ? isTreeView
        ? inviteeList?.map((item) => {
            item.users.map((user) =>
              inviteeCheckedArray.push(
                currentModule === shareModuleType?.EVENT
                  ? user
                  : { _id: user?._id }
              )
            );
          })
        : inviteeList?.map((item) =>
            currentModule === shareModuleType?.EVENT ? item : { _id: item._id }
          )
      : [];
    setCheckedIdList(isTreeView ? inviteeCheckedArray : updatedCheckedIdList);
  };
  const loadMore = () => {
    setCurrentPage((prev) => prev + 1);
  };
  const handleSearchText = (value) => {
    setCurrentPage(1);
    setSearchText(value);
  };
  const resetData = () => {
    setCurrentPage(1);
    setInviteeList([]);
    setCheckedAll(false);
    setCheckedIdList([...selectedCompanyList]);
  };
  const Checkbox = ({ item, onChangeInput, isChecked }) => {
    const profile = item?.profile || item?.profileUrl;

    return (
      <>
        <div style={{ display: "inline-block" }}>
          <div className="flexCenter" style={{ gap: 20 }}>
            <div style={{ marginTop: 14 }} onClick={() => onChangeInput(item)}>
              <input
                type="checkbox"
                checked={isChecked}
                name={item.name}
                id={item._id}
                onChange={() => {}}
              />
            </div>
            <div className="flexCenter">
              <div style={{ marginTop: 5 }}>
                <Persona
                  name={item.name}
                  image={profile?.includes(VALIDATION_BUCKETURL) ? profile : ""}
                  size={35}
                />
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ display: "flex" }}>
                  <TextWrapWithTooltip
                    textVariant="span"
                    customClassName="shareUserEllipsisTitle text-info ml-2 mt-1"
                    label={item?.name}
                  />
                  <span className="text-info ml-2 mt-1">{"|"}</span>
                  <span className="text-info ml-2 mt-1">{item.role}</span>
                </div>

                {showMobile
                  ? (item?.mobile || item?.email) && (
                      <TextWrapWithTooltip
                        textVariant="span"
                        customClassName="shareUserEllipsisSubTitle text-info ml-2 mt-1"
                        label={`${item?.mobile}, ${item?.email}`}
                      />
                    )
                  : item?.email && (
                      <TextWrapWithTooltip
                        textVariant="span"
                        customClassName="shareUserEllipsisSubTitle text-info ml-2 mt-1"
                        label={item?.email}
                      />
                    )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  // const onChange = (item) => {
  //   if (inviteeList?.length === 1) {
  //     setCheckedAll(true);
  //     selectAll(true);
  //   }

  //   const isCheckedId = checkedIdList.findIndex(
  //     (idItem) => idItem?._id === item?._id
  //   );

  //   if (isSingleSelect) {
  //     if (isCheckedId === -1) {
  //       setCheckedIdList([item]);
  //     } else {
  //       setCheckedIdList([]);
  //     }
  //   } else {
  //     if (isCheckedId === -1) {
  //       setCheckedIdList([...checkedIdList, item]);
  //     } else {
  //       const updatedList = checkedIdList.filter(
  //         (idItem) => idItem?._id !== item?._id
  //       );
  //       setCheckedIdList(updatedList);
  //     }
  //   }

  //   if (inviteeList?.length === 1 && isCheckedId !== -1) {
  //     setCheckedAll(false);
  //     selectAll(false);
  //   }
  // };

  const onChange = (item) => {
    if (inviteeList?.length === checkedIdList?.length && !searchText?.length) {
      setCheckedAll(true);
    }

    const isCheckedId = checkedIdList.findIndex(
      (idItem) => idItem?._id === item?._id
    );

    if (isSingleSelect) {
      if (isCheckedId === -1) {
        setCheckedIdList([item]);
      } else {
        setCheckedIdList([]);
        const isUncheckPresent = uncheckedIdList.find(
          (uncheckedid) => uncheckedid === item?._id
        );
        !isUncheckPresent &&
          setUncheckedIdList([...uncheckedIdList, item?._id]);
        setCheckedAll(false);
      }
    } else {
      if (isCheckedId === -1) {
        setCheckedIdList([...checkedIdList, item]);

        !searchText?.length &&
          setCheckedAll(checkedIdList?.length + 1 === inviteeList.length);
      } else {
        const updatedList = checkedIdList.filter(
          (idItem) => idItem?._id !== item?._id
        );
        const isUncheckPresent = uncheckedIdList.find(
          (uncheckedid) => uncheckedid === item?._id
        );
        !isUncheckPresent &&
          setUncheckedIdList([...uncheckedIdList, item?._id]);
        setCheckedIdList(updatedList);
        !searchText?.length &&
          setCheckedAll(updatedList?.length === inviteeList?.length);
      }
    }

    if (inviteeList?.length === 1 && isCheckedId !== -1) {
      setCheckedAll(false);
      !checkedIdList?.length && selectAll(false);
    }
  };

  const handleRoleCheckboxChange = (value) => {
    setCurrentPage(1);
    if (selectedRoles.includes(value)) {
      if (selectedRoles?.length > 1) {
        setSelectedRoles(selectedRoles.filter((role) => role !== value));
      }
    } else {
      setSelectedRoles([...selectedRoles, value]);
    }
  };
  const onSelectCompany = (value, isMultiselect = false) => {
    const fieldValue = value;
    formikRef.current.setFieldValue("companyFilter", fieldValue);
    currentModule === shareModuleType.EVENT && handleClose(true);
    if (!fieldValue.length) {
      setSelectedCompany(
        isMultiselect ? [companyResponse[0]?._id] : companyResponse[0]?._id
      );
    } else {
      setSelectedCompany(isMultiselect ? [...fieldValue] : [fieldValue]);
    }
    currentModule !== shareModuleType.EVENT && setCheckedIdList([]);
    setCurrentPage(1);
    setCheckedAll(false);
  };
  const RoleFilter = () => {
    return (
      <div>
        <a
          href="/#"
          className="nav-link dropdown-toggle p-0 pl-3"
          style={{ minWidth: "5px" }}
          data-toggle="dropdown"
          role="button"
          aria-haspopup="true"
          aria-expanded="false">
          <i className="fa fa-ellipsis-v manageIconColor" />
        </a>
        <div className="dropdown-menu dropdownAlignment">
          {(!!roleOptions?.length ? roleOptions : userData?.activityLogs)?.map(
            (role, idx) => {
              const name = !!roleOptions?.length
                ? role?.label
                : role?.user?.toLowerCase();
              return (
                <div
                  className="shareFilterItem manageIconColor cursor-pointer dropdown-item"
                  onClick={() =>
                    handleRoleCheckboxChange(
                      !!roleOptions?.length ? role?.value : role?.user
                    )
                  }
                  key={idx}>
                  <span className="shareFilterItemText">
                    {name === "pa"
                      ? Translate("common:personalAssistant")
                      : name === "gm"
                      ? Translate("common:generalManager")
                      : name}
                  </span>
                  <input
                    type="checkbox"
                    name={!!roleOptions?.length ? role?.value : role?.user}
                    id={idx}
                    checked={selectedRoles.includes(
                      !!roleOptions?.length ? role?.value : role?.user
                    )}
                  />
                </div>
              );
            }
          )}
        </div>
      </div>
    );
  };
  const getHeader = (isBtn = false) => {
    switch (currentModule) {
      case shareModuleType?.DOCUMENT_REPO:
        return isBtn
          ? Translate("contacts:share")
          : Translate("document:modalTitle");
      case shareModuleType?.CONTACT_REPO:
        return isBtn
          ? Translate("contacts:share")
          : Translate("contacts:shareContacts");
      case shareModuleType?.CALENDAR:
        return isBtn
          ? Translate("calendarPage:view")
          : Translate("calendarPage:selectMember");
      case shareModuleType?.MY_VOICE_NOTE:
        return isBtn
          ? Translate("VoiceNotes:share")
          : Translate("VoiceNotes:modalTitle");
      case shareModuleType?.EVENT:
        return isBtn
          ? Translate("calendarPage:invite")
          : Translate("calendarPage:inviteMembers");
      case shareModuleType?.MOM:
        return isBtn
          ? Translate("contacts:share")
          : Translate("document:modalTitle");
      default:
        break;
    }
  };
  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      scrollable={true}
      className="col-span-3 col-md-12">
      <Formik innerRef={formikRef} initialValues={initialValue} validateOnMount>
        {({}) => {
          return (
            <>
              <Modal.Header closeButton>
                <Modal.Title
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}>
                  {getHeader()}

                  {currentModule === shareModuleType?.CONTACT_REPO ? (
                    isTreeView ? (
                      <List
                        width={26}
                        height={26}
                        className="cursor-pointer"
                        onClick={() => {
                          setIsTreeView(false);
                          resetData();
                        }}
                      />
                    ) : (
                      <IconifyIcon
                        icon="carbon:flow"
                        className="cursor-pointer"
                        style={{ transform: "rotate(180deg)", marginTop: 4 }}
                        onClick={() => {
                          setIsTreeView(true);
                          resetData();
                        }}
                        width="20"
                      />
                    )
                  ) : null}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body
                style={{
                  minHeight: "calc(100vh - 400px)",
                  maxHeight: "calc(100vh - 400px)",
                  overflow: "auto",
                }}>
                <div className="shareDropDown">
                  <div className="shareDropDownWidthCompany">
                    <Searchbar
                      handleSearchText={handleSearchText}
                      setSearch={setSearchText}
                      isShareModal
                    />
                  </div>
                </div>
                <div className="shareUserFilterBox mt-3">
                  <div className="shareDropDownWidthCompany">
                    {currentModule === shareModuleType.EVENT ? (
                      <FormikMultiSelectDropDown
                        options={companyFilter && companyFilter}
                        name="companyFilter"
                        value={selectedCompany}
                        label={Translate("addManager:selectCompany")}
                        placeholder={Translate("contacts:selectCompany")}
                        onSelectChange={(value) => onSelectCompany(value, true)}
                        required={false}
                        isShowSelectall
                        maxWidth={"359px"}
                      />
                    ) : (
                      <FormikDropdownPicker
                        name="companyFilter"
                        options={companyFilter}
                        value={
                          companyFilter?.length ? companyFilter[0]?.label : ""
                        }
                        placeholder={Translate("contacts:selectCompany")}
                        onSelect={(value) => onSelectCompany(value, false)}
                        showCloseIcon={false}
                      />
                    )}
                  </div>
                  {showRoleFilter && RoleFilter()}
                </div>

                {currentModule !== shareModuleType.EVENT &&
                  !isSingleSelect &&
                  inviteeList?.length > 0 &&
                  !searchText.length && (
                    <div
                      className="shareModalSelect"
                      onClick={() => selectAll(!checkedAll)}>
                      <input
                        type="checkbox"
                        onChange={() => selectAll(!checkedAll)}
                        checked={checkedAll}
                        style={{ marginRight: "0.5rem" }}
                      />

                      <label onClick={() => selectAll(!checkedAll)}>
                        {Translate("contacts:selectAll")}
                      </label>
                    </div>
                  )}
                <div className="table-responsive todo_list over-flow-md scrollBox">
                  <table className="table table-hover table-striped table-vcenter mb-0">
                    <tbody>
                      {(isLoading ||
                        isDocInviteeLoading ||
                        isCalendarInviteeLoading ||
                        isShareInviteeLoading ||
                        isLoadingContactTreeInvitee ||
                        isMOMInviteeLoading) &&
                      currentPageNo === 1 ? (
                        <LinearProgress style={{ height: 2 }} />
                      ) : inviteeList?.length > 0 ? (
                        inviteeList?.map((item, index) => {
                          const isAvailable =
                            checkedIdList.length > 0 &&
                            checkedIdList.find(
                              (idItem) => idItem?._id === item?._id
                            );

                          const isChecked = isAvailable || false;
                          return isTreeView ? (
                            <Stack>
                              {(index === 0 ||
                                inviteeList[index - 1].companyId !==
                                  item.companyId) && (
                                <StyledTypography
                                  variant={"h6"}
                                  style={{ marginBottom: 8, marginLeft: 10 }}>
                                  {item?.companyName}
                                </StyledTypography>
                              )}
                              <StyledTypography
                                variant={"subtitle1"}
                                style={{ marginLeft: 10 }}>
                                {item?.role}
                              </StyledTypography>
                              {item?.users?.map((user) => {
                                const isAvailableTree =
                                  checkedIdList.length > 0 &&
                                  checkedIdList.find(
                                    (idItem) => idItem?._id === user?._id
                                  );
                                const isCheckedTree = isAvailableTree || false;
                                return user._id !== userData?._id ? (
                                  <tr key={`${index}_${user?._id}`}>
                                    <td key={index} className="tdStyling">
                                      <Checkbox
                                        item={user}
                                        onChangeInput={onChange}
                                        isChecked={isCheckedTree}
                                      />
                                    </td>
                                  </tr>
                                ) : null;
                              })}
                            </Stack>
                          ) : (
                            <tr key={`${index}_${item?._id}`}>
                              <td key={index} className="tdStyling">
                                <Checkbox
                                  item={item}
                                  onChangeInput={onChange}
                                  isChecked={isChecked}
                                />
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <div className="NoDataDisplayStyling mt-4">
                          <tr>
                            <td className="displayNodata tdStyling">
                              <span>
                                <NoDataFound
                                  message={Translate(
                                    "contacts:noContactsFound"
                                  )}
                                />
                              </span>
                            </td>
                          </tr>
                        </div>
                      )}
                    </tbody>
                  </table>
                </div>
              </Modal.Body>
              <Stack minHeight={70} maxHeight={70}>
                {currentPageNo < lastPageNo?.current && !isTreeView && (
                  <MessageListLoader
                    isLoading={
                      isShareInviteeLoading ||
                      isLoadingContactTreeInvitee ||
                      isDocInviteeLoading ||
                      isCalendarInviteeLoading ||
                      isloadingVoiceNoteInviteeList ||
                      isLoadingGetInvitee
                    }
                    onLoadMoreClick={loadMore}
                    message=" "
                    isShareModal
                  />
                )}
              </Stack>
              <Modal.Footer>
                <Button
                  variant={`primary saveButton`}
                  disabled={
                    currentModule === shareModuleType?.EVENT
                      ? false
                      : !checkedIdList.length
                  }
                  onClick={handleOnSubmit}
                  ref={defaultBtnRef}
                  onKeyDown={(e) => handleEventKeySubmit(e, handleOnSubmit)}>
                  {getHeader(true)}
                </Button>
              </Modal.Footer>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default memo(ShareWithUser);

import React, { useEffect, useState } from "react";

import WaveFormItem from "./WaveForm";
import { convertTo12Hr } from "../../../common/Utils/DateFormater";

const VoiceNotes = ({
  voiceObj,
  className,
  isShared,
  isVoiceNotesList,
  onVoiceNotePlay,
  params,
  currentVoiceNote,
}) => {
  let voiceNoteDate = convertTo12Hr(voiceObj?.createdAt);

  const [songList, setSongList] = useState([]);
  useEffect(() => {
    voiceObj && songList.push(voiceObj);
    setSongList([...songList]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className={className ? className : "container_spacing"}>
        {songList.map((song, index) => (
          <div key={index}>
            <WaveFormItem
              url={song}
              voiceNoteDate={voiceNoteDate}
              isShared={isShared}
              isVoiceNotesList={isVoiceNotesList}
              onVoiceNotePlay={onVoiceNotePlay}
              params={params}
              currentVoiceNote={currentVoiceNote}
            />
          </div>
        ))}
      </div>
    </>
  );
};

export default VoiceNotes;

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AccordianShimmer from "../../../../common/Shimmer/AccordionShimmer";
import { colors } from "../../../../common/Theme/Colors";
import {
  StyledCard,
  StyledTypography,
} from "../../../../common/Utils/StyledComponents";
import { useGetContcatInviteeTreeListMutation } from "../../../../request/ContactRepo";
import Translate from "../../../../translation/utils/translateFunction";
import ListView from "../../../ListView";
import { Persona } from "../../../Persona";
import { VALIDATION_BUCKETURL } from "../../../Persona/constants";
import PageHeader from "../../../common/pageHeader";
import { getBusinessCardListColumns } from "./column.data";
import Searchbar from "../../../common/Searchbar";
import { useDebouncedCallback } from "use-debounce";
import NoDataFound from "../../../ManageTask/NoDataFound";
import { showToast } from "../../../../common/Utils/ToastMessage";
import { CustomUserTitleCellRender } from "../../../common/CustomCellRender";

const BusinessCardList = ({ fixNavbar }) => {
  const { selectedCompanyList, isRtl } = useSelector(
    (state) => state.formanagement
  );
  const navigate = useNavigate();
  const [businessCardList, setBusinessCardList] = useState([]);
  const [searchText, setSearchText] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const [
    getContactTreeInvitee,
    {
      data: getContactTreeInviteeData,
      isLoading: isLoadingContactTreeInvitee,
      isSuccess: isContactTreeSuccess,
      isError,
      error,
    },
  ] = useGetContcatInviteeTreeListMutation();

  const getBodyObj = useMemo(() => {
    setBusinessCardList([]);

    const companyIds = selectedCompanyList?.length
      ? selectedCompanyList?.map((item) => item?.value)
      : [];

    const payload = {
      companyId: companyIds,
      searchText,
      pageNo: 1,
    };

    return companyIds?.length ? payload : null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, selectedCompanyList]);

  useEffect(() => {
    if (getBodyObj) {
      setIsLoading(true);
      if (getBodyObj?.searchText?.length) {
        debouncedSearch();
      } else {
        getContactTreeInvitee(getBodyObj);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getBodyObj]);

  const debouncedSearch = useDebouncedCallback(() => {
    getContactTreeInvitee(getBodyObj);
  }, 500);

  useEffect(() => {
    if (isContactTreeSuccess && getContactTreeInviteeData?.data) {
      const businessCardArray = [];
      getContactTreeInviteeData.data.map((item) => {
        const isPresent = businessCardArray?.find(
          (company) => company?.companyId === item?.companyId
        );
        if (isPresent) {
          isPresent.usersList.push(item?.users);
        } else {
          const obj = {
            companyName: item?.companyName,
            companyId: item?.companyId,
            usersList: [item?.users],
          };
          businessCardArray.push(obj);
        }
      });
      setBusinessCardList(businessCardArray);
      setIsLoading(false);
    }

    if (isError && error) {
      setIsLoading(false);
      showToast({
        type: "error",
        message: error?.message || Translate("common:unknownServerError"),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isContactTreeSuccess, isError, error]);

  const customActionRender = (params) => {
    return params?.row?.contactId ? (
      <div>
        <i
          className="m-2 fa fa-eye cursor-pointer manageIconColor"
          onClick={() =>
            navigate("/contactRepository/publicListing/viewBusinessCard", {
              state: {
                contactId: params?.row?.contactId,
                companyName: params?.row?.companyName,
                isBusinessCard: true,
              },
            })
          }
        ></i>
      </div>
    ) : (
      <StyledTypography variant="subtitle1">N/A</StyledTypography>
    );
  };

  const CustomSrCellRender = (params) => {
    return (
      <StyledTypography component="span" sx={{ color: colors.primary_009 }}>
        {params?.api?.getRowIndexRelativeToVisibleRows(params?.row?._id) + 1}
      </StyledTypography>
    );
  };

  const CustomCellRender = (params) => {
    return (
      <td>
        <div style={{ display: "flex" }}>
          <Persona
            name={params?.row?.name}
            image={
              params?.row?.profileUrl?.includes(VALIDATION_BUCKETURL)
                ? params?.row?.profileUrl
                : ""
            }
            size={28}
          />
          <span className="text-info ml-2 mt-1">{params?.row?.name}</span>
        </div>
      </td>
    );
  };
  const handleSearchText = (value) => {
    setSearchText(value);
  };
  return (
    <Grid
      item
      xs={12}
      className={`section-body ${isRtl ? "rtlMargin" : "ltrMargin"} ${
        fixNavbar ? "marginTop" : ""
      } `}
    >
      <StyledCard variant="outlined">
        <div className="container-fluid p-4">
          <div className="card">
            <div
              className="px-2"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <PageHeader
                title={
                  <h5 className="page-title">
                    {Translate("contacts:businessCard")}
                  </h5>
                }
                onClick={() => {
                  navigate("/contactRepository/publicListing");
                }}
                containerClass="card-header card-header-spacing"
              />
              <div className="card-options">
                {/* <div className="input-group">
                  <input
                    type="text"
                    autoComplete="off"
                    className="form-control form-control-sm searchBarWidth"
                    placeholder="Search something..."
                    name="search"
                    onChange={(e) => {
                      setTimeout(() => {
                        setSearchText(e.target.value);
                      }, 500);
                    }}
                  />
                </div> */}
                {/* NOTE:There is an issue when invalid data is searched in the text box  */}
                <div className="input-group">
                  <Searchbar
                    handleSearchText={handleSearchText}
                    setSearch={setSearchText}
                  />
                </div>
              </div>
            </div>

            {!!businessCardList.length &&
            !isLoadingContactTreeInvitee &&
            !isLoading ? (
              businessCardList?.map((item, idx) => {
                return (
                  <Accordion
                    style={{ marginBottom: 10, marginTop: 10 }}
                    defaultExpanded={idx === 0}
                  >
                    <AccordionSummary
                      id="first"
                      aria-controls="panel1"
                      expandIcon={
                        <i
                          className="fa fa-caret-up"
                          style={{ color: "black" }}
                        />
                      }
                    >
                      <StyledTypography
                        variant="subtitle1"
                        sx={{ fontWeight: "500" }}
                      >
                        {item?.companyName}
                      </StyledTypography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {item?.usersList?.map((user) => {
                        return (
                          <StyledCard variant="outlined">
                            <ListView
                              columns={getBusinessCardListColumns(
                                CustomUserTitleCellRender,
                                customActionRender,
                                CustomSrCellRender
                              )}
                              rows={user}
                              // autoHeight
                              sortingMode="server"
                              hidePagination
                              isExpandedTable
                              rowsPerPage={user.length}
                            />
                          </StyledCard>
                        );
                      })}
                    </AccordionDetails>
                  </Accordion>
                );
              })
            ) : isLoading ? (
              <AccordianShimmer />
            ) : (
              <NoDataFound isCenter />
            )}
          </div>
        </div>
      </StyledCard>
    </Grid>
  );
};
export default BusinessCardList;

export default {
  head: "الرسائل",
  people: "الدردشات",
  groups: "المجموعات",
  contacts: "جهات الإتصال",
  createNewGroup: "أنشئ مجموعة جديدة",
  addToTask: "أضف مهمة",
  addToSubTask: "أضف مهمة فرعية",
  noChatFound: "لم يتم العثور على دردشة",
  readMore: "اقرأ أكثر",
  readLess: "أقرأ أقل",
  deleteAlertTitle: "هل أنت متأكد أنك تريد حذف هذه الرسالة؟",
  at: "في ",
  contactInfo: "معلومات الإتصال",
  phone: "الموبايل",
  groupsCommon: "المجموعات المشتركة",
  initialScreenSubtitle: "ابحث عن شخص ما لبدء الدردشة",
  initialScreenBtnTitl: "ابدأ محادثة العمل",
  members: "الأعضاء",
  deleteMessage: "تم حذف الرسالة",
  relatedTask: "مهام ذات صلة",
  editGroupName: "تحرير اسم المجموعة",
  requiredValidation: "اسم المجموعة مطلوب",
  startConversationMesage: "يمكنك أن تبدأ محادثتك." 
};

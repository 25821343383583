import {
  jordanCountryCode,
  jordanCountryShortCode,
} from "../../common/Utils/ApiConstants";

export const InitialValues = {
  name: "",
  website: "",
  contact: "",
  countryCode: jordanCountryCode,
  officeTelephone: "",
  address: "",
  landmark: "",
  country: "",
  state: "",
  city: "",
  zipcode: "",
  lat: "",
  lng: "",
  mobileShortCode: jordanCountryShortCode,
};

export const companyUrl = [
  "www.flipkart.com",
  "www.google.com",
  "www.amazon.in",
  "www.Myntra.com",
];

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Grid,
  LinearProgress,
  Stack,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import AccordianShimmer from "../../../../common/Shimmer/AccordionShimmer";
import { colors } from "../../../../common/Theme/Colors";
import {
  DateTimeFormats,
  formatDateYYYYMMDD,
  formatFullMonthYear,
} from "../../../../common/Utils/DateFormater";
import {
  StyledCard,
  StyledTypography,
} from "../../../../common/Utils/StyledComponents";
import { showToast } from "../../../../common/Utils/ToastMessage";
import {
  useAddLeaveMutation,
  useExportAttendanceDetailsReportMutation,
  useGetAttendanceDetailMutation,
} from "../../../../request/Report";
import Translate from "../../../../translation/utils/translateFunction";
import AlertPermission from "../../../AlertPermission";
import IconifyIcon from "../../../Icon";
import ListView from "../../../ListView";
import NoDataFound from "../../../ManageTask/NoDataFound";
import { getAttendanceReportDetailColumns } from "./components/column.data";
import { CSVLink } from "react-csv";
import { getMoment } from "../../../../common/Utils/commonFunction";

const AttendanceReportDeatil = ({ fixNavbar }) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { userData, isRtl } = useSelector((state) => state.formanagement);
  const [attendanceList, setAttendanceList] = useState([]);
  const [navigationFlag, setNavigationFlag] = useState();
  const [selectedDate, setSelectedDate] = useState(
    moment(state?.requestData.date)
  );
  const [show, setShow] = useState(false);
  const [selectedColumnDate, setSelectedColumnDate] = useState();
  const [isReportLoading, setIsReportLoading] = useState(false);
  const showModal = () => setShow(true);
  const hideModal = () => setShow(false);
  const [csvData, setCSVData] = useState([]);
  const csvLink = useRef();
  const isVisible = userData?._id === state?.data._id;
  const [
    applyLeave,
    { data: leaveData, isSuccess: isSuccessLeave, isLoading: isLoadingLeave },
  ] = useAddLeaveMutation();

  const [getAttendanceDetail, { data: detailData, isSuccess, isLoading }] =
    useGetAttendanceDetailMutation();
  const requestObj = useMemo(() => {
    return {
      companyId: state?.requestData.companyId,
      userId: state?.data._id,
      year: moment(selectedDate).format(DateTimeFormats.year),
      month: moment(selectedDate).format(DateTimeFormats.longMonth),
    };
  }, [state, selectedDate]);

  useEffect(() => {
    if (requestObj) {
      setIsReportLoading(true);
      getAttendanceDetail(requestObj);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestObj]);

  const [
    exportAttendanceReportDetailsApi,
    {
      data: exportAttendanceReportDetails,
      isLoading: isExportLoading,
      error: exportError,
    },
  ] = useExportAttendanceDetailsReportMutation();
  const parseCSV = (csvData) => {
    const rows =
      csvData &&
      csvData
        .split(/\r?\n(?=(?:(?:[^"]*"){2})*[^"]*$)/)
        .map((row) =>
          row
            .split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/)
            .map((cell) => cell.replace(/^"|"$/g, ""))
        );

    return rows;
  };
  useEffect(() => {
    if (exportAttendanceReportDetails?.length > 0) {
      const rowArray = parseCSV(exportAttendanceReportDetails);
      if (rowArray) {
        setCSVData(rowArray);
      }
    }

    if (exportError) {
      const errorResponse = JSON.parse(exportError?.data);

      showToast({
        message:
          JSON.stringify(errorResponse?.message) ||
          Translate("common:unknownServerError"),
        type: "error",
      });
    }
  }, [exportAttendanceReportDetails, exportError]);

  useEffect(() => {
    if (csvData?.length > 0) {
      // eslint-disable-next-line no-unused-expressions
      csvLink?.current?.link?.click();
    }
  }, [csvData]);

  const handleExportTask = () => {
    if (requestObj) exportAttendanceReportDetailsApi(requestObj);
  };

  useEffect(() => {
    if (detailData?.data && isSuccess) {
      const { reportData, navigationFlags } = detailData?.data;
      setAttendanceList(reportData);
      setNavigationFlag(navigationFlags);
      setIsReportLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);
  useEffect(() => {
    if (leaveData?.message && isSuccessLeave === true) {
      showToast({
        message: leaveData?.message,
        type: leaveData?.data ? "success" : "info",
      });
    }
  }, [isSuccessLeave]);
  const onOkClick = () => {
    const convertFormat = new Date(selectedColumnDate);
    const bodyObj = {
      companyId: state?.requestData?.companyId[0],
      date: convertFormat.toISOString(),
      reason: "",
      type: "",
    };
    applyLeave(bodyObj);
    hideModal();
    setSelectedColumnDate();
  };
  const customActionCellRender = (params) => {
    return (
      params?.row?.isAbsent && (
        <td>
          <i
            className="m-2 fa fa-pencil cursor-pointer icon-color"
            data-toggle="tooltip"
            title={Translate("reports:changeStatus")}
            onClick={() => {
              setSelectedColumnDate(params?.row?.date);
              showModal();
            }}
          />
        </td>
      )
    );
  };
  return (
    <Grid
      item
      xs={12}
      className={`section-body ${isRtl ? "rtlMargin" : "ltrMargin"} ${
        fixNavbar ? "marginTop" : ""
      } `}>
      <StyledCard variant="outlined" className="mb-3">
        <Stack
          className="card-header fontSize-22"
          flexDirection={"row"}
          justifyContent={"space-between"}>
          <Stack flexDirection={"row"} alignItems={"center"}>
            <i
              className="icon fe fe-arrow-left mr-3 icon-size backnavigation-iconColor cursor-pointer cursor-pointer"
              onClick={() => navigate(-1)}
              title={Translate("common:backToolTip")}
            />
            <h5 className="page-title">
              {Translate("reports:attendanceViewTitle")}
            </h5>
          </Stack>
          <Stack flexDirection={"row"} alignItems={"center"}>
            <div className="header-action pull-right mr-3">
              <button
                type="button"
                className="btn btn-primary saveButton py-2"
                onClick={handleExportTask}
                disabled={!attendanceList?.length}>
                {isExportLoading ? (
                  <div className="align-self-center">
                    <CircularProgress size={22} color="inherit" />
                  </div>
                ) : (
                  <>
                    <i className="fe fe-download" />
                    <StyledTypography className="mx-1" component="span">
                      {Translate("common:exportLabel")}
                    </StyledTypography>
                  </>
                )}
              </button>

              {csvData?.length > 0 && (
                <CSVLink
                  data={csvData}
                  ref={csvLink}
                  filename={`${Translate(
                    "taskReport:exportAttendanceReportDetails"
                  )}_${formatDateYYYYMMDD(getMoment().toDate())}.csv`}
                />
              )}
            </div>

            {navigationFlag?.previous && (
              <>
                <IconifyIcon
                  className="cursor-pointer"
                  icon={"ant-design:left-circle-outlined"}
                  fontSize="24"
                  onClick={() =>
                    setSelectedDate(
                      moment(selectedDate).subtract("month", 1).format()
                    )
                  }
                />
                <StyledTypography
                  variant="subtitle1"
                  pl={2}
                  sx={{ fontWeight: "500", opacity: 0.5 }}
                  color={colors.primary}>
                  {moment(selectedDate)
                    .subtract("month", 1)
                    .format(DateTimeFormats.shortMonth)}
                </StyledTypography>
              </>
            )}

            <StyledTypography
              variant="h6"
              px={2}
              sx={{ fontWeight: "600" }}
              color={colors.primary}>
              {formatFullMonthYear(selectedDate)}
            </StyledTypography>

            {navigationFlag?.next && (
              <>
                <StyledTypography
                  variant="subtitle1"
                  pr={2}
                  sx={{ fontWeight: "500", opacity: 0.5 }}
                  color={colors.primary}>
                  {moment(selectedDate)
                    .add("month", 1)
                    .format(DateTimeFormats.shortMonth)}
                </StyledTypography>
                <IconifyIcon
                  className="cursor-pointer"
                  icon={"ant-design:right-circle-outlined"}
                  fontSize="24"
                  onClick={() =>
                    setSelectedDate(
                      moment(selectedDate).add("month", 1).format()
                    )
                  }
                />
              </>
            )}
          </Stack>
        </Stack>
        {isLoadingLeave && (
          <Box sx={{ width: "100%", padding: "10px" }}>
            <LinearProgress style={{ height: 2 }} />
          </Box>
        )}
      </StyledCard>
      {isLoading || isReportLoading ? (
        <AccordianShimmer />
      ) : attendanceList?.length ? (
        attendanceList?.map((week, idx) => {
          const { range, weekData } = week;

          return (
            <Accordion
              style={{ marginBottom: 10, marginTop: 10 }}
              defaultExpanded={(idx === 0 && weekData?.length) || idx === 1}
              disabled={!weekData?.length && idx === 0}>
              <AccordionSummary
                id="first"
                aria-controls="panel1"
                expandIcon={
                  <i className="fa fa-caret-up" style={{ color: "black" }} />
                }>
                <StyledTypography
                  variant="subtitle1"
                  sx={{ fontWeight: "500" }}>
                  {range}
                </StyledTypography>
              </AccordionSummary>
              <AccordionDetails>
                <ListView
                  columns={getAttendanceReportDetailColumns(
                    customActionCellRender,
                    isVisible
                  )}
                  rows={weekData}
                  autoHeight
                  sortingMode="server"
                  hidePagination
                  isExpandedTable
                />
              </AccordionDetails>
            </Accordion>
          );
        })
      ) : (
        <StyledCard variant="outlined">
          <NoDataFound isCenter className="NoComment fontSize-24" />
        </StyledCard>
      )}
      <AlertPermission
        title={"Attendance status"}
        onOk={onOkClick}
        onCanel={hideModal}
        show={show}
        subTitle="Are you sure, you want change attendance status Absent to On leave?"
      />
    </Grid>
  );
};
export default AttendanceReportDeatil;

import { Stack } from "@mui/material";
import React from "react";
import { StyledTypography } from "../../../common/Utils/StyledComponents";
import { colors } from "../../../common/Theme/Colors";

const ShowMore = ({ data, onShowMorePress }) => {
  return (
    <Stack style={{ marginTop: 30, marginBottom: 30 }}>
      {data.map((element, index) => {
        return (
          <Stack key={index} className="cursor-pointer">
            <div
              onClick={() => {
                onShowMorePress(element?.moreEvents);
              }}
              style={{
                position: "absolute",
                width: 20,
                marginLeft: 2,
                top: element?.top * 1.1,
              }}>
              <StyledTypography
                variant="subtitle2"
                sx={{
                  fontWeight: "600",
                  fontSize: "1rem",
                  color: colors.primary,
                }}>
                +{element?.moreEvents?.length}
              </StyledTypography>
            </div>
          </Stack>
        );
      })}
    </Stack>
  );
};

export default ShowMore;

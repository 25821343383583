import {
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useField } from "formik";
import React, { memo, useEffect, useState } from "react";
import { colors } from "../../../common/Theme/Colors";
import {
  FontFamily,
  StyledTypography,
  ErroLabelStyle,
} from "../../../common/Utils/StyledComponents";
import IconifyIcon from "../../Icon";
import Translate from "../../../translation/utils/translateFunction";

const FormikMultiSelectDropDown = ({
  name,
  label,
  required,
  disabled,
  options,
  onSelect,
  placeholder,
  value,
  maxWidth,
  isShowSelectall = false,
  // Added one prop to get the value of all selected companies
  onSelectChange,
  autoFocus,
}) => {
  const [field, meta, helpers] = useField(name);
  const { error, touched } = { ...meta };
  const { value: fieldValue = value } = field;
  const [isSelectAll, setIsSelectAll] = useState(false);
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    label !== value &&
      helpers.setValue(value === "string" ? value.split(",") : value);
    onSelectChange && onSelectChange(value);
  };

  const getLabelByValue = options.reduce(
    (acc, option) => ({ ...acc, [option?.value]: option?.label }),
    {}
  );
  useEffect(() => {
    if (value?.length) {
      value?.length === options?.length
        ? setIsSelectAll(true)
        : setIsSelectAll(false);
      helpers.setValue(value);
    }
  }, [value]);
  const onSelectAllClick = () => {
    onSelectChange &&
      onSelectChange(isSelectAll ? [] : options.map((item) => item.value));
    setIsSelectAll(!isSelectAll);
  };
  return (
    <FormControl fullWidth variant="outlined">
      <StyledTypography className="labelStyling" component="span">
        {label}
        {required && (
          <StyledTypography sx={{ color: "red" }} component="span">
            {" *"}
          </StyledTypography>
        )}
      </StyledTypography>
      <Select
        id="demo-multiple-name"
        multiple
        value={fieldValue}
        onChange={handleChange}
        size="small"
        error={touched && !!error}
        disabled={disabled}
        style={{
          background: disabled ? "#e5e5e58f" : "",
          color: !fieldValue && colors.grey_003,
        }}
        sx={{ fontFamily: FontFamily }}
        autoFocus={autoFocus}
        endAdornment={
          !disabled &&
          !required &&
          fieldValue?.length > 0 && (
            <IconButton
              size="small"
              sx={{ marginRight: 1 }}
              onClick={() => {
                onSelectChange && onSelectChange([]);
                onSelect && onSelect([]);
                helpers.setValue([]);
              }}
            >
              <IconifyIcon icon="ion:close-outline" fontSize={25} />
            </IconButton>
          )
        }
        displayEmpty
        renderValue={
          fieldValue && fieldValue?.length
            ? (selected) => (
                <Tooltip
                  title={selected
                    .map((value) => getLabelByValue[value])
                    .join(", ")}
                >
                  <Typography
                    noWrap
                    sx={{ fontFamily: "inherit", maxWidth: maxWidth }}
                  >
                    {selected
                      .slice(0, 4)
                      .map((value) => getLabelByValue[value])
                      .join(", ")}
                    {selected.length > 4 && "..."}
                  </Typography>
                </Tooltip>
              )
            : () => (
                <Typography
                  noWrap
                  sx={{
                    fontFamily: "inherit",
                    maxWidth: maxWidth,
                    color: "#868686a6",
                  }}
                >
                  {placeholder || label}
                </Typography>
              )
        }
      >
        {isShowSelectall && (
          <Stack
            flexDirection={"row"}
            alignItems={"center"}
            className="cursor-pointer"
            style={{
              paddingLeft: 10,
              paddingRight: 10,
              width: "fit-content",
            }}
          >
            <input
              className="cursor-pointer"
              type="checkbox"
              checked={isSelectAll}
              onChange={onSelectAllClick}
            />
            <StyledTypography
              variant="subtitle1"
              mx={1}
              onClick={onSelectAllClick}
            >
              {Translate("common:selectAll")}
            </StyledTypography>
          </Stack>
        )}
        {options?.map(({ value, label }) => (
          <MenuItem
            key={value}
            value={value}
            onClick={() => onSelect?.(value, label)}
            style={{ paddingLeft: 10, paddingRight: 10 }}
          >
            {label}
          </MenuItem>
        ))}
      </Select>

      {touched && error && (
        <StyledTypography sx={ErroLabelStyle} component="span">
          {error}
        </StyledTypography>
      )}
    </FormControl>
  );
};

export default memo(FormikMultiSelectDropDown);

import { io } from "socket.io-client";
// import {LoginData} from 'request/Authentication';
let socket;
const SOCKETURL = process.env.REACT_APP_SOCKET_URL;
export const startSocket = () => {
  if (!socket) {
    socket = io(SOCKETURL, {
      reconnectionDelay: 15000,
      reconnectionDelayMax: 15000,
      transports: ["polling"],
    });
  }
};

export const initiateSocket = (room) => {
  if (!socket) {
    startSocket();
  }
  socket.emit("connection", room);
};

export const subscribeToChannel = (cb, channelId) => {
  if (!socket) {
    startSocket();
  }
  socket.emit("join", { channelId });

  socket.on("receiveMessage", function (data) {
    cb(data);
  });
};

export const emitSeen = (channelId, userId, type) => {
  if (!socket) {
    startSocket();
  }
  socket.emit("seen", { channelId, userId, type });
};

export const unSubscribeToChannel = (channelId) => {
  socket.emit("unsubscribe", { channelId });
  socket.off("join");
  socket.off("receiveMessage");
  socket.off("seen");
};

//Disconnect socket
export const disconnectSocket = () => {
  if (socket) {
    socket.disconnect();
    socket = null;
  }
};

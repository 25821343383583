import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { showToast } from "../../../common/Utils/ToastMessage";
import { useGetSubtaskListMutation } from "../../../request/Task";
import Translate from "../../../translation/utils/translateFunction";
import { Grid, Stack } from "@mui/material";
import {
  StyledCard,
  StyledTypography,
} from "../../../common/Utils/StyledComponents";
import { colors } from "../../../common/Theme/Colors";
import ListView from "../../ListView";
import { getSubtaskColumns } from "./Components/column.data";
import { useSelector } from "react-redux";

const SubtaskListing = ({ fixNavbar }) => {
  const { isRtl } = useSelector((state) => state.formanagement);
  const { state } = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [lastPage, setLastPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [subtasks, setSubtasks] = useState([]);

  const [
    fetchSubtaskList,
    {
      data: subtaskList,
      isLoading: isSubtaskLoading,
      isError: isSubtaskError,
      error: subtaskError,
    },
  ] = useGetSubtaskListMutation();

  useEffect(() => {
    if (state) {
      fetchSubtaskList({
        taskId: state?.taskId,
        pageNo: currentPage,
      });
    }
  }, [state, currentPage]);

  useEffect(() => {
    if (subtaskList?.data?.nodes) {
      const { nodes, pageInfo } = subtaskList.data;
      setSubtasks(nodes);
      setLastPage(pageInfo.lastPageNo);
      setIsLoading(false);
    }

    if (subtaskError && isSubtaskError) {
      showToast({ message: JSON.stringify(subtaskError.data), type: "error" });
      setIsLoading(false);
    }
  }, [subtaskList]);

  const CustomSrCellRender = (params) => {
    return (
      <StyledTypography component="span" sx={{ color: colors.primary_009 }}>
        {params?.api?.getRowIndexRelativeToVisibleRows(params?.row?._id) +
          1 +
          (currentPage - 1) * 10}
      </StyledTypography>
    );
  };

  const CustomActionCellRender = (params) => {
    return (
      <td data-toggle="tooltip" title={Translate("common:viewToolTip")}>
        <i
          className="manageIconColor ml-3 fa fa-eye cursor-pointer"
          onClick={() =>
            navigate("/subtask/view", {
              state: {
                isSubtaskList: true,
                navigateFrom:
                  state?.parentState?.navigateFrom ||
                  state?.parentNavigation ||
                  "",
                ...state,
                taskId: params?.row?._id,
              },
            })
          }
        ></i>
      </td>
    );
  };

  const handleBackNavigation = () => {
    // from task details
    if (state?.isTaskDetails) {
      return navigate(-1);
    }

    return navigate("/taskDetails", {
      state: {
        navigateFrom:
          state?.parentState?.navigateFrom || state?.navigateFrom || "",
        ...state,
        taskId: subtasks?.length ? subtasks[0]?.parentTask?.id : state?.taskId,
      },
    });
  };

  return (
    <Grid
      item
      xs={12}
      className={`section-body ${isRtl ? "rtlMargin" : "ltrMargin"} ${
        fixNavbar ? "marginTop" : ""
      } `}
    >
      <StyledCard variant="outlined">
        <div className="container-fluid p-4">
          <div className="tab-content">
            <div>
              <div className="card">
                <Stack
                  flexDirection={"row"}
                  alignItems={"center"}
                  marginBottom={5}
                >
                  <i
                    className="icon fe fe-arrow-left mr-3 icon-size backnavigation-iconColor cursor-pointer"
                    onClick={handleBackNavigation}
                    title={Translate("common:backToolTip")}
                  />
                  <h5 className="page-title">
                    {Translate("taskDetails:viewLinkedSubtaskHeading")}
                  </h5>
                </Stack>

                <ListView
                  columns={getSubtaskColumns(
                    CustomSrCellRender,
                    CustomActionCellRender
                  )}
                  rows={subtasks}
                  autoHeight
                  onPaginationChange={setCurrentPage}
                  currentPage={currentPage}
                  showShimmer={isSubtaskLoading}
                  showLoader={isLoading}
                  totalPageCount={lastPage}
                />
              </div>
            </div>
          </div>
        </div>
      </StyledCard>
    </Grid>
  );
};

export default SubtaskListing;

import React from "react";
import { styles } from "../TextField/TextField/index.Style";
import { Typography } from "@mui/material";
import { StyledTypography } from "../../common/Utils/StyledComponents";

const RadioButtons = ({
  radioOptions,
  onPress,
  label,
  isCheckBox,
  defaultCheck = undefined,
  defaultChecked = isCheckBox ? undefined : 0,
  disabled = false,
  name,
  required = false,
  icons,
  isObject = false,
}) => {
  return (
    <>
      {label !== undefined && label?.length > 0 && (
        <StyledTypography
          sx={{ ...styles.label, fontWeight: 500 }}
          component="span">
          {label}
          {required && (
            <StyledTypography sx={{ color: "red" }} component="span">
              {" *"}
            </StyledTypography>
          )}
        </StyledTypography>
      )}
      {/* {(
        <div>
          <span style={styles.label}>{label}</span>
        </div>
      )} */}

      <div>
        {radioOptions.map((radioItems, index) => {
          const radioValue = isObject ? radioItems?.value : radioItems;
          return (
            <label
              className={
                isCheckBox
                  ? "custom-control custom-checkbox"
                  : "custom-control custom-radio"
              }
              key={`${radioValue}_${index}`}
              style={{ cursor: disabled ? "default" : "cursor" }}>
              {isCheckBox ? (
                <input
                  type="checkbox"
                  className="custom-control-input"
                  name={name || "example-checkbox1"}
                  defaultValue={radioValue}
                  checked={
                    defaultCheck !== undefined
                      ? defaultCheck
                      : defaultChecked === index
                  }
                  onClick={onPress}
                  disabled={disabled}
                />
              ) : (
                <input
                  type="radio"
                  className="custom-control-input"
                  name={name || "example-radios"}
                  defaultValue={radioValue}
                  defaultChecked={defaultChecked === index}
                  onChange={onPress}
                  disabled={disabled}
                />
              )}
              {icons ? (
                <div className="custom-control-label custom-radioButton">
                  <i className={icons[index] || ""} />{" "}
                  {isObject ? radioItems?.label : radioItems}
                </div>
              ) : (
                <div className="custom-control-label">
                  {isObject ? radioItems?.label : radioItems}
                </div>
              )}
            </label>
          );
        })}
      </div>
    </>
  );
};

export default RadioButtons;

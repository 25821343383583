import React from "react";
import { Regex } from "../../../common/Utils/ApiConstants";
import { colors } from "../../../common/Theme/Colors";
import { AppFonts } from "../../../common/Theme/Fonts";

export const setMessageType = (docResult) => {
  if (docResult?.length) {
    const extension = docResult
      .substring(docResult.lastIndexOf(".") + 1)
      .toLowerCase();
    switch (extension) {
      case "docx":
        return "doc";
      case "pdf":
        return "application/pdf";
      case "png":
        return "image/png";
      case "jpg":
      case "jpeg":
        return "image/jpeg";
      case "gif":
        return "image/gif";
      case "csv":
        return "text/csv";
      case "mp3":
        return "mp3";
      case "mp4":
        return "mp4";
      case "xls":
        return "application/vnd.ms-excel";
      case "xlsx":
        return "application/vnd.ms-excel";
      case "heif":
        return "image/heif";
      case "heic":
        return "image/heic";
      default:
        return "attachment";
    }
  }
  return "text/plain";
};

export const RenderMessageContent = (textMsg, isStr) => {
  const { mentionWithDelimiter, mentionNoDelimiter } = Regex;

  const renderParts = [];
  const resultArray = textMsg.split(mentionWithDelimiter);
  let prevMatch = false;
  let messageStr = "";

  for (let i = 0; i < resultArray.length; i++) {
    const match = mentionNoDelimiter.exec(resultArray[i]);

    if (match && match?.length) {
      prevMatch = true;
      continue;
    } else {
      let msgItem = !prevMatch && resultArray[i];

      if (prevMatch) {
        let mentionName = `@${resultArray[i]}`;
        msgItem = isStr ? (
          mentionName
        ) : (
          <span
            key={i}
            style={{
              color: colors.primary_007,
              fontWeight: 500,
            }}
          >
            {mentionName}
          </span>
        );
      }

      renderParts.push(msgItem);
      messageStr += msgItem;
      prevMatch = false;
    }
  }

  return isStr ? renderParts.join("") : renderParts;
};

export default {
  editPersonalAssistantHeading: "تعديل المساعد/ة الشخصي",
  addPersonalAssistantHeading: "إضافة مساعد/ة شخصي",
  selectCompany: "إختر الشركة",
  dropdownPlaceholder: "إختر الشركة",
  selectCompanyMessage: "يرجى اختيار شركة واحدة على الأقل",
  selectedCompanyMessage: "المحدد شركة الجميع",
  name: "اسم المساعد/ة الشخصي",
  dob: "تاريخ الميلاد",
  designation: "الوظيفة",
  department: "القسم",
  contactNumber: "رقم جهة الإتصال",
  contactNumberPlaceHolder: "0000 0000 00",
  alternateContactNumber: "رقم جهة اتصال بديل",
  alternateContactNumberPlaceHolder: "0000 0000 00",
  hrContactNumber: "جهة اتصال الموارد البشرية",
  hrContactNumberPlaceHolder: "0000 0000 00",
  extensionNumber: "الرقم الفرعي",
  companyExtensionNumber: "الرقم الفرعي للشركة",
  extensionNumberPlaceholder: "0000 0000 00",
  companyExtensionPlaceholder: "1",
  companyPhoneExtensionPlaceholder: "000",
  email: "ايميل العمل",
  emailPlaceholder: "ايميل العمل",
  address: "عنوان السكن",
  addressPlaceholder_1: "رقم المنزل/الشقة/البلوك",
  addressPlaceholder_2: "الشقة/الطريق/المنطقة",
  addressPlaceholder_3: "رقم الشقة/البلوك",
  addressPlaceholder_4: "المبنى/الطريق/المنطقة",
  countryPlaceholder: "الدولة",
  statePlaceholder: "الولاية/المنطقة",
  cityPlaceholder: "المدينة",
  zipcodePlaceholder: "الرمز البريدي ",
  workAddress: "عنوان العمل",
  gender: "الجنس",
  uploadPhoto: "تحميل صورة",
  save: "حفظ",
  addMore: "أضف المزيد",
  nameError: "اسم المساعد/ة الشخصي حقل مطلوب",
  dobError: "تاريخ الميلاد حقل مطلوب",
  designationError: "الوظيفة حقل مطلوب",
  departmentError: "القسم حقل مطلوب",
  mobileError: "رقم جهة الإتصال حقل مطلوب",
  mobileInvalidError: "رقم جهة الإتصال غير صالح",
  workEmailErrorInvalid: "ايميل العمل غير صالح",
  workEmailError: "ايميل العمل حقل مطلوب",
  addressError: "عنوان السكن حقل مطلوب",
  countryError: "الدولة حقل مطلوب",
  stateError: "الولاية/المنطقة مطلوبة",
  cityError: "المدينة حقل مطلوب",
  workAddressError: "عنوان العمل حقل مطلوب",
  zipcodeErrorInvalid: "الرمز البريدي غير فعّال",
  ageAlert: "يجب أن يكون العمر أكبر من 18 سنة",
  reportTo: "المدير المباشر",
  viewPersonalAssistantHeading: "عرض المساعد/ة الشخصي",
  deleteAlert: "هل أنت متأكد من حذف المساعد/ة الشخصي",
  srNoTableHeading: "الرقم",
  actionTableHeading: "الإجراء",
  nameTableHeading: "الإسم",
  mobileTableHeading: "رقم الموبايل",
  searchPlaceholder: "ابحث عن شيء...",
  addButtonLabel: "إضافة",
  rateButtonLabel: "معدل",
  companyNumberMinMaxError: "يجب أن يتكون رقم الشركة من 5 إلى 12 رقمًا",
  companyNumberMinError: "يجب أن يكون رقم الشركة أكبر من أو يساوي 5",
  companyNumberMaxError: "يجب أن يكون رقم الشركة أقل من أو يساوي 12",
  companyExtensionCodeMinMaxError:
    "يجب أن يتكون رمز ملحق الشركة من 1 إلى 4 أرقام",
  companyNameTableHeading: "اسم الشركة",
  emailTableHeading: "ايميل",
};

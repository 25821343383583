import Translate from "../../../../translation/utils/translateFunction";
import { convertToSentenceCase } from "../../../../common/Utils/commonFunction";

export const getSharedUsersColumn = (
  CustomSrCellRender,
  CustomNameCellRender
) => {
  const columns = [
    {
      field: "lineNo",
      headerName: Translate("common:srNoTitle"),
      align: "center",
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      renderCell: CustomSrCellRender,
    },
    {
      field: "name",
      headerName: Translate("contacts:userName"),
      flex: 0.4,
      sortable: false,
      disableColumnMenu: true,
      renderCell: CustomNameCellRender,
    },
    {
      field: "role",
      headerName: Translate("contacts:designation"),
      flex: 0.4,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => convertToSentenceCase(params?.row?.role)
    },
  ];
  return columns;
};

import React from "react";
import CompanyListing from "./component";
const ContactRepository = () => {
  return (
    <div className="main-container">
      <CompanyListing />
    </div>
  );
};

export default ContactRepository;

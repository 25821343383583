import React from "react";
import { useField } from "formik";
import SearchableDropDown from "../../TextField/SearchDropDown";

export const FormikSearchableDropDown = ({
  name,
  onSelect,
  onChange,
  selected,
  options,
  ...props
}) => {
  const [field, meta] = useField(name);
  const { error, touched } = { ...meta };

  return (
    <SearchableDropDown
      {...props}
      className={
        touched && error
          ? "form-control paddingStyle is-invalid"
          : "form-control paddingStyle "
      }
      selected={selected}
      onSelect={onSelect}
      options={options}
      name={name}
      onChange={onChange}
      isError={touched && error !== undefined}
      touched={touched}
      error={error}
    />
  );
};

export default FormikSearchableDropDown;

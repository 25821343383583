export default {
  head: 'Task details',
  taskTitle: 'Task title',
  description: 'Description',
  date: 'Date',
  time: 'Time',
  attachment: 'Attachment',
  alert: 'Share via.',
  whatsapp: 'Whatsapp',
  email: 'Email',
  startDate:'Start date',
  dueDate:"Due date",
  StartTime:'Start time',
  DueTime:'Due time',
  status:"Status",
  priority:"Priority",
  Team:"Team",
};

import Lottie from "lottie-react";
import React from "react";
import greenZoneAnimation from "../../../../src/assets/gif/greenZone.json";
import redZoneAnimation from "../../../../src/assets/gif/redZone.json";
import { colors } from "../../../common/Theme/Colors";
import { Persona } from "../../Persona";
import { VALIDATION_BUCKETURL } from "../../Persona/constants";
import {
  getProgresbarColor,
  getStatusLabel,
} from "../../Task/Components/utils";
import { teamStyle } from "../constants";
import Translate from "../../../translation/utils/translateFunction";
import TextWrapWithTooltip from "../../TextWrapWithTooltip";
import { isRtl as validateRtl } from "../../../common/Utils/commonFunction";

const isRtl = validateRtl();

// Custom Renders for Column Data
export const CustomDueDateCellRender = (params) => {
  const { formattedEndDate, dueTime } = params?.row;
  return (
    <div>
      <div className="text-info">{formattedEndDate}</div>
      <div className="text-pink">{dueTime}</div>
    </div>
  );
};

export const CustomPriorityCellRender = (params) => {
  const { priority } = params?.row;

  return (
    <TextWrapWithTooltip
      textVariant="span"
      customClassName={`chatUserTitle ${priority?.toLowerCase()}`}
      label={priority}
      customStyle={isRtl && { direction: "ltr" }}
    />
  );
};

export const CustomProgressBarCellRender = (params) => {
  const { taskProgress, taskStatus } = params?.row;

  return (
    <div style={{ width: "100%" }}>
      <strong>{taskProgress}%</strong>
      <div className="progress progress-xs">
        <div
          style={{
            backgroundColor: getProgresbarColor(taskStatus),
            width: `${taskProgress}%`,
          }}
          className={`progress-bar bg-azure`}
          role="progressbar"
        />
      </div>
    </div>
  );
};

export const CustomTaskTypeCellRender = (params) => {
  const [zoneColor, zoneAnimation] =
    params?.row?.taskColorIndicatorFlag == "green"
      ? [colors.green, greenZoneAnimation, "green"]
      : params?.row?.taskColorIndicatorFlag === "red"
      ? [colors.red, redZoneAnimation, "red"]
      : [];

  return (
    <>
      {zoneAnimation && (
        <Lottie
          animationData={zoneAnimation}
          loop={true}
          style={{ width: 20, height: 20, marginRight: 5 }}
        />
      )}
      <span>{params?.row?.type}</span>
      {params?.row?.hasPinned && (
        <i className="fa fa-thumb-tack ml-2 iconPinColorStyle" />
      )}
      {params?.row?.hasFlagged && (
        <i className="fa fa-flag-o ml-2 iconFlagColorStyle" />
      )}
    </>
  );
};

export const CustomTeamCellRender = (params) => (
  <ul className="list-unstyled" style={teamStyle}>
    {params?.row?.userDetails?.map((item, index) => {
      const userTitle =
        index === 0
          ? Translate("taskDetails:reporter")
          : index === 1
          ? Translate("taskDetails:assignee")
          : Translate("taskDetails:reAssignee");

      return (
        item?.name && (
          <li data-toggle="tooltip" title={`${userTitle}: ${item?.name}`}>
            <Persona
              name={item?.name}
              image={
                item?.profileUrl?.includes(VALIDATION_BUCKETURL)
                  ? item?.profileUrl
                  : ""
              }
              size={28}
            />
          </li>
        )
      );
    })}
  </ul>
);

export const CustomStatusCellRender = (params) =>
  getStatusLabel(params?.row?.taskStatus);

export const MarkCriticalAndPinTask = ({
  taskId,
  showCriticalFlag,
  hasFlagged,
  isCompleted,
  hasPinned,
  markAsCritical,
  pinTask,
}) => {
  return (
    <>
      {showCriticalFlag && (
        <div
          className="manageIconColor ml-3 cursor-pointer dropdown-item"
          onClick={() => {
            let body = {
              taskId: taskId,
              isCritical: hasFlagged ? false : true,
            };
            markAsCritical(body);
          }}
        >
          <span className={`manageIconColor fa fa-flag-o`} />
          {hasFlagged
            ? Translate("manageTask:removeCritical")
            : Translate("manageTask:markasCritical")}
        </div>
      )}
      {!isCompleted && (
        <div
          className="manageIconColor ml-3 cursor-pointer dropdown-item"
          onClick={() => {
            let body = {
              taskId: taskId,
            };
            pinTask(body);
          }}
        >
          <span className={`iconPinColorStyle fa fa-thumb-tack`} />
          {hasPinned
            ? Translate("manageTask:unPinTask")
            : Translate("manageTask:pinTask")}
        </div>
      )}
    </>
  );
};

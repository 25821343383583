import React, { useEffect, useState } from "react";
import { Grid, Stack } from "@mui/material";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getManagetTaskColumns } from "../column.data";
import ListView from "../../ListView";
import { colors } from "../../../common/Theme/Colors";
import {
  StyledCard,
  StyledTypography,
} from "../../../common/Utils/StyledComponents";
import { showToast } from "../../../common/Utils/ToastMessage";
import {
  useGetChatGroupRelatedTaskListMutation,
  useGetChatRelatedTaskListMutation,
  useGetEmailRelatedTaskListMutation,
  useGetRelatedTaskDataMutation,
} from "../../../request/ManageTaskFilters/RelatedTask";
import {
  useCriticalTaskMutation,
  usePinTaskMutation,
} from "../../../request/Task";
import Translate from "../../../translation/utils/translateFunction";
import { SUBTASK_PREFIX } from "../constants";
import { tabType as messageType } from "../../Message/Components/constants";
import PageHeader from "../../common/pageHeader";
import { taskStatusType } from "../../AddTask/constants";
import { MarkCriticalAndPinTask } from "../Components/utils";

const RelatedTaskListing = ({ fixNavbar }) => {
  const navigate = useNavigate();
  const { userData, isRtl } = useSelector((state) => state.formanagement);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPageNo, setLastPageNo] = useState(0);
  const [relatedTaskListingData, setRelatedTaskListingData] = useState([]);
  const { state } = useLocation();
  const [chatGroupList, setChatGroupList] = useState();
  const [chatList, setChatList] = useState();

  const [emailRelatedTaskData, setEmailRelatedTaskData] = useState();

  const [
    getRelatedTaskList,
    {
      data: relatedTaskData,
      isLoading: loadingRelatedTask,
      isFetching: fetchingRelatedTaskData,
      isError: relatedTaskIsError,
      error: relatedTaskError,
    },
  ] = useGetRelatedTaskDataMutation();

  const [pinTask, { data: pinTaskData }] = usePinTaskMutation({
    fixedCacheKey: "pinnedTask",
  });
  const [markCritical, { data: markCriticalData }] = useCriticalTaskMutation({
    fixedCacheKey: "criticalTask",
  });

  const [
    getEmailRelatedTaskList,
    {
      data: emailRelatedTask,
      isSuccess: isSuccessEmailRelatedTask,
      isError: isErrorEmailRelatedTask,
      error: errorEmailRelatedTask,
      isLoading: isLoadingEmailRelatedTask,
    },
  ] = useGetEmailRelatedTaskListMutation();

  const [
    getChatGroupRelatedTaskList,
    {
      data: chatGroupRelatedTask,
      isSuccess: isSuccessChatGroupRelatedTask,
      isError: isErrorChatGroupRelatedTask,
      error: errorChatGroupRelatedTask,
      isLoading: isLoadingChatGroupRelatedTask,
    },
  ] = useGetChatGroupRelatedTaskListMutation();

  const [
    getChatRelatedTaskList,
    {
      data: chatRelatedTask,
      isSuccess: isSuccessChatRelatedTask,
      isError: isErrorChatRelatedTask,
      error: errorChatRelatedTask,
      isLoading: isLoadingChatRelatedTask,
    },
  ] = useGetChatRelatedTaskListMutation();

  useEffect(() => {
    const requestObjectEmail = {
      pageNo: currentPage,
      taskId: state?.id,
    };
    setRelatedTaskListingData([]);

    const requestObjectChatGroup = {
      groupId: state?.id,
      pageNo: currentPage,
      searchText: "",
    };
    const requestObjectChat = {
      chatId: state?.id,
      pageNo: currentPage,
      searchText: "",
    };
    if (
      state &&
      state.type === messageType?.GROUP_LIST &&
      state?.moduleType === "Chat"
    ) {
      getChatGroupRelatedTaskList(requestObjectChatGroup);
    } else if (state && state.moduleType === "Inbox") {
      getEmailRelatedTaskList(requestObjectEmail);
    } else if (
      state &&
      state.type === messageType?.CHAT_LIST &&
      state?.moduleType === "Chat"
    ) {
      getChatRelatedTaskList(requestObjectChat);
    } else {
      getRelatedTaskList(currentPage);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, currentPage, pinTaskData, markCriticalData, state?.id]);

  useEffect(() => {
    if (chatGroupRelatedTask && isSuccessChatGroupRelatedTask) {
      const { nodes, pageInfo } = chatGroupRelatedTask?.data;
      setChatGroupList(nodes);
      setLastPageNo(pageInfo?.lastPageNo);
    }
    if (isErrorChatGroupRelatedTask && errorChatGroupRelatedTask) {
      showToast({
        message: JSON.stringify(errorChatGroupRelatedTask),
        type: "error",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    chatGroupRelatedTask,
    isSuccessChatGroupRelatedTask,
    errorChatGroupRelatedTask,
  ]);

  useEffect(() => {
    if (chatRelatedTask && isSuccessChatRelatedTask) {
      const { nodes, pageInfo } = chatRelatedTask?.data;
      setChatList(nodes);
      setLastPageNo(pageInfo?.lastPageNo);
    }
    if (isErrorChatRelatedTask && errorChatRelatedTask) {
      showToast({
        message: JSON.stringify(errorChatGroupRelatedTask),
        type: "error",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatRelatedTask, isSuccessChatRelatedTask, errorChatRelatedTask]);

  useEffect(() => {
    if (emailRelatedTask && isSuccessEmailRelatedTask) {
      const { nodes, pageInfo } = emailRelatedTask?.data;
      setEmailRelatedTaskData(nodes);
      setLastPageNo(pageInfo?.lastPageNo);
    }
    if (isErrorEmailRelatedTask && errorEmailRelatedTask) {
      showToast({
        message: JSON.stringify(errorEmailRelatedTask),
        type: "error",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailRelatedTask, isSuccessEmailRelatedTask, errorEmailRelatedTask]);

  useEffect(() => {
    if (relatedTaskData?.nodes) {
      const { nodes, pageInfo } = relatedTaskData;
      setRelatedTaskListingData(nodes);
      setLastPageNo(pageInfo?.lastPageNo);
      // setLoading(false);
    }
    if (relatedTaskIsError && relatedTaskError) {
      showToast({ message: JSON.stringify(relatedTaskError), type: "error" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [relatedTaskData, relatedTaskError]);

  const customActionCellRender = (params) => {
    const { taskNumber } = params?.row;
    const isSubtask = taskNumber?.startsWith(SUBTASK_PREFIX);
    const isCompleted = params?.row?.taskStatus === taskStatusType?.COMPLETED;

    const showCriticalFlag = isCompleted
      ? false
      : params?.row?.userDetails?.some(
          (details) => details?._id === userData?._id
        );

    return (
      <>
        <div className="headerMargin">
          <a
            href="/#"
            className="nav-link dropdown-toggle"
            data-toggle="dropdown"
            role="button"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i className="fa fa-ellipsis-v manageIconColor" />
          </a>
          <div className="dropdown-menu dropdownAlignment dropdownIconMenu">
            <MarkCriticalAndPinTask
              taskId={params?.row?.taskId || params?.row?._id}
              showCriticalFlag={showCriticalFlag}
              hasFlagged={params?.row?.hasFlagged}
              isCompleted={isCompleted}
              hasPinned={params?.row?.hasPinned}
              markAsCritical={markCritical}
              pinTask={pinTask}
            />
            <div
              className="manageIconColor ml-3 cursor-pointer dropdown-item"
              onClick={() =>
                navigate(isSubtask ? "/subtask/view" : "/taskDetails", {
                  state: {
                    taskId: params?.row?.taskId,
                    isTaskFilter: true,
                    navigateFrom: "/relatedTaskListing",
                    parentState:
                      state?.moduleType === "Chat" ||
                      state?.moduleType === "Inbox"
                        ? state
                        : null,
                  },
                })
              }
            >
              <span className="manageIconColor fa fa-eye" />
              {Translate("manageTask:viewTask")}
            </div>
          </div>
        </div>
      </>
    );
  };

  const customSrCellRender = (params) => {
    return (
      <StyledTypography component="span" sx={{ color: colors.primary_009 }}>
        {params?.api?.getRowIndexRelativeToVisibleRows(params?.row?._id) +
          1 +
          (currentPage - 1) * 10}
      </StyledTypography>
    );
  };

  const getRows = () => {
    if (
      state &&
      state.type === messageType?.GROUP_LIST &&
      state?.moduleType === "Chat"
    ) {
      return chatGroupList;
    } else if (state && state.moduleType === "Inbox") {
      return emailRelatedTaskData;
    } else if (
      state &&
      state.type === messageType?.CHAT_LIST &&
      state?.moduleType === "Chat"
    ) {
      return chatList;
    } else {
      return relatedTaskListingData;
    }
  };

  const handleNavigation = () => {
    if (state?.moduleType === "Chat") {
      let reqObj = {
        ...state,
        _id: state?.id,
      };

      if (state?.type === messageType?.GROUP_LIST)
        reqObj["groupId"] = state?.id;

      return navigate("/message", {
        state: { data: reqObj },
      });
    }

    if (state?.moduleType === "Inbox") {
      return navigate("/inbox/viewEmailDetails", {
        state: { id: state?.inboxId, type: state?.type },
      });
    }

    return navigate("/manageTask");
  };

  return (
    <Grid
      item
      xs={12}
      className={`section-body ${isRtl ? "rtlMargin" : "ltrMargin"} ${
        fixNavbar ? "marginTop" : ""
      } `}
    >
      <StyledCard variant="outlined">
        <div className="container-fluid p-4">
          <div className="tab-content">
            <div>
              <div className="card">
                <PageHeader
                  containerClass="card-header card-header-spacing formTitle"
                  title={
                    <h5 className="page-title">
                      {Translate("manageTask:relatedTasks")}
                    </h5>
                  }
                  onClick={handleNavigation}
                />
                <div className="card-body">
                  <ListView
                    columns={getManagetTaskColumns(
                      customSrCellRender,
                      customActionCellRender,
                      false
                    )}
                    rows={getRows()}
                    autoHeight
                    sortingMode="server"
                    onPaginationChange={setCurrentPage}
                    currentPage={currentPage}
                    showShimmer={
                      loadingRelatedTask ||
                      isLoadingEmailRelatedTask ||
                      isLoadingChatGroupRelatedTask ||
                      isLoadingChatRelatedTask
                    }
                    showLoader={fetchingRelatedTaskData}
                    totalPageCount={lastPageNo}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </StyledCard>
    </Grid>
  );
};
export default RelatedTaskListing;

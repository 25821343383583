import { getDayJs, getMoment } from "../../common/Utils/commonFunction";
import Translate from "../../translation/utils/translateFunction";
export const InitialValues = {
  company: "",
  projectId: "",
  title: "",
  description: "",
  assignTo: "",
  reportTo: "",
  relatedTaskName: "",
  startDate: getMoment().toDate(),
  dueDate: getMoment().add(1, "days").toDate(),
  startTime: getDayJs(),
  dueTime: getDayJs(),
  priority: "Low",
  markAsCritical: false,
};
export const options = [
  "www.flipkart.com",
  "www.google.com",
  "www.amazon.in",
  "www.Myntra.com",
];

export const companyType = [
  {
    label: "Project",
    value: "Project",
  },
  {
    label: "Task",
    value: "Task",
  },
  {
    label: "Goal",
    value: "Goal",
  },
];

export const centerContent = {
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  alignContent: "center",
  fontWeight: "500",
  flex: 1,
};

export const checkBoxOption = [Translate("addTask:markAsCritical")];

export const priorities = ["Emergency", "High", "Medium", "Low"];

const ASSIGNED = "Assigned",
  INPROGRESS = "Inprogress",
  INPROGRESS_LABEL = "In-progress",
  ACCEPTED = "Accepted",
  REJECTED = "Rejected",
  RESOLVED = "Resolved",
  REOPENED = "Reopened",
  AWAITING_APPROVAL = "AwaitingApproval",
  AWAITING_APPROVAL_LABEL = "Awaiting approval",
  COMPLETED = "Completed",
  OVERDUE = "Overdue",
  REASSIGNED = "Reassigned";

export const taskStatusType = {
  ASSIGNED,
  INPROGRESS,
  INPROGRESS_LABEL,
  ACCEPTED,
  REJECTED,
  RESOLVED,
  REOPENED,
  AWAITING_APPROVAL,
  AWAITING_APPROVAL_LABEL,
  COMPLETED,
  OVERDUE,
  REASSIGNED,
};

export const taskApprovalEnums = {
  ACCEPT_BY_ASSIGNEE: "ACCEPT_BY_ASSIGNEE",
  REJECT_BY_ASSIGNEE: "REJECT_BY_ASSIGNEE",
  START: "START",
  START_SELF_ASSIGNED: "START_SELF_ASSIGNED",
  RESOLVE: "RESOLVE",
  APPROVE_BY_REPORTER: "APPROVE_BY_REPORTER",
  DISAPPROVE_BY_REPORTER: "DISAPPROVE_BY_REPORTER",
  MARK_AS_COMPLETED: "MARK_AS_COMPLETED",
  REALLOCATION_REQUEST: "REALLOCATION_REQUEST",
  APPROVE_REALLOCATION_REQ: "APPROVE_REALLOCATION_REQ",
  DISAPPROVE_REALLOCATION_REQ: "DISAPPROVE_REALLOCATION_REQ",
  APPROVE_BY_MANAGER: "APPROVE_BY_MANAGER",
  DISAPPROVE_BY_MANAGER: "DISAPPROVE_BY_MANAGER",
};

export const taskDetailOptions = [
  "viewLinkedSubtaskLabel",
  "viewTaskDependencyLabel",
];
export const taskDetailOptionShare = [
  "viewLinkedSubtaskLabel",
  "viewTaskDependencyLabel",
  "share",
];

export const defaultProjectOptions = {
  label: "Independent task",
  value: "null",
};

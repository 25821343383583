export default {
  head: "صندوق الرسائل",
  subject: "موضوع",
  from: "من",
  to: "الى",
  date: "التاريخ",
  create: "انشاء مهمة",
  related: "مهام ذات صلة",
  assigned: "المهمات المكلفة",
  viewAssignedTask: "عرض المهمات المكلفة",
  cc: "نسخة",
  inboxViwe: "منظر",
  filter: "منقي",
  Inbox: "صندوق الرسائل",
  Sent: "المرسلة",
  createTaskButton: "انشاء مهمة",
  relatedTaskButton: "مهام ذات صلة",
  actionable: "للتنفيذ",
  informative: "معلوماتية",
  labelAs: "تسمية باسم",
  sentFilter: "مرسل",
};

import * as d3 from "d3";
import { userType } from "../../../common/Utils/ApiConstants";

export const siblingLine = (d, allNodes) => {
  //start point
  const start = allNodes.filter(function (v) {
    if (d.source.id === v.id) {
      return true;
    } else {
      return false;
    }
  });
  //end point
  const end = allNodes.filter(function (v) {
    if (d.target.id === v.id) {
      return true;
    } else {
      return false;
    }
  });
  //define teh start coordinate and end co-ordinate
  const linedata = [
    {
      x: start[0].x,
      y: start[0].y,
    },
    {
      x: end[0].x,
      y: end[0].y,
    },
  ];

  const fun = d3.svg
    .line()
    .x(function (t) {
      return t.x;
    })
    .y(function (t) {
      return t.y;
    })
    .interpolate("linear");

  return fun(linedata);
};

export function JoinPaths(d) {
  if (d.target.no_parent) {
    return "M0,0L0,0";
  }
  let diff = d.source.y - d.target.y;
  //0.40 defines the point from where you need the line to break out change is as per your choice.
  let ny = d.target.y + diff * 0.5;

  let linedata = [
    {
      x: d.target.x,
      y: d.target.y,
    },
    {
      x: d.target.x,
      y: ny,
    },
    {
      x: d.source.x,
      y: d.source.y,
    },
  ];

  const fun = d3.svg
    .line()
    .x(function (t) {
      return t.x;
    })
    .y(function (t) {
      return t.y;
    })
    .interpolate("step-after");

  return fun(linedata);
}

export const flatten = (root) => {
  const results = [];
  let i = 0;

  function recurse(node) {
    if (node.children) {
      node.children.forEach(recurse);
    }

    if (!node.id) {
      node.id = ++i;
    }

    results.push(node);
  }

  recurse(root);
  return results;
};

export const getOwnerAndGmCount = (nodes) => {
  let ownerCount = 0;
  let gmCount = 0;

  for (const node of nodes) {
    const { designation, children } = node;

    if (designation?.toUpperCase() === userType?.Owner?.toUpperCase()) {
      ownerCount++;
    }

    for (let idx = 0; idx < children?.length; idx++) {
      if (
        children[idx]?.designation?.toUpperCase() ===
        userType?.GeneralManager?.toUpperCase()
      ) {
        gmCount++;
      }
    }
  }

  return {
    ownerCount,
    gmCount,
  };
};

import React, { useRef, useState, useEffect, memo } from "react";
import {
  useAddGroupMutation,
  useAddGroupFromTaskMutation,
} from "../../../../request/Message";
import { Formik } from "formik";
import Translate from "../../../../translation/utils/translateFunction";
import { InitialValues } from "./Components/constants";
import { showToast } from "../../../../common/Utils/ToastMessage";
import { FormikTextField } from "../../../FormikForm/component/FormikTextField";
import Loader from "../../../staff/Owner/LoadingComp";
import { useSelector } from "react-redux";
import MemberProfileList from "../MemberProfileList";
import { AddGroupSchema } from "./Components/validators";
import { useLocation } from "react-router-dom";
import { groupType } from "./Components/constants";
import { handleEventKeySubmit } from "../../../../common/Utils/commonFunction";

const AddGroup = ({ groupMembers, navigateTo }) => {
  const { validations, userData } = useSelector(
    (state) => state?.formanagement
  );
  const formikRef = useRef(false);
  const [selectedMembers, setSelectedMembers] = useState([]);

  const { state } = useLocation();

  const [
    addGroupApi,
    {
      data: addGroupData,
      isLoading: isAddGroupLoading,
      isSuccess: isAddGroupSuccess,
      isError: isAddGroupError,
      error: addGroupError,
    },
  ] = useAddGroupMutation();

  const [
    addGroupFromTaskApi,
    {
      data: addTaskGroupData,
      isLoading: isAddTaskGroupLoading,
      isSuccess: isAddTaskGroupSuccess,
      isError: isAddTaskGroupError,
      error: addTaskGroupError,
    },
  ] = useAddGroupFromTaskMutation();

  useEffect(() => {
    if (groupMembers?.length && userData) {
      if (state?.data?.createGroupFromTask) {
        setSelectedMembers([...groupMembers]);
      } else {
        setSelectedMembers([...groupMembers, userData]);
      }
    }
  }, [groupMembers, userData, state]);

  useEffect(() => {
    if (
      (isAddGroupError && addGroupError) ||
      (isAddTaskGroupError && addTaskGroupError)
    ) {
      const groupError = addGroupError?.data || addTaskGroupError?.data;

      if (!groupError) {
        showToast({
          message:
            addGroupError?.message ||
            addTaskGroupError?.message ||
            Translate("common:unknownServerError"),
          type: "error",
        });
      } else {
        groupError?.error?.length
          ? groupError.error.map((errorItem) => {
              if (!InitialValues.hasOwnProperty(errorItem?.param)) {
                return showToast({ message: errorItem?.msg, type: "error" });
              }

              return formikRef.current.setFieldError(
                errorItem.param,
                errorItem?.msg
              );
            })
          : showToast({
              message:
                groupError?.message || Translate("common:unknownServerError"),
              type: "error",
            });
      }
    }

    if (
      (isAddGroupSuccess && addGroupData) ||
      (isAddTaskGroupSuccess && addTaskGroupData)
    ) {
      showToast({
        type: "success",
        message: addGroupData?.message || addTaskGroupData?.message,
      });

      formikRef.current.resetForm();
      navigateTo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addGroupError, addGroupData, addTaskGroupData, addTaskGroupError]);

  const onSubmit = (formValues) => {
    const memberIds = groupMembers.map((item) => item?._id);
    const reqObj = {
      groupImage: "", // Backend dependency
      groupName: formValues?.groupName,
    };

    if (state?.data?.createGroupFromTask) {
      reqObj["groupType"] = groupType?.TASK;
      reqObj["taskId"] = state?.data?.taskId;

      addGroupFromTaskApi(reqObj);
    } else {
      reqObj["groupMembers"] = memberIds;
      reqObj["groupType"] = groupType?.CHAT;

      addGroupApi(reqObj);
    }
  };

  return (
    <Formik
      initialValues={InitialValues}
      innerRef={formikRef}
      validateOnMount
      onSubmit={onSubmit}
      validationSchema={AddGroupSchema}
    >
      {({ handleSubmit }) => {
        return (
          <>
            <div className="row" style={{ marginTop: "8px" }}>
              <div className="card">
                <div
                  className="card-body"
                  onKeyDown={(e) => handleEventKeySubmit(e, handleSubmit)}
                >
                  <div className="row">
                    <div className="form-group col-md-12 col-sm-12">
                      <FormikTextField
                        name="groupName"
                        label={Translate("group:groupSubject")}
                        placeholder={Translate("group:groupSubjectPlaceholder")}
                        autoComplete={"off"}
                        maxLength={validations?.nameLength.MAX}
                        autoFocus
                      />
                    </div>
                  </div>
                  <div className="showGroupMembers">
                    <span className="name">
                      {Translate("group:members")}: {selectedMembers?.length}
                    </span>
                    <MemberProfileList members={selectedMembers} />
                  </div>

                  <div className="card-footer text-right line_spacing">
                    <button
                      type="submit"
                      className="btn btn-primary saveButton"
                      onClick={handleSubmit}
                    >
                      {Translate("common:add")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <Loader loading={isAddGroupLoading || isAddTaskGroupLoading} />
          </>
        );
      }}
    </Formik>
  );
};

export default memo(AddGroup);

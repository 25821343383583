import { Methods, Urls } from "../../common/Utils/ApiConstants";
import { CreateApi } from "../CreateApi";

const RenewalCollection = CreateApi.injectEndpoints({
  endpoints: (builder) => ({
    addRenewalData: builder.mutation({
      query: (body) => {
        return {
          url: Urls.addRenewal,
          method: Methods.post,
          body,
        };
      },
    }),
    getRenewalList: builder.mutation({
      query: (body) => {
        return {
          url: Urls.getRenewalList,
          method: Methods.post,
          body,
        };
      },
    }),
    getRenewalDetails: builder.mutation({
      query: (id) => {
        return {
          url: `${Urls.getRenewalDetails}${id}`,
          method: Methods.get,
        };
      },
    }),
    editRenewalData: builder.mutation({
      query: (data) => {
        return {
          url: `${Urls.editRenewal}${data?.id}`,
          method: Methods.put,
          body: data?.body,
        };
      },
    }),
  }),
});

export const {
  useAddRenewalDataMutation,
  useGetRenewalListMutation,
  useGetRenewalDetailsMutation,
  useEditRenewalDataMutation,
} = RenewalCollection;

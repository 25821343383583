import Translate from "../../../translation/utils/translateFunction";

export const weekDays = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const SELF = Translate("common:self"),
  OWNER = Translate("drawer:owner"),
  PA = Translate("common:personalAssistant"),
  GM = Translate("common:generalManager"),
  MANAGER = Translate("drawer:manager"),
  EMPLOYEE = Translate("drawer:employee"),
  VENDOR = Translate("filter:vendors_Suppliers");

export const userTypeOptions = {
  SELF,
  OWNER,
  PA,
  GM,
  MANAGER,
  EMPLOYEE,
  VENDOR,
};

export const companyTypeOptions = [
  {
    label: "All",
    value: "ALL",
  },
  {
    label: "Project",
    value: "Project",
  },
  {
    label: "Task",
    value: "Task",
  },
  {
    label: "Goal",
    value: "Goal",
  },
];

export const companyType = ["Project", "Task", "Goal"];

import { Stack } from "@mui/material";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  DateTimeFormats,
  formatDateYYYYMMDD,
  getTZFormatDate,
} from "../../../common/Utils/DateFormater";
import { StyledTypography } from "../../../common/Utils/StyledComponents";
import {
  useAddAttendanceConfigureMutation,
  useGetViewAttendanceMutation,
} from "../../../request/Report";
import Translate from "../../../translation/utils/translateFunction";
import RenameVoiceNote from "../../VoiceNotes/Components/RenameVoiceNote";
import FullCalendarScreen from "../../common/fullcalendar";
import { weekDays } from "./constants";
import { showToast } from "../../../common/Utils/ToastMessage";
import Loader from "../../staff/Owner/LoadingComp";
import { useNavigate } from "react-router-dom";
import IconifyIcon from "../../Icon";
import { colors } from "../../../common/Theme/Colors";
import { getMoment } from "../../../common/Utils/commonFunction";
import { calendarViewEnums } from "../../Calendar/utils";
import AlertPermission from "../../AlertPermission";
const Configuration = ({ fixNavbar }) => {
  const YMDFormat = "YYYY-MM-DD";
  const navigate = useNavigate();
  const { selectedCompanyList, isRtl } = useSelector(
    (state) => state?.formanagement
  );
  const [selectedWeekend, setSelectedWeekend] = useState([]);
  const [selectedWeekendDates, setSelectedWeekendDates] = useState([]);
  const [holidays, setHolidays] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showConsent, setShowConsent] = useState(false);
  const [consentDates, setConsentDates] = useState([]);
  const [consentDay, setConsentDay] = useState();
  const [initialDateForCalendar, setInitialDateForCalendar] = useState(
    getMoment().clone().format(YMDFormat)
  );
  const [initialDate, setInitialDate] = useState(
    getMoment().clone().format(YMDFormat)
  );
  const [isUpdateAttendance, setIsUpdateAttendance] = useState(false);

  const [
    addConfigure,
    {
      data: serverResponse,
      isSuccess: isConfigureSuccess,
      isLoading: isConfigureLoading,
    },
  ] = useAddAttendanceConfigureMutation();

  const [
    getViewAttendanceReportApi,
    {
      data: viewAttendanceReportData,
      isLoading: isViewAttendanceLoading,
      isSuccess: isViewAttendanceSuccess,
      isError: isErrorViewAttendance,
      error: errorViewAttendance,
    },
  ] = useGetViewAttendanceMutation();
  const hideModal = () => {
    setShowConsent(false);
    consentDates.map((dates) => {
      const getIdx = selectedWeekendDates.findIndex(
        (d) => formatDateYYYYMMDD(d?.date) == formatDateYYYYMMDD(dates?.date)
      );
      if (getIdx !== -1) {
        selectedWeekendDates.splice(getIdx, 1);
        setSelectedWeekendDates([...selectedWeekendDates]);
      }
    });
    const getIdxDay = selectedWeekend.findIndex((day) => day === consentDay);
    if (getIdxDay !== -1) {
      selectedWeekend.splice(getIdxDay, 1);
      setSelectedWeekend([...selectedWeekend]);
    }
    setConsentDates([]);
  };
  const onConfirm = () => {
    consentDates.map((dates) => {
      setHolidays((prevHoliday) => {
        const previousHoliday = [...prevHoliday];
        const getIdx = previousHoliday.findIndex(
          (day) =>
            formatDateYYYYMMDD(day?.date) === formatDateYYYYMMDD(dates?.date)
        );
        if (getIdx !== -1) {
          previousHoliday.splice(getIdx, 1);
        }

        return previousHoliday;
      });
    });
    setConsentDay();
    setConsentDates([]);
    setShowConsent(false);
  };
  const requestObj = useMemo(() => {
    const companies = selectedCompanyList?.length
      ? selectedCompanyList?.map((item) => item?.value)
      : [];

    const finalReq = {
      companyId: companies[0],

      year: moment(initialDateForCalendar).format(DateTimeFormats.year),
      month: moment(initialDateForCalendar).format(DateTimeFormats.longMonth),
    };
    return companies?.length ? finalReq : null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCompanyList, initialDateForCalendar, isConfigureSuccess]);

  useEffect(() => {
    getViewAttendanceReportApi(requestObj);
  }, [requestObj, initialDateForCalendar]);
  const [markedEvents, setMarkedEvents] = useState([]);

  useEffect(() => {
    if (viewAttendanceReportData?.data && isViewAttendanceSuccess) {
      const { data } = viewAttendanceReportData;
      setHolidays(data?.holidays);

      setIsUpdateAttendance(true);
      const getDayOfWeek = (dateString) => {
        const date = new Date(dateString);
        const dayIndex = date?.getDay();
        return weekDays[dayIndex];
      };

      const daysOfWeek = data?.weekends.map((item) => getDayOfWeek(item?.date));
      const uniqueDaysOfWeek = daysOfWeek.filter(
        (day, index) => daysOfWeek.indexOf(day) === index
      );

      setSelectedWeekend(uniqueDaysOfWeek);
      setSelectedWeekendDates(data?.weekends);
      const updatedEvents = data?.holidays.map((ele) => ({
        _id: ele?.type,
        title: ele?.occasion || "",
        startDate: ele?.date ? ele?.date : "",
        end: ele?.date ? ele?.date : "",
        ...ele,
      }));

      setMarkedEvents(updatedEvents);
    }
    if (viewAttendanceReportData && isViewAttendanceSuccess) {
      if (viewAttendanceReportData?.hasOwnProperty("data")) {
        setIsUpdateAttendance(true);
      } else {
        setIsUpdateAttendance(false);
        setSelectedWeekend([]);
        setSelectedWeekendDates([]);
        setHolidays([]);
      }
    }
    if (isErrorViewAttendance && errorViewAttendance) {
      showToast({
        message:
          errorViewAttendance?.data?.message ||
          Translate("common:unknownServerError"),
        type: "error",
      });
    }
  }, [
    viewAttendanceReportData,
    isViewAttendanceSuccess,
    isErrorViewAttendance,
    errorViewAttendance,
  ]);

  const handleDateClick = (info) => {
    const exist = selectedWeekendDates.find(
      (dates) =>
        formatDateYYYYMMDD(dates?.date) === formatDateYYYYMMDD(info?.date)
    );
    if (
      !exist &&
      moment(info?.date).format("MM") ===
        moment(initialDateForCalendar).format("MM")
    ) {
      setInitialDate(info?.date);
      setShowModal(true);
    }
  };

  const handleCalendarView = (date) => {
    resetData();
    setInitialDateForCalendar(date);
  };
  const resetData = () => {
    setSelectedWeekend([]);
    setSelectedWeekendDates([]);
    setHolidays([]);
    setConsentDates([]);
    setConsentDay();
  };
  useEffect(() => {
    if (weekDays.length && selectedWeekend?.length < 0) {
      setSelectedWeekend([
        ...selectedWeekend,
        weekDays[0],
        weekDays[weekDays.length - 1],
      ]);
      getDates(0);
      getDates(weekDays.length - 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weekDays, initialDateForCalendar]);

  useEffect(() => {
    if (isConfigureSuccess === true && serverResponse) {
      if (serverResponse?.data) {
        resetData();
        showToast({ message: serverResponse?.message, type: "success" });
        getViewAttendanceReportApi(requestObj);
      } else if (serverResponse?.success == false)
        showToast({ message: serverResponse?.message, type: "error" });
      else showToast({ message: serverResponse?.message, type: "info" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConfigureSuccess]);

  const onSave = () => {
    const requestObj = {
      companyId: selectedCompanyList[0]?.value,
      year: moment(initialDateForCalendar).format(DateTimeFormats.year),
      month: moment(initialDateForCalendar).format(DateTimeFormats.longMonth),
      weekends: selectedWeekendDates,
      holidays: holidays,
      isUpdate: isUpdateAttendance,
    };
    if (selectedWeekend?.length >= 2 || selectedWeekend?.length === 0) {
      addConfigure(requestObj);
    } else {
      showToast({
        message: Translate("common:weekendsError"),
        type: "error",
      });
    }
  };

  const onSubmit = (renamedValue) => {
    const selectedDate = moment(initialDate).format("YYYY-MM-DD");

    const isHolidayExists = holidays.find((obj) => {
      const eleDate = obj.date.split("T")[0];

      return eleDate === selectedDate;
    });

    if (isHolidayExists) {
      showToast({ message: Translate("common:holidayPresent"), type: "error" });
    } else {
      const newHolidays = [
        ...holidays,
        {
          date: getTZFormatDate(initialDate),
          occasion: renamedValue,
          type: "Corporate",
        },
      ];
      setHolidays(newHolidays);
      if (renamedValue) {
        const requestObj = {
          companyId: selectedCompanyList[0]?.value,
          year: moment(initialDateForCalendar).format(DateTimeFormats.year),
          month: moment(initialDateForCalendar).format(
            DateTimeFormats.longMonth
          ),
          weekends: selectedWeekendDates,
          holidays: newHolidays,

          isUpdate: isUpdateAttendance,
        };
        addConfigure(requestObj);
      }
    }
  };
  // const getDates = (dayIndex, isRemove) => {

  //   let my_date = moment(initialDateForCalendar)
  //     .format(DateTimeFormats.YearMonthDay)
  //     .split("-");
  //   let year = parseInt(my_date[0]);
  //   let month = parseInt(my_date[1]) - 1;
  //   for (let i = 0; i <= new Date(year, month, 0).getDate(); i++) {
  //     let date = new Date(year, month, i);
  //     if (date.getDay() === dayIndex) {
  //       const getIdx = selectedWeekendDates.findIndex(
  //         (d) => formatDateYYYYMMDD(d?.date) === formatDateYYYYMMDD(date)
  //       );
  //
  //       isRemove
  //         ? selectedWeekendDates.splice(getIdx, 1)
  //         : selectedWeekendDates.push({ date: getTZFormatDate(date) });
  //       setSelectedWeekendDates([...selectedWeekendDates]);
  //     }
  //   }
  // };

  // Added new function for the dates to check the existing dates present and then add or remove dates
  const getDates = (dayIndex, isRemove) => {
    let my_date = moment(initialDateForCalendar)
      .format(DateTimeFormats.YearMonthDay)
      .split("-");
    let year = parseInt(my_date[0]);
    let month = parseInt(my_date[1]) - 1;
    setConsentDates([]);
    setSelectedWeekendDates((prevSelectedWeekendDates) => {
      const updatedSelectedWeekendDates = [...prevSelectedWeekendDates];
      const consentTemp = [];
      for (let i = 0; i <= new Date(year, month + 1, 0).getDate(); i++) {
        let date = new Date(year, month, i);

        if (date.getDay() === dayIndex) {
          const formattedDate = formatDateYYYYMMDD(date);

          if (isRemove) {
            const idxToRemove = updatedSelectedWeekendDates.findIndex(
              (d) => formatDateYYYYMMDD(d?.date) === formattedDate
            );

            if (idxToRemove !== -1) {
              updatedSelectedWeekendDates.splice(idxToRemove, 1);
            }
          } else {
            const exists = updatedSelectedWeekendDates.some(
              (d) => formatDateYYYYMMDD(d?.date) === formattedDate
            );
            const isHolidayExist = holidays?.some(
              (d) => formatDateYYYYMMDD(d?.date) === formattedDate
            );

            if (
              !exists &&
              moment(date).format("MM") ===
                moment(initialDateForCalendar).format("MM")
            ) {
              updatedSelectedWeekendDates.push({ date: getTZFormatDate(date) });
              consentTemp.push({ date: getTZFormatDate(date) });
              isHolidayExist && setShowConsent(true);
            }
          }
        }
      }
      setConsentDates([...consentTemp]);
      return updatedSelectedWeekendDates;
    });
  };
  return (
    <div
      className={`section-body ${isRtl ? "rtlMargin" : "ltrMargin"} ${
        fixNavbar ? "marginTop" : ""
      } mt-3`}>
      <div className="container-fluid">
        <div className="card" style={{ marginBottom: 8 }}>
          <div className="card-header">
            <i
              className="icon fe fe-arrow-left mr-3 icon-size backnavigation-iconColor cursor-pointer"
              onClick={() => navigate(-1)}
              title={Translate("common:backToolTip")}
            />
            <h5 className="page-title">
              {Translate("reports:ConfigureTitle")}
            </h5>
            <Stack
              flexDirection={"row"}
              alignItems={"center"}
              className="card-options">
              <IconifyIcon
                className="cursor-pointer"
                icon={"ant-design:left-circle-outlined"}
                fontSize="24"
                onClick={() =>
                  setInitialDateForCalendar(
                    moment(initialDateForCalendar).subtract("year", 1).format()
                  )
                }
              />
              <StyledTypography
                variant="h6"
                px={2}
                color={colors.primary}
                sx={{ fontWeight: "500" }}>
                {moment(initialDateForCalendar).format(DateTimeFormats.year)}
              </StyledTypography>
              <IconifyIcon
                className="cursor-pointer"
                icon={"ant-design:right-circle-outlined"}
                fontSize="24"
                onClick={() =>
                  setInitialDateForCalendar(
                    moment(initialDateForCalendar).add("year", 1).format()
                  )
                }
              />
            </Stack>
          </div>
        </div>
        <div className="row clearfix row-deck">
          <div className="col-lg-3 col-md-12">
            <div className="card">
              <div className="card-header bline d-block" style={{ height: 77 }}>
                <Stack
                  flexDirection={"row"}
                  alignItems={"center"}
                  mb={2}
                  className="card-title"
                  style={{ marginTop: "4px" }}>
                  <StyledTypography
                    variant="subtitle1"
                    fontWeight={"500"}
                    className="font-16 mb-0">
                    {Translate("reports:weekendTitle")}
                  </StyledTypography>
                </Stack>
              </div>
              <Stack className="card-body">
                {weekDays.map((day, idx) => {
                  const active = selectedWeekend.findIndex((d) => d === day);

                  return (
                    <button
                      type="button"
                      onClick={() => {
                        if (active === -1) {
                          setSelectedWeekend([...selectedWeekend, day]);
                          setConsentDay(day);
                          getDates(idx);
                        } else {
                          selectedWeekend.splice(active, 1);
                          setSelectedWeekend([...selectedWeekend]);
                          setConsentDay();
                          getDates(idx, true);
                        }
                      }}
                      className={`btn ${
                        active !== -1 ? "btn-primary" : "btn-outline-primary"
                      } saveButton mb-2`}>
                      {Translate(`reports:${day}`)}
                    </button>
                  );
                })}
              </Stack>
            </div>
          </div>
          <div className="col-lg-9 col-md-12">
            <div className="card">
              <div className="card-header bline d-block">
                <div className="calendarHeaderBox d-block">
                  <Stack
                    flexDirection={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    mb={2}
                    className="card-title">
                    <StyledTypography
                      variant="subtitle1"
                      fontWeight={"500"}
                      className="font-16 mb-0">
                      {Translate("reports:holidayTitle")}
                    </StyledTypography>
                    <button
                      type="button"
                      onClick={onSave}
                      className={"btn btn-primary saveButton"}>
                      {Translate("common:save")}
                    </button>
                  </Stack>
                </div>
              </div>
              <div className="card-body">
                <FullCalendarScreen
                  events={markedEvents}
                  handleCalendarView={handleCalendarView}
                  handleDateClick={handleDateClick}
                  calendarDate={initialDateForCalendar}
                  right={false}
                  isConfigure={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Loader loading={isConfigureLoading} />
      <RenameVoiceNote
        title={Translate("reports:occasionTitle")}
        placeholder={Translate("reports:addOcassion")}
        handleModal={setShowModal}
        showModal={showModal}
        isOccasion={true}
        onSuccessFulRename={(value) => {}}
        onSubmit={onSubmit}
      />
      {showConsent && (
        <AlertPermission
          show={showConsent}
          subTitle={Translate("attendance:consentMsg")}
          okTitle={Translate("common:confirm")}
          onOk={onConfirm}
          onCanel={hideModal}
        />
      )}
    </div>
  );
};

export default Configuration;

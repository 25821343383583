import React from "react";
import { ReactComponent as Document } from "../../../src/assets/images/drawer-menu-svg/text_snippet.svg";

const DocumentShareIcon = () => {
  return (
    <div style={{ position: "relative" }}>
      <Document fill="#02005B" />
      <i className="fa fa-share-alt docShareIcon" />
    </div>
  );
};

export default DocumentShareIcon;

export const colors = {
  black: "#000000",
  black_001: "rgba(0,0,0,0.3)",
  black_002: "#181818",
  primary: "#02005B",
  primary_002: "#7372B8",
  primary_003: "#747688",
  primary_004: "#85849E",
  primary_005: "#CCCBEC",
  primary_007: "#3C3A82",
  primary_008: "#76708F",
  primary_009: "#2c2945",
  primary_010: "#5a5278",
  grey: "#808080",
  grey_001: "#F5F5F9",
  grey_002: "#F0F0F0",
  grey_003: "#9B9B9B",
  grey_004: "#565656",
  grey_005: "#CCCCCC",
  grey_006: "rgba(2, 0, 91, 0.05)",
  grey_007: "#E2E2F0",
  grey_008: "#D7D7D7",
  grey_009: "#F4F4FB",
  grey_010: "#FFEEEE",
  grey_011: "#E5E5E5",
  grey_012: "#E8E8F0",
  grey_013: "#B9B9B9",
  grey_014: "#D9D9D9",
  grey_015: "#AAABAC",
  grey_016: "#f1f1f1",
  white: "#ffffff",
  white_001: "#FFEEEE",
  red: "#FF0000",
  red_001: "#EE4047",
  red_002: "#FF0000",
  red_003: "#E46667",
  blue_001: "#1371FF",
  blue_002: "#7BD0FF",
  blue_005: "#daf5f7",
  orange: "#FFA24C",
  orange_001: "#FF7301",
  emergencyText: "#DC0042",
  emergency: "rgba(220, 0, 66, 0.05)",
  mediumText: "#FFAE42",
  selectedCompany: "rgba(2, 0, 91, 0.05)",
  blue_003: "#11AAFF",
  green: "#1AC368",
  yellow: "#F4BD34",
  emergencyBackground: "#FFEEEE",
  green_001: "#19c27e",
  green_002: "#8EFFC2",
  green_003: "#008000",
  highBackground: "rgba(255, 0, 0, 0.05)",
  mediumBackground: "#FFF7E8",
  lowBackground: "rgba(2, 0, 91, 0.05)",
  completedText: "#1AC368",
  medium: "#FFAE42",
  inbox: "#E8E8F3",
  placeHolder: "#495057b0",
  blue_004: "#5A8EDD",
};

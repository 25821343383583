import React from "react";
import { StyledTypography } from "../../../common/Utils/StyledComponents";
import Translate from "../../../translation/utils/translateFunction";
import { FormikPhoneInput } from "../../FormikForm/component/FormikPhoneInput";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as BrandLogo } from "../../../assets/images/FoM_logo.svg";
import { Stack } from "@mui/material";
import { colors } from "../../../common/Theme/Colors";
import Loader from "../../staff/Owner/LoadingComp";
import { handleEventKeySubmit } from "../../../common/Utils/commonFunction";

const LoginWithOTPMobile = ({
  handleSubmit,
  setCountry,
  setMobileNumber,
  isLoading,
  onGoBack,
}) => {
  const contactPhoneMaxLength = 12;
  const navigate = useNavigate();

  return (
    <div className="card" style={{ zIndex: 999 }}>
      <>
        <div className="text-center mb-30">
          <Link className="header-brand" to="/">
            <BrandLogo className="forLogo"></BrandLogo>
          </Link>
        </div>
        {/* <StyledTypography variant="h4" mb={"5px"} sx={{ fontWeight: "600" }}>
            {Translate("login:welcome")}
          </StyledTypography> */}
        {/* <StyledTypography
            variant="h5"
            marginY={"10px"}
            sx={{ fontWeight: "500" }}>
            {Translate("login:signIn")}
          </StyledTypography> */}
      </>

      <div className="card-body cardStyling">
        <div
          className="form-group col-md-12 pl-0"
          onKeyDown={(e) => handleEventKeySubmit(e, handleSubmit)}>
          <FormikPhoneInput
            name="phoneNumber"
            label={Translate("login:mobileNo")}
            placeholder={Translate("login:mobileNoPlaceholder")}
            onChange={(value, country) => {
              setMobileNumber(value?.split("-")[1]);
              setCountry(country);
            }}
            autoComplete={"off"}
            maxLength={contactPhoneMaxLength}
            onkeyDown={(e) => {
              if (e.key === "Enter") {
                handleSubmit();
              }
            }}
            autoFocus
            isLogin
          />
        </div>
        <div className="form-footer">
          <button
            type="submit"
            className="btn btn-primary btn-block"
            onClick={() => !isLoading && handleSubmit()}>
            {isLoading ? (
              <Loader loading={isLoading} isListLoader message={""} />
            ) : (
              Translate("login:proceed")
            )}
          </button>
        </div>

        <StyledTypography
          mt={"30px"}
          fontWeight={"500"}
          onClick={onGoBack}
          tabIndex="0"
          onKeyDown={(e) => handleEventKeySubmit(e, onGoBack)}
          className="text-center text-primary cursor-pointer">
          {Translate("login:emailLogin")}
        </StyledTypography>

        <Stack mt={"1.5rem"}>
          <StyledTypography variant="subtitle2">
            {Translate("login:termsAndConditions5")}
          </StyledTypography>
          <Stack flexDirection={"row"}>
            <StyledTypography
              className="cursor-pointer"
              variant="subtitle2"
              sx={{ color: colors.primary_010 }}
              // onClick={() => navigate("/termsConditions")}
              onClick={() => navigate("/termsOfServices")}>
              {Translate("login:termsAndConditions2")}
            </StyledTypography>
            <StyledTypography variant="subtitle2" mx={"4px"}>
              {Translate("login:termsAndConditions3")}
            </StyledTypography>
            <StyledTypography
              className="cursor-pointer"
              variant="subtitle2"
              sx={{ color: colors.primary_010 }}
              // onClick={() => navigate("/privacy")}
              onClick={() => navigate("/privacyPolicy")}>
              {Translate("login:termsAndConditions4")}
            </StyledTypography>
          </Stack>
        </Stack>
      </div>
    </div>
  );
};
export default LoginWithOTPMobile;

export default {
  head: "Vendors/suppliers",
  addVendorHeading: "Add vendor",
  editVendorHeading: "Edit vendor",
  title: "Vendors/Suppliers associated with company",
  vendorDetails: "Vendor details",
  companyName: "Vendor company name",
  selectCompany: "Select company",
  dropdownPlaceholder: "Select company",
  selectCompanyMessage: "Please select at least one company",
  vendorName: "Vendor name",
  email: "Email id",
  contactNumber: "Contact number",
  contactNumberPlaceHolder: "0000 0000 00",
  alternateContactNumber: "Alternate contact number",
  alternateContactNumberPlaceHolder: "0000 0000 00",
  address: "Address",
  country: "Country",
  taskDependency: "View task dependency",
  tasks: "Tasks",
  designation: "Designation",
  companyExtensionNumber: "Company phone extension number",
  extensionNumberPlaceholder: "0000 0000 00",
  companyExtensionPlaceholder: "1",
  companyPhoneExtensionPlaceholder: "000",
  department: "Department",
  workEmail: "Work email",
  workEmailPlaceholder: "Work email address",
  companyAddress: "Company address",
  addressPlaceholder_1: "House/flat/block no.",
  addressPlaceholder_2: "Apartment/road/area",
  addressPlaceholder_3: "Flat/block no.",
  addressPlaceholder_4: "Building/road/area",
  countryPlaceholder: "Country",
  statePlaceholder: "State/region",
  cityPlaceholder: "City",
  zipcodePlaceholder: "Zip code ",
  workAddress: "Work address",
  gender: "Gender",
  uploadPhoto: "Upload photo",
  save: "Save",
  addMore: "Add more",
  companyError: "Company is required",
  companyNameError: "Vendor company name is required",
  nameError: "Vendor name is required",
  designationError: "Designation is required",
  departmentError: "Department is required",
  reportToError: "Report To is required",
  mobileError: "Contact number is required",
  workEmailErrorInvalid: "Work email is not valid",
  workEmailError: "Work email is required",
  addressError: "Residence address is required",
  countryError: "Country is required",
  stateError: "State/Region is required",
  cityError: "City is required",
  workAddressError: "Work address is required",
  zipcodeErrorInvalid: "Zipcode is invalid!",
  ageAlert: "Age should be greater than 18",
  reportTo: "Report to",
  viewVendorHeading: "View vendor",
  deleteAlert: "Are you sure want to delete this vendor?",
  srNoTableHeading: "Sr.no",
  actionTableHeading: "Action",
  nameTableHeading: "Name",
  mobileTableHeading: "Mobile no",
  companyNameTableHeading: "Company name",
  searchPlaceholder: "Search something...",
  addButtonLabel: "Add",
  selectedCompanyMessage: "All company selected",
};

import { Stack } from "@mui/material";
import React from "react";
import Translate from "../../translation/utils/translateFunction";
import { StyledTypography } from "../Utils/StyledComponents";
import { useSelector } from "react-redux";

const CompanyHeader = ({ setShowModal, className = "", selectedCompany }) => {
  const { selectedCompanyList, isRtl } = useSelector(
    (state) => state.formanagement
  );
  const company = selectedCompanyList;
  return (
    <Stack flexDirection={"row"} alignItems={"center"} className={className}>
      <div onClick={() => setShowModal(true)} className="cursor-pointer">
        <StyledTypography
          variant="subtitle1"
          fontWeight={"500"}
          className="font-16 mb-0">
          {selectedCompany
            ? selectedCompany
            : selectedCompanyList?.length > 1
            ? Translate("common:allCompanySelect")
            : selectedCompanyList?.length > 0
            ? selectedCompanyList[0]?.label
            : Translate("common:selectCompany")}
        </StyledTypography>
      </div>
      <div className="m-2 cursor-pointer" onClick={() => setShowModal(true)}>
        <i className={isRtl ? "fa fa-angle-left" : "fa fa-angle-right"}></i>
      </div>
    </Stack>
  );
};
export default CompanyHeader;

export default {
    head: "تقرير الأداء",
    overallProgress: "الإنجاز الكّلي",
    emergency: "طارئ",
    high: "عالي",
    medium: "متوسط",
    low: "منخفض",
    assigned: "تم التكلييف",
    completed: "مكتمل",
    inprogress: "قيد الإنجاز",
    resolved: "تم حلها",
    reopened: "تم إعادة فتحها",
    overdue: "متأخرة",
    all: "الكل",
    byTask: "حسب المهمة",
    byProject: "حسب المشروع",
    byGoal: "حسب الهدف",
    progress: "الإنجاز",
    totalAssigned: "جميع المهام المكلّفة",
    onTime: "على الوقت",
    before: "قبل",
    after: "بعد",
    sortByPlaceHolder: "حدد فرز حسب",
    sortByLabel: "ترتيب",
    staffPlaceHolder: "حدد الموظفين",
    staffLabel: "طاقم العمل",
    performanceDetailsHeader: "تفاصيل تقرير الأداء",
    name: "الإسم",
    companyName: "اسم الشركة",
    role: "دور",
    isIn: " في داخل ",
    zone: " منطقة",
    viewPerformanceLabel: "عرض الأداء",
    pointsEarnedTitle: "النقاط المكتسبة",
    totalEmailLabel: "مجموع كل الإيميلات",
    receivedEmailLabel: "الإيميلات المستقبلة",
    sentEmailLabel: "الإيميلات المرسلة",
    ratioEmailLabel: "النسبة",
    taskCompletionLabel: "إنجاز المهمة",
    totalAssignedLabel: "مجموع المهمات المكلّفة",
    finalResultLabel: "النتيجة النهائية",
    definitionResultLabel: "تعريف النتيجة",
    excellentResultLabel: "80 - 100%: ممتاز",
    goodResultLabel: "70 - 80%: جيد",
    satisfactoryResultLabel: "60 - 70%: مقبول",
    avgResultLabel: "50 - 60%: متوسط",
    belowAvgResultLabel: "حتى 50%: أقل من المتوسط",
    beforeTime: "قبل الوقت",
    afterTime: "بعد الوقت",
    completedonTime: "مكتمل (على الوقت)",
    completedbeforeTime: "مكتمل (قبل)",
    completedafterTime: "مكتمل (بعد)",
    inRedZone: 'أنت في المنطقة الحمراء',
    totalTasks: "إجمالي المهام"
  };
  
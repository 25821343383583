import { CreateApi } from "../../CreateApi";
import { Methods, Urls } from "../../../common/Utils/ApiConstants";
const assignedByPATaskCollection = CreateApi.injectEndpoints({
  endpoints: (builder) => ({
    getAssignedByPATaskData: builder.query({
      query: (pageNo) => {
        return {
          url: Urls.getAssignedByPATaskList,
          method: Methods.get,
          params: { pageNo },
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
    }),
  }),
});
export const { useLazyGetAssignedByPATaskDataQuery } =
  assignedByPATaskCollection;

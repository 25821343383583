import React, { useRef, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { InitialValues } from "../../components/constants";
import DocumentForm from "../../components/DocumentForm";
import { useViewDocumentDataMutation } from "../../../../request/Document/AddDocument";
import { showToast } from "../../../../common/Utils/ToastMessage";
import { Formik } from "formik";

const ViewSharedDocument = () => {
  const formikRef = useRef(null);
  const { state } = useLocation();

  const [filesAdded, setfilesAdded] = useState([]);
  const [images, setImages] = useState([]);

  const [
    viewDocumentApi,
    {
      data: documentViewData,
      isSuccess: isViewSuccess,
      isLoading: isViewLoading,
      isError: isViewError,
      error: errorViewDocument,
    },
  ] = useViewDocumentDataMutation();

  useEffect(() => {
    if (state) {
      viewDocumentApi(state);
    }
  }, [viewDocumentApi, state]);

  useEffect(() => {
    if (isViewError && errorViewDocument) {
      showToast({
        message: errorViewDocument?.data?.message,
        type: "error",
      });
    }

    if (isViewSuccess && documentViewData) {
      const { data } = documentViewData;

      formikRef.current.setFieldValue("companyId", data?.companyId[0]?.name);
      formikRef.current.setFieldValue("title", data?.title);
      formikRef.current.setFieldValue("description", data?.description);

      if (data?.attachment?.url) {
        const { url, type } = data?.attachment;
        const obj = {
          path: decodeURIComponent(url).split("/").pop(),
          type,
          url,
        };
        setfilesAdded([...filesAdded, obj]);
        setImages([data?.attachment?.url]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentViewData, errorViewDocument]);

  return (
    <Formik initialValues={InitialValues} innerRef={formikRef}>
      {({ values }) => {
        return (
          <DocumentForm
            isSharedDocument
            isView
            values={values}
            filesAdded={filesAdded}
            images={images}
            setfilesAdded={setfilesAdded}
            isLoading={isViewLoading}
            documentData={documentViewData}
          />
        );
      }}
    </Formik>
  );
};

export default ViewSharedDocument;

import React from "react";
import { Stack } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useField } from "formik";
import {
  FontFamily,
  StyledTypography,
  ErroLabelStyle,
} from "../../../common/Utils/StyledComponents";

export const disableColor = {
  "& .MuiInputBase-input.Mui-disabled": {
    WebkitTextFillColor: "#33303cad",
    background: "#e5e5e58f",
  },
};

export const FormikPhoneInputWithExtField = ({
  label,
  required,
  maxLength = 15,
  extMaxLength = 3,
  onLocationSelect,
  disabled,
  RenderRightContainer,
  extPlaceholder,
  extName,
  ...props
}) => {
  const [field, meta, helpers] = useField(props.name);
  const [extField, extMeta, extHelpers] = useField(extName);

  const { error, touched } = { ...meta };
  const { error: extError, touched: extTouched } = { ...extMeta };

  const handleInputChange = (event, type) => {
    if (event.target.value.length > maxLength && type === "phone") {
      event.target.value = event.target.value.slice(0, maxLength);
    }

    if (event.target.value.length > extMaxLength && type === "extension") {
      event.target.value = event.target.value.slice(0, extMaxLength);
    }
  };

  return (
    <Stack>
      <StyledTypography className="labelStyling" component="span">
        {label}
        {required && (
          <StyledTypography sx={{ color: "red" }} component="span">
            {" *"}
          </StyledTypography>
        )}
      </StyledTypography>
      <Stack flexDirection={"row"} alignItems={"center"} gap={"8px"}>
        <TextField
          {...field}
          size="small"
          type="tel"
          style={{ width: "75%" }}
          sx={disabled ? disableColor : null}
          inputProps={{
            style: {
              fontFamily: FontFamily,
              padding: "8.5px 14px",
            },
            maxLength: maxLength,
          }}
          InputProps={{ endAdornment: RenderRightContainer || undefined }}
          onChange={(e) =>
            !isNaN(e.target.value) && helpers.setValue(e.target.value)
          }
          onInput={(e) => handleInputChange(e, "phone")}
          onFocus={(event) => event.target.select()}
          value={field.value || ""}
          {...props}
          variant="outlined"
          autoComplete="off"
          error={touched && error !== undefined}
          disabled={disabled}
        />

        <TextField
          {...extField}
          size="small"
          type="tel"
          style={{ width: "25%" }}
          sx={disabled ? disableColor : null}
          inputProps={{
            style: {
              fontFamily: FontFamily,
              padding: "8.5px 14px",
            },
            maxLength: extMaxLength,
          }}
          InputProps={{ endAdornment: RenderRightContainer || undefined }}
          onChange={(e) =>
            !isNaN(e.target.value) && extHelpers.setValue(e.target.value)
          }
          onInput={(e) => handleInputChange(e, "extension")}
          onFocus={(event) => event.target.select()}
          value={extField.value || ""}
          {...props}
          placeholder={extPlaceholder}
          variant="outlined"
          autoComplete="off"
          error={extTouched && extError !== undefined}
          disabled={disabled}
        />
      </Stack>
      <Stack flexDirection={"row"}>
        <StyledTypography
          sx={{ ...ErroLabelStyle, width: "75%" }}
          component="span"
        >
          {touched && error && error}
        </StyledTypography>
        <StyledTypography
          sx={{ ...ErroLabelStyle, width: "25%" }}
          component="span"
        >
          {extTouched && extError && extError}
        </StyledTypography>
      </Stack>
    </Stack>
  );
};

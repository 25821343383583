import React, { useState } from "react";
import { colors } from "../../../common/Theme/Colors";
import { StyledTypography } from "../../../common/Utils/StyledComponents";
import { convertTo12Hr } from "../../../common/Utils/DateFormater";

const EventCard = ({ event, onCardClick, eventCardWidth }) => {
  const [showCard, setShowCard] = useState(event?.height > 60 ? true : false);
  const [cardHeight, setCardHeight] = useState(event?.height);
  const [cardWidth, setCardWidth] = useState(event?.width);
  const [cardLeftPositions, setCardLeftPosition] = useState(event?.left);
  return (
    <div
      className="cardhover"
      data-class="bg-primary"
      style={{
        position: "absolute",
        left: cardLeftPositions,
        top: event.top,
        width: cardWidth,
        height: cardHeight,
        background: colors.white,
      }}
      onMouseOver={() => {
        event?.height < 60 && setCardHeight(104.2);
        setCardWidth(eventCardWidth);
        event?.left > 0 && setCardLeftPosition(0);
        setTimeout(() => !showCard && setShowCard(true), 330);
      }}
      onMouseLeave={() => {
        setCardWidth(event?.width);
        setCardLeftPosition(event?.left);
        if (event?.height < 60) {
          setCardHeight(event?.height);
          setShowCard(false);
        }
      }}
      /// Commenting below code for maintaining constant
      // onMouseOver={() => {
      //   event?.height < 60 && setCardHeight(104.2);
      //   setTimeout(() => !showCard && setShowCard(true), 330);
      // }}
      // onMouseLeave={() => {
      //   if (event?.height < 40) {
      //     setCardHeight(event?.height);
      //     setShowCard(false);
      //   }
      // }}
      onClick={() => onCardClick?.(event)}>
      <div
        style={{
          marginBottom:
            Number(event?.endTime.split(":")[0]) <= 23 &&
            Number(event?.endTime.split(":")[1]) <= 45
              ? "3px"
              : 0,
          border: `1px solid ${colors.primary}`,
          width: cardWidth,
          height:
            Number(event?.endTime.split(":")[0]) <= 23 &&
            Number(event?.endTime.split(":")[1]) <= 45
              ? cardHeight - 2
              : cardHeight,
          borderLeftWidth: "5px",
          borderLeftColor:
            event?.color !== colors.grey_002 ? event?.color : colors.primary,
          padding: event?.height < 60 ? "2px" : "5px",
        }}>
        <StyledTypography
          variant="subtitle1"
          className="ellipsisEventCard cardhoverText"
          sx={{
            color: colors.primary,
            fontWeight: 500,
            maxWidth: cardWidth - 5,
          }}>
          {event?.subject}
        </StyledTypography>
        {showCard && (
          <>
            <StyledTypography
              variant="subtitle2"
              className="ellipsisEventCard cardhoverText"
              sx={{
                maxWidth: cardWidth - 5,
              }}>
              {event?.description}
            </StyledTypography>
            <StyledTypography
              variant="subtitle2"
              className="ellipsisEventCard cardhoverText"
              sx={{
                color:
                  event?.color === colors.grey_002
                    ? colors.black
                    : event?.color,
                maxWidth: cardWidth - 5,
              }}>
              {convertTo12Hr(event?.start)}-{convertTo12Hr(event?.end)}
            </StyledTypography>
          </>
        )}
      </div>
    </div>
  );
};
export default EventCard;

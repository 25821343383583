export default {
  name: "اسم المالك",
  workEmail: "ايميل العمل",
  dateOfBirth: "تاريخ الميلاد",
  dateOfBirthError: "تاريخ الميلاد حقل مطلوب",
  department: "القسم",
  companyDropdownPlaceholder: "اختر الشركة",
  companyDropdownError: "الشركة حقل مطلوب",
  workEmailPlaceholder: "ايميل العمل",
  workEmailError: "ايميل العمل حقل مطلوب",
  contactNumber: "رقم جهة الإتصال",
  alternate_contactNumber: "رقم جهة اتصال بديل",
  address: "سجل العنوان ",
  // addressError:'Register address is required',
  residenceAddressPlaceholder: "منزل / شقة / قطعة رقم",
  residenceAddressPlaceholder2: "شقة / طريق / منطقة",
  resCountryError: "الدولة حقل مطلوب",
  resStateError: "الولاية/المنطقة مطلوبة",
  workCountryError: "الدولة حقل مطلوب",
  workStateError: "الولاية/المنطقة مطلوبة",
  workAddressError: "عنوان العمل حقل مطلوب",
  departmentError: "القسم حقل مطلوب",
  designation: "الوظيفة",
  uploadPhoto: "حمل الصورة",
  gender: "الجنس",
  workAddress: "عنوان العمل",
  country: "الدولة",
  state: "الولاية/المنطقة",
  city: "المدينة",
  zipcode: "الرمز البريدي",
  add: "يضيف",
  save: "حفظ",
  hrContactError: "مطلوب الاتصال بالموارد البشرية",
  companyPhoneExtensionPlaceholder: "000",
  addMore: "أضف المزيد",
  workAddressPlaceholder: "المبنى/الطريق/المنطقة",
  workAddressPlaceholder2: "العنوان / رقم القطعة",
  nameError: "اسم المالك حقل مطلوب",
  nameError_2: "اسم المالك غير فعّال",
  nameError_3: "يجب أن يتكون اسم المالك من 3 حروف على الأقل",
  nameError_4: "يجب أن يتكون اسم المالك من 100 حرف على الأكثر",
  genderError: "الجنس حقل مطلوب",
  emailError: "الايميل حقل مطلوب",
  numberError_1: "رقم جهة الإتصال غير فعّال",
  numberError_2: "رقم جهة الإتصال حقل مطلوب",
  alternate_numberError_1: "الرقم البديل لجهة الإتصال غير فعّال",
  alternate_numberError_2: "الرقم البديل لجهة الإتصال حقل مطلوب",
  addressError: "العنوان حقل مطلوب",
  designationError: "الوظيفة حقل مطلوب",
  edit: "تعديل التفاصيل",
  alert: "هل أنت متأكد من حذف المالك؟",
  extensionNumber: "الرقم الفرعي للشركة",
  contactNumberPlaceholder: "0000 0000 00",
  hrContact: "جهة اتصال الموارد البشرية",
  hrContactPlaceholder: "0000 0000 00",
  extensionNumberPlaceholder: "0000 0000 00",
  extensionNumberError_1: "الرقم الفرعي غير فعّال",
  extensionNumberError_2: "الرقم الفرعي حقل مطلوب",
  resCityError: "يجب أن تحتوي على الحروف الهجائية فقط",
  resZipCodeError: "يجب أن يكون الحد الأقصى للطول 6",
  workCityError: "يجب أن تحتوي على الحروف الهجائية فقط",
  workZipCodeError: "يجب أن يكون الحد الأقصى للطول 6",
  viewOwner: "عرض المالك",
  alternateMobile: "رقم جهة اتصال بديل",
  editOwner: "تعديل المالك",
  deleteAlertMsg: "هل أنت متأكد أنك تريد حذف هذا المالك؟",
  ageAlert: "يجب أن يكون العمر أكبر من 18 سنة",
  zipcodeError_2: "الرمز البريدي غير فعّال",
  companyError: "الشركة حقل مطلوب",
  reportTo: "المدير المباشر",
  selectComapnyMessage: "الرجاء اختيار شركة واحدة على الأقل",
  image: "صورة",
  file: "ملف",
};

import homePage from "./Home/homePage";
import common from "./common";
import drawer from "./drawer";
import addCompany from "./Home/addCompany";
import addOwner from "./Home/addOwner";
import addManager from "./Home/addManager";
import addTask from "./Home/addTask";
import addEmployee from "./Home/addEmployee";
import manageTask from "./ManageTask/manageTask";
import taskDetails from "./ManageTask/taskDetails";
import calendarPage from "./Calendar/calendarPage";
import createEvent from "./Calendar/createEvent";
import taskDetail from "./Calendar/taskDetail";
import notificationPage from "./Notification/notificationPage";
import messagePage from "./Messages/messagePage";
import group from "./Messages/group";
import viewContact from "./Messages/viewContact";
import inboxPage from "./Inbox/inboxPage";
import accountPage from "./Account/accountPage";
import attendance from "./Account/attendance";
import login from "./Auth/login";
import logout from "./Auth/logout";
import selectCompany from "./Auth/selectCompany";
import forgotPassword from "./Auth/forgotPassword";
import contacts from "./ContactRepository/contacts";
import businessCard from "./ContactRepository/businessCard";
import document from "./DocumentRepository/document";
import assign from "./DocumentRepository/assign";
import vendors from "./vendors_suppliers/vendors";
import filter from "./Filters/filter";
import taskReport from "./TaskReport/taskReport";
import performanceReport from "./PerformanceReport/performanceReport";
import workloadReport from "./WorkloadReport/workloadReport";
import staffReport from "./StaffReport/staffReport";
import permissions from "./Permissions/permissions";
import managersHomePage from "./Home/Manager/managersHomePage";
import pointsEarned from "./PointsEarned/pointsEarned";
import VoiceNotes from "./VoiceNotes/VoiceNotes";
import activityLog from "./ActivityLog/activityLog";
import renewals from "./Renewals/renewals";
import addPersonalAssistant from "./Home/addPersonalAssistant";
import timeZone from "./Timezone/timeZone";
import changePassword from "./Auth/changePassword";
import dashboard from "./Dashboard/dashboard";
import reports from "./Reports/reports";
import momPage from "./Mom/momPage";
import addHrOfficer from "./Home/addHrOfficer";
import project from "./Project";
import customReport from "./CustomReport";
import leaveRequest from "./ManageRequest/leaveRequest";
export default {
  homePage,
  addCompany,
  addOwner,
  addManager,
  addTask,
  manageTask,
  taskDetails,
  calendarPage,
  createEvent,
  taskDetail,
  notificationPage,
  messagePage,
  group,
  viewContact,
  inboxPage,
  addEmployee,
  accountPage,
  login,
  selectCompany,
  forgotPassword,
  contacts,
  businessCard,
  document,
  assign,
  vendors,
  filter,
  taskReport,
  performanceReport,
  workloadReport,
  staffReport,
  permissions,
  managersHomePage,
  attendance,
  pointsEarned,
  VoiceNotes,
  activityLog,
  drawer,
  logout,
  common,
  renewals,
  addPersonalAssistant,
  timeZone,
  changePassword,
  dashboard,
  reports,
  momPage,
  addHrOfficer,
  project,
  customReport,
  leaveRequest,
};

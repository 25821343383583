import React, { useRef, useState, useEffect } from "react";
import { InitialValues } from "../Components/constants";
import { AddRenewalSchema } from "../Components/validations";
import { useNavigate } from "react-router-dom";
import RenewalForm from "../Components/RenewalForm";
import { useAddRenewalDataMutation } from "../../../request/Renewals";
import { showToast } from "../../../common/Utils/ToastMessage";
import { Formik } from "formik";
import {
  formatDateYYYYMMDD,
  formatDateToISOString,
} from "../../../common/Utils/DateFormater";
import Translate from "../../../translation/utils/translateFunction";
import { EncryptData } from "../../../common/Utils/EncryptData";

const AddRenewals = () => {
  const formikRef = useRef(false);
  const navigate = useNavigate();
  const [filesAdded, setFilesAdded] = useState([]);
  const [images, setImages] = useState([]);

  const [
    addRenewalApi,
    {
      data: addRenewalData,
      isLoading: isRenewalLoading,
      isSuccess: isRenewalSuccess,
      isError: isRenewalError,
      error: addRenewalError,
    },
  ] = useAddRenewalDataMutation();

  useEffect(() => {
    if (isRenewalError && addRenewalError) {
      const renewalError = addRenewalError?.data;

      if (!renewalError) {
        showToast({
          message: Translate("common:unknownServerError"),
          type: "error",
        });
      } else {
        renewalError.error.length
          ? renewalError.error.map((errorItem) => {
              if (!InitialValues.hasOwnProperty(errorItem?.param)) {
                return showToast({ message: errorItem.msg, type: "error" });
              }

              return formikRef.current.setFieldError(
                errorItem.param,
                errorItem.msg
              );
            })
          : showToast({
              message:
                renewalError?.message || Translate("common:unknownServerError"),
              type: "error",
            });
      }
    }

    if (isRenewalSuccess && addRenewalData) {
      showToast({
        type: "success",
        message: addRenewalData.message,
      });

      formikRef.current.resetForm();
      setFilesAdded([]);
      setImages([]);
      navigate("/renewals");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addRenewalError, addRenewalData]);

  const onSubmit = (formValues) => {
    const { isNotExpiry, registrationDate, expiryDate } = formValues;

    const reqObj = {
      companyId: formValues?.companyId,
      name: formValues?.name,
      documentCategory: formValues?.documentCategory,
      title: formValues?.title,
      isNotExpiry,
      registrationDate: formatDateYYYYMMDD(registrationDate),
      registrationDateObject: formatDateToISOString(registrationDate),
      webAttachment: [],
    };

    if (!isNotExpiry) {
      reqObj["expiryDate"] = formatDateYYYYMMDD(expiryDate);
      reqObj["expiryDateObject"] = formatDateToISOString(expiryDate);
    }

    let b64Array;
    let typeFile;

    if (filesAdded.length) {
      b64Array = filesAdded[0].b64;
      typeFile = filesAdded[0].type;

      const { webAttachment } = reqObj;

      webAttachment.push({
        url: b64Array,
        type: typeFile,
        renewalsFileExt: filesAdded[0]?.name?.split(".").pop(),
        renewalsFileName: filesAdded[0]?.name,
      });
    }

    addRenewalApi(reqObj);
  };

  return (
    <Formik
      initialValues={InitialValues}
      innerRef={formikRef}
      validateOnMount
      onSubmit={onSubmit}
      validationSchema={AddRenewalSchema}
    >
      {({ values, setFieldValue, handleSubmit }) => {
        return (
          <RenewalForm
            isAdd
            formikRef={formikRef}
            values={values}
            setFieldValue={setFieldValue}
            handleSubmit={handleSubmit}
            isLoading={isRenewalLoading}
            images={images}
            setImages={setImages}
            filesAdded={filesAdded}
            setFilesAdded={setFilesAdded}
          />
        );
      }}
    </Formik>
  );
};

export default AddRenewals;

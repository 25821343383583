import moment from "moment";
import { getMoment } from "./commonFunction";

export function getUtcOffset() {
  const date = getMoment().utc(true).toDate();
  const offsetInHours = date.getTimezoneOffset();
  return offsetInHours;
}

export function formatDateYYYYMMDD(date) {
  const formatedDate = moment(date).format("YYYY-MM-DD");
  return formatedDate;
}
export function formatTimeHHMM(time) {
  const formatedTime = moment(time).format("hh:mm a");
  return formatedTime;
}

export function formatGetTime() {
  const formatedGetTime = getMoment().format("DD");
  return formatedGetTime;
}

export function formatGetMonth() {
  const formateGetMonth = getMoment().format("MM");
  return formateGetMonth;
}

export function formatGetTimeWithValue(date) {
  const formatedGetTime = moment(date).format("DD");
  return formatedGetTime;
}

export function formatGetMonthWithValue(date) {
  const formateGetMonth = moment(date).format("MM");
  return formateGetMonth;
}

export function formatGetYearsDiff(date) {
  const formatedGetYearsDiff =
    Number(getMoment().format("YYYY")) - Number(moment(date).format("YYYY"));
  return formatedGetYearsDiff;
}

export function formatDiffDuration(todaysDate, date) {
  const formatedDiffDuration = moment
    .duration(Number(todaysDate) - Number(new Date(date)))
    .years();
  return formatedDiffDuration;
}

export function formatGetExpiryDate(date) {
  const formatedGetExpiryDate = moment(date).add(1, "days").toDate();
  return formatedGetExpiryDate;
}
export function getYear() {
  const formatedgetYear = getMoment().year();
  return formatedgetYear;
}

export function convertTo12Hr(date) {
  const formated12HourTime = moment(date).format("hh:mm A");
  return formated12HourTime;
}

export function convertDateFormat(date) {
  const formated = moment(date, "YYYY-MM-DD").format("MMM DD, YYYY;");
  return formated;
}
export function isAfter(date) {
  const formatedTime = moment(date).utc().isAfter(getMoment().toDate());
  return formatedTime;
}

export function parsePriceTwoDigit(price) {
  const formatedPrice = parseFloat(price).toFixed(2);
  return formatedPrice;
}

export function dateToFromNowDaily(myDate) {
  const fromNow = moment(myDate).utc().fromNow();

  return moment(myDate)
    .utc()
    .calendar(null, {
      lastWeek: "[] dddd",
      lastDay: "[Yesterday]",
      sameDay: "hh:mm a",
      nextDay: "[Tomorrow]",
      nextWeek: "dddd",
      sameElse: function () {
        return "[" + fromNow + "]";
      },
    });
}

export function formatTimeTo12Hour(time) {
  return time ? moment(time, "HH:mm").format("hh:mm A") : "";
}

export const UpdateTime = (time) => {
  const minutes = Math.floor(time / 60);
  const seconds = Math.floor(time - minutes * 60);
  const unit = minutes >= 1 ? "min" : "sec";
  const formattedTime =
    str_pad_left(minutes, "0", 2) +
    ":" +
    str_pad_left(seconds, "0", 2) +
    " " +
    unit;

  return formattedTime;
};
const str_pad_left = (string, pad, length) => {
  return (new Array(length + 1).join(pad) + string).slice(-length);
};

export const convertDate = (prevTime, currentDate) => {
  let dueTime = moment(prevTime).utc().format("hh:mm");
  let date = moment(currentDate);
  return date.utc().format(`YYYY-MM-DDT${dueTime}[Z]`);
};
export const getTZFormatDate = (date) => {
  return moment(date).format("YYYY-MM-DDThh:mm:ss[Z]");
};
export const monthDayYear = (date) => {
  return moment(date).format("MMM D,YYYY");
};
export const daybeforeToday = (date) => {
  return moment(date).subtract(1, "day").format("MMM D,YYYY");
};

export const formatDateByToday = (date) => {
  const today = getMoment().startOf("day");
  const inputDate = moment(date);

  if (today.isSame(inputDate, "day")) {
    return "Today";
  }

  return inputDate.format("MMM D, YYYY");
};

export const formatDateToISOString = (date) => {
  return moment(date).toISOString();
};

export const formatDateYYYYMMDDWithSlash = (date) => {
  return moment(date).format("YYYY/MM/dd");
};
export const formatFullMonthYear = (date) => {
  return moment(date).format(DateTimeFormats.longMonthYear);
};
export const DateTimeFormats = {
  MonthDateYear: "MMM dd, yyyy",
  YearMonthDay: "YYYY-MM-DD",
  DayMonthYear: "DD-MM-YYYY",
  Time: "HH:mm",
  TimeAMPM: "hh:mm A",
  MonthDate: "MMM DD",
  ShortMonthDateYear: "MM DD, YYYY",
  MonthDateYear2: "MMM dd, yyyy",
  MonthSingleDateYear: "MMM D, YYYY",
  minutesSeconds: "mm:ss",
  monthYear: "MMM, YYYY",
  year: "YYYY",
  longMonth: "MMMM",
  shortMonth: "MMM",
  Today: "Today",
  TZformat: "yyyy-mm-DDThh:mm:ss.s+zzzzzz",
  longMonthYear: "MMMM, yyyy",
  dayMonthYearBySlash: "DD/MM/YYYY",
  calendar_format: "YYYY-MM-DDTHH:mm:00",
};
export const formatDateWithTime = (inputDate) => {
  return moment(inputDate).format("MMM DD, YYYY, h:mm A");
};
export const convertDateFormatWithMonth = (date) => {
  const formated = moment(date, "YYYY-MM-DD").format("MMM DD, YYYY");
  return formated;
};

export const formatTime = (inputDate) => {
  return moment(inputDate).format("h:mm A");
};

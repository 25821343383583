import { Stack } from "@mui/material";
import React, { memo } from "react";
import {
  daybeforeToday,
  formatGetExpiryDate,
  monthDayYear,
} from "../../../common/Utils/DateFormater";
import { StyledTypography } from "../../../common/Utils/StyledComponents";
import { Persona } from "../../Persona";
import { RenderMessageContent } from "../MessageDetail/utils";
import Translate from "../../../translation/utils/translateFunction";
import { useSelector } from "react-redux";
import { colors } from "../../../common/Theme/Colors";
import IconifyIcon from "../../Icon";
import TextWrapWithTooltip from "../../TextWrapWithTooltip";

const MessageItem = ({
  item,
  onClick,
  selectedUser,
  isNewChat,
  className,
  isGroup,
  isTooltip,
}) => {
  const { userData, isRtl } = useSelector((state) => state.formanagement);
  const getTime = () => {
    const { formattedDate, formattedTime } = item;
    const time =
      formattedDate === monthDayYear()
        ? formattedTime
        : formattedDate === monthDayYear(daybeforeToday())
        ? Translate("common:yesterday")
        : formattedDate;
    return time;
  };

  const getLastMessageType = () => {
    if (item?.lastMessage?.isDeleted) {
      return Translate("messagePage:deleteMessage");
    } else if (!item?.lastMessage?.isAttachment && item?.lastMessage?.message) {
      if (isGroup) return RenderMessageContent(item?.lastMessage?.message);
      return item?.lastMessage?.message;
    } else if (item?.lastMessage?.isAttachment) {
      switch (item.lastMessage.messageType) {
        case "image/png":
        case "image/heif":
        case "image/heic":
        case "image/jpeg":
          return "Image";
        case "image/gif":
          return "GIF";
        case "mp4":
          return "Video";
        case "mp3":
          return "Audio";
        case "application/pdf":
          return "PDF";
        case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        case "application/vnd.ms-excel":
          return "Attachment";
        case "doc":
        case "csv":
          return "Document";
        default:
          return "Attachment";
      }
    }
    return Translate("messagePage:startConversationMesage");
  };

  return (
    <li
      className={`online${onClick ? " cursor-pointer" : ""} ${className}`}
      onClick={() => onClick?.(item)}
      style={{
        background: selectedUser === item?._id ? "#d8dee36e" : "#f8f9fa",
      }}
    >
      <div
        className="media"
        style={{
          alignItems: isNewChat ? "center" : "flex-start",
          width: "100%",
        }}
      >
        <Persona
          name={item?.username || item?.name || item?.groupName}
          image={item?.userImage || item?.profileUrl || item?.groupImage}
          size={40}
        />
        <div
          className="media-body ml-1"
          style={{ marginRight: isRtl && "4px" }}
        >
          <Stack flexDirection={"row"} justifyContent={"space-between"}>
            <Stack maxWidth={"75%"}>
              {isTooltip ? (
                <TextWrapWithTooltip
                  customClassName="name chatGroupItem"
                  label={item?.username || item?.name || item?.groupName}
                  textVariant="span"
                />
              ) : (
                <span className="name ellipsisStyle">
                  {item?.username || item?.name || item?.groupName}
                </span>
              )}
              {!isNewChat && (
                <Stack flexDirection={"row"}>
                  {item?.lastMessage &&
                    item?.lastMessage.user === userData?._id && (
                      <IconifyIcon
                        icon="charm:tick-double"
                        fontSize="20"
                        style={{ marginRight: "5px" }}
                        color={item?.isSeen ? colors.blue_001 : colors.grey_003}
                      />
                    )}
                  <span
                    className="message ellipsisStyle"
                    style={{
                      fontStyle: item?.lastMessage?.isDeleted && "italic",
                    }}
                  >
                    {getLastMessageType()}
                  </span>
                </Stack>
              )}
            </Stack>
            {!isNewChat && (
              <Stack alignItems={"flex-end"}>
                <Stack maxWidth={"80px"}>
                  <span
                    className={`message leftDirection ${
                      !item?.messageUnseenCount ? "ellipsis" : ""
                    }`}
                  >
                    {getTime()}
                  </span>
                </Stack>
                {!!item?.messageUnseenCount && (
                  <Stack
                    className="chatMessageBadgeContainer"
                    marginLeft={"5px"}
                  >
                    <StyledTypography
                      variant="subtitle1"
                      className="chatMessageBadgeStyle"
                    >
                      {item?.messageUnseenCount || 1}
                    </StyledTypography>
                  </Stack>
                )}
              </Stack>
            )}
          </Stack>
          {/* <span className="badge badge-outline status" /> */}
        </div>
      </div>
    </li>
  );
};
export default memo(MessageItem);

import Translate from "../../../../translation/utils/translateFunction";

export const getViewSharedListColumns = (customCellRender) => {
  const columns = [
    {
      field: "_id",
      align: "center",
      headerAlign: "center",
      headerName: Translate("contacts:srNo"),
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "name",
      headerName: Translate("contacts:userName"),
      flex: 0.5,
      sortable: true,
      disableColumnMenu: true,
      renderCell: customCellRender,
    },
    {
      field: "mobile",
      headerName: Translate("contacts:mobile"),
      flex: 0.4,
      sortable: true,
      disableColumnMenu: true,
    },
    {
      field: "role",
      headerName: Translate("contacts:role"),
      flex: 0.4,
      sortable: true,
      disableColumnMenu: true,
    },
  ];
  return columns;
};

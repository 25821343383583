export const InitialValues = {
  companyId: null,
  subject: null,
  description: null,
  timeZone: null,
  fromDate: null,
  toDate: null,
  startTime: null,
  endtime: null,
  repeatEvent: "DO_NOT_REPEAT",
  reminder: "15_MINUTES_BEFORE",
  venu: null,
};
export const InitialEventRecurrence = {
  repeatEveryNumber: "",
  repeatEvery: "WEEK",
  repeatWeek: {
    sunday: false,
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: true,
    friday: false,
    saturday: false,
  },
  selectedDay: "Sun",
  repeatFrom: "",
  repeatFromUTCObject: "",
  repeatEnd: {
    never: true,
    on: "",
    occurance: "",
  },
  repeatEndSelected: "Never",
  pickedDate: "",
};

import { Methods, Urls } from "../../common/Utils/ApiConstants";
import { CreateApi } from "../CreateApi";

const CmsCollection = CreateApi.injectEndpoints({
  endpoints: (builder) => ({
    getPrivacyDetails: builder.mutation({
      query: (body) => {
        return {
          url: Urls.getPrivacyDetails,
          method: Methods.post,
          body: body,
        };
      },
    }),
    getTermsAndConditionDetails: builder.mutation({
      query: (body) => {
        return {
          url: Urls.getTermsAndConditionDetails,
          method: Methods.post,
          body: body,
        };
      },
    }),
    getAboutUsDetails: builder.mutation({
      query: (body) => {
        return {
          url: Urls.getAboutUsDetails,
          method: Methods.post,
          body: body,
        };
      },
    }),
  }),
});

export const {
  useGetPrivacyDetailsMutation,
  useGetTermsAndConditionDetailsMutation,
  useGetAboutUsDetailsMutation,
} = CmsCollection;

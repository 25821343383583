import React, { useEffect, useRef, useState } from "react";
import Waveform from "react-audio-waveform";
import { colors } from "../../../common/Theme/Colors";
import { useSelector } from "react-redux";

const WaveFormItem = ({
  url,
  voiceNoteDate,
  isShared,
  attachmentUrl,
  isVoiceNotesList = true,
  onVoiceNotePlay,
  params,
  currentVoiceNote,
}) => {
  const { isRtl } = useSelector((state) => state.formanagement);
  const audioTrackRef = useRef(undefined);
  const [play, setPlay] = useState(false);
  const [stateUpdater, setStateUpdater] = useState(0);

  const updateSong = (second) => {
    if (audioTrackRef.current) {
      audioTrackRef.current.currentTime = second;
    }
  };

  useEffect(() => {
    if (currentVoiceNote) {
      if (currentVoiceNote !== params) {
        setPlay(false);
      }
    }
  }, [currentVoiceNote]);

  const UpdateTime = (time) => {
    if (!time) return "00:00";

    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time - minutes * 60);
    const currentTime =
      str_pad_left(minutes, "0", 2) + ":" + str_pad_left(seconds, "0", 2);

    return currentTime;
  };
  const str_pad_left = (string, pad, length) => {
    return (new Array(length + 1).join(pad) + string).slice(-length);
  };
  const onTimeChange = () => {
    if (
      audioTrackRef.current?.currentTime === audioTrackRef.current?.duration
    ) {
      setTimeout(() => {
        audioTrackRef.current.load();
        setPlay(false);
      }, 500);
    }
  };

  return (
    <div key={`${url?.timeLength}`}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          padding: 5,
        }}
      >
        <div
          className={` ${isRtl ? "rtl sharedVoiceNoteRtl" : "sharedVoiceNote"} 
      `}
        >
          {/* <i
      `}>
          <i
            style={{ fontSize: 30 }}
            className={
              play
                ? "fa fa-pause-circle-o manageIconColor cursor-pointer"
                : "fa fa-play-circle-o manageIconColor cursor-pointer"
            }
            onClick={() => {
              setPlay(!play);
              let temp = stateUpdater;
              let intervalId;

              if (!audioTrackRef.current?.paused) {
                clearInterval(intervalId);
                audioTrackRef.current.pause();
              } else {
                intervalId = setInterval(() => {
                  temp = temp + 1;
                  setStateUpdater(temp);
                  if (
                    audioTrackRef.current?.duration ===
                    audioTrackRef.current?.currentTime
                  ) {
                    clearInterval(intervalId);
                  }
                }, 300);

                if (!isVoiceNotesList) {
                  onVoiceNotePlay && onVoiceNotePlay(true);
                }

                audioTrackRef.current.play();
              }
            }}
          /> */}
          <i
            style={{ fontSize: 30 }}
            className={
              play
                ? "fa fa-pause-circle-o manageIconColor cursor-pointer"
                : "fa fa-play-circle-o manageIconColor cursor-pointer"
            }
            onClick={() => {
              const audioElements = document.querySelectorAll("audio");

              audioElements.forEach((audio) => {
                if (!audio.paused) {
                  audio.pause();
                }
              });

              setPlay(!play);

              if (!play) {
                audioTrackRef.current.play();

                let temp = stateUpdater;
                let intervalId;

                intervalId = setInterval(() => {
                  temp = temp + 1;
                  setStateUpdater(temp);
                  if (
                    audioTrackRef.current?.duration ===
                    audioTrackRef.current?.currentTime
                  ) {
                    clearInterval(intervalId);
                  }
                }, 300);

                if (!isVoiceNotesList) {
                  onVoiceNotePlay && onVoiceNotePlay(true);
                }
              } else {
                audioTrackRef.current.pause();
              }
            }}
          />

          {/* {isShared && <i className="fa fa-share-alt shareIcon" />} */}
          {isShared && (
            <i
              className={`fa fa-share-alt ${
                isRtl ? "rtl shareIconRtl" : "shareIcon"
              } 
          `}
            />
          )}
        </div>
        <div id="waveform">
          <Waveform
            barWidth={1}
            peaks={url?.buffer || url?.byteCode}
            height={50}
            pos={audioTrackRef.current?.currentTime}
            duration={url?.timeLength / 1000}
            onClick={(second) => updateSong(second)}
            color={colors.grey_004}
            progressColor={
              audioTrackRef.current ? colors.primary : colors.grey_004
            }
            transitionDuration={300}
            progressGradientColors={[
              [0, audioTrackRef.current ? colors.primary : colors.grey_004],
              [1, audioTrackRef.current ? colors.primary : colors.grey_004],
            ]}
          />
        </div>
      </div>
      <audio
        ref={audioTrackRef}
        src={attachmentUrl || url?.src}
        onTimeUpdate={onTimeChange}
      />
      {isVoiceNotesList && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",

            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span style={{ marginLeft: 45 }}>{voiceNoteDate}</span>

          <div style={{ paddingRight: 10 }}>
            <span id="time-total">
              {audioTrackRef?.current
                ? UpdateTime(Math.round(audioTrackRef?.current?.currentTime))
                : UpdateTime(0)}
            </span>
            <span>{` / `}</span>
            <span>
              {url?.timeLengthInSec
                ? UpdateTime(Number(url?.timeLengthInSec))
                : UpdateTime(Math.round(url.timeLength / 1000))}
            </span>
          </div>
        </div>
      )}
    </div>
  );
};
export default WaveFormItem;

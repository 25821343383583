import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { formatTimeHHMM, monthDayYear } from "../../common/Utils/DateFormater";
import {
  emitSeen,
  initiateSocket,
  startSocket,
  subscribeToChannel,
  unSubscribeToChannel,
} from "../../common/Utils/socket";
import {
  useAddChatMutation,
  useMessageListMutation,
  useGroupMessageListMutation,
} from "../../request/Message";
import { setActiveChatIdAction } from "../../store/Reducer";
import ChatScreen from "./Chats";
import MessageHeader from "./Components/MesaageHeader";
import MessageDetail from "./MessageDetail";
import GroupScreen from "./Groups";
import { useLocation } from "react-router-dom";
import IconifyIcon from "../Icon";
import InitialScreen from "./InitialLandingScreen";
import Translate from "../../translation/utils/translateFunction";
import { tabType, tabTypes } from "./Components/constants";

const { CHAT_LIST, GROUP_LIST } = tabType;

const MessageScreen = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { userData, isRtl } = useSelector((state) => state.formanagement);
  const [selectedTab, setSelectedTab] = useState(
    state?.data?.groupId?.length || state?.data?.createGroupFromTask
      ? GROUP_LIST
      : CHAT_LIST
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [currentGroupPage, setCurrentGroupPage] = useState(1);
  const [currentDetailPage, setCurrentDetailPage] = useState(1);
  const [newChatCurrentPage, setNewChatCurrentPage] = useState(1);
  const [selectedUser, setSelectedUser] = useState(state?.data);
  const [messageDetailData, setMessageDetailData] = useState([]);
  const [searchText, setSearchText] = useState();
  const isMessageLastPage = useRef(false);
  const [isNewChatList, setIsNewChatList] = useState(false);
  const [isNewGroupList, setIsNewGroupList] = useState(false);
  const [socketMsg, setSocketMsg] = useState();
  const [checkedGroupMembers, setCheckedGroupMembers] = useState([]);
  const [isAddGroup, setIsAddGroup] = useState(false);
  const [groupDetails, setGroupDetails] = useState("");
  const [updatedIcon, setUpdateIcon] = useState(false);
  const [updatedGroupName, setUpdateGroupName] = useState(false);
  const [deleteGroupIcon, setDeleteGroupIcon] = useState(false);
  const [isMsgAdded, setIsMsgAdded] = useState(false);
  /********************************************************Api Hooks Declaration************************************************************/
  const [
    getMessages,
    { data: serverResponse, isSuccess: isMessageSuccess, isLoading },
  ] = useMessageListMutation();

  const [
    getGroupMessages,
    {
      data: groupMessageList,
      isSuccess: isGroupMessageSuccess,
      isLoading: isGroupMessageLoading,
    },
  ] = useGroupMessageListMutation();

  const [
    addChat,
    { data: serverResponseAddToChat, isSuccess: isAddToChatSuccess },
  ] = useAddChatMutation();

  /********************************************************common Functions  ************************************************************/
  const showNewChat = () => setIsNewChatList(true);
  const hideNewChat = () => setIsNewChatList(false);

  const hideNewGroup = () => setIsNewGroupList(false);

  const showAddGroup = () => setIsAddGroup(true);
  const hideAddGroup = () => setIsAddGroup(false);

  const socketMessages = (res) => {
    const message = JSON.parse(res?.replace(/,$/, ""));
    setSocketMsg(message);
  };

  const goBackToChatTab = () => {
    if (selectedTab === CHAT_LIST) {
      hideNewChat();
      setCurrentPage(1);
    } else if (selectedTab === GROUP_LIST) {
      hideAddGroup();
      hideNewGroup();
      setCheckedGroupMembers([]);
      setCurrentGroupPage(1);
    }
    setSelectedUser("");
    setSearchText();
    setSelectedTab(selectedTab);
  };

  const handleAddGroupMember = () => {
    getGroupMessages(messageRequestObj);
  };

  const handleSelectGroup = (item) => {
    setSelectedTab(GROUP_LIST);
    onChatClick(item);
  };

  const handleMessageFromGroup = (item) => {
    setSelectedTab(CHAT_LIST);
    setGroupDetails("");
    onChatClick(item);
  };

  const onAddNewChatClick = () => {
    setSearchText();
    setNewChatCurrentPage(1);
    showNewChat();
  };

  const onAddNewGroupClick = () => {
    setSelectedUser("");
    setSearchText();
    setNewChatCurrentPage(1);
    setIsNewGroupList(true);
  };

  useEffect(() => {
    if (state?.data?.createGroupFromTask) {
      onAddNewGroupClick();
      setCheckedGroupMembers(state?.data?.groupUsers);
      showAddGroup();
    }

    if (state?.data?.isMessageRoute) {
      setSelectedTab(
        state?.data?.groupId?.length || state?.data?.createGroupFromTask
          ? GROUP_LIST
          : CHAT_LIST
      );
      setSelectedUser(state?.data);
    }
  }, [state]);

  const onSearch = (value) => {
    setCurrentPage(1);
    setCurrentGroupPage(1);
    setNewChatCurrentPage(1);
    setSearchText(value);
  };

  const onChatClick = (item) => {
    if (selectedUser !== item) {
      setMessageDetailData([]);
      setCurrentDetailPage(1);
      setSelectedUser(item);
    }
  };

  useEffect(() => {
    startSocket();
    initiateSocket("Mobile Socket started.");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedUser?._id !== "") {
      subscribeToChannel(
        socketMessages,
        isNewChatList || selectedUser?.fromGroup
          ? selectedUser?.chatId || serverResponseAddToChat?.data?._id
          : selectedUser?._id
      );
      dispatch(
        setActiveChatIdAction(
          isNewChatList || selectedUser?.fromGroup
            ? selectedUser?.chatId || serverResponseAddToChat?.data?._id
            : selectedUser?._id
        )
      );
    } else {
      unSubscribeToChannel(
        isNewChatList || selectedUser?.fromGroup
          ? selectedUser?.chatId || serverResponseAddToChat?.data?._id
          : selectedUser?._id
      );
      dispatch(setActiveChatIdAction(undefined));
    }
    return () => {
      unSubscribeToChannel(
        isNewChatList || selectedUser?.fromGroup
          ? selectedUser?.chatId || serverResponseAddToChat?.data?._id
          : selectedUser?._id
      );
      dispatch(setActiveChatIdAction(undefined));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUser, isAddToChatSuccess]);

  /********************************************************Api hooks result useeffect************************************************************/
  useEffect(() => {
    if (isMessageSuccess && serverResponse?.data) {
      const { record, pageInfo } = serverResponse?.data;
      currentDetailPage !== 1
        ? setMessageDetailData((prev) => record?.concat(prev))
        : setMessageDetailData(record);
      isMessageLastPage.current = pageInfo?.hasNextPage;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMessageSuccess]);

  useEffect(() => {
    if (isGroupMessageSuccess && groupMessageList?.data) {
      const { record, pageInfo } = groupMessageList?.data;
      setGroupDetails(groupMessageList?.data);

      const messages = record?.toReversed();

      currentDetailPage !== 1
        ? setMessageDetailData((prev) => messages?.concat(prev))
        : setMessageDetailData(messages);
      isMessageLastPage.current = pageInfo?.hasNextPage;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGroupMessageSuccess]);

  /********************************************************Api request obj ************************************************************************/
  const messageRequestObj = useMemo(() => {
    const reqId = selectedUser?.chatId || selectedUser?._id;

    const req = {
      pageNo: currentDetailPage,
    };

    if (selectedTab === GROUP_LIST) {
      req["groupId"] = reqId;
    } else if (selectedTab === CHAT_LIST) {
      req["chatId"] = reqId;
    }
    return req;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUser, currentDetailPage]);

  const addChatRequest = useMemo(() => {
    return {
      chatWith: selectedUser?._id,
    };
  }, [selectedUser]);

  /********************************************************Api call*********************************************************************************/
  useEffect(() => {
    if (
      (addChatRequest && isNewChatList) ||
      (addChatRequest && selectedUser?.isNewChatFromGroup)
    ) {
      !selectedUser?.chatId && addChat(addChatRequest);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addChatRequest]);

  useEffect(() => {
    if (
      tabTypes.includes(selectedTab) &&
      (selectedUser?._id || selectedUser?.chatId)
    ) {
      selectedTab === CHAT_LIST && getMessages(messageRequestObj);
      selectedTab === GROUP_LIST && getGroupMessages(messageRequestObj);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageRequestObj, selectedUser]);

  useEffect(() => {
    if (socketMsg) {
      const obj = {
        _id: socketMsg.messageId,
        message: socketMsg.message,
        isAttachment: socketMsg.isAttachment === "true" ? true : false,
        attachmentUrl: socketMsg.attachmentUrl || "",
        attachmentUrlDetails: socketMsg?.attachmentUrlDetails,
        messageType: socketMsg.messageType,
        senderId: socketMsg.user,
        recieverId: "",
        senderRole: "",
        createdAt: socketMsg.createdAt,
        updatedAt: socketMsg.createdAt,
        isOwn: userData?._id === socketMsg.user ? true : false,
        formattedTime: socketMsg?.formattedTime,
        formattedDate: socketMsg?.formattedDate,
        senderName: socketMsg.name || "",
        senderProfile: socketMsg.profileUrl || "",
        size: socketMsg.size,
        voiceNote: socketMsg?.voiceNote,
      };
      let tempArr = [...messageDetailData];
      tempArr.push(obj);
      setMessageDetailData(tempArr);
      setIsMsgAdded(true);
      if (userData?._id !== socketMsg.user) {
        emitSeen(
          isNewChatList || selectedUser?.fromGroup
            ? selectedUser?.chatId || serverResponseAddToChat?.data?._id
            : state?.isNotification
            ? selectedUser?.chatId
            : selectedUser?._id,
          userData?._id,
          selectedTab
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socketMsg]);

  const handleSelectTab = (type) => {
    setSelectedUser("");
    if (type === CHAT_LIST) {
      setCurrentPage(1);
    } else {
      setCurrentGroupPage(1);
    }
    setSelectedTab(type);
    setGroupDetails("");
  };

  const handleDeleteMsg = (messageId) => {
    const tempMessages = messageDetailData?.map((item) =>
      messageId === item?._id ? { ...item, isDeleted: true } : item
    );

    setMessageDetailData(tempMessages);
  };

  /********************************************************Return ****************************************************************************************/
  return (
    <>
      <div
        className={`section-light chat_app ${
          isRtl ? "rtlMargin" : "ltrMargin"
        }`}
        style={{ marginTop: isRtl && "-42px" }}
      >
        {selectedUser && !selectedUser?.createGroupFromTask ? (
          <MessageDetail
            selectedChat={selectedUser}
            messageDetail={messageDetailData}
            type={selectedTab}
            isLoading={isLoading || isGroupMessageLoading}
            hasNextPage={isMessageLastPage.current}
            isCreateScreen={isNewChatList}
            addToChatData={serverResponseAddToChat?.data}
            groupDetails={groupDetails}
            onClickLoadMore={() => setCurrentDetailPage((prev) => prev + 1)}
            onDeleteMessage={handleDeleteMsg}
            onClickForAddMember={handleAddGroupMember}
            onClickForSelectGroup={handleSelectGroup}
            onGroupIconUpdate={(event) => {
              if (event) {
                getGroupMessages(messageRequestObj);
                setUpdateIcon(true);
              }
            }}
            onGroupIconDelete={(event) => {
              if (event) {
                getGroupMessages(messageRequestObj);
                setDeleteGroupIcon(true);
              }
            }}
            onSuccessFullEdit={(event) => {
              if (event) {
                getGroupMessages(messageRequestObj);
                setUpdateGroupName(true);
              }
            }}
            onMessageFromGroup={handleMessageFromGroup}
            isMsgAdded={isMsgAdded}
            handleMsgAdded={setIsMsgAdded}
          />
        ) : (
          <InitialScreen />
        )}
        <div
          className="chat_list section-white"
          id="users"
          style={{
            marginLeft: !isRtl && "20px",
            marginBottom: "1.2%",
            width: "25%",
          }}
        >
          <MessageHeader
            selectedTab={selectedTab}
            handleSelectTab={handleSelectTab}
            setSearchText={onSearch}
            searchText={searchText}
            onAddNewChat={onAddNewChatClick}
            onAddNewGroup={onAddNewGroupClick}
            onGoBack={goBackToChatTab}
            isNewChat={isNewChatList}
            isNewGroup={isNewGroupList}
            isAddGroup={isAddGroup}
          />

          {
            <div
              className="tab-content"
              style={{
                height: "calc(100vh - 190px)",
                overflowY: "auto",
              }}
            >
              {selectedTab === CHAT_LIST || isNewChatList ? (
                <ChatScreen
                  searchText={searchText?.trim()}
                  selectedUser={selectedUser?._id || selectedUser?.chatId}
                  onClick={onChatClick}
                  isNewChat={isNewChatList}
                  selectedTab={selectedTab}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  newChatCurrentPage={newChatCurrentPage}
                  setNewChatCurrentPage={setNewChatCurrentPage}
                />
              ) : (
                <GroupScreen
                  searchText={searchText?.trim()}
                  selectedUser={selectedUser?._id}
                  onClick={onChatClick}
                  isNewGroup={isNewGroupList}
                  selectedTab={selectedTab}
                  currentPage={currentGroupPage}
                  setCurrentPage={setCurrentGroupPage}
                  userData={userData}
                  newChatCurrentPage={newChatCurrentPage}
                  setNewChatCurrentPage={setNewChatCurrentPage}
                  checkedGroupMembers={checkedGroupMembers}
                  setCheckedGroupMembers={setCheckedGroupMembers}
                  isAddGroup={isAddGroup}
                  goBackClick={goBackToChatTab}
                  onIconUpdate={updatedIcon}
                  onGroupNameEdit={updatedGroupName}
                  onGroupIconDelete={deleteGroupIcon}
                  onSuccessfullIconGroupName={(event) => {
                    if (event) {
                      setUpdateIcon(false);
                    }
                  }}
                  onSuccessfullEditGroupName={(event) => {
                    if (event) {
                      setUpdateGroupName(false);
                    }
                  }}
                  onSuccessfullDeleteGroupImage={(event) => {
                    if (event) {
                      setDeleteGroupIcon(false);
                    }
                  }}
                />
              )}
            </div>
          }
          {isNewGroupList && checkedGroupMembers?.length > 1 && !isAddGroup && (
            <span data-toggle="tooltip" title={Translate("common:nextToolTip")}>
              <IconifyIcon
                onClick={showAddGroup}
                className="addGroupIcon"
                icon="bi:arrow-right-circle-fill"
              />
            </span>
          )}
        </div>
      </div>
    </>
  );
};

export default MessageScreen;

import { Methods, Urls } from "../../../common/Utils/ApiConstants";
import { CreateApi } from "../../CreateApi";

const personalAssistantCollection = CreateApi.injectEndpoints({
  endpoints: (builder) => ({
    getPersonalAssistantListing: builder.mutation({
      query: ({ requestBody, pageNo }) => {
        return {
          url: Urls.getPersonalAssistantList,
          method: Methods.post,
          body: requestBody,
          params: { pageNo },
        };
      },
    }),
    addPersonalAssistantData: builder.mutation({
      query: (body) => {
        return {
          url: Urls.addPersonalAssistant,
          method: Methods.post,
          body: body,
        };
      },
    }),
    viewPersonalAssistantData: builder.mutation({
      query: (body) => {
        return {
          url: `${Urls.viewPersonalAssistant}${body}`,
          method: Methods.get,
        };
      },
    }),
    editPersonalAssistantData: builder.mutation({
      query: (body) => {
        return {
          url: `${Urls.editPersonalAssistant}${body?.id}`,
          method: Methods.put,
          body: body?.body,
        };
      },
    }),
    deletePersonalAssistantData: builder.mutation({
      query: (body) => {
        return {
          url: `${Urls.deletePersonalAssistant}${body}`,
          method: Methods.delete,
        };
      },
    }),
    ratePersonalAssistant: builder.mutation({
      query: (body) => {
        return {
          url: `${Urls.ratePersonalAssistant}${body.id}`,
          method: Methods.put,
          body: body?.body,
        };
      },
    }),
  }),
});

export const {
  useGetPersonalAssistantListingMutation,
  useAddPersonalAssistantDataMutation,
  useViewPersonalAssistantDataMutation,
  useEditPersonalAssistantDataMutation,
  useDeletePersonalAssistantDataMutation,
  useRatePersonalAssistantMutation,
} = personalAssistantCollection;

import { Stack } from "@mui/material";
import React from "react";
import { colors } from "../../../../common/Theme/Colors";
import WaveFormItem from "../../../VoiceNotes/Components/WaveForm";

export const AudioMessageView = ({ currentMessage }) => {
  const voiceNote = currentMessage?.voiceNote;

  return (
    <Stack style={styles.audioView} marginRight={1}>
      <Stack flexDirection={"row"} alignItems="space-between">
        <Stack flexDirection={"row"} style={styles.waveView}>
          <Stack style={styles.viewAudio}>
            <Stack flexDirection={"row"}>
              <WaveFormItem
                attachmentUrl={currentMessage?.attachmentUrl}
                url={voiceNote}
                voiceNoteDate={currentMessage?.formattedTime}
              />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
const styles = {
  audioView: {
    backgroundColor: colors.grey_008,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    borderBottomLeftRadius: 6,
  },
  waveView: { marginTop: 10, marginLeft: 8 },
  fileIconAudio: {
    marginLeft: 10,
  },
  viewAudio: {
    marginLeft: 10,
    marginTop: 5,
  },
};

import Translate from "../../../translation/utils/translateFunction";

export const allEvents = [
  {
    label: Translate("createEvent:eventRepeatOption1"),
    value: "DO_NOT_REPEAT",
  },
  { label: Translate("createEvent:eventRepeatOption2"), value: "DAY" },
  { label: Translate("createEvent:eventRepeatOption3"), value: "WEEK" },
  { label: Translate("createEvent:eventRepeatOption4"), value: "MONTH" },
  { label: Translate("createEvent:eventRepeatOption5"), value: "YEAR" },
  { label: Translate("createEvent:eventRepeatOption6"), value: "CUSTOM" },
];
export const allReminders = [
  // {label: '5 minutes before', value: '5 minutes before'},
  {
    label: Translate("createEvent:eventReminder1"),
    value: "15_MINUTES_BEFORE",
  },
  {
    label: Translate("createEvent:eventReminder2"),
    value: "30_MINUTES_BEFORE",
  },
  { label: Translate("createEvent:eventReminder3"), value: "1_HOUR_BEFORE" },
  { label: Translate("createEvent:eventReminder4"), value: "1_DAY_BEFORE" },
  // {label: 'Custom', value: 'CUSTOM'},
];
export const allRepeatData = [
  { label: Translate("calendarPage:week"), value: "WEEK" },
  { label: Translate("calendarPage:month"), value: "MONTH" },
  { label: Translate("calendarPage:year"), value: "YEAR" },
];
export const daysArray = [
  { id: 1, label: "S", value: "Sun" },
  { id: 2, label: "M", value: "Mon" },
  { id: 3, label: "T", value: "Tue" },
  { id: 4, label: "W", value: "Wed" },
  { id: 5, label: "T", value: "Thr" },
  { id: 6, label: "F", value: "Fri" },
  { id: 7, label: "S", value: "Sat" },
];
export const timeZone = [{ value: "kolkata-india", label: "Kolkata india" }];

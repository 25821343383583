import { CreateApi } from "../../CreateApi";
import { Methods, Urls } from "../../../common/Utils/ApiConstants";
const pinnedTaskcollection = CreateApi.injectEndpoints({
  endpoints: (builder) => ({
    getPinnedTaskData: builder.query({
      query: (pageNo) => {
        return {
          url: Urls.getPinnedTaskList,
          method: Methods.get,
          params: { pageNo },
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
    }),
  }),
});
export const { useLazyGetPinnedTaskDataQuery } = pinnedTaskcollection;

import { Stack } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useField } from "formik";
import React from "react";
import {
  FontFamily,
  StyledTypography,
  ErroLabelStyle
} from "../../../common/Utils/StyledComponents";

export const disableColor = {
  "& .MuiInputBase-input.Mui-disabled": {
    WebkitTextFillColor: "#33303cad",
    background: "#e5e5e58f",
  },
};
export const FormikNumberField = ({
  label,
  required,
  maxLength = 15,
  onLocationSelect,
  disabled,
  RenderRightContainer,
  ...props
}) => {
  const [field, meta, helpers] = useField(props.name);
  const { error, touched } = { ...meta };
  const handleInputChange = (event) => {
    if (event.target.value.length > maxLength) {
      event.target.value = event.target.value.slice(0, maxLength);
    }
  };

  return (
    <Stack>
      <StyledTypography className="labelStyling" component="span">
        {label}
        {required && (
          <StyledTypography sx={{ color: "red" }} component="span">
            {" *"}
          </StyledTypography>
        )}
      </StyledTypography>
      <TextField
        {...field}
        size="small"
        type="tel"
        sx={disabled ? disableColor : null}
        inputProps={{
          style: { fontFamily: FontFamily, padding: "8.5px 14px" },
          maxLength: maxLength,
        }}
        InputProps={{ endAdornment: RenderRightContainer || undefined }}
        onChange={(e) =>
          !isNaN(e.target.value) &&
          e.target.value.indexOf(".") === -1 &&
          helpers.setValue(e.target.value)
        }
        onInput={handleInputChange}
        onFocus={(event) => event.target.select()}
        value={field.value || ""}
        {...props}
        variant="outlined"
        autoComplete="off"
        error={touched && error !== undefined}
        disabled={disabled}
      />
      {touched && error && (
        <StyledTypography sx={ErroLabelStyle} component="span">
          {error}
        </StyledTypography>
      )}
    </Stack>
  );
};

import Translate from "../../../../translation/utils/translateFunction";
import { CustomTitleCellRender } from "../../../common/CustomCellRender";

export const getGMColumns = (CustomSrCellRender, CustomActionCellRender) => {
  const columns = [
    {
      field: "lineNo",
      headerName: Translate("addManager:srNoTableHeading"),
      sortable: false,
      disableColumnMenu: true,
      renderCell: CustomSrCellRender,
    },
    {
      field: "name",
      headerName: Translate("addManager:nameTableHeading"),
      minWidth: 250,
      maxWidth: 250,
      sortable: true,
      disableColumnMenu: true,
      renderCell: (params) => CustomTitleCellRender(params?.row?.name),
    },
    {
      field: "mobile",
      headerName: Translate("addManager:mobileTableHeading"),
      minWidth: 250,
      maxWidth: 250,
      sortable: true,
      disableColumnMenu: true,
    },
    {
      field: "companyName",
      headerName: Translate("addManager:companyNameTableHeading"),
      minWidth: 250,
      maxWidth: 250,
      sortable: true,
      disableColumnMenu: true,
      renderCell: (params) => CustomTitleCellRender(params?.row?.companyName),
    },
    {
      field: "email",
      headerName: Translate("addManager:emailTableHeading"),
      minWidth: 250,
      maxWidth: 250,
      sortable: true,
      disableColumnMenu: true,
      renderCell: (params) => CustomTitleCellRender(params?.row?.email),
    },
    {
      field: "Action",
      headerName: Translate("addManager:actionTableHeading"),
      minWidth: 250,
      maxWidth: 250,
      sortable: false,
      disableColumnMenu: true,
      renderCell: CustomActionCellRender,
    },
  ];
  return columns;
};

import { Grid } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import CompanyHeader from "../../common/CompanyHeader";
import { colors } from "../../common/Theme/Colors";
import {
  StyledCard,
  StyledTypography,
} from "../../common/Utils/StyledComponents";
import { showToast } from "../../common/Utils/ToastMessage";
import { userTypes } from "../../common/users/userTypes";
import { useGetVendorListingMutation } from "../../request/VendorsSuppliers";
import Translate from "../../translation/utils/translateFunction";
import CompanyListing from "../ContactRepository/component";
import ListView from "../ListView";
import Searchbar from "../common/Searchbar";
import { getVendorColumns } from "./Components/column.data";
import { checkModuleAccess } from "../../common/Utils/commonFunction";

const VendorListing = ({ fixNavbar }) => {
  const navigate = useNavigate();
  const { selectedCompanyList, userData, isRtl } = useSelector(
    (state) => state.formanagement
  );

  const [isLoading, setIsLoading] = useState(false);
  const [lastPage, setLastPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [vendorData, setVendorData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [companyList, setCompanyList] = useState([]);

  const [
    fetchVendorList,
    {
      data: vendorList,
      isLoading: isVendorLoading,
      isError: isVendorError,
      error: vendorError,
    },
  ] = useGetVendorListingMutation();

  const requestObj = useMemo(() => {
    const companyIds = selectedCompanyList?.length
      ? selectedCompanyList?.map((item) => item?.value)
      : [];

    const finalReq = {
      companyId: companyIds,
      searchText,
      pageNo: currentPage,
    };

    return companyIds?.length ? finalReq : null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, searchText, selectedCompanyList]);

  const debouncedSearch = useDebouncedCallback(() => {
    fetchVendorList(requestObj);
  }, 500);

  useEffect(() => {
    setVendorData([]);
    if (selectedCompanyList?.length) {
      setIsLoading(true);

      if (requestObj?.searchText?.length) {
        debouncedSearch();
      } else {
        fetchVendorList(requestObj);
      }
    } else {
      userData &&
        showToast({
          type: "info",
          message: Translate("vendors:selectCompanyMessage"),
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestObj]);

  useEffect(() => {
    if (vendorList?.data?.nodes) {
      const { nodes, pageInfo } = vendorList.data;
      setVendorData(nodes);
      setLastPage(pageInfo.lastPageNo);
      setIsLoading(false);
    }

    if (vendorError && isVendorError) {
      vendorError?.originalStatus !== 500 &&
        showToast({
          message:
            JSON.stringify(vendorError.data) ||
            Translate("common:unknownServerError"),
          type: "error",
        });
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendorList, isVendorError]);

  const handleSearchText = (value) => {
    setCurrentPage(1);
    setSearchText(value);
  };

  const customActionCellRender = (params) => {
    return (
      <td>
        <i
          className="m-2 fa fa-eye cursor-pointer manageIconColor"
          onClick={() =>
            navigate("/vendors/view", {
              state: params?.row?._id,
            })
          }
          data-toggle="tooltip"
          title={Translate("common:viewToolTip")}
        ></i>
      </td>
    );
  };

  const customSrCellRender = (params) => {
    return (
      <StyledTypography component="span" sx={{ color: colors.primary_009 }}>
        {params?.api?.getRowIndexRelativeToVisibleRows(params?.row?._id) +
          1 +
          (currentPage - 1) * 10}
      </StyledTypography>
    );
  };

  const handleCompanyModal = () => {
    companyList?.length !== 1 && setShowModal(true);
  };

  return (
    <Grid
      item
      xs={12}
      className={`section-body ${isRtl ? "rtlMargin" : "ltrMargin"} ${
        fixNavbar ? "marginTop" : ""
      } `}
    >
      <StyledCard variant="outlined">
        <div className="container-fluid p-4">
          <div className="tab-content">
            <div>
              <div className="card">
                <div className="card-header card-header-spacing">
                  <CompanyHeader setShowModal={setShowModal} />
                  <div className="card-options">
                    <div className="input-group">
                      <Searchbar
                        handleSearchText={handleSearchText}
                        setSearch={setSearchText}
                      />
                    </div>

                    {checkModuleAccess(userData?.add, userTypes?.Vendor) && (
                      <div className="header-action pull-right ml-2">
                        <button
                          type="button"
                          className="btn btn-primary py-2"
                          onClick={() => navigate("/vendors/add")}
                        >
                          <i className="fe fe-plus" />
                          <StyledTypography className="mx-1" component="span">
                            {Translate("vendors:addButtonLabel")}
                          </StyledTypography>
                        </button>
                      </div>
                    )}
                  </div>
                </div>
                <ListView
                  columns={getVendorColumns(
                    customActionCellRender,
                    customSrCellRender
                  )}
                  rows={vendorData}
                  autoHeight
                  onPaginationChange={setCurrentPage}
                  currentPage={currentPage}
                  showShimmer={isLoading || isVendorLoading}
                  showLoader={isLoading}
                  totalPageCount={lastPage}
                />
              </div>
            </div>
          </div>
        </div>
      </StyledCard>
      <CompanyListing
        handleModal={(value) => {
          setCurrentPage(1);
          setShowModal(value);
        }}
        showModal={showModal}
        selectedCompanyList={selectedCompanyList}
        moduleType="VENDOR"
        setCompanyList={setCompanyList}
      />
    </Grid>
  );
};

export default VendorListing;

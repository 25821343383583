export default {
  head: "تقرير حجم العمل",
  occupied: "مشغول",
  free: "مُتاح",
  staffPlaceHolder: "حدد الموظفين",
  staffLabel: "طاقم العمل",
  workloadDetailsHeader: "تفاصيل تقرير عبء العمل",
  exportWorkloadFileName: "FOM_WORKLOAD_REPORT",
  defaultSelected: "تم اختيارها كشركة افتراضية",
  filterOptions: "يمكن تغيير ذلك من خيارات التصفية.",
};

import React, { useState, useEffect } from "react";
import Loader from "../staff/Owner/LoadingComp";
import { showToast } from "../../common/Utils/ToastMessage";
import { useGetAboutUsDetailsMutation } from "../../request/Cms";
import NoDataFound from "../ManageTask/NoDataFound";

const AboutUs = () => {
  const [mainContent, setMainContent] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const [
    getAboutUsDetailsApi,
    {
      data: aboutUsData,
      isSuccess: isAboutUsSuccess,
      isLoading: isAboutUsLoading,
      isError: isAboutUsError,
      error: aboutUsError,
    },
  ] = useGetAboutUsDetailsMutation();

  useEffect(() => {
    getAboutUsDetailsApi({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isAboutUsError && aboutUsError) {
      showToast({
        message: aboutUsError?.data?.message,
        type: "error",
      });
      setIsLoading(false);
    }

    if (aboutUsData && isAboutUsSuccess) {
      if (!aboutUsData?.success) {
        showToast({
          message: aboutUsData?.message,
          type: "error",
        });
      } else {
        console.log("aboutUsData?.data", aboutUsData?.data);
        setMainContent(aboutUsData?.data);
      }

      setIsLoading(false);
    }
  }, [isAboutUsSuccess, aboutUsData, isAboutUsError, aboutUsError]);

  return (
    <div className="row container_spacing">
        <div className="cms-container card">
          {!isAboutUsLoading && !isLoading ? (
            aboutUsData?.data ? (
              <div
              className="col-lg-12 col-md-12 col-sm-12 col-xs-12 card_spacing p-20"
                dangerouslySetInnerHTML={{ __html: mainContent }}
              />
            ) : (
              <NoDataFound isCenter />
            )
          ) : null}
          <Loader loading={isAboutUsLoading || isLoading} />
        
      </div>
    </div>
  );
};

export default AboutUs;

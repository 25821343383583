import { CreateApi } from "../../CreateApi";
import { Methods, Urls } from "../../../common/Utils/ApiConstants";
const relatedTaskCollection = CreateApi.injectEndpoints({
  endpoints: (builder) => ({
    getRelatedTaskData: builder.mutation({
      query: (pageNo) => {
        return {
          url: Urls.getRelatedTaskList,
          method: Methods.get,
          params: { pageNo },
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
    }),

    getEmailRelatedTaskList: builder.mutation({
      query: (requestBody) => {
        return {
          url: Urls.getEmailRelatedTaskList,
          method: Methods.post,
          body: requestBody,
        };
      },
    }),
    getChatGroupRelatedTaskList: builder.mutation({
      query: (requestBody) => {
        return {
          url: Urls.getChatGroupRelatedTaskList,
          method: Methods.post,
          body: requestBody,
        };
      },
    }),
    getChatRelatedTaskList: builder.mutation({
      query: (requestBody) => {
        return {
          url: Urls.getChatRelatedTaskList,
          method: Methods.post,
          body: requestBody,
        };
      },
    }),
  }),
});
export const {
  useGetRelatedTaskDataMutation,
  useGetEmailRelatedTaskListMutation,
  useGetChatGroupRelatedTaskListMutation,
  useGetChatRelatedTaskListMutation,
} = relatedTaskCollection;

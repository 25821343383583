export default {
  editPersonalAssistantHeading: "Edit PA",
  addPersonalAssistantHeading: "Add PA",
  selectCompany: "Select company",
  dropdownPlaceholder: "Select company",
  selectCompanyMessage: "Please select at least one company",
  selectedCompanyMessage: "All company selected",
  name: "Personal assistant name",
  dob: "Date of birth",
  designation: "Designation",
  department: "Department",
  contactNumber: "Contact number",
  contactNumberPlaceHolder: "0000 0000 00",
  alternateContactNumber: "Alternate contact number",
  alternateContactNumberPlaceHolder: "0000 0000 00",
  hrContactNumber: "HR contact number",
  hrContactNumberPlaceHolder: "0000 0000 00",
  extensionNumber: "Extension number",
  companyExtensionNumber: "Company phone extension number",
  extensionNumberPlaceholder: "0000 0000 00",
  companyExtensionPlaceholder: "1",
  companyPhoneExtensionPlaceholder: "000",
  email: "Work email",
  emailPlaceholder: "Work email address",
  address: "Residence address",
  addressPlaceholder_1: "House/flat/block no.",
  addressPlaceholder_2: "Apartment/road/area",
  addressPlaceholder_3: "Flat/block no.",
  addressPlaceholder_4: "Building/road/area",
  countryPlaceholder: "Country",
  statePlaceholder: "State/region",
  cityPlaceholder: "City",
  zipcodePlaceholder: "Zip code ",
  workAddress: "Work address",
  gender: "Gender",
  uploadPhoto: "Upload photo",
  save: "Save",
  addMore: "Add more",
  nameError: "Personal assistant name is required",
  dobError: "Date of birth is required",
  designationError: "Designation is required",
  departmentError: "Department is required",
  mobileError: "Contact number is required",
  mobileInvalidError: "Contact number is invalid",
  workEmailErrorInvalid: "Work email is not valid",
  workEmailError: "Work email is required",
  addressError: "Residence address is required",
  countryError: "Country is required",
  stateError: "State/Region is required",
  cityError: "City is required",
  workAddressError: "Work address is required",
  zipcodeErrorInvalid: "Zipcode is invalid!",
  ageAlert: "Age should be greater than 18",
  reportTo: "Report to",
  viewPersonalAssistantHeading: "View personal assistant",
  deleteAlert: "Are you sure want to delete this personal assistant?",
  srNoTableHeading: "Sr.no",
  actionTableHeading: "Action",
  nameTableHeading: "Name",
  mobileTableHeading: 'Mobile no.',
  searchPlaceholder: "Search something...",
  addButtonLabel: "Add",
  rateButtonLabel: "Rate",
  companyNumberMinMaxError: "Company number should be 5 to 12 digits long",
  companyNumberMinError: "Company number must be greater than or equal to 5",
  companyNumberMaxError: "Company number must be less than or equal to 12",
  companyExtensionCodeMinMaxError: "Company extension code should be 1 to 4 digits long",
  companyNameTableHeading: "Company name",
  emailTableHeading: "Email",
};

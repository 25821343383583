import { Grid } from "@mui/material";
import * as d3 from "d3";
import React, { useEffect, useMemo, useState } from "react";
import { MapInteractionCSS } from "react-map-interaction";
import { useSelector } from "react-redux";
import CompanyHeader from "../../../common/CompanyHeader";
import { StyledCard } from "../../../common/Utils/StyledComponents";
import { showToast } from "../../../common/Utils/ToastMessage";
import { useGetCompanyStructureDetailsMutation } from "../../../request/OrganizationStructure";
import Translate from "../../../translation/utils/translateFunction";
import CompanyListing from "../../ContactRepository/component";
import Loader from "../../staff/Owner/LoadingComp";
import { DeployNodes } from "../Components/DeployNodes";
import { NodeCurves } from "../Components/NodeCurves";
import { SiblingCurves } from "../Components/SiblingCurves";
import { flatten, getOwnerAndGmCount } from "../Components/utils";

const CompanyStructure = ({ fixNavbar }) => {
  const { selectedCompanyList, isRtl } = useSelector(
    (state) => state.formanagement
  );

  const [isLoading, setIsLoading] = useState(true);
  const [displayNodes, setDisplayNodes] = useState();
  const [lines, setLines] = useState();
  const [siblingLines, setSiblingLines] = useState();
  const [showModal, setShowModal] = useState(false);
  const [treeWidth, setTreeWidth] = useState(1000);
  const [selectedCompany, setSelectedCompany] = useState([
    selectedCompanyList[0],
  ]);

  const [
    getCompanyStructureApi,
    {
      data: companyStructureData,
      isLoading: isCompanyStructureLoading,
      isSuccess: isCompanyStructureSuccess,
      isError: isCompanyStructureError,
      error: companyStructureError,
    },
  ] = useGetCompanyStructureDetailsMutation();

  const requestBody = useMemo(() => {
    const companyIds = selectedCompany?.length
      ? selectedCompany?.map((item) => item?.value)
      : [];

    const reqObj = {
      companyId: companyIds[0],
    };

    return companyIds?.length ? reqObj : null;
  }, [selectedCompany]);

  useEffect(() => {
    if (requestBody) {
      setIsLoading(true);
      getCompanyStructureApi(requestBody);
    }
  }, [requestBody]);

  useEffect(() => {
    if (isCompanyStructureSuccess && companyStructureData?.data) {
      const { hierarchyData, sibilingData } = companyStructureData?.data;

      const localData = JSON.parse(JSON.stringify(hierarchyData));

      generateTree(localData, sibilingData);
    }

    if ((companyStructureError, isCompanyStructureError)) {
      setIsLoading(false);
      showToast({
        type: "error",
        message:
          JSON.stringify(companyStructureError?.data?.message) ||
          Translate("common:unknownServerError"),
      });
    }
  }, [
    companyStructureData,
    isCompanyStructureSuccess,
    isCompanyStructureError,
    companyStructureError,
  ]);

  const generateTree = (root, siblings) => {
    const allNodes = flatten(root); // flatten

    const { ownerCount, gmCount } = getOwnerAndGmCount(allNodes);

    const maxRoleWidth = Math.max(ownerCount, gmCount);
    const tempWidth = maxRoleWidth * 250;
    const treeHeight = gmCount > 0 ? 600 : 300;
    tempWidth > 1000 && setTreeWidth(tempWidth);

    const tree = d3?.layout?.tree()?.size([maxRoleWidth * 250, treeHeight]);
    const nodes = tree?.nodes(root);
    const links = tree?.links(nodes);
    const curves = NodeCurves(links);
    setLines(curves);

    const siblingPath = SiblingCurves(siblings, allNodes);
    setSiblingLines(siblingPath);

    const rectNodes = DeployNodes(allNodes);
    setDisplayNodes(rectNodes);
    setIsLoading(false);
  };

  return (
    <Grid
      item
      xs={12}
      className={`section-body ${isRtl ? "rtlMargin" : "ltrMargin"} ${
        fixNavbar ? "marginTop" : ""
      } `}
    >
      <StyledCard variant="outlined">
        <div className="container-fluid">
          <div className="tab-content">
            <div className="card cardHeight">
              <div className="row">
                <div className="col-12">
                  <div className="card-header fixedHeight">
                    <CompanyHeader
                      setShowModal={setShowModal}
                      selectedCompany={
                        selectedCompany[0]?.label ||
                        Translate("common:selectCompany")
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="card-body">
                    {isLoading || isCompanyStructureLoading ? (
                      <Loader isListLoader />
                    ) : (
                      displayNodes && (
                        <MapInteractionCSS>
                          <svg width={treeWidth} height="1000">
                            <g>
                              {siblingLines}
                              {lines}
                              <foreignObject width="100%" height="100%">
                                {displayNodes}
                              </foreignObject>
                            </g>
                          </svg>
                        </MapInteractionCSS>
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </StyledCard>

      <CompanyListing
        handleModal={setShowModal}
        showModal={showModal}
        selectedCompanyList={selectedCompany}
        isSingleSelect
        onSelect={(value) => {
          setSelectedCompany(value);
        }}
        isFilter
      />
    </Grid>
  );
};

export default CompanyStructure;

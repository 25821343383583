export default {
  ok: "Ok",
  cancel: "Cancel",
  save: "Save",
  addMore: "Add More",
  invite: "Invite",
  addButton: "Add",
  share: "Share",
  yes: "Yes",
  apply: "Apply",
  send: "Send",
  saveChanges: "Save changes",
  done: "Done",
  confirm: "Confirm",
  add: "Add",
  errorMessage: "No comments found",
  edit: "Edit",
  delete: "Delete",
  search: "Search",
  searchTask: "Search task",
  selectAll: "Select all",
  takePhoto: "Take a photo",
  uploadGallery: "Upload from gallery",
  copy_Paste: "Copy/paste text",
  accessLogs: "Access logs",
  performance: "Performance",
  rate: "Rate",
  redZone: "Red zone",
  greenZone: "Green zone",
  calendar: "Calendar",
  inbox: "Inbox",
  home: "Home",
  message: "Message",
  manageTask: "Manage Task",
  alert: "Alert",
  imagePreviewAlert: "Only 1 file is allowed.",
  notificationAlert:
    "To receive realtime updates from other users, please enable notification permission. Please refer link to enable notification -",
  referThis: "Instructions here...",
  allCompanySelect: "All company selected",
  selectCompany: "Select company",
  unAuthTitle: "Unauthorized Access",
  unAuthsubtitle: "You are not authorized to access this page.",
  unAuthBtn: "Take me home",
  editToolTip: "Edit",
  backToolTip: "Go back",
  viewToolTip: "View",
  deleteToolTip: "Delete",
  searchPlaceholder: "Search something...",
  close: "Close",
  fileAddLimitMsg: "You can only send one attachment at a time.",
  dropZoneFileSizeMsg: "You cannot add file size more than 50 MB.",
  fileAddLimitMsg10: " files are allowed.",
  maximum: "Maximum",
  invalidFileMsg: "Invalid file format",
  unknownServerError: "Something went wrong!",
  continue: "Continue",
  showFilterToolTip: "Show filter",
  clearFilterToolTip: "Clear filter",
  selectCompanyMessage: "Please select at least one company",
  dateRangePlaceholder: "From date - To date",
  removeToolTip: "Remove",
  forManagemnet: "For.Management",
  srNoTitle: "Sr.no",
  actionTitle: "Action",
  nextToolTip: "Next",
  shareToolTip: "Share",
  loadMoreBtnLabel: "Load more",
  apiFailedMessage: "Failed to fetch the data",
  sortByTitle: "Sort by",
  clearAllTitle: "Clear all",
  required: "Required field",
  noDataFoundMessage: "No data found",
  welcome: "Welcome",
  english: "English",
  language: "Language",
  deleteProfileAlert: "Are you sure you want to delete this profile image?",
  account: "Account",
  logOut: "Log-out",
  personalAssistant: "Personal assistant",
  generalManager: "General manager",
  exportLabel: "Export",
  datePlaceHolder: "Select date",
  copy: "Copy",
  copyMsg: "Copied",
  imageSizeLimitMsg: "You cannot add image size more than 10 MB.",
  click: "Click",
  allow: "Allow",
  filterTitle: "Filters",
  today: "Today",
  yesterday: "Yesterday",
  self: "Self",
  ScreenViewalertMsg:
    "Sorry, this website is only available on desktop devices.",
  noteTitle: "Note",
  duplicateFileError: "already uploaded",
  arabic: "Arabic",
  loadingTitle: "loading...",
  day: "DD",
  month: "MM",
  year: "YYYY",
  hour: "HH",
  minute: "MM",
  holidayPresent: "Holiday already exits!",
  weekendsError: "Select atleast two days to set weekends",
  allCompanySelectworkload: "All companies",
  addmom: "Add MOM",
  xlsB64PreviewError:
    "Preview unavailable for this file format before uploading. Please proceed with the upload to view contents.",
  noEventMsg: "No events found",
  download: "Download",
  downloadAlert: "Are you sure you want to download the",
  pdfDownloadError: "Error fetching the PDF",
  loadingText: "Loading...",
};

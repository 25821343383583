export default {
  head: "Workload report",
  occupied: "Occupied",
  free: "Free",
  staffPlaceHolder: "Select staff",
  staffLabel: "Staff",
  workloadDetailsHeader: "Workload report detail",
  exportWorkloadFileName: "FOM_WORKLOAD_REPORT",
  defaultSelected: "is selected as default company.",
  filterOptions: "This can be changed from filter options.",
};

import { Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { Link } from "react-router-dom";
import { ReactComponent as BrandLogo } from "../../../assets/images/FoM_logo.svg";
import { colors } from "../../../common/Theme/Colors";
import { StyledTypography } from "../../../common/Utils/StyledComponents";
import Translate from "../../../translation/utils/translateFunction";
import Loader from "../../staff/Owner/LoadingComp";
import { handleEventKeySubmit } from "../../../common/Utils/commonFunction";

const VerifyOTP = ({
  handleSubmit,
  mobileNo,
  resendOtp,
  otp,
  setOtp,
  isLoading,
  onGoBack,
  isForgotPassword = false,
}) => {
  const [showResend, setShowResend] = useState(true);
  const [counter, setCounter] = useState(30);
  const [stateUpdater, setStateUpdater] = useState(false);

  var intervalId;
  //   const [
  //     verifyOtp,
  //     {
  //       isSuccess: isSuccesEmailOtp,
  //       data: emailOtpData,
  //       isLoading: isLoadingEmailOtp,
  //       // error: errorEmailOtpData,
  //       isError: isErrorEmailOtpData,
  //     },
  //   ] = useVerifyEmailOtpMutation();

  useEffect(() => {
    if (showResend) {
      intervalId = setInterval(() => {
        setStateUpdater((prev) => !prev);
      }, 1000);
    }
    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showResend]);
  useEffect(() => {
    if (counter > 0) setCounter((prev) => prev - 1);
    else {
      clearInterval(intervalId);
      setShowResend(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateUpdater]);

  return (
    <div className="card" style={{ zIndex: 999 }}>
      <div className="card-body cardStyling">
        <>
          <div className="text-center mb-35">
            <Link className="header-brand" to="/">
              <BrandLogo className="forLogo"></BrandLogo>
            </Link>
          </div>
          {/* <StyledTypography variant="h5" mb={"5px"} sx={{ fontWeight: "600" }}>
            {Translate("forgotPassword:verifyOTP")}
          </StyledTypography> */}
          <StyledTypography
            variant="subtitle1"
            marginY={"10px"}
            sx={{ fontWeight: "500" }}>
            {isForgotPassword
              ? Translate("forgotPassword:titleText_2")
              : Translate("forgotPassword:titleText_3") + `${mobileNo}`}
          </StyledTypography>
          {Translate("forgotPassword:titleText_4")}
        </>
        <div onKeyDown={(e) => handleEventKeySubmit(e, handleSubmit)}>
          <OtpInput
            value={otp}
            onChange={setOtp}
            numInputs={4}
            renderSeparator={<span style={{ width: "5%" }} />}
            renderInput={(props) => <input {...props} />}
            inputStyle={{
              width: "20%",
              height: "3rem",
              fontSize: "1.5rem",
              marginTop: "2%",
            }}
            inputType="tel"
            shouldAutoFocus
          />
        </div>
        <Stack
          sx={{ marginY: 3 }}
          flexDirection={"row"}
          justifyContent={"space-around"}>
          <Stack flexDirection={"row"}>
            <StyledTypography variant="subtitle2">
              {Translate("forgotPassword:receivedOTP")}
            </StyledTypography>
            <StyledTypography
              className="cursor-pointer"
              variant="subtitle2"
              ml={"5px"}
              sx={{
                color: colors.primary_010,
                opacity: showResend ? 0.5 : 1,
              }}
              onClick={() => {
                if (!showResend) {
                  resendOtp();
                  setCounter(30);
                  setShowResend(true);
                }
              }}>
              {Translate("forgotPassword:resendOTP")}
            </StyledTypography>
          </Stack>
          {counter > 0 && (
            <StyledTypography
              variant="subtitle2"
              sx={{ color: colors.primary_010, textAlign: "right" }}>
              {counter}
            </StyledTypography>
          )}
        </Stack>

        <div className="form-footer">
          <button
            type="submit"
            className="btn btn-primary btn-block"
            onClick={() => !isLoading && handleSubmit?.()}
            disabled={otp.length < 4}>
            {isLoading ? (
              <Loader loading={isLoading} isListLoader message={""} />
            ) : (
              Translate("forgotPassword:verify")
            )}
          </button>
        </div>
      </div>
      {/* <StyledTypography
        mt={"10px"}
        onClick={onGoBack}
        className="text-center text-muted cursor-pointer">
        {Translate("login:emailLogin")}
      </StyledTypography> */}
      {!isForgotPassword && (
        <StyledTypography
          mt={"30px"}
          fontWeight={"500"}
          onClick={onGoBack}
          tabIndex="0"
          onKeyDown={(e) => handleEventKeySubmit(e, onGoBack)}
          className="text-center text-primary cursor-pointer">
          {Translate("login:emailLogin")}
        </StyledTypography>
      )}
      {isForgotPassword && (
        <StyledTypography
          mt={"30px"}
          fontWeight={"500"}
          onClick={() => onGoBack(false)}
          className="text-center text-primary cursor-pointer">
          {Translate("forgotPassword:back")}
        </StyledTypography>
      )}
    </div>
  );
};
export default VerifyOTP;

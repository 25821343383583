export default {
  forgotPassword: "نسيت كلمة السر",
  titleText_1: "حدد الإيميل لإعادة تعيين كلمة السر",
  email: "Email address",
  emailPlaceholder: "Enter your email",
  next: "التالي",
  verifyOTP: "Verify OTP",
  titleText_2: "تحقق من رمز ال OTP المرسل الى ايميلك",
  titleText_3: "Enter the OTP has sent to ",
  titleText_4: "تحقق من رمز ال OTP لتسجيل الدخول",
  receivedOTP: "لم تستلم كلمة المرور لمرة واحدة OTP؟",
  resendOTP: "إعادة ارسال رمز ال OTP",
  verify: "تأكيد",
  setPassword: "Set password",
  newPassword: "ادخل كلمة سر جديدة",
  confirmPassword: "تأكيد كلمة السر الجديدة",
  save: "Save",
  passwordError: "New password is required",
  passwordError1: "New password is not valid",
  beforUnloadTitle: "Are you sure, you want to leave this page?",
  leave: "leave",
  back: "خلف",
  backToForgotPassword: "العودة إلى نسيت كلمة المرور",
};

import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Translate from "../../../../translation/utils/translateFunction";
import { InitialValues } from "../../components/constants";
import { addDocumentSchema } from "../../components/utils";
import { showToast } from "../../../../common/Utils/ToastMessage";
import { useAddDocumentDataMutation } from "../../../../request/Document/AddDocument";
import DocumentForm from "../../components/DocumentForm";
import { Formik } from "formik";

const AddMyDocument = () => {
  const navigate = useNavigate();
  const formikRef = useRef(null);

  const { selectedCompanyList } = useSelector((state) => state?.formanagement);
  const [filesAdded, setfilesAdded] = useState([]);
  const [images, setImages] = useState([]);
  const [isTaskCreate, setIsTaskCreate] = useState(false);
  const [
    getAddDocumentApi,
    {
      data: addDocumentData,
      isSuccess: successAddingDocument,
      isError: errorAddingDocument,
      error: errorDocument,
      isLoading: uploadingDocument,
    },
  ] = useAddDocumentDataMutation();

  useEffect(() => {
    if (selectedCompanyList?.length) {
      InitialValues.companyId = selectedCompanyList[0].value;
      formikRef.current.setFieldValue(
        "companyId",
        selectedCompanyList[0].value
      );
    }
  }, [selectedCompanyList]);

  useEffect(() => {
    if (successAddingDocument && addDocumentData) {
      showToast({ message: Translate("document:addSuccess"), type: "success" });
      formikRef.current.resetForm();
      setfilesAdded([]);
      setImages([]);
      isTaskCreate
        ? navigate("/addTask", {
            state: {
              isMyDocument: true,
              documentId: addDocumentData?.data?._id,
              title: addDocumentData?.data?.title,
              description: addDocumentData?.data?.description,
              attachment: addDocumentData?.data?.attachment,
              company:
                addDocumentData?.data?.companyId?.length > 0 &&
                addDocumentData?.data?.companyId[0],
            },
          })
        : navigate("/documentRepository/myDocuments");
    }

    if (errorAddingDocument && errorDocument) {
      setIsTaskCreate(false);
      errorDocument.data.error && errorDocument.data.error.length > 0
        ? errorDocument.data.error.map((errorItem) => {
            if (!InitialValues.hasOwnProperty(errorItem?.param)) {
              return showToast({ message: errorItem.msg, type: "error" });
            }
            return formikRef.current?.setFieldError(
              errorItem.param,
              errorItem.msg
            );
          })
        : showToast({
            message:
              errorDocument?.data.message ||
              Translate("common:unknownServerError"),
            type: "error",
          });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successAddingDocument, errorDocument]);

  const onSubmit = (companyFormValues) => {
    let reqObj = {
      companyId: [companyFormValues?.companyId],
      title: companyFormValues.title,
      description: companyFormValues.description,
      isAttachment: false,
    };
    let b64Array;
    let typeFile;

    if (filesAdded.length) {
      const { documentFileName, documentFileExt } = filesAdded[0];

      b64Array = filesAdded[0].b64;
      typeFile = filesAdded[0].type;
      let fileSize = filesAdded[0].size;
      let convertedFileSize = fileSize.toString();

      reqObj = {
        ...reqObj,
        isAttachment: true,
        webAttachment: {
          url: b64Array,
          type: typeFile,
          documentFileName,
          documentFileExt,
        },
        size: convertedFileSize,
      };
    }

    getAddDocumentApi(reqObj);
  };

  return (
    <Formik
      initialValues={InitialValues}
      innerRef={formikRef}
      validateOnMount
      onSubmit={onSubmit}
      validationSchema={addDocumentSchema}>
      {({ values, handleSubmit }) => {
        return (
          <DocumentForm
            values={values}
            handleSubmit={handleSubmit}
            filesAdded={filesAdded}
            images={images}
            setfilesAdded={setfilesAdded}
            setImages={setImages}
            setCreateTask={setIsTaskCreate}
            isLoading={uploadingDocument}
            isAdd
          />
        );
      }}
    </Formik>
  );
};

export default AddMyDocument;

import React from "react";
import { colors } from "../../common/Theme/Colors";
import IconifyIcon from "../Icon";
import { VALIDATION_BUCKETURL } from "./constants";
import { getPersonaInitialsColor } from "./persona.initialcolor";
import { getInitials } from "./utils";
import { useSelector } from "react-redux";
import { getFileExtFromUrlAndB64 } from "../../common/Utils/commonFunction";
import { iosImgTypes } from "../../common/Utils/ApiConstants";
import FOMImg from "../../assets/images/common/Image1 140_140.png";
import { validateUrl } from "../../common/Utils/commonFunction";

export const Persona = ({
  name,
  image,
  size,
  className,
  isBase64,
  isProfile,
}) => {
  const { isRtl } = useSelector((state) => state.formanagement);
  const PersonaSize = size ? size : 48;
  const fileExtension = getFileExtFromUrlAndB64(image);
  const isIos = iosImgTypes?.includes(fileExtension);

  const containerStyle = {
    backgroundColor: image ? colors.grey_008 : getPersonaInitialsColor(name),
    borderRadius: PersonaSize / 2,
    height: PersonaSize,
    width: PersonaSize,
    minWidth: PersonaSize,
    borderColor: "transparent",
    marginRight: isRtl ? 0 : 3,
    marginLeft: isRtl ? 3 : 0,
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "end",
  };

  const imageStyle = {
    height: PersonaSize,
    width: PersonaSize,
    borderRadius: PersonaSize / 2,
  };

  const fontStyles = {
    color: colors.white,
    fontSize: PersonaSize * 0.38,
    textAlign: "center",
    height: PersonaSize,
    width: PersonaSize,
    borderRadius: PersonaSize / 2,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
  const groupStyle = {
    color: colors.white,
    textAlign: "center",
    height: PersonaSize - 12,
    width: PersonaSize - 12,
    borderRadius: PersonaSize / 3,
  };
  return (
    <div style={containerStyle} className={className}>
      {image ? (
        image?.includes(VALIDATION_BUCKETURL) ||
        isBase64 ||
        validateUrl(image) ? (
          <img src={isIos ? FOMImg : image || ""} style={imageStyle} alt="" />
        ) : (
          <img
            src={isIos ? FOMImg : { uri: image || "" }}
            style={imageStyle}
            alt=""
          />
        )
      ) : isProfile ? (
        <IconifyIcon
          icon="mdi:user-outline"
          style={{
            ...groupStyle,
            position: "absolute",
            top: "4px",
            left: "6px",
          }}
        />
      ) : (
        <div style={fontStyles}>{getInitials(name || "")}</div>
      )}
    </div>
  );
};

import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { showToast } from "../../../../common/Utils/ToastMessage";
import Translate from "../../../../translation/utils/translateFunction";
import { useAddHrOfficerMutation } from "../../../../request/Staff/HrOfficer";
import { formatDateYYYYMMDD } from "../../../../common/Utils/DateFormater";
import { Formik } from "formik";
import moment from "moment";
import { getAddressError } from "../../../../common/Utils/commonFunction";
import HrOfficerForm from "../Components/HrOfficerForm";
import { InitialValues } from "../Components/constants";
import { AddHrOfficerSchema } from "../Components/validators";

const AddHrOfficer = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const formikRef = useRef(null);
  const [reportTo, setReportTo] = useState({});
  const [filesAdded, setFilesAdded] = useState([]);
  const [images, setImages] = useState([]);
  const [addMoreHrOfficer, setAddMoreHrOfficer] = useState(false);
  const [selectedStaff, setSelectedStaff] = useState();

  const [
    addHrOfficerApi,
    { data: hrOfficerData, isSuccess, isLoading, isError, error },
  ] = useAddHrOfficerMutation();

  useEffect(() => {
    if (isError && error) {
      const hrOfficerError = error?.data;

      if (!hrOfficerError) {
        showToast({
          message: Translate("common:unknownServerError"),
          type: "error",
        });
      } else {
        hrOfficerError?.error?.length
          ? hrOfficerError.error.map((errorItem) => {
              const isPresent = getAddressError(
                errorItem.param,
                formikRef.current?.setFieldError
              );
              !isPresent &&
                formikRef.current.setFieldError(errorItem.param, errorItem.msg);
            })
          : showToast({
              message:
                hrOfficerError?.message ||
                Translate("common:unknownServerError"),
              type: "error",
            });
      }
    }

    if (isSuccess && hrOfficerData) {
      if (!hrOfficerData?.success) {
        showToast({ message: hrOfficerData?.message, type: "error" });
      } else {
        showToast({ message: hrOfficerData?.message, type: "success" });
      }

      formikRef.current.resetForm();
      InitialValues.dob = "";
      setFilesAdded([]);
      setImages([]);

      if (!addMoreHrOfficer) {
        navigate("/staff/hrOfficer");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hrOfficerData, error]);

  const onSubmit = (formValues) => {
    const {
      companyId,
      dob,
      countryCode,
      alternateMobile,
      alternateMobileCountryCode,
    } = formValues;

    let reqObj = {
      companyId,
      name: formValues.name,
      gender: formValues?.gender.toUpperCase(),
      dob:
        moment(dob).creationData().format === "YYYY-MM-DD"
          ? dob
          : formatDateYYYYMMDD(dob),
      designation: formValues.designation,
      department: formValues.department,
      reportTo,
      countryCode: countryCode ? countryCode : "962",
      mobile: formValues?.mobile?.split("-")[1],
      mobileShortCode: formValues.mobileShortCode,
      alternateMobile: formValues?.alternateMobile
        ? formValues?.alternateMobile?.split("-")[1]
        : "",
      alternateMobileCountryCode: alternateMobile
        ? alternateMobileCountryCode
          ? alternateMobileCountryCode
          : "962"
        : "",
      alternateMobileShortCode: formValues?.alternateMobileShortCode,
      companyExtension: formValues.companyExtension,
      companyNumber: formValues.companyNumber,
      email: formValues.email,
      residenceAddress: {
        latitude: formValues.resLat,
        longitude: formValues.resLng,
        latlong: {
          latitude: formValues.resLat,
          longitude: formValues.resLng,
        },
        flat: formValues.resFlat,
        address: formValues.resAddress,
        country: formValues.resCountry,
        state: formValues.resState,
        city: formValues.resCity,
        zipCode: formValues.resZipCode,
      },
      workAddress: {
        latitude: formValues.workLat,
        longitude: formValues.workLng,
        latlong: {
          latitude: formValues.workLat,
          longitude: formValues.workLng,
        },
        flat: formValues.workFlat,
        address: formValues.workAddress,
        country: formValues.workCountry,
        state: formValues.workState,
        countryISO: "IN",
        city: formValues.workCity,
        zipCode: formValues.workZipCode,
      },
      emailVerified: "YES",
      mobileVerified: "YES",
      primary: "NO",
      system: "NO",
      role: {
        type: selectedStaff,
      },
      profileUrl: "",
    };

    let b64Object = {};

    if (filesAdded?.length > 0) {
      b64Object = {
        ...filesAdded.map((ele) => ({
          url: ele?.b64,
          type: ele?.type,
          profileFileName: ele?.name,
          profileFileExt: ele?.name.split(".").pop(),
        }))[0],
      };
      reqObj = {
        ...reqObj,
        webProfileUrl: b64Object,
      };
    }

    addHrOfficerApi(reqObj);
  };

  return (
    <Formik
      initialValues={InitialValues}
      innerRef={formikRef}
      validateOnMount
      onSubmit={onSubmit}
      validationSchema={AddHrOfficerSchema}>
      {({ values, setFieldValue, handleSubmit }) => {
        return (
          <HrOfficerForm
            formikRef={formikRef}
            isAdd
            isLoading={isLoading}
            values={values}
            setFieldValue={setFieldValue}
            hrOfficerData={state}
            handleSubmit={handleSubmit}
            setReportTo={setReportTo}
            filesAdded={filesAdded}
            setFilesAdded={setFilesAdded}
            images={images}
            setImages={setImages}
            setAddMoreHrOfficer={setAddMoreHrOfficer}
            selectedStaff={selectedStaff}
            setSelectedStaff={setSelectedStaff}
          />
        );
      }}
    </Formik>
  );
};

export default AddHrOfficer;

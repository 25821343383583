import Translate from "../../../../translation/utils/translateFunction";

export const getContactRepoSelectMemeberColumns = (
  customCellRender,
  CustomCellActionRender
) => {
  const columns = [
    {
      field: "serialNumber",
      headerName: Translate("contacts:srNo"),
      sortable: false,
      disableColumnMenu: true,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "name",
      headerName: Translate("contacts:userName"),
      flex: 0.5,
      sortable: false,
      disableColumnMenu: true,
      renderCell: customCellRender,
    },
    {
      field: "role",

      headerName: Translate("contacts:role"),
      flex: 0.5,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "Action",
      headerName: Translate("contacts:action"),
      flex: 0.2,
      sortable: false,
      disableColumnMenu: true,

      renderCell: CustomCellActionRender,
    },
  ];
  return columns;
};

import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import PersonalAssistantForm from "../Components/PersonalAssistantForm";
import { showToast } from "../../../../common/Utils/ToastMessage";
import { InitialValues } from "../Components/constants";
import {
  useViewPersonalAssistantDataMutation,
  useRatePersonalAssistantMutation,
} from "../../../../request/Staff/PersonalAssistant";
import { Formik } from "formik";

const ViewPersonalAssistant = () => {
  const { state } = useLocation();
  const formikRef = useRef(null);
  const [filesAdded, setFilesAdded] = useState([]);
  const [images, setImages] = useState([]);
  const [isRating, setIsRating] = useState(false);
  const [isRedZone, setIsRedZone] = useState(false);

  const [
    getPersonalAssistantApi,
    {
      data: paData,
      isLoading: isPaLoading,
      isSuccess: isViewSuccess,
      isError: isViewError,
      error: errorViewPA,
    },
  ] = useViewPersonalAssistantDataMutation();

  const [
    ratePersonalAssistantApi,
    {
      data: paRateData,
      isLoading: isPaRateLoading,
      isSuccess: isRateSuccess,
      isError: isRateError,
      error: ratePAError,
    },
  ] = useRatePersonalAssistantMutation();

  useEffect(() => {
    if (state) {
      getPersonalAssistantApi(state);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPersonalAssistantApi, state]);

  useEffect(() => {
    if (isViewError && errorViewPA) {
      showToast({
        message: errorViewPA?.data?.message,
        type: "error",
      });
    }

    if (isViewSuccess && paData) {
      const data = paData?.data[0];

      setIsRedZone(data.zone === "GREEN" ? false : true);

      if (data) {
        formikRef.current.setFieldValue(
          "companyId",
          data?.company?.map((item) => item?._id)
        );
        formikRef.current.setFieldValue("name", data?.name);
        formikRef.current.setFieldValue("dob", new Date(data?.dob));
        formikRef.current.setFieldValue("designation", data?.designation);
        formikRef.current.setFieldValue("department", data?.department);
        // formikRef.current.setFieldValue("mobile", data?.login.mobile);
        formikRef.current.setFieldValue(
          "mobile",
          `${data?.login?.countryCode}-${data?.login?.mobile}`
        );
        formikRef.current.setFieldValue(
          "mobileShortCode",
          data?.login.mobileShortCode
        );
        formikRef.current.setFieldValue("countryCode", data?.login.countryCode);
        // formikRef.current.setFieldValue(
        //   "alternateMobile",
        //   data?.alternateMobile
        // );
        if (data?.alternateMobile) {
          formikRef.current.setFieldValue(
            "alternateMobile",

            `${data?.alternateMobileCountryCode}-${data?.alternateMobile}`
          );
        }
        formikRef.current.setFieldValue(
          "alternateMobileCountryCode",
          data?.alternateMobileCountryCode
            ? data?.alternateMobileCountryCode
            : "962"
        );
        formikRef.current.setFieldValue(
          "alternateMobileShortCode",
          data?.alternateMobileShortCode
        );
        formikRef.current.setFieldValue("companyNumber", data?.companyNumber);
        formikRef.current.setFieldValue(
          "companyExtension",
          data?.companyExtension
        );
        formikRef.current.setFieldValue("email", data?.login.email);
        formikRef.current.setFieldValue("resFlat", data?.residenceAddress.flat);
        formikRef.current.setFieldValue(
          "resState",
          data?.residenceAddress.state
        );
        formikRef.current.setFieldValue(
          "resCountry",
          data?.residenceAddress.country
        );
        formikRef.current.setFieldValue("resCity", data?.residenceAddress.city);
        formikRef.current.setFieldValue(
          "resZipCode",
          data?.residenceAddress.zipCode
        );
        formikRef.current.setFieldValue(
          "resAddress",
          data?.residenceAddress.address
        );
        formikRef.current.setFieldValue(
          "resLat",
          data?.residenceAddress.latlong.latitude
        );
        formikRef.current.setFieldValue(
          "resLng",
          data?.residenceAddress.latlong.longitude
        );
        formikRef.current.setFieldValue("workFlat", data?.workAddress.flat);
        formikRef.current.setFieldValue("workState", data?.workAddress.state);
        formikRef.current.setFieldValue(
          "workCountry",
          data?.workAddress.country
        );
        formikRef.current.setFieldValue("workCity", data?.workAddress.city);
        formikRef.current.setFieldValue(
          "workZipCode",
          data?.workAddress.zipCode
        );
        formikRef.current.setFieldValue(
          "workAddress",
          data?.workAddress.address
        );
        formikRef.current.setFieldValue(
          "workLat",
          data?.workAddress.latlong.latitude
        );
        formikRef.current.setFieldValue(
          "workLng",
          data?.workAddress.latlong.longitude
        );
        formikRef.current.setFieldValue("gender", data?.gender);

        if (data?.profileImageDetails) {
          // const obj = {
          //   path: decodeURIComponent(data?.profileUrl).split("/").pop(),
          //   type: "image/png",
          //   url: data?.profileUrl,
          // };
          setFilesAdded([...filesAdded, data?.profileImageDetails]);
          setImages([data?.profileImageDetails?.url]);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paData, errorViewPA]);

  useEffect(() => {
    if (ratePAError && isRateError) {
      showToast({
        message: ratePAError?.data?.message,
        type: "error",
      });
    }

    if (isRateSuccess && paRateData) {
      showToast({ message: paRateData.message, type: "success" });
      setIsRating((prev) => !prev);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paRateData, ratePAError]);

  const onSubmit = () => {
    const bodyObj = {
      zone: isRedZone ? "RED" : "GREEN",
    };

    ratePersonalAssistantApi({
      id: paData?.data[0]._id,
      body: bodyObj,
    });
  };

  return (
    <Formik
      initialValues={InitialValues}
      innerRef={formikRef}
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue, handleSubmit, errors }) => {
        return (
          <PersonalAssistantForm
            isView
            isLoading={isPaLoading || isPaRateLoading}
            formikRef={formikRef}
            values={values}
            setFieldValue={setFieldValue}
            paData={paData?.data[0]}
            handleSubmit={handleSubmit}
            setReportTo={() => {}}
            filesAdded={filesAdded}
            setFilesAdded={setFilesAdded}
            images={images}
            setImages={setImages}
            isRating={isRating}
            setIsRating={setIsRating}
            isRedZone={isRedZone}
            setIsRedZone={setIsRedZone}
            errors={errors}
          />
        );
      }}
    </Formik>
  );
};

export default ViewPersonalAssistant;

import React, { Fragment } from "react";
import _ from "lodash";
import OrganizationCard from "./OrganizationCard";
import { Persona } from "../../Persona";

export function DeployNodes(nodes, horizontal = false) {
  const rectNodes = _.map(nodes, function (n, index) {
    if (n.hidden === false) {
      return (
        <Fragment key={index}>
          {n.type === "image" ? (
            <div
              style={{ position: "absolute", left: n?.x - 23, top: n?.y - 6 }}
            >
              <Persona image={n?.imgUrl} />
            </div>
          ) : (
            <OrganizationCard
              node={n}
              isCardImg={n.type === "cardImg" ? true : false}
              horizontal={horizontal}
            />
          )}
        </Fragment>
      );
    }
  });
  return rectNodes;
}

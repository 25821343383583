import { Grid, Stack } from "@mui/material";
import { StyledEngineProvider, styled } from "@mui/material/styles";
import { TreeItem, treeItemClasses } from "@mui/x-tree-view";
import { TreeView } from "@mui/x-tree-view/TreeView";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { colors } from "../../common/Theme/Colors";
import {
  FontFamily,
  StyledCard,
  StyledTypography,
} from "../../common/Utils/StyledComponents";
import { useGetTaskDependancyMutation } from "../../request/Task";
import Translate from "../../translation/utils/translateFunction";
import IconifyIcon from "../Icon";
import Loader from "../staff/Owner/LoadingComp";
import { moduleType } from "../Task/Components/constants";
import PageHeader from "../common/pageHeader";

const StyledTreeItem = styled(TreeItem)(
  ({
    rootNode,
    line,
    lineBottom,
    color,
    borderColor = "grey",
    leftColor,
    bottomLineColor,
  }) => {
    const cardBorderColor = borderColor === "none" ? "grey" : borderColor;
    const lineTypeLeft = line;
    const lineTypeBottom =
      lineBottom === 1 ? "solid" : lineBottom === 2 ? "dashed" : "solid";
    const { isRtl } = useSelector((state) => state.formanagement);
    return {
      position: "relative",
      "&:before": {
        pointerEvents: "none",
        content: '""',
        position: "absolute",
        width: 16,
        left: isRtl ? "none" : 0,
        right: isRtl ? 0 : "none",
        top: 16,
        borderBottom: !rootNode
          ? `1.7px ${lineTypeBottom} ${
              bottomLineColor || color || cardBorderColor
            } !important`
          : "none",
      },
      ["& .MuiCollapse-wrapperInner.MuiCollapse-vertical"]: {
        position: "relative",
        borderLeft: isRtl
          ? "none"
          : `1.7px ${lineTypeLeft} ${color || cardBorderColor}`,
        borderRight: isRtl
          ? `1.7px ${lineTypeLeft} ${color || cardBorderColor}`
          : "none",
        marginLeft: isRtl ? 0 : "5px",
        marginRight: isRtl ? "5px" : 0,
        height: "100%",
        paddingTop: "10px",
        marginBottom: "10px",
      },
      ["& .MuiCollapse-vertical.MuiTreeItem-group"]: {
        marginRight: isRtl ? "16px !important" : 0,
      },
      ["& .MuiTreeItem-content"]: {
        flexDirection: "row-reverse",
        border: `0.5px solid ${cardBorderColor}`,
        borderLeft: isRtl
          ? `0.5px solid ${cardBorderColor}`
          : color
          ? `5px solid ${leftColor}`
          : `0.5px solid ${cardBorderColor}`,
        borderRight: isRtl
          ? color
            ? `5px solid ${leftColor}`
            : `0.5px solid ${cardBorderColor}`
          : `0.5px solid ${cardBorderColor}`,
        width: "fit-content",
        marginLeft: isRtl ? 0 : 16,
        marginRight: isRtl ? 16 : 0,
        padding: 5,
      },
      ["& .MuiTreeItem-iconContainer"]: {
        marginLeft: 15,
      },
      ["& .MuiTreeItem-iconContainer svg"]: {
        fontSize: "12px !important",
      },
      ["& .MuiTreeItem-label"]: {
        fontFamily: `${FontFamily} !important`,
      },
      [`& .${treeItemClasses.group}`]: {
        marginLeft: 16,
        position: "relative",
        "&::before": {
          content: '""',
          position: "absolute",
          height: "100%",
          left: 0,
          top: 0,
          // borderLeft: `1.7px ${lineType} ${borderColor}`,
        },
      },
    };
  }
);

const TaskDependancy = () => {
  // const location = useLocation();               //add code if view task dependancy from webview
  // const query = new URLSearchParams(location.search);

  const { isFixNavbar, isRtl } = useSelector((state) => state.formanagement);
  const navigate = useNavigate();
  const { state } = useLocation();
  const [expandArray, setExpandArray] = useState([1, 2, 3]);
  const [fetchTaskDependancy, { data: serverResponse, isLoading }] =
    useGetTaskDependancyMutation();
  const requesObj = useMemo(() => {
    return {
      taskId: state?.taskId,
    };
  }, [state]);
  useEffect(() => {
    fetchTaskDependancy(requesObj);
  }, [requesObj]);
  const navigateTo = (module, taskId) => {
    module === moduleType.SUBTASK
      ? navigate("/subtask/view", {
          state: {
            taskId: taskId,
            isTaskFilter: true,
          },
        })
      : navigate("/taskDetails", {
          state: {
            taskId: taskId,
          },
        });
  };
  const onExpandCollapseClick = (e, id, isExpand) => {
    e.stopPropagation();
    if (isExpand) {
      expandArray.push(id);
    } else {
      const getId =
        !!expandArray.length && expandArray.findIndex((item) => item === id);
      expandArray.splice(getId, 1);
    }

    setExpandArray([...expandArray]);
  };
  const renderLegends = () => {
    return (
      <Stack flexDirection={"row"} justifyContent={"center"} mr={"10rem"}>
        <Stack
          flexDirection={"row"}
          alignItems={"flex-start"}
          px={"10px"}
          py={"30px"}>
          <Stack
            mx={1}
            sx={{
              height: 25,
              width: "0.5px",
              border: `1px solid ${colors.primary}`,
            }}
          />
          <StyledTypography
            sx={{
              fontSize: "14px",
              fontWeight: "500",
            }}>
            {Translate("addTask:taskDependancyHeader1")}
          </StyledTypography>
        </Stack>
        <Stack
          flexDirection={"row"}
          alignItems={"flex-start"}
          px={"10px"}
          py={"30px"}>
          <Stack
            mx={1}
            sx={{
              height: 25,
              width: "0.5px",
              border: `1px dashed ${colors.grey}`,
            }}
          />
          <StyledTypography
            sx={{
              fontSize: "14px",
              fontWeight: "500",
            }}>
            {Translate("addTask:taskDependancyHeader2")}
          </StyledTypography>
        </Stack>
        <Stack
          flexDirection={"row"}
          alignItems={"flex-start"}
          px={"10px"}
          py={"30px"}>
          <Stack
            mx={1}
            sx={{
              height: 25,
              width: "0.5px",
              border: `1px solid ${colors.blue_001}`,
            }}
          />
          <StyledTypography
            sx={{
              fontSize: "14px",
              fontWeight: "500",
            }}>
            {Translate("addTask:taskDependancyHeader3")}
          </StyledTypography>
        </Stack>
      </Stack>
    );
  };
  const RenderExpand = ({ onExpandClick }) => (
    <IconifyIcon icon={"teenyicons:right-solid"} onClick={onExpandClick} />
  );
  const RenderCollapse = ({ onCollapseClick }) => (
    <IconifyIcon icon={"teenyicons:down-solid"} onClick={onCollapseClick} />
  );
  return (
    <Grid
      item
      xs={12}
      className={`section-body ${isRtl ? "rtlMargin" : "ltrMargin"} ${
        isFixNavbar ? "marginTop" : ""
      } `}
      sx={{ height: "85vh" }}>
      <StyledCard variant="outlined" sx={{ height: "86vh" }}>
        {!serverResponse?.data ? (
          <Loader loading={isLoading} />
        ) : (
          <div>
            <PageHeader
              title={serverResponse?.data?.title}
              onClick={() => navigate(-1)}
              containerClass="page-title formTitle p-3 row align-items-center"
            />

            <div className="container-fluid">
              <div className="row" style={{ justifyContent: "space-between" }}>
                <StyledEngineProvider injectFirst>
                  <TreeView
                    defaultCollapseIcon={
                      <IconifyIcon
                        icon={"teenyicons:down-solid"}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      />
                    }
                    defaultExpandIcon={
                      <IconifyIcon
                        icon={"teenyicons:right-solid"}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      />
                    }
                    expanded={expandArray}
                    sx={{ height: "100%", m: 3, maxWidth: "30%" }}>
                    <span
                      style={{
                        fontSize: "12px",
                        fontFamily: FontFamily,
                        fontWeight: 500,
                        marginInline: 10,
                      }}>
                      {serverResponse?.data?.userType}
                    </span>
                    <StyledTreeItem
                      rootNode
                      style={{ marginBottom: "10px" }}
                      nodeId={serverResponse?.data?.id}
                      label={serverResponse?.data?.title}
                      line={serverResponse?.data?.lineType}
                      color={serverResponse?.data?.color}
                      leftColor={serverResponse?.data?.color}
                      collapseIcon={
                        <RenderCollapse
                          onCollapseClick={(e) =>
                            onExpandCollapseClick(
                              e,
                              serverResponse?.data?.id,
                              false
                            )
                          }
                        />
                      }
                      expandIcon={
                        <RenderExpand
                          onExpandClick={(e) =>
                            onExpandCollapseClick(
                              e,
                              serverResponse?.data?.id,
                              true
                            )
                          }
                        />
                      }
                      onClick={(e) => {
                        e.stopPropagation();
                        navigateTo(
                          serverResponse?.data?.type,
                          serverResponse?.data?.taskId
                        );
                      }}
                      borderColor={serverResponse?.data?.borderColor}>
                      {serverResponse?.data?.assignToTasks?.map(
                        (assignedTask) => (
                          <>
                            <span
                              style={{
                                fontSize: "12px",
                                fontFamily: FontFamily,
                                fontWeight: 500,
                                marginInline: 10,
                              }}>
                              {assignedTask.userType}
                            </span>
                            <StyledTreeItem
                              style={{ marginBottom: "10px" }}
                              nodeId={assignedTask.id}
                              label={assignedTask.title}
                              line={assignedTask?.lineType}
                              color={assignedTask?.lineColor}
                              leftColor={assignedTask?.color}
                              bottomLineColor={
                                !!assignedTask?.subTasks?.length
                                  ? serverResponse?.data?.lineColor
                                  : assignedTask?.lineColor
                              }
                              collapseIcon={
                                <RenderCollapse
                                  onCollapseClick={(e) =>
                                    onExpandCollapseClick(
                                      e,
                                      assignedTask?.id,
                                      false
                                    )
                                  }
                                />
                              }
                              expandIcon={
                                <RenderExpand
                                  onExpandClick={(e) =>
                                    onExpandCollapseClick(
                                      e,
                                      assignedTask?.id,
                                      true
                                    )
                                  }
                                />
                              }
                              onClick={() =>
                                navigateTo(
                                  assignedTask?.type,
                                  assignedTask?.taskId
                                )
                              }
                              borderColor={assignedTask?.borderColor}>
                              {(!!assignedTask?.relatedTasks?.length ||
                                !!assignedTask?.subTasks?.length ||
                                !!assignedTask?.reassignToTasks?.length) && (
                                <>
                                  {assignedTask?.reassignToTasks?.map(
                                    (reassignedTask) => (
                                      <>
                                        <span
                                          style={{
                                            fontSize: "12px",
                                            fontFamily: FontFamily,
                                            fontWeight: 500,
                                            marginInline: 10,
                                          }}>
                                          {reassignedTask.userType}
                                        </span>
                                        <StyledTreeItem
                                          style={{ marginBottom: "10px" }}
                                          nodeId={reassignedTask.id}
                                          label={reassignedTask.title}
                                          line={reassignedTask?.lineType}
                                          color={reassignedTask.lineColor}
                                          leftColor={reassignedTask?.color}
                                          collapseIcon={
                                            <RenderCollapse
                                              onCollapseClick={(e) =>
                                                onExpandCollapseClick(
                                                  e,
                                                  reassignedTask?.id,
                                                  false
                                                )
                                              }
                                            />
                                          }
                                          expandIcon={
                                            <RenderExpand
                                              onExpandClick={(e) =>
                                                onExpandCollapseClick(
                                                  e,
                                                  reassignedTask?.id,
                                                  true
                                                )
                                              }
                                            />
                                          }
                                          onClick={() =>
                                            navigateTo(
                                              reassignedTask?.type,
                                              reassignedTask?.taskId
                                            )
                                          }
                                          borderColor={
                                            reassignedTask?.borderColor
                                          }>
                                          {(!!reassignedTask?.subTasks
                                            ?.length ||
                                            !!reassignedTask?.relatedTasks
                                              ?.length) && (
                                            <>
                                              {reassignedTask?.subTasks?.map(
                                                (reassignedSubTask) => (
                                                  <>
                                                    {reassignedSubTask.userType && (
                                                      <span
                                                        style={{
                                                          fontSize: "12px",
                                                          fontFamily:
                                                            FontFamily,
                                                          fontWeight: 500,
                                                          marginInline: 10,
                                                        }}>
                                                        {
                                                          reassignedSubTask.userType
                                                        }
                                                      </span>
                                                    )}
                                                    <StyledTreeItem
                                                      style={{
                                                        marginBottom: "10px",
                                                      }}
                                                      nodeId={
                                                        reassignedSubTask.id
                                                      }
                                                      label={
                                                        reassignedSubTask.title
                                                      }
                                                      line={
                                                        reassignedSubTask?.lineType
                                                      }
                                                      leftColor={
                                                        reassignedSubTask?.color
                                                      }
                                                      collapseIcon={
                                                        <RenderCollapse
                                                          onCollapseClick={(
                                                            e
                                                          ) =>
                                                            onExpandCollapseClick(
                                                              e,
                                                              reassignedSubTask?.id,
                                                              false
                                                            )
                                                          }
                                                        />
                                                      }
                                                      expandIcon={
                                                        <RenderExpand
                                                          onExpandClick={(e) =>
                                                            onExpandCollapseClick(
                                                              e,
                                                              reassignedSubTask?.id,
                                                              true
                                                            )
                                                          }
                                                        />
                                                      }
                                                      onClick={() =>
                                                        navigateTo(
                                                          reassignedSubTask?.type,
                                                          reassignedSubTask?.taskId
                                                        )
                                                      }
                                                      lineBottom={2}
                                                      color={
                                                        reassignedSubTask.color
                                                      }
                                                      borderColor={
                                                        reassignedSubTask?.borderColor
                                                      }>
                                                      {reassignedSubTask?.assignToTasks?.map(
                                                        (
                                                          reassignedAssignedTask
                                                        ) => (
                                                          <>
                                                            <span
                                                              style={{
                                                                fontSize:
                                                                  "12px",
                                                                fontFamily:
                                                                  FontFamily,
                                                                fontWeight: 500,
                                                                marginInline: 10,
                                                              }}>
                                                              {
                                                                reassignedAssignedTask.userType
                                                              }
                                                            </span>
                                                            <StyledTreeItem
                                                              style={{
                                                                marginBottom:
                                                                  "10px",
                                                              }}
                                                              nodeId={
                                                                reassignedAssignedTask.id
                                                              }
                                                              label={
                                                                reassignedAssignedTask.title
                                                              }
                                                              line={
                                                                reassignedAssignedTask?.lineType
                                                              }
                                                              leftColor={
                                                                reassignedAssignedTask?.color
                                                              }
                                                              collapseIcon={
                                                                <RenderCollapse
                                                                  onCollapseClick={(
                                                                    e
                                                                  ) =>
                                                                    onExpandCollapseClick(
                                                                      e,
                                                                      reassignedAssignedTask?.id,
                                                                      false
                                                                    )
                                                                  }
                                                                />
                                                              }
                                                              expandIcon={
                                                                <RenderExpand
                                                                  onExpandClick={(
                                                                    e
                                                                  ) =>
                                                                    onExpandCollapseClick(
                                                                      e,
                                                                      reassignedAssignedTask?.id,
                                                                      true
                                                                    )
                                                                  }
                                                                />
                                                              }
                                                              onClick={() =>
                                                                navigateTo(
                                                                  reassignedAssignedTask?.type,
                                                                  reassignedAssignedTask?.taskId
                                                                )
                                                              }
                                                              color={
                                                                reassignedAssignedTask.color
                                                              }
                                                              borderColor={
                                                                reassignedAssignedTask?.borderColor
                                                              }
                                                            />
                                                          </>
                                                        )
                                                      )}
                                                    </StyledTreeItem>
                                                  </>
                                                )
                                              )}
                                              {reassignedTask?.relatedTasks?.map(
                                                (reassignedRelatedTask) => (
                                                  <>
                                                    {reassignedRelatedTask.userType && (
                                                      <span
                                                        style={{
                                                          fontSize: "12px",
                                                          fontFamily:
                                                            FontFamily,
                                                          fontWeight: 500,
                                                          marginInline: 10,
                                                        }}>
                                                        {
                                                          reassignedRelatedTask.userType
                                                        }
                                                      </span>
                                                    )}
                                                    <StyledTreeItem
                                                      style={{
                                                        marginBottom: "10px",
                                                      }}
                                                      nodeId={
                                                        reassignedRelatedTask.id
                                                      }
                                                      label={
                                                        reassignedRelatedTask.title
                                                      }
                                                      line={
                                                        reassignedRelatedTask?.lineType
                                                      }
                                                      leftColor={
                                                        reassignedRelatedTask?.color
                                                      }
                                                      collapseIcon={
                                                        <RenderCollapse
                                                          onCollapseClick={(
                                                            e
                                                          ) =>
                                                            onExpandCollapseClick(
                                                              e,
                                                              reassignedRelatedTask?.id,
                                                              false
                                                            )
                                                          }
                                                        />
                                                      }
                                                      expandIcon={
                                                        <RenderExpand
                                                          onExpandClick={(e) =>
                                                            onExpandCollapseClick(
                                                              e,
                                                              reassignedRelatedTask?.id,
                                                              true
                                                            )
                                                          }
                                                        />
                                                      }
                                                      onClick={() =>
                                                        navigateTo(
                                                          reassignedRelatedTask?.type,
                                                          reassignedRelatedTask?.taskId
                                                        )
                                                      }
                                                      lineBottom={2}
                                                      color={
                                                        reassignedRelatedTask.color
                                                      }>
                                                      {reassignedRelatedTask?.assignToTasks?.map(
                                                        (
                                                          reassignedAssignedTask
                                                        ) => (
                                                          <>
                                                            <span
                                                              style={{
                                                                fontSize:
                                                                  "12px",
                                                                fontFamily:
                                                                  FontFamily,
                                                                fontWeight: 500,
                                                                marginInline: 10,
                                                              }}>
                                                              {
                                                                reassignedAssignedTask.userType
                                                              }
                                                            </span>
                                                            <StyledTreeItem
                                                              style={{
                                                                marginBottom:
                                                                  "10px",
                                                              }}
                                                              nodeId={
                                                                reassignedAssignedTask.id
                                                              }
                                                              label={
                                                                reassignedAssignedTask.title
                                                              }
                                                              line={
                                                                reassignedAssignedTask?.lineType
                                                              }
                                                              leftColor={
                                                                reassignedAssignedTask?.color
                                                              }
                                                              collapseIcon={
                                                                <RenderCollapse
                                                                  onCollapseClick={(
                                                                    e
                                                                  ) =>
                                                                    onExpandCollapseClick(
                                                                      e,
                                                                      reassignedAssignedTask?.id,
                                                                      false
                                                                    )
                                                                  }
                                                                />
                                                              }
                                                              expandIcon={
                                                                <RenderExpand
                                                                  onExpandClick={(
                                                                    e
                                                                  ) =>
                                                                    onExpandCollapseClick(
                                                                      e,
                                                                      reassignedAssignedTask?.id,
                                                                      true
                                                                    )
                                                                  }
                                                                />
                                                              }
                                                              onClick={() =>
                                                                navigateTo(
                                                                  reassignedAssignedTask?.type,
                                                                  reassignedAssignedTask?.taskId
                                                                )
                                                              }
                                                              color={
                                                                reassignedAssignedTask.color
                                                              }
                                                            />
                                                          </>
                                                        )
                                                      )}
                                                    </StyledTreeItem>
                                                  </>
                                                )
                                              )}
                                            </>
                                          )}
                                        </StyledTreeItem>
                                      </>
                                    )
                                  )}
                                  {assignedTask?.subTasks?.map(
                                    (assignedSubTask) => (
                                      <>
                                        {assignedSubTask.userType && (
                                          <span
                                            style={{
                                              fontSize: "12px",
                                              fontFamily: FontFamily,
                                              fontWeight: 500,
                                              marginInline: 10,
                                            }}>
                                            {assignedSubTask.userType}
                                          </span>
                                        )}
                                        <StyledTreeItem
                                          nodeId={assignedSubTask.id}
                                          label={assignedSubTask.title}
                                          line={assignedSubTask?.lineType}
                                          leftColor={assignedSubTask?.color}
                                          lineBottom={2}
                                          collapseIcon={
                                            <RenderCollapse
                                              onCollapseClick={(e) =>
                                                onExpandCollapseClick(
                                                  e,
                                                  assignedSubTask?.id,
                                                  false
                                                )
                                              }
                                            />
                                          }
                                          expandIcon={
                                            <RenderExpand
                                              onExpandClick={(e) =>
                                                onExpandCollapseClick(
                                                  e,
                                                  assignedSubTask?.id,
                                                  true
                                                )
                                              }
                                            />
                                          }
                                          onClick={() =>
                                            navigateTo(
                                              assignedSubTask?.type,
                                              assignedSubTask?.taskId
                                            )
                                          }
                                          color={assignedSubTask.color}
                                          borderColor={
                                            assignedSubTask?.borderColor
                                          }
                                        />
                                      </>
                                    )
                                  )}
                                  {assignedTask?.relatedTasks?.map(
                                    (relatedTask) => (
                                      <>
                                        <span
                                          style={{
                                            fontSize: "12px",
                                            fontFamily: FontFamily,
                                            fontWeight: 500,
                                            marginInline: 10,
                                          }}>
                                          {relatedTask?.userType}
                                        </span>
                                        <StyledTreeItem
                                          nodeId={relatedTask.id}
                                          label={relatedTask.title}
                                          line={assignedTask?.lineType}
                                          leftColor={relatedTask?.color}
                                          collapseIcon={
                                            <RenderCollapse
                                              onCollapseClick={(e) =>
                                                onExpandCollapseClick(
                                                  e,
                                                  relatedTask?.id,
                                                  false
                                                )
                                              }
                                            />
                                          }
                                          expandIcon={
                                            <RenderExpand
                                              onExpandClick={(e) =>
                                                onExpandCollapseClick(
                                                  e,
                                                  relatedTask?.id,
                                                  true
                                                )
                                              }
                                            />
                                          }
                                          onClick={() =>
                                            navigateTo(
                                              assignedTask?.type,
                                              assignedTask?.taskId
                                            )
                                          }
                                          color={relatedTask.color}>
                                          {!!relatedTask?.subTasks?.length && (
                                            <>
                                              {relatedTask?.subTasks?.map(
                                                (relatedTaskSubTask) => (
                                                  <>
                                                    {relatedTaskSubTask.userType && (
                                                      <span
                                                        style={{
                                                          fontSize: "12px",
                                                          fontFamily:
                                                            FontFamily,
                                                          fontWeight: 500,
                                                          marginInline: 10,
                                                        }}>
                                                        {
                                                          relatedTaskSubTask.userType
                                                        }
                                                      </span>
                                                    )}
                                                    <StyledTreeItem
                                                      nodeId={
                                                        relatedTaskSubTask.id
                                                      }
                                                      label={
                                                        relatedTaskSubTask.title
                                                      }
                                                      line={
                                                        relatedTaskSubTask?.lineType
                                                      }
                                                      leftColor={
                                                        relatedTaskSubTask?.color
                                                      }
                                                      collapseIcon={
                                                        <RenderCollapse
                                                          onCollapseClick={(
                                                            e
                                                          ) =>
                                                            onExpandCollapseClick(
                                                              e,
                                                              relatedTaskSubTask?.id,
                                                              false
                                                            )
                                                          }
                                                        />
                                                      }
                                                      expandIcon={
                                                        <RenderExpand
                                                          onExpandClick={(e) =>
                                                            onExpandCollapseClick(
                                                              e,
                                                              relatedTaskSubTask?.id,
                                                              true
                                                            )
                                                          }
                                                        />
                                                      }
                                                      onClick={() =>
                                                        navigateTo(
                                                          relatedTaskSubTask?.type,
                                                          relatedTaskSubTask?.taskId
                                                        )
                                                      }
                                                      lineBottom={2}
                                                      color={
                                                        relatedTaskSubTask.color
                                                      }
                                                      borderColor={
                                                        relatedTaskSubTask.borderColor
                                                      }
                                                    />
                                                  </>
                                                )
                                              )}
                                            </>
                                          )}
                                        </StyledTreeItem>
                                      </>
                                    )
                                  )}
                                </>
                              )}
                            </StyledTreeItem>
                          </>
                        )
                      )}
                      {serverResponse?.data?.subTasks?.map((subTask) => (
                        <>
                          {subTask.userType && (
                            <span
                              style={{
                                fontSize: "12px",
                                fontFamily: FontFamily,
                                fontWeight: 500,
                                marginInline: 10,
                              }}>
                              {subTask.userType}
                            </span>
                          )}
                          <StyledTreeItem
                            nodeId={subTask.id}
                            label={subTask.title}
                            line={subTask?.lineType}
                            lineBottom={2}
                            color={subTask.lineColor}
                            leftColor={subTask?.color}
                            collapseIcon={
                              <RenderCollapse
                                onCollapseClick={(e) =>
                                  onExpandCollapseClick(e, subTask?.id, false)
                                }
                              />
                            }
                            expandIcon={
                              <RenderExpand
                                onExpandClick={(e) =>
                                  onExpandCollapseClick(e, subTask?.id, true)
                                }
                              />
                            }
                            onClick={() =>
                              navigateTo(subTask?.type, subTask?.taskId)
                            }
                            borderColor={subTask?.borderColor}
                          />
                        </>
                      ))}
                      {serverResponse?.data?.relatedTasks?.map(
                        (relatedTask) => (
                          <>
                            <span
                              style={{
                                fontSize: "12px",
                                fontFamily: FontFamily,
                                fontWeight: 500,
                                marginInline: 10,
                              }}>
                              {relatedTask?.userType}
                            </span>
                            <StyledTreeItem
                              nodeId={relatedTask.id}
                              label={relatedTask.title}
                              line={relatedTask?.lineType}
                              color={relatedTask.lineColor}
                              leftColor={relatedTask?.color}
                              collapseIcon={
                                <RenderCollapse
                                  onCollapseClick={(e) =>
                                    onExpandCollapseClick(
                                      e,
                                      relatedTask?.id,
                                      false
                                    )
                                  }
                                />
                              }
                              expandIcon={
                                <RenderExpand
                                  onExpandClick={(e) =>
                                    onExpandCollapseClick(
                                      e,
                                      relatedTask?.id,
                                      true
                                    )
                                  }
                                />
                              }
                              onClick={() =>
                                navigateTo(
                                  relatedTask?.type,
                                  relatedTask?.taskId
                                )
                              }
                              borderColor={relatedTask?.borderColor}>
                              {!!relatedTask?.subTasks?.length && (
                                <>
                                  {relatedTask?.subTasks?.map(
                                    (relatedTaskSubTask) => (
                                      <>
                                        {relatedTaskSubTask.userType && (
                                          <span
                                            style={{
                                              fontSize: "12px",
                                              fontFamily: FontFamily,
                                              fontWeight: 500,
                                              marginInline: 10,
                                            }}>
                                            {relatedTaskSubTask.userType}
                                          </span>
                                        )}
                                        <StyledTreeItem
                                          nodeId={relatedTaskSubTask.id}
                                          label={relatedTaskSubTask.title}
                                          line={relatedTaskSubTask?.lineType}
                                          lineBottom={2}
                                          color={relatedTaskSubTask.lineColor}
                                          leftColor={relatedTaskSubTask?.color}
                                          collapseIcon={
                                            <RenderCollapse
                                              onCollapseClick={(e) =>
                                                onExpandCollapseClick(
                                                  e,
                                                  relatedTaskSubTask?.id,
                                                  false
                                                )
                                              }
                                            />
                                          }
                                          expandIcon={
                                            <RenderExpand
                                              onExpandClick={(e) =>
                                                onExpandCollapseClick(
                                                  e,
                                                  relatedTaskSubTask?.id,
                                                  true
                                                )
                                              }
                                            />
                                          }
                                          onClick={() =>
                                            navigateTo(
                                              relatedTaskSubTask?.type,
                                              relatedTaskSubTask?.taskId
                                            )
                                          }
                                          borderColor={
                                            relatedTaskSubTask.borderColor
                                          }
                                        />
                                      </>
                                    )
                                  )}
                                </>
                              )}
                            </StyledTreeItem>
                          </>
                        )
                      )}
                    </StyledTreeItem>
                  </TreeView>
                  {renderLegends()}
                </StyledEngineProvider>
              </div>
            </div>
          </div>
        )}
      </StyledCard>
    </Grid>
  );
};
export default TaskDependancy;

import { Divider, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import { colors } from "../../../common/Theme/Colors";
import { FontSizes } from "../../../common/Theme/Fonts";
import Translate from "../../../translation/utils/translateFunction";
import NoDataFound from "../../ManageTask/NoDataFound";
import { Persona } from "../../Persona";
import TextWrapWithTooltip from "../../TextWrapWithTooltip";
import Searchbar from "../../common/Searchbar";
import IconifyIcon from "../../Icon";
import { useSetInviteeSelectMutation } from "../../../request/Calendar";

const ParticipantModal = ({
  show,
  onHide,
  participantList,
  handleInvitee,
  hideClose,
}) => {
  const { selectedCompanyList } = useSelector((state) => state.formanagement);
  const [searchText, setSearchText] = useState();
  const [participants, setParticipants] = useState(participantList);
  const [setInviteeSelect] = useSetInviteeSelectMutation();

  useEffect(() => {
    if (searchText) {
      const tempList = participantList.filter((item) =>
        item?.name?.toLowerCase()?.includes(searchText?.toLowerCase())
      );
      setParticipants([...tempList]);
    } else {
      setParticipants([...participantList]);
    }
  }, [searchText]);
  const updateParticipants = (item, index) => {
    participants.splice(index, 1);
    setParticipants([...participants]);
    const requestObj = {
      moduleInvitee: {
        companyId: selectedCompanyList?.map((company) => company?.value),
        users: participants?.map((itemm) => itemm?._id) || [],
      },
      moduleName: "EVENT",
      deselectUsers: [item?._id],
    };
    setInviteeSelect(requestObj);
    handleInvitee([...participants]);
  };
  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      scrollable={true}
      className="col-span-3 col-md-12">
      <Modal.Header closeButton>
        <Modal.Title>{Translate("createEvent:invitedHeader")}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={styles.outerMainView}>
        <Stack>
          <Stack mb={2}>
            <Searchbar
              isShareModal
              setSearch={setSearchText}
              handleSearchText={setSearchText}
            />
          </Stack>
          {participants?.length ? (
            participants?.map((item, index) => (
              <Stack>
                <Stack
                  py={1}
                  flexDirection={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}>
                  <Stack flexDirection={"row"} alignItems={"center"}>
                    <Persona name={item?.name} size={40} className={"mr-2"} />
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div style={{ display: "flex" }}>
                        <TextWrapWithTooltip
                          textVariant="span"
                          customClassName="shareUserEllipsisTitle text-info ml-2 mt-1"
                          label={item?.name}
                        />
                        <span className="text-info ml-2 mt-1">{"|"}</span>
                        <span className="text-info ml-2 mt-1">{item.role}</span>
                      </div>

                      {item?.email && (
                        <TextWrapWithTooltip
                          textVariant="span"
                          customClassName="shareUserEllipsisSubTitle text-info ml-2 mt-1"
                          label={item?.email}
                        />
                      )}
                    </div>
                  </Stack>
                  {!hideClose && (
                    <Stack>
                      <IconifyIcon
                        icon={"ic:outline-close"}
                        className="cursor-pointer"
                        fontSize="22"
                        onClick={() => updateParticipants(item, index)}
                      />
                    </Stack>
                  )}
                </Stack>
                {participants?.length - 1 !== index && <Divider />}
              </Stack>
            ))
          ) : (
            <NoDataFound isCenter />
          )}
        </Stack>
      </Modal.Body>
    </Modal>
  );
};

export default ParticipantModal;

const styles = {
  outerMainView: {
    backgroundColor: "#f5f5f996",
    borderRadius: 5,
    paddingInline: 20,
    minHeight: "calc(100vh - 400px)",
    maxHeight: "calc(100vh - 400px)",
    overflow: "auto",
  },
  closeIcon: {
    alignSelf: "flex-end",
  },
  card: {
    backgroundColor: colors.white,
    borderRadius: 3,
    marginVertical: 10,
  },
  displayFrame: {
    paddingBottom: 0,
    paddingTop: 10,
    flex: 1,
  },
  titleText: {
    color: colors.primary,
    fontSize: FontSizes.regular,
  },
  description: {
    fontSize: FontSizes.xSmall,
  },
};

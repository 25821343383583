import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Switch from "react-switch";
import { colors } from "../../../../common/Theme/Colors";
import { StyledTypography } from "../../../../common/Utils/StyledComponents";
import { showToast } from "../../../../common/Utils/ToastMessage";
import {
  useRateStaffManagerMutation,
  useViewStaffManagerDataMutation,
} from "../../../../request/Staff/Manager";
import Translate from "../../../../translation/utils/translateFunction";
import { Persona } from "../../../Persona";
import RadioButtons from "../../../RadioButtons";
import { TextField } from "../../../TextField/TextField";
import Loader from "../../Owner/LoadingComp";
import { radioOptions } from "./constants";
import PageHeader from "../../../common/pageHeader";
import { Formik } from "formik";
import FormikSearchableDropDown from "../../../FormikForm/component/FormikSearchableDropDown";
import { CustomUserItem } from "../../../common/CustomCellRender";

const ViewManager = () => {
  const navigate = useNavigate();
  const formikRef = useRef(null);
  const { state } = useLocation();
  const { validations } = useSelector((state) => state?.formanagement);
  const [reportTo, setReportTo] = useState("");

  const [
    viewManagerApi,
    {
      data: viewManagerData,
      isSuccess: successfullyViewManager,
      isError: errorAddingViewManager,
      error: errorViewManager,
      isLoading: isLoadingViewManager,
    },
  ] = useViewStaffManagerDataMutation();

  const [
    rateManagerApi,
    {
      data: rateManagerData,
      isSuccess: rateSuccessfullyManager,
      isError: isErrorRateManager,
      error: errorRateManager,
    },
  ] = useRateStaffManagerMutation();

  const [managerData, setMangerData] = useState({});
  const [isRating, setIsRating] = useState(false);
  const [isRedZone, setIsRedZone] = useState(false);

  useEffect(() => {
    viewManagerApi(state);
  }, [viewManagerApi, state]);

  useEffect(() => {
    if (errorAddingViewManager && errorViewManager) {
      showToast({
        message: errorViewManager?.data?.message,
        type: "error",
      });
    }

    if (successfullyViewManager && viewManagerData) {
      const { name, role, profileUrl, email } =
        viewManagerData?.data[0]?.reportTo;

      setMangerData(viewManagerData?.data[0]);
      setIsRedZone(viewManagerData?.data[0].zone === "GREEN" ? false : true);
      setReportTo({
        value: name,
        label: (
          <CustomUserItem
            userName={name}
            title={role ? `${name} | ${role}` : name}
            profileUrl={profileUrl}
            email={email}
          />
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewManagerData, errorViewManager]);

  useEffect(() => {
    if (errorRateManager && isErrorRateManager) {
      showToast({
        message: errorRateManager?.data?.message,
        type: "error",
      });
    }

    if (rateSuccessfullyManager && rateManagerData) {
      showToast({ message: rateManagerData.message, type: "success" });
      setIsRating((prev) => !prev);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rateManagerData, errorRateManager]);

  const onSubmit = () => {
    let bodyObj = {
      zone: isRedZone ? "RED" : "GREEN",
    };
    rateManagerApi({
      body: bodyObj,
      id: viewManagerData?.data[0]._id,
    });
  };

  return (
    <>
      <div className="row container_spacing">
        <div className="card card_spacing">
          <PageHeader
            title={Translate("addManager:viewManagerHeading")}
            onClick={() => navigate("/managerListing")}
            RightContainer={() =>
              viewManagerData?.data[0].isEditable && (
                <div className="card-options">
                  <i
                    className={`icon-color ml-3 cursor-pointer ${"fa fa-pencil-square-o"}`}
                    onClick={() =>
                      navigate("/addManager", {
                        state: viewManagerData?.data[0],
                      })
                    }
                    data-toggle="tooltip"
                    title={Translate("common:editToolTip")}
                  ></i>
                </div>
              )
            }
          />
          <div className="card-body">
            <div className="row imageDisplay mb-2">
              <div className="form-group col-md-4 col-sm-8">
                <Persona
                  name={managerData?.name}
                  image={managerData?.profileImageDetails?.url}
                  size={96}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-4 col-sm-12">
                <TextField
                  value={
                    managerData?.company?.length > 0
                      ? managerData?.company[0]?.name
                      : ""
                  }
                  label={Translate("addManager:selectCompany")}
                  placeholder={Translate("addManager:dropdownPlaceholder")}
                  autoComplete={"off"}
                  maxLength={validations?.nameLength.MAX}
                  disabled={true}
                  className={"form-control textFieldHeight"}
                />
              </div>
              <div className="form-group col-md-4 col-sm-12">
                <TextField
                  value={managerData?.name}
                  label={Translate("addManager:name")}
                  placeholder={Translate("addManager:name")}
                  autoComplete={"off"}
                  maxLength={validations?.nameLength.MAX}
                  disabled={true}
                  className={"form-control textFieldHeight"}
                />
              </div>
              <div className="form-group col-md-4 col-sm-12">
                <TextField
                  label={Translate("addManager:dob")}
                  value={managerData?.dob}
                  placeholder={Translate("addManager:dob")}
                  disabled={true}
                  className={"form-control textFieldHeight"}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-4 col-sm-12">
                <TextField
                  value={managerData?.designation}
                  label={Translate("addManager:designation")}
                  placeholder={Translate("addManager:designation")}
                  autoComplete={"off"}
                  maxLength={validations?.designationLength.MAX}
                  disabled={true}
                  className={"form-control textFieldHeight"}
                />
              </div>
              <div className="form-group col-md-4 col-sm-12">
                <TextField
                  value={managerData?.department}
                  label={Translate("addManager:department")}
                  placeholder={Translate("addManager:department")}
                  autoComplete={"off"}
                  maxLength={validations?.departmentLength.MAX}
                  disabled={true}
                  className={"form-control textFieldHeight"}
                />
              </div>
              <div className="form-group col-md-4 col-sm-12">
                <TextField
                  value={managerData?.login?.mobileWithCountryCode}
                  label={Translate("addManager:contactNumber")}
                  placeholder={Translate("addManager:contactNumberPlaceHolder")}
                  autoComplete={"off"}
                  maxLength={validations?.contactPhoneLength.MAX}
                  disabled={true}
                  className={"form-control textFieldHeight"}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-4 col-sm-12">
                <TextField
                  value={
                    managerData?.alternateMobileWithCountryCode
                      ? managerData?.alternateMobileWithCountryCode
                      : "NA"
                  }
                  label={Translate("addManager:alternate_contactNumber")}
                  placeholder={Translate("addManager:contactNumberPlaceHolder")}
                  autoComplete={"off"}
                  maxLength={validations?.contactPhoneLength.MAX}
                  disabled={true}
                  className={"form-control textFieldHeight"}
                />
              </div>
              <div className="form-group col-md-4 col-sm-12">
                <TextField
                  value={managerData?.hrMobileWithCountryCode || "NA"}
                  label={Translate("addManager:hrContactNumber")}
                  placeholder={Translate("addManager:contactNumberPlaceHolder")}
                  autoComplete={"off"}
                  maxLength={validations?.contactPhoneLength.MAX}
                  disabled={true}
                  className={"form-control textFieldHeight"}
                />
              </div>
              <div className="form-group col-md-4 col-sm-12 ">
                <TextField
                  value={managerData?.companyNumberWithExtension || "NA"}
                  label={Translate("addManager:companyExtensionNumber")}
                  placeholder={Translate(
                    "addManager:extensionNumberPlaceholder"
                  )}
                  autoComplete={"off"}
                  maxLength={validations?.telephoneLength.MAX}
                  disabled={true}
                  className={"form-control textFieldHeight"}
                />
              </div>
              <div className="form-group col-md-4 col-sm-12">
                <TextField
                  value={managerData?.login?.email}
                  label={Translate("addManager:email")}
                  placeholder={Translate("addManager:emailPlaceholder")}
                  autoComplete={"off"}
                  disabled={true}
                  className={"form-control textFieldHeight"}
                />
              </div>
              <div className="form-group col-md-4 col-sm-12">
                <Formik
                  innerRef={formikRef}
                  initialValues={{ reportTo: "" }}
                  validateOnMount
                >
                  {({}) => {
                    return (
                      <FormikSearchableDropDown
                        selected={reportTo}
                        options={[]}
                        label={Translate("addPersonalAssistant:reportTo")}
                        placeholder={Translate("addPersonalAssistant:reportTo")}
                        name="reportTo"
                        disabled
                      />
                    );
                  }}
                </Formik>
              </div>
            </div>

            <div className="row">
              <div className="form-group col-md-12 col-sm-12">
                <div className="AddressLabel">
                  <StyledTypography variant="subtitle1">
                    {Translate("addManager:address")}
                  </StyledTypography>
                </div>
                <TextField
                  value={managerData?.residenceAddress?.address}
                  placeholder={Translate("addManager:addressPlaceholder_1")}
                  autoComplete={"off"}
                  isLocation={true}
                  locationValue={() => {}}
                  disabled={true}
                  className={"form-control textFieldHeight"}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-12 col-sm-12">
                <TextField
                  value={managerData?.residenceAddress?.flat}
                  placeholder={Translate("addManager:addressPlaceholder_2")}
                  autoComplete={"off"}
                  maxLength={validations?.landmarkLimit.MAX}
                  disabled={true}
                  className={"form-control textFieldHeight"}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-4 col-sm 8">
                <TextField
                  placeholder={Translate("addManager:countryPlaceholder")}
                  value={managerData?.residenceAddress?.country}
                  disabled={true}
                  maxLength={validations?.cityLimit.MAX}
                  className={"form-control textFieldHeight"}
                />
              </div>
              <div className="form-group col-md-4 col-sm 8">
                <TextField
                  placeholder={Translate("addManager:statePlaceholder")}
                  value={managerData?.residenceAddress?.state}
                  disabled={true}
                  maxLength={validations?.cityLimit.MAX}
                  className={"form-control textFieldHeight"}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-4 col-sm 8">
                <TextField
                  value={managerData?.residenceAddress?.city}
                  placeholder={Translate("addManager:cityPlaceholder")}
                  maxLength={validations?.cityLimit.MAX}
                  disabled={true}
                  className={"form-control textFieldHeight"}
                />
              </div>
              <div className="form-group col-md-4 col-sm 8">
                <TextField
                  value={managerData?.residenceAddress?.zipCode}
                  maxLength={6}
                  placeholder={Translate("addManager:zipcodePlaceholder")}
                  disabled={true}
                  className={"form-control textFieldHeight"}
                />
              </div>
            </div>

            <div className="row">
              <div className="form-group col-md-12 col-sm-12">
                <div className="AddressLabel">
                  <StyledTypography variant="subtitle1">
                    {Translate("addManager:workAddress")}
                  </StyledTypography>
                </div>
                <TextField
                  value={managerData?.workAddress?.address}
                  placeholder={Translate("addManager:addressPlaceholder_3")}
                  autoComplete={"off"}
                  isLocation={true}
                  locationValue={() => {}}
                  disabled={true}
                  className={"form-control textFieldHeight"}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-12 col-sm-12">
                <TextField
                  value={managerData?.workAddress?.flat}
                  placeholder={Translate("addManager:addressPlaceholder_4")}
                  autoComplete={"off"}
                  maxLength={validations?.landmarkLimit.MAX}
                  disabled={true}
                  className={"form-control textFieldHeight"}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-4 col-sm 8">
                <TextField
                  value={managerData?.workAddress?.country}
                  placeholder={Translate("addManager:countryPlaceholder")}
                  disabled={true}
                  maxLength={validations?.cityLimit.MAX}
                  className={"form-control textFieldHeight"}
                />
              </div>
              <div className="form-group col-md-4 col-sm 8">
                <TextField
                  value={managerData?.workAddress?.state}
                  placeholder={Translate("addManager:statePlaceholder")}
                  disabled={true}
                  maxLength={validations?.cityLimit.MAX}
                  className={"form-control textFieldHeight"}
                />
              </div>
            </div>

            <div className="row">
              <div className="form-group col-md-4 col-sm 8">
                <TextField
                  value={managerData?.workAddress?.city}
                  placeholder={Translate("addManager:cityPlaceholder")}
                  maxLength={validations?.cityLimit.MAX}
                  disabled={true}
                  className={"form-control textFieldHeight"}
                />
              </div>
              <div className="form-group col-md-4 col-sm 8">
                <TextField
                  value={managerData?.workAddress?.zipCode}
                  maxLength={6}
                  placeholder={Translate("addManager:zipcodePlaceholder")}
                  disabled={true}
                  className={"form-control textFieldHeight"}
                />
              </div>
            </div>
            {managerData?.gender && (
              <div className="row">
                <div className="form-group col-md-4 col-sm 8">
                  <RadioButtons
                    radioOptions={radioOptions}
                    defaultChecked={managerData?.gender === "MALE" ? 0 : 1}
                    label={Translate("addManager:gender")}
                    disabled
                  />
                </div>
              </div>
            )}
            {managerData.isHrManager && (
              <div className="row">
                <div className="parent-container form-group col-md-4 col-sm 8">
                  <StyledTypography
                    className="labelStyling"
                    component="span"
                    style={{ marginRight: 10 }}
                  >
                    {Translate("addManager:marskAsHrManager")}
                  </StyledTypography>
                  <input
                    type="checkbox"
                    checked={managerData.isHrManager}
                    className="custom-control-checkbox"
                    style={{ "pointer-events": "none" }}
                    label={Translate("addManager:marskAsHrManager")}
                  />
                </div>
              </div>
            )}
            {isRating && (
              <div>
                <div>
                  <span style={{ color: colors.primary_003 }}>
                    {Translate("common:rate")}
                  </span>
                </div>
                <div className="row">
                  <h7
                    className="nav-link"
                    style={{
                      color: isRedZone ? colors.red : colors.green,
                      paddingBottom: 10,
                    }}
                  >
                    {isRedZone ? "Red zone" : "Green zone"}
                  </h7>
                  <Switch
                    onChange={() => {
                      setIsRedZone((prev) => !prev);
                    }}
                    checked={isRedZone}
                    height={25}
                    width={50}
                    onHandleColor={colors.primary}
                    onColor={colors.primary_002}
                  />
                </div>
              </div>
            )}

            {(managerData?.isRateable || managerData?.isPerformanceAccess) && (
              <div className="card-footer text-right">
                {managerData?.isPerformanceAccess && (
                  <button
                    type="submit"
                    className={`btn btn-primary saveButton ${
                      isRating && "staffPerformanceBtn"
                    }`}
                    onClick={() =>
                      navigate("/staff/performance", {
                        state: { userData: managerData },
                      })
                    }
                  >
                    {Translate("common:performance")}
                  </button>
                )}
                {managerData?.isRateable &&
                  (!isRating ? (
                    <button
                      type="submit"
                      className="btn btn-white addButton"
                      onClick={() => {
                        setIsRating((prev) => !prev);
                      }}
                    >
                      {Translate("common:rate")}
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="btn btn-primary saveButton"
                      onClick={() => {
                        onSubmit();
                      }}
                    >
                      {Translate("addManager:save")}
                    </button>
                  ))}
              </div>
            )}
          </div>
        </div>
      </div>
      <Loader loading={isLoadingViewManager} />
    </>
  );
};
export default ViewManager;

import { Formik } from "formik";
import React, { memo, useEffect, useMemo, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Translate from "../../../translation/utils/translateFunction";
import FormikSearchableDropDown from "../../FormikForm/component/FormikSearchableDropDown";
import { FormikTextField } from "../../FormikForm/component/FormikTextField";
import {
  RequestContactInitialvalues,
  centerContent,
  contactype,
} from "./constant";
import {
  useGetRequestContactApiMutation,
  useGetrequestContactListMutation,
} from "../../../request/ContactRepo";
import { useSelector } from "react-redux";
import { showToast } from "../../../common/Utils/ToastMessage";
import { getSentenseCaseforSingleWord } from "../../../common/Utils/commonFunction";
import { Persona } from "../../Persona";
import { RequestContactSchema } from "./utils";
import { CustomUserItem } from "../../common/CustomCellRender";

const RequestContact = ({
  showModal,
  closeModal,
  onSuccessfulRequestContact,
  contactListType,
}) => {
  const [requestTo, setrequestTo] = useState("");
  const [requestToList, setRequestToList] = useState([]);
  const [search, setSearch] = useState("");

  const { selectedCompanyList, validations } = useSelector(
    (state) => state.formanagement
  );

  const formikRef = useRef(null);
  const [
    requestContactListApi,
    {
      data: requestContactList,
      isSuccess: isRequestContactSuccess,
      isLoading: isRequestContactLoading,
      isError: isRequestContactError,
      error: requestContactError,
    },
  ] = useGetrequestContactListMutation();

  const [
    requestContactApi,
    {
      data: requestContactData,
      isLoading: isLoadingRequestContactData,
      isSuccess: isSuccessRequestContactData,
      isError: isErrorRequestContactData,
      error: errorRequestContactData,
    },
  ] = useGetRequestContactApiMutation();

  const requestObject = useMemo(() => {
    const companies = selectedCompanyList?.length
      ? selectedCompanyList?.map((item) => item?.value)
      : [];

    const finalReq = {
      companyId: companies,
      searchText: search,
    };

    return companies?.length ? finalReq : null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, selectedCompanyList]);

  useEffect(() => {
    requestContactListApi(requestObject);
  }, [requestObject]);

  const handleSubmit = (values) => {
    const requestContactObj = {
      companyId: requestTo?.companyId,
      requestTo: [values.requestTo],
      contactType: contactListType,
      contactNeeded: values.contactNeeded,
    };
    requestContactApi(requestContactObj);
  };

  useEffect(() => {
    if (errorRequestContactData && isRequestContactError) {
      errorRequestContactData.data.error &&
        showToast({
          message: errorRequestContactData?.data?.message,
          type: "error",
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    requestContactList,
    errorRequestContactData,
    isRequestContactError,
    isRequestContactSuccess,
  ]);

  useEffect(() => {
    if (requestContactError && isErrorRequestContactData) {
      requestContactError.data.error &&
        showToast({
          message: requestContactError?.data?.message,
          type: "error",
        });
    }
    if (requestContactData && isSuccessRequestContactData) {
      showToast({ message: requestContactData?.message, type: "success" });
      onSuccessfulRequestContact(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    requestContactData,
    requestContactError,
    isErrorRequestContactData,
    isSuccessRequestContactData,
  ]);

  useEffect(() => {
    if (requestContactList && isRequestContactSuccess) {
      setSearchableData(requestContactList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestContactList, isRequestContactSuccess]);

  const setSearchableData = (res) => {
    const requestToData = [];

    res?.data.length > 0 &&
      res.data.map((item) => {
        const { name, userId, profile, role, companyId, email } = item;
        const obj = {
          id: userId,
          value: name,
          name,
          profile,
          companyId,
          label: (
            <CustomUserItem
              userName={name}
              title={role ? `${name} | ${role}` : name}
              profileUrl={profile}
              email={email}
            />
          ),
        };
        requestToData.push(obj);
      });

    setRequestToList([...requestToData]);
  };

  return (
    <div className="requestModal">
      <Modal
        show={showModal}
        onHide={closeModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
        className="col-span-3 col-md-12"
        // style={{ height: "75%", border: "2px solid red" }}
      >
        <Formik
          initialValues={RequestContactInitialvalues}
          validateOnMount
          onSubmit={handleSubmit}
          innerRef={formikRef}
          validationSchema={RequestContactSchema}
        >
          {({ setFieldValue, submitForm, isValid }) => {
            return (
              <>
                <Modal.Header closeButton>
                  <Modal.Title>
                    {Translate("contacts:requestContactModal")}
                  </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                  <div
                    style={{
                      minHeight: "calc(100vh - 340px)",
                      maxHeight: "calc(100vh - 500px)",

                      overflow: "auto",
                    }}
                  >
                    <div>
                      <div className="form-group col-md-12 col-sm-12">
                        <FormikSearchableDropDown
                          selected={requestTo}
                          onSelect={(value) => {
                            setrequestTo(value);
                            setFieldValue("requestTo", value.id);
                          }}
                          options={requestToList}
                          onChange={(text) => setSearch(text.trim())}
                          placeholder={Translate("contacts:requestTo")}
                          label={Translate("contacts:requestTo")}
                          name="requestTo"
                          required
                        />
                      </div>
                      <div className="form-group col-md-12 col-sm-12">
                        <FormikTextField
                          name="contactNeeded"
                          placeholder={Translate("contacts:contactNeeded")}
                          autoComplete={"off"}
                          multiline
                          noOfRows={5}
                          maxLength={validations.companyNameLimit.MAX}
                          label={Translate("contacts:contactNeeded")}
                          required
                        />
                      </div>
                    </div>
                  </div>
                </Modal.Body>

                <Modal.Footer>
                  <Button
                    variant="btn btn-white addButton"
                    onClick={closeModal}
                  >
                    {Translate("common:close")}
                  </Button>
                  <Button
                    variant={"btn btn-primary saveButton"}
                    onClick={() => {
                      submitForm();
                    }}
                    disabled={!isValid}
                    type="submit"
                  >
                    {Translate("contacts:sendRequest")}
                  </Button>
                </Modal.Footer>
              </>
            );
          }}
        </Formik>
      </Modal>
    </div>
  );
};
export default memo(RequestContact);

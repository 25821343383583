import Translate from "../../translation/utils/translateFunction";

export const getTodaysEventColumns = (
  SubjectCellRender,
  customCellRender,
  CustomDueDateTodaysCellRender,
  CustomStartDateTodaysCellRender
) => {
  const columns = [
    {
      field: "serialNumber",
      headerName: Translate("common:srNoTitle"),
      align: "center",
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "subject",
      headerName: Translate("dashboard:subject"),
      minWidth: 300,
      maxWidth: 300,
      sortable: true,
      disableColumnMenu: true,
      renderCell: SubjectCellRender,
    },
    {
      field: "description",
      headerName: Translate("dashboard:description"),
      minWidth: 300,
      maxWidth: 300,
      sortable: true,
      disableColumnMenu: true,
    },

    {
      field: "formattedStartTime",
      headerName: Translate("dashboard:startTime"),
      minWidth: 150,
      maxWidth: 150,
      sortable: true,
      disableColumnMenu: true,
      renderCell: CustomStartDateTodaysCellRender,
    },

    {
      field: "formattedEndTime",
      headerName: Translate("dashboard:endTime"),
      minWidth: 150,
      maxWidth: 150,
      sortable: true,
      disableColumnMenu: true,
      renderCell: CustomDueDateTodaysCellRender,
    },
    {
      field: "Action",
      headerName: Translate("common:actionTitle"),
      minWidth: 150,
      maxWidth: 150,
      sortable: false,
      disableColumnMenu: true,
      renderCell: customCellRender,
    },
  ];
  return columns;
};

import { Icon } from "@iconify/react";
import moment from "moment";
import React from "react";
import FoM_logo from "../../../assets/images/FM_logo_card.svg";
import {
  DateTimeFormats,
  formatDateYYYYMMDDWithSlash,
} from "../../../common/Utils/DateFormater";
import Translate from "../../../translation/utils/translateFunction";
import { useSelector } from "react-redux";

const ViewBusinessCardCommon = ({
  viewBusinessCard,
  isViewLoading,
  companyName,
}) => {
  const { isRtl } = useSelector((state) => state.formanagement);

  if (!viewBusinessCard._id) return null;
  return (
    <>
      <div key={viewBusinessCard?._id}>
        <div className="card businessCardBackgroundColor">
          <div className="card_spacing">
            <div>
              <div className="contactBusinessCardParentDiv mt-3">
                {/* <div
                  className=" businessCardFontColor businessCardContactNameStyling ellipsisEventCard "
                  onMouseOver={handleMouseIn}
                  onMouseOut={handleMouseOut}
                  style={{ flex: "80%" }}
                >
                  {isViewLoading || !viewBusinessCard?.contactName
                    ? "--"
                    : viewBusinessCard?.contactName}
                </div>
                <div
                  className={"showTooltip"}
                  style={{ display: hover ? "block" : "none" }}
                >
                  {isViewLoading || !viewBusinessCard?.contactName
                    ? "--"
                    : viewBusinessCard?.contactName}
                </div> */}
                <span
                  tabindex="0"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title={
                    isViewLoading || !viewBusinessCard?.contactName
                      ? "--"
                      : viewBusinessCard?.contactName
                  }
                  class="d-inline-block ellipsisEventCard "
                  style={{ flex: "80%" }}
                >
                  <div className=" businessCardFontColor businessCardContactNameStyling ellipsisEventCard  ">
                    {isViewLoading || !viewBusinessCard?.contactName
                      ? "--"
                      : viewBusinessCard?.contactName}
                  </div>
                </span>

                <div className="businessCardLogoStyles">
                  <div
                    className="text-center"
                    style={{
                      width: "30%",
                      marginRight: 10,
                    }}
                  >
                    <img src={FoM_logo} alt="Example SVG" />
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div className="TaskStylingDesignation">
                <div className="taskCardContentStyling commonWidth businessCardFontColor businessCardDesignation">
                  {isViewLoading || !viewBusinessCard?.contactDesignation
                    ? "--"
                    : viewBusinessCard?.contactDesignation}
                </div>
              </div>
            </div>
            <div className="mb-2">
              <div className="TaskStyling">
                <div className="taskCardContentStyling commonWidth businessCardFontColor">
                  <span
                    className={`taskHeading businessCardFontColor ${
                      isRtl ? "viewContact" : "contactViewCompanyName"
                    }`}
                  >
                    {Translate("contacts:companyBusinessCard")}
                  </span>
                  {isViewLoading || !viewBusinessCard?.companyName
                    ? companyName || "--"
                    : viewBusinessCard?.companyName}
                </div>
              </div>
            </div>
            <hr className=" businessCardHrColor"></hr>
            <div>
              <div className="TaskStyling">
                <div className="taskCardContentStyling commonWidth businessCardFontColor businessCardDetailsParentDiv">
                  <div>
                    <Icon
                      icon="ic:outline-phone"
                      className="businessCardFontColor businessCardIcons mr-2"
                      style={{
                        marginBottom: 4,
                      }}
                    />
                  </div>

                  <div className="businessCardDetails">
                    {isViewLoading || !viewBusinessCard?.contactPhone
                      ? "--"
                      : `${viewBusinessCard?.contactPhoneCountryCode || ""}${
                          viewBusinessCard?.contactPhone
                        }`}
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div className="TaskStyling">
                <div className="taskCardContentStyling commonWidth businessCardFontColor businessCardDetailsParentDiv">
                  <div>
                    <Icon
                      icon="material-symbols:mail-outline"
                      className="businessCardFontColor businessCardIcons mr-2"
                    />
                  </div>

                  <div className="businessCardDetails">
                    {isViewLoading ||
                    viewBusinessCard?.contactEmail?.length === 0
                      ? "--"
                      : viewBusinessCard?.contactEmail?.[0].email
                      ? viewBusinessCard?.contactEmail?.[0].email
                      : viewBusinessCard?.contactEmail}
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="TaskStyling">
                <div className="taskCardContentStyling commonWidth businessCardFontColor businessCardDetailsParentDiv">
                  <div>
                    <Icon
                      icon="fluent:location-12-regular"
                      className="businessCardFontColor businessCardIcons mr-2"
                    />
                  </div>

                  <div className="businessCardDetails">
                    {isViewLoading || !viewBusinessCard?.contactAddress
                      ? "--"
                      : viewBusinessCard?.contactAddress}
                  </div>
                </div>
              </div>
            </div>

            <div className="float-right py-2 mt-3">
              <div className="list-unstyled mb-0" style={{ display: "flex" }}>
                <span className="mr-2 taskCardContentStyling businessCardFooterColor">
                  <span>{Translate("contacts:sourceTitle")} </span>
                  {viewBusinessCard?.addedBy?.name},
                </span>
                <span className="mr-2 taskCardContentStyling businessCardFooterColor">
                  {moment(viewBusinessCard?.createdAt).format("YYYY/MM/DD")}
                </span>
                <span className="mr-2 taskCardContentStyling businessCardFooterColor">
                  {moment(viewBusinessCard?.createdAt).format(
                    DateTimeFormats.Time
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ViewBusinessCardCommon;

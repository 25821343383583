import * as yup from "yup";
import Translate from "../../../../translation/utils/translateFunction";
import { validateMobileSchema } from "../../../../common/Utils/commonFunction";

export const AddManagerSchema = () => {
  const schema = yup.object().shape({
    companyId: yup
      .string()
      .trim()
      .required(Translate("addManager:companyError")),
    name: yup.string().trim().required(Translate("addManager:nameError")),
    dob: yup.string().trim().required(Translate("addManager:dobError")),
    designation: yup
      .string()
      .trim()
      .required(Translate("addManager:designationError")),
    department: yup
      .string()
      .trim()
      .required(Translate("addManager:departmentError")),
    ...validateMobileSchema("mobile", Translate("addManager:numberError_2")),
    reportTo: yup.string().trim().required(Translate("addTask:reportToError")),
    email: yup
      .string()
      .email(Translate("addManager:workEmailError"))
      .trim()
      .required(Translate("addManager:workEmailError_1")),
    resAddress: yup
      .string()
      .trim()
      .required(Translate("addManager:addressError")),
    workAddress: yup
      .string()
      .trim()
      .required(Translate("addManager:workAddressError")),
    resCountry: yup
      .string()
      .trim()
      .required(Translate("addManager:countryError")),
    resState: yup.string().trim().required(Translate("addManager:stateError")),
    workCountry: yup
      .string()
      .trim()
      .required(Translate("addManager:countryError")),
    workState: yup.string().trim().required(Translate("addManager:stateError")),
  });

  return schema;
};

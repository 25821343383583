import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import {
  LocalizationProvider,
  StaticDatePicker,
  pickersLayoutClasses,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import React from "react";
import { Modal } from "react-bootstrap";
import Translate from "../../translation/utils/translateFunction";
import { getDayJs } from "../../common/Utils/commonFunction";

const DatePickerModal = ({
  showDatePickerModal,
  value,
  setValue,
  setFilterSelectedDate,
  closeDatePickerModal,
  handleApply,
}) => {
  const customActionButtons = (props) => {
    const { onAccept, onCancel } = props;
    const actions = [
      { text: Translate("common:cancel"), method: onCancel },
      { text: Translate("common:apply"), method: onAccept },
    ];
    return (
      <List sx={{ display: "flex", justifyContent: "flex-end" }}>
        {actions.map(({ text, method }) => (
          <ListItem
            key={text}
            disablePadding
            className={
              text === Translate("common:apply")
                ? "btn btn-primary saveButton"
                : "btn btn-white addButton"
            }
            sx={{
              minWidth: 80,
              width: 80,
              height: 40,
              marginInline: "8px",
              paddingLeft: 0,
            }}>
            <ListItemButton onClick={method}>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    );
  };
  return (
    <Modal
      show={showDatePickerModal}
      onHide={closeDatePickerModal}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      className="col-span-3 col-md-12">
      <Modal.Body>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <StaticDatePicker
            slotProps={{
              layout: {
                sx: {
                  [`.${pickersLayoutClasses.contentWrapper}`]: {
                    display: "block",
                  },
                },
              },
            }}
            slots={{
              actionBar: customActionButtons,
            }}
            orientation="portrait"
            defaultValue={value}
            onAccept={(newValue) => {
              newValue && setValue(newValue);
              newValue && setFilterSelectedDate(dayjs(newValue).toISOString());
              handleApply();
            }}
            maxDate={getDayJs()}
            onClose={closeDatePickerModal}
          />
        </LocalizationProvider>
      </Modal.Body>
    </Modal>
  );
};
export default DatePickerModal;

import * as yup from "yup";
import Translate from "../../translation/utils/translateFunction";

export const addTaskSchema = () => {
  const schema = yup.object().shape({
    company: yup.string().trim().required(Translate("addTask:companyReqError")),
    /*  type: yup.string().trim().required(Translate("addTask:titleError")), */
    projectId: yup.string().trim().required(Translate("addTask:projectError")),
    title: yup.string().trim().required(Translate("addTask:nameError")),
    description: yup
      .string()
      .trim()
      .required(Translate("addTask:descriptionError")),
    assignTo: yup.string().trim().required(Translate("addTask:assignToError")),
    reportTo: yup.string().trim().required(Translate("addTask:reportToError")),
    startDate: yup
      .string()
      .trim()
      .required(Translate("addTask:startDateError")),
    dueDate: yup.string().trim().required(Translate("addTask:dueDateError")),
    startTime: yup
      .string()
      .trim()
      .required(Translate("addTask:startTimeError")),
    dueTime: yup.string().trim().required(Translate("addTask:dueTimeError")),
  });

  return schema;
};

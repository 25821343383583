import Translate from "../../../../translation/utils/translateFunction";
import {
  CustomDueDateCellRender,
  CustomPriorityCellRender,
  CustomStatusCellRender,
} from "../../Components/utils";

export const getReAllocationTaskColumns = (
  CustomSrCellRender,
  CustomActionCellRender
) => {
  const columns = [
    {
      field: "lineNo",
      headerName: Translate("common:srNoTitle"),
      align: "center",
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      flex: 0.17,
      renderCell: CustomSrCellRender,
    },
    {
      field: "taskNumber",
      headerName: Translate("manageTask:taskIdTitle"),
      flex: 0.3,
      sortable: false,
      disableColumnMenu: true,
      valueGetter: (params) => params.row?.taskNumber,
    },

    {
      field: "projectId",
      headerName: Translate("manageTask:projectTitle"),

      flex: 0.4,
      sortable: false,
      disableColumnMenu: true,

      renderCell: (params) => {
        if (params?.row?.projectDetails) {
          return (
            <div className="projectFieldParent">
              <span className="projectNumberField">
                {params?.row?.projectDetails?.projectNumber}
              </span>

              <span
                tabindex="0"
                data-toggle="tooltip"
                data-placement="bottom"
                title={params?.row?.projectDetails?.title}
                class="d-inline-block ellipsisEventCard "
                style={{ flex: "80%", width: "100%" }}
              >
                <div className="projectNameField">
                  {params?.row?.projectDetails?.title}
                </div>
              </span>
            </div>
          );
        } else {
          return <span>{`Independent task`}</span>;
        }
      },
    },
    // {
    //   field: "type",
    //   headerName: Translate("manageTask:taskTypeTitle"),
    //   flex: 0.35,
    //   sortable: false,
    //   disableColumnMenu: true,
    //   renderCell: CustomTaskTypeCellRender,
    // },
    {
      field: "title",
      headerName: Translate("manageTask:taskNameTitle"),
      flex: 0.5,
      sortable: false,
      disableColumnMenu: true,
      valueGetter: (params) => params.row?.title,
    },
    {
      field: "companyName",
      headerName: Translate("manageTask:companyNameTitle"),
      flex: 0.5,
      sortable: false,
      disableColumnMenu: true,
      valueGetter: (params) => params.row?.company?.name,
    },
    {
      field: "assigneeName",
      headerName: Translate("manageTask:assigneeNameTitle"),
      flex: 0.35,
      sortable: false,
      disableColumnMenu: true,
      valueGetter: (params) => params.row?.userDetails[1].name,
    },
    {
      field: "dueDate",
      headerName: Translate("manageTask:dueDateAndTimeTitle"),
      flex: 0.3,
      sortable: false,
      disableColumnMenu: true,
      renderCell: CustomDueDateCellRender,
    },
    {
      field: "priority",
      headerName: Translate("manageTask:priorityTitle"),
      flex: 0.25,
      sortable: false,
      disableColumnMenu: true,
      renderCell: CustomPriorityCellRender,
    },
    {
      field: "taskStatus",
      headerName: Translate("manageTask:statusTitle"),
      flex: 0.32,
      sortable: false,
      disableColumnMenu: true,
      renderCell: CustomStatusCellRender,
    },
    {
      field: "Action",
      headerName: Translate("common:actionTitle"),
      flex: 0.28,
      align: "center",
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      hide: true,
      renderCell: CustomActionCellRender,
    },
  ];
  return columns;
};

import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { showToast } from "../../../common/Utils/ToastMessage";
import { useGetSharedMomUsersListMutation } from "../../../request/Mom";
import Translate from "../../../translation/utils/translateFunction";
import { Grid, Stack } from "@mui/material";
import {
  StyledCard,
  StyledTypography,
} from "../../../common/Utils/StyledComponents";
import { colors } from "../../../common/Theme/Colors";
import ListView from "../../ListView";
import { useSelector } from "react-redux";
import { getSharedUsersColumn } from "./components/column.data";
import { Persona } from "../../Persona";

const SharedUsers = ({ fixNavbar }) => {
  const { isRtl } = useSelector((state) => state.formanagement);
  const { state } = useLocation();
  const navigate = useNavigate();
  const [lastPage, setLastPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [sharedUsers, setSharedUsers] = useState([]);

  const [
    getSharedUsersList,
    {
      data: sharedUsersList,
      isLoading: isSharedUserLoading,
      isError: isSharedUserError,
      error: sharedUserError,
    },
  ] = useGetSharedMomUsersListMutation();

  useEffect(() => {
    if (state) {
      getSharedUsersList({
        momId: state,
        pageNo: currentPage,
      });
    }
  }, [state, currentPage]);

  useEffect(() => {
    if (sharedUsersList?.data?.record) {
      const { record, pageInfo } = sharedUsersList.data;
      setSharedUsers(record);
      setLastPage(pageInfo.lastPageNo);
    }

    if (sharedUserError && isSharedUserError) {
      showToast({
        message:
          sharedUserError?.data?.message ||
          Translate("document:apiFailedMessage"),
        type: "error",
      });
    }
  }, [sharedUsersList, sharedUserError]);

  const CustomSrCellRender = (params) => {
    return (
      <StyledTypography component="span" sx={{ color: colors.primary_009 }}>
        {params?.api?.getRowIndexRelativeToVisibleRows(params?.row?._id) +
          1 +
          (currentPage - 1) * 10}
      </StyledTypography>
    );
  };

  const CustomNameCellRender = (params) => {
    return (
      <div style={{ display: "flex" }}>
        <Persona
          name={params?.row?.name}
          image={params?.row?.profileUrl}
          size={28}
        />
        <span className="text-info ml-2 mt-1">{params?.row?.name}</span>
      </div>
    );
  };

  return (
    <Grid
      item
      xs={12}
      className={`section-body ${isRtl ? "rtlMargin" : "ltrMargin"} ${
        fixNavbar ? "marginTop" : ""
      } `}
    >
      <StyledCard variant="outlined">
        <div className="container-fluid p-4">
          <div className="tab-content">
            <div>
              <div className="card">
                <Stack
                  flexDirection={"row"}
                  alignItems={"center"}
                  marginBottom={5}
                >
                  <i
                    className="icon fe fe-arrow-left mr-3 icon-size backnavigation-iconColor cursor-pointer"
                    onClick={() => {
                      navigate(-1);
                    }}
                    title={Translate("common:backToolTip")}
                  />
                  <h5 className="page-title">
                    {Translate("momPage:sharedUsersTitle")}
                  </h5>
                </Stack>

                <ListView
                  columns={getSharedUsersColumn(
                    CustomSrCellRender,
                    CustomNameCellRender
                  )}
                  rows={sharedUsers}
                  autoHeight
                  onPaginationChange={setCurrentPage}
                  currentPage={currentPage}
                  showShimmer={isSharedUserLoading}
                  showLoader={isSharedUserLoading}
                  totalPageCount={lastPage}
                />
              </div>
            </div>
          </div>
        </div>
      </StyledCard>
    </Grid>
  );
};

export default SharedUsers;

import { Methods, Urls } from "../../common/Utils/ApiConstants";

import { CreateApi } from "../CreateApi";
const InboxModule = CreateApi.injectEndpoints({
  endpoints: (builder) => ({
    getInboxList: builder.mutation({
      query: (requestBody) => {
        return {
          url: Urls.inboxList,
          method: Methods.post,
          body: requestBody,
        };
      },
    }),
    getInboxEmailDetails: builder.mutation({
      query: (body) => {
        return {
          url: `${Urls.viewInboxEmailDetails}${body}`,
          method: Methods.get,
        };
      },
    }),
    setEmailLableAs: builder.mutation({
      query: (body) => {
        return {
          url: Urls.emailLabelAs,
          method: Methods.post,
          body: body,
        };
      },
      invalidatesTags: ["EmailDetails"],
    }),
  }),
});
export const {
  useGetInboxListMutation,
  useGetInboxEmailDetailsMutation,
  useSetEmailLableAsMutation,
} = InboxModule;

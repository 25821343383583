export default {
  notification: "الإشعارات",
  notificationHeader: "الإشعاراتس",
  contact: "جهة الإتصال",
  senderNameTitle: "اسم المرسل",
  descriptionTitle: "الوصف",
  roleTitle: "دور",
  dueDateAndTimeTitle: "التاريخ و الوقت",
  viewHeading: "عرض الإخطار",
};

import { Grid, Stack } from "@mui/material";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { colors } from "../../../common/Theme/Colors";
import { formatFullMonthYear } from "../../../common/Utils/DateFormater";
import {
  StyledCard,
  StyledTypography,
} from "../../../common/Utils/StyledComponents";
import { useGetMyLeaveRequestMutation } from "../../../request/LeaveRequest";
import Translate from "../../../translation/utils/translateFunction";
import PageHeader from "../../common/pageHeader";
import IconifyIcon from "../../Icon";
import ListView from "../../ListView";
import { getMyLeaveRequestColumns } from "./column.data";
import { getMoment } from "../../../common/Utils/commonFunction";
const MyLeaveRequests = () => {
  const { isRtl, fixNavbar, selectedCompanyList } = useSelector(
    (state) => state.formanagement
  );
  const navigate = useNavigate();
  const [navigationFlag, setNavigationFlag] = useState();
  const [dateRange, setDateRange] = useState();
  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [myLeavesList, setMyLeavesList] = useState([]);
  const lastPage = useRef(0);
  const [
    getMyLeaves,
    {
      data: serverResponse,
      isSuccess: isMyLeavesSuccess,
      isLoading: isMyleaveLoading,
    },
  ] = useGetMyLeaveRequestMutation();
  const requestObj = useMemo(() => {
    const companies = selectedCompanyList?.length
      ? selectedCompanyList?.map((item) => item?.value)
      : [];
    return {
      companyId: companies,
      date: dateRange
        ? moment(dateRange).add("d", 1).toISOString()
        : getMoment().toDate(),
      searchText: searchText,
      pageNo: currentPage,
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, searchText, dateRange, selectedCompanyList]);
  useEffect(() => {
    getMyLeaves(requestObj);
  }, [requestObj]);
  useEffect(() => {
    if (isMyLeavesSuccess && serverResponse?.data) {
      const { record, pageInfo } = serverResponse?.data;
      lastPage.current = pageInfo?.lastPageNo;
      setMyLeavesList(record);
    }
  }, [isMyLeavesSuccess]);
  const customSrCellRender = (params) => {
    return (
      <StyledTypography component="span" sx={{ color: colors.primary_009 }}>
        {params?.api?.getRowIndexRelativeToVisibleRows(params?.row?._id) +
          1 +
          (currentPage - 1) * 10}
      </StyledTypography>
    );
  };
  const customActionCellRender = (params) => {
    return (
      <td>
        <i
          className="m-2 fa fa-eye cursor-pointer manageIconColor"
          data-toggle="tooltip"
          title={Translate("common:viewToolTip")}
          onClick={() =>
            navigate("/myLeaveRequests/view", {
              state: { leaveId: params?.row?._id },
            })
          }
        ></i>
      </td>
    );
  };
  return (
    <Grid
      item
      xs={12}
      className={`section-body ${isRtl ? "rtlMargin" : "ltrMargin"} ${
        fixNavbar ? "marginTop" : ""
      } `}
    >
      <StyledCard variant="outlined">
        <div className="container-fluid  p-4">
          <div className="tab-content">
            <div>
              <div className="card">
                <PageHeader
                  title={
                    <h5 className="page-title">
                      {Translate("leaveRequest:myLeave")}
                    </h5>
                  }
                  onClick={() => navigate(-1)}
                  RightContainer={() => (
                    <Stack
                      flexDirection={"row"}
                      alignItems={"center"}
                      className="card-options"
                    >
                      <IconifyIcon
                        className="cursor-pointer"
                        icon={"ant-design:left-circle-outlined"}
                        fontSize="24"
                        style={{ color: colors.black }}
                        onClick={() =>
                          setDateRange(
                            moment(dateRange).subtract("month", 1).format()
                          )
                        }
                      />

                      <StyledTypography
                        variant="h6"
                        px={2}
                        color={colors.primary}
                        sx={{ fontWeight: "500" }}
                      >
                        {formatFullMonthYear(dateRange)}
                      </StyledTypography>

                      <IconifyIcon
                        className="cursor-pointer"
                        icon={"ant-design:right-circle-outlined"}
                        style={{ color: colors.black }}
                        fontSize="24"
                        onClick={() =>
                          setDateRange(
                            moment(dateRange).add("month", 1).format()
                          )
                        }
                      />
                    </Stack>
                  )}
                  containerClass="card-header card-header-spacing formTitle"
                />
                <ListView
                  columns={getMyLeaveRequestColumns(
                    customSrCellRender,
                    customActionCellRender
                  )}
                  rows={myLeavesList}
                  autoHeight
                  sortingMode="server"
                  onPaginationChange={setCurrentPage}
                  currentPage={currentPage}
                  showShimmer={isMyleaveLoading}
                  showLoader={isMyleaveLoading}
                  totalPageCount={lastPage.current}
                />
              </div>
            </div>
          </div>
        </div>
      </StyledCard>
    </Grid>
  );
};
export default MyLeaveRequests;

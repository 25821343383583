import dayjs from "dayjs";
import moment from "moment";
import React, { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { showToast } from "../../../../common/Utils/ToastMessage";
import {
  getDayJs,
  getMoment,
  validateDuplicateFiles,
} from "../../../../common/Utils/commonFunction";
import {
  useDeleteSubtaskMutation,
  useLazyGetAssignToCollectionQuery,
  useLazyGetReportToCollectionQuery,
} from "../../../../request/Task";
import Translate from "../../../../translation/utils/translateFunction";
import Priority from "../../../AddTask/Priority";
import AlertPermission from "../../../AlertPermission";
import Dropzone from "../../../CommonFileUpload/Dropzone";
import FormikDatePicker from "../../../FormikForm/component/FormikDatePicker.js";
import FormikSearchableDropDown from "../../../FormikForm/component/FormikSearchableDropDown";
import { FormikTextField } from "../../../FormikForm/component/FormikTextField";
import CommonImage from "../../../ImageGallery/CommonImage";
import ScrollToTop from "../../../ScrollToTop";
import PageHeader from "../../../common/pageHeader";
import Loader from "../../../staff/Owner/LoadingComp";
import {
  handleEventKeySubmit,
  stopEventPropogation,
} from "../../../../common/Utils/commonFunction";
import { CustomUserItem } from "../../../common/CustomCellRender.js";
import { userDefaultDropdownItem } from "../../../../common/Utils/ApiConstants/index.js";
import { userTypes } from "../../../../common/users/userTypes.js";

const SubtaskForm = ({
  isAdd,
  isEdit,
  formikRef,
  values,
  setFieldValue,
  errors,
  subtaskData,
  handleSubmit,
  selectedCompany,
  images,
  filesAdded,
  setFilesAdded,
  startDateTZ,
  setStartDateTZ,
  dueDateTZ,
  setDueDateTZ,
  isLoading,
}) => {
  const navigate = useNavigate();
  const { userData, validations } = useSelector((state) => state.formanagement);

  const [assignTo, setAssignTo] = useState("");
  const [assignToOptionList, setAssignToOptionList] = useState([]);
  const [reportTo, setReportTo] = useState("");
  const [reportToOptionList, setReportToOptionList] = useState([]);
  const [searchTextAssign, setSearchTextAssign] = useState("");
  const [searchTextReport, setSearchTextReport] = useState("");
  const startDateForDue = false;
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);

  const acceptedFileType = {
    "image/png": [".png", ".jpg", ".jpeg", ".pdf", ".xls", ".xlsx"],
  };

  const [
    getAssignTo,
    {
      data: assignToData,
      isSuccess: isAssignToListSuccess,
      isLoading: loadingAssigneToData,
    },
  ] = useLazyGetAssignToCollectionQuery();

  const [getReportTo, { data: reportToData, isLoading: loadingReportToData }] =
    useLazyGetReportToCollectionQuery();

  const [
    deleteSubtaskApi,
    {
      data: subtaskDeleteData,
      isSuccess: isDeleteSuccess,
      isLoading: isDeleteSubtaskLoading,
      isError: isDeleteError,
      error: deleteSubtaskError,
    },
  ] = useDeleteSubtaskMutation();

  useEffect(() => {
    if (deleteSubtaskError && isDeleteError) {
      showToast({
        message: deleteSubtaskError?.data?.message,
        type: "error",
      });
    }
    if (isDeleteSuccess && subtaskDeleteData) {
      showToast({
        message: subtaskDeleteData.message,
        type: "success",
      });
      navigate("/manageTask");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteSubtaskError, subtaskDeleteData]);

  const convertDueDateUTC = (startDate, dueDate) => {
    let startDatePostFix = startDate.toString().split("T")[0];
    let dueDatePostFix = dueDate.toString().split("T")[1];
    let finalDueDate = startDatePostFix + "T" + dueDatePostFix;
    return finalDueDate;
  };

  useEffect(() => {
    if (userData) {
      setAssignAndReportToMe(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  const setAssignAndReportToMe = () => {
    const assignToObj = {
      id: userData?._id,
      value: userDefaultDropdownItem.ASSIGN_TO_ME,
      label: (
        <CustomUserItem
          userName={userData?.name}
          title={userDefaultDropdownItem.ASSIGN_TO_ME}
          profileUrl={userData?.profileUrl}
          customClass={"addtaskUserEllipsisTitle"}
        />
      ),
    };

    let userReport = {};

    if (userData?.role?.type === userTypes.persoalAssistant) {
      const { reportToUser } = userData;

      userReport = {
        ...reportToUser,
        id: reportToUser?._id,
        role: reportToUser?.role?.type,
        email: reportToUser?.login?.email,
      };
    } else {
      userReport = {
        id: userData?._id,
        name: userData?.name,
        role: userData?.role?.type,
        profileUrl: userData?.profileUrl,
        email: userData?.login?.email,
      };
    }

    const reportToObj = {
      id: userReport?.id,
      value: userReport?.name,
      label: (
        <CustomUserItem
          userName={userReport?.name}
          title={userReport?.name}
          role={userReport?.role}
          profileUrl={userReport?.profileUrl}
          email={userReport?.email}
          customClass={"addtaskUserEllipsisTitle"}
        />
      ),
    };

    setReportTo(reportToObj);
    formikRef.current.setFieldValue("reportTo", reportToObj.id);
    setAssignToOptionList([assignToObj]);
    setReportToOptionList([reportToObj]);
  };

  useEffect(() => {
    if (assignToData && isAssignToListSuccess) {
      setSearchableData(assignToData, true);
    }

    if (reportToData) {
      setSearchableData(reportToData, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignToData, isAssignToListSuccess, reportToData]);

  useEffect(() => {
    const assigneeObj = {
      searchText: searchTextAssign,
      companyId: selectedCompany,
    };

    const reportObj = {
      searchText: searchTextReport,
      companyId: selectedCompany,
    };

    if (selectedCompany) {
      getAssignTo(assigneeObj);
      getReportTo(reportObj);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCompany, searchTextAssign, searchTextReport]);

  /**********************************************************************Searchable Function****************************************************/
  const setSearchableData = (res, isAssignee = false) => {
    const assignToList = [];
    const reportToList = [];
    const assignToObj = {
      id: userData?._id,
      value: isAssignee ? userDefaultDropdownItem.ASSIGN_TO_ME : userData?.name,
      label: isAssignee ? (
        <CustomUserItem
          userName={userData?.name}
          title={userDefaultDropdownItem.ASSIGN_TO_ME}
          profileUrl={userData?.profileUrl}
          customClass={"addtaskUserEllipsisTitle"}
        />
      ) : (
        <CustomUserItem
          userName={userData?.name}
          title={
            userData?.role?.type
              ? `${userData?.name} | ${userData?.role?.type}`
              : userData?.name
          }
          profileUrl={userData?.profileUrl}
          email={userData?.login?.email}
          customClass={"addtaskUserEllipsisTitle"}
        />
      ),
    };

    isAssignee
      ? assignToList.push(assignToObj)
      : reportToList.push(assignToObj);
    res.length > 0 &&
      res.map((assigneeItem) => {
        const { name, _id, profileUrl, email, role } = assigneeItem;

        const obj = {
          id: _id,
          value: name,
          label: (
            <CustomUserItem
              userName={name}
              title={role?.type ? `${name} | ${role?.type}` : name}
              profileUrl={profileUrl}
              email={email}
              customClass={"addtaskUserEllipsisTitle"}
            />
          ),
        };
        isAssignee ? assignToList.push(obj) : reportToList.push(obj);
      });

    isAssignee && setAssignToOptionList([...assignToList]);

    if (isEdit) {
      const { assignee, reporter } = subtaskData?.data;

      const assigneeOption = (isAssignee ? assignToList : reportToList)?.find(
        (item) =>
          isAssignee ? item?.id === assignee?._id : item?.id === reporter?._id
      );

      if (assigneeOption) {
        if (isAssignee) {
          formikRef.current.setFieldValue("assignTo", assignee?._id);
          setAssignTo({ ...assigneeOption });
        } else {
          formikRef.current.setFieldValue("reportTo", reporter?._id);
          setReportTo({ ...assigneeOption });
        }
      }
    }
  };

  /********************************************************************** Add-Image UseEffect ****************************************************/
  const addFiles = (file) => {
    const url =
      file.type === "image/png" ||
      file.type === "image/jpg" ||
      file.type === "image/jpeg" ||
      file.type === "image/gif" ||
      file.type === "application/pdf" ||
      file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      file.type === "application/vnd.ms-excel"
        ? URL.createObjectURL(file)
        : null;

    const checkDuplicates = validateDuplicateFiles(file, filesAdded);

    if (checkDuplicates?.isDuplicate) {
      showToast({
        message: `File ${checkDuplicates?.fileName} ${Translate(
          "common:duplicateFileError"
        )}`,
        type: "error",
      });
      return;
    }

    if (url) {
      file.preview = url;
    }

    if (filesAdded.length <= 10) {
      filesAdded.push(file);
      setFilesAdded([...filesAdded]);
    } else {
      filesAdded.pop(file);
    }
  };

  /********************************************************************** RemoveFile Function ****************************************************/
  const removeFile = (file) => {
    const deletedFiles = filesAdded.findIndex(
      (fileName) => fileName.path === file.path
    );

    if (deletedFiles !== -1) {
      filesAdded.splice(deletedFiles, 1);
      setFilesAdded([...filesAdded]);
    }
  };

  /********************************************************************** UpdateDate Function ****************************************************/
  const updateDate = (dateValue, fromStartDate) => {
    const dueDate = dateValue ? moment(dateValue).add(1, "days").toDate() : "";

    if (fromStartDate) {
      formikRef.current.setFieldValue("startDate", dateValue);
      let tempStartDateTZ = moment(dateValue).format(
        "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
      );

      formikRef.current.setFieldValue("dueDate", dueDate);
      let tempDueDateTZ = moment(dueDate).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");

      // update time
      formikRef.current.setFieldValue("startTime", getDayJs());
      formikRef.current.setFieldValue("dueTime", getDayJs());

      setStartDateTZ(
        convertDueDateUTC(
          tempStartDateTZ,
          getMoment().add(1, "minute").toDate().toISOString()
        )
      );

      setDueDateTZ(
        convertDueDateUTC(
          tempDueDateTZ,
          getMoment().add(1, "minute").toDate().toISOString()
        )
      );
    } else {
      // from dueDate
      if (
        dateValue &&
        moment(dateValue).format("YYYY-MM-DD") ===
          moment(formikRef.current.values.startDate).format("YYYY-MM-DD")
      ) {
        // if both date is same date
        showToast({
          message: Translate("addTask:sameDateError"),
          type: "error",
        });

        formikRef.current.setFieldValue("dueDate", dueDate);
        setDueDateTZ(moment(dueDate).toISOString());
      } else {
        formikRef.current.setFieldValue("dueDate", dateValue);
        const tempDueTime = isEdit
          ? dueDateTZ
          : dayjs(formikRef.current.values.dueTime).toISOString();

        const dueDateWithTz = convertDueDateUTC(
          moment(dateValue).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
          tempDueTime
        );

        setDueDateTZ(dueDateWithTz);
      }
    }
  };

  const removeSubtask = () => {
    setShowDeleteAlert(false);
    deleteSubtaskApi({ taskId: subtaskData?.data?._id });
  };

  const closeSubtaskAlert = () => setShowDeleteAlert(false);

  const navigateTo = () => {
    if (isEdit || (isAdd && subtaskData?.isTaskDetails)) {
      return navigate(-1);
    }

    return navigate("/manageTask");
  };

  return (
    <>
      <ScrollToTop />
      <div className="row container_spacing ">
        <div className="card card_spacing">
          <PageHeader
            title={
              isEdit
                ? Translate("addTask:editSubtaskHeading")
                : Translate("addTask:addSubtaskHeading")
            }
            onClick={navigateTo}
          />
          <div
            className="card-body"
            onKeyDown={(e) => handleEventKeySubmit(e, handleSubmit)}
          >
            <div className="row">
              <div className="form-group col-md-6 col-sm-12">
                <FormikTextField
                  name="projectTitle"
                  label={Translate("addTask:project")}
                  placeholder={Translate("addTask:projectPlaceholder")}
                  disabled={true}
                  required
                />
              </div>
              <div className="form-group col-md-6 col-sm-12">
                <FormikTextField
                  name="parentTitle"
                  label={Translate("addTask:parentTitle")}
                  placeholder={Translate("addTask:parentTitle")}
                  autoComplete={"off"}
                  maxLength={validations?.taskName.MAX}
                  disabled
                  required
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-12 col-sm-12">
                <FormikTextField
                  name="title"
                  label={Translate("addTask:subtaskName")}
                  placeholder={Translate("addTask:subtaskName")}
                  autoComplete={"off"}
                  maxLength={validations?.taskName.MAX}
                  required
                  disabled={
                    subtaskData?.isReAllocate || subtaskData?.isRejected
                  }
                />
              </div>
            </div>

            <div className="form-group">
              <FormikTextField
                name="description"
                label={Translate("addTask:description")}
                placeholder={Translate("addTask:descriptionPlaceholder")}
                autoComplete={"off"}
                multiline
                noOfRows={3}
                maxLength={validations?.taskDescription.MAX}
                required
                disabled={subtaskData?.isReAllocate || subtaskData?.isRejected}
              />
            </div>

            <div className="row">
              <div className="form-group col-md-4 col-sm-12">
                <FormikSearchableDropDown
                  selected={assignTo}
                  onSelect={(value) => {
                    setAssignTo(value);
                    setFieldValue("assignTo", value.id);
                  }}
                  options={assignToOptionList}
                  onChange={(text) => setSearchTextAssign(text)}
                  placeholder={Translate("addTask:assignTo")}
                  label={Translate("addTask:assignTo")}
                  name="assignTo"
                  required
                />
              </div>
              <div className="form-group col-md-4 col-sm-12">
                <FormikSearchableDropDown
                  selected={reportTo}
                  onSelect={(value) => {
                    setReportTo(value);
                    setFieldValue("reportTo", value.id);
                  }}
                  onChange={(text) => setSearchTextReport(text)}
                  options={reportToOptionList}
                  placeholder={Translate("addTask:reportTo")}
                  label={Translate("addTask:reportTo")}
                  name="reportTo"
                  disabled
                  required
                />
              </div>
            </div>

            <div className="row">
              <div className="form-group col-md-3 col-sm-12">
                <FormikDatePicker
                  label={Translate("addTask:startDate")}
                  containerStyle="datePickerContainer"
                  className="form-control bw-0"
                  format="yyyy/MM/dd"
                  name="startDate"
                  value={values.startDate}
                  onChange={(value) => {
                    updateDate(value, true);
                  }}
                  minDate={
                    isEdit
                      ? new Date(subtaskData.data?.startDate)
                      : getMoment().toDate()
                  }
                  disabled={isEdit}
                  isprofile
                  required
                />
              </div>
              <div className="form-group col-md-3 col-sm-12">
                <FormikDatePicker
                  label={Translate("addTask:startTime")}
                  format="HH:mm"
                  name="startTime"
                  value={values.startTime}
                  onChange={(event) => {
                    if (dayjs(event).format() !== "Invalid Date") {
                      setFieldValue("startTime", dayjs(event));
                      setFieldValue("dueTime", dayjs(event));
                      const utcDate = dayjs(event).toISOString();
                      const startDateWithTz = convertDueDateUTC(
                        startDateTZ,
                        utcDate
                      );
                      setStartDateTZ(startDateWithTz);

                      const dueDateWithTz = convertDueDateUTC(
                        dueDateTZ,
                        utcDate
                      );
                      setDueDateTZ(dueDateWithTz);
                    }
                  }}
                  isTimePicker
                  disabled={isEdit}
                  isRowError={
                    !!errors?.dueTime ||
                    !!errors?.startDate ||
                    !!errors?.dueDate
                  }
                  required
                />
              </div>

              <div className="form-group col-md-3 col-sm-12">
                <FormikDatePicker
                  label={Translate("addTask:dueDate")}
                  containerStyle="datePickerContainer"
                  className="form-control bw-0"
                  format="yyyy/MM/dd"
                  name="dueDate"
                  required
                  value={values?.dueDate}
                  onChange={(value) => {
                    updateDate(value, false);
                  }}
                  minDate={
                    startDateForDue ? getMoment().toDate() : values.startDate
                  }
                  disabled={
                    subtaskData?.isReAllocate || subtaskData?.isRejected
                  }
                />
              </div>

              <div className="form-group col-md-3 col-sm-12">
                <FormikDatePicker
                  label={Translate("addTask:dueTime")}
                  format="HH:mm"
                  name="dueTime"
                  value={values?.dueTime}
                  onChange={(event) => setFieldValue("dueTime", dayjs(event))}
                  isTimePicker
                  disabled
                  isRowError={
                    !!errors.startTime || !!errors.startDate || !!errors.dueDate
                  }
                  required
                />
              </div>
            </div>

            <div className="form-group">
              <Priority
                label={Translate("addTask:priority")}
                value={values.priority}
                onPress={(value) => setFieldValue("priority", value)}
                defaultCheck={values.markAsCritical}
                markAsCriticalState={isEdit}
                disabled={subtaskData?.isReAllocate || subtaskData?.isRejected}
              />
            </div>

            {!subtaskData?.isReAllocate && !subtaskData?.isRejected && (
              <div className="display" onKeyDown={stopEventPropogation}>
                <Dropzone
                  translate={Translate("addTask:attach")}
                  icon={"icon-paper-clip"}
                  onfileSelect={(filesArray) => addFiles(filesArray)}
                  acceptedImages={acceptedFileType}
                  maximumFiles={10}
                  maxFileSize={52428800}
                  fileLength={filesAdded.length}
                />
              </div>
            )}
            <div className="AttachmentBoxStyling">
              <CommonImage
                images={images}
                files={filesAdded}
                removeFile={removeFile}
                icon={subtaskData?.isReAllocate || subtaskData?.isRejected}
              />
            </div>
          </div>

          <div className="card-footer text-right line_spacing">
            <button
              type="submit"
              className="btn btn-primary saveButton"
              onClick={handleSubmit}
            >
              {isEdit ? Translate("addTask:save") : Translate("addTask:add")}
            </button>
          </div>
        </div>
      </div>
      <AlertPermission
        show={showDeleteAlert}
        subTitle={Translate("taskDetails:deleteSubtask")}
        onOk={removeSubtask}
        onCanel={closeSubtaskAlert}
      />
      <Loader
        loading={
          isLoading ||
          /* isTaskDataLoading || */
          loadingAssigneToData ||
          loadingReportToData ||
          isDeleteSubtaskLoading
        }
      />
    </>
  );
};

export default memo(SubtaskForm);

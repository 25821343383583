import * as yup from "yup";
import Translate from "../../translation/utils/translateFunction";

export const ChangeTimezoneSchema = () => {
  const schema = yup.object().shape({
    selectTimeZone: yup.string().required(Translate("timeZone:timeZoneError")),
  });

  return schema;
};

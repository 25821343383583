export const suggestions = [
  { id: "1", display: "All" },
  { id: "2", display: "Esther Howard" },
  { id: "3", display: "Kristin Watson" },
  { id: "4", display: "Jenny Wilson" },
  { id: "5", display: "Leslie Alexander" },
  { id: "6", display: "Marvin McKinney" },
  { id: "7", display: "John Doe" },
  { id: "8", display: "Robert Fox" },
  { id: "9", display: "Kristin Watson" },
  { id: "10", display: "Jenny Wilson" },
  { id: "11", display: "Leslie Alexander" },
  { id: "12", display: "Kristin Watson" },
  { id: "13", display: "Jenny Wilson" },
  { id: "14", display: "Leslie Alexander" },
];

const RELATED_TASK = "relatedTask",
  EDIT_GROUP_NAME = "editGroupName";

export const chatFilterOptions = [RELATED_TASK];

export const groupFilterOptions = [RELATED_TASK, EDIT_GROUP_NAME];

export const chatOptionType = {
  RELATED_TASK,
  EDIT_GROUP_NAME,
};

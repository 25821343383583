import { Stack } from "@mui/material";
import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StyledTypography } from "../../common/Utils/StyledComponents";
import { showToast } from "../../common/Utils/ToastMessage";
import {
  useChangeTimeZoneMutation,
  useGetTimezoneListQuery,
} from "../../request/Timezone";
import { setSelectTimeZoneAction, userDataAction } from "../../store/Reducer";
import Translate from "../../translation/utils/translateFunction";
import FormikSearchableDropDown from "../FormikForm/component/FormikSearchableDropDown";
import { TimezoneInitialValues } from "./constants";
import { ChangeTimezoneSchema } from "./utils";
import Loader from "../staff/Owner/LoadingComp";
import { handleEventKeySubmit } from "../../common/Utils/commonFunction";

export const Timezone = () => {
  const { userData, selecteTimeZone } = useSelector(
    (state) => state.formanagement
  );
  const [timeZone, setTimeZone] = useState([]);

  const [selectedTimeZone, setSelectedTimeZone] = useState();
  const dispatch = useDispatch();
  // Currently setting the india as default country ISO
  const { data: serverResponse, isSuccess } = useGetTimezoneListQuery();
  // userData?.login?.mobileShortCode
  const [
    changeTimeZone,
    { data: changeTimeZoneData, isSuccess: successChangeTimezone, isLoading },
  ] = useChangeTimeZoneMutation();

  const onSubmit = async () => {
    let requestObject = {
      timezone: selectedTimeZone?.value?.zoneName,
      utcOffset: selectedTimeZone?.value?.gmtOffsetName,
      userId: userData._id,
    };
    changeTimeZone(requestObject);
  };

  useEffect(() => {
    if (serverResponse && isSuccess) {
      const timeZoneArray = [];
      serverResponse.map((item) => {
        !!item?.timezones?.length &&
          item.timezones.map((time) => {
            const obj = {
              id: time?.zoneName,
              value: time,
              label: (
                <Stack flexDirection={"column"}>
                  <StyledTypography variant="subtitle2">
                    {time?.zoneName}
                    {time?.gmtOffsetName}
                  </StyledTypography>
                </Stack>
              ),
            };
            timeZoneArray.push(obj);
          });
      });
      setTimeZone([...timeZoneArray]);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (successChangeTimezone) {
      if (changeTimeZoneData?.success) {
        dispatch(setSelectTimeZoneAction(selectedTimeZone?.value));
        const tempUserData = {
          ...userData,
          login: {
            ...userData?.login,
            timezone: changeTimeZoneData?.data?.timezone,
            utcOffset: changeTimeZoneData?.data?.utcOffset,
          },
        };
        localStorage.setItem("userData", JSON.stringify(tempUserData));
        dispatch(userDataAction(tempUserData));
        showToast({ message: changeTimeZoneData.message, type: "success" });
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        showToast({ message: changeTimeZoneData.message, type: "error" });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successChangeTimezone, changeTimeZoneData]);
  const formikRef = useRef(null);

  useEffect(() => {
    if (selecteTimeZone && isSuccess) {
      setSelectedTimeZone({
        id: selecteTimeZone?.zoneName,
        value: selecteTimeZone,
        label: (
          <Stack flexDirection={"column"}>
            <StyledTypography variant="subtitle2">
              {selecteTimeZone?.zoneName}
              {selecteTimeZone?.gmtOffsetName}
            </StyledTypography>
          </Stack>
        ),
      });
      formikRef.current.setFieldValue(
        "selectTimeZone",
        selecteTimeZone?.zoneName
      );
    }
  }, [selecteTimeZone, isSuccess]);
  const setOptionList = (text = "") => {
    return (
      timeZone.length &&
      timeZone?.filter(
        (user) =>
          user?.value?.zoneName?.toLowerCase().includes(text?.toLowerCase()) ||
          user?.value?.gmtOffsetName
            ?.toLowerCase()
            .includes(text?.toLowerCase())
      )
    );
  };
  const promiseOptions = (text) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(setOptionList(text.trim()));
      }, 500);
    });
  return (
    <>
      <Formik
        initialValues={TimezoneInitialValues}
        innerRef={formikRef}
        validateOnMount
        validationSchema={ChangeTimezoneSchema}
        onSubmit={onSubmit}>
        {({ handleSubmit, setFieldValue }) => {
          return (
            <div className="row container_spacing">
              <div className="card card_spacing">
                <div className="page-title formTitle p-3 row align-items-center">
                  {Translate("timeZone:changeTimezone")}
                </div>
                <div className="card-body">
                  <div className="row mt-3">
                    <div
                      className="form-group col-md-5 col-sm 12"
                      onKeyDown={(e) => handleEventKeySubmit(e, handleSubmit)}>
                      <FormikSearchableDropDown
                        selected={selectedTimeZone}
                        name="selectTimeZone"
                        label={Translate("timeZone:timeZoneLabel")}
                        placeholder={Translate("timeZone:timeZoneLabel")}
                        onSelect={(value) => {
                          setFieldValue("selectTimeZone", value?.id);
                          setSelectedTimeZone(value);
                        }}
                        onChange={promiseOptions}
                        options={timeZone}
                        isTimeZone={true}
                        isTimeZoneScreen={true}
                      />
                    </div>
                  </div>

                  <div className="card-footer text-right line_spacing">
                    <button
                      type="submit"
                      className="btn btn-primary saveButton"
                      onClick={() => {
                        handleSubmit();
                      }}>
                      {Translate("timeZone:save")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          );
        }}
      </Formik>
      <Loader loading={isLoading} />
    </>
  );
};

export default Timezone;

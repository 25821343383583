import { isRtl as validateRtl } from "../../../common/Utils/commonFunction";
import Translate from "../../../translation/utils/translateFunction";
import {
  CustomDueDateCellRender,
  CustomPriorityCellRender,
  CustomProgressBarCellRender,
  CustomStatusCellRender,
  CustomTeamCellRender,
} from "../../ManageTask/Components/utils";
import { CustomTitleCellRender } from "../../common/CustomCellRender";

const isRtl = validateRtl();

export const getTaskReportColumns = (
  customSrCellRender,
  customActionCellRender,
  isSort = true
) => {
  const columns = [
    {
      field: "lineNo",
      headerName: Translate("common:srNoTitle"),
      align: "center",
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      renderCell: customSrCellRender,
      flex: 0.15,
    },
    {
      field: "taskNumber",
      headerName: Translate("manageTask:taskIdTitle"),
      flex: 0.3,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => CustomTitleCellRender(params?.row?.taskNumber),
    },

    /*   {
      field: "type",
      headerName: Translate("manageTask:taskTypeTitle"),
      flex: 0.26,
      sortable: false,
      disableColumnMenu: true,
      renderCell: CustomTaskTypeCellRender,
    }, */
    {
      field: "title",
      headerName: Translate("manageTask:taskNameTitle"),
      flex: 0.4,
      sortable: isSort,
      disableColumnMenu: true,
      renderCell: (params) => CustomTitleCellRender(params?.row?.title),
    },

    {
      field: "company.name",
      headerName: Translate("manageTask:companyNameTitle"),
      flex: isRtl ? 0.47 : 0.5,
      sortable: isSort,
      disableColumnMenu: true,
      renderCell: (params) => CustomTitleCellRender(params?.row?.company?.name),
    },
    {
      field: "dueDate",
      headerName: Translate("manageTask:dueDateAndTimeTitle"),
      flex: 0.3,
      sortable: isSort,
      disableColumnMenu: true,
      renderCell: CustomDueDateCellRender,
    },
    {
      field: "teamTitle",
      headerName: Translate("manageTask:teamTitle"),
      flex: 0.33,
      sortable: false,
      disableColumnMenu: true,
      renderCell: CustomTeamCellRender,
    },
    {
      field: "priority",
      headerName: Translate("manageTask:priorityTitle"),
      flex: 0.25,
      sortable: false,
      disableColumnMenu: true,
      renderCell: CustomPriorityCellRender,
    },
    {
      field: "taskStatus",
      headerName: Translate("manageTask:statusTitle"),
      flex: isRtl ? 0.3 : 0.32,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) =>
        CustomTitleCellRender(CustomStatusCellRender(params)),
    },
    {
      field: "progressBar",
      headerName: Translate("manageTask:progressBar"),
      flex: isRtl ? 0.26 : 0.3,
      sortable: false,
      disableColumnMenu: true,
      renderCell: CustomProgressBarCellRender,
    },
    {
      field: "Action",
      headerName: Translate("common:actionTitle"),
      flex: isRtl ? 0.1 : 0.2,
      align: "center",
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      hide: true,
      renderCell: customActionCellRender,
    },
  ];
  return columns;
};

export default {
  totalAssigned: "مجموع المهمات المكلّفة",
  inProgress: "قيد التنفيذ",
  overdue: "مُتأخر",
  staffReport: "تقرير الموظفين",
  completed: "مكتمل",
  configure: "تهيئة",
  holidayTitle: "تعيين العطل",
  weekendTitle: "تعيين عطلات نهاية الأسبوع",
  occasionTitle: "أضف مناسبة",
  attendanceViewTitle: "تفاصيل تقرير الحضور",
  changeStatus: "في مغادرة",
  holiday: "العُطَل",
  absentTitle: "غائب",
  ConfigureTitle: "الاعدادات",
  leaveRequest: "طلب مغادرة",
  appliedFor: "قدّم لِ",
  leaveDisApproveAlert: "هل أنت متأكد من رفض هذا الطلب؟"
};

import * as yup from "yup";
import Translate from "../../../translation/utils/translateFunction";

export const addDocumentSchema = () => {
  const schema = yup.object().shape({
    title: yup
      .string()
      .trim()
      .required(Translate("document:titleError")),
    description: yup
      .string()
      .trim()
      .required(Translate("document:descriptionError")),
  });
  return schema;
};

import { Stack } from "@mui/material";
import React from "react";
import Modal from "react-bootstrap/Modal";
import { Persona } from "../../Persona";
import { StyledTypography } from "../../../common/Utils/StyledComponents";
import Translate from "../../../translation/utils/translateFunction";
import { colors } from "../../../common/Theme/Colors";
import MessageItem from "../Components/MessageItem";
import { useSelector } from "react-redux";

const ChatInfo = ({
  showModal,
  handleClose,
  selectedUser,
  commonGroups,
  onClickForSelectGroup,
}) => {
  const { isRtl } = useSelector((state) => state.formanagement);

  const getCountryState = () => {
    const { officeAddress } = selectedUser?.companyId[0];
    return (
      <StyledTypography variant="subtitle2" sx={{ fontWeight: 300 }}>
        {officeAddress?.city}
        {officeAddress?.city && officeAddress?.country && ", "}
        {officeAddress?.country}
      </StyledTypography>
    );
  };

  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onBackdropClick={handleClose}
      scrollable={true}
      className="col-span-3 col-md-12"
    >
      <Modal.Body style={{ padding: "0px" }}>
        <Stack>
          <Stack px={"1rem"} pt={"1rem"}>
            <button
              type="button"
              style={{ opacity: 1, color: colors.white }}
              className="close cursor-pointer align-self-end"
              data-dismiss="alert"
              aria-label="Close"
              onClick={handleClose}
            />
            <Stack alignItems={"center"} mb={2}>
              <Persona
                name={selectedUser?.name}
                image={selectedUser?.profileUrl}
                size={100}
              />
              <StyledTypography
                variant="h6"
                sx={{ fontSize: "1.4rem", textAlign: "center" }}
                mt={1}
                /*  className="ellipsisStyle maxwidth-350" */
              >
                {selectedUser?.username || selectedUser?.name}
              </StyledTypography>
              <StyledTypography
                variant="subtitle2"
                sx={{ textAlign: "center" }}
                /*  className="ellipsisStyle maxwidth-350" */
              >
                {selectedUser?.designation} {Translate("messagePage:at")}
                {selectedUser?.companyId[0]?.name}
              </StyledTypography>
              {selectedUser && getCountryState()}
            </Stack>
          </Stack>
          <Stack
            py={2}
            px={3}
            sx={{ background: colors.grey_016, direction: isRtl && "rtl" }}
            className={isRtl && "rtl"}
          >
            <Stack my={2}>
              <StyledTypography variant="h6" sx={{ fontSize: "1.1rem" }}>
                {Translate("messagePage:contactInfo")}
              </StyledTypography>
              <Stack my={2}>
                <Stack flexDirection={"row"} alignItems={"baseline"}>
                  <i
                    className="fa fa-envelope-o"
                    title={Translate("addManager:emailTableHeading")}
                  />
                  <StyledTypography
                    variant="subtitle1"
                    mx={1}
                    sx={{ fontWeight: 500 }}
                  >
                    {Translate("addManager:emailTableHeading")}
                  </StyledTypography>
                </Stack>
                <StyledTypography
                  variant="subtitle2"
                  sx={{ color: colors.primary_010 }}
                >
                  {selectedUser?.email}
                </StyledTypography>
              </Stack>
              <Stack>
                <Stack flexDirection={"row"} alignItems={"baseline"}>
                  <i
                    className="fe fe-phone"
                    title={Translate("messagePage:phone")}
                  />
                  <StyledTypography
                    variant="subtitle1"
                    mx={1}
                    sx={{ fontWeight: 500 }}
                  >
                    {Translate("messagePage:phone")}
                  </StyledTypography>
                </Stack>
                <StyledTypography
                  variant="subtitle2"
                  className="leftDirection"
                  sx={{ color: colors.primary_010 }}
                >
                  +{selectedUser?.countryCode + selectedUser?.mobile}
                </StyledTypography>
              </Stack>
            </Stack>
            {!!commonGroups?.length && (
              <Stack>
                <Stack direction="row" justifyContent="space-between">
                  <StyledTypography
                    variant="subtitle2"
                    my={2}
                    sx={{ color: colors.primary_010 }}
                  >
                    {Translate("messagePage:groupsCommon")}
                  </StyledTypography>
                  <StyledTypography
                    variant="subtitle2"
                    my={2}
                    sx={{ color: colors.primary_010 }}
                  >
                    {commonGroups?.length}
                  </StyledTypography>
                </Stack>
                <ul
                  className="commentContainer m-0 p-0"
                  style={{ maxHeight: "200px" }}
                >
                  {commonGroups?.map((item, idx) => (
                    <MessageItem
                      key={idx}
                      item={item}
                      isNewChat={true}
                      selectedUser={selectedUser}
                      className={"p-2 mb-1 d-flex align-items-center"}
                      isGroup={true}
                      onClick={() => {
                        handleClose();
                        onClickForSelectGroup(item);
                      }}
                      isTooltip
                    />
                  ))}
                </ul>
              </Stack>
            )}
          </Stack>
        </Stack>
      </Modal.Body>
    </Modal>
  );
};
export default ChatInfo;

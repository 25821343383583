import { Stack } from "@mui/material";
import moment from "moment";
import React from "react";
import Modal from "react-bootstrap/Modal";
import { colors } from "../../../common/Theme/Colors";
import { FontSizes } from "../../../common/Theme/Fonts";
import { StyledTypography } from "../../../common/Utils/StyledComponents";
import Translate from "../../../translation/utils/translateFunction";
import { calendarEnums } from "../utils";

const ShowMorePopup = ({
  eventData,
  isVisible,
  setEventsPopup,
  onCancel,
  navigate,
}) => {
  return (
    <Modal
      show={isVisible}
      onHide={onCancel}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      scrollable={true}
      className="col-span-3 col-md-12">
      <Modal.Header closeButton>
        <Modal.Title>{Translate("createEvent:eventList")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Stack style={styles.outerMainView}>
          {eventData?.map((item) => {
            return (
              <div
                onClick={() => {
                  setEventsPopup(false);
                  item?.type === calendarEnums.event
                    ? navigate("/calendar/viewEvent", {
                        state: { eventId: item?._id },
                      })
                    : item?.type === calendarEnums.subTask
                    ? navigate("/subtask/view", {
                        state: {
                          taskId: item?._id,
                          module: calendarEnums.event,
                        },
                      })
                    : navigate("/taskDetails", {
                        state: {
                          taskId: item?._id,
                        },
                      });
                }}
                className="cursor-pointer">
                <Stack
                  flexDirection={"row"}
                  alignItems={"center"}
                  style={styles.card}>
                  <Stack
                    style={{
                      width: 5,
                      height: "100%",
                      borderTopLeftRadius: 3,
                      borderBottomLeftRadius: 3,
                      marginRight: 5,
                      backgroundColor: item?.color,
                    }}
                  />
                  <Stack style={styles.displayFrame}>
                    <StyledTypography style={styles.titleText}>
                      {item?.subject}
                    </StyledTypography>
                    <StyledTypography style={styles.description}>
                      {item?.description}
                    </StyledTypography>
                    <StyledTypography
                      style={{
                        color: item?.color,
                        fontSize: FontSizes.small,
                        paddingBottom: 5,
                      }}>{`${moment(item?.start).format("hh:mm A")} - ${moment(
                      item?.end
                    ).format("hh:mm A")}`}</StyledTypography>
                  </Stack>
                </Stack>
              </div>
            );
          })}
        </Stack>
      </Modal.Body>
    </Modal>
  );
};

export default ShowMorePopup;

const styles = {
  outerMainView: {
    backgroundColor: colors.grey_001,
    height: "85%",
    borderRadius: 5,
    padding: 20,
    marginBottom: 15,
  },
  closeIcon: {
    alignSelf: "flex-end",
  },
  card: {
    backgroundColor: colors.white,
    borderRadius: 3,
    marginVertical: 10,
  },
  displayFrame: {
    paddingBottom: 0,
    paddingTop: 10,
    flex: 1,
  },
  titleText: {
    color: colors.primary,
    fontSize: FontSizes.regular,
  },
  description: {
    fontSize: FontSizes.xSmall,
  },
};

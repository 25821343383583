import Translate from "../../translation/utils/translateFunction";

export const HomeScreenStrings = {
  DAY: "DAY",
  WEEK: "WEEK",
  MONTH: "MONTH",
  QUARTER: "QUARTER",
  YEAR: "YEAR",
  CUSTOM: "CUSTOM",
  Daily: Translate("dashboard:Daily"),
  lastWeek: Translate("dashboard:lastWeek"),
  lastMonth: Translate("dashboard:lastMonth"),
  lastQuarter: Translate("dashboard:lastQuarter"),
  lastYear: Translate("dashboard:lastYear"),
  customDate: Translate("dashboard:customDate"),
};
export const allFilterData = [
  { label: HomeScreenStrings.Daily, value: HomeScreenStrings.DAY },
  { label: HomeScreenStrings.lastWeek, value: HomeScreenStrings.WEEK },
  { label: HomeScreenStrings.lastMonth, value: HomeScreenStrings.MONTH },
  { label: HomeScreenStrings.lastQuarter, value: HomeScreenStrings.QUARTER },
  { label: HomeScreenStrings.lastYear, value: HomeScreenStrings.YEAR },
  { label: HomeScreenStrings.customDate, value: HomeScreenStrings.CUSTOM },
];

export const vendorUser = "VENDOR";

import * as yup from "yup";
import Translate from "../../../translation/utils/translateFunction";

export const addProjectSchema = () => {
  const schema = yup.object().shape({
    companyId: yup.string().trim().required(Translate("addOwner:companyError")),
    title: yup.string().trim().required(Translate("document:titleError")),
    description: yup
      .string()
      .trim()
      .required(Translate("document:descriptionError")),
  });
  return schema;
};

export default {
  addManagerHeading: "Add manager",
  gender: "Gender",
  addMore: "Add more",
  uploadPhoto: "Upload Photo",
  save: "Save",
  name: "Manager name",
  designation: "Designation",
  department: "Department",
  contactNumber: "Contact number",
  contactNumberPlaceHolder: "0000 0000 00",
  alternate_contactNumber: "Alternate contact number",
  email: "Work email",
  emailPlaceholder: "Work email address",
  address: "Residence address",
  selectCompany: "Select company",
  dropdownPlaceholder: "Select company",
  dob: "Date of birth",
  companyError: "Company is required",
  nameError: "Manager name is required",
  nameError_2: "Manager name is not valid",
  nameError_3: "Manager name must be at least 3 characters",
  nameError_4: "Manager name must be at most 100 characters",
  designationError: "Designation is required",
  designationError_2: "Designation is not valid",
  genderError: "Gender is required",
  departmentError: "Department is required",
  departmentError_2: "Department is not valid",
  numberError_1: "Contact number is not valid",
  numberError_2: "Contact number is required",
  numberError_3: "Contact number must be at least 5 characters",
  numberError_4: "Contact number must be at most 12 characters",
  alternate_numberError_1: "Alternate Contact number is not valid",
  alternate_numberError_2: "Alternate Contact number is required",
  emailError: "Work email is required",
  addressError: "Residence address is required",
  workAddressError: "Work address is required",
  company: "Company",
  alert: "Are you sure want to delete this manager?",
  ageAlert: "Age should be greater than 18",
  extensionNumber: "Company phone extension number",
  companyExtensionNumber: "Company phone extension number",
  extensionNumberPlaceholder: "00 0000 0000",
  companyExtensionPlaceholder: "1",
  companyPhoneExtensionPlaceholder: "000",
  extensionNumberError_1: "Extension number is not valid",
  extensionNumberError_2: "Extension number is required",
  hrContactNumber: "HR contact number",
  hrContactNumberPlaceHolder: "00 0000 0000",
  hrContactNumberError_1: "HR contact number is not valid",
  hrContactNumberError_2: "HR contact number is required",
  hrContactNumberError_3: "HR contact number must be at least 5 characters",
  hrContactNumberError_4: "HR contact number must be at most 12 characters",
  addressPlaceholder_1: "House/flat/block no.",
  addressPlaceholder_2: "Apartment/road/area",
  addressPlaceholder_3: "Flat/block no.",
  addressPlaceholder_4: "Building/road/area",
  countryPlaceholder: "Country",
  statePlaceholder: "State/region",
  cityPlaceholder: "City",
  zipcodePlaceholder: "Zip code ",
  workAddress: "Work address",
  addPhoto: "Add photo",
  companyExtensionNumberError_1: "Company phone extension number is not valid",
  companyExtensionNumberError_2: "Company phone extension number is required",
  companyExtensionNumberError_3:
    "Company phone extension number must be at least 5 characters",
  companyExtensionNumberError_4:
    "Company phone extension number must be at most 15 characters",
  extensionError_1: "Extension required",
  extensionError_2: "Extension must be at least 1 characters",
  extensionError_3: "Extension must be at most 3 characters",
  countryError: "Country is required",
  stateError: "State/Region is required",
  cityError: "City is required",
  cityError_2: "City is not valid",
  zipcodeError: "Zip code is required",
  zipcodeError_2: "Zipcode is invalid!",
  zipcodeError_3: "Zip code must be at least 5 characters",
  zipcodeError_4: "Zip code must be at most 6 characters",
  imageError: "Please upload valid image",
  allSelectedCompany: "All Companies",
  selectedCompanyMessage: "All company selected",
  dobError: "Date of birth is required",
  workEmailError: "Work email is not valid",
  workEmailError_1: "Work email is required",
  addGeneralManagerHeading: "Add general manager",
  gmName: "General manager name",
  gmNameError: "General manager name is required",
  viewGeneralManagerHeading: "View general manager",
  alertGM: "Are you sure want to delete this general manager?",
  editGeneralManagerHeading: "Edit general manager",
  viewManagerHeading: "View manager",
  editManagerHeading: "Edit manager",
  alertManager: "Are you sure want to delete this manager?",
  selectCompanyMessage: "Please select at least one company",
  srNoTableHeading: "Sr.no",
  actionTableHeading: "Action",
  nameTableHeading: "Name",
  mobileTableHeading: "Mobile no",
  companyNameTableHeading: "Company name",
  emailTableHeading: "Email",
  searchPlaceholder: "Search something...",
  addButtonLabel: "Add",
  reportToLabel: "Report to",
  marskAsHrManager: "Mark as Hr Manager",
  reportTo: "Report to",
  alertText1: "Are you really want to assign ",
  alertText2: " to ",
  alertText3: ". Once you added , you will not able to revert it.",
  confirmation: "Confirmation",
};

export default {
  addManagerHeading: "إضافة مدير",
  gender: "الجنس",
  addMore: "أضف المزيد",
  uploadPhoto: "حمل الصورة",
  save: "حفظ",
  name: "اسم المدير",
  designation: "الوظيفة",
  department: "القسم",
  contactNumber: "رقم جهة الإتصال",
  contactNumberPlaceHolder: "0000 0000 00",
  alternate_contactNumber: "رقم جهة اتصال بديل",
  email: "ايميل العمل",
  emailPlaceholder: "ايميل العمل",
  address: "عنوان السكن",
  selectCompany: "اختر الشركة",
  dropdownPlaceholder: "اختر الشركة",
  dob: "تاريخ الميلاد",
  companyError: "الشركة حقل مطلوب",
  nameError: "اسم المدير حقل مطلوب",
  nameError_2: "اسم المدير غير فعّال",
  nameError_3: "اسم المدير يتكون من 3 حروف على الأقل",
  nameError_4: "يجب أن لا يزيد اسم المدير عن 100 حرف",
  designationError: "الوظيفة حقل مطلوب",
  designationError_2: "اسم الوظيفة غير فعّال",
  genderError: "الجنس حقل مطلوب",
  departmentError: "القسم حقل مطلوب",
  departmentError_2: "اسم القسم غير فعّال",
  numberError_1: "رقم جهة الإتصال غير فعّال",
  numberError_2: "رقم جهة الإتصال حقل مطلوب",
  numberError_3: "يجب أن يتكون رقم جهة الإتصال من 5 حروف على الأقل",
  numberError_4: "يجب أن يتكون رقم جهة الإتصال من 12 حرف على الأكثر",
  alternate_numberError_1: "الرقم البديل لجهة الإتصال غير فعّال",
  alternate_numberError_2: "الرقم البديل لجهة الإتصال حقل مطلوب",
  emailError: "ايميل العمل حقل مطلوب",
  addressError: "عنوان السكن حقل مطلوب",
  workAddressError: "عنوان العمل حقل مطلوب",
  company: "الشركة",
  alert: "هل أنت متأكد من حذف المدير؟",
  ageAlert: "يجب أن يكون العمر أكبر من 18 سنة",
  extensionNumber: "الرقم الفرعي للشركة",
  companyExtensionNumber: "الرقم الفرعي للشركة",
  extensionNumberPlaceholder: "00 0000 0000",
  companyExtensionPlaceholder: "1",
  companyPhoneExtensionPlaceholder: "000",
  extensionNumberError_1: "الرقم الفرعي غير فعّال",
  extensionNumberError_2: "الرقم الفرعي حقل مطلوب",
  hrContactNumber: "جهة اتصال الموارد البشرية",
  hrContactNumberPlaceHolder: "00 0000 0000",
  hrContactNumberError_1: "جهة اتصال الموارد البشرية غير فعّال",
  hrContactNumberError_2: "جهة اتصال الموارد البشرية حقل مطلوب",
  hrContactNumberError_3:
    "رقم جهة اتصال الموارد البشرية يتكون من 3 أرقام على الأقل",
  hrContactNumberError_4:
    "رقم جهة اتصال الموارد البشرية يتكون من 12 رقم على الأكثر",
  addressPlaceholder_1: "رقم المنزل/الشقة/البلوك",
  addressPlaceholder_2: "الشقة/الطريق/المنطقة",
  addressPlaceholder_3: "رقم الشقة/البلوك",
  addressPlaceholder_4: "المبنى/الطريق/المنطقة",
  countryPlaceholder: "الدولة",
  statePlaceholder: "الولاية/المنطقة",
  cityPlaceholder: "المدينة",
  zipcodePlaceholder: "الرمز البريدي ",
  workAddress: "عنوان العمل",
  addPhoto: "أضف صورة",
  companyExtensionNumberError_1: "الرقم الفرعي للشركة غير فعّال",
  companyExtensionNumberError_2: "الرقم الفرعي للشركة حقل مطلوب",
  companyExtensionNumberError_3:
    "الرقم الفرعي للشركة يجب أن يتكون من 5 أرقام على الأقل",
  companyExtensionNumberError_4:
    "الرقم الفرعي للشركة يجب أن يتكون من 15 رقم على الأكثر",
  extensionError_1: "الرقم الفرعي حقل مطلوب",
  extensionError_2: "الرقم الفرعي حقل مطلوب",
  extensionError_3: "يجب أن يتكون الرقم الفرعي من 3 ارقام على الأكثر",
  countryError: "الدولة حقل مطلوب",
  stateError: "الولاية/المنطقة مطلوبة",
  cityError: "المدينة حقل مطلوب",
  cityError_2: "اسم المدينة غير فعّال",
  zipcodeError: "الرمز البريدي حقل مطلوب",
  zipcodeError_2: "الرمز البريدي غير فعّال",
  zipcodeError_3: "يجب أن يتكون الرمز البريدي من 5 أرقام على الأقل",
  zipcodeError_4: "يجب أن يتكون الرمز البريدي من 6 أرقام على الأكثر",
  imageError: "يرجى تحميل صورة صالحة للإستخدام",
  allSelectedCompany: "جميع الشركات",
  selectedCompanyMessage: "تم اختيار جميع الشركات",
  dobError: "تاريخ الميلاد حقل مطلوب",
  workEmailError: "البريد الإلكتروني للعمل غير صالح",
  workEmailError_1: "ايميل العمل حقل مطلوب",
  addGeneralManagerHeading: "إضافة مدير عام",
  gmName: "اسم المدير العام",
  gmNameError: "اسم المدير العام حقل مطلوب",
  viewGeneralManagerHeading: "عرض المدير العام",
  alertGM: "هل أنت متأكد أنك تريد حذف هذا المدير العام؟",
  editGeneralManagerHeading: "تحرير المدير العام",
  viewManagerHeading: "عرض المدير",
  editManagerHeading: "مدير التحرير",
  alertManager: "هل أنت متأكد من حذف المدير؟",
  selectCompanyMessage: "الرجاء اختيار شركة واحدة على الأقل",
  srNoTableHeading: "الأب رقم",
  actionTableHeading: "فعل",
  nameTableHeading: "اسم",
  mobileTableHeading: "رقم المحمول",
  companyNameTableHeading: "اسم الشركة",
  emailTableHeading: "بريد إلكتروني",
  searchPlaceholder: "ابحث عن شيء...",
  addButtonLabel: "يضيف",
  reportToLabel: "المدير المباشر",
  marskAsHrManager: "وضع علامة كمدير للموارد البشرية",
  reportTo: "تقرير الى",
};

import { Methods, Urls } from "../../common/Utils/ApiConstants";

import { CreateApi } from "../CreateApi";
const myVoiceNotesListing = CreateApi.injectEndpoints({
  endpoints: (builder) => ({
    getMyVoiceNotesListing: builder.query({
      query: ({ currentPage, debouncedSearch, type, isSharedByMe }) => {
        return {
          url: Urls.getMyVoiceNotes,
          method: Methods.get,
          params: {
            pageNo: currentPage,
            type: type,
            isSharedByMe: isSharedByMe,
            ...(debouncedSearch ? { searchValue: debouncedSearch } : null),
          },
        };
      },
    }),
    deleteVoiceNote: builder.mutation({
      query: (body) => {
        return {
          url: Urls.deleteVoiceNote,
          body: body,
          method: Methods.delete,
        };
      },
    }),
    renameVoiceNote: builder.mutation({
      query: (body) => {
        return {
          url: `${Urls.renameVoiceNote}${body.id}`,
          method: Methods.put,
          body: body.body,
        };
      },
    }),
    getSharedWithMeListing: builder.mutation({
      query: (requestBody) => {
        return {
          url: Urls.getSharedWithMeVoiceNotes,
          method: Methods.post,
          body: requestBody,
        };
      },
    }),
    getVoiceNoteReminder: builder.mutation({
      query: (requestBody) => {
        return {
          url: Urls.getVoiceNoteReminder,
          method: Methods.post,
          body: requestBody,
        };
      },
    }),
    getAccessLogsListing: builder.mutation({
      query: (requestBody) => {
        return {
          url: Urls.accessLogsListingVoiceNote,
          method: Methods.post,
          body: requestBody,
        };
      },
    }),
    getShareVoiceNotes: builder.mutation({
      query: (requestBody) => {
        return {
          url: Urls.shareVoiceNotes,
          method: Methods.post,
          body: requestBody,
        };
      },
    }),
    getShareVoiceNotesInviteeList: builder.mutation({
      query: (requestBody) => {
        return {
          url: Urls.shareVoiceNotesInviteeList,
          method: Methods.post,
          body: requestBody,
        };
      },
    }),
    getAddAccessLogs: builder.mutation({
      query: (requestBody) => {
        return {
          url: Urls.voiceNotesAddAccessLogs,
          method: Methods.post,
          body: requestBody,
        };
      },
    }),
  }),
});
export const {
  useGetMyVoiceNotesListingQuery,
  useDeleteVoiceNoteMutation,
  useRenameVoiceNoteMutation,
  useGetSharedWithMeListingMutation,
  useGetVoiceNoteReminderMutation,
  useGetAccessLogsListingMutation,
  useGetShareVoiceNotesMutation,
  useGetShareVoiceNotesInviteeListMutation,
  useGetAddAccessLogsMutation,
} = myVoiceNotesListing;

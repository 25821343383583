export default {
  head: "تقرير طاقم العمل",
  owner: "المالك",
  manager: "المدير",
  employees: "الموظفين",
  vendor: "البائع",
  viewDetailsTitle: "تفاصيل تقرير الحضور",
  nameHeading: "اسم",
  companyNameHeading: "اسم الشركة",
  totalAssignedHeading: "جميع المهام المكلّفة",
  inProgressHeading: "في تَقَدم",
  completedHeading: "مكتمل",
  exportStaffFileName: "FOM_STAFF_REPORT",
};

import { toConvertKB } from "../../../../common/Utils/commonFunction";
import Translate from "../../../../translation/utils/translateFunction";
import { CustomTitleCellRender } from "../../../common/CustomCellRender";

export const getMyDocumentColumns = (
  customSrCellRender,
  customDateCellRender,
  customActionCellRender
) => {
  const columns = [
    {
      field: "lineNo",
      headerName: Translate("common:srNoTitle"),
      align: "center",
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      renderCell: customSrCellRender,
    },
    {
      field: "title",
      headerName: Translate("document:documentTableHeading"),
      flex: 0.6,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => CustomTitleCellRender(params?.row?.title),
    },
    {
      field: "createdAt",
      headerName: Translate("document:dateTimeTableHeading"),
      flex: 0.5,
      sortable: false,
      disableColumnMenu: true,
      renderCell: customDateCellRender,
    },
    {
      field: "size",
      headerName: Translate("document:documentSizeTableHeading"),
      flex: 0.5,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) =>
        CustomTitleCellRender(toConvertKB(params.row?.size) || ""),
    },
    {
      field: "Action",
      headerName: Translate("common:actionTitle"),
      flex: 0.3,
      sortable: false,
      disableColumnMenu: true,
      hide: true,
      renderCell: customActionCellRender,
    },
  ];
  return columns;
};

import Translate from "../../../../translation/utils/translateFunction";

export const sortOptions = [
  {
    label: Translate("taskReport:recentTask"),
    value: "RECENT",
  },
  {
    label: Translate("taskReport:oldTask"),
    value: "ALL",
  },
];

export const priorityOptions = [
  {
    label: "All",
    value: "ALL",
  },
  {
    label: "Emergency",
    value: "Emergency",
  },
  {
    label: "High",
    value: "High",
  },
  {
    label: "Medium",
    value: "Medium",
  },
  {
    label: "Low",
    value: "Low",
  },
];

export const staffDefaultOptions = [
  {
    label: Translate("filter:all"),
    value: "ALL",
  },
];
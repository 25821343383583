import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { InitialValues, PrivateListType } from "../../component/constant";
import { AddContactSchema } from "../../component/utils";
import ContactForm from "../../component/ContactForm";
import { useEditContactMutation } from "../../../../request/ContactRepo";
import { showToast } from "../../../../common/Utils/ToastMessage";

const EditContact = () => {
  const { state } = useLocation();
  const [filesAdded, setfilesAdded] = useState([]);
  const [images, setImages] = useState([]);
  const formikRef = useRef(null);
  const navigate = useNavigate();

  const initialImage = [state?.contactProfileDetails];

  const [
    editContactApi,
    {
      data: editContact,
      isSuccess: successfulEditContact,
      isError: errorInEditContact,
      error: errorEditContact,
      isLoading: isEditingContact,
    },
  ] = useEditContactMutation();

  useEffect(() => {
    if (errorInEditContact && errorEditContact) {
      errorEditContact?.data?.error && errorEditContact.data.error.length > 0
        ? errorEditContact.data.error.map((errorItem) =>
            formikRef.current?.setFieldError(errorItem.param, errorItem.msg)
          )
        : showToast({
            message: errorEditContact?.data?.message,
            type: "error",
          });
    }

    if (successfulEditContact && editContact) {
      showToast({ message: editContact.message, type: "success" });
      formikRef.current.resetForm();
      setfilesAdded([]);
      setImages([]);

      if (editContact.data.contactType === PrivateListType) {
        navigate("/contactRepository/privateListing");
      } else {
        navigate("/contactRepository/publicListing");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editContact, errorEditContact]);

  const onSubmit = (values) => {
    let requestObject = {
      companyName: values?.companyName,
      contactName: values?.contactName,
      contactDesignation: values?.contactDesignation,
      contactIndustry: values?.contactIndustry,
      contactDepartment: values?.contactDepartment,
      contactField: values?.contactField,
      contactSector: values?.contactSector,
      workEmail: values?.workEmail,
      contactPhoneCountryCode: values?.contactPhoneCountryCode,
      contactPhone: values?.contactPhone?.split("-")[1],

      // alternateContact: values?.alternateContact,
      // alternateContact: values?.alternateContact?.split("-")[1],

      contactAddress: values?.contactAddress,
      contactType: values?.contactType,
      contactPhoneShortCode: values?.contactPhoneShortCode,
      isEdited: true,
    };
    if (filesAdded.length > 0) {
      if (filesAdded.every((value) => initialImage?.includes(value))) {
        requestObject = {
          ...requestObject,
          contactProfile: state?.contactProfile,
          contactProfileDetails: state?.contactProfileDetails,
        };
      } else {
        const b64Object = {
          ...filesAdded.map((ele) => ({
            url: ele?.b64,
            type: ele?.type,
            contactFileName: ele?.name,
            contactFileExt: ele?.name.split(".").pop(),
          }))[0],
        };

        requestObject = {
          ...requestObject,
          webAttachment: b64Object,
        };
      }
    } else {
      requestObject = {
        ...requestObject,
      };
    }

    if (
      values?.alternateContact?.length > 0 &&
      !values?.alternateContact?.endsWith("-")
    ) {
      requestObject = {
        ...requestObject,
        alternateContact: values?.alternateContact?.split("-")[1],

        alternateContactCountryCode: values?.alternateContactCountryCode,

        alternateContactShortCode: values?.alternateContactShortCode,
      };
    } else {
      requestObject = {
        ...requestObject,
        alternateContact: "",
        alternateContactCountryCode: "",

        alternateContactShortCode: "",
      };
    }

    editContactApi({
      body: requestObject,
      id: state._id,
    });
  };

  useEffect(() => {
    if (state) {
      formikRef.current.setFieldValue("companyName", state?.companyName);
      formikRef.current.setFieldValue("contactName", state?.contactName);
      formikRef.current.setFieldValue(
        "contactDesignation",
        state?.contactDesignation ? state?.contactDesignation : ""
      );
      formikRef.current.setFieldValue(
        "contactIndustry",
        state?.contactIndustry
      );
      formikRef.current.setFieldValue(
        "contactDepartment",
        state?.contactDepartment || ""
      );
      formikRef.current.setFieldValue("contactField", state?.contactField);
      formikRef.current.setFieldValue("contactSector", state?.contactSector);

      formikRef.current.setFieldValue(
        "workEmail",
        state?.contactEmail?.length && state?.contactEmail?.[0].email
          ? state?.contactEmail?.[0].email
          : state?.contactEmail?.length && state?.contactEmail?.[0]
          ? state?.contactEmail?.[0]
          : ""
      );
      formikRef.current.setFieldValue(
        "contactPhoneCountryCode",
        state?.contactPhoneCountryCode || "962"
      );

      formikRef.current.setFieldValue(
        "contactPhone",
        `${state?.contactPhoneCountryCode}-${state?.contactPhone}`
      );

      formikRef.current.setFieldValue(
        "alternateContactCountryCode",
        state?.alternateContactCountryCode
          ? state?.alternateContactCountryCode
          : "962"
      );
      if (state?.alternateContact) {
        formikRef.current.setFieldValue(
          "alternateContact",
          `${state?.alternateContactCountryCode}-${state?.alternateContact}`
        );
      }

      formikRef.current.setFieldValue("contactAddress", state?.contactAddress || "");
      formikRef.current.setFieldValue("contactType", state?.contactType);
      formikRef.current.setFieldValue(
        "alternateContactShortCode",
        state?.alternateContactShortCode
          ? state?.alternateContactShortCode
          : "JO"
      );
      formikRef.current.setFieldValue(
        "contactPhoneShortCode",
        state?.contactPhoneShortCode || "JO"
      );

      if (
        state?.contactProfileDetails &&
        Object.keys(state?.contactProfileDetails).includes("url")
      ) {
        setfilesAdded([state?.contactProfileDetails]);
        setImages([state?.contactProfileDetails?.url]);
      }
    }
  }, [state]);

  return (
    <Formik
      initialValues={InitialValues}
      innerRef={formikRef}
      validateOnMount
      onSubmit={onSubmit}
      validationSchema={AddContactSchema}>
      {({ values, handleSubmit }) => {
        return (
          <ContactForm
            isEdit
            values={values}
            handleSubmit={handleSubmit}
            filesAdded={filesAdded}
            images={images}
            setfilesAdded={setfilesAdded}
            setImages={setImages}
            ContactFormData={state}
            isLoading={isEditingContact}
            setDeletedAttachments={() => {}}
            listType={state?.contactType}
          />
        );
      }}
    </Formik>
  );
};
export default EditContact;

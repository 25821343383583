import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { InitialValues } from "../Components/constants";
import { AddEmployeeSchema } from "../Components/utils";
import EmployeeForm from "../Components/EmployeeForm";
import { useLocation } from "react-router-dom";
import {
  useRateEmployeeMutation,
  useViewEmployeeDataMutation,
} from "../../../../request/Staff/Employee";
import { showToast } from "../../../../common/Utils/ToastMessage";

const ViewEmployee = () => {
  const [filesAdded, setfilesAdded] = useState([]);
  const [images, setImages] = useState([]);
  const { state } = useLocation();
  const [isRedZone, setIsRedZone] = useState(false);
  const [reportTo, setReportTo] = useState({});
  const [isRating, setIsRating] = useState(false);
  const [
    getViewEmployeeApi,
    {
      data: viewEmployee,
      isLoading: isviewEmployeeDataLoading,
      isSuccess: isViewSuccess,
      isError: isViewError,
      error: errorViewEmployee,
    },
  ] = useViewEmployeeDataMutation();

  useEffect(() => {
    if (state) {
      getViewEmployeeApi(state);
    }
  }, [getViewEmployeeApi, state]);

  useEffect(() => {
    if (isViewError && errorViewEmployee) {
      showToast({
        message: errorViewEmployee?.data?.message,
        type: "error",
      });
    }

    if (isViewSuccess && viewEmployee) {
      const data = viewEmployee?.data[0];

      setIsRedZone(data.zone === "GREEN" ? false : true);

      if (data) {
        setReportTo({
          id: data?.reportTo?._id,
          name: data?.reportTo?.name,
          role: data?.reportTo?.role,
          profileUrl: data?.reportTo?.profileUrl,
        });

        if (data?.profileUrl) {
          const obj = {
            path: decodeURIComponent(data?.profileUrl).split("/").pop(),
            type: "image/png",
            url: data?.profileUrl,
          };
          setfilesAdded([...filesAdded, obj]);
          setImages([data?.profileUrl]);
        }
      }
    }
  }, [viewEmployee, errorViewEmployee]);

  const [
    rateEmployeeApi,
    {
      data: employeeRateData,
      isLoading: isEmployeeRateLoading,
      isSuccess: isRateSuccess,
      isError: isRateError,
      error: rateEmployeeError,
    },
  ] = useRateEmployeeMutation();

  useEffect(() => {
    if (rateEmployeeError && isRateError) {
      showToast({
        message: rateEmployeeError?.data?.message,
        type: "error",
      });
    }

    if (isRateSuccess && employeeRateData) {
      showToast({ message: employeeRateData.message, type: "success" });
      setIsRating((prev) => !prev);
    }
  }, [employeeRateData, rateEmployeeError]);

  const onSubmit = () => {
    const bodyObj = {
      zone: isRedZone ? "RED" : "GREEN",
    };

    rateEmployeeApi({
      id: viewEmployee?.data[0]._id,
      body: bodyObj,
    });
  };

  return (
    <Formik
      initialValues={InitialValues}
      onSubmit={onSubmit}
    >
      {({ values, handleSubmit }) => {
        return (
          <EmployeeForm
            values={values}
            handleSubmit={handleSubmit}
            filesAdded={filesAdded}
            images={images}
            setfilesAdded={setfilesAdded}
            setImages={setImages}
            employeeData={viewEmployee?.data[0]}
            isView
            isLoading={isviewEmployeeDataLoading || isEmployeeRateLoading}
            isRating={isRating}
            setIsRating={setIsRating}
            isRedZone={isRedZone}
            setIsRedZone={setIsRedZone}
            setReportTo={setReportTo}
          />
        );
      }}
    </Formik>
  );
};
export default ViewEmployee;

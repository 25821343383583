export default {
  fromDate: "From date",
  toDate: "To date",
  assignedByOwner: "Assigned by Owner",
  assignedByGm: "Assigned by GM",
  assignedByManager: "Assigned by Manager",
  generateReport: "Generate Report",
  reset: "Reset",
  fromDateError: "From date is required",
  toDateError: "To date is required",
  ownerMultiFieldError: "Select at least one field from Assigned by Owner or Assigned by GM or Assigned by Manager",
  ownerError: "Assigned by Owner is required",
  gmMultiFieldError: "Select at least one field from Assigned by GM or Assigned by Manager",
  gmError: "Assigned by GM is required",
  managerError: "Assigned by Manager is required",
  dateNote1: 'Note:- You can able to generate report upto',
  dateNote2: 'months from selected from date.',
};

import * as yup from "yup";
import Translate from "../../../translation/utils/translateFunction";
import { validateMobileSchema } from "../../../common/Utils/commonFunction";

export const AddVendorSchema = () => {
  const schema = yup.object().shape({
    companyId: yup.string().trim().required(Translate("vendors:companyError")),
    companyName: yup
      .string()
      .trim()
      .required(Translate("vendors:companyNameError")),
    name: yup.string().trim().required(Translate("vendors:nameError")),
    reportTo: yup.string().trim().required(Translate("vendors:reportToError")),
    ...validateMobileSchema("mobile", Translate("vendors:mobileError")),
    email: yup
      .string()
      .email(Translate("vendors:workEmailErrorInvalid"))
      .trim()
      .required(Translate("vendors:workEmailError")),
    workAddress: yup
      .string()
      .trim()
      .required(Translate("vendors:workAddressError")),
    country: yup.string().trim().required(Translate("addManager:countryError")),
    state: yup.string().trim().required(Translate("addManager:stateError")),
  });

  return schema;
};

export const validateAddressFields = (params) => {
  let param = "";

  switch (params) {
    case "workAddress.address":
      param = "workAddress";
      break;
    case "workAddress.country":
      param = "country";
      break;
    case "workAddress.state":
      param = "state";
      break;
    case "workAddress.zipCode":
      param = "zipCode";
      break;
    default:
      break;
  }

  return param;
};

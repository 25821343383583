const TASK = "TASK",
  TASK_LABEL = "Task",
  SUBTASK_LABEL = "Subtask",
  SUBTASK = "SUBTASK";

export const centerContent = {
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  alignContent: "center",
  fontWeight: "500",
  flex: 1,
};

export const moduleType = {
  TASK,
  SUBTASK,
};

export const taskStatusEnums = {
  ASSIGNED: "Assigned",
  ACCEPTED: "Accepted",
  INPROGRESS: "Inprogress",
  AWAITING_APPROVAL: "AwaitingApproval",
};

export const taskApprovalEnums = {
  ACCEPT_BY_ASSIGNEE: "ACCEPT_BY_ASSIGNEE",
  REJECT_BY_ASSIGNEE: "REJECT_BY_ASSIGNEE",
  START: "START",
  START_SELF_ASSIGNED: "START_SELF_ASSIGNED",
  RESOLVE: "RESOLVE",
  APPROVE_BY_REPORTER: "APPROVE_BY_REPORTER",
  DISAPPROVE_BY_REPORTER: "DISAPPROVE_BY_REPORTER",
  MARK_AS_COMPLETED: "MARK_AS_COMPLETED",
  REALLOCATION_REQUEST: "REALLOCATION_REQUEST",
  APPROVE_REALLOCATION_REQ: "APPROVE_REALLOCATION_REQ",
  DISAPPROVE_REALLOCATION_REQ: "DISAPPROVE_REALLOCATION_REQ",
  APPROVE_BY_MANAGER: "APPROVE_BY_MANAGER",
  DISAPPROVE_BY_MANAGER: "DISAPPROVE_BY_MANAGER"
};

export const moduleTypeOptions = [
  {
    label: TASK_LABEL,
    value: TASK,
  },
  {
    label: SUBTASK_LABEL,
    value: SUBTASK,
  },
];

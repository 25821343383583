import React from "react";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import { useFormikContext } from "formik";
import Translate from "../../../translation/utils/translateFunction";
import { FormikNumberField } from "../../FormikForm/component/FormikNumberField";
import RadioButtons from "../../RadioButtons";
import { customNotificationOptions, customReminderOptions } from "./constants";

const CustomReminder = ({
  handleModal,

  showModal,
  onSave,
  onCancel,
}) => {
  const handleClose = () => {
    onCancel(false);
  };

  const { values, setFieldValue, errors } = useFormikContext();

  const selectedTimeInterval = customReminderOptions.indexOf(
    values.timeInterval
  );

  const selectedNotification = customNotificationOptions.indexOf(
    values.notification
  );

  return (
    <>
      <Modal
        show={showModal}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        scrollable={true}
        className="col-span-3 col-md-12">
        <Modal.Header closeButton>
          <Modal.Title>
            {Translate("VoiceNotes:customReminderHeading")}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body
          style={{
            minHeight: "calc(100vh - 450px)",
            maxHeight: "calc(100vh - 450px)",
          }}>
          <div style={{ margin: "7% 7% 6% 0" }}>
            <FormikNumberField
              name="time"
              autoComplete={"off"}
              placeholder={Translate("VoiceNotes:reminderTimeLabel")}
              maxLength={2}
            />
          </div>
          <div className="row">
            <div className="form-group col-md-5 col-sm 8 customReminderRadioButtons">
              <RadioButtons
                name="timeInterval"
                isObject={true}
                radioOptions={customReminderOptions}
                defaultChecked={selectedTimeInterval}
                onPress={(event) => {
                  setFieldValue("timeInterval", event.target.value);
                }}
              />
            </div>
          </div>

          <div className="row customVoiceNotereminderSeperator">
            <div className="form-group col-md-5 col-sm 8 mt-4 customReminderRadioButtons">
              <RadioButtons
                name="notification"
                radioOptions={customNotificationOptions}
                defaultChecked={selectedNotification}
                isObject
                onPress={(event) => {
                  setFieldValue("notification", event.target.value);
                }}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="btn btn-white addButton"
            onClick={() => onCancel(false)}>
            {Translate("common:cancel")}
          </Button>
          <Button
            variant={`primary saveButton`}
            onClick={() => !errors.time && onSave(false)}
            disabled={errors.time || !values.time}>
            {Translate("common:save")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default CustomReminder;

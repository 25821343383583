import { colors } from "../../../common/Theme/Colors";

export const getTaskStatusColor = (taskStatus, taskStatusColor) => {
  const borderColor =
    taskStatus === "Inprogress"
      ? taskStatusColor.Inprogress
      : taskStatus === "Completed"
      ? taskStatusColor.Completed
      : taskStatus === "Reopened"
      ? taskStatusColor.Reopened
      : taskStatus === "Resolved"
      ? taskStatusColor.Resolved
      : taskStatus === "Overdue"
      ? taskStatusColor.Overdue
      : taskStatus === "Assigned"
      ? taskStatusColor.Assigned
      : taskStatus === "Accepted"
      ? taskStatusColor.Accepted
      : taskStatus === "AwaitingApproval"
      ? taskStatusColor.AwaitingApproval
      : taskStatus === "Reassigned"
      ? taskStatusColor.Reassigned
      : colors.grey_008;
  return borderColor;
};

import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Switch from "react-switch";
import { colors } from "../../../../common/Theme/Colors";
import { StyledTypography } from "../../../../common/Utils/StyledComponents";
import { showToast } from "../../../../common/Utils/ToastMessage";
import {
  useRateStaffGeneralManagerMutation,
  useViewStaffGeneralManagerDataMutation,
} from "../../../../request/Staff/Manager";
import Translate from "../../../../translation/utils/translateFunction";
import RadioButtons from "../../../RadioButtons";
import { TextField } from "../../../TextField/TextField";
import { radioOptions } from "./constants";
import { Persona } from "../../../Persona";
import PageHeader from "../../../common/pageHeader";
import { Formik } from "formik";
import FormikSearchableDropDown from "../../../FormikForm/component/FormikSearchableDropDown";
import { CustomUserItem } from "../../../common/CustomCellRender";

const ViewGeneralManager = () => {
  const navigate = useNavigate();
  const formikRef = useRef(null);
  const { state } = useLocation();
  const { validations } = useSelector((state) => state?.formanagement);
  const [reportTo, setReportTo] = useState("");

  const [
    generalManagerApi,
    {
      data: generalManagerData,
      isSuccess: successfullyGeneralManager,
      isError: errorAddingGeneralManager,
      error: errorGeneralManager,
    },
  ] = useViewStaffGeneralManagerDataMutation();

  const [
    rateGeneralManagerApi,
    {
      data: rateGeneralManagerData,
      isSuccess: rateSuccessfullyGeneralManager,
      isError: isErrorRateGeneralManager,
      error: errorRateGeneralManager,
    },
  ] = useRateStaffGeneralManagerMutation();

  const [GMData, setGMData] = useState({});
  const [isRating, setIsRating] = useState(false);
  const [isRedZone, setIsRedZone] = useState(false);

  useEffect(() => {
    generalManagerApi(state);
  }, [generalManagerApi, state]);

  useEffect(() => {
    if (errorAddingGeneralManager && errorGeneralManager) {
      showToast({
        message: errorGeneralManager?.data?.message,
        type: "error",
      });
    }

    if (successfullyGeneralManager && generalManagerData) {
      const { name, role, profileUrl, email } =
        generalManagerData?.data[0]?.reportTo;

      setGMData(generalManagerData?.data[0]);
      setIsRedZone(generalManagerData?.data[0].zone === "GREEN" ? false : true);
      setReportTo({
        value: name,
        label: (
          <CustomUserItem
            userName={name}
            title={role ? `${name} | ${role}` : name}
            profileUrl={profileUrl}
            email={email}
          />
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generalManagerData, errorGeneralManager]);

  useEffect(() => {
    if (errorRateGeneralManager && isErrorRateGeneralManager) {
      showToast({
        message: errorRateGeneralManager?.data?.message,
        type: "error",
      });
    }

    if (rateSuccessfullyGeneralManager && rateGeneralManagerData) {
      showToast({ message: rateGeneralManagerData.message, type: "success" });
      setIsRating((prev) => !prev);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rateGeneralManagerData, errorRateGeneralManager]);

  const onSubmit = () => {
    let bodyObj = {
      zone: isRedZone ? "RED" : "GREEN",
    };
    rateGeneralManagerApi({
      body: bodyObj,
      id: generalManagerData?.data[0]._id,
    });
  };

  return (
    <div className="row container_spacing">
      <div className="card card_spacing">
        <PageHeader
          title={Translate("addManager:viewGeneralManagerHeading")}
          onClick={() => navigate("/generalManagerListing")}
          RightContainer={() =>
            generalManagerData?.data[0].isEditable && (
              <div className="card-options">
                <i
                  className={`icon-color ml-3 cursor-pointer ${"fa fa-pencil-square-o"}`}
                  onClick={() =>
                    navigate("/addGeneralManager", {
                      state: generalManagerData?.data[0],
                    })
                  }
                  data-toggle="tooltip"
                  title={Translate("common:editToolTip")}
                ></i>
              </div>
            )
          }
        />
        <div className="card-body">
          <div className="row imageDisplay mb-2">
            <div className="form-group col-md-4 col-sm-8">
              <Persona
                name={GMData?.name}
                image={GMData?.profileImageDetails?.url}
                size={96}
              />
            </div>
          </div>
          <div className="row">
            <div className="form-group col-md-4 col-sm-12">
              <TextField
                value={GMData?.company?.map((ele) => ele.name).join(", ")}
                label={Translate("addManager:selectCompany")}
                placeholder={Translate("addManager:dropdownPlaceholder")}
                autoComplete={"off"}
                maxLength={validations?.nameLength.MAX}
                disabled={true}
                className={"form-control textFieldHeight"}
              />
            </div>
            <div className="form-group col-md-4 col-sm-12">
              <TextField
                value={GMData?.name}
                label={Translate("addManager:gmName")}
                placeholder={Translate("addManager:gmName")}
                autoComplete={"off"}
                maxLength={validations?.nameLength.MAX}
                disabled={true}
                className={"form-control textFieldHeight"}
              />
            </div>
            <div className="form-group col-md-4 col-sm-12">
              <TextField
                label={Translate("addManager:dob")}
                value={GMData?.dob}
                placeholder={Translate("addManager:dob")}
                disabled={true}
                className={"form-control textFieldHeight"}
              />
            </div>
          </div>
          <div className="row">
            <div className="form-group col-md-4 col-sm-12">
              <TextField
                value={GMData?.designation}
                label={Translate("addManager:designation")}
                placeholder={Translate("addManager:designation")}
                autoComplete={"off"}
                maxLength={validations?.designationLength.MAX}
                disabled={true}
                className={"form-control textFieldHeight"}
              />
            </div>
            <div className="form-group col-md-4 col-sm-12">
              <TextField
                value={GMData?.department}
                label={Translate("addManager:department")}
                placeholder={Translate("addManager:department")}
                autoComplete={"off"}
                maxLength={validations?.departmentLength.MAX}
                disabled={true}
                className={"form-control textFieldHeight"}
              />
            </div>
            <div className="form-group col-md-4 col-sm-12">
              <Formik
                innerRef={formikRef}
                initialValues={{ reportTo: "" }}
                validateOnMount
              >
                {({}) => {
                  return (
                    <FormikSearchableDropDown
                      selected={reportTo}
                      options={[]}
                      label={Translate("addManager:reportToLabel")}
                      placeholder={Translate("addManager:reportToLabel")}
                      name="reportTo"
                      disabled
                    />
                  );
                }}
              </Formik>
            </div>
          </div>
          <div className="row">
            <div className="form-group col-md-4 col-sm-12">
              <TextField
                value={GMData?.login?.mobileWithCountryCode}
                label={Translate("addManager:contactNumber")}
                placeholder={Translate("addManager:contactNumberPlaceHolder")}
                autoComplete={"off"}
                maxLength={validations?.contactPhoneLength.MAX}
                disabled={true}
                className={"form-control textFieldHeight"}
              />
            </div>
            <div className="form-group col-md-4 col-sm-12">
              <TextField
                value={
                  GMData?.alternateMobile?.length > 0
                    ? GMData?.alternateMobileWithCountryCode
                    : "NA"
                }
                label={Translate("addOwner:alternateMobile")}
                placeholder={Translate("addOwner:contactNumberPlaceholder")}
                autoComplete={"off"}
                maxLength={validations?.contactPhoneLength.MAX}
                disabled={true}
                className={"form-control textFieldHeight"}
              />
            </div>
            <div className="form-group col-md-4 col-sm-12">
              <TextField
                value={GMData?.hrMobileWithCountryCode || "NA"}
                label={Translate("addManager:hrContactNumber")}
                placeholder={Translate("addManager:contactNumberPlaceHolder")}
                autoComplete={"off"}
                maxLength={validations?.contactPhoneLength.MAX}
                disabled={true}
                className={"form-control textFieldHeight"}
              />
            </div>
          </div>
          <div className="row">
            <div className="form-group col-md-4 col-sm-12 ">
              <TextField
                value={GMData?.companyNumberWithExtension || "NA"}
                label={Translate("addManager:companyExtensionNumber")}
                placeholder={Translate("addManager:extensionNumberPlaceholder")}
                autoComplete={"off"}
                maxLength={validations?.telephoneLength.MAX}
                disabled={true}
                className={"form-control textFieldHeight"}
              />
            </div>
            <div className="form-group col-md-4 col-sm-12">
              <TextField
                value={GMData?.login?.email}
                label={Translate("addManager:email")}
                placeholder={Translate("addManager:emailPlaceholder")}
                autoComplete={"off"}
                disabled={true}
                className={"form-control textFieldHeight"}
              />
            </div>
          </div>
          <div className="row">
            <div className="form-group col-md-12 col-sm-12">
              <div className="AddressLabel">
                <StyledTypography variant="subtitle1">
                  {Translate("addManager:address")}
                </StyledTypography>
              </div>
              <TextField
                value={GMData?.residenceAddress?.address}
                placeholder={Translate("addManager:addressPlaceholder_1")}
                autoComplete={"off"}
                isLocation={true}
                locationValue={() => {}}
                disabled={true}
                className={"form-control textFieldHeight"}
              />
            </div>
          </div>
          <div className="row">
            <div className="form-group col-md-12 col-sm-12">
              <TextField
                value={GMData?.residenceAddress?.flat}
                placeholder={Translate("addManager:addressPlaceholder_2")}
                autoComplete={"off"}
                maxLength={validations?.landmarkLimit.MAX}
                disabled={true}
                className={"form-control textFieldHeight"}
              />
            </div>
          </div>
          <div className="row">
            <div className="form-group col-md-4 col-sm 8">
              <TextField
                placeholder={Translate("addManager:countryPlaceholder")}
                value={GMData?.residenceAddress?.country}
                disabled={true}
                maxLength={validations?.cityLimit.MAX}
                className={"form-control textFieldHeight"}
              />
            </div>
            <div className="form-group col-md-4 col-sm 8">
              <TextField
                placeholder={Translate("addManager:statePlaceholder")}
                value={GMData?.residenceAddress?.state}
                disabled={true}
                maxLength={validations?.cityLimit.MAX}
                className={"form-control textFieldHeight"}
              />
            </div>
          </div>
          <div className="row">
            <div className="form-group col-md-4 col-sm 8">
              <TextField
                value={GMData?.residenceAddress?.city}
                placeholder={Translate("addManager:cityPlaceholder")}
                maxLength={validations?.cityLimit.MAX}
                disabled={true}
                className={"form-control textFieldHeight"}
              />
            </div>
            <div className="form-group col-md-4 col-sm 8">
              <TextField
                value={GMData?.residenceAddress?.zipCode}
                maxLength={6}
                placeholder={Translate("addManager:zipcodePlaceholder")}
                disabled={true}
                className={"form-control textFieldHeight"}
              />
            </div>
          </div>

          <div className="row">
            <div className="form-group col-md-12 col-sm-12">
              <div className="AddressLabel">
                <StyledTypography variant="subtitle1">
                  {Translate("addManager:workAddress")}
                </StyledTypography>
              </div>
              <TextField
                value={GMData?.workAddress?.address}
                placeholder={Translate("addManager:addressPlaceholder_3")}
                autoComplete={"off"}
                isLocation={true}
                //   RenderRightContainer={<LocationIcon />}
                locationValue={() => {}}
                disabled={true}
                className={"form-control textFieldHeight"}
              />
            </div>
          </div>
          <div className="row">
            <div className="form-group col-md-12 col-sm-12">
              <TextField
                value={GMData?.workAddress?.flat}
                placeholder={Translate("addManager:addressPlaceholder_4")}
                autoComplete={"off"}
                maxLength={validations?.landmarkLimit.MAX}
                disabled={true}
                className={"form-control textFieldHeight"}
              />
            </div>
          </div>
          <div className="row">
            <div className="form-group col-md-4 col-sm 8">
              <TextField
                value={GMData?.workAddress?.country}
                placeholder={Translate("addManager:countryPlaceholder")}
                disabled={true}
                maxLength={validations?.cityLimit.MAX}
                className={"form-control textFieldHeight"}
              />
            </div>
            <div className="form-group col-md-4 col-sm 8">
              <TextField
                value={GMData?.workAddress?.state}
                placeholder={Translate("addManager:statePlaceholder")}
                disabled={true}
                maxLength={validations?.cityLimit.MAX}
                className={"form-control textFieldHeight"}
              />
            </div>
          </div>
          <div className="row">
            <div className="form-group col-md-4 col-sm 8">
              <TextField
                value={GMData?.workAddress?.city}
                placeholder={Translate("addManager:cityPlaceholder")}
                maxLength={validations?.cityLimit.MAX}
                disabled={true}
                className={"form-control textFieldHeight"}
              />
            </div>
            <div className="form-group col-md-4 col-sm 8">
              <TextField
                value={GMData?.workAddress?.zipCode}
                maxLength={6}
                placeholder={Translate("addManager:zipcodePlaceholder")}
                disabled={true}
                className={"form-control textFieldHeight"}
              />
            </div>
          </div>
          {GMData?.gender && (
            <div className="row">
              <div className="form-group col-md-4 col-sm 8">
                <RadioButtons
                  radioOptions={radioOptions}
                  defaultChecked={GMData?.gender === "MALE" ? 0 : 1}
                  label={Translate("addManager:gender")}
                  disabled
                />
              </div>
            </div>
          )}

          {isRating && (
            <div>
              <div>
                <span style={{ color: colors.primary_003 }}>
                  {Translate("common:rate")}
                </span>
              </div>
              <div className="row">
                <h7
                  className="nav-link"
                  style={{
                    color: isRedZone ? colors.red : colors.green,
                    paddingBottom: 10,
                  }}
                >
                  {isRedZone ? "Red zone" : "Green zone"}
                </h7>
                <Switch
                  onChange={() => {
                    setIsRedZone((prev) => !prev);
                  }}
                  checked={isRedZone}
                  height={25}
                  width={50}
                  onHandleColor={colors.primary}
                  onColor={colors.primary_002}
                />
              </div>
            </div>
          )}

          {(GMData?.isRateable || GMData?.isPerformanceAccess) && (
            <div className="card-footer text-right">
              {GMData?.isPerformanceAccess && (
                <button
                  type="submit"
                  className={`btn btn-primary saveButton ${
                    isRating && "staffPerformanceBtn"
                  }`}
                  onClick={() =>
                    navigate("/staff/performance", {
                      state: { userData: GMData },
                    })
                  }
                >
                  {Translate("common:performance")}
                </button>
              )}
              {GMData?.isRateable &&
                (!isRating ? (
                  <button
                    type="submit"
                    className="btn btn-white addButton"
                    onClick={() => {
                      setIsRating((prev) => !prev);
                    }}
                  >
                    {Translate("common:rate")}
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="btn btn-primary saveButton"
                    onClick={() => {
                      onSubmit();
                    }}
                  >
                    {Translate("addManager:save")}
                  </button>
                ))}
            </div>
          )}

          {/*  {GMData?.isEditable && (
            <div className="card-footer text-right">
              <button
              type="submit"
              className="btn btn-primary saveButton"
              onClick={() =>
                navigate("/staff/performance", {
                  state: { userData: GMData },
                })
              }
            >
              {Translate("common:performance")}
            </button>
              {!isRating ? (
                <button
                  type="submit"
                  className="btn btn-white addButton"
                  onClick={() => {
                    setIsRating((prev) => !prev);
                  }}>
                  Rate
                </button>
              ) : (
                <button
                  type="submit"
                  className="btn btn-primary saveButton"
                  onClick={() => {
                    onSubmit();
                  }}>
                  {Translate("addManager:save")}
                </button>
              )}
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
};
export default ViewGeneralManager;

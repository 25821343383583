import Translate from "../../../../translation/utils/translateFunction";

export const getContactRepoSelectMemeberPrivateContacts = (
  customCellRender,
  CustomCellActionRender
) => {
  const columns = [
    {
      field: "serialNumber",
      headerName: Translate("contacts:srNo"),
      sortable: false,
      disableColumnMenu: true,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "contactName",
      headerName: Translate("contacts:userName"),
      flex: 0.5,
      sortable: false,
      disableColumnMenu: true,
      renderCell: customCellRender,
    },

    {
      field: "Action",
      headerName: Translate("contacts:action"),
      flex: 0.4,
      sortable: false,
      disableColumnMenu: true,
      renderCell: CustomCellActionRender,
    },
  ];
  return columns;
};

export const getContactRepoShareMembers = (
  customCellRender,
  CustomCellActionRender
) => {
  const columns = [
    {
      field: "serialNumber",
      headerName: Translate("contacts:srNo"),
      sortable: false,
      disableColumnMenu: true,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "contactName",
      headerName: Translate("contacts:userName"),
      flex: 0.5,
      sortable: false,
      disableColumnMenu: true,
      renderCell: customCellRender,
    },

    {
      field: "contactPhone",
      headerName: Translate("contacts:contactNumber"),
      flex: 0.4,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "Action",
      headerName: Translate("contacts:action"),
      flex: 0.4,
      sortable: false,
      disableColumnMenu: true,
      renderCell: CustomCellActionRender,
    },
  ];
  return columns;
};

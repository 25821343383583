import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { InitialValues } from "../constants";
import { addCompanySchema } from "../utils";
import { useSelector } from "react-redux";
import { showToast } from "../../../common/Utils/ToastMessage";
import { useLocation, useNavigate } from "react-router-dom";
import { useGetCompanyDataMutation } from "../../../request/company";
import CompanyForm from "../components/CompanyForm";
import { getAddressError } from "../../../common/Utils/commonFunction";

const AddCompany = () => {
  const formikRef = useRef(null);
  const { state } = useLocation();
  const navigate = useNavigate();

  const { userData } = useSelector((state) => state.formanagement);
  const [filesAdded, setfilesAdded] = useState([]);
  const [images, setImages] = useState([]);
  const [addMore, setAddMore] = useState(false);

  const [
    addCompany,
    {
      data: companyData,
      isLoading: loadingCompanyData,
      isError: companyDataIsError,
      isSuccess: companyDataSuccess,
      error: companyDataError,
    },
  ] = useGetCompanyDataMutation();

  useEffect(() => {
    if (companyDataIsError && companyDataError) {
      companyDataError.data.error &&
        companyDataError.data.error.length > 0 &&
        companyDataError.data.error.map((errorItem) =>{
          const isPresent = getAddressError(
            errorItem.param,
            formikRef.current?.setFieldError
          );
          !isPresent &&
            formikRef.current.setFieldError(errorItem.param, errorItem.msg);
        });
    }
    if (companyDataSuccess && companyData) {
      showToast({ message: "Company added successfully", type: "success" });
      formikRef.current.resetForm();
      setfilesAdded([]);
      setImages([]);
      if (addMore) {
        formikRef.current.resetForm();
      } else {
        navigate("/");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyDataError, companyData]);

  useEffect(() => {
    if (filesAdded.length > 0) {
      const newImages = filesAdded.map((imageElement) =>
        imageElement.preview ? imageElement.preview : imageElement?.url
      );
      setImages(newImages);
    }
  }, [filesAdded]);

  const onSubmit = async (companyFormValues) => {
    // ************************Request Object****************************************
    let requestObject = {
      clientId: userData?.clientId,

      name: companyFormValues.name,
      website: companyFormValues.website,

      countryCode: companyFormValues.countryCode,
      // contact: companyFormValues.contact,
      contact: companyFormValues.contact?.split("-")[1],

      officeTelephone: companyFormValues.officeTelephone,
      contactWithCountryCode: companyFormValues.countryCode.concat(
        companyFormValues.contact?.split("-")[1]
      ),
      officeAddress: {
        latlong: {
          latitude: companyFormValues.lat,
          longitude: companyFormValues.lng,
        },
        address: companyFormValues.address,
        country: companyFormValues.country,
        state: companyFormValues.state,
        city: companyFormValues.city,
        landmark: companyFormValues.landmark,
        zipcode: companyFormValues.zipcode,
      },
      mobileShortCode: companyFormValues?.mobileShortCode,
    };
    let b64Array;
    if (filesAdded.length > 0) {
      b64Array = filesAdded[0].b64;
      requestObject = {
        ...requestObject,
        webLogo: b64Array,
      };
    } else {
      requestObject = {
        ...requestObject,
        webLogo: state?.viewCompanyData?.logo,
      };
    }

    if (!filesAdded?.length) {
      requestObject = {
        ...requestObject,
        webLogo: "",
      };
    }

    addCompany(requestObject);
  };

  return (
    <Formik
      initialValues={InitialValues}
      innerRef={formikRef}
      validateOnMount
      onSubmit={onSubmit}
      validationSchema={addCompanySchema}
    >
      {({ values, handleSubmit }) => {
        return (
          <CompanyForm
            values={values}
            handleSubmit={handleSubmit}
            filesAdded={filesAdded}
            images={images}
            setfilesAdded={setfilesAdded}
            setImages={setImages}
            isLoading={loadingCompanyData}
            onAddMore={(value) => setAddMore(value)}
            isAdd
          />
        );
      }}
    </Formik>
  );
};
export default AddCompany;

export default {
  type: "النوع",
  title: "العنوان",
  titlePlaceholder: "العنوان",
  taskName: "العنوان",
  subtaskName: "اسم المهمة الفرعية",
  add: "يضيف",
  addTaskHeading: "اختر مهمة",
  description: "الوصف",
  descriptionPlaceholder: "إضافة وصف",
  assignTo: "اسناد لِ",
  startDate: "تاريخ االبدء",
  dueDate: "تاريخ التسليم",
  priority: "الأولوية",
  subTask: "المهمة الفرعية",
  attach: "أرفق ملف",
  attachHeading: "المرفقات",
  dropdownPlaceholder: "اختر النوع",
  companyDropdownPlaceholder: "اختر الشركة",
  companyError: "اختر الشركة أول",
  titleError: "النوع حقل مطلوب",
  nameError: "العنوان حقل مطلوب",
  addError: "إضافة المهمة/المهمة الفرعية حقل مطلوب",
  designationError: "الوظيفة حقل مطلوب",
  descriptionError: "الوصف حقل مطلوب",
  assignToError: "اسناد المهمة لِ حقل مطلوب",
  startDateError: "تاريخ البدء حقل مطلوب",
  dueDateError: "تاريخ الإستحقاق حقل مطلوب",
  priorityError: "الأولوية حقل مطلوب",
  dateError: "التاريخ حقل مطلوب",
  timeError: "التوقيت حقل مطلوب",
  reportTo: "المدير المباشر",
  reportToError: "المدير المباشر حقل مطلوب",
  parentTaskName: "اسم المهمة الأساسية",
  parentNameError: "اسم المهمة الأساسية حقل مطلوب",
  relatedTask: "مهام ذات صلة",
  relatedTaskPlaceholder: "اسم المهمة ذات صلة",
  markAsCritical: 'وضع علامة "مهمة جداً"',
  startTime: "توقيت البدء",
  dueTime: "توقيت الإستحقاق",
  AddSubtaskAlert: "يرجى حفظ تفاصيل المهمة لإضافة مهمة فرعية",
  AddSubtaskAlertButton: "حفظ واستمرار",
  voiceNote: "أضف رسالة صوتيه",
  assigneePlaceholder: "حدد المكلّف",
  reporterPlaceholder: "اختر المراسل",
  taskNameError: "اسم المهمة حقل مطلوب",
  sameDateError: "يجب ألا يكون تاريخ الاستحقاق مساوياً لتاريخ البدء",
  beforeDateError: "يجب أن يكون تاريخ الاستحقاق أكبر من التاريخ الحالي",
  descriptionInputError: "يجب أن يحتوي الوصف على حرف واحد على الأقل",
  editTaskHeading: "تحرير المهمة",
  save: "حفظ",
  deleteAlertTitle: "هل أنت متأكد من حذف هذا التعليق ؟",
  subtaskButton: "المهمة الفرعية",
  addSubtaskHeading: "اختر مهمة فرعية",
  editSubtaskHeading: "تحرير المهمة الفرعية",
  parentTitle: "عنوان المهمة الأساسية",
  subtaskTitle: "نوع المهمة الفرعية",
  typeError: "النوع حقل مطلوب",
  parentTitleError: "عنوان المهمة الأساسية حقل مطلوب",
  startTimeError: "توقيت البدء حقل مطلوب",
  dueTimeError: "الوقت المناسب مطلوب",
  commentAlert: "الرجاء إضافة تعليق",
  companyReqError: "الشركة حقل مطلوب",
  assignToPlaceholder: "حدد المكلّف",
  subtaskNameError: "اسم المهمة الفرعية مطلوب",
  taskDependancyHeader1: "تم تكليفه بالمهمة",
  taskDependancyHeader2: "مهمة فرعية",
  taskDependancyHeader3: "مهمة ذات صلة",
  project: "مشروع",
  projectPlaceholder: "اختر مشروع",
  projectError: "المشروع مطلوب",
};

export default {
  head: "Voice note",
  myVoiceNotes: "My voice notes",
  sharedWithMe: "Shared with me",
  share: "Share",
  setReminder: "Set reminder",
  sharedByMe: "Shared by me",
  deleteAlert: "Are you sure, you want to delete this voice Note?",
  addVoiceNote: "Add voice note",
  title: "Title",
  titleError: "Title is Invalid.",
  VoiceRec: "Voice note recording",
  errorMessage: "Title is invalid",
  searchError: "Space or special characters are not allowed",
  minError: "Require at least 3 characters",
  searchPlaceHolder: "Search something...",
  srNoTableHeading: "Sr.no",
  voicenoteTitle: "Title",
  voiceNote: "Voice note",
  dateTime: "Date and time",
  duration: "Duration",
  action: "Action",
  deleteAlertMsg: "Are you sure you want to delete this voice note?",
  OWN: "Created by own",
  PA: "Created by PA",
  apiFailedMessage: "Failed to fetch the data",
  requiredValidation: "Title is required",
  reminderpageHeading: "Set reminder",
  reminderFor: "Reminder for",
  reminderName: "Reminder title",
  remindTo: "Remind to",
  date: "Date",
  time: "Time",
  reminder: "Reminder",
  addReminder: "Select reminder",
  save: "Save",
  reminderForRequired: "Reminder for is required",
  reminderTypeRequired: "Reminder type is required",
  accesslogsSrno: "Sr.no",
  accesslogsName: "User name",
  accesslogsDesignation: "Designation",
  accesslogsCompanyName: "Company name",
  accesslogsDateTime: "Date and Time",
  renameTitle: "Rename voice note",
  name: "Name",
  deleteVoiceNote: "Delete voice note",
  sharedBy: "Shared by",
  srNo: "Sr.no",
  userName: "User name",
  designation: "Designation",
  companyName: "Company name",
  DateTime: "Date and Time",
  customReminderHeading: "Custom reminder",
  reminderTimeLabel: "Enter value",
  closeCustomReminderButtonLabel: "Cancel",
  timeError: "Cannot set reminder for past time",
  reminderTimeMessageErrorVoiceNotes: "Please provide values between",
  startTime: "Start time",
  reminderTimeError: "Reminder time must be a number",
  minutes: "minutes",
  hours: "hours",
  days: "days",
  weeks: "weeks",
  to: "to",
  modalTitle: "Share to",
  editIcon: "Rename",
  deleteIcon: "Delete",
  shareIcon: "Share",
  reminderIcon: "Reminder",
  accesslogsIcon: "Access logs",
  reminderToRequired: "Remind to is required",
};

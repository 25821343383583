import React from "react";
import Translate from "../../translation/utils/translateFunction";
import { useSelector } from "react-redux";

const PageHeader = ({
  title,
  onClick,
  toolTip = Translate("common:backToolTip"),
  RightContainer,
  containerClass = "page-title formTitle p-3 mb-3 row align-items-center",
}) => {
  const { isRtl } = useSelector((state) => state.formanagement);
  return isRtl ? (
    <div className={containerClass}>
      {title}
      <i
        className="icon fe fe-arrow-left ml-3 icon-size backnavigation-iconColor cursor-pointer"
        onClick={onClick}
        data-toggle="tooltip"
        title={toolTip}
      />

      {RightContainer && <RightContainer />}
    </div>
  ) : (
    <div className={containerClass}>
      <i
        className="icon fe fe-arrow-left mr-3 icon-size backnavigation-iconColor cursor-pointer"
        onClick={onClick}
        data-toggle="tooltip"
        title={toolTip}
      />
      {title}
      {RightContainer && <RightContainer />}
    </div>
  );
};
export default PageHeader;

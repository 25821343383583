export default {
  head: "Inbox",
  subject: "Subject",
  from: "From",
  to: "To",
  date: "Date",
  create: "Create task",
  related: "Related task",
  assigned: "Assigned task",
  viewAssignedTask: "View assigned task",
  cc: "CC",
  inboxViwe: "View",
  filter: "Filter",
  Inbox: "Inbox",
  Sent: "Sent mail",
  createTaskButton: "Create task",
  relatedTaskButton: "Related task",
  actionable: "Actionable",
  informative: "Informative",
  labelAs: "Label as",
  sentFilter: "Sent",
};

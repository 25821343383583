import { LinearProgress, Stack } from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDebouncedCallback } from "use-debounce";
import { colors } from "../../../../common/Theme/Colors";
import { StyledTypography } from "../../../../common/Utils/StyledComponents";
import { showToast } from "../../../../common/Utils/ToastMessage";
import {
  useAddGroupMemberMutation,
  useGetGroupInviteeMutation,
  useGroupDetailsMutation,
} from "../../../../request/Message";
import Translate from "../../../../translation/utils/translateFunction";
import IconifyIcon from "../../../Icon";
import MessageListLoader from "../../Components/MessageLoader/MessageListLoader";
import GroupList from "../GroupList";
import MemberProfileList from "../MemberProfileList";
import NoDataFound from "../../../ManageTask/NoDataFound";
import { useSelector } from "react-redux";
import Searchbar from "../../../common/Searchbar";
import { handleEventKeySubmit } from "../../../../common/Utils/commonFunction";

const AddGroupMember = ({
  showModal,
  handleClose,
  groupId,
  selectedUser,
  setShowAddMember,
  onClickForAddMember,
}) => {
  const defaultBtnRef = useRef(null);
  const { isRtl } = useSelector((state) => state.formanagement);
  const [searchText, setSearchText] = useState("");
  const [existingMemberIds, setExistingMemberIds] = useState([]);
  const [groupMembers, setGroupMembers] = useState([]);
  const [newMembers, setNewMembers] = useState([]);
  const [inviteeMembers, setInviteeMembers] = useState([]);
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const isLastPage = useRef(false);

  const [
    getGroupInfoApi,
    {
      data: groupInfoData,
      isLoading: isGroupInfoLoading,
      isSuccess: isGroupInfoSuccess,
      isError: isGroupInfoError,
      error: groupInfoError,
    },
  ] = useGroupDetailsMutation();

  const [
    getGroupInviteeList,
    {
      data: groupInviteeData,
      isLoading: isGroupInviteeLoading,
      isSuccess: isGroupInviteeSuccess,
      isError: isGroupInviteeError,
      error: groupInviteeError,
    },
  ] = useGetGroupInviteeMutation();

  const [
    addMemberApi,
    {
      data: addMemberData,
      isLoading: isAddMemberLoading,
      isSuccess: isAddMemberSuccess,
      isError: isAddMemberError,
      error: addGroupError,
    },
  ] = useAddGroupMemberMutation();

  useEffect(() => {
    if (showModal && newMembers?.length > 0) {
      defaultBtnRef.current.focus();
    }
  }, [showModal, newMembers]);

  useEffect(() => {
    if (groupId) {
      getGroupInfoApi({ groupId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupId]);

  const groupInviteeRequest = useMemo(() => {
    return {
      pageNo: currentPage,
      searchText,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, searchText]);

  const debouncedSearch = useDebouncedCallback(() => {
    getGroupInviteeList(groupInviteeRequest);
  }, 500);

  useEffect(() => {
    if (groupInviteeRequest?.searchText?.length) {
      debouncedSearch();
    } else {
      getGroupInviteeList(groupInviteeRequest);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupInviteeRequest]);

  useEffect(() => {
    if (groupInfoData && isGroupInfoSuccess) {
      const { record } = groupInfoData?.data;
      setGroupMembers(record);
      setExistingMemberIds(record?.map((item) => item?._id));
    }
    if (isGroupInfoError && groupInfoError) {
      showToast({
        message:
          groupInfoError?.data.message ||
          Translate("common:unknownServerError"),
        type: "error",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupInfoData, isGroupInfoSuccess, isGroupInfoError]);

  useEffect(() => {
    if (groupInviteeData && isGroupInviteeSuccess) {
      const { data } = groupInviteeData;
      const { nodes, pageInfo } = data;

      currentPage !== 1
        ? setInviteeMembers((prev) => prev.concat(nodes))
        : setInviteeMembers(nodes);
      isLastPage.current = pageInfo?.hasNextPage;
    }
    if (isGroupInviteeError && groupInviteeError) {
      showToast({
        message:
          groupInviteeError?.data.message ||
          Translate("common:unknownServerError"),
        type: "error",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupInviteeData, isGroupInviteeSuccess, isGroupInviteeError]);

  useEffect(() => {
    if (isAddMemberError && addGroupError) {
      const groupError = addGroupError?.data;

      if (!groupError) {
        showToast({
          message: Translate("common:unknownServerError"),
          type: "error",
        });
      } else {
        groupError?.error?.length
          ? groupError.error.map((errorItem) => {
              return showToast({ message: errorItem.msg, type: "error" });
            })
          : showToast({
              message:
                groupError?.message || Translate("common:unknownServerError"),
              type: "error",
            });
      }
    }

    if (isAddMemberSuccess && addMemberData) {
      showToast({
        type: "success",
        message: addMemberData.message,
      });

      onClickForAddMember();
      setShowAddMember(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addGroupError, addMemberData]);

  const handleSelectedUsers = (value) => {
    const isExist = newMembers.find((item) => item?._id === value?._id);

    if (existingMemberIds?.includes(value?._id)) return;

    if (isExist) {
      setGroupMembers(groupMembers.filter((item) => item?._id !== value?._id));
      setNewMembers(newMembers.filter((item) => item?._id !== value?._id));
    } else {
      setGroupMembers([...groupMembers, value]);
      setNewMembers([...newMembers, value]);
    }
  };

  const addGroupMember = () => {
    if (newMembers?.length) {
      const userIds = newMembers?.map((user) => user?._id);

      addMemberApi({ groupId, body: { users: userIds } });
    } else {
      showToast({
        message:
          groupInfoError?.data.message || Translate("group:addMemberError"),
        type: "error",
      });
    }
  };

  const handleSearchText = (value) => {
    setCurrentPage(1);
    setSearchText(value);
  };

  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable={true}
      className={`col-span-3 col-md-12 ${isRtl && "rtl"}`}
    >
      <Modal.Body style={{ padding: "0px", backgroundColor: "#f1f1f1" }}>
        <Stack py={2} px={3} sx={{ background: colors.grey_016 }}>
          <div className="addGroupMemberHeader">
            <StyledTypography variant="h6" sx={{ fontSize: "1.4rem" }}>
              {Translate("messagePage:contacts")}
            </StyledTypography>
            <button
              type="button"
              className="close cursor-pointer align-self-end"
              data-dismiss="alert"
              aria-label="Close"
              onClick={handleClose}
            />
          </div>

          <div
            style={{
              height: "calc(100vh - 210px)",
            }}
          >
            <div className="groupInfoSearch">
              <StyledTypography
                variant="subtitle2"
                my={2}
                sx={{ color: colors.primary_010 }}
              >
                {Translate("group:addMember")}
              </StyledTypography>
              <span
                onClick={() => setShowSearchBar((val) => !val)}
                data-toggle="tooltip"
                title={Translate("common:search")}
                className="cursor-pointer"
              >
                <IconifyIcon icon="material-symbols:search" color="#5a5278" />
              </span>
            </div>

            <div className="input-group mb-2" style={{ width: "100%" }}>
              {showSearchBar && (
                <Searchbar
                  handleSearchText={handleSearchText}
                  setSearch={setSearchText}
                  isMessageModule={true}
                />
              )}
            </div>
            {isGroupInfoLoading ||
            isAddMemberLoading ||
            (isGroupInviteeLoading && currentPage === 1) ? (
              <LinearProgress style={{ height: 2 }} />
            ) : (
              <>
                {newMembers?.length > 0 && (
                  <MemberProfileList
                    members={newMembers}
                    onClick={handleSelectedUsers}
                    isIcon
                  />
                )}
                {inviteeMembers?.length > 0 ? (
                  <GroupList
                    chatList={inviteeMembers}
                    selectedUser={selectedUser}
                    checkedGroupMembers={groupMembers}
                    onClick={handleSelectedUsers}
                    isNewGroup
                  />
                ) : (
                  !groupInviteeData?.data?.nodes?.length && (
                    <NoDataFound isCenter />
                  )
                )}
              </>
            )}

            {isLastPage.current && !isAddMemberLoading && (
              <MessageListLoader
                isLoading={isGroupInviteeLoading || isGroupInfoLoading}
                onLoadMoreClick={() => setCurrentPage((prev) => prev + 1)}
                styleProps={{ paddingBottom: "20px" }}
              />
            )}
          </div>
        </Stack>
      </Modal.Body>
      {newMembers?.length > 0 && (
        <Modal.Footer>
          <Button
            variant={`primary saveButton`}
            onClick={addGroupMember}
            ref={defaultBtnRef}
            onKeyDown={(e) => handleEventKeySubmit(e, addGroupMember)}
          >
            {Translate("common:save")}
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};
export default AddGroupMember;

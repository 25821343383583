import { CreateApi } from "../../CreateApi";
import { Methods, Urls } from "../../../common/Utils/ApiConstants";
const documentRepositorycollection = CreateApi.injectEndpoints({
  endpoints: (builder) => ({
    addDocumentData: builder.mutation({
      query: (body) => {
        return {
          url: Urls.addDocument,
          method: Methods.post,
          body: body,
        };
      },
    }),
    editDocumentData: builder.mutation({
      query: (body) => {
        return {
          url: `${Urls.editDocument}${body?.id}`,
          method: Methods.put,
          body: body?.body,
        };
      },
    }),
    viewDocumentData: builder.mutation({
      query: (body) => {
        return {
          url: `${Urls.viewDocument}${body}`,
          method: Methods.get,
        };
      },
    }),
    accessLogsData: builder.mutation({
      query: (body) => {
        return {
          url: Urls.accessLogDocument,
          method: Methods.post,
          body: body,
        };
      },
    }),
  }),
});
export const {
  useAddDocumentDataMutation,
  useEditDocumentDataMutation,
  useViewDocumentDataMutation,
  useAccessLogsDataMutation,
} = documentRepositorycollection;

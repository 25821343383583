import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import React from "react";
import ListShimmer from "./ListShimmer";

const AccordianShimmer = () => {
  return (
    <Stack style={{ padding: 15 }} spacing={1}>
      <Stack style={{ marginTop: 15, marginBottom: 15 }}>
        {[...Array(5)].map((item, index) =>
          index === 0 ? (
            <Skeleton
              variant="rectangular"
              width={"100%"}
              height={400}
              style={{ marginBottom: 10, marginTop: 10 }}>
              <ListShimmer />
            </Skeleton>
          ) : (
            <Skeleton
              variant="rectangular"
              width={"100%"}
              height={50}
              style={{ marginBottom: 10, marginTop: 10 }}
            />
          )
        )}
      </Stack>
    </Stack>
  );
};
export default AccordianShimmer;

import { Methods, Urls } from "../../common/Utils/ApiConstants";
import { CreateApi } from "../CreateApi";

const MessageCollection = CreateApi.injectEndpoints({
  endpoints: (builder) => ({
    chatList: builder.mutation({
      query: (body) => {
        return {
          url: Urls.chatList,
          method: Methods.post,
          body: body,
        };
      },
    }),
    messageList: builder.mutation({
      query: (body) => {
        return {
          url: Urls.chatDetailMessages,
          method: Methods.post,
          body: body,
        };
      },
    }),
    getChatInvitee: builder.mutation({
      query: (body) => {
        return {
          url: Urls.chatInvitee,
          method: Methods.post,
          body: body,
        };
      },
    }),
    addChat: builder.mutation({
      query: (body) => {
        return {
          url: Urls.addChat,
          method: Methods.post,
          body: body,
        };
      },
    }),
    sendMessage: builder.mutation({
      query: (body) => {
        return {
          url: Urls.sendMessage,
          method: Methods.post,
          body: body,
        };
      },
    }),
    deleteMessage: builder.mutation({
      query: (data) => {
        return {
          url: `${Urls.deleteMessage}/${data.chatId}`,
          method: Methods.put,
          body: data.body,
        };
      },
    }),
    chatDetails: builder.mutation({
      query: (body) => {
        return {
          url: Urls.chatDetailInfo,
          method: Methods.post,
          body: body,
        };
      },
    }),
    groupchatList: builder.mutation({
      query: (body) => {
        return {
          url: Urls.groupChatList,
          method: Methods.post,
          body: body,
        };
      },
    }),
    getGroupInvitee: builder.mutation({
      query: (body) => {
        return {
          url: Urls.groupInvitee,
          method: Methods.post,
          body: body,
        };
      },
    }),
    addGroup: builder.mutation({
      query: (body) => {
        return {
          url: Urls.addGroup,
          method: Methods.post,
          body: body,
        };
      },
    }),
    groupMessageList: builder.mutation({
      query: (body) => {
        return {
          url: Urls.groupDetailMessages,
          method: Methods.post,
          body: body,
        };
      },
    }),
    groupDetails: builder.mutation({
      query: (body) => {
        return {
          url: Urls.groupDetailInfo,
          method: Methods.post,
          body: body,
        };
      },
    }),
    addGroupMember: builder.mutation({
      query: (data) => {
        return {
          url: `${Urls.addGroupMember}/${data.groupId}`,
          method: Methods.put,
          body: data.body,
        };
      },
    }),
    removeGroupMember: builder.mutation({
      query: (data) => {
        return {
          url: `${Urls.removeGroupMember}/${data.groupId}`,
          method: Methods.put,
          body: data.body,
        };
      },
    }),
    getGroupMemberList: builder.mutation({
      query: (body) => {
        return {
          url: Urls.getGroupMemberList,
          method: Methods.post,
          body: body,
        };
      },
    }),
    editGroupName: builder.mutation({
      query: (body) => {
        return {
          url: `${Urls.editGroupName}${body.id}`,
          method: Methods.put,
          body: body.body,
        };
      },
    }),
    uploadGroupProfileIcon: builder.mutation({
      query: (bodyObj) => ({
        url: `${Urls.uploadGroupIcon}${bodyObj.id}`,
        method: Methods.put,
        body: bodyObj?.bodyObj,
      }),
    }),
    deleteGroupProfileIcon: builder.mutation({
      query: (bodyObj) => ({
        url: `${Urls.deleteGroupIcon}${bodyObj.id}`,

        method: Methods.delete,
      }),
    }),
    addGroupFromTask: builder.mutation({
      query: (body) => {
        return {
          url: Urls.addGroupFromTask,
          method: Methods.post,
          body: body,
        };
      },
    }),
  }),
});

export const {
  useChatListMutation,
  useMessageListMutation,
  useGetChatInviteeMutation,
  useAddChatMutation,
  useSendMessageMutation,
  useDeleteMessageMutation,
  useChatDetailsMutation,
  useGroupchatListMutation,
  useGetGroupInviteeMutation,
  useAddGroupMutation,
  useGroupMessageListMutation,
  useGroupDetailsMutation,
  useAddGroupMemberMutation,
  useRemoveGroupMemberMutation,
  useGetGroupMemberListMutation,
  useEditGroupNameMutation,
  useUploadGroupProfileIconMutation,
  useDeleteGroupProfileIconMutation,
  useAddGroupFromTaskMutation
} = MessageCollection;

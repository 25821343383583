import { colors } from "../../../../common/Theme/Colors";

export const chartOptions = {
  labels: [""],
  fill: {
    colors: [colors.primary, colors.grey_001],
  },
  legend: {
    show: false,
  },
  tooltip: {
    enabled: false,
  },
  responsive: [
    {
      breakpoint: 480,
      options: {
        chart: {
          width: 200,
        },
        legend: {
          show: false,
        },
      },
    },
  ],
};

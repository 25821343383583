import i18n from "i18next";
import { initReactI18next } from "react-i18next";
// import AsyncStorage from '@react-native-async-storage/async-storage';
// import * as RNLocalize from 'react-native-localize';
import en from "./en";
import ar from "./ar";

export const AVAILABLE_LANGUAGES = {
  en,
  ar,
};

// const AVALAILABLE_LANG_CODES = Object.keys(AVAILABLE_LANGUAGES);
const languageDetector = {
  type: "languageDetector",
  // If this is set to true, your detect function receives a callback function that you should call with your language,
  //useful to retrieve your language stored in AsyncStorage for example
  async: true,
  init: (_services, _detectorOptions, _i18nextOptions) => {
    /* use services and options */
  },
  detect: (callback) => {
    const isRtl = localStorage.getItem("isRtl");
    // AsyncStorage.getItem('APP_LANG', (err: any, lng: any) => {
    //   //Handle error fetching stored data or no data found
    //   if (err || !lng) {
    //     if (err) {
    //     } else {
    //     }
    //     const bestLng = RNLocalize.findBestAvailableLanguage(
    //       AVALAILABLE_LANG_CODES,
    //     );

    //     callback(bestLng?.languageTag ?? 'en');
    //     return;
    //   }
    callback(isRtl === "true" ? "ar" : "en");
    // });
  },
  cacheUserLanguage: (lng) => {
    // AsyncStorage.setItem('APP_LANG', lng);
  },
};

i18n
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    compatibilityJSON: "v3",
    resources: AVAILABLE_LANGUAGES,
    react: {
      useSuspense: false,
    },
    interpolation: {
      escapeValue: false,
    },
    defaultNS: "common",
  });

export default i18n;

import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Stack } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import EmailDetailModal from "../Components/EmailDetailModal";
import { convertDateFormatWithMonth } from "../../../common/Utils/DateFormater";
import {
  StyledCard,
  StyledTypography,
} from "../../../common/Utils/StyledComponents";
import { useGetInboxEmailDetailsMutation } from "../../../request/InboxModule";
import { showToast } from "../../../common/Utils/ToastMessage";
import Translate from "../../../translation/utils/translateFunction";
import { Persona } from "../../Persona";
import Loader from "../../staff/Owner/LoadingComp";
import CommonImage from "../../ImageGallery/CommonImage";
import NoDataFound from "../../ManageTask/NoDataFound";

const ViewEmailDetails = ({ fixNavbar }) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  
  const [viewEmailDetails, setViewEmailDetails] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [attachmentsArray, setAttachmentsArray] = useState();
  const [attachments, setAttachments] = useState([]);

  const { taskStatusColor, isRtl } = useSelector(
    (state) => state.formanagement
  );

  const openEmailDetails = () => {
    setIsOpen((isOpen) => !isOpen);
  };

  const [
    getViewEmailDetailsApi,
    {
      data: viewInboxEmailData,
      isSuccess: isSuccessEmailDetails,
      isError: isErrorEmailDetials,
      error: errorEmailDetails,
      isLoading: isLoadingEmailDetails,
    },
  ] = useGetInboxEmailDetailsMutation();

  useEffect(() => {
    getViewEmailDetailsApi(state?.id);
  }, [getViewEmailDetailsApi, state]);

  useEffect(() => {
    if (isErrorEmailDetials && errorEmailDetails) {
      showToast({
        message: errorEmailDetails?.data?.message,
        type: "error",
      });
    }

    if (isSuccessEmailDetails && viewInboxEmailData) {
      // if (viewEmailDetails?.data?.attachment?.length) {
      //   setAttachmentBox(true);
      // } else {
      //   setAttachmentBox(false);
      // }
      const { attachment } = viewInboxEmailData?.data;
      setViewEmailDetails(viewInboxEmailData?.data);

      const attachmentArray = [];
      attachment &&
        attachment.map((attachmentItem, idx) => {
          const obj = {
            name: attachmentItem?.name,
            path: attachmentItem?.name,
            ...attachmentItem,
          };
          attachmentArray.push(obj);
        });
      setAttachmentsArray(attachmentArray);
      setAttachments(
        viewInboxEmailData?.data?.attachment.map((item) => item?.url)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewInboxEmailData, errorEmailDetails]);

  const lastWord = viewEmailDetails?.from?.name;

  const extractedEmailName = lastWord || "";

  const handleBackNavigation = () => {
    if(state?.type === "SENT") {
     
      return navigate("/inbox/sentMail");
    }

    return navigate("/inbox/inboxList")
  }

  return (
    <Grid
      item
      xs={12}
      className={`section-body ${isRtl ? "rtlMargin" : "ltrMargin"} ${
        fixNavbar ? "marginTop" : ""
      } `}
    >
      {!isLoadingEmailDetails && (
        <StyledCard variant="outlined">
          <div className="container-fluid p-4">
            <div className="tab-content">
              <div>
                <div className="card">
                  <Stack
                    flexDirection={"row"}
                    alignItems={"center"}
                    marginBottom={5}
                  >
                    <i
                      className="icon fe fe-arrow-left ml-3 icon-size backnavigation-iconColor cursor-pointer"
                      onClick={handleBackNavigation}
                      title={Translate("common:backToolTip")}
                    />
                  </Stack>
                  <div className="card-body">
                    <div className="commonDisplay">
                      <span className="viewEmailSubject">
                        {Translate("inboxPage:subject")}:
                      </span>
                      <span className="mx-1 viewEmailSubject">
                        {viewEmailDetails?.subject}
                      </span>
                    </div>
                    <div className="commonDisplay viewEmailUserName">
                      <div className="commonDisplay">
                        <div style={{ marginTop: 5 }}>
                          <Persona
                            name={
                              viewEmailDetails?.from?.name ||
                              viewEmailDetails?.from?.name
                            }
                            size={40}
                          />
                        </div>
                        <div className="commonDisplay viewEmailUserStyles">
                          <div>
                            <StyledTypography
                              fontWeight={"500"}
                              className="viewEmailFromName"
                            >
                              {isLoadingEmailDetails
                                ? "--"
                                : viewEmailDetails?.from?.name ||
                                  viewEmailDetails?.from?.name}
                            </StyledTypography>
                          </div>
                          <div>
                            <StyledTypography
                              fontWeight={"300"}
                              style={{ display: "inline-block" }}
                            >
                              {isLoadingEmailDetails ? (
                                "--"
                              ) : (
                                <>
                                  {"to "}
                                  {viewEmailDetails?.to
                                    .slice(0, 2)
                                    .map((recipient, index) => (
                                      <span key={index}>
                                        {index > 0 ? ", " : ""}
                                        {recipient.name}
                                      </span>
                                    ))}
                                  {viewEmailDetails?.to?.length > 2 && (
                                    <>
                                      {" ... "}
                                      {viewEmailDetails?.to?.length - 2}
                                    </>
                                  )}
                                </>
                              )}
                            </StyledTypography>

                            <i
                              className="fa fa-caret-down ml-2 cursor-pointer"
                              onClick={() => openEmailDetails()}
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        className="commonDisplay"
                        style={{ alignItems: "center" }}
                      >
                        {viewEmailDetails?.isActionable && (
                          <div className="taskStatusStyling taskCardContentStyling emailActionsColor viewEmailActions">
                            <span>{Translate("inboxPage:actionable")}</span>
                          </div>
                        )}
                        {viewEmailDetails?.isInformative && (
                          <div className="taskStatusStyling taskCardContentStyling emailActionsColor viewEmailActions">
                            <span>{Translate("inboxPage:informative")}</span>
                          </div>
                        )}

                        <div>
                          <StyledTypography
                            fontWeight={"300"}
                            style={{ display: "inline-block" }}
                          >
                            {convertDateFormatWithMonth(viewEmailDetails?.date)}
                          </StyledTypography>
                        </div>
                      </div>
                    </div>
                    {isOpen && (
                      <EmailDetailModal
                        taskStatus={
                          viewEmailDetails?.priorityTaskId?.taskStatus
                        }
                        taskStatusColor={taskStatusColor}
                        viewEmailDetails={viewEmailDetails}
                        extractedEmailName={extractedEmailName}
                      />
                    )}
                    <div style={{ marginTop: 30 }}>
                      <button
                        type="submit"
                        className="btn btn-primary"
                        onClick={() => {
                          navigate("/addTask", {
                            state: {
                              isEmailCreateTask: true,
                              attachmentsDetails:
                                viewInboxEmailData?.data?.attachment,
                              emailId: viewEmailDetails?._id,
                              title: viewEmailDetails?.subject,
                              description: viewEmailDetails?.body[0]
                                ?.replace(/\0/g, "")
                                .slice(0, 250),
                            },
                          });
                        }}
                      >
                        {Translate("inboxPage:createTaskButton")}
                      </button>
                      <button
                        type="submit"
                        className="btn btn-white addButton"
                        onClick={() =>
                          navigate("/relatedTaskListing", {
                            state: {
                              id: viewEmailDetails?.taskId,
                              moduleType: "Inbox",
                              inboxId: state?.id,
                              type: state?.type
                            },
                          })
                        }
                      >
                        {Translate("inboxPage:relatedTaskButton")}
                      </button>
                    </div>

                    <div className="mt-5" style={{ width: "90%" }}>
                      <StyledTypography fontWeight={"300"}>
                        {viewEmailDetails?.body[0]?.replace(/\0/g, "")}
                      </StyledTypography>
                    </div>

                    {attachmentsArray && (
                      <div className="pt-5 pb-5">
                        <span className="taskHeading font-17">
                          {Translate("manageTask:attacheMenttitle")}
                        </span>
                        {attachmentsArray?.length > 0 ? (
                          <div className="AttachmentBoxStyling">
                            <CommonImage
                              images={attachments}
                              files={attachmentsArray}
                              icon={true}
                            />
                          </div>
                        ) : (
                          <NoDataFound
                            message={Translate("manageTask:noAttachmentMsg")}
                            isCenter
                            className="NoDataStyle"
                          />
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </StyledCard>
      )}
      <Loader loading={isLoadingEmailDetails} />
    </Grid>
  );
};
export default ViewEmailDetails;

import { Formik } from "formik";
import React, { useEffect, useRef } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import * as yup from "yup";
import Translate from "../../../../translation/utils/translateFunction";

import { showToast } from "../../../../common/Utils/ToastMessage";
import { FormikTextField } from "../../../FormikForm/component/FormikTextField";
import { useEditGroupNameMutation } from "../../../../request/Message";

export const GroupNameEditSchema = () => {
  const schema = yup.object().shape({
    title: yup
      .string()
      .required(Translate("messagePage:requiredValidation"))
      .nullable(),
  });

  return schema;
};
export const initialValue = {
  title: "",
};

const GroupNameEdit = ({
  handleModal,
  showModal,
  groupId,
  onSuccessFullEdit,
  groupDetails,
  title = Translate("messagePage:editGroupName"),
  placeholder = "Title",
}) => {
  const formikRef = useRef(null);

  const [
    editGroupNameApi,
    {
      data: editGroupNameData,
      isSuccess: successfulEditGroupName,
      isError: errorInEditGroupName,
      error: errorEditGroupName,
    },
  ] = useEditGroupNameMutation();

  useEffect(() => {
    if (errorEditGroupName && errorInEditGroupName) {
      errorEditGroupName.data.error &&
        showToast({
          message: errorEditGroupName?.data?.message,
          type: "error",
        });
    }

    if (successfulEditGroupName && editGroupNameData) {
      showToast({ message: editGroupNameData.message, type: "success" });

      onSuccessFullEdit(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editGroupNameData, errorEditGroupName, errorInEditGroupName]);
  useEffect(() => {
    if (groupDetails) {
      formikRef.current.setFieldValue(
        "title",
        groupDetails?.groupInfo?.groupName
      );
    }
  }, [groupDetails]);
  const handleSubmit = async (values) => {
    const requestObject = {
      groupName: values?.title,
    };
    await editGroupNameApi({
      body: requestObject,
      id: groupDetails?.groupInfo?._id || groupId,
    });

    handleModal && handleModal(false);
  };
  const handleClose = () => {
    handleModal && handleModal(false);
  };

  return (
    <>
      <Modal
        show={showModal}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        scrollable={true}
        className="col-span-3 col-md-12">
        <Formik
          initialValues={initialValue}
          validateOnMount
          onSubmit={handleSubmit}
          innerRef={formikRef}
          validationSchema={GroupNameEditSchema}>
          {({ values, setFieldValue, submitForm, errors }) => {
            return (
              <>
                <Modal.Header closeButton>
                  <Modal.Title>{title}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                  <div
                    style={{
                      marginLeft: "7%",
                      marginRight: "7%",
                      marginTop: "4%",
                    }}>
                    <FormikTextField
                      name="title"
                      placeholder={placeholder}
                      autoComplete={"off"}
                      value={values.title}
                      onChange={(e) => {
                        setFieldValue("title", e.target.value);
                      }}
                      maxLength={50}
                    />
                  </div>
                </Modal.Body>

                <Modal.Footer>
                  <Button
                    variant="btn btn-white addButton"
                    onClick={handleClose}>
                    {Translate("common:close")}
                  </Button>
                  <Button
                    variant={"btn btn-primary saveButton"}
                    onClick={() => {
                      submitForm();
                    }}
                    type="submit">
                    {Translate("common:save")}
                  </Button>
                </Modal.Footer>
              </>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};
export default GroupNameEdit;

import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useGetStaffReportDetailsMutation } from "../../../../request/Report";
import Translate from "../../../../translation/utils/translateFunction";
import ListView from "../../../ListView";
import { showToast } from "../../../../common/Utils/ToastMessage";
import { getManagetTaskColumns } from "../../../ManageTask/column.data";
import { StyledTypography } from "../../../../common/Utils/StyledComponents";
import { colors } from "../../../../common/Theme/Colors";
import { useDebouncedCallback } from "use-debounce";
import { moduleType } from "../../../Task/Components/constants";
import { useSelector } from "react-redux";
import Searchbar from "../../../common/Searchbar";

const ViewStaffReport = () => {
  const { isRtl } = useSelector((state) => state.formanagement);
  const navigate = useNavigate();
  const { state } = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPageNo, setLastPageNo] = useState(0);
  const [staffDetails, setStaffDetails] = useState([]);

  const [
    getStaffReportDetailsApi,
    {
      data: staffReportData,
      isLoading: isStaffReportLoading,
      isError: isStaffReportError,
      error: staffReportError,
    },
  ] = useGetStaffReportDetailsMutation();

  const requestBody = useMemo(() => {
    const reqObj = {
      userId: state,
      searchText,
      pageNo: currentPage,
    };

    return state ? reqObj : null;
  }, [state, searchText, currentPage]);

  const debouncedSearch = useDebouncedCallback(() => {
    getStaffReportDetailsApi(requestBody);
  }, 500);

  useEffect(() => {
    if (requestBody) {
      if (requestBody?.searchText?.length) {
        debouncedSearch();
      } else {
        getStaffReportDetailsApi(requestBody);
      }
    }
  }, [requestBody]);

  useEffect(() => {
    if (staffReportData?.data?.nodes) {
      const { nodes, pageInfo } = staffReportData.data;
      setStaffDetails(nodes);
      setLastPageNo(pageInfo.lastPageNo);
      setIsLoading(false);
    }

    if (isStaffReportError && staffReportError) {
      staffReportError.data.error && staffReportError.data.error.length > 0
        ? staffReportError.data.error.map((errorItem) =>
            showToast({ message: errorItem?.msg, type: "error" })
          )
        : showToast({
            message:
              JSON.stringify(staffReportError?.data?.message) ||
              Translate("common:unknownServerError"),
            type: "error",
          });
      setIsLoading(false);
    }
  }, [staffReportData, isStaffReportError, staffReportError]);

  const handleSearchText = (event) => {
    setCurrentPage(1);
    setSearchText(event);
  };

  const CustomSrCellRender = (params) => {
    return (
      <StyledTypography component="span" sx={{ color: colors.primary_009 }}>
        {params?.api?.getRowIndexRelativeToVisibleRows(params?.row?._id) +
          1 +
          (currentPage - 1) * 10}
      </StyledTypography>
    );
  };

  const CustomActionCellRender = (params) => {
    return (
      <td>
        <i
          className="fa fa-eye m-2 cursor-pointer manageIconColor"
          onClick={() =>
            navigate(
              params?.row?.moduleType === moduleType.SUBTASK
                ? "/subtask/view"
                : "/taskDetails",
              {
                state: {
                  taskId: params?.row?.taskId,
                  userDetails: params?.row?.userDetails,
                  isStaffReports: true,
                  staffId: state,
                },
              }
            )
          }
          data-toggle="tooltip"
          title={Translate("common:viewToolTip")}
        ></i>
      </td>
    );
  };

  return (
    <div className={`section-body ${isRtl ? "rtlMargin" : "ltrMargin"} mt-3`}>
      <div className="container-fluid">
        <div className="tab-content">
          <div className="d-flex col-12">
            <div className="card" style={{ marginBottom: 8 }}>
              <div className="card-header">
                <i
                  className="icon fe fe-arrow-left mr-3 icon-size backnavigation-iconColor cursor-pointer"
                  onClick={() => navigate("/reports/staff")}
                  title={Translate("common:backToolTip")}
                />
                <h5 className="page-title">
                  {Translate("staffReport:viewDetailsTitle")}
                </h5>
              </div>
            </div>
          </div>
          <div className="d-flex">
            <div className="col-12">
              <div className="card">
                <div className="row">
                  <div className="col-12">
                    <div className="card-header fixedHeight">
                      <div className="card-options">
                        <div className="sort_stat commentTextField">
                          <form className="headerMargin ml-3">
                            <div className="input-group">
                              <Searchbar
                                handleSearchText={handleSearchText}
                                setSearch={setSearchText}
                              />
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <ListView
                    columns={getManagetTaskColumns(
                      CustomSrCellRender,
                      CustomActionCellRender,
                      false
                    )}
                    rows={staffDetails}
                    autoHeight
                    sortingMode="server"
                    onPaginationChange={setCurrentPage}
                    currentPage={currentPage}
                    showShimmer={isLoading || isStaffReportLoading}
                    showLoader={isLoading}
                    totalPageCount={lastPageNo}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewStaffReport;

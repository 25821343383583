import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useViewBusinessCardMutation } from "../../../../../request/ContactRepo";
import { showToast } from "../../../../../common/Utils/ToastMessage";
import Translate from "../../../../../translation/utils/translateFunction";
import ViewBusinessCardCommon from "../../../component/viewBusinessCardCommon";
import CommonImage from "../../../../ImageGallery/CommonImage";
import NoDataFound from "../../../../ManageTask/NoDataFound";
import Loader from "../../../../staff/Owner/LoadingComp";
import { useSelector } from "react-redux";
import PageHeader from "../../../../common/pageHeader";

const ViewBusinessCardSelectMember = () => {
  const { isFixNavbar, isRtl } = useSelector((state) => state.formanagement);
  const navigate = useNavigate();
  const { state } = useLocation();
  const [viewBusinessCard, setViewBusinessCard] = useState([]);
  const [images, setImages] = useState([]);
  const [attachmentArray, setAttachmentArray] = useState([]);

  const [
    viewBusinessCardApi,
    {
      data: businessCardData,
      isSuccess: isViewSuccess,
      isLoading: isViewLoading,
      isError: isViewError,
      error: errorViewBusinessCard,
    },
  ] = useViewBusinessCardMutation();

  useEffect(() => {
    if (state) {
      viewBusinessCardApi(state.userId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewBusinessCardApi, state]);

  useEffect(() => {
    if (isViewError && errorViewBusinessCard) {
      showToast({
        message: errorViewBusinessCard?.data?.message,
        type: "error",
      });
    }

    if (isViewSuccess && businessCardData) {
      const { data } = businessCardData;

      setViewBusinessCard(data);

      if (data?.businessCardImage) {
        const obj = {
          path: decodeURIComponent(data?.businessCardImage).split("/").pop(),
          type: "image/png",
          url: data?.businessCardImage,
        };

        setAttachmentArray([obj]);
        setImages([data?.businessCardImage]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessCardData, errorViewBusinessCard]);

  return (
    <div
      className={`section-body ${isRtl ? "rtlMargin" : "ltrMargin"} ${
        isFixNavbar ? "marginTop" : ""
      } mt-3`}>
      <div className="card" style={{ marginBottom: 10 }}>
        <PageHeader
          title={
            <h5 className="page-title">{Translate("businessCard:head")}</h5>
          }
          onClick={() => {
            state?.isShare
              ? navigate("/contactRepository/sharedListing/roles", {
                  state: {
                    contactId: state.contactId,
                    currentMember: state.currentMember,
                    isShare: state?.isShare,
                  },
                })
              : navigate("/contactRepository/privateContacts", {
                  state: {
                    contactId: state.contactId,
                    currentMember: state.currentMember,
                  },
                });
          }}
          containerClass="card-header p-3"
        />
      </div>
      <div style={{ display: "flex" }}>
        <div className="col-4">
          <ViewBusinessCardCommon
            viewBusinessCard={viewBusinessCard}
            isViewLoading={isViewLoading}
          />
        </div>
        {isViewLoading === false && (
          <div className="col-8" style={{ width: "100%" }}>
            <div className=" card CommentCard_spacing">
              <div className="commentLabel cursor-pointer">
                <span className="taskHeading font-17">
                  {Translate("businessCard:attachmentTitle")}
                </span>
                <div>
                  <i
                    className={
                      viewBusinessCard?.businessCardImage
                        ? "fa fa-caret-down "
                        : "fa fa-caret-up"
                    }
                  />
                </div>
              </div>

              {viewBusinessCard?.businessCardImage ? (
                <div className="AttachmentBoxStyling">
                  <CommonImage
                    images={images}
                    files={attachmentArray}
                    icon={true}
                  />
                </div>
              ) : (
                <NoDataFound
                  message={Translate("businessCard:attachmentErrMsg")}
                  isCenter
                  className="NoDataStyle"
                />
              )}
            </div>
          </div>
        )}
      </div>
      <Loader loading={isViewLoading} />
    </div>
  );
};
export default ViewBusinessCardSelectMember;

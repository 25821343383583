export default {
  head: "تكليف المهمة",
  title: "العنوان",
  add: "إضافة",
  taskName: "اسم المهمة",
  description: "الوصف",
  descriptionPlaceholder: "محتوى الإيميل",
  assignTo: "اسناد لِ",
  startDate: "تاريخ االبدء",
  dueDate: "تاريخ التسليم",
  attachFile: "أرفق ملف",
  assignDropdownPlaceholder: "اسم او ايميل المستخدم",
  companyDropdownPlaceholder: "اختر الشركة",
  dropdownPlaceholder: "اختر",
  companyError: "الشركة حقل مطلوب",
  titleError: "العنوان حقل مطلوب",
  nameError: "الإسم حقل مطلوب",
  addError: "إضافة المهمة\/المهمة الفرعية حقل مطلوب",
  descriptionError: "الوصف حقل مطلوب",
  assignToError: "اسناد المهمة لِ حقل مطلوب",
  startDateError: "تاريخ البدء حقل مطلوب",
  dueDateError: "تاريخ الإستحقاق حقل مطلوب",
  priorityError: "الأولوية حقل مطلوب",
  reportToError: "المدير المباشر حقل مطلوب",
};

import React, { memo } from "react";
import MessageItem from "../../Components/MessageItem";
import Translate from "../../../../translation/utils/translateFunction";
import MemberItem from "../MemberItem";
import NoDataFound from "../../../ManageTask/NoDataFound";

const GroupList = ({
  chatList,
  onClick,
  selectedUser,
  isNewGroup,
  onAddClick,
  ...props
}) => {
  return chatList?.length ? (
    <div
      className="tab-pane fade show active"
      id="users-list"
      role="tabpanel"
      aria-labelledby="users-tab">
      <ul className="right_chat list-unstyled list">
        {chatList?.map((item) =>
          isNewGroup ? (
            <MemberItem
              item={item}
              onClick={onClick}
              selectedUser={selectedUser}
              {...props}
            />
          ) : (
            <MessageItem
              item={item}
              onClick={onClick}
              selectedUser={selectedUser}
              {...props}
            />
          )
        )}
      </ul>
    </div>
  ) : (
    <NoDataFound isCenter message={Translate("messagePage:noChatFound")} />
  );
};
export default memo(GroupList);

export default {
  changePassword: "تغيير كلمة المرور",
  oldPass: "كلمة المرور القديمة",
  newPass: "كلمة المرور الجديدة",
  confirmPass: "تأكيد كلمة المرور",
  oldPassReq: "كلمة المرور القديمة مطلوبة",
  newPassReq: "كلمة المرور الجديدة مطلوبة",
  confirmPassReq: "تأكيد كلمة المرور مطلوب",
  passNotMatch: "كلمة السر غير متطابقة",
  passValidationError:
    "يجب أن يحتوي على 8 أحرف على الأقل، 1 حرف كبير، 1 حرف صغير، 1 حرف خاص، و1 رقم",
  save: "حفظ",
  cancel: "الغاء",
};

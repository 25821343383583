import { Methods, Urls } from "../../common/Utils/ApiConstants";
import { CreateApi } from "../CreateApi";

const ProjectCollection = CreateApi.injectEndpoints({
  endpoints: (builder) => ({
    addProject: builder.mutation({
      query: (body) => {
        return {
          url: Urls.addProject,
          method: Methods.post,
          body,
        };
      },
    }),
    getProjectReportList: builder.mutation({
      query: (body) => {
        return {
          url: Urls.getProjectReportList,
          method: Methods.post,
          body: body,
        };
      },
    }),
    getProjectDropdownList: builder.mutation({
      query: (body) => {
        return {
          url: Urls.getProjectDropdownList,
          method: Methods.post,
          body: body,
        };
      },
    }),
  }),
});

export const {
  useAddProjectMutation,
  useGetProjectReportListMutation,
  useGetProjectDropdownListMutation,
} = ProjectCollection;

import React from "react";
import { colors } from "../../../common/Theme/Colors";
import _ from "lodash";
import { JoinPaths } from "./utils";

export const NodeCurves = (links) => {
  const nodePath = _.map(links, function (d, i) {
    return (
      <path
        d={JoinPaths(d)}
        key={i}
        fill="none"
        strokeWidth={3}
        stroke={colors.primary_003}
      />
    );
  });

  return nodePath;
};

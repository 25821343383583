import { Grid } from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import { StyledCard } from "../../../../common/Utils/StyledComponents";
import { showToast } from "../../../../common/Utils/ToastMessage";
import {
  useGetSelectMemberPrivateContactsMutation,
  useGetSelectMemberShareListingMutation,
} from "../../../../request/ContactRepo";
import Translate from "../../../../translation/utils/translateFunction";
import ListView from "../../../ListView";
import { Persona } from "../../../Persona";
import { VALIDATION_BUCKETURL } from "../../../Persona/constants";
import {
  getContactRepoSelectMemeberPrivateContacts,
  getContactRepoShareMembers,
} from "./column.data";
import Searchbar from "../../../common/Searchbar";
import { useSelector } from "react-redux";
import PageHeader from "../../../common/pageHeader";

const PrivateContactsSelectedMember = ({ fixNavbar }) => {
  const { isRtl } = useSelector((state) => state?.formanagement);
  const [loading, setLoading] = useState(true);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [search, setSearch] = useState("");
  const [selectMember, setSelectMember] = useState();

  const lastPage = useRef(0);

  const navigate = useNavigate();
  const { state } = useLocation();

  const [
    getSelectMemberPrivateContactListApi,
    {
      data: selectMemberContactsData,
      isSuccess: successSelectMemberContacts,
      isLoading: isLoadingSelectMemberContactsData,
      isError: isErrorSelectMemberContacts,
      error: errorSelectMemberContacts,
    },
  ] = useGetSelectMemberPrivateContactsMutation();
  const [
    getSelectMemberShareListApi,
    {
      data: serverResponse,
      isSuccess: isSuccessShareMemeber,
      isLoading: isLoadingShareMember,
      isError: isErrorShareMember,
      error: errorShareMember,
    },
  ] = useGetSelectMemberShareListingMutation();

  // useGetSelectPaSharedContactMutation
  useEffect(() => {
    if (selectMemberContactsData && successSelectMemberContacts) {
      const { nodes, pageInfo } = selectMemberContactsData.data;
      setSelectMember(nodes);
      lastPage.current = pageInfo.lastPageNo;
      setLoading(false);
    }
    if (isErrorSelectMemberContacts && errorSelectMemberContacts) {
      showToast({ message: "Failed to fetch the data", type: "error" });
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    successSelectMemberContacts,
    isErrorSelectMemberContacts,
    selectMemberContactsData,
    errorSelectMemberContacts,
  ]);
  useEffect(() => {
    if (serverResponse && isSuccessShareMemeber) {
      const { nodes, pageInfo } = serverResponse.data;
      setSelectMember(nodes);
      lastPage.current = pageInfo.lastPageNo;
      setLoading(false);
    }
    if (isErrorShareMember && errorShareMember) {
      showToast({ message: "Failed to fetch the data", type: "error" });
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessShareMemeber, isErrorSelectMemberContacts]);
  let requestObject = useMemo(() => {
    if (state) {
      let requestObj = {
        employeeId: state.contactId,
        // employeeId: "63aa9107c81c3efda484380b",
        pageNo: currentPageNo,
        searchValue: search,
      };

      return requestObj;
    }
  }, [search, currentPageNo, state]);

  const debouncedSearch = useDebouncedCallback(() => {
    if (state) {
      state?.isShare
        ? getSelectMemberShareListApi(requestObject)
        : getSelectMemberPrivateContactListApi(requestObject);
    }
  }, 500);

  useEffect(() => {
    setLoading(true);
    if (requestObject?.searchValue?.length) {
      debouncedSearch();
    } else {
      state?.isShare
        ? getSelectMemberShareListApi(requestObject)
        : getSelectMemberPrivateContactListApi(requestObject);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state,
    currentPageNo,
    search,
    requestObject,
    getSelectMemberPrivateContactListApi,
  ]);

  const CustomCellActionRender = (params) => {
    return (
      <td>
        <i
          className={`m-2 fa fa-eye cursor-pointer manageIconColor 
          
          `}
          onClick={() =>
            navigate(
              state?.isShare
                ? "/contactRepository/viewSelectShareMemberBusinessCard"
                : "/contactRepository/viewSelectMemberBusinessCard",
              {
                state: {
                  userId: params?.id,
                  contactId: state.contactId,
                  currentMember: state.currentMember,
                  isShare: state?.isShare,
                },
              }
            )
          }
          data-toggle="tooltip"
          title={Translate("contacts:view")}
        ></i>
      </td>
    );
  };
  const handleSearchText = (value) => {
    setCurrentPageNo(1);
    setSearch(value);
  };

  const CustomCellRender = (params) => {
    return (
      <td>
        <div style={{ display: "flex" }}>
          <Persona
            name={params?.row?.contactName}
            image={
              params?.row?.contactProfile?.includes(VALIDATION_BUCKETURL)
                ? params?.row?.contactProfile
                : ""
            }
            size={28}
          />
          <span className="text-info ml-2 mt-1">
            {params?.row?.contactName}
          </span>
        </div>
      </td>
    );
  };

  const rowsWithSerialNumbers =
    selectMember &&
    selectMember.map((row, index) => ({
      ...row,
      serialNumber: index + 1,
    }));

  return (
    <Grid
      item
      xs={12}
      className={`section-body ${isRtl ? "rtlMargin" : "ltrMargin"} ${
        fixNavbar ? "marginTop" : ""
      } `}
    >
      <StyledCard variant="outlined">
        <div className="container-fluid p-4">
          <div className="tab-content">
            <div>
              <div className="card">
                <div className="card-header card-header-spacing">
                  <PageHeader
                    title={
                      <h5 className="page-title">
                        {state?.isShare
                          ? Translate("contacts:sharedContacts")
                          : Translate("contacts:privateContacts")}
                      </h5>
                    }
                    onClick={() =>
                      state?.isShare
                        ? navigate(
                            "/contactRepository/sharedListing/selectMember",
                            {
                              state: state?.currentMember,
                            }
                          )
                        : navigate(
                            "/contactRepository/privateListing/selectMember",
                            {
                              state: state?.currentMember,
                            }
                          )
                    }
                    containerClass="card-header p-3"
                  />

                  <div className="card-options">
                    <div className="input-group">
                      <Searchbar
                        handleSearchText={handleSearchText}
                        setSearch={setSearch}
                      />
                    </div>
                  </div>
                </div>
                <ListView
                  columns={
                    state?.isShare
                      ? getContactRepoShareMembers(
                          CustomCellRender,
                          CustomCellActionRender
                        )
                      : getContactRepoSelectMemeberPrivateContacts(
                          CustomCellRender,
                          CustomCellActionRender
                        )
                  }
                  rows={rowsWithSerialNumbers}
                  autoHeight
                  onPaginationChange={setCurrentPageNo}
                  currentPage={currentPageNo}
                  showShimmer={isLoadingSelectMemberContactsData}
                  showLoader={loading || isLoadingSelectMemberContactsData}
                  totalPageCount={lastPage.current}
                />
              </div>
            </div>
          </div>
        </div>
      </StyledCard>
    </Grid>
  );
};

export default PrivateContactsSelectedMember;

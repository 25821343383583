import React, { useState } from "react";
import { usePlacesWidget } from "react-google-autocomplete";
import { colors } from "../../../common/Theme/Colors";
import { StyledTypography } from "../../../common/Utils/StyledComponents";
import { styles } from "./index.Style";

const ICON_WIDTH_SPACE = 40;

export const TextField = ({
  label,
  icon,
  isError,
  autoComplete,

  RenderRightContainer,
  style,
  multiline,
  keyboardType,
  showBorder,
  removeIcon,
  containerStyles,
  editable = true,
  enableBackground = false,
  innerRef,
  className,
  disabled,
  touched,
  error,
  noOfRows,
  maxLength,
  onShow,
  type,
  value,
  showPassword,
  isLocation = false,
  nonEditable,
  locationValue,

  ...props
}) => {
  const [layoutData, setLayoutData] = useState(50);
  const [inFocus, setInFocus] = useState(false);

  const borderStyle = {
    borderWidth: 1,
    borderColor: removeIcon
      ? colors.white
      : inFocus
      ? colors.grey_003
      : showBorder
      ? colors.grey_008
      : isError
      ? colors.red
      : !editable && !enableBackground
      ? colors.grey_012
      : colors.white,
    borderRadius: 3,
  };

  const onFocus = () => setInFocus(true);

  const onBlur = () => setInFocus(false);

  const TextFieldWidth = layoutData?.width;
  const TextInputStyles = {
    width: RenderRightContainer ? TextFieldWidth : layoutData?.width,
  };
  const iconContainerStyles = {
    width: ICON_WIDTH_SPACE,
    height: 50,
  };
  const multilineStyle = {
    textAlignVertical: "top",
  };

  const textInputContainer = {
    backgroundColor:
      !editable && !enableBackground ? colors.grey_012 : colors.white,
  };

  const textInputText = {
    color: editable || enableBackground ? colors.black : colors.primary_003,
  };

  const { ref } = usePlacesWidget({
    apiKey: "AIzaSyCAuvo_l-egs78Y2DEvuH0_LwEiepXEUnk",

    onPlaceSelected: (places, inputRef, autoCompleteRef) => {
      locationValue(places);
    },
    options: { types: ["geocode", "establishment"] },
  });

  return (
    <div>
      {label !== undefined && label?.length > 0 && (
        <StyledTypography component="span">{label}</StyledTypography>
      )}

      <div
        style={{
          position: "relative",
        }}>
        {icon && (
          <div className="iconStyling">
            <i className={icon} size={18} color={colors.primary_003} />
          </div>
        )}

        {multiline ? (
          <textarea
            className={className}
            ref={innerRef}
            value={value}
            onFocus={onFocus}
            onBlur={onBlur}
            rows={noOfRows}
            {...props}
            disabled={!editable}
            autoComplete={autoComplete}
            style={
              (TextInputStyles,
              styles.textInput,
              multiline && multilineStyle,
              textInputText,
              style)
            }
            maxLength={maxLength}
          />
        ) : (
          <input
            className={className}
            ref={isLocation ? ref : innerRef}
            value={value}
            icon={icon}
            onFocus={onFocus}
            onBlur={onBlur}
            {...props}
            multiple={multiline}
            maxLength={maxLength}
            disabled={disabled ? editable : !editable}
            autoComplete={autoComplete}
            type={type}
            style={
              (TextInputStyles,
              styles.textInput,
              multiline && multilineStyle,
              textInputText,
              style)
            }
          />
        )}

        {RenderRightContainer && (
          <div
            className={`iconStyling ${
              touched && error ? "rightIconStyleError" : "rightIconStyle"
            }`}
            onClick={() => onShow?.(!showPassword)}>
            {RenderRightContainer}
          </div>
        )}

        {touched && error && <div className="invalid-feedback">{error}</div>}
      </div>
    </div>
  );
};

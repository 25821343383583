export default {
  head: "تقرير المهمة",
  assigned: "المُكلّف",
  complete: "مكتمل",
  inProgress: "قيد التنفيذ",
  resolved: "تم حلها",
  reOpened: "أعيد فتحها",
  overdue: "مُتأخر",
  completedBefore: "مكتمل (قبل)",
  completedAfter: "مكتمل (بعد)",
  priorityPlaceHolder: "حدد الأولوية",
  sortByPlaceHolder: "حدد فرز حسب",
  staffPlaceHolder: "حدد الموظفين",
  sortByLabel: "ترتيب",
  staffLabel: "طاقم العمل",
  priorityLabel: "الأولوية",
  graphDataNotFound: "لا توجد بيانات متاحة للمخطط.",
  exportTasKFileName: "FOM_TASK_REPORT",
  donutDataNotFound: "لا توجد بيانات متاحة للرسم البياني.",
  exportAttendanceFileName: "FOM_ATTENDANCE_REPORT",
  exportPerformanceFileName: "FOM_PERFORMANCE_REPORT",
  exportWorkdloadFileName: "FOM_WORKLOAD_REPORT",
  recentTask: "المهمة الأخيرة",
  oldTask: "المهمة القديمة",
};

import { getDayJs, getMoment } from "../../../../common/Utils/commonFunction";

export const InitialValues = {
  company: "",
  projectId: "",
  parentTitle: "",
  title: "",
  description: "",
  assignTo: "",
  reportTo: "",
  startDate: getMoment().toDate(),
  dueDate: getMoment().add(1, "days").toDate(),
  startTime: getDayJs(),
  dueTime: getDayJs(),
  priority: "Low",
  markAsCritical: false,
};

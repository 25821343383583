import React, { useState, useEffect } from "react";
import Loader from "../staff/Owner/LoadingComp";
import { showToast } from "../../common/Utils/ToastMessage";
import { useGetTermsAndConditionDetailsMutation } from "../../request/Cms";
import NoDataFound from "../ManageTask/NoDataFound";

const TermsCondition = () => {
  const [mainContent, setMainContent] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const [
    getTermsAndConditionDetailsApi,
    {
      data: termsAndConditionData,
      isSuccess: isTermsSuccess,
      isLoading: isTermsLoading,
      isError: isTermsError,
      error: termsError,
    },
  ] = useGetTermsAndConditionDetailsMutation();

  useEffect(() => {
    getTermsAndConditionDetailsApi({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isTermsError && termsError) {
      showToast({
        message: termsError?.data?.message,
        type: "error",
      });
      setIsLoading(false);
    }

    if (termsAndConditionData && isTermsSuccess) {
      if (!termsAndConditionData?.success) {
        showToast({
          message: termsAndConditionData?.message,
          type: "error",
        });
      } else {
        setMainContent(termsAndConditionData?.data);
      }

      setIsLoading(false);
    }
  }, [isTermsSuccess, termsAndConditionData, isTermsError, termsError]);

  return (
    <div className="row container_spacing">
        <div className="cms-container card">
          {!isTermsLoading && !isLoading ? (
            termsAndConditionData?.data ? (
              <div
              className="col-lg-12 col-md-12 col-sm-12 col-xs-12 card_spacing p-20"
                dangerouslySetInnerHTML={{ __html: mainContent }}
              />
            ) : (
              <NoDataFound isCenter />
            )
          ) : null}
          <Loader loading={isTermsLoading || isLoading} />
        
      </div>
    </div>
  );
};

export default TermsCondition;

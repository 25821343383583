import { Methods, Urls } from "../../common/Utils/ApiConstants";
import { CreateApi } from "../CreateApi";

const ActivityLogsCollection = CreateApi.injectEndpoints({
  endpoints: (builder) => ({
    getOwnerLogs: builder.mutation({
      query: (body) => {
        return {
          url: Urls.getOwnerActivityLogs,
          method: Methods.post,
          body: body,
        };
      },
    }),
    getManagerLogs: builder.mutation({
      query: (body) => {
        return {
          url: Urls.getManagerActivityLogs,
          method: Methods.post,
          body: body,
        };
      },
    }),
    getEmployeeLogs: builder.mutation({
      query: (body) => {
        return {
          url: Urls.getEmployeeActivityLogs,
          method: Methods.post,
          body: body,
        };
      },
    }),
    getGmLogs: builder.mutation({
      query: (body) => {
        return {
          url: Urls.getGmActivityLogs,
          method: Methods.post,
          body: body,
        };
      },
    }),
    getPaLogs: builder.mutation({
      query: (body) => {
        return {
          url: Urls.getPaActivityLogs,
          method: Methods.post,
          body: body,
        };
      },
    }),
    getVendorLogs: builder.mutation({
      query: (body) => {
        return {
          url: Urls.getVendorActivityLogs,
          method: Methods.post,
          body: body,
        };
      },
    }),
    getOwnerActivityLogsExports: builder.mutation({
      query: (body) => {
        return {
          url: Urls.getOwnerActivityLogsExport,
          method: Methods.post,
          body: body,
          responseHandler: (response) => response.text(),
        };
      },
    }),
    getOwnerPALogsExports: builder.mutation({
      query: (body) => {
        return {
          url: Urls.getPAActivityLogsExport,
          method: Methods.post,
          body: body,
          responseHandler: (response) => response.text(),
        };
      },
    }),
    getGMLogsExports: builder.mutation({
      query: (body) => {
        return {
          url: Urls.getGMActivityLogsExport,
          method: Methods.post,
          body: body,
          responseHandler: (response) => response.text(),
        };
      },
    }),
    getManagerLogsExports: builder.mutation({
      query: (body) => {
        return {
          url: Urls.getManagerActivityLogsExport,
          method: Methods.post,
          body: body,
          responseHandler: (response) => response.text(),
        };
      },
    }),
    getEmployeeLogsExports: builder.mutation({
      query: (body) => {
        return {
          url: Urls.getEmployeeActivityLogsExport,
          method: Methods.post,
          body: body,
          responseHandler: (response) => response.text(),
        };
      },
    }),
    getVendorLogsExports: builder.mutation({
      query: (body) => {
        return {
          url: Urls.getVendorActivityLogsExport,
          method: Methods.post,
          body: body,
          responseHandler: (response) => response.text(),
        };
      },
    }),
  }),
});

export const {
  useGetOwnerLogsMutation,
  useGetManagerLogsMutation,
  useGetEmployeeLogsMutation,
  useGetGmLogsMutation,
  useGetPaLogsMutation,
  useGetVendorLogsMutation,
  useGetOwnerActivityLogsExportsMutation,
  useGetOwnerPALogsExportsMutation,
  useGetGMLogsExportsMutation,
  useGetManagerLogsExportsMutation,
  useGetEmployeeLogsExportsMutation,
  useGetVendorLogsExportsMutation,
} = ActivityLogsCollection;

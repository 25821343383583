import * as yup from "yup";
import Translate from "../../translation/utils/translateFunction";

export const PersonalDetailsSchema = () => {
  const schema = yup.object().shape({
    name: yup.string().required(Translate("accountPage:nameError")),
    workEmail: yup
      .string()
      .email()
      .required(Translate("addEmployee:workError")),
    contactNumber: yup.string().required(Translate("addEmployee:contactError")),
    dob: yup.string().required(Translate("addEmployee:dobError")),
  });
  return schema;
};
export const PersonalDetailsVendorSchema = () => {
  const schema = yup.object().shape({
    name: yup.string().required(Translate("accountPage:nameError")),
    workEmail: yup
      .string()
      .email()
      .required(Translate("addEmployee:workError")),
    contactNumber: yup.string().required(Translate("addEmployee:contactError")),
  });
  return schema;
};

import {
  jordanCountryCode,
  jordanCountryShortCode,
} from "../../../common/Utils/ApiConstants";

export const InitialValues = {
  companyId: "",
  companyName: "",
  name: "",
  designation: "",
  department: "",
  reportTo: "",
  countryCode: jordanCountryCode,
  mobile: "",
  mobileShortCode: jordanCountryShortCode,
  alternateMobile: "",
  alternateMobileShortCode: jordanCountryShortCode,
  alternateMobileCountryCode: jordanCountryCode,
  companyNumber: "",
  companyExtension: "",
  email: "",
  workAddress: "",
  flat: "",
  latitude: "",
  longitude: "",
  country: "",
  state: "",
  city: "",
  zipCode: "",
  gender: "MALE",
  Male: false,
  Female: false,
};

export const radioOptions = ["Male", "Female"];

export const centerContent = {
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  alignContent: "center",
  fontWeight: "500",
  flex: 1,
};

import { Stack, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { colors } from "../../../common/Theme/Colors";
import { DateTimeFormats } from "../../../common/Utils/DateFormater";
import { StyledTypography } from "../../../common/Utils/StyledComponents";
import { showToast } from "../../../common/Utils/ToastMessage";
import Translate from "../../../translation/utils/translateFunction";
import IconifyIcon from "../../Icon";
import DropDownField from "../../TextField/DropdownField";
import Datepicker from "../../common/datepicker";
import { allRepeatData, daysArray } from "../CreateEvent/utils";
import { getMoment } from "../../../common/Utils/commonFunction";

const CustomModal = ({
  show,
  onCancel,
  data,
  setEventRecurrence,
  eventRecurrence,
}) => {
  const [repeatEvery, setRepeatEvery] = useState("");
  const [repeatOption, setRepeatOption] = useState("WEEK");
  const [endsOption, setEndsOption] = useState("Never");
  const [occurrence, setOccurrence] = useState();
  const [pickedDate, setPickedDate] = useState();
  const [pickedDateObject, setPickedDateObject] = useState();
  const [fromDate, setFromDate] = useState();
  const [fromDateObject, setFromDateObject] = useState();
  const [repeatEveryError, setRepeatEveryError] = useState(false);
  const [repeatsOnDateError, setRepeatsOnDateError] = useState(false);
  const [endsOnDateError, setEndsOnDateError] = useState(false);
  const [afterRecurrenceError, setAfterRecurrenceError] = useState(false);
  const [selectedDay, setSelectedDay] = useState("Sun");
  useEffect(() => {
    if (eventRecurrence) {
      setRepeatEvery(eventRecurrence.repeatEveryNumber.toString());
      setRepeatOption(eventRecurrence.repeatEvery);
      setSelectedDay(eventRecurrence.selectedDay);
      setEndsOption(eventRecurrence.repeatEndSelected);
      setPickedDateObject(eventRecurrence?.repeatEnd?.on);
      setOccurrence(eventRecurrence?.repeatEnd?.occurance);
      setPickedDate(eventRecurrence.pickedDate);
      setFromDate(eventRecurrence.repeatFrom);
      setFromDateObject(eventRecurrence.repeatFromUTCObject);
    }
  }, [eventRecurrence]);
  const RenderDays = ({ item, index }) => {
    return (
      <div
        onClick={() => setSelectedDay(item.value)}
        className="cursor-pointer">
        <Stack
          style={styles.days}
          sx={
            selectedDay === item.value
              ? { backgroundColor: colors.primary }
              : { backgroundColor: colors.white }
          }
          key={index?.toString()}>
          <StyledTypography
            variant="subtitle1"
            style={
              selectedDay === item.value
                ? { color: colors.white }
                : { color: colors.black }
            }>
            {item.label}
          </StyledTypography>
        </Stack>
      </div>
    );
  };
  const onSubmit = () => {
    let dataObj = {
      repeatEveryNumber: repeatOption
        ? repeatEvery.length > 0
          ? // eslint-disable-next-line radix
            parseInt(repeatEvery)
          : ""
        : "",
      repeatEvery: repeatOption,
      repeatWeek: {
        sunday: selectedDay === "Sun" && repeatOption === "WEEK" ? true : false,
        monday: selectedDay === "Mon" && repeatOption === "WEEK" ? true : false,
        tuesday:
          selectedDay === "Tue" && repeatOption === "WEEK" ? true : false,
        wednesday:
          selectedDay === "Wed" && repeatOption === "WEEK" ? true : false,
        thursday:
          selectedDay === "Thr" && repeatOption === "WEEK" ? true : false,
        friday: selectedDay === "Fri" && repeatOption === "WEEK" ? true : false,
        saturday:
          selectedDay === "Sat" && repeatOption === "WEEK" ? true : false,
      },
      selectedDay: repeatOption === "WEEK" ? selectedDay : "" || "",
      repeatFrom: repeatOption !== "WEEK" ? fromDate : "" || "",
      repeatFromUTCObject: fromDateObject || "",
      repeatEnd: {
        never: endsOption === "Never" ? true : false,
        on: endsOption === "On" ? pickedDateObject || "" : "",
        occurance: endsOption === "After" ? occurrence || "" : "",
      },
      repeatEndSelected: endsOption || "",
      pickedDate: pickedDate || "",
    };
    console.log("break obj:", dataObj);
    setEventRecurrence(dataObj);
  };
  return (
    <Modal
      show={show}
      onHide={onCancel}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      scrollable={true}
      className="col-span-3 col-md-12">
      <Modal.Header closeButton>
        <Modal.Title>{Translate("createEvent:customRecurrence")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Stack>
          <Stack mb={2}>
            <StyledTypography variant="subtitle1" sx={styles.label}>
              {Translate("createEvent:repeatsEvery")}
            </StyledTypography>
            <Stack flexDirection={"row"} alignItems={"center"}>
              <Stack mr={1}>
                <TextField
                  placeholder={"1"}
                  onChange={(text) => {
                    setRepeatEvery(text.target.value?.replace(/[^1-9]/g, ""));
                    if (text.target.value?.replace(/[^1-9]/g, "").length > 0) {
                      setRepeatEveryError(false);
                    }
                  }}
                  value={repeatEvery}
                  type="tel"
                  inputProps={{
                    maxLength: 1,
                    style: { width: "15px", height: "5px" },
                  }}
                  error={repeatEveryError}
                />
              </Stack>
              <DropDownField
                value={repeatOption}
                options={allRepeatData}
                className={"form-control textFieldHeight cursor-pointer"}
                onChange={(item) => setRepeatOption(item.target.value)}
                placeholder={Translate("accountPage:dropdownPlaceholder")}
              />
            </Stack>
          </Stack>
          <Stack mb={2}>
            <StyledTypography variant="subtitle1" sx={styles.label}>
              {Translate("createEvent:repeatsOn")}
            </StyledTypography>
            {repeatOption === "WEEK" ? (
              <Stack flexDirection={"row"} alignItems={"center"}>
                {daysArray.map((item, index) => (
                  <RenderDays item={item} index={index} />
                ))}
              </Stack>
            ) : (
              <Stack
                className="form-group mb-0 ml-1"
                flexDirection={"row"}
                alignItems={"center"}>
                <Datepicker
                  placeholder={DateTimeFormats.ShortMonthDateYear}
                  format={DateTimeFormats.MonthDateYear}
                  value={fromDate || ""}
                  className="form-control p-0"
                  minDate={
                    data?.fromDate
                      ? new Date(data?.fromDate)
                      : getMoment().toDate()
                  }
                  maxDate={
                    pickedDateObject?.length > 0
                      ? new Date(pickedDateObject)
                      : data?.toDate
                      ? new Date(data?.toDate)
                      : undefined
                  }
                  onChange={(value) => {
                    if (data?.fromDate) {
                      setFromDateObject(value.toISOString());
                      setFromDate(value);
                    } else {
                      showToast({
                        message: "Please select date from create event",
                        type: "info",
                      });
                    }
                    setRepeatsOnDateError(false);
                  }}
                  error={repeatsOnDateError}
                />
                {repeatsOnDateError && (
                  <StyledTypography
                    variant="subtitle2"
                    sx={{ color: colors.red_002 }}>
                    {Translate("common:required")}
                  </StyledTypography>
                )}
              </Stack>
            )}
          </Stack>
          <Stack mb={2}>
            <StyledTypography variant="subtitle1" sx={styles.label}>
              {Translate("createEvent:ends")}
            </StyledTypography>
            <Stack>
              <Stack mb={1}>
                <div
                  onClick={() => {
                    setEndsOption("Never");
                    setAfterRecurrenceError(false);
                    setEndsOnDateError(false);
                    setPickedDate();
                    setPickedDateObject();
                    setOccurrence("");
                  }}>
                  <Stack style={styles.item}>
                    <Stack flexDirection={"row"} alignItems={"center"}>
                      {endsOption === "Never" ? (
                        <IconifyIcon
                          icon="akar-icons:radio-fill"
                          width={"22"}
                          color={colors.primary_009}
                        />
                      ) : (
                        <IconifyIcon
                          icon="akar-icons:radio"
                          width={"22"}
                          color={colors.primary_009}
                        />
                      )}
                      <StyledTypography variant="body1" ml={1}>
                        {Translate("createEvent:never")}
                      </StyledTypography>
                    </Stack>
                  </Stack>
                </div>
              </Stack>
              <Stack mb={1}>
                <div
                  onClick={() => {
                    setEndsOption("On");
                    setOccurrence("");
                    setAfterRecurrenceError();
                  }}>
                  <Stack style={styles.item}>
                    <Stack flexDirection={"row"} alignItems={"center"}>
                      {endsOption === "On" ? (
                        <IconifyIcon
                          icon="akar-icons:radio-fill"
                          width={"22"}
                          color={colors.primary_009}
                        />
                      ) : (
                        <IconifyIcon
                          icon="akar-icons:radio"
                          width={"22"}
                          color={colors.primary_009}
                        />
                      )}
                      <StyledTypography variant="body1" ml={1}>
                        {Translate("createEvent:on")}
                      </StyledTypography>
                      <Stack className="form-group mb-0 ml-1">
                        <Datepicker
                          placeholder={DateTimeFormats.ShortMonthDateYear}
                          format={DateTimeFormats.MonthDateYear}
                          value={pickedDate}
                          className="form-control p-0"
                          minDate={
                            fromDateObject?.length > 0
                              ? new Date(fromDateObject)
                              : data?.fromDate
                              ? new Date(data?.fromDate)
                              : getMoment().toDate()
                          }
                          maxDate={
                            data?.toDate ? new Date(data?.toDate) : undefined
                          }
                          onChange={(value) => {
                            if (data?.fromDate) {
                              setPickedDateObject(value.toISOString());
                              setPickedDate(value);
                            } else {
                              showToast({
                                message: "Please select date from create event",
                                type: "info",
                              });
                            }
                            setEndsOnDateError(false);
                          }}
                          error={endsOnDateError}
                        />
                        {endsOnDateError && (
                          <StyledTypography
                            variant="subtitle2"
                            sx={{ color: colors.red_002 }}>
                            {Translate("common:required")}
                          </StyledTypography>
                        )}
                      </Stack>
                    </Stack>
                  </Stack>
                </div>
              </Stack>
              <Stack mb={1}>
                <div
                  onClick={() => {
                    setEndsOption("After");
                    setPickedDate();
                    setPickedDateObject();
                  }}>
                  <Stack style={styles.item}>
                    <Stack flexDirection={"row"} alignItems={"center"}>
                      {endsOption === "After" ? (
                        <IconifyIcon
                          icon="akar-icons:radio-fill"
                          width={"22"}
                          color={colors.primary_009}
                        />
                      ) : (
                        <IconifyIcon
                          icon="akar-icons:radio"
                          width={"22"}
                          color={colors.primary_009}
                        />
                      )}
                      <StyledTypography variant="subtitle1" ml={1}>
                        {Translate("createEvent:after")}
                      </StyledTypography>
                      <Stack mx={1}>
                        <TextField
                          placeholder={"1"}
                          onChange={(text) => {
                            setOccurrence(
                              text.target.value?.replace(/[^1-9]/g, "")
                            );
                            if (
                              text.target.value?.replace(/[^1-9]/g, "").length >
                              0
                            ) {
                              setAfterRecurrenceError(false);
                            }
                          }}
                          value={occurrence}
                          type="tel"
                          style={styles.textField}
                          inputProps={{
                            maxLength: 1,
                            style: { width: "10px", height: "0px" },
                          }}
                          error={afterRecurrenceError}
                        />
                      </Stack>
                      <StyledTypography variant="subtitle1">
                        {Translate("createEvent:occurrence")}
                      </StyledTypography>
                    </Stack>
                  </Stack>
                </div>
              </Stack>
            </Stack>
          </Stack>
          <Stack>
            <div
              className="text-right line_spacing"
              onClick={() => {
                if (
                  (endsOption === "On" &&
                    (pickedDate === undefined || pickedDate.length === 0)) ||
                  (endsOption === "After" &&
                    (occurrence.length === 0 || occurrence === undefined)) ||
                  repeatEvery.length === 0 ||
                  repeatEvery === undefined ||
                  (repeatOption !== "WEEK" &&
                    (fromDateObject === undefined ||
                      fromDateObject.length === 0))
                ) {
                  if (repeatEvery.length === 0 || repeatEvery === undefined) {
                    setRepeatEveryError(true);
                  }
                  if (
                    repeatOption !== "WEEK" &&
                    (fromDateObject === undefined ||
                      fromDateObject?.length === 0)
                  ) {
                    setRepeatsOnDateError(true);
                  }
                  if (
                    endsOption === "On" &&
                    (pickedDate === undefined || pickedDate.length === 0)
                  ) {
                    setEndsOnDateError(true);
                    setAfterRecurrenceError(false);
                  }
                  if (
                    endsOption === "After" &&
                    (occurrence.length === 0 || occurrence === undefined)
                  ) {
                    setAfterRecurrenceError(true);
                    setEndsOnDateError(false);
                  }
                  if (endsOption === "Never") {
                    setAfterRecurrenceError(false);
                    setEndsOnDateError(false);
                  }
                } else {
                  setRepeatEveryError(false);
                  setRepeatsOnDateError(false);
                  setEndsOnDateError(false);
                  setAfterRecurrenceError(false);
                  onSubmit();
                }
              }}>
              <button type="submit" className="btn btn-primary saveButton">
                {Translate("common:save")}
              </button>
            </div>
          </Stack>
        </Stack>
      </Modal.Body>
    </Modal>
  );
};
export default CustomModal;
const styles = {
  label: {
    color: colors.primary_003,
    marginBottom: "5px",
  },
  repeatDropdown: { width: 120 },
  item: {
    paddingTop: 1,
  },
  datePicker: { width: 170 },
  days: {
    height: "28px",
    width: "28px",
    borderRadius: 3,
    justifyContent: "center",
    alignItems: "center",
    marginRight: 10,
  },
  textField: {
    color: colors.black,
    borderRadius: 3,
    height: 40,
    top: 3,
  },
  fromOption: { width: 190 },
  error: {
    color: colors.red_002,
  },
  //   bottomSpace: {
  //     paddingBottom: isIPhoneX() ? 50 : Platform.OS === 'android' ? 20 : 0,
  //   },
};

import Translate from "../../translation/utils/translateFunction";

export const statusOption = [
  {
    label: "Completed",
    value: "Completed",
  },
  {
    label: "In-progress",
    value: "Inprogress",
  },
  {
    label: "Overdue",
    value: "Overdue",
  },
  {
    label: "Resolved",
    value: "Resolved",
  },
  {
    label: "Re-opened",
    value: "Reopened",
  },
  {
    label: "Assigned",
    value: "Assigned",
  },
  {
    label: "Accepted",
    value: "Accepted",
  },
  {
    label: "Rejected",
    value: "Rejected",
  },

  {
    label: "Awaiting-approval",
    value: "AwaitinngApproval",
  },
];
export const priorityOption = [
  {
    label: "Emergency",
    value: "Emergency",
  },
  {
    label: "High",
    value: "High",
  },
  {
    label: "Medium",
    value: "Medium",
  },
  {
    label: "Low",
    value: "Low",
  },
];
export const teamStyle = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "center",
};
export const filterHeders = [
  { title: Translate("manageTask:taskIdTitle"), isSort: false },
  { title: Translate("manageTask:taskTypeTitle"), isSort: false },
  {
    title: Translate("manageTask:taskNameTitle"),
    isSort: false,
    sortTitle: "title",
  },
  {
    title: Translate("manageTask:companyNameTitle"),
    isSort: false,
    sortTitle: "company.name",
  },
  {
    title: Translate("manageTask:dueDateTitle"),
    subTitle: Translate("manageTask:dueTimeTitle"),
    isSort: false,
    sortTitle: "dueDate",
  },
  { title: Translate("manageTask:teamTitle"), isSort: false },
  { title: Translate("manageTask:priorityTitle"), isSort: false },
  { title: Translate("manageTask:statusTitle"), isSort: false },
  { title: Translate("manageTask:actiontitle"), isSort: false },
  ,
];

export const officeLocationHeader = [
  { title: "Office Location", isSort: false },
  { title: Translate("manageTask:actiontitle"), isSort: false },
];

export const SUBTASK_PREFIX = "ST";

export const projectTypeOptions = [
  {
    label: "Project",
    value: "PROJECT",
  },
  {
    label: "Independent task",
    value: "INDEPENDENT",
  },
];

export default {
  head: "البائعين/الموردين",
  addVendorHeading: "إضافة بائع",
  editVendorHeading: "تحرير البائع",
  title: "البائعون/الموردون المرتبطون بالشركة",
  vendorDetails: "تفاصيل البائع",
  companyName: "اسم الشركة الموردة",
  selectCompany: "إختر الشركة",
  dropdownPlaceholder: "اختر الشركة",
  selectCompanyMessage: "الرجاء اختيار شركة واحدة على الأقل",
  vendorName: "اسم البائع",
  email: "عنوان الايميل",
  contactNumber: "رقم الاتصال",
  contactNumberPlaceHolder: "0000 0000 00",
  alternateContactNumber: "رقم الاتصال البديل",
  alternateContactNumberPlaceHolder: "0000 0000 00",
  address: "عنوان",
  country: "دولة",
  taskDependency: "عرض تبعية المهمة",
  tasks: "مهام",
  designation: "تعيين",
  companyExtensionNumber: "رقم الهاتف الداخلي للشركة",
  extensionNumberPlaceholder: "0000 0000 00",
  companyExtensionPlaceholder: "1",
  companyPhoneExtensionPlaceholder: "000",
  department: "قسم",
  workEmail: "البريد الإلكتروني العمل",
  workEmailPlaceholder: "عنوان البريد الإلكتروني للعمل",
  companyAddress: "عنوان الشركة",
  addressPlaceholder_1: "منزل/شقة/رقم المبنى.",
  addressPlaceholder_2: "شقة/طريق/منطقة",
  addressPlaceholder_3: "رقم الشقة/الكتلة",
  addressPlaceholder_4: "المبنى/الطريق/المنطقة",
  countryPlaceholder: "دولة",
  statePlaceholder: "الولاية/المنطقة",
  cityPlaceholder: "مدينة",
  zipcodePlaceholder: "الرمز البريدي",
  workAddress: "عنوان العمل",
  gender: "جنس",
  uploadPhoto: "حمل الصورة",
  save: "يحفظ",
  addMore: "أضف المزيد",
  companyError: "مطلوب شركة",
  companyNameError: "اسم شركة البائع مطلوب",
  nameError: "اسم البائع مطلوب",
  designationError: "التعيين مطلوب",
  departmentError: "القسم مطلوب",
  reportToError: "مطلوب تقرير إلى",
  mobileError: "رقم الاتصال مطلوب",
  workEmailErrorInvalid: "البريد الإلكتروني للعمل غير صالح",
  workEmailError: "مطلوب البريد الإلكتروني للعمل",
  addressError: "عنوان الإقامة مطلوب",
  countryError: "الدولة مطلوبة",
  stateError: "الولاية/المنطقة مطلوبة",
  cityError: "المدينة مطلوبة",
  workAddressError: "عنوان العمل مطلوب",
  zipcodeErrorInvalid: "الرمز البريدي غير صالح!",
  ageAlert: "يجب أن يكون العمر أكبر من 18",
  reportTo: "تقرير الى",
  viewVendorHeading: "عرض البائع",
  deleteAlert: "هل أنت متأكد أنك تريد حذف هذا البائع؟",
  srNoTableHeading: "الأب رقم",
  actionTableHeading: "فعل",
  nameTableHeading: "اسم",
  mobileTableHeading: "رقم المحمول",
  companyNameTableHeading: "اسم الشركة",
  searchPlaceholder: "ابحث عن شيء...",
  addButtonLabel: "يضيف",
  selectedCompanyMessage: "تم اختيار جميع الشركات",
};

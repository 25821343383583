import { Methods, Urls } from "../../common/Utils/ApiConstants";
import { CreateApi } from "../CreateApi";

const MomCollection = CreateApi.injectEndpoints({
  endpoints: (builder) => ({
    addMom: builder.mutation({
      query: (body) => {
        return {
          url: Urls.addMom,
          method: Methods.post,
          body,
        };
      },
    }),
    momListing: builder.mutation({
      query: (body) => {
        return {
          url: Urls.getMomList,
          method: Methods.post,
          body,
        };
      },
    }),
    momDetail: builder.mutation({
      query: (body) => {
        return {
          url: Urls.getMomDetail,
          method: Methods.post,
          body,
        };
      },
    }),
    editMom: builder.mutation({
      query: (body) => {
        return {
          url: Urls.editMom,
          method: Methods.put,
          body,
        };
      },
    }),
    deleteMom: builder.mutation({
      query: (body) => {
        return {
          url: Urls.deleteMom,
          method: Methods.put,
          body,
        };
      },
    }),
    shareMom: builder.mutation({
      query: (body) => {
        return {
          url: Urls.shareMom,
          method: Methods.post,
          body: body,
        };
      },
    }),
    sharedWithMeMomListing: builder.mutation({
      query: (body) => {
        return {
          url: Urls.sharedWithMeMomListing,
          method: Methods.post,
          body: body,
        };
      },
    }),
    getMOMInviteeList: builder.mutation({
      query: (body) => {
        return {
          url: Urls.getMOMInviteeList,
          method: Methods.post,
          body: body,
        };
      },
    }),
    getSharedMomUsersList: builder.mutation({
      query: (body) => {
        return {
          url: Urls.getSharedMomUsersList,
          method: Methods.post,
          body: body,
        };
      },
    }),
  }),
});

export const {
  useAddMomMutation,
  useMomListingMutation,
  useMomDetailMutation,
  useEditMomMutation,
  useDeleteMomMutation,
  useShareMomMutation,
  useSharedWithMeMomListingMutation,
  useGetMOMInviteeListMutation,
  useGetSharedMomUsersListMutation,
} = MomCollection;

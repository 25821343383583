import { Icon } from "@iconify/react";
import React from "react";
import Modal from "react-bootstrap/Modal";
import { colors } from "../../../common/Theme/Colors";
import Translate from "../../../translation/utils/translateFunction";
import { StyledTypography } from "../../../common/Utils/StyledComponents";

const LabelAsModal = ({
  show,
  onCancel,
  onOkClick,
  selectedLabel,
  setSelectedLabel,
  onLabelSelect,
}) => {
  const handleLabelClick = (label) => {
    if (selectedLabel === label) {
      onLabelSelect(true);
      setSelectedLabel(null);
    } else {
      setSelectedLabel(label);
      onLabelSelect(true);
    }
  };
  return (
    <Modal
      show={show}
      onHide={onCancel}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      scrollable={true}
      size="sm"
      className="col-span-3 col-md-12"
    >
      <Modal.Header closeButton>
        <Modal.Title>{Translate("inboxPage:labelAs")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          // onClick={() => setSelectedLabel("actionable")}
          onClick={() => handleLabelClick("actionable")}
          className="d-flex justify-content-between cursor-pointer mb-2"
        >
          <StyledTypography>
            {Translate("inboxPage:actionable")}
          </StyledTypography>
          {selectedLabel === "actionable" ? (
            <Icon icon={"ri:checkbox-line"} fontSize={20} />
          ) : (
            <Icon icon={"carbon:checkbox"} fontSize={20} />
          )}
        </div>
        <div
          // onClick={() => setSelectedLabel("informative")}
          onClick={() => handleLabelClick("informative")}
          className="d-flex justify-content-between cursor-pointer mb-1"
        >
          <StyledTypography>
            {Translate("inboxPage:informative")}
          </StyledTypography>
          {selectedLabel === "informative" ? (
            <Icon icon={"ri:checkbox-line"} fontSize={20} />
          ) : (
            <Icon icon={"carbon:checkbox"} fontSize={20} />
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div
          style={{ color: colors.primary, fontWeight: "500" }}
          className="cursor-pointer"
          onClick={onCancel}
        >
          {Translate("common:cancel")}
        </div>
        <div
          style={{ color: colors.primary, fontWeight: "500" }}
          className="cursor-pointer"
          onClick={onOkClick}
        >
          {Translate("common:ok")}
        </div>
      </Modal.Footer>
    </Modal>
  );
};
export default LabelAsModal;

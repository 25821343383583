import Translate from "../../../../translation/utils/translateFunction";

export const sortingOptions = [
  {
    label: Translate("filter:ascending"),
    value: 1,
  },
  {
    label: Translate("filter:descending"),
    value: -1,
  },
];

export const staffOptions = [
  {
    label: "Self",
    value: "SELF",
  },
  {
    label: "Owner",
    value: "OWNER",
  },
  {
    label: "PM",
    value: "PM",
  },
  {
    label: "GM",
    value: "GM",
  },
  {
    label: "Manager",
    value: "MANAGER",
  },
  {
    label: "Employee",
    value: "EMPLOYEE",
  },
  {
    label: "Vendor",
    value: "VENDOR",
  },
];

import React, { useState, useEffect } from "react";
import Loader from "../staff/Owner/LoadingComp";
import { showToast } from "../../common/Utils/ToastMessage";
import { useGetPrivacyDetailsMutation } from "../../request/Cms";
import NoDataFound from "../ManageTask/NoDataFound";

const Privacy = () => {
  const [mainContent, setMainContent] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const [
    getPrivacyDetailsApi,
    {
      data: privacyData,
      isSuccess: isPrivacySuccess,
      isLoading: isPrivacyLoading,
      isError: isPrivacyrError,
      error: privacyError,
    },
  ] = useGetPrivacyDetailsMutation();

  useEffect(() => {
    getPrivacyDetailsApi({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isPrivacyrError && privacyError) {
      showToast({
        message: privacyError?.data?.message,
        type: "error",
      });
      setIsLoading(false);
    }

    if (privacyData && isPrivacySuccess) {
      if (!privacyData?.success) {
        showToast({
          message: privacyData?.message,
          type: "error",
        });
      } else {
        setMainContent(privacyData?.data);
      }

      setIsLoading(false);
    }
  }, [isPrivacySuccess, privacyData, isPrivacyrError, privacyError]);

  return (
    <div className="row container_spacing">
        <div className="cms-container card">
          {!isPrivacyLoading && !isLoading ? (
            privacyData?.data ? (
              <div
                className="col-lg-12 col-md-12 col-sm-12 col-xs-12 card_spacing p-20"
                dangerouslySetInnerHTML={{ __html: mainContent }}
              />
            ) : (
              <NoDataFound isCenter />
            )
          ) : null}
          <Loader loading={isPrivacyLoading || isLoading} />
        </div>
    </div>
  );
};

export default Privacy;

import { CreateApi } from "../../CreateApi";
import { Methods, Urls } from "../../../common/Utils/ApiConstants";
const rejectedTaskcollection = CreateApi.injectEndpoints({
  endpoints: (builder) => ({
    getRejectedTaskData: builder.query({
      query: (pageNo) => {
        return {
          url: Urls.getRejectedTaskList,
          method: Methods.get,
          params: { pageNo },
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
    }),
  }),
});
export const { useLazyGetRejectedTaskDataQuery } = rejectedTaskcollection;

import { CircularProgress, Grid } from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import CompanyHeader from "../../../common/CompanyHeader";
import { colors } from "../../../common/Theme/Colors";
import { formatDateYYYYMMDD } from "../../../common/Utils/DateFormater";
import {
  StyledCard,
  StyledTypography,
} from "../../../common/Utils/StyledComponents";
import { showToast } from "../../../common/Utils/ToastMessage";
import { getMoment } from "../../../common/Utils/commonFunction";
import {
  useGetExportStaffReportsMutation,
  useGetStaffReportListMutation,
} from "../../../request/Report";
import Translate from "../../../translation/utils/translateFunction";
import CompanyListing from "../../ContactRepository/component";
import IconifyIcon from "../../Icon";
import ListView from "../../ListView";
import DropDownField from "../../TextField/DropdownField";
import Searchbar from "../../common/Searchbar";
import { staffDefaultOptions } from "../TaskReports/components/constants";
import { userTypeOptions } from "../components/constants";
import { getStaffReportColumns } from "./components/column.data";

const StaffReports = ({ fixNavbar }) => {
  const navigate = useNavigate();
  const csvLink = useRef();
  const { selectedCompanyList, userData, userType, isRtl } = useSelector(
    (state) => state.formanagement
  );

  const [searchText, setSearchText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [staffReports, setStaffReports] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPageNo, setLastPageNo] = useState(0);
  const [showFilterIcon, setShowFilterIcon] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [staffOption, setStaffOption] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState("ALL");
  const [csvData, setCSVData] = useState([]);

  const [
    getStaffReportsApi,
    {
      data: staffReportData,
      isLoading: isStaffReportLoading,
      isError: isStaffReportError,
      error: staffReportError,
    },
  ] = useGetStaffReportListMutation();

  const [
    exportStaffReportsApi,
    {
      data: exportStaffData,
      isLoading: isExportStaffLoading,
      error: exportStaffError,
    },
  ] = useGetExportStaffReportsMutation();

  const requestBody = useMemo(() => {
    const companyIds = selectedCompanyList?.length
      ? selectedCompanyList?.map((item) => item?.value)
      : [];

    const reqObj = {
      companyId: companyIds,
      searchText,
      staff: selectedStaff,
      pageNo: currentPage,
    };

    return companyIds?.length ? reqObj : null;
  }, [searchText, currentPage, selectedCompanyList, selectedStaff]);

  const debouncedSearch = useDebouncedCallback(() => {
    getStaffReportsApi(requestBody);
  }, 500);

  useEffect(() => {
    if (userData && userType) {
      const { staffReport } = userData;

      if (
        staffReport?.length &&
        userType !== userTypeOptions?.VENDOR &&
        userType !== userTypeOptions?.EMPLOYEE
      ) {
        const defaultAccess = staffReport?.map((user) => ({
          label: user in userTypeOptions && userTypeOptions[user],
          value: user,
        }));
        setStaffOption([...staffDefaultOptions, ...defaultAccess]);
        setShowFilter(true);
      }
    }
  }, [userData, userType]);

  useEffect(() => {
    setStaffReports([]);
    if (selectedCompanyList?.length) {
      setIsLoading(true);

      if (requestBody?.searchText?.length) {
        debouncedSearch();
      } else {
        getStaffReportsApi(requestBody);
      }
    } else {
      showToast({
        type: "info",
        message: Translate("vendors:selectCompanyMessage"),
      });
    }
  }, [requestBody]);

  useEffect(() => {
    if (staffReportData?.data?.nodes) {
      const { nodes, pageInfo } = staffReportData.data;
      setStaffReports(nodes);
      setLastPageNo(pageInfo.lastPageNo);
      setIsLoading(false);
    }

    if (isStaffReportError && staffReportError) {
      if (
        staffReportError?.data?.error?.length &&
        staffReportError?.data?.error[0]
      ) {
        staffReportError?.originalStatus !== 500 &&
          showToast({
            message: staffReportError?.data?.error[0]?.msg,
            type: "error",
          });
      } else {
        staffReportError?.originalStatus !== 500 &&
          showToast({
            message:
              JSON.stringify(staffReportError?.data) ||
              Translate("common:unknownServerError"),
            type: "error",
          });
      }

      setIsLoading(false);
    }
  }, [staffReportData, isStaffReportError, staffReportError]);

  useEffect(() => {
    if (csvData?.length > 0) {
      // eslint-disable-next-line no-unused-expressions
      csvLink?.current?.link?.click();
    }
  }, [csvData]);

  useEffect(() => {
    if (exportStaffData?.length > 0) {
      const rows = exportStaffData?.split("\n");
      const rowArray = rows.map((item) => item.trim().split(","));

      setCSVData(rowArray);
    }

    if (exportStaffError) {
      const errorResponse = JSON.parse(exportStaffError?.data);

      exportStaffError?.originalStatus !== 500 &&
        showToast({
          message:
            JSON.stringify(errorResponse?.message) ||
            Translate("common:unknownServerError"),
          type: "error",
        });
    }
  }, [exportStaffData, exportStaffError]);

  const showFilters = () => {
    setShowFilterIcon(!showFilterIcon);
  };

  const clearFilter = () => {
    setCurrentPage(1);
    setSelectedStaff("ALL");
  };

  const setFilterValue = (item, placeholder) => {
    setCurrentPage(1);
    switch (placeholder) {
      case Translate("taskReport:staffPlaceHolder"): {
        item.target.value !== placeholder
          ? setSelectedStaff(item.target.value)
          : setSelectedStaff("");

        break;
      }
    }
  };

  const handleSearchText = (event) => {
    selectedCompanyList?.length > 0 &&
      event?.target?.value.trim() &&
      setIsLoading(true);
    setCurrentPage(1);
    setSearchText(event);
  };

  const CustomSrCellRender = (params) => {
    return (
      <StyledTypography component="span" sx={{ color: colors.primary_009 }}>
        {params?.api?.getRowIndexRelativeToVisibleRows(params?.row?._id) +
          1 +
          (currentPage - 1) * 10}
      </StyledTypography>
    );
  };

  const CustomActionCellRender = (params) => {
    return (
      <td>
        <i
          className="m-2 fa fa-eye cursor-pointer manageIconColor"
          onClick={() =>
            navigate("/reports/staff/view", {
              state: params?.row?._id,
            })
          }
          data-toggle="tooltip"
          title={Translate("common:viewToolTip")}></i>
      </td>
    );
  };

  const handleExportTask = () => {
    exportStaffReportsApi(requestBody);
  };

  return (
    <Grid
      item
      xs={12}
      className={`section-body ${isRtl ? "rtlMargin" : "ltrMargin"} ${
        fixNavbar ? "marginTop" : ""
      } `}>
      <StyledCard variant="outlined">
        <div className="container-fluid">
          <div className="tab-content">
            <div className="card cardHeight">
              <div className="row">
                <div className="col-12">
                  <div className="card-header fixedHeight">
                    <CompanyHeader setShowModal={setShowModal} />
                    <div className="card-options">
                      <div className="sort_stat commentTextField">
                        {showFilter && (
                          <>
                            {showFilterIcon ? (
                              <div className="row">
                                <div
                                  className="col-12"
                                  style={{ marginTop: "9px" }}>
                                  <StyledTypography
                                    sx={{ color: colors.primary_009 }}
                                    component="span">
                                    {Translate("taskReport:staffLabel")}
                                  </StyledTypography>
                                  <div className="selectWidth">
                                    <div className="form-group cursor-pointer">
                                      <DropDownField
                                        options={staffOption}
                                        className={
                                          "form-control textFieldHeight cursor-pointer"
                                        }
                                        placeholder={Translate(
                                          "taskReport:staffPlaceHolder"
                                        )}
                                        onChange={(item) =>
                                          setFilterValue(
                                            item,
                                            Translate(
                                              "taskReport:staffPlaceHolder"
                                            )
                                          )
                                        }
                                        value={selectedStaff}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            <div
                              className="filterIconBox headerMargin mx-2"
                              onClick={() => {
                                showFilterIcon && clearFilter();
                                showFilters(!showFilterIcon);
                              }}>
                              {showFilterIcon ? (
                                <span
                                  data-toggle="tooltip"
                                  title={Translate(
                                    "common:clearFilterToolTip"
                                  )}>
                                  <IconifyIcon
                                    icon="mdi:filter-off"
                                    width="19"
                                    className="dateRangeFilterIcon"
                                  />
                                </span>
                              ) : (
                                <span
                                  data-toggle="tooltip"
                                  title={Translate("common:showFilterToolTip")}>
                                  <IconifyIcon
                                    icon="fa:filter"
                                    width="14"
                                    className="dateRangeFilterIcon"
                                  />
                                </span>
                              )}
                            </div>
                          </>
                        )}

                        <form className="headerMargin">
                          <div className="input-group">
                            <Searchbar
                              handleSearchText={handleSearchText}
                              setSearch={setSearchText}
                            />
                          </div>
                        </form>
                        <form className="headerMargin ml-3">
                          <div className="header-action pull-right">
                            <button
                              type="button"
                              className="btn btn-primary saveButton py-2"
                              onClick={handleExportTask}
                              disabled={!staffReports?.length}>
                              {isExportStaffLoading ? (
                                <div className="align-self-center">
                                  <CircularProgress size={22} color="inherit" />
                                </div>
                              ) : (
                                <>
                                  <i className="fe fe-download" />
                                  <StyledTypography
                                    className="mx-1"
                                    component="span">
                                    {Translate("common:exportLabel")}
                                  </StyledTypography>
                                </>
                              )}
                            </button>

                            {csvData?.length > 0 && (
                              <CSVLink
                                data={csvData}
                                ref={csvLink}
                                filename={`${Translate(
                                  "staffReport:exportStaffFileName"
                                )}_${formatDateYYYYMMDD(
                                  getMoment().toDate()
                                )}.csv`}
                              />
                            )}
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <ListView
                  columns={getStaffReportColumns(
                    CustomSrCellRender,
                    CustomActionCellRender
                  )}
                  rows={staffReports}
                  autoHeight
                  sortingMode="server"
                  onPaginationChange={setCurrentPage}
                  currentPage={currentPage}
                  showShimmer={isLoading || isStaffReportLoading}
                  showLoader={isLoading}
                  totalPageCount={lastPageNo}
                />
              </div>
            </div>
          </div>
        </div>
      </StyledCard>

      <CompanyListing
        handleModal={(value) => {
          setCurrentPage(1);
          setShowModal(value);
        }}
        showModal={showModal}
        selectedCompanyList={selectedCompanyList}
      />
    </Grid>
  );
};

export default StaffReports;

import Translate from "../../../../translation/utils/translateFunction";
import {
  CustomDueDateCellRender,
  CustomPriorityCellRender,
  CustomProgressBarCellRender,
  CustomTaskTypeCellRender,
  CustomStatusCellRender,
} from "../../../ManageTask/Components/utils";
import { CustomTitleCellRender } from "../../../common/CustomCellRender";

export const getSubtaskColumns = (
  CustomSrCellRender,
  CustomActionCellRender
) => {
  const columns = [
    {
      field: "lineNo",
      headerName: Translate("common:srNoTitle"),
      align: "center",
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      renderCell: CustomSrCellRender,
      flex: 0.17,
    },
    {
      field: "taskNumber",
      headerName: Translate("manageTask:taskIdTitle"),
      flex: 0.3,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => CustomTitleCellRender(params?.row?.taskNumber),
    },
    {
      field: "type",
      headerName: Translate("manageTask:taskTypeTitle"),
      flex: 0.35,
      sortable: false,
      disableColumnMenu: true,
      renderCell: CustomTaskTypeCellRender,
    },
    {
      field: "title",
      headerName: Translate("manageTask:taskNameTitle"),
      flex: 0.5,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => CustomTitleCellRender(params?.row?.title),
    },
    {
      field: "assigneeName",
      headerName: Translate("manageTask:assigneeNameTitle"),
      flex: 0.5,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => CustomTitleCellRender(params?.row?.assigneeName),
    },
    {
      field: "dueDate",
      headerName: Translate("manageTask:dueDateAndTimeTitle"),
      flex: 0.3,
      sortable: false,
      disableColumnMenu: true,
      renderCell: CustomDueDateCellRender,
    },
    {
      field: "priority",
      headerName: Translate("manageTask:priorityTitle"),
      flex: 0.25,
      sortable: false,
      disableColumnMenu: true,
      renderCell: CustomPriorityCellRender,
    },
    {
      field: "taskStatus",
      headerName: Translate("manageTask:statusTitle"),
      flex: 0.32,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) =>
        CustomTitleCellRender(CustomStatusCellRender(params)),
    },
    {
      field: "taskProgress",
      headerName: Translate("manageTask:progressBar"),
      flex: 0.32,
      sortable: false,
      disableColumnMenu: true,
      renderCell: CustomProgressBarCellRender,
    },
    {
      field: "Action",
      headerName: Translate("common:actionTitle"),
      flex: 0.2,
      sortable: false,
      disableColumnMenu: true,
      hide: true,
      renderCell: CustomActionCellRender,
    },
  ];
  return columns;
};

import React, { useRef, useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { getMOMmyListingColumns } from "../column.data";
import { colors } from "../../../../common/Theme/Colors";
import ListView from "../../../ListView";
import {
  StyledCard,
  StyledTypography,
} from "../../../../common/Utils/StyledComponents";
import { useNavigate } from "react-router-dom";
import { useSharedWithMeMomListingMutation } from "../../../../request/Mom";
import { showToast } from "../../../../common/Utils/ToastMessage";
import Translate from "../../../../translation/utils/translateFunction";

const SharedWithMeList = ({ fixNavbar }) => {
  const navigate = useNavigate();
  const { isRtl } = useSelector((state) => state.formanagement);
  const [currentPage, setCurrentPage] = useState(1);
  const [myEventsListData, setMyEventListData] = useState([]);
  const lastPage = useRef(1);

  const [
    getSharedMomList,
    { data: sharedMomData, isSuccess, isLoading, isError, error },
  ] = useSharedWithMeMomListingMutation();

  useEffect(() => {
    getSharedMomList({ pageNo: currentPage });
  }, [currentPage]);

  useEffect(() => {
    if (isSuccess && sharedMomData?.data) {
      const { record, pageInfo } = sharedMomData?.data;
      setMyEventListData(record);
      lastPage.current = pageInfo?.lastPageNo;
    }

    if (isError && error) {
      showToast({
        message: error?.data?.message || Translate("document:apiFailedMessage"),
        type: "error",
      });
    }
  }, [isSuccess, isError, error]);

  const customSrCellRender = (params) => {
    return (
      <StyledTypography component="span" sx={{ color: colors.primary_009 }}>
        {params?.api?.getRowIndexRelativeToVisibleRows(params?.row?._id) +
          1 +
          (currentPage - 1) * 10}
      </StyledTypography>
    );
  };
  const customActionCellRender = (params) => {
    return (
      <i
        className={`manageIconColor ml-3 fa fa-eye cursor-pointer`}
        onClick={() =>
          navigate("/mom/sharedWithMe/view", {
            state: { eventid: params?.row?._id, isShared: true },
          })
        }
      />
    );
  };

  return (
    <Grid
      item
      xs={12}
      className={`section-body ${isRtl ? "rtlMargin" : "ltrMargin"} ${
        fixNavbar ? "marginTop" : ""
      } `}
    >
      <StyledCard variant="outlined">
        <div className="container-fluid p-4">
          <div className="tab-content">
            <div>
              <div className="card">
                <ListView
                  columns={getMOMmyListingColumns(
                    customSrCellRender,
                    customActionCellRender
                  )}
                  rows={myEventsListData}
                  autoHeight
                  sortingMode="server"
                  onPaginationChange={setCurrentPage}
                  currentPage={currentPage}
                  showShimmer={isLoading}
                  showLoader={isLoading}
                  totalPageCount={lastPage.current}
                />
              </div>
            </div>
          </div>
        </div>
      </StyledCard>
    </Grid>
  );
};
export default SharedWithMeList;

import React from "react";
import { Persona } from "../../Persona";
import { userType } from "../../../common/Utils/ApiConstants";
import { CustomTitleCellRender } from "../../common/CustomCellRender";

const OrganizationCard = ({ node, isCardImg, horizontal }) => {
  const isFullBorder =
    horizontal &&
    (node?.designation?.toUpperCase() === userType?.Vendor?.toUpperCase() ||
      node?.designation?.toUpperCase() === userType?.Employee?.toUpperCase());

  return (
    <div
      className="orgCardContainer"
      style={{
        position: "absolute",
        left: node?.x - 70,
        top: node?.y - 35,
        width: "150px",
        border: isFullBorder ? `2px solid ${node?.color}` : "1px solid #a8a5a5",
        borderLeft: !isFullBorder
          ? `5px solid ${node?.color}`
          : `2px solid ${node?.color}`,
        transform: horizontal ? "rotate(90deg)" : undefined,
      }}
    >
      {isCardImg && (
        <Persona
          className={"orgCardImg"}
          name={node?.name}
          image={node?.imgUrl}
        />
      )}
      <div className="orgCardContent">
        {CustomTitleCellRender(node?.name, "orgCardName")}
        <p className="orgCardRole">{node?.designation}</p>
      </div>
    </div>
  );
};

export default OrganizationCard;

import Translate from "../../../translation/utils/translateFunction";
import { CustomTitleCellRender } from "../../common/CustomCellRender";

export const getUserColumns = (customSrCellRender, customDueDateCellRender) => {
  const columns = [
    {
      field: "lineNo",
      headerName: Translate("common:srNoTitle"),
      align: "center",
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      renderCell: customSrCellRender,
    },
    {
      field: "name",
      headerName: Translate("activityLog:userTitle"),
      flex: 0.4,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => CustomTitleCellRender(params?.row?.name),
    },
    {
      field: "company",
      headerName: Translate("activityLog:companyTitle"),
      flex: 0.4,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => CustomTitleCellRender(params?.row?.company),
    },
    {
      field: "date",
      headerName: Translate("activityLog:dateTimeTitle"),
      flex: 0.4,
      sortable: false,
      disableColumnMenu: true,
      renderCell: customDueDateCellRender,
    },
    {
      field: "documentTitle",
      headerName: Translate("activityLog:activityTitle"),
      flex: 0.6,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) =>
        CustomTitleCellRender(params?.row?.documentDescription),
    },
  ];
  return columns;
};

import { getDayJs, getMoment } from "../../../common/Utils/commonFunction";
import Translate from "../../../translation/utils/translateFunction";

const REM_15_MINUTES_BEFORE = "15_MINUTES_BEFORE",
  REM_30_MINUTES_BEFORE = "30_MINUTES_BEFORE",
  REM_1_HOUR_BEFORE = "1_HOUR_BEFORE",
  REM_CUSTOM = "CUSTOM",
  MINUTES_BEFORE = "Minutes before",
  HOURS_BEFORE = "Hours before",
  DAYS_BEFORE = "Days before ",
  WEEK_BEFORE = "Week before ",
  PUSH_NOTIFICATION = "As notification",
  MAIL_NOTIFICATION = "As mail",
  PUSH = "PUSH",
  MAIL = "EMAIL";

export const reminderOptions = [
  {
    label: Translate("createEvent:eventReminder1"),
    value: REM_15_MINUTES_BEFORE,
  },
  {
    label: Translate("createEvent:eventReminder2"),
    value: REM_30_MINUTES_BEFORE,
  },
  { label: Translate("createEvent:eventReminder3"), value: REM_1_HOUR_BEFORE },
  { label: Translate("createEvent:eventRepeatOption6"), value: REM_CUSTOM },
];

export const reminderType = {
  REM_15_MINUTES_BEFORE,
  REM_30_MINUTES_BEFORE,
  REM_1_HOUR_BEFORE,
  REM_CUSTOM,
};
export const notificationType = {
  PUSH,
  MAIL,
};

export const ReminderInitialValues = {
  time: 0,
  timeInterval: MINUTES_BEFORE,
  notification: PUSH_NOTIFICATION,
};

export const InitialValuesVoiceNotes = {
  reminderFor: "",
  remindTo: "Self",
  startDate: getMoment().toDate(),
  startTime: getDayJs(),
  reminder: "",
  time: null,

  timeInterval: "",
  notification: "",
};
export const InitialValues = {
  reminderFor: "",
  remindTo: "",
  startDate: getMoment().toDate(),
  startTime: getDayJs(),
  reminder: "",
  time: null,

  timeInterval: "",
  notification: "",
};
export const customReminderType = {
  MINUTES_BEFORE,
  HOURS_BEFORE,
  DAYS_BEFORE,
  WEEK_BEFORE,
};
export const customNotificationType = {
  PUSH_NOTIFICATION,
  MAIL_NOTIFICATION,
};
export const customReminderOptions = [
  {
    label: Translate("calendarPage:reminderMinute"),
    value: MINUTES_BEFORE,
  },
  {
    label: Translate("calendarPage:reminderHour"),
    value: HOURS_BEFORE,
  },
  {
    label: Translate("calendarPage:reminderDay"),
    value: DAYS_BEFORE,
  },
  {
    label: Translate("calendarPage:reminderWeek"),
    value: WEEK_BEFORE,
  },
];
export const customNotificationOptions = [
  {
    label: Translate("calendarPage:reminderNotification"),
    value: PUSH_NOTIFICATION,
  },
  {
    label: Translate("calendarPage:reminderMail"),
    value: MAIL_NOTIFICATION,
  },
];
export const typeOptions = ["PUSH", "EMAIL"];
export const action = "SHARE";
export const documentCollection = "voicenotes";
export const documentDescription = "From Shared Listing to View";
export const documentDescriptionMyVoiceNotes = "From  Listing to View";
export const actionType = "READ";
export const ownList = "OWN";
export const sharedByMe = "SHARED";
export const createdByPA = "PA";

import { Grid } from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import CompanyHeader from "../../../common/CompanyHeader";
import {
  StyledCard,
  StyledTypography,
} from "../../../common/Utils/StyledComponents";
import { showToast } from "../../../common/Utils/ToastMessage";
import { userTypes } from "../../../common/users/userTypes";
import { useGetEmployeeListingMutation } from "../../../request/Staff/Employee";
import Translate from "../../../translation/utils/translateFunction";
import CompanyListing from "../../ContactRepository/component";
import ListView from "../../ListView";
import Searchbar from "../../common/Searchbar";
import { getEmployeeColumns } from "./Components/column.data";
import { checkModuleAccess } from "../../../common/Utils/commonFunction";

const EmployeeListing = ({ fixNavbar }) => {
  const navigate = useNavigate();
  const { selectedCompanyList, userData, isRtl } = useSelector(
    (state) => state.formanagement
  );

  const [isLoading, setIsLoading] = useState(false);
  const [sortBy, setSortBy] = useState(true);
  const [selectedColumn, setSelectedColumn] = useState("name");

  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [search, setSearch] = useState("");
  const [employeeData, setEmployeeData] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const lastPage = useRef(0);
  const [
    fetchEmployeeList,
    {
      data: employeeList,
      isLoading: isEmployeeLoading,
      isError: isEmployeeListError,
      error: employeeListError,
    },
  ] = useGetEmployeeListingMutation();

  const requestObj = useMemo(() => {
    const companies = selectedCompanyList?.length
      ? selectedCompanyList?.map((item) => item?.value)
      : [];

    const finalReq = {
      companies,
      searchValue: search,
      sortCoulmn: selectedColumn,
      sortDirection: sortBy ? 1 : -1,
    };

    return companies?.length ? finalReq : null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageNo, search, sortBy, selectedCompanyList]);

  const debouncedSearch = useDebouncedCallback(() => {
    fetchEmployeeList({ requestBody: requestObj, pageNo: currentPageNo });
  }, 500);

  useEffect(() => {
    setEmployeeData([]);

    if (selectedCompanyList?.length) {
      sortBy === null && setIsLoading(true);

      if (requestObj?.searchValue?.length) {
        debouncedSearch();
      } else {
        sortBy !== undefined &&
          fetchEmployeeList({ requestBody: requestObj, pageNo: currentPageNo });
      }
    } else {
      showToast({
        type: "info",
        message: Translate("addEmployee:selectCompanyMessage"),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestObj]);

  useEffect(() => {
    if (employeeList?.data?.nodes) {
      const { nodes, pageInfo } = employeeList.data;
      setEmployeeData(nodes);

      lastPage.current = pageInfo.lastPageNo;
      setIsLoading(false);
    }

    if (employeeListError && isEmployeeListError) {
      employeeListError?.originalStatus !== 500 &&
        showToast({
          message: JSON.stringify(employeeListError.data),
          type: "error",
        });
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeList]);

  const debounced = useDebouncedCallback((columnName) => {
    selectedColumn !== columnName
      ? setSortBy(true)
      : setSortBy((current) => !current);
  }, 500);

  const getSort = (columnName = "") => {
    setIsLoading(true);
    selectedColumn !== columnName && setSortBy(undefined);
    debounced(columnName);
    setSelectedColumn(columnName);
  };
  const handleSearchText = (value) => {
    setCurrentPageNo(1);
    setSearch(value);
  };
  const rowsPerPage = 10;
  const startSerialNumber = (currentPageNo - 1) * rowsPerPage + 1;

  const rowsWithSerialNumbers =
    employeeData &&
    employeeData.map((row, index) => ({
      ...row,
      serialNumber: startSerialNumber + index,
    }));
  const CustomCellActionRender = (params) => {
    return (
      <td>
        <i
          className={`m-2 fa fa-eye cursor-pointer manageIconColor iconSize
              
            }`}
          data-toggle="tooltip"
          title={Translate("contacts:view")}
          onClick={() =>
            navigate("/viewEmployee", { state: params?.row?.userId })
          }
        ></i>
      </td>
    );
  };

  return (
    <Grid
      item
      xs={12}
      className={`section-body ${isRtl ? "rtlMargin" : "ltrMargin"} ${
        fixNavbar ? "marginTop" : ""
      } `}
    >
      <StyledCard variant="outlined">
        <div className="container-fluid p-4">
          <div className="tab-content">
            <div>
              <div className="card">
                <div className="card-header card-header-spacing">
                  <CompanyHeader setShowModal={setShowModal} />
                  <div style={{ display: "flex", marginLeft: "auto" }}>
                    <div className="card-options">
                      <div className="input-group">
                        <Searchbar
                          handleSearchText={handleSearchText}
                          setSearch={setSearch}
                        />
                      </div>

                      {checkModuleAccess(
                        userData?.add,
                        userTypes?.Employee
                      ) && (
                        <div className="header-action pull-right ml-2">
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => navigate("/addEmployee")}
                          >
                            <i className="fe fe-plus" />
                            <StyledTypography className="mx-1" component="span">
                              {Translate("common:add")}
                            </StyledTypography>
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <ListView
                    columns={getEmployeeColumns(CustomCellActionRender)}
                    rows={rowsWithSerialNumbers}
                    autoHeight
                    onPaginationChange={setCurrentPageNo}
                    currentPage={currentPageNo}
                    showShimmer={isEmployeeLoading}
                    showLoader={isLoading}
                    totalPageCount={lastPage.current}
                    onSortModelChange={(data) =>
                      data?.length && getSort(data[0]?.field)
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <CompanyListing
          handleModal={(value) => {
            setCurrentPageNo(1);
            setShowModal(value);
          }}
          showModal={showModal}
          selectedCompanyList={selectedCompanyList}
        />
      </StyledCard>
    </Grid>
  );
};

export default EmployeeListing;

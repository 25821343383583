import React, { useEffect, useState, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Translate from "../../../translation/utils/translateFunction";
import Donutchart from "../../common/donutchart";
import { colors } from "../../../common/Theme/Colors";
import { useGetStaffPerformanceDetailsMutation } from "../../../request/Staff/Performance";
import { showToast } from "../../../common/Utils/ToastMessage";
import Loader from "../Owner/LoadingComp";
import { chartOptions } from "./Components/constants";
import { useSelector } from "react-redux";

const StaffPerformance = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { selectedCompanyList } = useSelector((state) => state.formanagement);

  const [isLoading, setIsLoading] = useState(true);
  const [performanceDetails, setPerformanceDetails] = useState("");
  const [donutOptions, setDonutOptions] = useState(chartOptions);
  const [series, setSeries] = useState([0, 100]);

  const [
    getStaffPerformanceDetailsApi,
    {
      data: performanceData,
      isLoading: isPerformanceLoading,
      isSuccess: isPerformanceSuccess,
      isError: isPerformanceError,
      error: performanceError,
    },
  ] = useGetStaffPerformanceDetailsMutation();

  const requestBody = useMemo(() => {
    const companyIds = selectedCompanyList?.length
      ? selectedCompanyList?.map((item) => item?.value)
      : [];

    const reqObj = {
      userId: state?.userData?._id,
      companyId: companyIds,
    };

    return selectedCompanyList?.length && reqObj?.userId ? reqObj : null;
  }, [state, selectedCompanyList]);

  useEffect(() => {
    if (requestBody) {
      getStaffPerformanceDetailsApi(requestBody);
    }
  }, [requestBody]);

  useEffect(() => {
    if (performanceData && isPerformanceSuccess) {
      const { data } = performanceData;
      setPerformanceDetails(data);
      const { colorCode, performance } = data?.performanceData;

      const doNutFillColor = {
        colors: [colorCode, colors.grey_001],
      };

      if (Number(performance) > 0) {
        let seriesVal = 100 - Number(performance);
        setSeries([Number(performance), seriesVal]);
      }

      setDonutOptions({ ...donutOptions, fill: doNutFillColor });
      setIsLoading(false);
    }
    if (isPerformanceError && performanceError) {
      showToast({
        type: "error",
        message:
          JSON.stringify(performanceError?.data?.message) ||
          Translate("common:unknownServerError"),
      });
      setIsLoading(false);
    }
  }, [
    performanceData,
    isPerformanceSuccess,
    isPerformanceError,
    performanceError,
  ]);

  return (
    <>
      <div className="row container_spacing">
        <div className="card card_spacing">
          <div className="card-header">
            <i
              className="icon fe fe-arrow-left mr-3 icon-size backnavigation-iconColor cursor-pointer"
              onClick={() => navigate(-1)}
              title={Translate("common:backToolTip")}
            />
            <h5 className="page-title">
              {Translate("performanceReport:pointsEarnedTitle")}
            </h5>
          </div>
        </div>

        <div className="row">
          <div className="col-md-5 col-sm-12">
            <div className="card">
              <div className="card-body performanceCardBody">
                <p className="performanceTitle font-19">
                  {Translate("performanceReport:totalEmailLabel")} :{" "}
                  {performanceDetails?.emailData?.totalEmails || 0}
                </p>
                <div className="row performanceDetails">
                  <div className="col-md-4">
                    <p className="">
                      {Translate("performanceReport:receivedEmailLabel")}
                    </p>
                    <p className="performanceEmailDetails">
                      {performanceDetails?.emailData?.userEmailDetails
                        ?.emailReceived || 0}
                    </p>
                  </div>
                  <div className="col-md-4">
                    <p className="">
                      {Translate("performanceReport:sentEmailLabel")}
                    </p>
                    <p className="performanceEmailDetails">
                      {performanceDetails?.emailData?.userEmailDetails
                        ?.emailSent || 0}
                    </p>
                  </div>
                  <div className="col-md-4">
                    <p className="">
                      {Translate("performanceReport:ratioEmailLabel")}
                    </p>
                    <p className="performanceEmailDetails">
                      {performanceDetails?.emailData?.emailRatio
                        ? `${performanceDetails?.emailData?.emailRatio?.numerator}:${performanceDetails?.emailData?.emailRatio?.denominator}`
                        : 0}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-7 col-sm-12">
            <div className="card">
              <div className="card-body performanceCardBody">
                <p className="performanceTitle font-19">
                  {Translate("performanceReport:taskCompletionLabel")}
                </p>
                <div className="performanceDetails">
                  <p className="performanceTitle">
                    {Translate("performanceReport:totalAssignedLabel")} :{" "}
                    {performanceDetails?.taskData?.totalAssigned || 0}
                  </p>

                  <div className="row">
                    <div className="col-md-3">
                      <p className="performanceTitle">
                        {Translate("performanceReport:completed")} :{" "}
                        {performanceDetails?.taskData?.completed || 0}
                      </p>
                    </div>
                    <div className="col-md-3">
                      <p className="font-weight-normal">
                        {Translate("performanceReport:onTime")} :{" "}
                        <span
                          style={{ color: colors.blue_001 }}
                          className="font-weight-bold">
                          {performanceDetails?.taskData?.onTime || 0}
                        </span>
                      </p>
                    </div>
                    <div className="col-md-3">
                      <p className="font-weight-normal">
                        {Translate("performanceReport:before")} :{" "}
                        <span
                          style={{ color: colors.green }}
                          className="font-weight-bold">
                          {performanceDetails?.taskData?.beforeTime || 0}
                        </span>
                      </p>
                    </div>
                    <div className="col-md-3">
                      <p className="font-weight-normal">
                        {Translate("performanceReport:after")} :{" "}
                        <span
                          style={{ color: colors.red }}
                          className="font-weight-bold">
                          {performanceDetails?.taskData?.afterTime || 0}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-12">
            <div className="card">
              <div className="card-header performanceTitle font-19">
                {Translate("performanceReport:finalResultLabel")}
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6">
                    {performanceDetails && (
                      <div className="performanceChartBox">
                        <Donutchart
                          customOptions={donutOptions}
                          series={series}
                          height={"300px"}
                        />
                        <div className="performanceChartValueBox">
                          <div className="performanceChartValue">
                            {performanceDetails?.performanceData?.performance ||
                              0}
                            %
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="col-md-6">
                    <div>
                      <p className="performanceTitle font-18">
                        {Translate("performanceReport:definitionResultLabel")}
                      </p>
                      <p>
                        {Translate("performanceReport:excellentResultLabel")}
                      </p>
                      <p>{Translate("performanceReport:goodResultLabel")}</p>
                      <p>
                        {Translate("performanceReport:satisfactoryResultLabel")}
                      </p>
                      <p>{Translate("performanceReport:avgResultLabel")}</p>
                      <p>
                        {Translate("performanceReport:belowAvgResultLabel")}
                      </p>
                    </div>
                  </div>
                  <div
                    className="col-md-12 center"
                    style={{ textAlign: "center" }}>
                    <button
                      type="submit"
                      className="btn btn-primary saveButton"
                      onClick={() =>
                        navigate("/performanceDetails", {
                          state: { _id: state?.userData?._id },
                        })
                      }>
                      {Translate("performanceReport:viewPerformanceLabel")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Loader loading={isLoading || isPerformanceLoading} />
    </>
  );
};

export default StaffPerformance;

import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { showToast } from "../../../../common/Utils/ToastMessage";
import { useEditDocumentDataMutation } from "../../../../request/Document/AddDocument";
import Translate from "../../../../translation/utils/translateFunction";
import DocumentForm from "../../components/DocumentForm";
import { InitialValues } from "../../components/constants";
import { addDocumentSchema } from "../../components/utils";

const EditMyDocument = () => {
  const formikRef = useRef(null);
  const { state } = useLocation();
  const navigate = useNavigate();

  const [filesAdded, setfilesAdded] = useState([]);
  const [images, setImages] = useState([]);
  const [deletedAttachments, setDeletedAttachments] = useState({});
  const [isTaskCreate, setIsTaskCreate] = useState(false);

  const [
    editDocumentApi,
    {
      data: documentData,
      isSuccess: isEditSuccess,
      isLoading: isEditLoading,
      isError: isEditError,
      error: errorEditDocument,
    },
  ] = useEditDocumentDataMutation();

  useEffect(() => {
    if (state) {
      InitialValues.companyId = state?.companyId[0]?._id;
      formikRef.current.setFieldValue("companyId", state?.companyId[0]?._id);
      formikRef.current.setFieldValue("title", state?.title);
      formikRef.current.setFieldValue("description", state?.description);

      if (state?.attachment?.url) {
        const { url, type } = state?.attachment;
        const obj = {
          path: decodeURIComponent(url).split("/").pop(),
          type,
          url,
        };
        setfilesAdded([...filesAdded, obj]);
        setImages([state?.attachment?.url]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);
  useEffect(() => {
    if (errorEditDocument && isEditError) {
      setIsTaskCreate(false);
      errorEditDocument.data.error
        ? errorEditDocument.data.error.length > 0 &&
          errorEditDocument.data.error.map((errorItem) => {
            if (!InitialValues.hasOwnProperty(errorItem?.param)) {
              return showToast({ message: errorItem.msg, type: "error" });
            }
            return formikRef.current?.setFieldError(
              errorItem.param,
              errorItem.msg
            );
          })
        : showToast({
            message:
              errorEditDocument?.data?.message ||
              Translate("common:unknownServerError"),
            type: "error",
          });
    }

    if (isEditSuccess && documentData) {
      showToast({
        message: documentData.message,
        type: "success",
      });
      formikRef.current.resetForm();
      setfilesAdded([]);
      setImages([]);
      formikRef.current.resetForm();
      isTaskCreate
        ? navigate("/addTask", {
            state: {
              isMyDocument: true,
              documentId: documentData?.data?._id,
              title: documentData?.data?.title,
              description: documentData?.data?.description,
              attachment: documentData?.data?.attachment,
              company: documentData?.data?.companyId[0],
            },
          })
        : navigate("/documentRepository/myDocuments");
      setIsTaskCreate(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentData, errorEditDocument, isEditError]);

  const onSubmit = (formValues) => {
    const { companyId, title, description } = formValues;

    let reqObj = {
      companyId: [companyId],
      title,
      description,
    };

    let b64Array;
    let typeFile;

    if (deletedAttachments?.url) {
      reqObj = {
        ...reqObj,
        deletedAttachments,
        isAttachment: false,
      };
    }

    if (filesAdded.length) {
      b64Array = filesAdded[0]?.b64;
      typeFile = filesAdded[0].type;

      if (b64Array) {
        const { documentFileName, documentFileExt } = filesAdded[0];

        let fileSize = filesAdded[0].size;
        let convertedFileSize = fileSize.toString();

        reqObj = {
          ...reqObj,
          webAttachment: {
            url: b64Array,
            type: typeFile,
            documentFileName,
            documentFileExt,
          },
          size: convertedFileSize,
          isAttachment: true,
        };
      } else {
        reqObj = {
          ...reqObj,
          isAttachment: true,
          attachment: state?.attachment,
        };
      }
    }

    editDocumentApi({
      body: reqObj,
      id: state?._id,
    });
  };

  return (
    <Formik
      initialValues={InitialValues}
      innerRef={formikRef}
      validateOnMount
      onSubmit={onSubmit}
      validationSchema={addDocumentSchema}>
      {({ values, handleSubmit }) => {
        return (
          <DocumentForm
            isEdit
            values={values}
            handleSubmit={handleSubmit}
            filesAdded={filesAdded}
            images={images}
            setfilesAdded={setfilesAdded}
            setDeletedAttachments={setDeletedAttachments}
            setImages={setImages}
            setCreateTask={setIsTaskCreate}
            isLoading={isEditLoading}
            documentData={state}
          />
        );
      }}
    </Formik>
  );
};

export default EditMyDocument;

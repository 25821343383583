import { Stack, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { StyledTypography } from "../../../common/Utils/StyledComponents";
import Translate from "../../../translation/utils/translateFunction";
import { colors } from "../../../common/Theme/Colors";
import IconifyIcon from "../../Icon";
import Dropzone from "../../../components/CommonFileUpload/Dropzone";
import CommonImage from "../../ImageGallery/CommonImage";
import { showToast } from "../../../common/Utils/ToastMessage";
import { validateDuplicateFiles } from "../../../common/Utils/commonFunction";
import VoiceNotes from "../../VoiceNotes/Components/VoiceNotes";
import NoDataFound from "../../ManageTask/NoDataFound";
import { handleEventKeySubmit } from "../../../common/Utils/commonFunction";
import { useSelector } from "react-redux";

const AddMomRenderer = ({
  pointArray,
  setPointArray,
  filesAdded,
  setFilesAdded,
  imagesArray,
  voiceNote,
  setVoiceNote,
  isEdit = false,
}) => {
  const { validations } = useSelector((state) => state.formanagement);
  const acceptedFileType = {
    "image/png": [".png", ".jpg", ".jpeg", ".pdf", ".xls", ".xlsx"],
  };
  const [showPointInput, setShowPointInput] = useState(false);
  const [point, setPoint] = useState();
  const [images, setImages] = useState(imagesArray || []);
  const openPointInput = () =>
    pointArray.length < 10
      ? setShowPointInput(true)
      : showToast({ message: Translate("momPage:maxfilemsg"), type: "info" });
  const hidePointInput = () => setShowPointInput(false);
  const onSavePoint = () => {
    if (!!point?.length) {
      const trimmedVal = point.trim();
      trimmedVal?.length && setPointArray([...pointArray, trimmedVal]);
      setPoint("");
      hidePointInput();
    }
  };
  const removeFile = (file) => {
    const deletedFiles = filesAdded.findIndex(
      (fileName) => fileName.path === file.path
    );
    if (deletedFiles !== -1) {
      filesAdded.splice(deletedFiles, 1);
      setFilesAdded([...filesAdded]);
    }
  };
  useEffect(() => {
    if (filesAdded.length > 0) {
      const newImages = filesAdded.map((imageElement) =>
        imageElement?.preview ? imageElement?.preview : imageElement?.url
      );
      setImages(newImages);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filesAdded]);

  const addFiles = (file) => {
    const url =
      file.type === "image/png" ||
      file.type === "image/jpg" ||
      file.type === "image/jpeg"
        ? URL.createObjectURL(file)
        : null;

    const checkDuplicates = validateDuplicateFiles(file, filesAdded);

    if (checkDuplicates?.isDuplicate) {
      showToast({
        message: `File ${checkDuplicates?.fileName} ${Translate(
          "common:duplicateFileError"
        )}`,
        type: "error",
      });
      return;
    }

    if (url) {
      file.preview = url;
    }
    if (filesAdded.length <= 5) {
      filesAdded.push(file);
      setFilesAdded([...filesAdded]);
    } else {
      filesAdded.pop(file);
    }
  };
  const renderPointView = (value, idx) => (
    <Stack
      flexDirection={"row"}
      alignItems={"center"}
      justifyContent={"space-between"}
      px={3}
      py={1}>
      <Stack flexDirection={"row"} alignItems={"center"} width={"95%"}>
        <div
          style={{
            width: "5px",
            height: "5px",
            borderRadius: "2.5px",
            marginRight: "10px",
            backgroundColor: colors.black,
          }}
        />
        <Stack flexDirection={"row"} alignItems={"center"} width={"90%"}>
          <StyledTypography fontWeight={500} maxWidth={"100%"} color={colors.black}>
            {value}
          </StyledTypography>
        </Stack>
      </Stack>
      <IconifyIcon
        icon={"ic:outline-close"}
        className="cursor-pointer"
        fontSize="22"
        onClick={() => {
          pointArray.splice(idx, 1);
          setPointArray([...pointArray]);
        }}
      />
    </Stack>
  );

  return (
    <Stack>
      <Stack>
        {pointArray.map((item, idx) => renderPointView(item, idx))}
        {showPointInput ? (
          <Stack flexDirection={"row"} alignItems={"center"} py={1}>
            <TextField
              value={point}
              placeholder={Translate("momPage:addpoint")}
              onChange={(e) => setPoint(e.target.value)}
              autoComplete="off"
              autoFocus={true}
              style={{ width: "85%" }}
              inputProps={{
                maxLength: validations?.companyContactName.MAX,
              }}
              onKeyDown={(e) => handleEventKeySubmit(e, onSavePoint)}
            />
            <Stack
              flexDirection={"row"}
              alignItems={"center"}
              justifyContent={"space-around"}
              width={"15%"}>
              <IconifyIcon
                icon={"ic:baseline-check"}
                fontSize="24"
                color={point?.length && colors.green_003}
                className="cursor-pointer"
                onClick={onSavePoint}
              />
              <IconifyIcon
                icon={"ic:outline-close"}
                className="cursor-pointer"
                fontSize="24"
                onClick={hidePointInput}
              />
            </Stack>
          </Stack>
        ) : (
          <Stack
            flexDirection={"row"}
            alignItems={"center"}
            className="cursor-pointer"
            width={"fit-content"}
            onClick={openPointInput}
            py={1}>
            <i
              className="fa fa-plus-square-o subtaskIcon disabled"
              data-toggle="tooltip"
              style={{
                color:
                  pointArray?.length >= 10
                    ? colors.primary_002
                    : colors.primary,
              }}
              title="fa fa-plus-square-o"
            />
            <StyledTypography
              fontWeight={600}
              color={
                pointArray?.length >= 10 ? colors.primary_002 : colors.primary
              }
              px={1}>
              {Translate("momPage:addpoint")}
            </StyledTypography>
          </Stack>
        )}
      </Stack>
      {isEdit && (
        <Stack className="display" py={1}>
          <StyledTypography className="taskHeading font-16" pt={3}>
            {Translate("manageTask:voiceNoteTitle")}
          </StyledTypography>
          <Stack
            className="CommentCard_spacing"
            sx={{ backgroundColor: "#e9ecef" }}
            p={3}>
            {voiceNote?.src || voiceNote?.timeLength ? (
              <Stack
                flexDirection={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}>
                <div style={{ width: 450 }}>
                  <VoiceNotes
                    voiceObj={voiceNote}
                    className={"voiceNote_spacing"}
                  />
                </div>
                <IconifyIcon
                  icon={"ic:outline-close"}
                  className="cursor-pointer"
                  fontSize="22"
                  onClick={() => setVoiceNote?.({})}
                />
              </Stack>
            ) : (
              <NoDataFound
                message={Translate("manageTask:noVoiceNote")}
                isCenter
                className="NoDataStyle"
              />
            )}
          </Stack>
        </Stack>
      )}
      <Stack className="display" py={1}>
        <StyledTypography className="taskHeading font-16" pt={3} pb={1}>
          {Translate("manageTask:attacheMenttitle")}
        </StyledTypography>
        <Dropzone
          translate={Translate("addTask:attach")}
          icon={"icon-paper-clip"}
          onfileSelect={(filesArray) => addFiles(filesArray)}
          acceptedImages={acceptedFileType}
          maximumFiles={5}
          maxFileSize={52428800}
          fileLength={filesAdded.length}
          disabled={filesAdded.length >= 5}
        />
      </Stack>
      <div className="AttachmentBoxStyling">
        {filesAdded.length > 0 ? (
          <CommonImage
            images={images}
            files={filesAdded}
            removeFile={removeFile}
          />
        ) : (
          isEdit && (
            <NoDataFound
              message={Translate("manageTask:noAttachmentMsg")}
              isCenter
              className="NoDataStyle"
            />
          )
        )}
      </div>
    </Stack>
  );
};
export default AddMomRenderer;

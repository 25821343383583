import * as yup from "yup";
import Translate from "../../translation/utils/translateFunction";

export const addCompanySchema = () => {
  const schema = yup.object().shape({
    name: yup.string().trim().required(Translate("addCompany:nameError")),
    website: yup.string().trim().required(Translate("addCompany:urlError")),
    contact: yup
      .string()
      .trim()
      .required(Translate("addCompany:contactNumberError")),
    officeTelephone: yup
      .string()
      .trim()
      .required(Translate("addCompany:numberError_2")),

    address: yup.string().trim().required(Translate("addCompany:addressError")),
    country: yup.string().trim().required(Translate("addManager:countryError")),
    state: yup.string().trim().required(Translate("addManager:stateError")),
  });

  return schema;
};

import Translate from "../../../translation/utils/translateFunction";

export const InitialValues = {
  companyId: "",
  name: "",
  documentCategory: "",
  title: "",
  registrationDate: "",
  expiryDate: "",
  attachment: "",
  isNotExpiry: false,
};

export const docCategory = [
  {
    label: "License",
    value: "License",
  },
  {
    label: "Assets",
    value: "Assets",
  },
  {
    label: "Other",
    value: "Other",
  },
];

export const checkBoxOption = [Translate("renewals:renewalCheckBox")];

export const sortingOptions = [
  {
    label: Translate("renewals:default"),
    value: "default",
  },
  {
    label: Translate("renewals:atoz"),
    value: "AtoZ",
  },
];

import Translate from "../../../../translation/utils/translateFunction";
import {
  CustomTitleCellRender,
  CustomTagCellRender,
} from "../../../common/CustomCellRender";

export const getWorkloadReportColumns = (
  customActionCellRender,
  customSrCellRender
) => {
  const columns = [
    {
      field: "serialNumber",
      headerName: Translate("common:srNoTitle"),
      align: "center",
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      renderCell: customSrCellRender,
    },
    {
      field: "name",
      headerName: Translate("performanceReport:name"),
      flex: 0.6,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => CustomTitleCellRender(params?.row?.name),
    },
    {
      field: "companyName",
      headerName: Translate("performanceReport:companyName"),
      flex: 0.5,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => CustomTitleCellRender(params?.row?.companyName),
    },
    {
      field: "WorkloadDetails.totalAssigned",
      headerName: Translate("performanceReport:totalAssigned"),
      sortable: false,
      headerAlign: "center",
      align: "center",
      flex: 0.3,
      disableColumnMenu: true,
      renderCell: (params) =>
        CustomTagCellRender(
          params?.row?.WorkloadDetails?.totalAssigned,
          "tag-theme-blue"
        ),
    },
    {
      field: "WorkloadDetails.inProgress",
      headerName: Translate("performanceReport:inprogress"),
      flex: 0.3,
      headerAlign: "center",
      align: "center",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) =>
        CustomTagCellRender(
          params?.row?.WorkloadDetails?.inProgress,
          "tag-azure"
        ),
    },
    {
      field: "WorkloadDetails.overDue",
      headerName: Translate("performanceReport:overdue"),
      sortable: false,
      align: "center",
      headerAlign: "center",
      flex: 0.2,
      disableColumnMenu: true,
      renderCell: (params) =>
        CustomTagCellRender(
          params?.row?.WorkloadDetails?.overDue,
          "tag-orange"
        ),
    },
    {
      field: "WorkloadDetails.free",
      headerName: Translate("workloadReport:free"),
      sortable: false,
      flex: 0.2,
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
      renderCell: (params) =>
        CustomTagCellRender(
          params?.row?.WorkloadDetails?.free + "%",
          "tag-gray"
        ),
    },
    {
      field: "WorkloadDetails.occupied",
      headerName: Translate("workloadReport:occupied"),
      sortable: false,
      flex: 0.3,
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
      renderCell: (params) =>
        CustomTagCellRender(
          params?.row?.WorkloadDetails?.occupied + "%",
          "tag-theme-light-blue"
        ),
    },
    {
      field: "Action",
      headerName: Translate("common:actionTitle"),
      headerAlign: "center",
      align: "center",
      flex: 0.2,
      sortable: false,
      disableColumnMenu: true,
      renderCell: customActionCellRender,
    },
  ];
  return columns;
};

export default {
  head: "رسالة صوتيه ",
  myVoiceNotes: "رسائلي الصوتيه",
  sharedWithMe: "التى تم مشاركتها معي",
  share: "مشاركة ",
  setReminder: "ضبط تذكير",
  sharedByMe: "تم مشاركتها من قِبلي",
  deleteAlert: "هل أنت متأكد ، تريد حذف الرسالة الصوتيه ؟",
  addVoiceNote: "أضف رسالة صوتيه",
  title: "العنوان",
  titleError: "العنوان غير صالح",
  VoiceRec: "تسجيل الرسائل الصوتيه",
  errorMessage: "العنوان غير صالح",
  searchError: "المسافات أو الأحرف الخاصة غير مسموح بها",
  minError: "يتطلب 3 أحرف على الأقل",
  srNoTableHeading: "Sr.no",
  voicenoteTitle: "العنوان",
  voiceNote: "رسالة صوتيه",
  dateTime: "التاريخ و الوقت",
  duration: "مدة",
  deleteAlertMsg: "هل أنت متأكد ، تريد حذف الرسالة الصوتيه ؟",
  createdByOwn: "أنشئت بواسطة المالك",
  createdByPA: "أنشئت بواسطة المساعد الشخصي",
  apiFailedMessage: "فشل في جلب البيانات",
  requiredValidation: "Title is required",
  reminderpageHeading: "ضبط التذكير",
  reminderFor: "تذكير ب",
  reminderName: "عنوان التذكير",
  remindTo: "تذكير لِ",
  date: "تاريخ",
  time: "وقت",
  startTime: "وقت البدء",
  reminder: "تذكير",
  addReminder: "اختر تذكير",
  reminderForRequired: "التذكير حقل مطلوب",
  reminderTypeRequired: "نوع التذكير مطلوب",
  accesslogsSrno: "الأب رقم",
  accesslogsName: "اسم المستخدم",
  accesslogsDesignation: "الوظيفة",
  accesslogsCompanyName: "اسم الشركة",
  accesslogsDateTime: "التاريخ و الوقت",
  renameTitle: "إعادة تسمية الملاحظة الصوتية",
  name: "الإسم",
  sharedBy: "نشر بواسطة",
  srNo: "الأب رقم",
  userName: "اسم المستخدم",
  designation: "الوظيفة",
  companyName: "اسم الشركة",
  DateTime: "التاريخ و الوقت",
  customReminderHeading: "تذكير مخصص",
  reminderTimeLabel: "أدخل القيمة",
  timeError: "لا يمكن ضبط التذكير للوقت الماضي",
  reminderTimeMessageErrorVoiceNotes: "يرجى تقديم القيم بين",
  reminderTimeError: "يجب أن يكون وقت التذكير رقمًا",
  minutes: "دقائق",
  hours: "ساعات",
  days: "أيام",
  weeks: "أسابيع",
  to: "ل",
  modalTitle: "مشاركة ل",
  searchPlaceHolder: "ابحث عن شيء...",
  save: "حفظ",
  closeCustomReminderButtonLabel: "الغاء",
  editIcon: "إعادة تسمية",
  deleteIcon: "حذف",
  shareIcon: "مشاركة",
  reminderIcon: "تذكير",
  accesslogsIcon: "سجلات الدخول",
  OWN: "أنشئت بواسطة المالك",
  PA: "أنشئت بواسطة المساعد/ة الشخصي",
  reminderToRequired: "التذكير به مطلوب",
};

export const getInboxListColumns = (
  customCellRender,

  customLabelCellRender
) => {
  const columns = [
    {
      field: "contactName",
      headerName: "Name",
      flex: 0.99,
      sortable: true,
      disableColumnMenu: true,
      renderCell: customCellRender,
    },

    {
      field: "labelAs",
      headerName: "Names",
      flex: 0.01,

      sortable: true,
      disableColumnMenu: true,
      renderCell: customLabelCellRender,
    },
  ];
  return columns;
};

import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useDeleteEventMutation,
  useLazyEventDetailsQuery,
} from "../../../request/Calendar";
import Translate from "../../../translation/utils/translateFunction";
import AlertPermission from "../../AlertPermission";
import {
  InitialEventRecurrence,
  InitialValues,
} from "../CreateEvent/constants";
import { CreateEvenetValidationSchema } from "../CreateEvent/validation";
import CreateEventForm from "../components/CreateEventform";

const ViewEvent = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [timeZoneValue, setTimezoneValue] = useState();
  const [privateToggle, setPrivateToggle] = useState(false);
  const [showdeleteModal, setShowDeleteModal] = useState(false);
  const [eventRecurrence, setEventRecurrence] = useState(
    InitialEventRecurrence
  );
  const [inviteeMembers, setInviteeMembers] = useState([]);
  const [
    getEventDetail,
    {
      data: eventDetailData,
      isLoading: isEventDetailLoading,
      isFetching: isEventDetailfetching,
    },
  ] = useLazyEventDetailsQuery();
  const [
    deleteEvent,
    { data: serverResponse, isLoading: isDeleteEventLoading, isSuccess },
  ] = useDeleteEventMutation();

  useEffect(() => {
    state?.eventId && getEventDetail(state?.eventId);
  }, [state]);

  const onDelete = () => setShowDeleteModal(true);
  const hideDeleteModal = () => setShowDeleteModal(false);
  const onDeleteEvent = () => {
    deleteEvent({ eventId: state?.eventId });
    hideDeleteModal();
  };
  useEffect(() => {
    if (isSuccess && serverResponse?.data) {
      if (state?.isMOM) {
        return navigate("/mom/myevent");
      }

      return navigate(-1);
    }
  }, [isSuccess]);
  const OnAddMOM = () =>
    navigate("/calendar/addMom", { state: { data: eventDetailData?.data } });
  return (
    <Formik
      initialValues={InitialValues}
      validateOnMount
      validationSchema={CreateEvenetValidationSchema}>
      {({ handleSubmit }) => {
        return (
          <>
            <CreateEventForm
              handleSubmit={handleSubmit}
              isView
              eventData={eventDetailData?.data}
              setPrivateToggle={setPrivateToggle}
              privateToggle={privateToggle}
              isLoading={
                isEventDetailLoading ||
                isEventDetailfetching ||
                isDeleteEventLoading
              }
              setEventRecurrence={setEventRecurrence}
              eventRecurrence={eventRecurrence}
              onDelete={onDelete}
              setInviteeList={setInviteeMembers}
              eventInviteeList={inviteeMembers}
              setTimezoneValue={setTimezoneValue}
              timeZoneValue={timeZoneValue}
              OnAddMOM={OnAddMOM}
              currentState={state}
            />
            <AlertPermission
              show={showdeleteModal}
              subTitle={Translate("createEvent:alert")}
              okTitle={Translate("common:delete")}
              onOk={onDeleteEvent}
              onCanel={hideDeleteModal}
            />
          </>
        );
      }}
    </Formik>
  );
};
export default ViewEvent;

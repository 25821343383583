import * as yup from "yup";
import Translate from "../../../translation/utils/translateFunction";
import { customReminderType } from "./constants";

export const AddCustomReminderSchema = (isRemindToRequired) => {
  const schema = yup.object().shape({
    reminderFor: yup
      .string()
      .trim()
      .required(Translate("VoiceNotes:reminderForRequired")),
    reminder: yup
      .string()
      .trim()
      .required(Translate("VoiceNotes:reminderTypeRequired")),
    remindTo: isRemindToRequired
      ? yup.string().trim().required(Translate("VoiceNotes:reminderToRequired"))
      : yup.string().trim(),
    time: yup
      .number()
      .nullable()
      .typeError(Translate("VoiceNotes:reminderTimeError"))
      .when("timeInterval", (timeInterval, schema) => {
        let message = Translate(
          "VoiceNotes:reminderTimeMessageErrorVoiceNotes"
        );
        let minutes = Translate("VoiceNotes:minutes");
        let hours = Translate("VoiceNotes:hours");
        let days = Translate("VoiceNotes:days");
        let weeks = Translate("VoiceNotes:weeks");
        // let to = Translate("VoiceNotes:to");

        if (timeInterval === customReminderType?.MINUTES_BEFORE) {
          message = `${message} 1 to 60 ${minutes}`;
          return schema.min(1, message).max(60, message);
        } else if (timeInterval === customReminderType?.HOURS_BEFORE) {
          message = `${message} 1 to 24 ${hours}`;
          return schema.min(1, message).max(24, message);
        } else if (timeInterval === customReminderType?.DAYS_BEFORE) {
          message = `${message} 1 to 31 ${days}`;
          return schema.min(1, message).max(31, message);
        } else if (timeInterval === customReminderType?.WEEK_BEFORE) {
          message = `${message} 1 to 5 ${weeks}`;
          return schema.min(1, message).max(5, message);
        }
      }),
  });

  return schema;
};

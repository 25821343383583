import React from "react";
import { useSelector } from "react-redux";
import { StyledTypography } from "../../../../common/Utils/StyledComponents";
import Translate from "../../../../translation/utils/translateFunction";
import FormikDatePicker from "../../../FormikForm/component/FormikDatePicker";
import { FormikPhoneInput } from "../../../FormikForm/component/FormikPhoneInput";
import { FormikTextField } from "../../../FormikForm/component/FormikTextField";
import RadioButtons from "../../../RadioButtons";
import { radioOptions } from "./constants";
import { ReactComponent as LocationIcon } from "../../../../assets/images/Location.svg";
import FormikSearchableDropDown from "../../../FormikForm/component/FormikSearchableDropDown";
import { FormikPhoneInputWithExtField } from "../../../FormikForm/component/FormikPhoneInputWithExt";
import { FormikDropdownPicker } from "../../../FormikForm/component/FormikDropDownPicker";

const EmployeeBasicForm = ({
  isView,
  values,
  setFieldValue,
  allCompanyData,
  employeeData,
  isEdit,
  isAdd,
  requestTo,
  setrequestTo,
  setReporter,
  setOnSelect,
  requestToList,
  setReportToSearch,
  setReportTo,
  setSelectedCompany,
  selectedCompany,
  resetOnCompanyChange,
  setSelectedCompanyLabel,
}) => {
  const { validations } = useSelector((state) => state?.formanagement);
  const currentLocationData = (places, addressType) => {
    if (addressType) {
      setFieldValue("resAddress", places.formatted_address);
      setFieldValue("resLat", places.geometry.location.lat());
      setFieldValue("resLng", places.geometry.location.lng());
      places.address_components.map((ele) => {
        if (ele.types[0] === "country") {
          setFieldValue("resCountry", ele.long_name);
        }
        if (ele.types[0] === "administrative_area_level_1") {
          setFieldValue("resState", ele.long_name);
        }
        if (ele.types[0] === "locality") {
          setFieldValue("resCity", ele.long_name);
        }
        if (ele.types[0] === "postal_code") {
          setFieldValue("resZipCode", ele.long_name);
        } else {
          setFieldValue("resZipCode", "");
        }
      });
    } else {
      setFieldValue("workAddress", places.formatted_address);
      setFieldValue("workLat", places.geometry.location.lat());
      setFieldValue("workLng", places.geometry.location.lng());
      places.address_components.map((ele) => {
        if (ele.types[0] === "country") {
          setFieldValue("workCountry", ele.long_name);
        }
        if (ele.types[0] === "administrative_area_level_1") {
          setFieldValue("workState", ele.long_name);
        }
        if (ele.types[0] === "locality") {
          setFieldValue("workCity", ele.long_name);
        }
        if (ele.types[0] === "postal_code") {
          setFieldValue("workZipCode", ele.long_name);
        } else {
          setFieldValue("workZipCode", "");
        }
      });
    }
  };

  return (
    <>
      <div className="row">
        <div className="form-group col-md-4 col-sm-12">
          <FormikDropdownPicker
            options={allCompanyData}
            value={selectedCompany}
            name="companyId"
            label={Translate("addEmployee:company")}
            placeholder={Translate("addEmployee:company")}
            onSelect={(value, label) => {
              setSelectedCompanyLabel && setSelectedCompanyLabel(label);
              setSelectedCompany(value);
              value !== selectedCompany && resetOnCompanyChange();
            }}
            disabled={isView || isEdit}
            required={isAdd || isEdit}
            autoFocus={isAdd}
          />
        </div>
        <div className="form-group col-md-4 col-sm-12">
          <FormikTextField
            name="name"
            label={Translate("addEmployee:name")}
            placeholder={Translate("addEmployee:name")}
            autoComplete={"off"}
            maxLength={validations?.nameLength.MAX}
            disabled={isView}
            required={!isView}
          />
        </div>
        <div className="form-group col-md-4 col-sm-12">
          <FormikDatePicker
            label={Translate("addEmployee:dob")}
            containerStyle="datePickerContainer"
            className="form-control bw-0 "
            format="yyyy-MM-dd"
            name="dob"
            value={values?.dob}
            onChange={(value) => setFieldValue("dob", value)}
            disabled={isView}
            required={!isView}
          />
        </div>
      </div>
      <div className="row">
        <div className="form-group col-md-4 col-sm-12">
          <FormikTextField
            name="designation"
            label={Translate("addEmployee:designation")}
            placeholder={Translate("addEmployee:designation")}
            autoComplete={"off"}
            maxLength={validations?.designationLength.MAX}
            disabled={isView}
            required={!isView}
          />
        </div>
        <div className="form-group col-md-4 col-sm-12">
          <FormikTextField
            name="department"
            label={Translate("addEmployee:department")}
            placeholder={Translate("addEmployee:department")}
            autoComplete={"off"}
            maxLength={validations?.departmentLength.MAX}
            disabled={isView}
            required={!isView}
          />
        </div>
        <div className="form-group col-md-4 col-sm-12">
          <FormikTextField
            name="email"
            label={Translate("addEmployee:workEmail")}
            placeholder={Translate("addEmployee:workEmail")}
            autoComplete={"off"}
            disabled={isView}
            required={!isView}
          />
        </div>
      </div>
      <div className="row">
        <div
          className="form-group col-md-4 col-sm-12"
          style={{ marginTop: "0.3rem" }}>
          <FormikSearchableDropDown
            selected={requestTo}
            onSelect={(value) => {
              setrequestTo(value);
              setFieldValue("reportTo", value.id);

              setReporter({
                id: value.id,
                name: value.name,
                profileUrl: value.profileUrl,
                role: value.role,
              });
              setReportTo({
                id: value.id,
                name: value.name,
                profileUrl: value.profileUrl,
                role: value.role,
              });
              setOnSelect(true);
            }}
            options={requestToList}
            onChange={(text) => setReportToSearch(text.trim())}
            placeholder={Translate("addManager:reportTo")}
            label={Translate("addManager:reportTo")}
            name="reportTo"
            required
            disabled={isView}
          />
        </div>
        <div className="form-group col-md-4 col-sm-12">
          {isView ? (
            <FormikTextField
              name="mobile"
              value={employeeData?.login?.mobileWithCountryCode}
              label={Translate("addEmployee:contactNumber")}
              placeholder={Translate("addEmployee:contactNumberPlaceHolder")}
              autoComplete={"off"}
              maxLength={validations?.contactPhoneLength.MAX}
              disabled={true}
            />
          ) : (
            <FormikPhoneInput
              label={Translate("addEmployee:contactNumber")}
              name="mobile"
              value={values?.mobile}
              placeholder={Translate("addEmployee:contactNumberPlaceholder")}
              maxLength={validations?.contactPhoneLength.MAX}
              onChange={(value, country) => {
                setFieldValue("mobile", value);
                setFieldValue(
                  "mobileShortCode",
                  country?.countryCode?.toUpperCase()
                );
                setFieldValue("countryCode", country?.dialCode);
              }}
              autoComplete={"off"}
              required={isAdd || isEdit}
              disabled={isView}
              isLogin
            />
          )}
        </div>
        <div className="form-group col-md-4 col-sm-12">
          {isView ? (
            <FormikTextField
              name="alternateMobile"
              value={
                employeeData?.alternateMobileWithCountryCode &&
                !employeeData?.alternateMobileWithCountryCode.startsWith(
                  "undefined"
                )
                  ? employeeData?.alternateMobileWithCountryCode
                  : "NA"
              }
              label={Translate("addEmployee:alternateNumber")}
              placeholder={Translate("addEmployee:contactNumberPlaceholder")}
              autoComplete={"off"}
              maxLength={validations?.contactPhoneLength.MAX}
              disabled={true}
            />
          ) : (
            <FormikPhoneInput
              label={Translate("addEmployee:alternateNumber")}
              name="alternateMobile"
              value={values?.alternateMobile}
              placeholder={Translate("addEmployee:contactNumberPlaceholder")}
              maxLength={validations?.contactPhoneLength.MAX}
              onChange={(value, country) => {
                setFieldValue("alternateMobile", value);
                setFieldValue(
                  "alternateMobileShortCode",
                  country?.countryCode?.toUpperCase()
                );
                setFieldValue("alternateMobileCountryCode", country?.dialCode);
              }}
              autoComplete={"off"}
              disabled={isView}
              isLogin
            />
          )}
        </div>
      </div>
      <div className="row">
        <div className="form-group col-md-4 col-sm-12">
          {isView ? (
            <FormikTextField
              name="hrMobile"
              value={
                employeeData?.hrMobileWithCountryCode &&
                !employeeData?.hrMobileWithCountryCode.startsWith("undefined")
                  ? employeeData?.hrMobileWithCountryCode
                  : "NA"
              }
              label={Translate("addEmployee:hrContact")}
              placeholder={Translate("addEmployee:extensionNumberPlaceholder")}
              autoComplete={"off"}
              maxLength={validations?.contactPhoneLength.MAX}
              disabled={true}
            />
          ) : (
            <FormikPhoneInput
              label={Translate("addEmployee:hrContact")}
              name="hrMobile"
              value={values?.hrMobile}
              placeholder={Translate("addEmployee:extensionNumberPlaceholder")}
              maxLength={validations?.contactPhoneLength.MAX}
              onChange={(value, country) => {
                setFieldValue("hrMobile", value);
                setFieldValue(
                  "hrMobileShortCode",
                  country?.countryCode?.toUpperCase()
                );
                setFieldValue("hrMobileCountryCode", country?.dialCode);
              }}
              autoComplete={"off"}
              disabled={isView}
              isLogin
            />
          )}
        </div>

        <div className="form-group col-md-4 col-sm-12">
          <FormikPhoneInputWithExtField
            name="companyNumber"
            extName="companyExtension"
            label={Translate("addEmployee:extensionNumber")}
            placeholder={Translate("addEmployee:extensionNumberPlaceholder")}
            extPlaceholder={Translate(
              "addEmployee:companyPhoneExtensionPlaceholder"
            )}
            autoComplete={"off"}
            maxLength={validations?.telephoneLength.MAX}
            extMaxLength={validations?.companyExtension.MAX}
            disabled={isView}
          />
        </div>
      </div>
      <div className="row">
        <div className="form-group col-md-12 col-sm-12">
          <div className="AddressLabel">
            <StyledTypography variant="subtitle1">
              {Translate("addEmployee:address")}
              {!isView && (
                <StyledTypography sx={{ color: "red" }} component="span">
                  {" *"}
                </StyledTypography>
              )}
            </StyledTypography>
          </div>
          <FormikTextField
            name="resAddress"
            placeholder={Translate("addEmployee:residenceAddressPlaceholder")}
            autoComplete={"off"}
            isLocation={true}
            RenderRightContainer={
              !isView && <LocationIcon className="formikLocationIcon" />
            }
            locationValue={(currentLocation) => {
              currentLocationData(currentLocation, true);
            }}
            disabled={isView}
          />
        </div>
      </div>
      <div className="row">
        <div className="form-group col-md-12 col-sm-12">
          <FormikTextField
            name="resFlat"
            placeholder={Translate("addEmployee:residenceAddressPlaceholder2")}
            autoComplete={"off"}
            maxLength={validations?.landmarkLimit.MAX}
            disabled={isView}
          />
        </div>
      </div>
      <div className="row">
        <div className="form-group col-md-4 col-sm 8">
          <FormikTextField
            name="resCountry"
            disabled={isView}
            placeholder={Translate("addEmployee:countryPlaceholder")}
            maxLength={validations?.cityLimit.MAX}
          />
        </div>
        <div className="form-group col-md-4 col-sm 8">
          <FormikTextField
            name="resState"
            disabled={isView}
            placeholder={Translate("addEmployee:statePlaceholder")}
            maxLength={validations?.cityLimit.MAX}
          />
        </div>
      </div>
      <div className="row">
        <div className="form-group col-md-4 col-sm 8">
          <FormikTextField
            name="resCity"
            placeholder={Translate("addEmployee:city")}
            maxLength={validations?.cityLimit.MAX}
            disabled={isView}
          />
        </div>
        <div className="form-group col-md-4 col-sm 8">
          <FormikTextField
            name="resZipCode"
            maxLength={6}
            placeholder={Translate("addEmployee:zipcode")}
            disabled={isView}
          />
        </div>
      </div>

      <div className="form-group">
        <div className="AddressLabel">
          <StyledTypography variant="subtitle1">
            {Translate("addEmployee:workAddress")}
            {!isView && (
              <StyledTypography sx={{ color: "red" }} component="span">
                {" *"}
              </StyledTypography>
            )}
          </StyledTypography>
        </div>

        <FormikTextField
          name="workAddress"
          placeholder={Translate("addEmployee:workAddressPlaceholder")}
          autoComplete={"off"}
          isLocation={true}
          RenderRightContainer={
            !isView && <LocationIcon className="formikLocationIcon" />
          }
          locationValue={(currentLocation) => {
            currentLocationData(currentLocation, false);
          }}
          disabled={isView}
        />
      </div>
      <div className="row">
        <div className="form-group col-md-12 col-sm-12">
          <FormikTextField
            name="workFlat"
            placeholder={Translate("addEmployee:workAddressPlaceholder1")}
            autoComplete={"off"}
            maxLength={validations?.landmarkLimit.MAX}
            disabled={isView}
          />
        </div>
      </div>
      <div className="row">
        <div className="form-group col-md-4 col-sm 8">
          <FormikTextField
            name="workCountry"
            disabled={isView}
            placeholder={Translate("addEmployee:countryPlaceholder")}
            maxLength={validations?.cityLimit.MAX}
          />
        </div>
        <div className="form-group col-md-4 col-sm 8">
          <FormikTextField
            name="workState"
            disabled={isView}
            placeholder={Translate("addEmployee:statePlaceholder")}
            maxLength={validations?.cityLimit.MAX}
          />
        </div>
      </div>
      <div className="row">
        <div className="form-group col-md-4 col-sm 8">
          <FormikTextField
            name="workCity"
            placeholder={Translate("addEmployee:city")}
            maxLength={validations?.cityLimit.MAX}
            disabled={isView}
          />
        </div>
        <div className="form-group col-md-4 col-sm 8">
          <FormikTextField
            name="workZipCode"
            maxLength={6}
            placeholder={Translate("addEmployee:zipcode")}
            disabled={isView}
          />
        </div>
      </div>
      <div className="row">
        <div className="form-group col-md-4 col-sm 8">
          {(isAdd || isEdit || (isView && employeeData?.gender)) && (
            <RadioButtons
              radioOptions={radioOptions}
              defaultChecked={employeeData?.gender === "FEMALE" ? 1 : 0}
              label={Translate("addEmployee:gender")}
              onPress={(value) => {
                setFieldValue("gender", value.target.value);
              }}
              disabled={isView}
              required={!isView}
            />
          )}
        </div>
      </div>
    </>
  );
};
export default EmployeeBasicForm;

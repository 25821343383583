import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import SubtaskForm from "../Components/SubtaskForm";
import { AddSubtaskSchema } from "../Components/validations";
import { InitialValues } from "../Components/constants";
import Translate from "../../../../translation/utils/translateFunction";
import moment from "moment";
import { useAddSubTaskMutation } from "../../../../request/Task";
import { showToast } from "../../../../common/Utils/ToastMessage";
import { moduleType } from "../../Components/constants";
import {
  getNameAndExtFromFile,
  getMoment,
  getDayJs,
} from "../../../../common/Utils/commonFunction";
import { DateTimeFormats } from "../../../../common/Utils/DateFormater";
import dayjs from "dayjs";
import { getProjectTitle } from "../../Components/utils";

const AddSubTask = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const formikRef = useRef(null);

  const [selectedCompany, setSelectedCompany] = useState("");
  const [filesAdded, setFilesAdded] = useState([]);
  const [images, setImages] = useState([]);
  const [startDateTZ, setStartDateTZ] = useState("");
  const [dueDateTZ, setDueDateTZ] = useState("");

  const [
    addSubtaskApi,
    {
      data: addSubtaskData,
      isLoading: isSubtaskLoading,
      isSuccess: addSubtaskSuccess,
      isError: isSubtaskError,
      error: addSubtaskError,
    },
  ] = useAddSubTaskMutation();

  let reg2 = /[a-zA-Z]/;

  useEffect(() => {
    if (state?.isAddTask || state?.isTaskDetails) {
      const { company, title, projectId, projectTitle, projectNumber } =
        state?.data;
      formikRef.current.setFieldValue("parentTitle", title);
      formikRef.current.setFieldValue("projectId", projectId);
      formikRef.current.setFieldValue(
        "projectTitle",
        state?.projectData
          ? state?.projectData?.label
          : getProjectTitle(projectId, projectTitle, projectNumber)
      );
      const updatedStartDate = InitialValues.startDate;
      const updatedDueDate = InitialValues.dueDate;
      setStartDateTZ(getMoment(updatedStartDate).toISOString());
      setDueDateTZ(getMoment(updatedDueDate).toISOString());
      formikRef.current.setFieldValue("startTime", getDayJs());
      formikRef.current.setFieldValue("dueTime", getDayJs());
      let tempCompany = company;
      if (state?.isTaskDetails) {
        tempCompany = company?._id;
      }
      setSelectedCompany(tempCompany);
      formikRef.current.setFieldValue("company", tempCompany);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    if (filesAdded?.length > 0) {
      const newImages = filesAdded.map((imageElement) =>
        imageElement.preview ? imageElement.preview : imageElement?.url
      );

      setImages(newImages);
    }
  }, [filesAdded]);

  /********************************************************************** Add-SubTask UseEffect ****************************************************/
  useEffect(() => {
    if (isSubtaskError && addSubtaskError) {
      const taskError = addSubtaskError;

      taskError.data.error &&
        taskError.data.error.length > 0 &&
        taskError.data.error.map((errorItem) =>
          errorItem?.param === "startDateObject" ||
          errorItem?.param === "dueDateObject"
            ? showToast({ message: errorItem?.msg, type: "error" })
            : formikRef.current.setFieldError(errorItem.param, errorItem.msg)
        );
    }

    if (addSubtaskSuccess && addSubtaskData) {
      showToast({
        type: "success",
        message: addSubtaskData.message,
      });

      formikRef.current.resetForm();
      formikRef.current.setFieldValue("title", "");
      setFilesAdded([]);
      setImages([]);
      resetOnCompanyChange();
      formikRef.current.setFieldValue("markAsCritical", false);
      navigate("/manageTask");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addSubtaskError, addSubtaskData]);

  /********************************************************************** OnSubmit Function ****************************************************/
  const onSubmit = (taskFormValues) => {
    let b64Array = [];
    let oldAttachment = [];

    if (filesAdded.length > 0) {
      filesAdded.map((fileItem) => {
        if (fileItem.b64) {
          b64Array.push({
            url: fileItem?.b64 ? fileItem?.b64 : fileItem?.url,
            type: fileItem?.type,
            taskFileName: fileItem?.name || fileItem?.path,
            taskFileExt: fileItem?.taskFileExt
              ? fileItem?.taskFileExt
              : getNameAndExtFromFile(fileItem?.name)?.length &&
                getNameAndExtFromFile(fileItem?.name)[1],
          });
        } else {
          oldAttachment.push({
            url: fileItem?.url,
            type: fileItem?.type,
            taskFileName: fileItem?.name || fileItem?.path,
            taskFileExt: fileItem?.taskFileExt
              ? fileItem?.taskFileExt
              : getNameAndExtFromFile(fileItem?.name)?.length &&
                getNameAndExtFromFile(fileItem?.name)[1],
          });
        }
      });
    }

    let requestObj = {
      company: taskFormValues.company,
      parentTask: {
        id: state?.data?.taskId,
        taskNumber: state?.data?.taskNumber,
      },
      title: taskFormValues.title,
      projectId: taskFormValues.projectId,
      description: taskFormValues.description,
      assignTo: taskFormValues.assignTo,
      startDate: moment(taskFormValues.startDate).format(
        DateTimeFormats.YearMonthDay
      ),
      dueDate: moment(taskFormValues.dueDate).format(
        DateTimeFormats.YearMonthDay
      ),
      startTime: dayjs(taskFormValues.startTime).format(DateTimeFormats.Time),
      dueTime: dayjs(taskFormValues.dueTime).format(DateTimeFormats.Time),
      priority: taskFormValues.priority,
      reportTo: taskFormValues.reportTo,
      isCritical: taskFormValues.markAsCritical,
      attachment: oldAttachment,
      webAttachment: b64Array,
      startDateObject: startDateTZ,
      dueDateObject: dueDateTZ,
      moduleType: moduleType?.SUBTASK,
    };

    let isDueDateError = false;

    if (!isDueDateError) {
      if (reg2.test(taskFormValues.description)) {
        addSubtaskApi(requestObj);
      } else {
        showToast({
          message: Translate("addTask:descriptionInputError"),
          type: "error",
        });
      }
    }
  };

  /********************************************************************** Return Start ****************************************************/
  const resetOnCompanyChange = () => {
    formikRef.current.setFieldValue("assignTo", "");
    formikRef.current.setFieldValue("relatedTaskName", "");
  };

  return (
    <Formik
      initialValues={InitialValues}
      innerRef={formikRef}
      validateOnMount
      onSubmit={onSubmit}
      validationSchema={AddSubtaskSchema}>
      {({ values, setFieldValue, handleSubmit, errors }) => {
        return (
          <SubtaskForm
            isAdd
            formikRef={formikRef}
            values={values}
            setFieldValue={setFieldValue}
            errors={errors}
            subtaskData={state}
            handleSubmit={handleSubmit}
            selectedCompany={selectedCompany}
            filesAdded={filesAdded}
            images={images}
            setFilesAdded={setFilesAdded}
            startDateTZ={startDateTZ}
            setStartDateTZ={setStartDateTZ}
            dueDateTZ={dueDateTZ}
            setDueDateTZ={setDueDateTZ}
            isLoading={isSubtaskLoading}
          />
        );
      }}
    </Formik>
  );
};

export default AddSubTask;

export default {
  docName: "اسم المستند ",
  docNamePlaceholder: "اسم المستند",
  docCategory: "فئة المستند",
  title: "عنوان",
  docCategoryPlaceholder: "إختر فئة المستند ",
  registrationDate: "تاريخ التسجيل",
  expiryDate: "تاريخ  إنتهاء الصلاحية",
  addDoc: "يضيف",
  editDoc: "يحفظ",
  attach: "أرفق ملف",
  attachHeading: "مُرفق",
  nameError: "إسم الملف حقل مطلوب",
  categoryError: "فئة الملف حقل مطلوب",
  registrationDateError: "تاريخ التسجيل حقل مطلوب",
  renewalHeading: "أضف التجديد",
  renewalCheckBox: "هذه الوثيقة ليس لها انتهاء الصلاحية",
  companyError: "اسم الشركة مطلوب",
  editRenewalHeading: "تحرير التجديد",
  viewRenewalHeading: "تفاصيل التجديد",
  expiryDateError: "تاريخ  إنتهاء الصلاحية حقل مطلوب",
  default: "تقصير",
  atoz: "من الألف إلى الياء",
};

import { useFormikContext } from "formik";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { showToast } from "../../../common/Utils/ToastMessage";
import Translate from "../../../translation/utils/translateFunction";
import Dropzone from "../../CommonFileUpload/Dropzone";
import { FormikPhoneInput } from "../../FormikForm/component/FormikPhoneInput";
import { FormikTextField } from "../../FormikForm/component/FormikTextField";
import CommonImage from "../../ImageGallery/CommonImage";
import RadioButtons from "../../RadioButtons";
import Loader from "../../staff/Owner/LoadingComp";
import { PrivateListType, PublicListType, radioOptions } from "./constant";
import PageHeader from "../../common/pageHeader";
import { useSelector } from "react-redux";
import { StyledTypography } from "../../../common/Utils/StyledComponents";
import { handleEventKeySubmit } from "../../../common/Utils/commonFunction";

const ContactForm = ({
  filesAdded,
  setfilesAdded,
  setImages,
  images,
  isLoading,
  values,
  handleSubmit,
  isEdit,
  setDeletedAttachments,
  ContactFormData,
  listType,
}) => {
  const { setFieldValue } = useFormikContext();
  const navigate = useNavigate();
  const { validations } = useSelector((state) => state.formanagement);

  const icons = ["fa fa-shield", "fa fa-user-circle"];
  const addFiles = (file) => {
    const url =
      file.type === "image/png" ||
      file.type === "image/jpg" ||
      file.type === "image/jpeg"
        ? URL.createObjectURL(file)
        : null;
    if (url) {
      file.preview = url;
    }
    if (filesAdded.length < 1) {
      filesAdded.push(file);
      setfilesAdded([...filesAdded]);
      const newImages = filesAdded.map((imageElement) => imageElement.preview);
      setImages(newImages);
    } else {
      showToast({ message: Translate("document:fileLimit"), type: "error" });
    }
  };
  const acceptedFileType = {
    "image/png": [".png", ".jpg", ".jpeg"],
  };
  const removeFile = (file) => {
    const deletedFiles = filesAdded.filter(
      (fileName) => fileName.name !== file.name
    );

    if (isEdit) {
      setDeletedAttachments({ imageDeleted: true });
    }
    setfilesAdded(deletedFiles);
  };

  let isEditScreen = ContactFormData?.contactType === PublicListType ? 1 : 0;
  const isContactJsonFlag = ContactFormData?.contactJsonFlag;
  let isAddScreen = listType === PrivateListType ? 0 : 1;

  return (
    <div className="row container_spacing">
      <div className="card card_spacing">
        <PageHeader
          title={
            isEdit
              ? Translate("contacts:editContact")
              : Translate("contacts:addContact")
          }
          onClick={() => {
            listType === PrivateListType
              ? navigate("/contactRepository/privateListing")
              : navigate("/contactRepository/publicListing");
          }}
          containerClass="page-title formTitle p-3 row align-items-center"
        />
        <div className="card-body">
          <div onKeyDown={(e) => handleEventKeySubmit(e, handleSubmit)}>
            <div className="row mt-4">
              <div className="form-group col-md-4 col-sm 12">
                <FormikTextField
                  name="companyName"
                  label={Translate("contacts:selectCompany")}
                  placeholder={Translate("contacts:selectCompany")}
                  autoComplete={"off"}
                  required
                  maxLength={validations?.companyContactField.MAX}
                />
              </div>
              <div className="form-group col-md-4 col-sm 12">
                <FormikTextField
                  name="contactName"
                  label={Translate("contacts:contactName")}
                  placeholder={Translate("contacts:contactNamePlaceholder")}
                  autoComplete={"off"}
                  required
                  maxLength={validations?.companyContactName.MAX}
                />
              </div>
              <div className="form-group col-md-4 col-sm 12">
                <FormikTextField
                  name="contactDesignation"
                  value={values?.contactDesignation || ""}
                  label={Translate("contacts:contactdesignation")}
                  placeholder={Translate("contacts:contactdesignation")}
                  autoComplete={"off"}
                  required
                  maxLength={validations?.designationLength.MAX}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-4 col-sm 12">
                <FormikTextField
                  name="contactIndustry"
                  label={Translate("contacts:industry")}
                  placeholder={Translate("contacts:industry")}
                  autoComplete={"off"}
                  required
                  maxLength={validations?.companyContactIndustry.MAX}
                />
              </div>
              <div className="form-group col-md-4 col-sm 12">
                <FormikTextField
                  name="contactDepartment"
                  label={Translate("contacts:department")}
                  placeholder={Translate("contacts:department")}
                  autoComplete={"off"}
                  required
                  maxLength={validations?.departmentLength.MAX}
                />
              </div>
              <div className="form-group col-md-4 col-sm 12">
                <FormikTextField
                  name="contactSector"
                  value={values?.contactSector || ""}
                  label={Translate("contacts:sector")}
                  placeholder={Translate("contacts:sector")}
                  autoComplete={"off"}
                  required
                  maxLength={validations?.companyContactSector.MAX}
                />
              </div>
            </div>
            {isContactJsonFlag && (
              <div className="row mb-3" style={{ marginInline: "2px" }}>
                <StyledTypography
                  sx={{ fontSize: "0.9rem", fontWeight: "500" }}
                  className="noteContainer selectedItemStyle"
                >
                  {Translate("contacts:editContactNote")}
                </StyledTypography>
              </div>
            )}
            <div className="row">
              <div className="form-group col-md-4 col-sm 12">
                <FormikPhoneInput
                  label={Translate("contacts:contactNumber")}
                  name="contactPhone"
                  value={values?.contactPhone}
                  placeholder={Translate("contacts:contactNumberPlaceHolder")}
                  onChange={(value, country) => {
                    setFieldValue("contactPhone", value);
                    setFieldValue(
                      "contactPhoneShortCode",
                      country?.countryCode?.toUpperCase()
                    );
                    setFieldValue("contactPhoneCountryCode", country?.dialCode);
                  }}
                  autoComplete={"off"}
                  required
                  isLogin
                />
              </div>

              <div className="form-group col-md-4 col-sm 12">
                <FormikPhoneInput
                  label={Translate("contacts:alternateNumber")}
                  name="alternateContact"
                  value={values?.alternateContact}
                  placeholder={Translate("contacts:contactNumberPlaceHolder")}
                  onChange={(value, country) => {
                    setFieldValue("alternateContact", value);
                    setFieldValue(
                      "alternateContactShortCode",
                      country?.countryCode?.toUpperCase()
                    );
                    setFieldValue(
                      "alternateContactCountryCode",
                      country?.dialCode
                    );
                  }}
                  autoComplete={"off"}
                  isLogin
                />
              </div>

              <div className="form-group col-md-4 col-sm 12">
                <FormikTextField
                  name="contactField"
                  value={values?.contactField || ""}
                  label={Translate("contacts:field")}
                  placeholder={Translate("contacts:field")}
                  autoComplete={"off"}
                  required
                  maxLength={validations?.companyContactField.MAX}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-4 col-sm 12">
                <FormikTextField
                  name="workEmail"
                  label={Translate("contacts:workEmail")}
                  placeholder={Translate("contacts:workEmail")}
                  autoComplete={"off"}
                  required
                />
              </div>
              <div className="form-group col-md-4 col-sm 12">
                <FormikTextField
                  name="contactAddress"
                  value={values?.contactAddress || ""}
                  label={Translate("contacts:address")}
                  placeholder={Translate("contacts:address")}
                  autoComplete={"off"}
                  required
                  maxLength={validations?.address.MAX}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="form-group col-md-4 col-sm 8">
              <RadioButtons
                radioOptions={radioOptions}
                defaultChecked={
                  ContactFormData?.contactType ? isEditScreen : isAddScreen
                }
                onPress={(value) => {
                  if (
                    value?.target?.value === Translate("contacts:markPublic")
                  ) {
                    setFieldValue("contactType", "PUBLIC");
                  } else {
                    setFieldValue("contactType", "PRIVATE");
                  }
                }}
                icons={icons}
              />
            </div>
          </div>
          <div className="display">
            <Dropzone
              translate={Translate("contacts:addPhoto")}
              onfileSelect={(filesArray) => addFiles(filesArray)}
              acceptedImages={acceptedFileType}
              maximumFiles={10}
              maxFileSize={52428800}
              fileLength={filesAdded.length}
            />
          </div>
          {filesAdded.length > 0 && filesAdded?.[0].type && (
            <div className="AttachmentBoxStyling">
              <CommonImage
                images={images}
                files={filesAdded}
                removeFile={removeFile}
              />
            </div>
          )}

          <div className="card-footer text-right line_spacing">
            <button
              type="submit"
              className="btn btn-primary saveButton"
              onClick={() => {
                handleSubmit();
              }}
            >
              {Translate("contacts:save")}
            </button>
          </div>
        </div>
      </div>
      <Loader loading={isLoading} />
    </div>
  );
};
export default ContactForm;

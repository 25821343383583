import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import React, { useEffect, useRef, useState } from "react";
import { getMoment } from "../../common/Utils/commonFunction";
import Translate from "../../translation/utils/translateFunction";
import { calendarButtonType } from "../Calendar/components/constants";
import { calendarEnums, calendarViewEnums } from "../Calendar/utils";
import ReactDOM from "react-dom";
import moment from "moment";
import { DateTimeFormats } from "../../common/Utils/DateFormater";

const FullCalendarScreen = ({
  calendarDate,
  events,
  handleCalendarView,
  handleDateClick,
  right = `${calendarViewEnums.Month},${calendarViewEnums.Week},${calendarViewEnums.Day}`,

  onEventClick,
  onDateSetClick,
  isConfigure = false,
}) => {
  const calendarRef = useRef(null);
  const { PREV, NEXT, TODAY } = calendarButtonType;
  const [isTodayDisabled, setIsTodayDisabled] = useState(true);

  const customHeader = {
    left: "customPrev,customNext customToday",
    center: "title",
    right: right,
  };

  const handleCustomBtnClick = (direction) => {
    // Move to the previous or next view
    if (direction === PREV) {
      calendarRef.current.getApi().prev();
    } else if (direction === NEXT) {
      calendarRef.current.getApi().next();
    } else if (direction === TODAY) {
      calendarRef.current.getApi().gotoDate(getMoment().toDate());
      setIsTodayDisabled(true);
      return handleCalendarView(getMoment().clone().format("YYYY-MM-DD"));
    }

    // Fetch data for the new view
    setIsTodayDisabled(false);
    const data = calendarRef.current.getApi()?.view;
    handleCalendarView(data.currentStart);
  };

  useEffect(() => {
    // Add or remove fc-state-disabled class for custom today button
    const todayBtn = document.querySelector(".fc-customToday-button");

    if (todayBtn) {
      if (isTodayDisabled) {
        todayBtn.classList.add("fc-state-disabled");
      } else {
        todayBtn.classList.remove("fc-state-disabled");
      }
    }
  }, [isTodayDisabled]);

  useEffect(() => {
    if (isConfigure) {
      const dataDate = calendarRef.current?.getApi();
      dataDate.gotoDate(calendarDate);
    }
  }, [calendarDate]);
  const EventDetail = ({ event, view }) => {
    const isLess =
      moment
        .duration(
          moment(event?._def?.extendedProps?.endDateTime).diff(
            event?._def?.extendedProps?.startDateTime
          )
        )
        .asMinutes() < 25;
    return (
      <a
        tabindex="0"
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          paddingTop:
            view?.type === "dayGridMonth" ? "2px" : isLess ? "0px" : "2px",
          paddingBottom: "2px",
        }}>
        <div
          // class="fc-daygrid-event-dot"
          style={{
            marginRight: "3px",
            width:
              view?.type === "dayGridMonth" ? "10px" : isLess ? "5px" : "10px",
            height:
              view?.type === "dayGridMonth" ? "10px" : isLess ? "5px" : "10px",
            borderRadius:
              view?.type === "dayGridMonth" ? "5px" : isLess ? "2.5px" : "5px",
            background:
              event?._def?.extendedProps?.type === calendarEnums.event
                ? "rgb(238, 64, 71)"
                : "#3788d8",
          }}
        />
        <div class="fc-event-time">
          {moment(event?._def?.extendedProps?.startDateTime).format(
            DateTimeFormats.TimeAMPM
          )}
        </div>
        <div class="fc-event-title">{event?._def?.title}</div>
      </a>
    );
  };
  return (
    <div className="customFullCalendar" /* style={{ height: "74.5vh" }} */>
      <FullCalendar
        ref={calendarRef}
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        customButtons={{
          customPrev: {
            click: () => handleCustomBtnClick(PREV),
            icon: "fc-icon fc-icon-chevron-left",
          },
          customNext: {
            click: () => handleCustomBtnClick(NEXT),
            icon: "fc-icon fc-icon-chevron-right",
          },
          customToday: {
            text: TODAY,
            click: () => handleCustomBtnClick(TODAY),
            disabled: isTodayDisabled,
          },
        }}
        headerToolbar={customHeader}
        initialView="dayGridMonth"
        datesSet={onDateSetClick}
        initialDate={calendarDate}
        editable={false}
        selectable={true}
        dayMaxEvents={true}
        events={events}
        eventClick={onEventClick}
        dateClick={handleDateClick}
        // eventContent={EventDetail}
        buttonText={{
          day: Translate("calendarPage:day"),
          month: Translate("calendarPage:month"),
          week: Translate("calendarPage:week"),
        }}
      />
    </div>
  );
};

export default FullCalendarScreen;

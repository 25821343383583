import { Grid } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDebounce } from "use-debounce";
import { showToast } from "../../../common/Utils/ToastMessage";
import {
  StyledCard,
  StyledTypography,
} from "../../../common/Utils/StyledComponents";
import ListView from "../../ListView";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { colors } from "../../../common/Theme/Colors";
import {
  useDeleteContactMutation,
  useGetPublicListingQuery,
  useGetShareContactRequestMutation,
  useMarkAsPublicMutation,
} from "../../../request/ContactRepo";
import Translate from "../../../translation/utils/translateFunction";
import AlertPermission from "../../AlertPermission";
import IconifyIcon from "../../Icon";
import { Persona } from "../../Persona";
import { VALIDATION_BUCKETURL } from "../../Persona/constants";
import ShareWithUser from "../../ShareWithUser";
import { shareModuleType } from "../../ShareWithUser/components/constants";
import Loader from "../../staff/Owner/LoadingComp";
import CompanyListing from "../component";
import { PublicListType } from "../component/constant";
import { getContactRepoPublicListColumns } from "./column.data";
import CompanyHeader from "../../../common/CompanyHeader";
import Searchbar from "../../common/Searchbar";
import RequestContact from "../component/RequestContact";
import { UserTypes, contactypePublicList } from "../component/constant";

const PublicListContactRepo = ({ fixNavbar }) => {
  const [loading, setLoading] = useState(true);

  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [search, setSearch] = useState("");
  const [publicListDataFetched, setPublicListDataFetched] = useState();

  const lastPage = useRef(0);
  const contactType = PublicListType;
  const [debouncedSearch] = useDebounce(search, 300);
  const [selectedRow, setSelectedRow] = useState([]);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const { selectedCompanyList, userData, isRtl } = useSelector(
    (state) => state.formanagement
  );
  const companies = selectedCompanyList?.length
    ? selectedCompanyList?.map((item) => item?.value)
    : [];
  const [showMarkAsPublicPrivate, setShowMarkAsPublicPrivate] = useState(false);
  const [onmarkAsPrivate, setmarkAsPrivate] = useState();
  const [show, setshow] = useState(false);
  const [deleteId, setDeleteId] = useState([]);
  const [isDeletable, setIsDeletable] = useState();
  const [selectedContactId, setSelectedContactId] = useState();
  const [showShareModal, setShowShareModal] = useState(false);
  const [showRequestContact, setShowRequestContact] = useState(false);

  const {
    data: publicContactList,
    isError: isErrorPublicContactList,
    error: errorPublicContactList,
    refetch: refetchListing,
    isSuccess: successfullPublicContactList,
    isFetching: isFetchingPublicList,
  } = useGetPublicListingQuery(
    { currentPageNo, contactType, debouncedSearch, companies },
    { refetchOnMountOrArgChange: true }
  );

  useEffect(() => {
    if (publicContactList && successfullPublicContactList) {
      const { nodes, pageInfo } = publicContactList.data;
      setPublicListDataFetched(nodes);

      lastPage.current = pageInfo.lastPageNo;
      setLoading(false);
    }
    if (isErrorPublicContactList && errorPublicContactList) {
      showToast({ message: "Failed to fetch the data", type: "error" });
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successfullPublicContactList, publicContactList]);
  const [
    markAsPrivateApi,
    {
      data: markAsPrivate,
      isSuccess: successfullyMarkAsPrivate,
      isError: isErrorMarkAsPrivate,
      error: errorMarkAsPrivate,
      isLoading: isLoadingMarkAsPrivate,
    },
  ] = useMarkAsPublicMutation();

  useEffect(() => {
    if (errorMarkAsPrivate && isErrorMarkAsPrivate) {
      errorMarkAsPrivate.data.error &&
        showToast({
          message: errorMarkAsPrivate?.data?.message,
          type: "error",
        });
    }

    if (successfullyMarkAsPrivate && markAsPrivate) {
      showToast({ message: markAsPrivate.message, type: "success" });
      refetchListing();
      setmarkAsPrivate("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    markAsPrivate,
    errorMarkAsPrivate,
    isErrorMarkAsPrivate,
    successfullyMarkAsPrivate,
  ]);

  const handleSearchText = (value) => {
    setCurrentPageNo(1);
    setSearch(value);
  };
  const markAsPrivateFunction = () => {
    if (selectedRow?.length) {
      const requestObject = {
        contacts: selectedRow,
      };
      markAsPrivateApi({
        body: requestObject,
        contactType: "PRIVATE",
      });
    } else {
      const requestObject = {
        contacts: [onmarkAsPrivate],
      };
      markAsPrivateApi({
        body: requestObject,
        contactType: "PRIVATE",
      });
    }

    setShowMarkAsPublicPrivate(false);
  };

  const onCancelMarkAsPublic = () => setShowMarkAsPublicPrivate(false);
  const onCloseRequestContactModal = () => setShowRequestContact(false);
  const [
    deleteContactApi,
    {
      data: deleteContact,
      isSuccess: successfullyDeleteContact,
      isLoading: isContactDeleteLoading,
    },
  ] = useDeleteContactMutation();

  useEffect(() => {
    if (successfullyDeleteContact && deleteContact) {
      showToast({ message: deleteContact.message, type: "success" });
      refetchListing();

      if (selectedRow?.length) {
        setSelectedRow([]);
      } else {
        setDeleteId([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteContact]);

  const deleteContacts = () => {
    if (selectedRow?.length) {
      const requestObject = {
        contacts: selectedRow,
      };
      deleteContactApi(requestObject);
    } else if (isDeletable) {
      const requestObject = {
        contacts: [deleteId],
      };
      deleteContactApi(requestObject);
    } else {
      showToast({
        message: "You don't have access to delete this contact",
        type: "error",
      });
    }

    setshow(false);
  };
  const onCancel = () => setshow(false);

  const [
    shareContactApi,
    {
      data: shareContactData,
      isSuccess: isShareContactSuccess,
      isError: isShareContactError,
      error: shareContactError,
    },
  ] = useGetShareContactRequestMutation();
  useEffect(() => {
    if (shareContactData && isShareContactSuccess) {
      showToast({ message: shareContactData?.message, type: "success" });

      refetchListing();
      setSelectedRow([]);
    }

    if (shareContactError && isShareContactError) {
      showToast({
        message:
          shareContactError?.message || Translate("document:apiFailedMessage"),
        type: "error",
      });
    }
  }, [shareContactData, isShareContactSuccess, isShareContactError]);
  const handleShareDocument = (users) => {
    const reqObj = {
      contactId: selectedRow?.length ? selectedRow : [selectedContactId],
      users: users?.map((ele) => ele._id),
    };

    shareContactApi(reqObj);
  };

  const onCloseShareModal = () => setShowShareModal(false);

  const customActionCellRender = (params) => {
    const isRowSelected = selectedRow?.includes(params?.id);
    return (
      <>
        <div className="headerMargin">
          <a
            href="/#"
            className={`nav-link dropdown-toggle ${
              isRowSelected ? "disabled-icon" : ""
            }`}
            data-toggle="dropdown"
            role="button"
            aria-haspopup="true"
            aria-expanded="false">
            <i
              className={`fa fa-ellipsis-v cursor-pointer manageIconColor ${
                isRowSelected ? "disabled-icon" : ""
              }`}
            />
          </a>
          <div className="dropdown-menu dropdownAlignment dropdownIconMenu">
            <div
              className="manageIconColor ml-3 cursor-pointer dropdown-item"
              onClick={() =>
                navigate("/contactRepository/publicListing/viewBusinessCard", {
                  state: params?.id,
                })
              }>
              <span className="manageIconColor fe fe-eye" />
              {Translate("contacts:view")}
            </div>
            {params?.row?.isEditable && (
              <div
                className="manageIconColor ml-3 cursor-pointer dropdown-item"
                onClick={() =>
                  navigate("/contactRepository/publicList/editContact", {
                    state: params?.row,
                  })
                }>
                <span className="manageIconColor fa fa-pencil" />
                {Translate("contacts:edit")}
              </div>
            )}

            {params?.row?.isDeletable && (
              <div
                className="manageIconColor ml-3 cursor-pointer dropdown-item"
                onClick={() => {
                  setshow(true);
                  setDeleteId(params?.id);
                  setIsDeletable(params?.row?.isDeletable);
                }}>
                <span className="manageIconColor fa fa-trash" />
                {Translate("contacts:delete")}
              </div>
            )}

            <div
              className="manageIconColor ml-3 cursor-pointer dropdown-item"
              onClick={() =>
                navigate("/contactRepository/publicListing/accessLogs", {
                  state: params?.id,
                })
              }>
              <span className="manageIconColor fa fa-file-text" />
              {Translate("contacts:accesslogs")}
            </div>
            {params?.row?.isEditable && (
              <div
                className="manageIconColor ml-3 cursor-pointer dropdown-item"
                onClick={() => {
                  setShowMarkAsPublicPrivate(true);
                  setmarkAsPrivate(params?.id);
                }}>
                <span className="manageIconColor fa fa-shield" />
                {Translate("contacts:markAsPrivate")}
              </div>
            )}
            {params?.row?.isEditable && (
              <div
                className="manageIconColor ml-3 cursor-pointer dropdown-item"
                onClick={() => {
                  setShowShareModal(true);
                  setSelectedContactId(params?.id);
                }}>
                <span className="manageIconColor fe fe-share-2" />
                {Translate("contacts:share")}
              </div>
            )}
          </div>
        </div>
      </>
    );
  };

  const rowsPerPage = 10;
  const startSerialNumber = (currentPageNo - 1) * rowsPerPage + 1;

  const rowsWithSerialNumbers =
    publicListDataFetched &&
    publicListDataFetched.map((row, index) => ({
      ...row,
      serialNumber: startSerialNumber + index,
    }));
  return (
    <Grid
      item
      xs={12}
      className={`section-body ${isRtl ? "rtlMargin" : "ltrMargin"} ${
        fixNavbar ? "marginTop" : ""
      } `}>
      <StyledCard variant="outlined">
        <div className="container-fluid p-4">
          <div className="tab-content">
            <div>
              <div className="card">
                <div className="card-header card-header-spacing">
                  <CompanyHeader setShowModal={setShowModal} />
                  <div className="card-options">
                    {selectedRow.length > 0 && (
                      <div style={{ display: "flex" }}>
                        <div className="mr-2">
                          <span className=" ml-1">
                            <button
                              className="btn btn-sm btn-default  cutomButtonBorder"
                              type="button"
                              onClick={() => setShowMarkAsPublicPrivate(true)}>
                              <span
                                className="fa fa-shield manageIconColor"
                                style={{ fontSize: 15 }}
                              />
                              <span className="ml-2 buttonTextFontWeight">
                                {Translate("contacts:markAsPrivate")}
                              </span>
                            </button>
                          </span>
                        </div>
                        <div className="mr-2">
                          <span className=" ml-1">
                            <button
                              className="btn btn-sm btn-default  cutomButtonBorder"
                              type="button"
                              onClick={() => setShowShareModal(true)}>
                              <span
                                className="fe fe-share-2 manageIconColor"
                                style={{ fontSize: 15 }}
                              />
                              <span className="ml-2 buttonTextFontWeight">
                                {Translate("contacts:share")}
                              </span>
                            </button>
                          </span>
                        </div>
                        <div className="mr-2">
                          <span className=" ml-1">
                            <button
                              className="btn btn-sm btn-default  cutomButtonBorder"
                              type="button"
                              onClick={() => setshow(true)}>
                              <span
                                className="fa fa-trash manageIconColor"
                                style={{ fontSize: 15 }}
                              />
                              <span className="ml-2 buttonTextFontWeight">
                                {Translate("contacts:deleteContact")}
                              </span>
                            </button>
                          </span>
                        </div>
                      </div>
                    )}
                    {userData?.primary === "NO" ||
                    (userData?.primary === "YES" &&
                      userData?.role.type === UserTypes?.PA) ? (
                      <div className="header-action pull-right ml-2 mr-2">
                        <button
                          type="button"
                          className="btn btn-primary py-2"
                          onClick={() => setShowRequestContact(true)}>
                          <StyledTypography className="mx-1" component="span">
                            {Translate("contacts:requestContact")}
                          </StyledTypography>
                        </button>
                      </div>
                    ) : null}
                    <span
                      data-toggle="tooltip"
                      className="mx-2 cursor-pointer"
                      style={{ marginTop: "7px" }}
                      title={Translate("contacts:businessCard")}>
                      <IconifyIcon
                        icon={"typcn:business-card"}
                        fontSize="20px"
                        color={colors.primary}
                        onClick={() => navigate("BusinessCard")}
                      />
                    </span>
                    <div>
                      <div className="input-group">
                        <Searchbar
                          handleSearchText={handleSearchText}
                          setSearch={setSearch}
                        />
                      </div>
                    </div>
                    <div className="header-action ml-2">
                      <button
                        type="button"
                        className="btn btn-primary py-2"
                        onClick={() =>
                          navigate(
                            "/contactRepository/publicListing/addNewContact",
                            {
                              state: { listType: PublicListType },
                            }
                          )
                        }>
                        <i className="fe fe-plus" />
                        <StyledTypography className="mx-1" component="span">
                          {Translate("common:add")}
                        </StyledTypography>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <ListView
                    columns={getContactRepoPublicListColumns(customActionCellRender)}
                    rows={rowsWithSerialNumbers}
                    autoHeight
                    onPaginationChange={setCurrentPageNo}
                    currentPage={currentPageNo}
                    showShimmer={isFetchingPublicList}
                    showLoader={loading || isFetchingPublicList}
                    totalPageCount={lastPage.current}
                    checkboxSelection={true}
                    onRowSelectionModelChange={(newRowSelectionModel) => {
                      setSelectedRow(newRowSelectionModel);
                    }}
                    selectionModel={selectedRow}
                    disableRowSelectionOnClick
                    disableColumnSelector={false}
                    isRowSelectable={(params) =>
                      params?.row?.isEditable || params.row.isDeletable
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <CompanyListing
          handleModal={(value) => {
            setCurrentPageNo(1);
            setShowModal(value);
          }}
          showModal={showModal}
          selectedCompanyList={selectedCompanyList}
        />
        {showShareModal && (
          <ShareWithUser
            currentModule={shareModuleType?.CONTACT_REPO}
            showModal={showShareModal}
            closeModal={onCloseShareModal}
            onSubmit={handleShareDocument}
          />
        )}
        {showRequestContact && (
          <RequestContact
            showModal={showRequestContact}
            closeModal={onCloseRequestContactModal}
            onSuccessfulRequestContact={(event) => setShowRequestContact(event)}
            contactListType={contactypePublicList}
          />
        )}

        <AlertPermission
          show={show}
          subTitle={Translate("contacts:deleteSingleContactAlertMsg")}
          onOk={deleteContacts}
          onCanel={onCancel}
        />
        <AlertPermission
          show={showMarkAsPublicPrivate}
          subTitle={Translate("contacts:markAsPrivateAlertMessage")}
          onOk={markAsPrivateFunction}
          onCanel={onCancelMarkAsPublic}
        />
        <Loader loading={isLoadingMarkAsPrivate || isContactDeleteLoading} />
      </StyledCard>
    </Grid>
  );
};

export default PublicListContactRepo;

import { Methods, Urls } from "../../../common/Utils/ApiConstants";

import { CreateApi } from "../../CreateApi";
const staffmanagercollection = CreateApi.injectEndpoints({
  endpoints: (builder) => ({
    getStaffManagerListing: builder.mutation({
      query: ({ requestBody, pageNo }) => {
        return {
          url: Urls.getStaffManagerList,
          method: Methods.post,
          body: requestBody,
          params: { pageNo },
        };
      },
    }),
    getStaffGeneralManagerListing: builder.mutation({
      query: ({ requestBody, pageNo }) => {
        return {
          url: Urls.getStaffGeneralManagerList,
          method: Methods.post,
          body: requestBody,
          params: { pageNo },
        };
      },
    }),
    addStaffGeneralManagerData: builder.mutation({
      query: (body) => {
        return {
          url: Urls.addStaffGeneralManager,
          method: Methods.post,
          body: body,
        };
      },
    }),
    viewStaffGeneralManagerData: builder.mutation({
      query: (body) => {
        return {
          url: `${Urls.viewStaffGeneralManager}${body}`,
          method: Methods.get,
        };
      },
    }),
    editStaffGeneralManagerData: builder.mutation({
      query: (body) => {
        return {
          url: `${Urls.editStaffGeneralManager}${body.id}`,
          method: Methods.put,
          body: body.body,
        };
      },
    }),
    rateStaffGeneralManager: builder.mutation({
      query: (body) => {
        return {
          url: `${Urls.rateStaffGeneralManager}${body.id}`,
          method: Methods.put,
          body: body.body,
        };
      },
    }),
    deleteStaffGeneralManager: builder.mutation({
      query: (body) => {
        return {
          url: `${Urls.deleteStaffGeneralManager}${body}`,
          method: Methods.delete,
        };
      },
    }),
    addStaffManager: builder.mutation({
      query: (body) => {
        return {
          url: Urls.addStaffManager,
          method: Methods.post,
          body: body,
        };
      },
    }),
    viewStaffManagerData: builder.mutation({
      query: (body) => {
        return {
          url: `${Urls.viewStaffManager}${body}`,
          method: Methods.get,
        };
      },
    }),
    editStaffManagerData: builder.mutation({
      query: (body) => {
        return {
          url: `${Urls.editStaffManager}${body.managerId}`,
          method: Methods.put,
          body: body.body,
        };
      },
    }),
    rateStaffManager: builder.mutation({
      query: (body) => {
        return {
          url: `${Urls.rateStaffManager}${body.id}`,
          method: Methods.put,
          body: body.body,
        };
      },
    }),
    deleteStaffManager: builder.mutation({
      query: (body) => {
        return {
          url: `${Urls.deleteStaffManager}${body}`,
          method: Methods.delete,
        };
      },
    }),
    reportToListStaff: builder.mutation({
      query: (body) => {
        return {
          url: Urls.reportToListStaff,
          method: Methods.post,
          body: body,
        };
      },
    }),
  }),
});
export const {
  useGetStaffManagerListingMutation,
  useGetStaffGeneralManagerListingMutation,
  useAddStaffGeneralManagerDataMutation,
  useViewStaffGeneralManagerDataMutation,
  useEditStaffGeneralManagerDataMutation,
  useRateStaffGeneralManagerMutation,
  useDeleteStaffGeneralManagerMutation,
  useAddStaffManagerMutation,
  useViewStaffManagerDataMutation,
  useEditStaffManagerDataMutation,
  useRateStaffManagerMutation,
  useDeleteStaffManagerMutation,
  useReportToListStaffMutation,
} = staffmanagercollection;

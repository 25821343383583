export default {
  head: "Business card details",
  createHead: "Create contact",
  editHead: "Edit contact",
  contactName: "Contact name",
  userName: "User name",
  designation: "Designation",
  industry: "Industry",
  workEmail: "Work email ",
  workEmailPlaceholder: "workemail@mail.com",
  contactNumber: "Contact number",
  contactNumberPlaceholder: "00 0000 0000 00",
  alternateNumberPlaceholder: "00 0000 0000 00",
  address: "Address",
  nameError: "Contact name is required",
  designationError: "Designation is required",
  emailError: "Work email is required",
  emailValid: "Work email must be a valid email.",
  contactNumberError_1: "Contact number must be a valid.",
  contactNumberError_2: "Contact number is required",
  addressError: "Address is required",
  view: "View business card",
  companyName: "Company name",
  department: "Department",
  sector: "Sector",
  field: "Field",
  fieldPlaceHolder: "Field name",
  alternateNumber: "Alternate contact number",
  companyNameError: "Company name is required",
  departmentError: "Department is required",
  sectorError: "Sector is required",
  fieldError: "Field is required",
  industryError: "Industry is required",
  alternateContactNumberError_1: "Alternate contact number must be a valid.",
  alternateContactNumberError_2: "Alternate contact number is required",
  businessCard: "Business card",
  attachmentTitle: "Attachment",
  attachmentErrMsg:"No attachments found"
};

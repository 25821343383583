import { Methods, Urls } from "../../common/Utils/ApiConstants";
import { CreateApi } from "../CreateApi";

const TimeZoneCollection = CreateApi.injectEndpoints({
  endpoints: (builder) => ({
    getTimezoneList: builder.query({
      query: (countryCode) => ({
        url: Urls.getTimezoneList,
        method: Methods.get,
      }),
      transformResponse: (response) => {
        return response.data;
      },
    }),

    changeTimeZone: builder.mutation({
      query: (body) => {
        return {
          url: Urls.changeTimeZone,
          method: Methods.put,
          body: body,
        };
      },
    }),
  }),
});

export const { useGetTimezoneListQuery, useChangeTimeZoneMutation } =
  TimeZoneCollection;

import Translate from "../../../translation/utils/translateFunction";
import {
  CustomTitleCellRender,
  CustomTagCellRender,
} from "../../common/CustomCellRender";
import { convertToSentenceCase } from "../../../common/Utils/commonFunction";

export const getAttendanceReportColumns = (
  customActionCellRender,
  customSrCellRender
) => {
  const columns = [
    {
      field: "serialNumber",
      headerName: Translate("common:srNoTitle"),
      align: "center",
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      renderCell: customSrCellRender,
    },
    {
      field: "name",
      headerName: Translate("performanceReport:name"),
      flex: 0.4,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => CustomTitleCellRender(params?.row?.name),
    },
    {
      field: "role",
      headerName: Translate("performanceReport:role"),
      flex: 0.3,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => convertToSentenceCase(params?.row?.role)
    },
    {
      field: "attendanceDetails.totalDays",
      headerName: Translate("attendance:totalDays"),
      sortable: false,
      flex: 0.3,
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
      renderCell: (params) =>
        CustomTagCellRender(
          params?.row?.attendanceDetails?.totalDays,
          "tag-dark"
        ),
    },
    {
      field: "attendanceDetails.workingDays",
      headerName: Translate("attendance:workingDays"),
      sortable: false,
      flex: 0.3,
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
      renderCell: (params) =>
        CustomTagCellRender(
          params?.row?.attendanceDetails?.workingDays,
          "tag-theme-blue"
        ),
    },
    {
      field: "attendanceDetails.presentDays",
      headerName: Translate("attendance:presentDays"),
      sortable: false,
      flex: 0.3,
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
      renderCell: (params) =>
        CustomTagCellRender(
          params?.row?.attendanceDetails?.presentDays,
          "tag-theme-blue"
        ),
    },
    {
      field: "attendanceDetails.absentDays",
      headerName: Translate("attendance:absentDays"),
      sortable: false,
      flex: 0.3,
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
      renderCell: (params) =>
        CustomTagCellRender(
          params?.row?.attendanceDetails?.absentDays,
          "tag-red"
        ),
    },
    {
      field: "Action",
      headerName: Translate("common:actionTitle"),
      headerAlign: "center",
      align: "center",
      flex: 0.2,
      sortable: false,
      disableColumnMenu: true,
      renderCell: customActionCellRender,
    },
  ];
  return columns;
};

import { Methods, Urls } from "../../common/Utils/ApiConstants";
import { CreateApi } from "../CreateApi";

const DashboardCollection = CreateApi.injectEndpoints({
  endpoints: (builder) => ({
    getTodaysEvents: builder.mutation({
      query: (body) => {
        return {
          url: Urls.getTodaysEvent,
          method: Methods.post,
          body: body,
        };
      },
    }),

    getInprogressTasks: builder.mutation({
      query: (body) => {
        return {
          url: Urls.getInprogressTask,
          method: Methods.post,
          body: body,
        };
      },
    }),
    taskProgressFilter: builder.mutation({
      query: (body) => {
        return {
          url: Urls.taskProgressBar,
          method: Methods.post,
          body: body,
        };
      },
    }),
    riskFactor: builder.mutation({
      query: (body) => {
        return {
          url: Urls.riskFactor,
          method: Methods.post,
          body: body,
        };
      },
    }),
  }),
});

export const {
  useGetTodaysEventsMutation,
  useGetInprogressTasksMutation,
  useTaskProgressFilterMutation,
  useRiskFactorMutation,
} = DashboardCollection;

import React from "react";
import { UpdateTime } from "../../../common/Utils/DateFormater";
import { isRtl } from "../../../common/Utils/commonFunction";
import Translate from "../../../translation/utils/translateFunction";
import TextWrapWithTooltip from "../../TextWrapWithTooltip";
import { CustomDateTimeCellRender } from "../../common/CustomCellRender";

export const getMyVoiceNoteColumns = (
  customSrCellRender,
  customcellRender,
  customActionCellRender,
  screenWidth
) => {
  const columns = [
    {
      field: "lineNo",
      headerName: Translate("common:srNoTitle"),
      align: "center",
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      renderCell: customSrCellRender,
    },
    {
      field: "title",
      headerName: Translate("VoiceNotes:voicenoteTitle"),
      flex: 0.4,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <TextWrapWithTooltip
          textVariant="span"
          label={params?.row?.title}
          customStyle={
            isRtl()
              ? { direction: "ltr" }
              : { maxWidth: screenWidth <= 1300 ? "210px" : "425px" }
          }
        />
      ),
    },
    {
      field: "voiceNote",
      headerName: Translate("VoiceNotes:voiceNote"),
      minWidth: 360,
      flex: 0.7,
      sortable: false,
      disableColumnMenu: true,
      renderCell: customcellRender,
    },
    {
      field: "formattedDate",
      headerName: Translate("VoiceNotes:dateTime"),
      flex: 0.3,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const { formattedDate, formattedTime } = params.row;

        return CustomDateTimeCellRender(formattedDate, formattedTime);
      },
    },
    {
      field: "timeLength",
      headerName: Translate("VoiceNotes:duration"),
      flex: 0.25,
      sortable: false,
      disableColumnMenu: true,
      valueGetter: (params) =>
        params?.row?.timeLengthInSec?.toString()
          ? UpdateTime(params?.row?.timeLengthInSec)
          : UpdateTime(Math.round(params.row?.timeLength / 1000)),
    },

    {
      field: "Action",
      headerName: Translate("common:actionTitle"),
      flex: 0.2,
      sortable: false,
      disableColumnMenu: true,
      hide: true,
      editable: false,
      renderCell: customActionCellRender,
    },
  ];
  return columns;
};

import * as yup from "yup";
import Translate from "../../../../translation/utils/translateFunction";

export const AddSubtaskSchema = () => {
  const schema = yup.object().shape({
    /* projectId: yup.string().trim().required(Translate("addTask:typeError")), */
    parentTitle: yup
      .string()
      .trim()
      .required(Translate("addTask:parentTitleError")),
    title: yup.string().trim().required(Translate("addTask:subtaskNameError")),
    description: yup
      .string()
      .trim()
      .required(Translate("addTask:descriptionError")),
    assignTo: yup.string().trim().required(Translate("addTask:assignToError")),
    reportTo: yup.string().trim().required(Translate("addTask:reportTo")),
    startDate: yup
      .string()
      .trim()
      .required(Translate("addTask:startDateError")),
    dueDate: yup.string().trim().required(Translate("addTask:dueDateError")),
    startTime: yup
      .string()
      .trim()
      .required(Translate("addTask:startTimeError")),
    dueTime: yup.string().trim().required(Translate("addTask:dueTimeError")),
  });

  return schema;
};

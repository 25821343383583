import { Grid } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import { colors } from "../../../common/Theme/Colors";
import {
  StyledCard,
  StyledTypography,
} from "../../../common/Utils/StyledComponents";
import { showToast } from "../../../common/Utils/ToastMessage";
import { useGetSharedDocumentListingMutation } from "../../../request/Document/DocumentListing";
import Translate from "../../../translation/utils/translateFunction";
import CompanyListing from "../../ContactRepository/component";
import IconifyIcon from "../../Icon";
import ListView from "../../ListView";
import SortingFilter from "../../SortingFilter";
import { getSharedDocumentColumns } from "./Components/column.data";
// import { sortingOptions } from "./Components/constants";
import CompanyHeader from "../../../common/CompanyHeader";
import Searchbar from "../../common/Searchbar";
import { sortingOptions } from "../components/constants";

const SharedDocuments = ({ fixNavbar }) => {
  const navigate = useNavigate();
  const { selectedCompanyList, isRtl } = useSelector(
    (state) => state.formanagement
  );

  const [isLoading, setIsLoading] = useState(false);
  const [sortBy, setSortBy] = useState("default");
  const [lastPage, setLastPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sharedDocuments, setSharedDocuments] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showSortingFilter, setShowSortingFilter] = useState(false);

  const [
    getSharedDocumentApi,
    {
      data: sharedDocumentList,
      isLoading: isSharedDocumentLoading,
      isError: isSharedDocumentError,
      error: sharedDocumentError,
    },
  ] = useGetSharedDocumentListingMutation();

  const requestObj = useMemo(() => {
    const companyIds = selectedCompanyList?.length
      ? selectedCompanyList?.map((item) => item?.value)
      : [];

    const finalReq = {
      companyId: companyIds,
      searchText: search,
      sortBy,
      pageNo: currentPage,
    };

    return companyIds?.length ? finalReq : null;
  }, [search, sortBy, currentPage, selectedCompanyList]);

  const debouncedSearch = useDebouncedCallback(() => {
    getSharedDocumentApi(requestObj);
  }, 500);

  useEffect(() => {
    setSharedDocuments([]);

    if (selectedCompanyList?.length) {
      setIsLoading(true);

      if (requestObj?.searchText?.length) {
        debouncedSearch();
      } else {
        getSharedDocumentApi(requestObj);
      }
    } else {
      showToast({
        type: "info",
        message: Translate("document:selectCompanyMessage"),
      });
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestObj]);

  useEffect(() => {
    if (sharedDocumentList?.data?.nodes) {
      const { nodes, pageInfo } = sharedDocumentList.data;
      setSharedDocuments(nodes);
      setLastPage(pageInfo.lastPageNo);
      setIsLoading(false);
    }

    if (sharedDocumentError && isSharedDocumentError) {
      sharedDocumentError?.originalStatus !== 500 &&
        showToast({
          message: Translate("document:apiFailedMessage"),
          type: "error",
        });
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sharedDocumentList, isSharedDocumentError]);

  const debounced = useDebouncedCallback((sortValue) => {
    setSortBy(sortValue);
  }, 500);

  const getSort = (sortValue = "") => {
    if (sortValue !== sortBy) {
      setIsLoading(true);
      debounced(sortValue);
    }
  };

  const CustomSrCellRender = (params) => {
    return (
      <StyledTypography component="span" sx={{ color: colors.primary_009 }}>
        {params?.api?.getRowIndexRelativeToVisibleRows(params?.row?._id) +
          1 +
          (currentPage - 1) * 10}
      </StyledTypography>
    );
  };

  const CustomDateCellRender = (params) => {
    const { date, time } = params?.row;
    return (
      <td>
        <div className="text-info">{date || ""}</div>
        <div className="text-pink">{time || ""}</div>
      </td>
    );
  };

  const CustomActionCellRender = (params) => {
    return (
      <td>
        <i
          className="m-2 fa fa-eye cursor-pointer manageIconColor"
          onClick={() =>
            navigate("/documentRepository/sharedWithMe/view", {
              state: params?.row?._id,
            })
          }
          data-toggle="tooltip"
          title={Translate("common:viewToolTip")}></i>
      </td>
    );
  };

  const onCloseSortingModal = () => setShowSortingFilter(false);

  const handleSortingModal = (value) => {
    getSort(value);
    setShowSortingFilter(false);
  };
  const handleSearchText = (value) => {
    setCurrentPage(1);
    setSearch(value);
  };
  return (
    <Grid
      item
      xs={12}
      className={`section-body ${isRtl ? "rtlMargin" : "ltrMargin"} ${
        fixNavbar ? "marginTop" : ""
      } `}>
      <StyledCard variant="outlined">
        <div className="container-fluid p-4">
          <div className="tab-content">
            <div>
              <div className="card">
                <div className="card-header card-header-spacing">
                  <CompanyHeader setShowModal={setShowModal} />
                  <div className="card-options">
                    <div className="input-group centerElementsFlex">
                      <span
                        className="mr-2"
                        data-toggle="tooltip"
                        title={Translate("common:showFilterToolTip")}
                        onClick={() => setShowSortingFilter(true)}>
                        <IconifyIcon
                          icon="bx:filter"
                          width="28"
                          className="dateRangeFilterIcon"
                        />
                      </span>

                      <Searchbar
                        handleSearchText={handleSearchText}
                        setSearch={setSearch}
                      />
                    </div>
                  </div>
                </div>
                <ListView
                  columns={getSharedDocumentColumns(
                    CustomSrCellRender,
                    CustomDateCellRender,
                    CustomActionCellRender
                  )}
                  rows={sharedDocuments}
                  autoHeight
                  sortingMode="server"
                  onPaginationChange={setCurrentPage}
                  currentPage={currentPage}
                  showShimmer={isLoading || isSharedDocumentLoading}
                  showLoader={isLoading}
                  totalPageCount={lastPage}
                />
              </div>
            </div>
          </div>
        </div>
      </StyledCard>
      <CompanyListing
        handleModal={(value) => {
          setCurrentPage(1);
          setShowModal(value);
        }}
        showModal={showModal}
        selectedCompanyList={selectedCompanyList}
      />
      <SortingFilter
        showModal={showSortingFilter}
        handleClose={onCloseSortingModal}
        submitModal={handleSortingModal}
        sortingOptions={sortingOptions}
      />
    </Grid>
  );
};

export default SharedDocuments;

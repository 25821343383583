export default {
    fromDate: "من تاريخ",
    toDate: "إلى تاريخ",
    assignedByOwner: "تعيين بواسطة المالك",
    assignedByGm: "تعيين بواسطة المدير العام",
    assignedByManager: "تعيين بواسطة المدير",
    generateReport: "إنشاء تقرير",
    reset: "إعادة تعيين",
    fromDateError: "التاريخ المبدئي مطلوب",
    toDateError: "التاريخ النهائي مطلوب",
    ownerMultiFieldError: "يرجى تحديد حقل واحد على الأقل من تعيين بواسطة المالك أو تعيين بواسطة المدير العام أو تعيين بواسطة المدير",
    ownerError: "تعيين بواسطة المالك مطلوب",
    gmMultiFieldError: "يرجى تحديد حقل واحد على الأقل من تعيين بواسطة المدير العام أو تعيين بواسطة المدير",
    gmError: "تعيين بواسطة المدير العام مطلوب",
    managerError: "تعيين بواسطة المدير مطلوب",
    dateNote1: 'ملاحظة: - يمكنك إنشاء تقرير يصل إلى',
    dateNote2: 'أشهر من تاريخ التحديد',
  };
  
import { Formik } from "formik";
import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useViewCompanyDetailsMutation } from "../../request/Account";
import Translate from "../../translation/utils/translateFunction";
import { FormikTextField } from "../FormikForm/component/FormikTextField";
import Loader from "../staff/Owner/LoadingComp";
import DefaultImage from "../../assets/images/For_Logo.png";

const ViewCompany = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const companyId = location?.state?._id;

  const { fixNavbar, isRtl } = useSelector((state) => state?.formanagement);
  const formikRef = useRef(null);
  let InitialValues = {
    officeContact: "",
    officeTelephone: "",
    registeredAddress: "",
    landmark: "",
    country: "",
    stateRegion: "",
    city: "",
    zipcode: "",
  };

  const [
    getCompanyDetails,
    {
      data: viewCompanyData,
      isLoading: viewCompanyIsLoading,
      isSuccess: viewCompanySuccess,
    },
  ] = useViewCompanyDetailsMutation();

  useEffect(() => {
    if (companyId) {
      let bodyObj = {
        companyId: companyId,
      };
      getCompanyDetails(bodyObj);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  useEffect(() => {
    if (viewCompanySuccess) {
      formikRef.current.setValues((prevValues) => ({
        ...prevValues,
        officeContact: viewCompanyData?.contactWithCountryCode,
        officeTelephone: viewCompanyData?.officeTelephone,
        registeredAddress: viewCompanyData?.officeAddress?.address,
        landmark: viewCompanyData?.officeAddress?.landmark,
        country: viewCompanyData?.officeAddress?.country,
        stateRegion: viewCompanyData?.officeAddress?.state,
        city: viewCompanyData?.officeAddress?.city,
        zipcode: viewCompanyData?.officeAddress?.zipcode,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewCompanySuccess]);

  return (
    <Formik
      initialValues={InitialValues}
      innerRef={formikRef}
      enableReinitialize
    >
      {() => {
        return (
          <>
            <div
              className={`section-body ${isRtl ? "rtlMargin" : "ltrMargin"} ${
                fixNavbar ? "marginTop" : ""
              } `}
            >
              <div className="container-fluid">
                <div className="row clearfix">
                  <div className="col-md-12">
                    <div className="card card-profile">
                      <div className="card-body text-center">
                        <div className="paddingTop-20">
                        <img
                          height={100}
                          width={100}
                          className="card-profile-img"
                          src={
                            viewCompanyData?.logo
                              ? viewCompanyData?.logo
                              : DefaultImage
                          }
                          alt=""
                        />
                        </div>
                       
                        <h4 className="mb-3">{viewCompanyData?.name}</h4>
                        <text>{viewCompanyData?.website}</text>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`section-body ${
                isRtl ? "rtlMargin" : "ltrMargin"
              }`}
            >
              <div className="container-fluid">
                <div className="row clearfix">
                  <div className="col-lg-12 col-md-12">
                    <div className="card">
                      <div
                        className="custom-page-title formTitle  row"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "1rem 0px",
                        }}
                      >
                        <div>
                          <i
                            className={` cursor-pointer ${"fe fe-arrow-left"}`}
                            style={{ color: "#5a5278" }}
                            onClick={() => navigate("/profile")}
                            data-toggle="tooltip"
                            title={Translate("common:backToolTip")}
                          ></i>
                        </div>
                        {location?.state?.isAuthorised && (
                          <div>
                            <i
                              className={`ml-3 cursor-pointer ${"fa fa-pencil-square-o"}`}
                              style={{ color: "#5a5278" }}
                              onClick={() =>
                                navigate("/editOfficeLocation", {
                                  state: {
                                    viewCompanyData: viewCompanyData,
                                    isAuthorised: location.state.isAuthorised,
                                  },
                                })
                              }
                            ></i>
                          </div>
                        )}
                      </div>
                      <div className="card-body">
                        <div
                          className="row clearfix paddingTop-20"
                        >
                          <div className="col-sm-4">
                            <div className="form-group">
                              <FormikTextField
                                name="officeContact"
                                label={Translate("addCompany:contact")}
                                placeholder={Translate("addCompany:contact")}
                                autoComplete="off"
                                valueDisabled={true}
                                disabled={true}
                              />
                            </div>
                          </div>
                          <div className="col-sm-4">
                            <div className="form-group">
                              <FormikTextField
                                name="officeTelephone"
                                label={Translate("addCompany:officeTelephone")}
                                placeholder={Translate(
                                  "addCompany:officeTelephonePlaceHolder"
                                )}
                                autoComplete="off"
                                disabled={true}
                              />
                            </div>
                          </div>
                          <div className="col-sm-4">
                            <div className="form-group">
                              <FormikTextField
                                name="registeredAddress"
                                label={Translate("addCompany:address")}
                                placeholder={Translate("addCompany:address")}
                                valueDisabled={true}
                                disabled={true}
                                autoComplete="off"
                              />
                            </div>
                          </div>
                          <div className="col-sm-4 col-md-4">
                            <div className="form-group">
                              <FormikTextField
                                name="landmark"
                                label={Translate("accountDetails:Landmark")}
                                placeholder={Translate(
                                  "accountDetails:Landmark"
                                )}
                                valueDisabled={true}
                                disabled={true}
                                autoComplete="off"
                              />
                            </div>
                          </div>
                          <div className="col-sm-4 col-md-4">
                            <div className="form-group">
                              <FormikTextField
                                name="country"
                                label={Translate("addCompany:country")}
                                placeholder={Translate("addCompany:country")}
                                valueDisabled
                                disabled={true}
                                autoComplete="off"
                              />
                            </div>
                          </div>
                          <div className="col-sm-4 col-md-4">
                            <div className="form-group">
                              <FormikTextField
                                name="stateRegion"
                                label={Translate(
                                  "addPersonalAssistant:statePlaceholder"
                                )}
                                placeholder={Translate(
                                  "addPersonalAssistant:statePlaceholder"
                                )}
                                valueDisabled
                                disabled={true}
                                autoComplete="off"
                              />
                            </div>
                          </div>
                          <div className="col-sm-4 col-md-4">
                            <div className="form-group">
                              <FormikTextField
                                name="city"
                                label={Translate("addCompany:city")}
                                placeholder={Translate("addCompany:city")}
                                valueDisabled
                                disabled={true}
                                autoComplete="off"
                              />
                            </div>
                          </div>
                          <div className="col-sm-4 col-md-4">
                            <div className="form-group">
                              <FormikTextField
                                name="zipcode"
                                label={Translate("addCompany:zipcode")}
                                placeholder={Translate("addCompany:zipcode")}
                                valueDisabled
                                disabled={true}
                                autoComplete="off"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Loader loading={viewCompanyIsLoading} />
          </>
        );
      }}
    </Formik>
  );
};

export default ViewCompany;

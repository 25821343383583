import React, { useEffect, useRef, useState } from "react";
import Translate from "../../../../translation/utils/translateFunction";

import { showToast } from "../../../../common/Utils/ToastMessage";

import { useLocation, useNavigate } from "react-router-dom";
import { useGetAccessLogsListingMutation } from "../../../../request/VoiceNotes";

import ListView from "../../../ListView";
import { getAccessLogsColumns } from "./column.data";
import { Grid, Stack } from "@mui/material";
import { StyledCard } from "../../../../common/Utils/StyledComponents";
import { action, actionType } from "../../Components/constants";
import { useSelector } from "react-redux";

const AccessLogsVoiceNote = ({ fixNavbar }) => {
  const { isRtl } = useSelector((state) => state.formanagement);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setLoading] = useState(true);
  const [accessLogs, setAccessLogs] = useState([]);
  const { state } = useLocation();
  const navigate = useNavigate();
  const sortBy = null;
  const lastPage = useRef(0);

  const [
    getAccessLogsListingApi,
    {
      data: accesslogsData,
      isSuccess: successfullAccessLogsList,
      isLoading: loadingAccessLogsList,
      isError: isErrorAccessLogs,
      error: errorAccessLogs,
    },
  ] = useGetAccessLogsListingMutation();

  useEffect(() => {
    if (successfullAccessLogsList && accesslogsData) {
      const { nodes, pageInfo } = accesslogsData?.data;

      lastPage.current = pageInfo.lastPageNo;
      setAccessLogs(nodes);
      setLoading(false);
    }
    if (isErrorAccessLogs && errorAccessLogs) {
      setLoading(false);
      showToast({
        message: Translate("VoiceNotes:apiFailedMessage"),
        type: "error",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accesslogsData, successfullAccessLogsList]);

  useEffect(() => {
    if (state) {
      const finalRequestObject = {
        pageNo: currentPage,
        voicenoteId: state,
        action: actionType,
      };
      getAccessLogsListingApi(finalRequestObject);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, state, getAccessLogsListingApi]);

  const CustomCellRender = (params) => {
    return (
      <td>
        <>
          <div className="text-info">{params.row.formattedDate}</div>
          <div className="text-pink">{params.row.formattedTime}</div>
        </>
      </td>
    );
  };
  const rowsPerPage = 10;
  const startSerialNumber = (currentPage - 1) * rowsPerPage + 1;

  const rowsWithSerialNumbers =
    accessLogs &&
    accessLogs.map((row, index) => ({
      ...row,
      serialNumber: startSerialNumber + index,
    }));

  return (
    <Grid
      item
      xs={12}
      className={`section-body ${isRtl ? "rtlMargin" : "ltrMargin"} ${
        fixNavbar ? "marginTop" : ""
      } `}>
      <StyledCard variant="outlined">
        <div className="container-fluid p-4">
          <div className="tab-content">
            <div className="card-header card-header-spacing formTitle">
              <Stack flexDirection={"row"} alignItems={"center"}>
                <i
                  className="icon fe fe-arrow-left mr-3 icon-size backnavigation-iconColor"
                  onClick={() => {
                    navigate("/voiceNotes/myVoiceNotes");
                  }}
                  title={Translate("common:backToolTip")}
                />
                <h5 className="page-title">
                  {Translate("document:accessLogHeading")}
                </h5>
              </Stack>
            </div>
            <div>
              <div className="card">
                <ListView
                  columns={getAccessLogsColumns(CustomCellRender)}
                  rows={rowsWithSerialNumbers}
                  autoHeight
                  sortingMode="server"
                  sortBy={sortBy}
                  onPaginationChange={setCurrentPage}
                  currentPage={currentPage}
                  showShimmer={loadingAccessLogsList}
                  showLoader={isLoading}
                  totalPageCount={lastPage.current}
                />
              </div>
            </div>
          </div>
        </div>
      </StyledCard>
    </Grid>
  );
};
export default AccessLogsVoiceNote;

export default {
  type: "Type",
  title: "Title",
  titlePlaceholder: "Title",
  taskName: "Title",
  subtaskName: "Subtask name",
  add: "Add",
  addTaskHeading: "Add task",
  description: "Description",
  descriptionPlaceholder: "Add description",
  assignTo: "Assign to",
  startDate: "Start date",
  dueDate: "Due date",
  priority: "Priority",
  subTask: "Subtask",
  attach: "Attach file",
  attachHeading: "Attachments",
  dropdownPlaceholder: "Select type",
  companyDropdownPlaceholder: "Select company",
  companyError: "Select company first",
  titleError: "Type is required",
  nameError: "Title is required",
  addError: "Add task/Subtask is required",
  designationError: "Designation is required",
  descriptionError: "Description is required",
  assignToError: "Assign to is required",
  startDateError: "Start date is required",
  dueDateError: "Due date is required",
  priorityError: "Priority is required",
  dateError: "Date is required",
  timeError: "Time is required",
  reportTo: "Report to",
  reportToError: "Report to is required",
  parentTaskName: "Parent task name",
  parentNameError: "Parent Name is required",
  relatedTask: "Related task",
  relatedTaskPlaceholder: "Related task name",
  markAsCritical: "Mark as critical",
  startTime: "Start time",
  dueTime: "Due time",
  AddSubtaskAlert: "Please save the task details to add the subtask",
  AddSubtaskAlertButton: "Save and continue",
  voiceNote: "Add voice note",
  assigneePlaceholder: "Select assignee",
  reporterPlaceholder: "Select reporter",
  taskNameError: "Task name is required",
  sameDateError: "Due date should not be equal to start date",
  beforeDateError: "Due date should be greater to current date",
  descriptionInputError: "Description should have at least one character",
  editTaskHeading: "Edit task",
  save: "Save",
  deleteAlertTitle: "Are you sure you want to delete this comment?",
  subtaskButton: "Subtask",
  addSubtaskHeading: "Add subtask",
  editSubtaskHeading: "Edit subtask",
  parentTitle: "Parent title",
  subtaskTitle: "Subtask type",
  typeError: "Type is required",
  parentTitleError: "Parent title is required",
  startTimeError: "Start time is required",
  dueTimeError: "Due time is required",
  commentAlert: "Please add comment",
  companyReqError: "Company is required",
  assignToPlaceholder: "Select assignee",
  subtaskNameError: "Subtask name is required",
  taskDependancyHeader1: "Assigned to task",
  taskDependancyHeader2: "Sub-task",
  taskDependancyHeader3: "Related task",
  project: "Project",
  projectPlaceholder: "Select project",
  projectError: "Project is required",
};

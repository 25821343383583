const CHAT_LIST = "chatList",
  GROUP_LIST = "groupList",
  MESSAGE = "Message",
  VIEW = "View",
  REMOVE = "Remove";

export const tabType = {
  CHAT_LIST,
  GROUP_LIST,
};

export const tabTypes = [CHAT_LIST, GROUP_LIST];

export const groupMemberOptions = [MESSAGE, VIEW, REMOVE];

export const groupMemberType = { MESSAGE, VIEW, REMOVE };

import {
  LinearProgress,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useDebouncedCallback } from "use-debounce";
import { colors } from "../../../../common/Theme/Colors";
import { StyledTypography } from "../../../../common/Utils/StyledComponents";
import { showToast } from "../../../../common/Utils/ToastMessage";
import {
  useDeleteGroupProfileIconMutation,
  useGroupDetailsMutation,
  useRemoveGroupMemberMutation,
  useUploadGroupProfileIconMutation,
} from "../../../../request/Message";
import Translate from "../../../../translation/utils/translateFunction";
import AlertPermission from "../../../AlertPermission";
import IconifyIcon from "../../../Icon";
import { Persona } from "../../../Persona";
import MessageItem from "../../Components/MessageItem";
import NoDataFound from "../../../ManageTask/NoDataFound";
import Dropzone from "../../../CommonFileUpload/Dropzone";
import {
  groupMemberOptions,
  groupMemberType,
} from "../../Components/constants";
import { useSelector } from "react-redux";
import Skeleton from "@mui/material/Skeleton";
import Searchbar from "../../../common/Searchbar";

const GroupInfo = ({
  showModal,
  handleClose,
  groupId,
  selectedUser,
  headerData,
  onClickForRemoveMember,
  hasRemoveAccess,
  onGroupIconUpdate,
  onGroupIconDelete,
  onMessageFromGroup,
}) => {
  const { userData, isRtl } = useSelector((state) => state.formanagement);
  const [searchText, setSearchText] = useState("");
  const [groupMembers, setGroupMembers] = useState([]);
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [selectedMember, setSelectedMember] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [filesAdded, setfilesAdded] = useState([]);
  const [show, setshow] = useState(false);
  const [isGroupImageLoading, setIsGroupImageLoading] = useState(true);
  const [isGroupOwner, setIsGroupOwner] = useState(false);

  const acceptedFileType = {
    "image/png": [".png", ".jpg", ".jpeg"],
  };

  const [
    getGroupInfoApi,
    { data: groupData, isLoading, isSuccess, isError, error },
  ] = useGroupDetailsMutation();

  const [
    removeMemberApi,
    {
      data: removeMemberData,
      isLoading: isRemoveMemberLoading,
      isSuccess: isRemoveMemberSuccess,
      isError: isRemoveMemberError,
      error: removeMemberError,
    },
  ] = useRemoveGroupMemberMutation();

  const [
    updateGroupIconApi,
    {
      data: updateGroupIconData,
      isLoading: isLoadingUpdateGroupIcon,
      isSuccess: isSuccessUpdateGroupIcon,
      isError: isErrorUpdateGroupIcon,
      error: errorUpdateGroupIcon,
    },
  ] = useUploadGroupProfileIconMutation();

  const [
    deleteGroupIconApi,
    {
      data: deleteGroupIconData,
      isLoading: isLoadingDeleteIcon,
      isSuccess: isSuccessDeleteIcon,
      isError: isErrorDeleteIcon,
      error: errorDeleteIcon,
    },
  ] = useDeleteGroupProfileIconMutation();

  const groupInfoRequest = useMemo(() => {
    return {
      groupId,
      searchText,
    };
  }, [groupId, searchText]);

  const debouncedSearch = useDebouncedCallback(() => {
    getGroupInfoApi(groupInfoRequest);
  }, 500);

  useEffect(() => {
    if (headerData) {
      setIsGroupImageLoading(false);
    }
  }, [headerData]);

  useEffect(() => {
    if (groupInfoRequest?.searchText?.length) {
      debouncedSearch();
    } else {
      getGroupInfoApi(groupInfoRequest);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupInfoRequest]);

  useEffect(() => {
    if (groupData && isSuccess) {
      const { gorupDetails, record } = groupData?.data;
      setGroupMembers(record);
      setIsGroupOwner(
        gorupDetails?.groupOwner === userData?._id ? true : false
      );
    }
    if (isError && error) {
      showToast({
        message: error?.data.message || Translate("common:unknownServerError"),
        type: "error",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupData, isSuccess, isError]);

  useEffect(() => {
    if (isRemoveMemberError && removeMemberError) {
      const groupError = removeMemberError?.data;
      if (!groupError) {
        showToast({
          message: Translate("common:unknownServerError"),
          type: "error",
        });
      } else {
        groupError?.error?.length
          ? groupError.error.map((errorItem) => {
              return showToast({ message: errorItem.msg, type: "error" });
            })
          : showToast({
              message:
                groupError?.message || Translate("common:unknownServerError"),
              type: "error",
            });
      }
    }
    if (isRemoveMemberSuccess && removeMemberData) {
      showToast({
        type: "success",
        message: removeMemberData.message,
      });

      onClickForRemoveMember();
      getGroupInfoApi(groupInfoRequest);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [removeMemberError, removeMemberData]);

  useEffect(() => {
    if (errorUpdateGroupIcon && isErrorUpdateGroupIcon) {
      errorUpdateGroupIcon.data.error &&
        errorUpdateGroupIcon.data.error.length > 0 &&
        errorUpdateGroupIcon.data.error.map((errorItem) => {
          // eslint-disable-next-line no-unused-expressions
          showToast({
            message: errorUpdateGroupIcon?.data?.message,
            type: "error",
          });
        });
      setIsGroupImageLoading(false);
    }
    if (isSuccessUpdateGroupIcon && updateGroupIconData) {
      showToast({
        message: updateGroupIconData?.message,
        type: "success",
      });

      setfilesAdded([]);
      onGroupIconUpdate(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessUpdateGroupIcon, errorUpdateGroupIcon]);

  useEffect(() => {
    if (errorDeleteIcon && isErrorDeleteIcon) {
      errorDeleteIcon.data.error &&
        errorDeleteIcon.data.error.length > 0 &&
        errorDeleteIcon.data.error.map((errorItem) => {
          // eslint-disable-next-line no-unused-expressions
          showToast({
            message: errorDeleteIcon?.data?.message,
            type: "error",
          });
        });
      setIsGroupImageLoading(false);
    }
    if (isSuccessDeleteIcon && deleteGroupIconData) {
      showToast({
        message: deleteGroupIconData?.message,
        type: "success",
      });

      setfilesAdded([]);
      onGroupIconDelete(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessDeleteIcon, errorDeleteIcon]);

  const handleDialogOpen = (value) => {
    setSelectedMember(value);
  };

  const handleDialogClose = (value, member) => {
    if (value === groupMemberType?.REMOVE) {
      setSelectedMember(member);
      setShowAlert(true);
    }

    if (value === groupMemberType?.MESSAGE || value === groupMemberType?.VIEW) {
      let userObj = {
        ...member,
        fromGroup: true,
      };

      // new chat user
      if (!member?.chatId) {
        userObj["isNewChatFromGroup"] = true;
      }

      if (value === groupMemberType?.VIEW) {
        userObj["isViewInfoFromGroup"] = true;
      }

      onMessageFromGroup(userObj);
      handleClose();
    }
  };

  const onAlertCancel = () => setShowAlert(false);

  const removeMember = () => {
    setShowAlert(false);
    if (groupMembers?.length <= 3) {
      showToast({
        message: Translate("group:removeMemberValidationError"),
        type: "error",
      });
    } else {
      removeMemberApi({ groupId, body: { user: selectedMember?._id } });
    }
  };

  const addFiles = (file) => {
    const url =
      file.type === "image/png" ||
      file.type === "image/jpg" ||
      file.type === "image/jpeg"
        ? URL.createObjectURL(file)
        : null;
    if (url) {
      file.preview = url;
    }
    if (filesAdded.length < 1) {
      filesAdded.push(file);
      setfilesAdded([file]);

      setTimeout(() => {
        let bodyObj = {
          webGroupImage: {
            url: filesAdded[0]?.b64,
            type: filesAdded[0]?.type,
            groupImageFileName: filesAdded[0]?.name,
            groupImageFileExt: filesAdded[0]?.name.split(".").pop(),
          },
        };
        updateGroupIconApi({ bodyObj: bodyObj, id: groupId });
        setIsGroupImageLoading(true);
      }, 500);
    } else {
      showToast({
        message: Translate("common:fileAddLimitMsg"),
        type: "error",
      });
    }
  };

  const removeFile = () => {
    setshow(false);
    deleteGroupIconApi({ id: groupId });
    setIsGroupImageLoading(true);
  };

  const onCancel = () => setshow(false);

  const handleSearchText = (value) => {
    setSearchText && setSearchText(value);
  };

  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable={true}
      className={`col-span-3 col-md-12 ${isRtl && "rtl"}`}
    >
      <Modal.Body style={{ padding: "0px" }}>
        <Stack>
          <Stack px={"1rem"} pt={"1rem"}>
            <button
              type="button"
              style={{ opacity: 1, color: colors.black }}
              className="close cursor-pointer align-self-end"
              data-dismiss="alert"
              aria-label="Close"
              onClick={handleClose}
            />
            <Stack alignItems={"center"} mb={2}>
              <div style={{ position: "relative" }}>
                {isLoadingUpdateGroupIcon ||
                isGroupImageLoading ||
                isLoadingDeleteIcon ? (
                  <Skeleton
                    variant="circular"
                    animation="wave"
                    width={100}
                    height={100}
                  />
                ) : (
                  <Persona
                    name={headerData?.groupInfo?.groupName}
                    image={headerData?.groupInfo?.groupImage}
                    size={100}
                    isGroup
                  />
                )}

                <Dropzone
                  onfileSelect={addFiles}
                  acceptedImages={acceptedFileType}
                  maximumFiles={1}
                  fileLength={filesAdded.length}
                  // maxFileSize={50 * 1000000}
                  maxImageSize={10485760}
                  isProfile
                  className="groupProfileCameraIcon"
                  groupProfile
                  deleteAlert={() => {
                    setshow(true);
                  }}
                  isShowCameraDeleteIcon={headerData?.groupInfo?.groupImage}
                />
              </div>

              <center>
                <StyledTypography
                  variant="h6"
                  sx={{ fontSize: "1.4rem" }}
                  mt={1}
                >
                  {headerData?.groupInfo?.groupName}
                </StyledTypography>
              </center>
            </Stack>
          </Stack>
          <Stack py={2} px={3} sx={{ background: colors.grey_016 }}>
            <Stack>
              <div className="groupInfoSearch">
                <StyledTypography
                  variant="subtitle2"
                  my={2}
                  sx={{ color: colors.primary_010 }}
                >
                  {headerData?.groupMembersNumber}{" "}
                  {Translate("messagePage:members")}
                </StyledTypography>
                <span
                  onClick={() => setShowSearchBar((val) => !val)}
                  data-toggle="tooltip"
                  title={Translate("common:search")}
                  className="cursor-pointer"
                >
                  <IconifyIcon icon="material-symbols:search" color="#5a5278" />
                </span>
              </div>

              <div className="input-group mb-2" style={{ width: "100%" }}>
                {showSearchBar && (
                  <Searchbar
                    handleSearchText={handleSearchText}
                    setSearch={setSearchText}
                    isMessageModule={true}
                  />
                )}
              </div>
              <ul
                className="commentContainer m-0 p-0 groupMemberList"
                style={{ minHeight: "300px", maxHeight: "337px" }}
              >
                {isLoading || isRemoveMemberLoading ? (
                  <LinearProgress style={{ height: 2 }} />
                ) : groupMembers?.length ? (
                  groupMembers?.map((member, idx) => (
                    <div className="headerMargin">
                      <a
                        href="/#"
                        className="nav-link dropdown-toggle d-block"
                        data-toggle="dropdown"
                        role="button"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <MessageItem
                          key={idx}
                          item={member}
                          isNewChat={true}
                          selectedUser={selectedUser}
                          className={"p-2 mb-1 "}
                          onClick={handleDialogOpen}
                          isTooltip
                        />
                      </a>
                      {userData?._id === member?._id ? null : (
                        <div className="dropdown-menu dropdownAlignment">
                          {groupMemberOptions
                            ?.map((item, index) =>
                              item === groupMemberType?.REMOVE &&
                              !hasRemoveAccess ? null : (
                                <ListItem
                                  disableGutters
                                  key={index}
                                  className="manageIconColor ml-3 cursor-pointer dropdown-item p-1"
                                >
                                  <ListItemButton
                                    onClick={() =>
                                      handleDialogClose(item, member)
                                    }
                                  >
                                    <ListItemText
                                      primary={`${Translate(
                                        `group:${item?.toLowerCase()}`
                                      )} ${member?.name}`}
                                    />
                                  </ListItemButton>
                                </ListItem>
                              )
                            )
                            .filter((item) => item !== null)}
                        </div>
                      )}
                    </div>
                  ))
                ) : (
                  !groupData?.data?.record?.length && (
                    <NoDataFound
                      styleProps={{ textAlign: "center", fontSize: "18px" }}
                    />
                  )
                )}
              </ul>
            </Stack>
          </Stack>
        </Stack>
        <AlertPermission
          show={show}
          subTitle={Translate("addEmployee:alertProfileDel")}
          onOk={removeFile}
          onCanel={onCancel}
          okTitle={Translate("addEmployee:delete")}
          isAlertText
        />
        <AlertPermission
          show={showAlert}
          subTitle={Translate("group:removeMemberAlert")}
          onOk={removeMember}
          onCanel={onAlertCancel}
        />
      </Modal.Body>
    </Modal>
  );
};
export default GroupInfo;

export const InitialValues = {
  groupImage: "",
  groupName: "",
  groupMembers: [],
  groupType: "Chat",
};

export const groupType = {
  CHAT: "Chat",
  TASK: "Task"
}
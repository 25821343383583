import React, { useEffect, useMemo, useRef, useState, memo } from "react";
import GroupList from "./GroupList";
import {
  useGroupchatListMutation,
  useGetGroupInviteeMutation,
} from "../../../request/Message";
import { useDebouncedCallback } from "use-debounce";
import { LinearProgress } from "@mui/material";
import MessageListLoader from "../Components/MessageLoader/MessageListLoader";
import MemberProfileList from "./MemberProfileList";
import AddGroup from "./AddGroup";

const GroupScreen = ({
  onClick,
  selectedUser,
  isNewGroup,
  selectedTab,
  currentPage,
  setCurrentPage,
  searchText,
  userData,
  newChatCurrentPage,
  setNewChatCurrentPage,
  checkedGroupMembers,
  setCheckedGroupMembers,
  isAddGroup,
  goBackClick,
  onIconUpdate,
  onGroupNameEdit,
  onSuccessfullEditGroupName,
  onGroupIconDelete,
  onSuccessfullDeleteGroupImage,
  onSuccessfullIconGroupName,
}) => {
  const [groupList, setGroupList] = useState([]);
  const [newGroupList, setNewGroupList] = useState([]);
  const isChatLastPage = useRef(false);
  const isNewChatLastPage = useRef(false);

  const [
    getGroupListApi,
    {
      data: serverResponseGroupChatData,
      isSuccess: isGroupListSuccess,
      isLoading: isGroupListLoading,
    },
  ] = useGroupchatListMutation();

  const [
    getChatInviteeList,
    {
      data: groupInviteeData,
      isSuccess: isGroupInviteeSuccess,
      isLoading: isGroupInviteeLoading,
    },
  ] = useGetGroupInviteeMutation();

  const requestObj = useMemo(() => {
    return {
      pageNo: currentPage,
      searchText: searchText,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, selectedTab, searchText]);

  const groupInviteeReqObj = useMemo(() => {
    return {
      pageNo: newChatCurrentPage,
      searchText: searchText,
    };
  }, [searchText, newChatCurrentPage]);

  useEffect(() => {
    onIconUpdate && getGroupListApi(requestObj);
  }, [onIconUpdate]);

  useEffect(() => {
    onGroupNameEdit && getGroupListApi(requestObj);
  }, [onGroupNameEdit]);

  useEffect(() => {
    onGroupIconDelete && getGroupListApi(requestObj);
  }, [onGroupIconDelete]);

  useEffect(() => {
    if (isNewGroup) {
      searchText ? debouncedSearch() : getChatInviteeList(groupInviteeReqObj);
    } else {
      searchText ? debouncedSearch() : getGroupListApi(requestObj);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestObj, selectedTab, groupInviteeReqObj, isNewGroup]);

  const debouncedSearch = useDebouncedCallback(() => {
    isNewGroup
      ? getChatInviteeList(groupInviteeReqObj)
      : getGroupListApi(requestObj);
  }, 500);

  useEffect(() => {
    if (serverResponseGroupChatData && isGroupListSuccess) {
      // update group seen count for the user
      const records = serverResponseGroupChatData?.data?.record.map((group) => {
        let unseenCount = 0;

        if (selectedUser !== group?._id) {
          const groupMem = group?.groupMembersUnseenCount.find(
            (mem) => mem?.userId === userData?._id
          );

          unseenCount = groupMem?.messageUnseenCount || 0;
        }

        return {
          ...group,
          messageUnseenCount: unseenCount,
        };
      });

      currentPage !== 1
        ? setGroupList((prev) => prev.concat(records))
        : setGroupList(records);
      isChatLastPage.current =
        serverResponseGroupChatData?.data?.pageInfo?.hasNextPage;

      onGroupNameEdit && onSuccessfullEditGroupName(true);
      onGroupIconDelete && onSuccessfullDeleteGroupImage(true);
      onIconUpdate && onSuccessfullIconGroupName(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGroupListSuccess]);

  useEffect(() => {
    if (isGroupInviteeSuccess && groupInviteeData) {
      newChatCurrentPage !== 1
        ? setNewGroupList((prev) => prev.concat(groupInviteeData?.data?.nodes))
        : setNewGroupList(groupInviteeData?.data?.nodes);
      isNewChatLastPage.current = groupInviteeData?.data?.pageInfo?.hasNextPage;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGroupInviteeSuccess]);

  const handleOnClick = (item) => {
    onClick(item);
    // update seen count
    if (item?.messageUnseenCount > 0) {
      setGroupList(
        groupList.map((group) =>
          group?._id === item?._id ? { ...group, messageUnseenCount: 0 } : group
        )
      );
    }
  };

  const handleSelectedUsers = (value) => {
    const isExist = checkedGroupMembers.find(
      (item) => item?._id === value?._id
    );

    if (isExist) {
      setCheckedGroupMembers(
        checkedGroupMembers.filter((item) => item?._id !== value?._id)
      );
    } else {
      setCheckedGroupMembers([...checkedGroupMembers, value]);
    }
  };

  return (
    <>
      {(isGroupListLoading && currentPage === 1) ||
      (isGroupInviteeLoading && newChatCurrentPage === 1) ? (
        <LinearProgress style={{ height: 2 }} />
      ) : isNewGroup ? (
        <>
          {isAddGroup ? (
            <AddGroup
              groupMembers={checkedGroupMembers}
              navigateTo={goBackClick}
            />
          ) : (
            <>
              {checkedGroupMembers?.length > 0 && (
                <MemberProfileList
                  members={checkedGroupMembers}
                  onClick={handleSelectedUsers}
                  isIcon
                />
              )}
              <GroupList
                chatList={newGroupList}
                onClick={handleSelectedUsers}
                selectedUser={selectedUser}
                checkedGroupMembers={checkedGroupMembers}
                isNewGroup
              />
            </>
          )}
        </>
      ) : (
        <GroupList
          chatList={groupList}
          onClick={handleOnClick}
          selectedUser={selectedUser}
          isGroup
        />
      )}

      {!isAddGroup &&
        (isNewGroup ? isNewChatLastPage.current : isChatLastPage.current) && (
          <MessageListLoader
            isLoading={isGroupListLoading || isGroupInviteeLoading}
            onLoadMoreClick={() =>
              isNewGroup
                ? setNewChatCurrentPage((prev) => prev + 1)
                : setCurrentPage((prev) => prev + 1)
            }
          />
        )}
    </>
  );
};

export default memo(GroupScreen);

import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { colors } from "../../common/Theme/Colors";
import NoDataFound from "../ManageTask/NoDataFound";
import Translate from "../../translation/utils/translateFunction";

const Piechart = ({
  optionsList,
  legends,
  selectedFilter,
  riskCountList,
  fromReport,
  graphHeight,
}) => {
  const [series, setSeries] = useState();
  const [options, setOptions] = useState();

  useEffect(() => {
    if ((selectedFilter || fromReport) && optionsList) {
      setSeries(optionsList?.map((item) => item?.y));
      setOptions({
        chart: {
          width: 380,
          type: "donut",
        },
        states: {
          active: {
            filter: { type: "none", value: 0 },
          },
          hover: {
            filter: {
              type: "none",
            },
          },
        },
        colors: Object.values(legends),
        tooltip: {
          y: {
            formatter: (val) => {
              return "";
            },
            fillSeriesColor: true,
            title: {
              formatter: (seriesName) => {
                const hoveredItem = riskCountList?.find(
                  (item) => item?.type === seriesName
                );
                return `${hoveredItem?.type}: ${hoveredItem?.count}`;
              },
            },
          },
        },
        plotOptions: {
          donut: {
            expandOnClick: false,
          },
        },
        labels: optionsList?.map((item) =>
          Translate(`dashboard:${item?.type}`)
        ),
        legend: {
          position: "bottom",
          markers: {
            fillColors: Object.values(legends),
          },
        },
        fill: {
          colors: Object.values(legends),
        },
        dataLabels: {
          enabled: true,
          formatter: (val) => {
            return parseFloat(val).toFixed(2) + "%";
          },
          style: {
            fontSize: "16px",
            colors: [colors.grey_016],
          },
        },
        stroke: {
          width: optionsList?.find((item) => item?.y === 100) ? 0 : 2,
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: "100%",
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilter, optionsList, riskCountList]);
  return (
    <div>
      {options &&
        series &&
        (optionsList?.length ? (
          <div id="chart">
            <ReactApexChart
              options={options}
              series={series}
              type="donut"
              width="100%"
              height={graphHeight ? graphHeight : "auto"}
            />
          </div>
        ) : (
          <NoDataFound message={Translate("dashboard:noDataMsg")} isCenter />
        ))}
    </div>
  );
};

export default Piechart;

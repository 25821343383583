import * as yup from "yup";
import Translate from "../../../translation/utils/translateFunction";
export const AddLeaveSchema = () => {
  const schema = yup.object().shape({
    companyId: yup
      .string()
      .trim()
      .nullable()
      .required(Translate("addManager:companyError")),
    fromDate: yup
      .string()
      .trim()
      .nullable()
      .required(Translate("accountPage:fromDateError")),
    toDate: yup
      .string()
      .trim()
      .nullable()
      .required(Translate("accountPage:toDateError")),
    leaveReason: yup
      .string()
      .trim()
      .nullable()
      .required(Translate("accountPage:leaveReasonError")),
  });

  return schema;
};

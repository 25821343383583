import { Methods, Urls } from "../../../common/Utils/ApiConstants";
import { CreateApi } from "../../CreateApi";

const HrOfficerCollection = CreateApi.injectEndpoints({
  endpoints: (builder) => ({
    getHrOfficerList: builder.mutation({
      query: ({ requestBody, pageNo }) => {
        return {
          url: Urls.getHrOfficerList,
          method: Methods.post,
          body: requestBody,
          params: { pageNo },
        };
      },
    }),
    addHrOfficer: builder.mutation({
      query: (body) => {
        return {
          url: Urls.addHrOfficer,
          method: Methods.post,
          body: body,
        };
      },
    }),
    viewHrOfficer: builder.mutation({
      query: (body) => {
        return {
          url: `${Urls.viewHrOfficer}${body}`,
          method: Methods.get,
        };
      },
    }),
    editHrOfficer: builder.mutation({
      query: (body) => {
        return {
          url: `${Urls.editHrOfficer}${body?.id}`,
          method: Methods.put,
          body: body?.body,
        };
      },
    }),
  }),
});

export const {
  useGetHrOfficerListMutation,
  useAddHrOfficerMutation,
  useViewHrOfficerMutation,
  useEditHrOfficerMutation,
} = HrOfficerCollection;

import React, { memo, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useLazyGetCompanyListQuery } from "../../../request/company";
import Translate from "../../../translation/utils/translateFunction";
import { FormikDropdownPicker } from "../../FormikForm/component/FormikDropDownPicker";
import { FormikTextField } from "../../FormikForm/component/FormikTextField";
import { handleEventKeySubmit } from "../../../common/Utils/commonFunction";
import PageHeader from "../../common/pageHeader";
import Loader from "../../staff/Owner/LoadingComp";

const ProjectForm = ({ isAdd, values, isLoading, handleSubmit }) => {
  const navigate = useNavigate();
  const { validations } = useSelector((state) => state?.formanagement);
  const [allCompanyData, setAllCompanyData] = useState([]);

  const [getCompanyList, { data: companyResponse, isLoading: isCompanyLoading }] =
    useLazyGetCompanyListQuery();

  useEffect(() => {
    getCompanyList();
  }, []);

  useEffect(() => {
    if (companyResponse) {
      const companyData = [];

      companyResponse.map((item) =>
        companyData.push({
          label: item.name,
          value: `${item._id}`,
        })
      );

      setAllCompanyData(companyData);
    }
  }, [companyResponse]);

  useEffect(() => {
    return () => {
      // cleanup initial values
      values.companyId = "";
    };
  }, []);

  const getTopTitle = () => {
    if (isAdd) {
      return Translate("project:addProject");
    }
  };

  return (
    <>
      <div className="row container_spacing">
        <div className="card card_spacing">
          <PageHeader
            title={<h5 className="page-title">{getTopTitle()}</h5>}
            onClick={() => navigate("/")}
            containerClass="page-title formTitle p-3 row align-items-center"
          />
          <div className="card-body">
            <div onKeyDown={(e) => handleEventKeySubmit(e, handleSubmit)}>
              <div className="row mt-3">
                <div className="form-group col-md-6 col-sm 12">
                  <FormikDropdownPicker
                    options={allCompanyData}
                    value={values?.companyId}
                    name="companyId"
                    label={Translate("vendors:selectCompany")}
                    placeholder={Translate("vendors:dropdownPlaceholder")}
                    required
                  />
                </div>
              </div>

              <div className="row">
                <div className="form-group col-md-6 col-sm-12">
                  <FormikTextField
                    name="title"
                    label={Translate("document:title")}
                    placeholder={Translate("document:title")}
                    autoComplete={"off"}
                    maxLength={validations?.documentTitle.MAX}
                    required
                  />
                </div>
              </div>

              <div className="form-group">
                <FormikTextField
                  name="description"
                  label={Translate("document:description")}
                  placeholder={Translate("document:description")}
                  autoComplete={"off"}
                  multiline
                  noOfRows={5}
                  maxLength={validations?.documentDescription.MAX}
                  required
                />
              </div>
            </div>
            <div className="text-right line_spacing">
              <button
                type="submit"
                className="btn btn-primary saveButton"
                onKeyDown={(e) => handleEventKeySubmit(e, handleSubmit)}
                onClick={handleSubmit}
              >
                {Translate("common:add")}
              </button>
            </div>
          </div>
        </div>
      </div>
      {<Loader loading={isLoading || isCompanyLoading} />}
    </>
  );
};

export default memo(ProjectForm);

export default {
  barTitle: "انجازية المهم",
  donugtTitle: "عامل الخطر",
  inprogressTitle: "مهمات قيد التنفيذ",
  eventTitle: "أحداث اليوم",
  custom: "مخصص",
  noDataMsg: "لم يتم العثور على بيانات الرسم البياني",
  day: "يوم",
  week: "أسبوع",
  month: "شهر",
  quarter: "ربع",
  year: "سنة",
  custom: "مخصص",
  Daily: "يوميًا",
  lastWeek: "الأسبوع الماضي",
  lastMonth: "الشهر الماضي",
  lastQuarter: "الربع الأخير",
  lastYear: "العام الماضي",
  customDate: "تاريخ مخصص",
  subject: "موضوع",
  description: "وصف",
  startTime: "وقت البدء",
  endTime: "وقت النهاية",
  dashboard: "لوحة القيادة",
  lowRisk: "منخفض الخطورة",
  mediumRisk: "متوسط الخطورة",
  highRisk: "عالي الخطورة",
};

import Translate from "../../../../translation/utils/translateFunction";
import { CustomTitleCellRender } from "../../../common/CustomCellRender";

export const getEmployeeColumns = (CustomCellActionRender) => {
  const columns = [
    {
      field: "serialNumber",
      headerName: Translate("addManager:srNoTableHeading"),
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "name",
      headerName: Translate("addManager:nameTableHeading"),
      flex: 0.5,
      sortable: true,
      disableColumnMenu: true,
      renderCell: (params) => CustomTitleCellRender(params?.row?.name),
    },
    {
      field: "mobile",
      headerName: Translate("addManager:mobileTableHeading"),
      flex: 0.4,
      sortable: true,
      disableColumnMenu: true,
    },
    {
      field: "companyName",
      headerName: Translate("addManager:companyNameTableHeading"),
      flex: 0.4,
      sortable: true,
      disableColumnMenu: true,
      renderCell: (params) => CustomTitleCellRender(params?.row?.companyName),
    },
    {
      field: "email",
      headerName: Translate("addManager:emailTableHeading"),
      flex: 0.4,
      sortable: true,
      disableColumnMenu: true,
      renderCell: (params) => CustomTitleCellRender(params?.row?.email),
    },
    {
      field: "Action",
      headerName: Translate("common:actionTitle"),
      flex: 0.4,
      sortable: false,
      disableColumnMenu: true,
      renderCell: CustomCellActionRender,
    },
  ];
  return columns;
};

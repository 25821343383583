import { Stack } from "@mui/material";
import React, { useState } from "react";
import ImageViewer from "react-simple-image-viewer";
import { colors } from "../../../../common/Theme/Colors";
import { convertTo12Hr } from "../../../../common/Utils/DateFormater";
import FOMImage from "../../../../assets/images/common/Image1 152_158.png";
import { iosImgTypes } from "../../../../common/Utils/ApiConstants";

export const ImageMessageView = ({ currentMessage, type, imageType }) => {
  const receiverUser = !currentMessage.isOwn;
  const [visible, setIsVisible] = useState(false);
  const isIos = iosImgTypes.includes(imageType);

  let imageData = [];
  imageData.push(currentMessage?.attachmentUrl);

  return (
    <Stack marginRight={1} className="cursor-pointer">
      <div
        onClick={() => {
          setIsVisible(true);
        }}
      >
        <img
          src={isIos ? FOMImage : currentMessage?.attachmentUrl}
          style={receiverUser ? styles.receiverImage : styles.senderImage}
          alt=""
        />
      </div>

      <Stack
        flexDirection="row"
        justifyContent="flex-end"
        style={styles.imageSendTime}
      >
        {type !== "People" && !currentMessage?.message && (
          <span className="time leftDirection">
            {convertTo12Hr(currentMessage?.updatedAt)}
          </span>
        )}
        {/* {senderUser && !currentMessage?.message && (
          <Icon
            name="sent_tick"
            size={16}
            color={colors.primary_003}
            style={styles.tickIcon}
          />
        )} */}
      </Stack>
      {visible && (
        <ImageViewer
          src={[isIos ? FOMImage : currentMessage?.attachmentUrl]}
          currentIndex={0}
          onClose={() => setIsVisible(false)}
          closeOnClickOutside
          disableScroll={false}
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,0.9)",
          }}
        />
      )}
    </Stack>
  );
};
const styles = {
  receiverImage: {
    height: 158,
    width: 152,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    borderBottomRightRadius: 6,
    borderWidth: 3,
    borderColor: colors.white,
  },
  senderImage: {
    height: 158,
    width: 152,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    borderBottomLeftRadius: 6,
    borderWidth: 3,
    borderColor: colors.grey_008,
  },
  imageSendTime: { alignSelf: "flex-end" },
  leftNameTime: {
    color: colors.black,
    marginRight: 10,
  },
  nameTime: {
    color: colors.black,
  },
  tickIcon: {
    marginLeft: 3,
    marginRight: 3,
  },
};

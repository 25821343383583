import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom/dist";
import {
  DateTimeFormats,
  formatDateYYYYMMDD,
} from "../../../common/Utils/DateFormater";
import { showToast } from "../../../common/Utils/ToastMessage";
import {
  getMoment,
  getNameAndExtFromFile,
  handleEventKeySubmit,
  validateDuplicateFiles,
} from "../../../common/Utils/commonFunction";
import { userTypes } from "../../../common/users/userTypes";
import { useAddLeaveRequestMutation } from "../../../request/LeaveRequest";
import { useLazyGetCompanyListQuery } from "../../../request/company";
import Translate from "../../../translation/utils/translateFunction";
import Dropzone from "../../CommonFileUpload/Dropzone";
import FormikDatePicker from "../../FormikForm/component/FormikDatePicker";
import { FormikDropdownPicker } from "../../FormikForm/component/FormikDropDownPicker";
import { FormikTextField } from "../../FormikForm/component/FormikTextField";
import CommonImage from "../../ImageGallery/CommonImage";
import PageHeader from "../../common/pageHeader";
import Loader from "../../staff/Owner/LoadingComp";
import { acceptedFileType, InitialValues } from "./constants";
import { AddLeaveSchema } from "./utils";

const LeaveRequest = () => {
  const navigate = useNavigate();
  const { validations, userData } = useSelector(
    (state) => state?.formanagement
  );
  const [filesAdded, setfilesAdded] = useState([]);
  const [images, setImages] = useState([]);
  const [allCompanyData, setAllCompanyData] = useState([]);
  const formikRef = useRef(null);
  const [getCompanyList, { data: companyResponse }] =
    useLazyGetCompanyListQuery();
  const [addLeave, { data: serverResponse, isSuccess: isAddLeaveSuccess }] =
    useAddLeaveRequestMutation();

  useEffect(() => {
    getCompanyList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (companyResponse) {
      let companyData = [];
      companyResponse.map((item) =>
        companyData.push({
          label: item.name,
          value: `${item._id}`,
        })
      );
      setAllCompanyData(companyData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyResponse]);

  useEffect(() => {
    if (isAddLeaveSuccess && serverResponse?.data) {
      showToast({ message: serverResponse?.message, type: "success" });
      resetData();
      navigate(-1);
    } else if (isAddLeaveSuccess && serverResponse) {
      showToast({ message: serverResponse?.message, type: "info" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAddLeaveSuccess]);
  useEffect(() => {
    if (filesAdded.length > 0) {
      const newImages = filesAdded.map((imageElement) =>
        imageElement.preview ? imageElement.preview : imageElement?.url
      );
      setImages(newImages);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filesAdded]);
  const resetData = () => {
    formikRef.current?.resetForm();
    setImages([]);
    setfilesAdded([]);
  };
  const addFiles = async (file) => {
    const url =
      file.type === "image/png" ||
      file.type === "image/jpg" ||
      file.type === "image/jpeg" ||
      file.type === "application/pdf" ||
      file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      file.type === "application/vnd.ms-excel" ||
      file.type === "doc"
        ? URL.createObjectURL(file)
        : null;

    const checkDuplicates = validateDuplicateFiles(file, filesAdded);
    if (checkDuplicates?.isDuplicate) {
      showToast({
        message: `File ${checkDuplicates?.fileName} ${Translate(
          "common:duplicateFileError"
        )}`,
        type: "error",
      });
      return;
    }
    if (url) {
      file.preview = url;
    }
    if (filesAdded.length <= 10) {
      filesAdded.push(file);
      setfilesAdded([...filesAdded]);
    } else {
      filesAdded.pop(file);
    }
  };
  const removeFile = (file) => {
    const deletedFiles = filesAdded.findIndex(
      (fileName) => fileName.path === file.path
    );
    if (deletedFiles !== -1) {
      filesAdded.splice(deletedFiles, 1);
      setfilesAdded([...filesAdded]);
    }
  };
  const onSubmit = (formValues) => {
    const timeSuffix = "T00:00:00.000+00:00";
    const filesArray = [];
    if (filesAdded.length) {
      filesAdded.map((item) => {
        filesArray.push({
          url: item?.b64,
          type: item?.type,
          fileName: item?.name,
          fileExt:
            getNameAndExtFromFile(item?.name)?.length &&
            getNameAndExtFromFile(item?.name)[1],
        });
      });
    }
    const requestObj = {
      companyId: formValues?.companyId,
      leaveReason: formValues.leaveReason,
      startDate: getMoment(formValues.fromDate).format(
        DateTimeFormats.YearMonthDay
      ),
      endDate: getMoment(formValues.toDate).format(
        DateTimeFormats.YearMonthDay
      ),
      startDateUtcObject: formatDateYYYYMMDD(formValues.fromDate) + timeSuffix,
      endDateUtcObject: formatDateYYYYMMDD(formValues.toDate) + timeSuffix,
      webAttachment: filesArray,
    };
    addLeave(requestObj);
  };
  return (
    <Formik
      initialValues={InitialValues}
      innerRef={formikRef}
      validateOnMount
      onSubmit={onSubmit}
      validationSchema={AddLeaveSchema}>
      {({ values, setFieldValue, handleSubmit }) => {
        return (
          <div className="row container_spacing">
            <div className="card card_spacing">
              <PageHeader
                title={Translate("drawer:applyLeave")}
                onClick={() => navigate(-1)}
                RightContainer={() =>
                  userData.role.type === userTypes.Manager.toUpperCase() ||
                  userData.role.type === userTypes.Employee.toUpperCase() ? (
                    <div className="card-options">
                      <a
                        href="/#"
                        className="nav-link dropdown-toggle"
                        data-toggle="dropdown"
                        role="button"
                        aria-haspopup="true"
                        aria-expanded="false">
                        <i className="fa fa-ellipsis-v manageIconColor" />
                      </a>

                      <div className="dropdown-menu dropdownAlignment">
                        <div
                          className="cursor-pointer"
                          onClick={() =>
                            navigate("/profile/applyLeave/myLeaveRequests")
                          }>
                          <a className="dropdown-item">
                            {Translate("leaveRequest:myLeave")}
                          </a>
                        </div>
                      </div>
                    </div>
                  ) : undefined
                }
              />
              <div
                className="card-body"
                onKeyDown={(e) => handleEventKeySubmit(e, handleSubmit)}>
                {/* <div className="row">
                  <div className="form-group col-md-4 col-sm 8">
                    <RadioButtons
                      radioOptions={leaveRadioOptions}
                      defaultChecked={
                        leaveType === leaveTypeEnums.fullDay ? 1 : 0
                      }
                      label={Translate("accountPage:laveType")}
                      onPress={(value) => setLeaveType(value.target.value)}
                      required
                    />
                  </div>
                </div> */}
                <div className="row">
                  <div className="form-group col-md-4 col-sm-12">
                    <FormikDropdownPicker
                      options={allCompanyData}
                      name="companyId"
                      label={Translate("addManager:selectCompany")}
                      placeholder={Translate("addManager:dropdownPlaceholder")}
                      onSelect={(value) => {
                        setFieldValue("companyId", value);
                      }}
                      required
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-4 col-sm-12">
                    <FormikDatePicker
                      label={Translate("customReport:fromDate")}
                      containerStyle="datePickerContainer"
                      className="form-control bw-0"
                      format="yyyy/MM/dd"
                      name="fromDate"
                      value={values.fromDate}
                      minDate={getMoment().add(1, "day").toDate()}
                      onChange={(value) => {
                        setFieldValue("fromDate", value);
                        setFieldValue("toDate", "");
                      }}
                      required
                    />
                  </div>
                  <div className="form-group col-md-4 col-sm-12">
                    <FormikDatePicker
                      label={Translate("customReport:toDate")}
                      containerStyle="datePickerContainer"
                      className="form-control bw-0"
                      format="yyyy/MM/dd"
                      name="toDate"
                      value={values.toDate}
                      onChange={(value) => {
                        setFieldValue("toDate", value);
                      }}
                      minDate={values.fromDate}
                      required
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-8 col-sm-12">
                    <FormikTextField
                      name="leaveReason"
                      label={Translate("accountPage:leaveReason")}
                      placeholder={Translate("accountPage:leaveReason")}
                      autoComplete={"off"}
                      maxLength={validations?.designationLength.MAX}
                      required
                    />
                  </div>
                </div>
                <div className="display mt-2">
                  <Dropzone
                    translate={Translate("accountPage:attachmentText")}
                    icon={"icon-paper-clip"}
                    onfileSelect={(filesArray) => addFiles(filesArray)}
                    acceptedImages={acceptedFileType}
                    maximumFiles={1}
                    maxFileSize={10485760}
                    fileLength={filesAdded.length}
                  />
                  <div className="AttachmentBoxStyling">
                    <CommonImage
                      images={images}
                      files={filesAdded}
                      removeFile={removeFile}
                    />
                  </div>
                </div>
              </div>

              <div className="card-footer text-right">
                <button
                  type="submit"
                  className="btn btn-primary saveButton"
                  onClick={handleSubmit}>
                  {Translate("common:apply")}
                </button>
              </div>
            </div>
            <Loader loading={false} />
          </div>
        );
      }}
    </Formik>
  );
};
export default LeaveRequest;

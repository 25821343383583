import React, { useEffect, useMemo, useState } from "react";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { useDebouncedCallback } from "use-debounce";
import CompanyHeader from "../../../common/CompanyHeader";
import { colors } from "../../../common/Theme/Colors";
import {
  StyledCard,
  StyledTypography,
} from "../../../common/Utils/StyledComponents";
import { showToast } from "../../../common/Utils/ToastMessage";
import { useGetProjectReportListMutation } from "../../../request/Project";
import Translate from "../../../translation/utils/translateFunction";
import CompanyListing from "../../ContactRepository/component";
import ListView from "../../ListView";
import Searchbar from "../../common/Searchbar";
import { getProjectReportColumns } from "./components/column.data";

const ProjectReport = ({ fixNavbar }) => {
  const { selectedCompanyList, isRtl } = useSelector(
    (state) => state.formanagement
  );

  const [searchText, setSearchText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [staffReports, setStaffReports] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPageNo, setLastPageNo] = useState(0);

  const [
    getProjectReport,
    {
      data: projectReport,
      isLoading: isProjectReportLoading,
      isError: isProjectReportError,
      error: projectReportError,
    },
  ] = useGetProjectReportListMutation();

  const requestBody = useMemo(() => {
    const companyIds = selectedCompanyList?.length
      ? selectedCompanyList?.map((item) => item?.value)
      : [];

    const reqObj = {
      companyId: companyIds,
      searchText,
      pageNo: currentPage,
    };

    return companyIds?.length ? reqObj : null;
  }, [searchText, currentPage, selectedCompanyList]);

  const debouncedSearch = useDebouncedCallback(() => {
    getProjectReport(requestBody);
  }, 500);

  useEffect(() => {
    setStaffReports([]);
    if (selectedCompanyList?.length) {
      setIsLoading(true);

      if (requestBody?.searchText?.length) {
        debouncedSearch();
      } else {
        getProjectReport(requestBody);
      }
    } else {
      showToast({
        type: "info",
        message: Translate("vendors:selectCompanyMessage"),
      });
    }
  }, [requestBody]);

  useEffect(() => {
    if (projectReport?.data?.record) {
      const { record, pageInfo } = projectReport.data;
      setStaffReports(record);
      setLastPageNo(pageInfo.lastPageNo);
      setIsLoading(false);
    }

    if (isProjectReportError && projectReportError) {
      if (projectReportError?.originalStatus === 404) {
        showToast({
          message: Translate("common:unknownServerError"),
          type: "error",
        });
      } else {
        projectReportError?.originalStatus !== 500 &&
          showToast({
            message:
              JSON.stringify(projectReportError?.data) ||
              Translate("common:unknownServerError"),
            type: "error",
          });
      }

      setIsLoading(false);
    }
  }, [projectReport, isProjectReportError, projectReportError]);

  const handleSearchText = (event) => {
    selectedCompanyList?.length > 0 &&
      event?.target?.value.trim() &&
      setIsLoading(true);
    setCurrentPage(1);
    setSearchText(event);
  };

  const CustomSrCellRender = (params) => {
    return (
      <StyledTypography component="span" sx={{ color: colors.primary_009 }}>
        {params?.api?.getRowIndexRelativeToVisibleRows(params?.row?._id) +
          1 +
          (currentPage - 1) * 10}
      </StyledTypography>
    );
  };

  return (
    <Grid
      item
      xs={12}
      className={`section-body ${isRtl ? "rtlMargin" : "ltrMargin"} ${
        fixNavbar ? "marginTop" : ""
      } `}
    >
      <StyledCard variant="outlined">
        <div className="container-fluid">
          <div className="tab-content">
            <div className="card cardHeight">
              <div className="row">
                <div className="col-12">
                  <div className="card-header fixedHeight">
                    <CompanyHeader setShowModal={setShowModal} />
                    <div className="card-options">
                      <div className="sort_stat commentTextField">
                        <form className="headerMargin">
                          <div className="input-group">
                            <Searchbar
                              handleSearchText={handleSearchText}
                              setSearch={setSearchText}
                            />
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <ListView
                  columns={getProjectReportColumns(CustomSrCellRender)}
                  rows={staffReports}
                  autoHeight
                  sortingMode="server"
                  onPaginationChange={setCurrentPage}
                  currentPage={currentPage}
                  showShimmer={isLoading || isProjectReportLoading}
                  showLoader={isLoading}
                  totalPageCount={lastPageNo}
                />
              </div>
            </div>
          </div>
        </div>
      </StyledCard>

      <CompanyListing
        handleModal={(value) => {
          setCurrentPage(1);
          setShowModal(value);
        }}
        showModal={showModal}
        selectedCompanyList={selectedCompanyList}
      />
    </Grid>
  );
};

export default ProjectReport;

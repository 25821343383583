import * as yup from "yup";
import Translate from "../../../../translation/utils/translateFunction";

const createErrorMessage = (field, errorMsg) => ({
  path: field,
  message: errorMsg,
});

export const getCustomReportValidation = (isOwner, isGM) => {
  const schema = yup
    .object()
    .shape({
      fromDate: yup
        .string()
        .trim()
        .required(Translate("customReport:fromDateError")),
      toDate: yup
        .string()
        .trim()
        .required(Translate("customReport:toDateError")),
      companyId: yup
        .string()
        .trim()
        .required(Translate("addOwner:companyError")),
      projectId: yup
        .string()
        .trim()
        .required(Translate("addTask:projectError")),
      priority: yup
        .array()
        .of(yup.string().trim())
        .required(Translate("addTask:priorityError"))
        .min(1, Translate("addTask:priorityError")),
    })
    .test(
      "oneOfRequired",
      Translate("customReport:ownerMultiFieldError"),
      function (values) {
        const { ownerId, gmId, managerId } = values;

        if (isOwner) {
          if (!ownerId && !gmId && !managerId) {
            return this.createError(
              createErrorMessage(
                "ownerId",
                Translate("customReport:ownerMultiFieldError")
              )
            );
          }
        } else if (isGM) {
          if (!gmId && !managerId) {
            return this.createError(
              createErrorMessage(
                "gmId",
                Translate("customReport:gmMultiFieldError")
              )
            );
          }
        } else {
          if (!managerId) {
            return this.createError(
              createErrorMessage(
                "managerId",
                Translate("customReport:managerError")
              )
            );
          }
        }

        return true;
      }
    );

  return schema;
};

import * as yup from "yup";
import Translate from "../../../translation/utils/translateFunction";

export const AddRenewalSchema = () => {
  const schema = yup.object().shape({
    companyId: yup.string().trim().required(Translate("renewals:companyError")),
    name: yup.string().trim().required(Translate("renewals:nameError")),
    documentCategory: yup
      .string()
      .trim()
      .required(Translate("renewals:categoryError")),
    registrationDate: yup
      .date()
      .required(Translate("renewals:registrationDateError")),
    isNotExpiry: yup.boolean().required("Required"),
    expiryDate: yup.date().when("isNotExpiry", {
      is: false,
      then: yup.date().required(Translate("renewals:expiryDateError")),
    }),
  });

  return schema;
};

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { showToast } from "../../../../common/Utils/ToastMessage";
import { useViewSharedContactBusinessCardMutation } from "../../../../request/ContactRepo";
import Translate from "../../../../translation/utils/translateFunction";
import CommonImage from "../../../ImageGallery/CommonImage";
import Loader from "../../../staff/Owner/LoadingComp";

import ListView from "../../../ListView";

import { StyledCard } from "../../../../common/Utils/StyledComponents";
import NoDataFound from "../../../ManageTask/NoDataFound";
import { Persona } from "../../../Persona";
import { VALIDATION_BUCKETURL } from "../../../Persona/constants";
import ViewBusinessCardCommon from "../../component/viewBusinessCardCommon";
import { getViewSharedListColumns } from "./column.data";
import { useSelector } from "react-redux";
import PageHeader from "../../../common/pageHeader";

const ViewCardSharedList = () => {
  const { isFixNavbar, isRtl } = useSelector((state) => state.formanagement);
  const navigate = useNavigate();
  const { state } = useLocation();
  const [viewBusinessCard, setViewBusinessCard] = useState([]);
  const [images, setImages] = useState([]);
  const [attachmentArray, setAttachmentArray] = useState([]);
  const [sharedDataList, setSharedDataList] = useState();
  const roleMapping = {
    OWNER: "Owner",
    MANAGER: "Manager",
    GM: "General Manager",
    VENDOR: "Vendor",
    EMPLOYEE: "Employee",
    PA: "PA",
  };

  const [sharedCompanyData, setSharedCompanyData] = useState([]);
  console.log("in shared viewBusinessCard", viewBusinessCard);
  const [
    viewBusinessCardApi,
    {
      data: businessCardData,
      isSuccess: isViewSuccess,
      isLoading: isViewLoading,
      isError: isViewError,
      error: errorViewBusinessCard,
    },
  ] = useViewSharedContactBusinessCardMutation();

  useEffect(() => {
    if (state) {
      viewBusinessCardApi(state);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewBusinessCardApi, state]);

  useEffect(() => {
    if (isViewError && errorViewBusinessCard) {
      showToast({
        message: errorViewBusinessCard?.data?.message,
        type: "error",
      });
    }

    if (isViewSuccess && businessCardData) {
      setViewBusinessCard(businessCardData?.data?.record);
      setSharedDataList(businessCardData?.data?.sharedUserData);

      if (businessCardData?.data?.record?.businessCardImage) {
        const obj = {
          path: decodeURIComponent(
            businessCardData?.data?.record?.businessCardImage
          )
            .split("/")
            .pop(),
          type: "image/png",
          url: businessCardData?.data?.record?.businessCardImage,
        };

        setAttachmentArray([obj]);
        setImages([businessCardData?.data?.record?.businessCardImage]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessCardData, errorViewBusinessCard]);

  // ************************************************************************listing**********************************

  useEffect(() => {
    if (
      !sharedDataList ||
      !Array.isArray(sharedDataList) ||
      sharedDataList.length === 0
    ) {
      setSharedCompanyData([]);
      return;
    }

    const companies = sharedDataList.reduce((result, item) => {
      const { companyName, role, users, companyId } = item;
      const roleName = roleMapping[role] || role;

      if (!result[companyName] && !result[companyId]) {
        result[companyName] = { companyName, companyId, roles: {} };
      }

      if (!result[companyName].roles[roleName]) {
        result[companyName].roles[roleName] = { roleName, users: [] };
      }

      result[companyName].roles[roleName].users.push(...users);

      return result;
    }, {});

    const companyDataArray = Object.values(companies).map((company) => ({
      ...company,
      roles: Object.values(company.roles),
    }));

    setSharedCompanyData(companyDataArray);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sharedDataList]);

  const CustomCellRender = (params) => {
    return (
      <td>
        <div style={{ display: "flex" }}>
          <Persona
            name={params?.row?.name}
            image={
              params?.row?.profileUrl?.includes(VALIDATION_BUCKETURL)
                ? params?.row?.profileUrl
                : ""
            }
            size={28}
          />
          <span className="text-info ml-2 mt-1">{params?.row?.name}</span>
        </div>
      </td>
    );
  };

  const totalUserCount = businessCardData?.data?.sharedUserData.reduce(
    (count, item) => count + item.users.length,
    0
  );

  return (
    <div
      className={`section-body ${isRtl ? "rtlMargin" : "ltrMargin"} ${
        isFixNavbar ? "marginTop" : ""
      } mt-3`}>
      <div className="card" style={{ marginBottom: 10 }}>
        <PageHeader
          title={
            <h5 className="page-title">{Translate("businessCard:head")}</h5>
          }
          onClick={() => navigate("/contactRepository/sharedListing")}
          containerClass="card-header p-3"
        />
      </div>

      <div style={{ display: "flex" }}>
        <div className="col-4 ">
          <ViewBusinessCardCommon
            viewBusinessCard={viewBusinessCard}
            isViewLoading={isViewLoading}
          />
        </div>

        <div className="col-8" style={{ width: "100%" }}>
          {isViewLoading === false && (
            <div className=" card CommentCard_spacing">
              <div className="commentLabel cursor-pointer">
                <span className="taskHeading font-17">
                  {Translate("businessCard:attachmentTitle")}
                </span>
                <div>
                  <i
                    className={
                      viewBusinessCard?.businessCardImage
                        ? "fa fa-caret-down "
                        : "fa fa-caret-up"
                    }
                  />
                </div>
              </div>

              {viewBusinessCard?.businessCardImage ? (
                <div className="AttachmentBoxStyling">
                  <CommonImage
                    images={images}
                    files={attachmentArray}
                    icon={true}
                  />
                </div>
              ) : (
                <NoDataFound
                  message={Translate("businessCard:attachmentErrMsg")}
                  isCenter
                  className="NoDataStyle"
                />
              )}
            </div>
          )}

          {isViewLoading === false && (
            <span style={{ fontWeight: 500, fontSize: 17 }}>
              This contact is shared with {totalUserCount} people:
            </span>
          )}

          {sharedCompanyData?.map((sharedData) => {
            return (
              <Accordion style={{ marginBottom: 10, marginTop: 10 }}>
                <AccordionSummary
                  id="first"
                  aria-controls="panel1"
                  expandIcon={
                    <i className="fa fa-caret-up" style={{ color: "black" }} />
                  }>
                  <Typography>{sharedData?.companyName}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {sharedData?.roles.map((rolesData) => {
                    const rowsWithSerialNumbers =
                      rolesData?.users &&
                      rolesData?.users.map((row, index) => ({
                        ...row,
                        _id: index + 1,
                        role: rolesData?.roleName,
                      }));

                    return (
                      <StyledCard variant="outlined">
                        <ListView
                          columns={getViewSharedListColumns(CustomCellRender)}
                          rows={rowsWithSerialNumbers}
                          hidePagination
                          isExpandedTable
                          rowsPerPage={rolesData?.users.length}
                        />
                      </StyledCard>
                    );
                  })}
                </AccordionDetails>
              </Accordion>
            );
          })}
        </div>
      </div>
      <Loader loading={isViewLoading} />
    </div>
  );
};
export default ViewCardSharedList;

import { Methods, Urls } from "../../../common/Utils/ApiConstants";
import { CreateApi } from "../../CreateApi";

const PerformanceCollection = CreateApi.injectEndpoints({
  endpoints: (builder) => ({
    getStaffPerformanceDetails: builder.mutation({
      query: (body) => {
        return {
          url: Urls.getStaffPerformanceDetails,
          method: Methods.post,
          body: body,
        };
      },
    }),
  }),
});

export const { useGetStaffPerformanceDetailsMutation } = PerformanceCollection;

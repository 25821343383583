export default {
  barTitle: "Task progress",
  donugtTitle: "Risk factor",
  inprogressTitle: "In-progress tasks",
  eventTitle: "Today's events",
  custom: "Custom",
  noDataMsg: "No graph data found",
  day: "DAY",
  week: "WEEK",
  month: "MONTH",
  quarter: "QUARTER",
  year: "YEAR",
  custom: "CUSTOM",
  Daily: "Daily",
  lastWeek: "Last week",
  lastMonth: "Last month",
  lastQuarter: "Last quarter",
  lastYear: "Last year",
  customDate: "Custom date",
  subject: "Subject",
  description: "Description",
  startTime: "Start date and time",
  endTime: "Due date and time",
  dashboard: "Dashboard",
  lowRisk: "Low risk",
  mediumRisk: "Medium risk",
  highRisk: "High risk",
};

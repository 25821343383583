import React from "react";
import { priorities } from "./constants";
import { styles } from "../TextField/TextField/index.Style";
import RadioButtons from "../RadioButtons";
import { checkBoxOption } from "./constants";
import { useFormikContext } from "formik";

const Priority = ({
  value,
  markAsCriticalState,
  onPress,
  label,
  defaultCheck = undefined,
  disabled
}) => {
  const { values, setFieldValue } = useFormikContext();

  return (
    <>
      <div>
        {label !== undefined && label?.length > 0 && (
          <div>
            <span className="labelStyling" style={styles.label}>{label}</span>
          </div>
        )}
      </div>

      <div className="row priorityBtnStyle">
        {priorities.map((item) => {
          const isSelected = value === item;
          return (
            <div
              className={
                isSelected
                  ? `priority ${item.toLowerCase()}Selected`
                  : `priority ${item.toLowerCase()}`
              }
              style={{ cursor: disabled ? "default" : "cursor" }}
              onClick={() => !disabled && onPress(item)}
            >
              {item}
            </div>
          );
        })}
        {!markAsCriticalState && (
          <div className="critical">
            <RadioButtons
              radioOptions={checkBoxOption}
              onPress={() => {
                setFieldValue("markAsCritical", !values.markAsCritical);
              }}
              isCheckBox
              defaultCheck={defaultCheck}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default Priority;

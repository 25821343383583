import React, { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { showToast } from "../../../common/Utils/ToastMessage";
import { getNameAndExtFromFile } from "../../../common/Utils/commonFunction";
import { useLazyGetCompanyListQuery } from "../../../request/company";
import Translate from "../../../translation/utils/translateFunction";
import Dropzone from "../../CommonFileUpload/Dropzone";
import { FormikDropdownPicker } from "../../FormikForm/component/FormikDropDownPicker";
import { FormikTextField } from "../../FormikForm/component/FormikTextField";
import CommonImage from "../../ImageGallery/CommonImage";
import { styles } from "../../TextField/TextField/index.Style";
import PageHeader from "../../common/pageHeader";
import Loader from "../../staff/Owner/LoadingComp";
import { handleEventKeySubmit } from "../../../common/Utils/commonFunction";

const DocumentForm = ({
  isSharedDocument,
  isEdit,
  isView,
  filesAdded,
  setfilesAdded,
  setImages,
  images,
  isLoading,
  values,
  handleSubmit,
  setDeletedAttachments,
  documentData,
  setCreateTask,
  isAdd,
}) => {
  const navigate = useNavigate();
  const { validations } = useSelector((state) => state?.formanagement);

  const [allCompanyData, setAllCompanyData] = useState([]);

  const acceptedFileType = {
    "image/png": [".png", ".jpg", ".jpeg", ".pdf"],
  };

  const [
    getCompanyList,
    { data: companyResponse, isLoading: isCompanyLoading },
  ] = useLazyGetCompanyListQuery();

  useEffect(() => {
    getCompanyList();
  }, []);

  useEffect(() => {
    if (companyResponse) {
      const companyData = [];

      companyResponse.map((item) =>
        companyData.push({
          label: item.name,
          value: `${item._id}`,
        })
      );

      setAllCompanyData(companyData);
    }
  }, [companyResponse]);

  useEffect(() => {
    return () => {
      // cleanup initial values
      values.companyId = "";
    };
  }, []);

  const addFiles = (file) => {
    const { type, name } = file;
    const fileTypes = ["image/png", "image/png", "image/jpeg"];

    const splitName = getNameAndExtFromFile(name);
    file["documentFileName"] = name;
    file["documentFileExt"] = splitName[1] || "";
    const url = fileTypes.includes(type) ? URL.createObjectURL(file) : null;
    if (url) file.preview = url;
    if (filesAdded.length < 1) {
      filesAdded.push(file);
      setfilesAdded([...filesAdded]);
      const newImages = filesAdded.map((imageElement) => imageElement.preview);
      setImages(newImages);
    } else {
      showToast({ message: Translate("document:fileLimit"), type: "error" });
    }
  };

  const removeFile = (file) => {
    const deletedFiles = filesAdded.filter(
      (fileName) => fileName.name !== file.name
    );
    if (isEdit) {
      setDeletedAttachments(file);
    }
    setfilesAdded(deletedFiles);
  };

  const handleTaskNavigation = () => {
    if (isView) {
      return navigate("/addTask", {
        state: {
          isMyDocument: true,
          documentId: documentData?.data?._id,
          title: documentData?.data?.title,
          description: documentData?.data?.description,
          attachment: documentData?.data?.attachment,
          company:
            documentData?.data?.companyId?.length > 0 &&
            documentData?.data?.companyId[0]?._id,
        },
      });
    } else {
      setCreateTask(true);
      handleSubmit();
    }
  };

  const handleAccessLogNavigation = () => {
    return isSharedDocument
      ? navigate("/documentRepository/sharedWithMe/accessLogs", {
          state: documentData?.data?._id,
        })
      : navigate("/documentRepository/myDocuments/accessLogs", {
          state: documentData?.data?._id,
        });
  };

  return (
    <div className="row container_spacing">
      <div className="card card_spacing">
        <PageHeader
          title={
            <h5 className="page-title">
              {isView
                ? Translate("document:viewDocument")
                : isEdit
                ? Translate("document:editDocument")
                : Translate("document:addDocument")}
            </h5>
          }
          onClick={() => {
            isSharedDocument
              ? isView && navigate("/documentRepository/sharedWithMe")
              : isEdit
              ? navigate("/documentRepository/myDocuments/view", {
                  state: documentData?._id,
                })
              : navigate("/documentRepository/myDocuments");
          }}
          RightContainer={() =>
            !isSharedDocument &&
            isView &&
            documentData?.data && (
              <div className="card-options">
                <i
                  className={`icon-color ml-3 ${"fa fa-pencil-square-o"} cursor-pointer`}
                  onClick={() =>
                    navigate("/documentRepository/myDocuments/edit", {
                      state: documentData?.data,
                    })
                  }
                ></i>
              </div>
            )
          }
          containerClass="page-title formTitle p-3 row align-items-center"
        />
        <div className="card-body">
          <div onKeyDown={(e) => handleEventKeySubmit(e, handleSubmit)}>
            <div className="row mt-3">
              <div className="form-group col-md-6 col-sm 12">
                {isView ? (
                  <FormikTextField
                    name="companyId"
                    label={Translate("vendors:selectCompany")}
                    placeholder={Translate("vendors:selectCompany")}
                    autoComplete={"off"}
                    maxLength={validations?.documentTitle.MAX}
                    disabled
                  />
                ) : (
                  <FormikDropdownPicker
                    options={allCompanyData}
                    value={values?.companyId}
                    name="companyId"
                    label={Translate("vendors:selectCompany")}
                    placeholder={Translate("vendors:dropdownPlaceholder")}
                    required
                    autoFocus={isAdd}
                  />
                )}
              </div>
            </div>

            <div className="row">
              <div className="form-group col-md-6 col-sm-12">
                <FormikTextField
                  name="title"
                  label={Translate("document:title")}
                  placeholder={Translate("document:title")}
                  autoComplete={"off"}
                  maxLength={validations?.documentTitle.MAX}
                  disabled={isView}
                  required={!isView}
                />
              </div>
            </div>

            <div className="form-group">
              <FormikTextField
                name="description"
                label={Translate("document:description")}
                placeholder={Translate("document:description")}
                autoComplete={"off"}
                multiline
                noOfRows={5}
                maxLength={validations?.documentDescription.MAX}
                disabled={isView}
                required={!isView}
              />
            </div>
          </div>
          <div className="row imageDisplay">
            <div className="form-group col-md-4 col-sm 8">
              {!isView && (
                <Dropzone
                  translate={Translate("document:attach")}
                  icon={"icon-paper-clip"}
                  onfileSelect={(filesArray) => addFiles(filesArray)}
                  acceptedImages={acceptedFileType}
                  maximumFiles={1}
                  maxFileSize={52428800}
                  fileLength={filesAdded.length}
                />
              )}
              {filesAdded &&
                filesAdded.length > 0 &&
                (isView ? (
                  <>
                    <span style={styles.label}>
                      {Translate("document:attachment")}
                    </span>
                    <CommonImage
                      images={images}
                      icon={true}
                      files={filesAdded}
                    />
                  </>
                ) : (
                  <CommonImage
                    images={images}
                    files={filesAdded}
                    removeFile={removeFile}
                  />
                ))}
            </div>
          </div>
          {<Loader loading={isLoading || isCompanyLoading} />}

          <div className="text-right line_spacing">
            <>
              <button
                type="submit"
                className="btn btn-primary saveButton"
                onKeyDown={(e) => handleEventKeySubmit(e, handleTaskNavigation)}
                onClick={handleTaskNavigation}
              >
                {Translate("document:createButton")}
              </button>
              {isView ? (
                <button
                  type="submit"
                  className="btn btn-white addButton ml-4"
                  onKeyDown={(e) =>
                    handleEventKeySubmit(e, handleAccessLogNavigation)
                  }
                  onClick={handleAccessLogNavigation}
                >
                  {Translate("document:accessButton")}
                </button>
              ) : (
                <button
                  type="submit"
                  className="btn btn-primary saveButton ml-4"
                  onKeyDown={(e) => handleEventKeySubmit(e, handleSubmit)}
                  onClick={handleSubmit}
                >
                  {Translate("document:save")}
                </button>
              )}
            </>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(DocumentForm);

import { Methods, Urls } from "../../common/Utils/ApiConstants";
import { CreateApi } from "../CreateApi";

const TaskCollection = CreateApi.injectEndpoints({
  endpoints: (builder) => ({
    getAssignToCollection: builder.query({
      query: (data) => ({
        url: Urls.assignToList,
        method: Methods.post,
        body: data,
      }),
      transformResponse: (response) => {
        return response.data;
      },
    }),
    getReportToCollection: builder.query({
      query: (data) => ({
        url: Urls.reportToList,
        method: Methods.post,
        body: data,
      }),
      transformResponse: (response) => {
        return response.data;
      },
    }),
    getRelatedTaskCollection: builder.query({
      query: ({ companyId, taskId }) => {
        return {
          url: `${Urls.relatedTask}${companyId}&currentTaskId=${taskId}`,
          method: Methods.get,
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
    }),
    AddTask: builder.mutation({
      query: (body) => ({
        url: Urls.addTask,
        method: Methods.post,
        body: body,
      }),
    }),
    getManageTaskCollection: builder.mutation({
      query: (body) => {
        return {
          url: Urls.taskList,
          method: Methods.post,
          body: body,
        };
      },
    }),

    getSelfAssignedTaskCollection: builder.query({
      query: (pageNo) => {
        return {
          url: Urls.selfTaskList,
          method: Methods.get,
          params: { pageNo },
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
    }),
    getTaskDetailQuery: builder.query({
      query: (taskId) => {
        return {
          url: `${Urls.getTaskDetail}${taskId}`,
          method: Methods.get,
        };
      },
      transformResponse: (response) => {
        return response;
      },
    }),

    getComments: builder.query({
      query: (data) => ({
        url: `${Urls.getComments1}${data.taskId}${Urls.getComments2}${data.pageNo}`,
        method: Methods.get,
      }),
      transformResponse: (response) => {
        return response.data;
      },
    }),
    editTaskCollection: builder.mutation({
      query: (body) => {
        return {
          url: Urls.editTask,
          method: Methods.put,
          body: body,
        };
      },
    }),
    taskHistory: builder.mutation({
      query: ({ taskId, pageNo }) => ({
        url: `${Urls.taskHistory}${taskId}/${pageNo}`,
        method: Methods.get,
      }),
    }),

    pinTask: builder.mutation({
      query: (body) => {
        return {
          url: Urls.pinTask,
          method: Methods.post,
          body: body,
        };
      },
    }),
    criticalTask: builder.mutation({
      query: (body) => {
        return {
          url: Urls.criticalTask,
          method: Methods.post,
          body: body,
        };
      },
    }),
    addComment: builder.mutation({
      query: (data) => {
        return {
          url: Urls.addComment,
          method: Methods.post,
          body: data,
          transformResponse: (response) => {
            return response.data;
          },
        };
      },
    }),
    editComment: builder.mutation({
      query: (data) => {
        return {
          url: `${Urls.editComment}${data.commentId}`,
          method: Methods.put,
          body: data,
          transformResponse: (response) => {
            return response.update;
          },
        };
      },
    }),
    deleteComment: builder.mutation({
      query: (data) => {
        return {
          url: Urls.deleteComment,
          method: Methods.delete,
          body: data,
        };
      },
    }),
    setTaskReminder: builder.mutation({
      query: (data) => {
        return {
          url: Urls.addTaskReminder,
          method: Methods.post,
          body: data,
        };
      },
    }),
    acceptTaskByAssignee: builder.mutation({
      query: (data) => {
        return {
          url: Urls.acceptTaskByAssignee,
          method: Methods.put,
          body: data,
        };
      },
    }),
    rejectTaskByAssignee: builder.mutation({
      query: (data) => {
        return {
          url: Urls.rejectTaskByAssignee,
          method: Methods.put,
          body: data,
        };
      },
    }),
    startTask: builder.mutation({
      query: (data) => {
        return {
          url: Urls.startTask,
          method: Methods.put,
          body: data,
        };
      },
    }),
    startSelfAssignTask: builder.mutation({
      query: (data) => {
        return {
          url: Urls.startSelfAssignTask,
          method: Methods.put,
          body: data,
        };
      },
    }),
    resolveTask: builder.mutation({
      query: (data) => {
        return {
          url: Urls.resolveTask,
          method: Methods.put,
          body: data,
        };
      },
    }),
    approveTaskByReporter: builder.mutation({
      query: (data) => {
        return {
          url: Urls.approveTaskByReporter,
          method: Methods.put,
          body: data,
        };
      },
    }),
    disapproveTaskByReporter: builder.mutation({
      query: (data) => {
        return {
          url: Urls.disapproveTaskByReporter,
          method: Methods.put,
          body: data,
        };
      },
    }),
    markTaskAsCompleted: builder.mutation({
      query: (data) => {
        return {
          url: Urls.markTaskAsCompleted,
          method: Methods.put,
          body: data,
        };
      },
    }),
    addSubTask: builder.mutation({
      query: (body) => ({
        url: Urls.addSubtask,
        method: Methods.post,
        body: body,
      }),
    }),
    getSubtaskList: builder.mutation({
      query: ({ taskId, pageNo }) => ({
        url: `${Urls.getSubtaskList}${taskId}/${pageNo}`,
        method: Methods.get,
      }),
    }),
    getSubtaskDetail: builder.query({
      query: (taskId) => {
        return {
          url: `${Urls.getSubtaskDetails}${taskId}`,
          method: Methods.get,
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
    }),
    editSubtask: builder.mutation({
      query: (body) => {
        return {
          url: Urls.editSubtask,
          method: Methods.put,
          body: body,
        };
      },
    }),
    deleteSubtask: builder.mutation({
      query: (data) => {
        return {
          url: Urls.deleteSubtask,
          method: Methods.delete,
          body: data,
        };
      },
    }),
    reassignTask: builder.mutation({
      query: (data) => {
        return {
          url: Urls.reassignTask,
          method: Methods.put,
          body: data,
        };
      },
    }),
    deleteTask: builder.mutation({
      query: (body) => {
        return {
          url: Urls.deleteTask,
          body: body,
          method: Methods.delete,
        };
      },
    }),
    reAllocateTask: builder.mutation({
      query: (data) => {
        return {
          url: Urls.reAllocateTask,
          method: Methods.put,
          body: data,
        };
      },
    }),
    disApproveReAllocateTaskReq: builder.mutation({
      query: (data) => {
        return {
          url: Urls.disapproveReAllocateTaskReq,
          method: Methods.put,
          body: data,
        };
      },
    }),
    reAllocateTaskToAssignee: builder.mutation({
      query: (data) => {
        return {
          url: Urls.reAllocateTaskToAssignee,
          method: Methods.put,
          body: data,
        };
      },
    }),
    getReportedByPAList: builder.query({
      query: (pageNo) => {
        return {
          url: Urls.reportedByPA,
          method: Methods.get,
          params: { pageNo },
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
    }),
    assignTaskFromRejected: builder.mutation({
      query: (data) => {
        return {
          url: Urls.assignTaskFromRejected,
          method: Methods.put,
          body: data,
        };
      },
    }),
    getTaskDependancy: builder.mutation({
      query: (data) => {
        return {
          url: Urls.getTaskDependancy,
          method: Methods.post,
          body: data,
        };
      },
    }),
    getShareTask: builder.mutation({
      query: (data) => {
        return {
          url: Urls.shareTask,
          method: Methods.post,
          body: data,
        };
      },
    }),
    getEmployeeRequestCollection: builder.query({
      query: (pageNo) => {
        return {
          url: Urls.getEmployeeRequestList,
          method: Methods.get,
          params: { pageNo },
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
    }),
    approveTaskByManager: builder.mutation({
      query: (data) => {
        return {
          url: Urls.approveTaskByManager,
          method: Methods.put,
          body: data,
        };
      },
    }),
    disapproveTaskByManager: builder.mutation({
      query: (data) => {
        return {
          url: Urls.disapproveTaskByManager,
          method: Methods.put,
          body: data,
        };
      },
    }),
  }),
});
export const {
  useAddTaskMutation,
  useLazyGetAssignToCollectionQuery,
  useLazyGetReportToCollectionQuery,
  useLazyGetRelatedTaskCollectionQuery,
  useGetManageTaskCollectionMutation,
  useLazyGetSelfAssignedTaskCollectionQuery,
  useLazyGetTaskDetailQueryQuery,
  useLazyGetCommentsQuery,
  useEditTaskCollectionMutation,
  useTaskHistoryMutation,
  usePinTaskMutation,
  useCriticalTaskMutation,
  useAddCommentMutation,
  useEditCommentMutation,
  useDeleteCommentMutation,
  useSetTaskReminderMutation,
  useAcceptTaskByAssigneeMutation,
  useRejectTaskByAssigneeMutation,
  useStartTaskMutation,
  useStartSelfAssignTaskMutation,
  useResolveTaskMutation,
  useApproveTaskByReporterMutation,
  useDisapproveTaskByReporterMutation,
  useMarkTaskAsCompletedMutation,
  useAddSubTaskMutation,
  useGetSubtaskListMutation,
  useLazyGetSubtaskDetailQuery,
  useEditSubtaskMutation,
  useDeleteSubtaskMutation,
  useReassignTaskMutation,
  useDeleteTaskMutation,
  useReAllocateTaskMutation,
  useDisApproveReAllocateTaskReqMutation,
  useReAllocateTaskToAssigneeMutation,
  useLazyGetReportedByPAListQuery,
  useAssignTaskFromRejectedMutation,
  useGetTaskDependancyMutation,
  useGetShareTaskMutation,
  useLazyGetEmployeeRequestCollectionQuery,
  useApproveTaskByManagerMutation,
  useDisapproveTaskByManagerMutation
} = TaskCollection;

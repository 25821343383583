export default {
  head: "تفاصيل المهمة",
  dueDate: "تاريخ التسليم",
  dueTime: "توقيت الإستحقاق",
  status: "الحالة",
  priority: "الأولوية",
  company: "الشركة",
  companyName: "اسم الشركة",
  task: "المهمة ",
  taskName: "اسم المهمة",
  description: "الوصف",
  assignee: "المُكلّف",
  assigneeName: "إسم المُكلّف",
  designation: "وظيفة المُكلّف",
  designationAssignto: "الوظيفة",
  addComment: "أضف تعليق",
  commentHead: "تعليق",
  attachment: "مرفق",
  approve: "قبول",
  disapprove: "رفض",
  alert: "هل أنت متأكد من عدم قبول المهمة؟",
  reopen: "أُعيد فتحها",
  reason: "سبب إعادة فتح المهمة :",
  reasonDisapprove: "سبب عدم الموافقة على المهمة:",
  addDetailsPlaceholder: "أضف تفاصيل",
  resolve: "تم حلها",
  start: "ابدأ",
  reAllocateTo: "إعادة التخصيص إلى",
  comment: "تعليق من المالك",
  reporter: "الموظف",
  reporterName: "اسم الموظف",
  designationReporter: "وظيفة الموظف",
  alertDelete: "هل أنت متأكد من حذف هذه المهمة؟",
  alertDeleteComment: "هل أنت متأكد من حذف هذا التعليق ؟",
  alertResolve: "هل أنت متأكد من أنك تريد حل هذه المهمة؟",
  alertStart: "هل أنت متأكد من بدء هذه المهمة؟",
  alertReopen: "هل أنت متأكد من إعادة فتح هذه المهمة؟",
  date: "التاريخ",
  time: "الوقت",
  completed: "مكتمل ",
  markAsCompleted: "وضع علامة كمكتمل",
  pendingApproval: "في إنتظار الموافقة",
  editTaskDetails: "تعديل تفاصيل المهمة",
  addSubtask: "أضف مهمة فرعية",
  edited: "مُعدّلة",
  parentTask: "اسم المهمة الأساسية",
  reAllocate: "إعادة التخصيص",
  alertMarkAsCompleted: "هل أنت متأكد اتمام هذه المهمة؟",
  discard: "تجاهل",
  alertCompleted: "هل أنت متأكد من حفظ التغيرات؟",
  resolved: "تم حلها",
  relatedTask: "اسم المهمة ذات صلة",
  pinTask: "تثبيت المهمة",
  unpinTask: "إزالة تثبيت المهمة",
  markAsCritical: `وضع علامة "مهم جداً"`,
  removeFromCritical: `إزالة علامة " مهم جداً"`,
  createGroup: "انشاء مجموعة",
  alarm: "منبه",
  reAssignTo: "إعادة تكليف إلى",
  reminder: "تذكير",
  reminderFor: "تذكير ب",
  remindTo: "تذكير لِ",
  startDate: "تاريخ االبدء",
  startTime: "توقيت البدء",
  subTaskName: "اسم المهمة الفرعية",
  chat: "مرتبط بالمحادثة",
  taskHistory: "سجل المهام",
  voiceNote: "تسجيل صوتي",
  alertRemoveVoiceNote: "هل أنت متأكد من حذف الرسالة الصوتية؟",
  rejectRequestAlert: "هل أنت متأكد من رفض هذا الطلب؟",
  rejectTaskAlert: "هل أنت متأكد من رفض المهمة؟",
  disapproveTaskAlert: "هل أنت متأكد من عدم قبول المهمة؟",
  rejectReasonAlert: "سبب رفض المهمة",
  disapproveReasonAlert: "سبب عدم الموافقة على المهمة:",
  acceptButtonLabel: "قبول",
  rejectButtonLabel: "رفض",
  startSelfButtonLabel: "ابدأ",
  startButtonLabel: "ابدأ",
  resolveButtonLabel: "تم حلها",
  markAsCompletedButtonLabel: "وضع علامة كمكتمل ",
  approveButtonLabel: "قبول",
  disapproveButtonLabel: "رفض",
  addReminderButtonLabel: "حفظ",
  taskReminderHeading: "تذكير",
  saveCustomReminderButtonLabel: "حفظ",
  closeCustomReminderButtonLabel: "يغلق",
  timeError: "التوقيت حقل مطلوب",
  reminderForError: "التذكير حقل مطلوب ",
  remindToError: "التذكير حقل مطلوب",
  startDateError: "تاريخ البدء حقل مطلوب",
  startTimeError: "توقيت البدء حقل مطلوب",
  saveButtonLabel: "حفظ",
  reasonInputPlaceholder: "أضف تفاصيل",
  viewLinkedSubtaskHeading: "مهمة فرعية مرتبطة",
  viewLinkedSubtaskLabel: "عرض المهمة الفرعية المرتبطة",
  viewTaskDependencyLabel: "اعتمادية المهمة",
  addSubtaskBtnLabel: "أضف مهمة فرعية",
  reminderTimeLabel: "الوقت",
  subtaskHead: "تفاصيل الواجب الفرعي",
  reminderToolTip: "تذكير",
  startDateLabel: "تاريخ االبدء",
  dueDateLabel: "تاريخ التسليم",
  reassign: "إعادة تكليف إلى",
  saveChange: "حفظ التغييرات",
  linkedwithChat: "مرتبط بالمحادثة",
  linkedwithEmail: "مرتبط بالإيميل",
  deleteAlertMsg: "هل أنت متأكد من حذف هذه المهمة؟",
  reAllocateButtonLabel: "إعادة تخصيص",
  alertReAllocationTask: "هل أنت متأكد من إعادة تخصيص هذا الواجب؟",
  alertReAllocationSubtask: "هل أنت متأكد من إعادة تخصيص هذا الواجب الفرعي؟",
  alertReAllocationDisApprove:
    "هل أنت متأكد من عدم الموافقة على طلب إعادة التخصيص لهذه المهمة ؟",
  alertReAllocationDisapproveReason: "سبب عدم الموافقة على طلب إعادة التخصيص",
  reAllocationReqTaskError: "لقد أرسلت بالفعل طلب إعادة التخصيص لهذا الواجب.",
  reAllocationReqSubtaskError:
    "لقد أرسلت بالفعل طلب إعادة التخصيص لهذا الواجب الفرعي.",
  reAssignUserTaskError: "لا يمكنك تعيين المهمة لنفس المستخدم",
  reAssignUserSubtaskError: "لا يمكنك تعيين الواجب الفرعي لنفس المستخدم",
  createGroupLabel: "انشاء مجموعة",
  deleteSubtask: "هل أنت متأكد من حذف هذا الواجب الفرعي؟",
  reopenTaskTitle: "سبب إعادة فتح المهمة",
  rejectedTaskTitle: "سبب رفض المهمة",
  reAllocationTaskErrorTitle: "سبب عدم الموافقة على طلب إعادة التخصيص",
  addReasonError: "يرجى إضافة سبب",
  linkedWithGroup: "مرتبط بالمجموعة",
  subtaskTitle: "مهمة فرعية",
  share: "يشارك",
  copyTitle: "مهمة النسخ",
  reAssignee: "إعادة تكليف",
  reAssigneeName: "اسم إعادة تكليف",
  reAssigneeDesignation: "مسمى إعادة تكليف",
  assignemeail: "البريد الإلكتروني للمكلف",
  reporterEmail: "البريد الإلكتروني للمراسل",
  reassignEmail: "إعادة تعيين البريد الإلكتروني",
};

import React, { useEffect, useState } from "react";
import { useGetNotificationListMutation } from "../../request/Notifications";
import {
  StyledCard,
  StyledTypography,
} from "../../common/Utils/StyledComponents";
import { showToast } from "../../common/Utils/ToastMessage";
import ListView from "../ListView";
import { getNotificationColumns } from "./Components/column.data";
import { Grid } from "@mui/material";
import { colors } from "../../common/Theme/Colors";
import { useNavigate } from "react-router-dom";
import Translate from "../../translation/utils/translateFunction";
import { useSelector } from "react-redux";

const NotificationListing = ({ fixNavbar }) => {
  const navigate = useNavigate();
  const { isRtl } = useSelector((state) => state.formanagement);
  const [isLoading, setIsLoading] = useState(false);
  const [lastPage, setLastPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [notifications, setNotifications] = useState([]);

  const [
    fetchNotificationListApi,
    {
      data: notificationList,
      isLoading: isNotificationLoading,
      isError: isNotificationError,
      error: notificationError,
    },
  ] = useGetNotificationListMutation();

  useEffect(() => {
    fetchNotificationListApi({
      pageNo: currentPage,
    });
  }, [currentPage]);

  useEffect(() => {
    if (notificationList?.data?.nodes) {
      const { nodes, pageInfo } = notificationList.data;
      setNotifications(nodes);
      setLastPage(pageInfo.lastPageNo);
      setIsLoading(false);
    }

    if (notificationError && isNotificationError) {
      notificationError?.originalStatus !== 500 &&
        showToast({
          message: JSON.stringify(notificationError.data),
          type: "error",
        });
      setIsLoading(false);
    }
  }, [notificationList]);

  const CustomSrCellRender = (params) => {
    return (
      <StyledTypography component="span" sx={{ color: colors.primary_009 }}>
        {params?.api?.getRowIndexRelativeToVisibleRows(params?.row?._id) +
          1 +
          (currentPage - 1) * 10}
      </StyledTypography>
    );
  };

  const CustomActionCellRender = (params) => {
    return (
      <td>
        <i
          className="m-2 fa fa-eye cursor-pointer manageIconColor"
          onClick={() =>
            navigate("/notifications/view", {
              state: params?.row,
            })
          }
          data-toggle="tooltip"
          title={Translate("common:viewToolTip")}
        ></i>
      </td>
    );
  };

  return (
    <Grid
      item
      xs={12}
      className={`section-body ${isRtl ? "rtlMargin" : "ltrMargin"} ${
        fixNavbar ? "marginTop" : ""
      } `}
    >
      <StyledCard variant="outlined">
        <div className="container-fluid p-4">
          <div className="tab-content">
            <div>
              <div className="card">
                <ListView
                  columns={getNotificationColumns(
                    CustomSrCellRender,
                    CustomActionCellRender
                  )}
                  rows={notifications}
                  autoHeight
                  onPaginationChange={setCurrentPage}
                  currentPage={currentPage}
                  showShimmer={isNotificationLoading}
                  showLoader={isLoading}
                  totalPageCount={lastPage}
                />
              </div>
            </div>
          </div>
        </div>
      </StyledCard>
    </Grid>
  );
};

export default NotificationListing;

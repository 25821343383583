export default {
  totalAssigned: "Total assigned",
  inProgress: "In-progress",
  overdue: "Overdue",
  staffReport: "Staff report",
  completed: "Completed",
  configure: "Configure",
  holidayTitle: "Set holidays",
  weekendTitle: "Set weekends",
  occasionTitle: "Add occasion",
  attendanceViewTitle: "Attendance report detail",
  changeStatus: "On leave",
  holiday: "Holiday",
  absentTitle: "Absent",
  ConfigureTitle: "Configuration",
  addOcassion: "Add occasion",
  leaveRequest: "Leave request",
  appliedFor: "Applied for",
  leaveDisApproveAlert: "Are you sure you want to disapprove the request?",
};

import React, { useRef, useState, useEffect } from "react";
import { InitialValues } from "../Components/constants";
import { AddRenewalSchema } from "../Components/validations";
import { useNavigate, useLocation } from "react-router-dom";
import RenewalForm from "../Components/RenewalForm";
import { useEditRenewalDataMutation } from "../../../request/Renewals";
import { showToast } from "../../../common/Utils/ToastMessage";
import { Formik } from "formik";
import {
  formatDateYYYYMMDD,
  formatDateToISOString,
} from "../../../common/Utils/DateFormater";
import Translate from "../../../translation/utils/translateFunction";
import { EncryptData } from "../../../common/Utils/EncryptData";

const EditRenewals = () => {
  const formikRef = useRef(false);
  const { state } = useLocation();
  const navigate = useNavigate();

  const [filesAdded, setFilesAdded] = useState([]);
  const [images, setImages] = useState([]);
  const [deletedAttachments, setDeletedAttachments] = useState([]);

  const [
    editRenewalApi,
    {
      data: editRenewalData,
      isLoading: isRenewalLoading,
      isSuccess: isRenewalSuccess,
      isError: isRenewalError,
      error: editRenewalError,
    },
  ] = useEditRenewalDataMutation();

  useEffect(() => {
    if (state?.data) {
      const { data } = state;
      const { attachment, isNotExpiry, companyId } = data;
      InitialValues.companyId = companyId?._id;
      formikRef.current.setFieldValue("companyId", companyId?._id);
      formikRef.current.setFieldValue("name", data?.name);
      formikRef.current.setFieldValue(
        "documentCategory",
        data?.documentCategory
      );
      formikRef.current.setFieldValue("title", data?.title);
      formikRef.current.setFieldValue("isNotExpiry", isNotExpiry);
      InitialValues.isNotExpiry = isNotExpiry;
      formikRef.current.setFieldValue(
        "registrationDate",
        new Date(data?.registrationDate)
      );

      if (!isNotExpiry) {
        formikRef.current.setFieldValue(
          "expiryDate",
          new Date(data?.expiryDate)
        );
      }

      if (attachment?.length) {
        const { url, type, renewalsFileName, renewalsFileExt } = attachment[0];

        let fileType = type;

        if (!type.includes("/") && renewalsFileExt === "pdf") {
          fileType = `${type}/${renewalsFileExt}`;
        }

        const obj = {
          path: decodeURIComponent(url).split("/").pop(),
          type: fileType,
          url,
          name: renewalsFileName,
        };

        setFilesAdded([...filesAdded, obj]);
        setImages([url]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    if (isRenewalError && editRenewalError) {
      const renewalError = editRenewalError?.data;

      if (!renewalError) {
        showToast({
          message: Translate("common:unknownServerError"),
          type: "error",
        });
      } else {
        renewalError?.error?.length
          ? renewalError.error.map((errorItem) => {
              if (!InitialValues.hasOwnProperty(errorItem?.param)) {
                return showToast({ message: errorItem.msg, type: "error" });
              }

              return formikRef.current.setFieldError(
                errorItem.param,
                errorItem.msg
              );
            })
          : showToast({
              message:
                renewalError?.message || Translate("common:unknownServerError"),
              type: "error",
            });
      }
    }

    if (isRenewalSuccess && editRenewalData) {
      showToast({
        type: "success",
        message: editRenewalData.message,
      });

      formikRef.current.resetForm();
      setFilesAdded([]);
      setImages([]);
      navigate("/renewals");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editRenewalError, editRenewalData]);

  const onSubmit = (formValues) => {
    const { isNotExpiry, registrationDate, expiryDate } = formValues;

    const reqObj = {
      companyId: formValues?.companyId,
      name: formValues?.name,
      documentCategory: formValues?.documentCategory,
      title: formValues?.title,
      isNotExpiry,
      registrationDate: formatDateYYYYMMDD(registrationDate),
      registrationDateObject: formatDateToISOString(registrationDate),
      attachment: deletedAttachments?.length ? [] : state?.data?.attachment,
      webAttachment: [],
      deletedAttachments: deletedAttachments?.length ? deletedAttachments : [],
    };

    if (!isNotExpiry) {
      reqObj["expiryDate"] = formatDateYYYYMMDD(expiryDate);
      reqObj["expiryDateObject"] = formatDateToISOString(expiryDate);
    }

    let b64Array;
    let typeFile;

    if (filesAdded?.length) {
      b64Array = filesAdded[0].b64;
      typeFile = filesAdded[0].type;

      if (b64Array) {
        const { webAttachment } = reqObj;

        webAttachment.push({
          url: b64Array,
          type: typeFile,
          renewalsFileExt: filesAdded[0]?.name?.split(".").pop(),
          renewalsFileName: filesAdded[0]?.name,
        });
      }
    }

    editRenewalApi({
      id: state?.data?._id,
      body: reqObj,
    });
  };

  return (
    <Formik
      initialValues={InitialValues}
      innerRef={formikRef}
      validateOnMount
      onSubmit={onSubmit}
      validationSchema={AddRenewalSchema}
    >
      {({ values, setFieldValue, handleSubmit }) => {
        return (
          <RenewalForm
            isEdit
            formikRef={formikRef}
            values={values}
            setFieldValue={setFieldValue}
            handleSubmit={handleSubmit}
            isLoading={isRenewalLoading}
            images={images}
            setImages={setImages}
            filesAdded={filesAdded}
            setFilesAdded={setFilesAdded}
            setDeletedAttachments={setDeletedAttachments}
            renewalData={state?.data}
          />
        );
      }}
    </Formik>
  );
};

export default EditRenewals;

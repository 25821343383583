import React from "react";
import _ from "lodash";
import { colors } from "../../../common/Theme/Colors";
import { siblingLine } from "./utils";

export const SiblingCurves = (siblings, allNodes) => {
  const siblingJoins = _.map(siblings, function (d, i) {
    return (
      <path
        key={"curves_" + i}
        d={siblingLine(d, allNodes)}
        fill={"none"}
        stroke={colors.primary_003}
        strokeWidth={3}
      />
    );
  });

  return siblingJoins;
};

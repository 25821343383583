import { Stack } from "@mui/material";
import React from "react";
import { colors } from "../../../../../common/Theme/Colors";
import { StyledTypography } from "../../../../../common/Utils/StyledComponents";
import Translate from "../../../../../translation/utils/translateFunction";
import TextWrapWithTooltip from "../../../../TextWrapWithTooltip";
import { truncateHolidayText } from "../../../components/utils";

export const getAttendanceReportDetailColumns = (
  customActionCellRender,
  isVisible
) => {
  const columns = [
    {
      field: "date",
      headerName: "Date",
      flex: 0.07,
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Stack
          width={"100%"}
          alignItems={"center"}
          sx={{ background: colors.primary }}>
          <StyledTypography
            variant="subtitle1"
            color={colors.white}
            sx={{ fontWeight: "600", fontSize: 16 }}>
            {params?.row?.dayOfMonth}
          </StyledTypography>
          <StyledTypography
            variant="subtitle2"
            color={colors.white}
            className="labelStyling">
            {params?.row?.day}
          </StyledTypography>
        </Stack>
      ),
    },
    {
      field: "checkIn",
      headerName: "Check in",
      flex: 0.3,
      sortable: false,
      headerAlign: "center",
      disableColumnMenu: true,
      renderCell: (params) => {
        const { checkIn, checkInLocation } = params?.row;
        return (
          <Stack width={"100%"} alignItems={"center"}>
            <StyledTypography variant="subtitle1" sx={{ fontWeight: "500" }}>
              {checkIn}
            </StyledTypography>
            <TextWrapWithTooltip
              textVariant="subtitle2"
              customStyle={{ fontWeight: 500 }}
              customClassName="attendanceReportColTitle"
              label={checkInLocation}
            />
          </Stack>
        );
      },
    },
    {
      field: "checkOut",
      headerName: "Check out",
      flex: 0.3,
      sortable: false,
      headerAlign: "center",
      disableColumnMenu: true,
      renderCell: (params) => {
        const {
          checkOut,
          checkOutLocation,
          color,
          holiday,
          label,
          isAbsent,
          isLeave,
        } = params?.row;
        return (
          <Stack width={"100%"} alignItems={"center"}>
            {holiday ? (
              <StyledTypography
                variant="subtitle1"
                color={color}
                sx={{ fontWeight: "500" }}
                className="attendanceReportHolidayTitle"
                data-toggle="tooltip"
                title={label}>
                {truncateHolidayText(
                  `${Translate("reports:holiday")}`,
                  label,
                  20
                )}
              </StyledTypography>
            ) : (
              <>
                <StyledTypography
                  variant="subtitle1"
                  color={color}
                  sx={{ fontWeight: "500" }}>
                  {holiday
                    ? Translate("reports:holiday")
                    : isLeave
                    ? Translate("reports:changeStatus")
                    : isAbsent
                    ? Translate("reports:absentTitle")
                    : checkOut || "---"}
                </StyledTypography>
                <TextWrapWithTooltip
                  textVariant="subtitle2"
                  customStyle={{ fontWeight: 500 }}
                  customClassName="attendanceReportColTitle"
                  label={checkOut ? checkOutLocation : ""}
                />
              </>
            )}
          </Stack>
        );
      },
    },
    {
      field: "workingHours",
      headerName: "Working hours",
      align: "center",
      headerAlign: "center",
      flex: 0.3,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const { color, holiday, workingHours, isAbsent, isLeave } = params?.row;
        return (
          <StyledTypography
            variant="subtitle1"
            color={color}
            sx={{ fontWeight: "500" }}>
            {holiday || isAbsent || isLeave ? "" : workingHours || "---"}
          </StyledTypography>
        );
      },
    },
    isVisible
      ? {
          field: "action",
          headerName: "Action",
          align: "center",
          headerAlign: "center",
          flex: 0.1,
          sortable: false,
          disableColumnMenu: true,
          renderCell: customActionCellRender,
        }
      : {},
  ];
  return columns;
};

import React, { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  formatDateYYYYMMDDWithSlash,
  formatGetExpiryDate,
} from "../../../common/Utils/DateFormater";
import { showToast } from "../../../common/Utils/ToastMessage";
import { useLazyGetCompanyListQuery } from "../../../request/company";
import Translate from "../../../translation/utils/translateFunction";
import Dropzone from "../../CommonFileUpload/Dropzone";
import FormikDatePicker from "../../FormikForm/component/FormikDatePicker";
import { FormikDropdownPicker } from "../../FormikForm/component/FormikDropDownPicker";
import { FormikTextField } from "../../FormikForm/component/FormikTextField";
import CommonImage from "../../ImageGallery/CommonImage";
import RadioButtons from "../../RadioButtons";
import PageHeader from "../../common/pageHeader";
import Loader from "../../staff/Owner/LoadingComp";
import { checkBoxOption, docCategory } from "./constants";
import { handleEventKeySubmit, stopEventPropogation } from "../../../common/Utils/commonFunction";

const RenewalForm = ({
  isAdd,
  isView,
  isEdit,
  handleSubmit,
  setFieldValue,
  values,
  isLoading,
  images,
  setImages,
  filesAdded,
  setFilesAdded,
  setDeletedAttachments,
  renewalData,
}) => {
  const navigate = useNavigate();
  const { validations } = useSelector((state) => state?.formanagement);

  const [allCompanyData, setAllCompanyData] = useState([]);

  const acceptedFileType = {
    "image/png": [".png", ".jpg", ".jpeg", ".pdf", ".heif", ".heic"],
  };

  const [
    getCompanyList,
    { data: companyResponse, isLoading: isCompanyLoading },
  ] = useLazyGetCompanyListQuery();

  useEffect(() => {
    getCompanyList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (companyResponse) {
      const companyData = [];

      companyResponse.map((item) =>
        companyData.push({
          label: item.name,
          value: `${item._id}`,
        })
      );

      setAllCompanyData(companyData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyResponse]);

  const updateDate = (value) => {
    const expiryDate = formatGetExpiryDate(value);
    setFieldValue("registrationDate", value);
    if (!values?.isNotExpiry) {
      setFieldValue("expiryDate", expiryDate);
    }
  };

  const addFiles = (file) => {
    const { type } = file;
    const fileTypes = [
      "image/png",
      "image/png",
      "image/jpeg",
      "image/heic",
      "image/heif",
    ];

    const url = fileTypes.includes(type) ? URL.createObjectURL(file) : null;

    if (url) file.preview = url;

    if (filesAdded.length < 1) {
      filesAdded.push(file);
      setFilesAdded([...filesAdded]);

      const newImages = filesAdded.map((imageElement) =>
        imageElement.preview ? imageElement.preview : imageElement?.url
      );

      setImages(newImages);
    } else {
      showToast({ message: Translate("document:fileLimit"), type: "error" });
    }
  };
  const removeFile = (file) => {
    const deletedFiles = filesAdded.filter(
      (fileName) => fileName.name !== file.name
    );

    if (isEdit && !file?.b64) {
      setDeletedAttachments([file]);
    }

    setFilesAdded(deletedFiles);
  };

  const getPageTitle = () => {
    if (isView) return Translate("renewals:viewRenewalHeading");

    if (isEdit) return Translate("renewals:editRenewalHeading");

    return Translate("renewals:renewalHeading");
  };

  return (
    <>
      <div className="row container_spacing">
        <div className="card card_spacing">
          <div className="mt-10 pl-3 row"></div>
          <PageHeader
            title={getPageTitle()}
            onClick={() =>
              isEdit
                ? navigate("/renewals/view", {
                    state: { renewalId: renewalData?._id },
                  })
                : navigate("/renewals")
            }
            RightContainer={() =>
              isView && (
                <div className="card-options">
                  <i
                    className="icon-color ml-3 fa fa-pencil-square-o cursor-pointer"
                    onClick={() =>
                      navigate("/renewals/edit", {
                        state: {
                          data: renewalData,
                        },
                      })
                    }
                    data-toggle="tooltip"
                    title={Translate("common:editToolTip")}
                  ></i>
                </div>
              )
            }
          />

          <div
            className="card-body"
            onKeyDown={(e) => handleEventKeySubmit(e, handleSubmit)}
          >
            <div className="row">
              <div className="form-group col-md-4 col-sm 12">
                <FormikDropdownPicker
                  options={allCompanyData}
                  value={values?.companyId}
                  name="companyId"
                  label={Translate("vendors:selectCompany")}
                  placeholder={Translate("vendors:dropdownPlaceholder")}
                  disabled={isView}
                  required
                  autoFocus={isAdd}
                />
              </div>
              <div className="form-group col-md-4 col-sm-12">
                <FormikTextField
                  name="name"
                  label={Translate("renewals:docName")}
                  placeholder={Translate("renewals:docNamePlaceholder")}
                  autoComplete={"off"}
                  maxLength={validations?.renewalName.MAX}
                  disabled={isView}
                  required
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-4 col-sm-12">
                <FormikDropdownPicker
                  options={docCategory}
                  value={""}
                  name="documentCategory"
                  label={Translate("renewals:docCategory")}
                  placeholder={Translate("renewals:docCategoryPlaceholder")}
                  onSelect={(value) => {
                    if (value !== "Other") setFieldValue("title", "");
                    setFieldValue("documentCategory", value);
                  }}
                  disabled={isView}
                  required
                />
              </div>
              {values.documentCategory === "Other" && (
                <div className="form-group col-md-4 col-sm-12">
                  <FormikTextField
                    name="title"
                    label={Translate("renewals:title")}
                    placeholder={Translate("renewals:title")}
                    autoComplete={"off"}
                    maxLength={validations?.renewalTitle.MAX}
                    disabled={isView}
                  />
                </div>
              )}
            </div>
            <div className="row">
              <div className="form-group col-md-4 col-sm-12">
                <FormikDatePicker
                  label={Translate("renewals:registrationDate")}
                  containerStyle="datePickerContainer"
                  className="form-control bw-0"
                  format="yyyy/MM/dd"
                  name="registrationDate"
                  value={values.registrationDate}
                  onChange={updateDate}
                  disabled={isView}
                  required
                />
              </div>
              <div className="form-group col-md-4 col-sm-12">
                {values?.isNotExpiry ? (
                  <FormikTextField
                    name="expiryDate"
                    label={Translate("renewals:expiryDate")}
                    placeholder={Translate("renewals:expiryDate")}
                    value="NA"
                    disabled
                  />
                ) : (
                  <FormikDatePicker
                    label={Translate("renewals:expiryDate")}
                    containerStyle="datePickerContainer"
                    className="form-control bw-0"
                    format="yyyy/MM/dd"
                    name="expiryDate"
                    max={formatDateYYYYMMDDWithSlash(values.expiryDate)}
                    value={values.expiryDate}
                    onChange={(value) => {
                      setFieldValue("expiryDate", value);
                    }}
                    minDate={values.registrationDate}
                    disabled={values?.isNotExpiry || isView}
                  />
                )}
              </div>
            </div>

            {(isAdd || isEdit || (isView && renewalData?.isNotExpiry)) && (
              <div className="documentExpiryCheck">
                <RadioButtons
                  radioOptions={checkBoxOption}
                  onPress={(event) => {
                    const isNotExpiry = event?.target?.checked;

                    if (isNotExpiry) {
                      setFieldValue("expiryDate", "");
                    } else {
                      setFieldValue(
                        "expiryDate",
                        formatGetExpiryDate(values?.registrationDate)
                      );
                    }

                    setFieldValue("isNotExpiry", isNotExpiry);
                  }}
                  defaultCheck={values?.isNotExpiry}
                  isCheckBox
                  disabled={isView}
                />
              </div>
            )}

            <div className="row imageDisplay" onKeyDown={stopEventPropogation}>
              <div className="form-group col-md-4 col-sm 8">
                {!isView && (
                  <div className="display">
                    <Dropzone
                      translate={Translate("renewals:attach")}
                      icon={"icon-paper-clip"}
                      onfileSelect={(filesArray) => addFiles(filesArray)}
                      acceptedImages={acceptedFileType}
                      maximumFiles={10}
                      maxFileSize={52428800}
                      fileLength={filesAdded?.length}
                    />
                  </div>
                )}
                {filesAdded && filesAdded?.length > 0 && (
                  <CommonImage
                    images={images}
                    icon={isView}
                    files={filesAdded}
                    removeFile={!isView && removeFile}
                  />
                )}
              </div>
            </div>
            {!isView && (
              <div className="text-right line_spacing">
                <button
                  type="submit"
                  className="btn btn-primary saveButton"
                  onClick={handleSubmit}
                >
                  {isEdit
                    ? Translate("renewals:editDoc")
                    : Translate("renewals:addDoc")}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <Loader loading={isLoading || isCompanyLoading} />
    </>
  );
};

export default memo(RenewalForm);

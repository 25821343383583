export default {
  head: "Messages",
  people: "Chats",
  groups: "Groups",
  contacts: "Contacts",
  createNewGroup: "Create new group",
  addToTask: "Add to task",
  addToSubTask: "Add to subtask",
  noChatFound: "No chat found",
  readMore: "Read more",
  readLess: "Read less",
  deleteAlertTitle: "Are you sure you want to delete this message?",
  at: "at ",
  contactInfo: "Contact info",
  phone: "Phone",
  groupsCommon: "Groups in common",
  initialScreenSubtitle: "Search for someone to start chatting",
  initialScreenBtnTitl: "Start work chat",
  members: "Members",
  deleteMessage: "This message has been deleted",
  relatedTask: "Related task",
  editGroupName: "Edit group name",
  requiredValidation: "Group name is required",
  startConversationMesage: "You can start your conversation."
};

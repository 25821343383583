import React from "react";
import { getTaskStatusColor } from "./utils";
import Translate from "../../../translation/utils/translateFunction";
import { formatDateWithTime } from "../../../common/Utils/DateFormater";

const EmailDetailModal = ({
  taskStatus,
  taskStatusColor,
  viewEmailDetails,
  extractedEmailName,
}) => {
  return (
    <div className="viewEmailSenderDetails">
      {taskStatus && (
        <div className="viewEmailTaskStatus">
          <div
            style={{
              border: `2px solid ${getTaskStatusColor(
                taskStatus,
                taskStatusColor
              )}`,
              color: getTaskStatusColor(taskStatus, taskStatusColor),
              fontWeight: "500",
            }}
            className="taskStatusStyling taskCardContentStyling viewEmailTaskStatusStyles"
          >
            {taskStatus}
          </div>
        </div>
      )}

      <div className="commonDisplay viewEmailSenderDetailsFrom">
        <div className="viewEmailCommonTitle">
          <span>{Translate("inboxPage:from")}:</span>
        </div>

        <div className="mx-2 viewEmailCommonMail">
          <div className="inboxFromHeading">
            <span className="mr-1">{viewEmailDetails?.from?.name}</span>|
            <span style={{ fontWeight: 300 }} className="ml-1">
              {viewEmailDetails?.from?.address ||
                viewEmailDetails?.from?.address}
            </span>
          </div>
        </div>
      </div>

      <div className="commonDisplay viewEmailSenderDetailsFrom">
        <div className="viewEmailCommonTitle">
          <span>{Translate("inboxPage:to")}:</span>
        </div>
        <div className="mx-2 viewEmailCommonMail mb-1">
          {viewEmailDetails?.to &&
            viewEmailDetails?.to?.map((ele, index) => (
              <div className="inboxEllipsis" key={index}>
                <span className="mr-1">{ele?.name}</span> |
                <span style={{ fontWeight: 300 }} className="ml-1">
                  {ele?.address}
                </span>
              </div>
            ))}
        </div>
      </div>
      {viewEmailDetails?.cc?.length > 0 && (
        <div className="viewEmailCommonDetails">
          <div className="viewEmailCommonTitle">
            <span>{Translate("inboxPage:cc")}:</span>
          </div>
          <div className="mx-2 viewEmailCommonMail mb-1">
            {viewEmailDetails?.cc?.map((ele, index) => (
              <div className="inboxEllipsis" key={index}>
                <span className="mr-1">{ele?.name}</span> |
                <span style={{ fontWeight: 300 }} className="ml-1">
                  {ele?.address}
                </span>
              </div>
            ))}
          </div>
        </div>
      )}
      <div className="viewEmailCommonDetails">
        <div className="viewEmailCommonTitle">
          <span>{Translate("inboxPage:date")}:</span>
        </div>
        <div className="mx-2 viewEmailCommonMail">
          {formatDateWithTime(viewEmailDetails?.date)}
        </div>
      </div>
    </div>
  );
};
export default EmailDetailModal;

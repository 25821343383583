import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { showToast } from "../../../common/Utils/ToastMessage";
import { useViewVendorDataMutation } from "../../../request/VendorsSuppliers";
import VendorForm from "../Components/VendorForm";
import { InitialValues } from "../Components/constants";

const ViewVendor = () => {
  const { state } = useLocation();
  const formikRef = useRef(null);

  const [reportTo, setReportTo] = useState("");
  const [selectedCompany, setSelectedCompany] = useState("");
  const [filesAdded, setFilesAdded] = useState([]);
  const [images, setImages] = useState([]);

  const [
    getVendorApi,
    {
      data: vendorData,
      isSuccess: isVendorSuccess,
      isLoading: isVendorLoading,
      isError: isVendorError,
      error: vendorError,
    },
  ] = useViewVendorDataMutation();

  useEffect(() => {
    if (state) {
      getVendorApi(state);
    }
  }, [getVendorApi, state]);

  useEffect(() => {
    if (isVendorError && vendorError) {
      showToast({
        message: vendorError?.data?.message,
        type: "error",
      });
    }

    if (isVendorSuccess && vendorData) {
      const { data } = vendorData;

      formikRef.current.setFieldValue("companyId", data?.companyId[0]._id);
      setSelectedCompany(data?.companyId[0]._id);
      formikRef.current.setFieldValue("companyName", data?.companyName);
      formikRef.current.setFieldValue("name", data?.name);
      formikRef.current.setFieldValue("designation", data?.designation);
      formikRef.current.setFieldValue("department", data?.department);
      // formikRef.current.setFieldValue("mobile", data?.login.mobile);
      formikRef.current.setFieldValue(
        "mobile",
        `${data?.login?.countryCode}-${data?.login?.mobile}`
      );
      formikRef.current.setFieldValue(
        "mobileShortCode",
        data?.login.mobileShortCode
      );
      formikRef.current.setFieldValue("countryCode", data?.login.countryCode);
      if (data?.alternateMobile) {
        formikRef.current.setFieldValue(
          "alternateMobile",
          `${data?.alternateMobileCountryCode}-${data?.alternateMobile}`
        );
      }

      formikRef.current.setFieldValue(
        "alternateMobileCountryCode",
        data?.alternateMobileCountryCode
          ? data?.alternateMobileCountryCode
          : "962"
      );
      formikRef.current.setFieldValue(
        "alternateMobileShortCode",
        data?.alternateMobileShortCode ? data?.alternateMobileShortCode : "JO"
      );
      formikRef.current.setFieldValue("companyNumber", data?.companyNumber);
      formikRef.current.setFieldValue(
        "companyExtension",
        data?.companyExtension
      );
      formikRef.current.setFieldValue("email", data?.login.email);
      formikRef.current.setFieldValue("flat", data?.workAddress.flat);
      formikRef.current.setFieldValue("state", data?.workAddress.state);
      formikRef.current.setFieldValue("country", data?.workAddress.country);
      formikRef.current.setFieldValue("city", data?.workAddress.city);
      formikRef.current.setFieldValue("zipCode", data?.workAddress.zipCode);
      formikRef.current.setFieldValue("workAddress", data?.workAddress.address);
      formikRef.current.setFieldValue(
        "latitude",
        data?.workAddress.latlong.latitude
      );
      formikRef.current.setFieldValue(
        "longitude",
        data?.workAddress.latlong.longitude
      );
      formikRef.current.setFieldValue("gender", data?.gender);

      if (data?.profileUrl) {
        const obj = {
          path: decodeURIComponent(data?.profileUrl).split("/").pop(),
          type: "image/png",
          url: data?.profileUrl,
        };
        setFilesAdded([...filesAdded, obj]);
        setImages([data?.profileUrl]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendorData, isVendorSuccess, vendorError, isVendorError]);

  const onSubmit = () => {};

  return (
    <Formik
      initialValues={InitialValues}
      innerRef={formikRef}
      onSubmit={onSubmit}
    >
      {({ values, handleSubmit }) => {
        return (
          <VendorForm
            formikRef={formikRef}
            isView
            isLoading={isVendorLoading}
            vendorData={vendorData?.data}
            values={values}
            reportTo={reportTo}
            setReportTo={setReportTo}
            filesAdded={filesAdded}
            setFilesAdded={setFilesAdded}
            images={images}
            setImages={setImages}
            selectedCompany={selectedCompany}
            setSelectedCompany={setSelectedCompany}
            handleSubmit={handleSubmit}
          />
        );
      }}
    </Formik>
  );
};

export default ViewVendor;

export default {
  head: 'Filters',
  clearAll: 'Clear all',
  apply: 'Apply',
  close: 'Close',
  title: 'Title',
  priority: 'Priority',
  status: 'Status',
  companies: 'Companies',
  staff: 'Staff',
  sortBy: 'Sort by',
  all: 'All',
  task: 'Task',
  project: 'Project',
  goal: 'Goal',
  emergency: 'Emergency',
  high: 'High',
  medium: 'Medium',
  low: 'Low',
  completed: 'Completed',
  inProgress: 'In-progress',
  resolved: 'Resolved',
  reOpened: 'Re-opened',
  overdue: 'Overdue',
  managers: 'Managers',
  employees: 'Employees',
  vendors_Suppliers: 'Vendors/suppliers',
  recentTask: 'Recent task',
  allTask: 'All task',
  ascending: 'Ascending',
  descending: 'Descending',
  calendar: 'Calendar',
  fromDate: 'From date',
  toDate: 'To date',
  self: 'Self',
};

export default {
  selectContacts: "Contacts",
  contactRepository: "Contact repository",
  editCard: "Edit business card",
  public: "Public",
  private: "Private",
  shared: "Shared",
  markPrivate: "Mark as private",
  markPublic: "Mark as public",
  delete: "Delete",
  deleteAlert: "Are you sure, you want to delete this contact?",
  owner: "Own",
  manager: "Manager",
  companySort: "Sort by company",
  industrySort: "Sort by industry",
  departSort: "Sort by department",
  sectorSort: "Sort by sector",
  fieldSort: "Sort by field",
  sharedContacts: "Shared contacts",
  shareContact_1: "This contact shared with",
  shareContact_2: "people",
  sharedWithMe: "Shared with me",
  sharedByMe: "Shared by me",
  tooltip: "Upload business card",
  accessLogs: "Access logs",
  sortByCompany: "Sort by company",
  sortByDepartment: "Sort by department",
  sortByManager: "Sort by manager",
  createContact: "Create contact",
  contacts: "Contacts",
  addPhoto: "Add photo",
  changePhoto: "Change photo",
  shareContactWith: "Share contact with",
  contactSyncing: "Contacts syncing",
  fetchingContacts: "Fetching contacts from phone.",
  accessLogsHeading: "Access logs",
  apiFailedMessage: "Failed to fetch the data",
  Owner: "Owner",
  PA: "Personal assistant",
  OWNER: "Owner",
  GM: "General manager",
  MANAGER: "Manager",
  EMPLOYEE: "Employee",
  generalManager: "General manager",
  Manager: "Manager",
  selectComapnyMessage: "Please select at least one company",
  markAsPublic: "Mark as public",
  share: "Share",
  filter: "Filter",
  view: "View",
  accesslogs: "Access logs",
  selectMemberHeading: "Select member",
  privateContacts: "Private contacts",
  name: "Contact name",
  designation: "Designation",
  company: "Company",
  contactNumber: "Contact number",
  contactEmail: "Contact email",
  contactAddress: "Address",
  addContact: "Add contact",
  selectCompany: "Company name",
  contactName: "Contact name",
  contactNamePlaceholder: "Contact name",
  contactdesignation: "Designation",
  industry: "Industry",
  department: "Department",
  sector: "Sector",
  field: "Field",
  workEmail: "Work email",
  contactNumberPlaceHolder: "0000 0000 00",
  alternateNumber: "Alternate contact number",
  address: "Address",
  contactNameError: "Contact name is required",
  contactIndustryError: "Industry is required",
  contactSectorError: "Sector is required",
  contactFieldError: "Field is required",
  contactDesignationError: "Designation is required",
  contactDepartmentError: "Department is required",
  contactPhoneError: "Contact number is required",
  contactAddressError: "Address is required",
  companyNameError: "Company is required",
  workEmailError_1: "Work email is required",
  workEmailError: "Work email is not valid",
  save: "Save",
  editContact: "Edit contact",
  markAsPrivate: "Mark as private",
  deleteContact: "Delete contact",
  deleteSingleContactAlertMsg:
    "Are you sure you want to delete this contact(s)?",
  shareContacts: "Share contact with",
  selectAll: "Select all",
  loadMoreTitle: "Load more",
  companyBusinessCard: "Company:",
  Sharedwithme: "Shared with me",
  shareByMe: "Shared by me",
  markAsPublicAlertMessage:
    "Are you sure you want to mark the contact as public?",
  edit: "Edit",
  markAsPrivateAlertMessage:
    "Are you sure you want to mark the contact as private?",
  srNo: "Sr.no",
  userName: "Name",
  mobile: "Mobile no.",
  action: "Action",
  companyName: "Company name",
  dateTime: "Date and Time",
  role: "Role",
  sourceTitle: "Source:",
  noContactsFound: "No contacts found",
  requestContact: "Request contact",
  requestContactModal: "Request contact:",
  requestTo: "To",
  sendRequest: "Send request",
  contactNeeded: "Contact needed",
  requestToError: "Request to field is required",
  contactNeededError: "Contact needed field is required",
  PA: "Personal assistant",
  businessCard: "Business card",
  contactNumberError_1: "Invalid contact number",
  alternateContactNumberError_1: "Invalid alternate contact number",
  editContactNote:
    "Note - This contact is synced from mobile, so we request you to please correct value of country code , contact number , alternate contact number.",
};

import { Stack } from "@mui/material";
import React, { memo } from "react";
import { Persona } from "../../../Persona";
import IconifyIcon from "../../../Icon";
import { useSelector } from "react-redux";

const MemberItem = ({ item, onClick, selectedUser, checkedGroupMembers }) => {
  const isChecked = checkedGroupMembers?.find((val) => val?._id === item?._id);
  const { isRtl } = useSelector((state) => state.formanagement);

  return (
    <li
      key={item?._id}
      className={"online cursor-pointer"}
      onClick={() => onClick(item)}
      style={{
        background: selectedUser === item?._id ? "#d8dee36e" : "#f8f9fa",
      }}>
      <div className="media" style={{ alignItems: "center" }}>
        <Persona
          name={item?.username || item?.name || item?.groupName}
          image={item?.userImage || item?.profileUrl || item?.groupImage}
          size={40}
        />
        {isChecked && (
          <IconifyIcon
            className="selectMemberIcon"
            icon="material-symbols:check-circle"
          />
        )}
        <div className="media-body">
          <Stack flexDirection={"row"} justifyContent={"space-between"}>
            <Stack maxWidth={isRtl ? "300px" : "145px"}>
              <span className="name" style={{ marginRight: isRtl && "4px"}}>
                {item?.username || item?.name || item?.groupName}
              </span>
            </Stack>
          </Stack>
        </div>
      </div>
    </li>
  );
};
export default memo(MemberItem);

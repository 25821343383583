import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useLazyGetMyLeaveDetailQuery } from "../../../request/LeaveRequest";
import Translate from "../../../translation/utils/translateFunction";
import CommonImage from "../../ImageGallery/CommonImage";
import NoDataFound from "../../ManageTask/NoDataFound";
import Loader from "../../staff/Owner/LoadingComp";
import { LeaveDetailCard } from "./Components/LeaveDetailCard";
import { StatusCheck } from "./Components/StatusCheck";

const LeaveRequestDetail = () => {
  const { validations, isRtl } = useSelector((state) => state.formanagement);
  const navigate = useNavigate();
  const { state } = useLocation();
  const [showAttachment, setShowAttachment] = useState(true);
  const [attachmentsArray, setAttachmentsArray] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [leaveData, setLeaveData] = useState();
  const [
    getLeaveDetail,
    {
      data: serverResponse,
      isSuccess: isDeatilSuccess,
      isLoading: isDetailLoading,
    },
  ] = useLazyGetMyLeaveDetailQuery();
  useEffect(() => {
    if (isDeatilSuccess && serverResponse?.data) {
      const { attachment } = serverResponse?.data;
      setLeaveData(serverResponse?.data);
      const attachmentArray = [];
      attachment.map((attachmentItem, idx) => {
        const obj = {
          name: attachmentItem?.fileName,
          path: attachmentItem?.fileName,
          ...attachmentItem,
        };
        attachmentArray.push(obj);
      });
      setAttachmentsArray(attachmentArray);
      setAttachments(attachment?.map((item) => item?.url));
    }
  }, [isDeatilSuccess]);
  useEffect(() => {
    getLeaveDetail(state?.leaveId);
  }, []);

  return (
    <>
      <div
        className={`section-body ${
          isRtl ? "rtlMargin" : "ltrMargin"
        } mt-3 mb-2`}>
        <div className="card">
          <div className="card-header">
            <i
              className="icon fe fe-arrow-left mr-3 icon-size backnavigation-iconColor cursor-pointer"
              onClick={() => navigate(-1)}
              title={Translate("common:backToolTip")}
            />
            <h5 className="page-title">{Translate("drawer:leaveReq")}</h5>
          </div>
        </div>
      </div>
      <div className={`section-body ${isRtl ? "rtlMargin" : "ltrMargin"}`}>
        {isDetailLoading ? (
          <Loader loading={isDetailLoading} />
        ) : (
          <>
            <div className="row">
              <div className="col-md-5 col-sm-12">
                <LeaveDetailCard
                  leaveData={leaveData}
                  validations={validations}
                />
              </div>

              <div className="col-md-7 col-sm-12" style={{ width: "100%" }}>
                <div className="card">
                  <div
                    className={`commentLabel cursor-pointer CommentCard_spacing ${
                      showAttachment && "taskHeadingLineBottom"
                    }`}
                    onClick={() => setShowAttachment(!showAttachment)}>
                    <span className="taskHeading font-17">
                      {Translate("manageTask:attacheMenttitle")}
                    </span>
                    <div>
                      <i
                        className={
                          showAttachment
                            ? "fa fa-caret-down "
                            : "fa fa-caret-up"
                        }
                      />
                    </div>
                  </div>
                  {showAttachment && (
                    <div className="CommentCard_spacing">
                      {attachmentsArray.length > 0 ? (
                        <div className="AttachmentBoxStyling">
                          <CommonImage
                            images={attachments}
                            files={attachmentsArray}
                            icon={true}
                          />
                        </div>
                      ) : (
                        <NoDataFound
                          message={Translate("manageTask:noAttachmentMsg")}
                          isCenter
                          className="NoDataStyle"
                        />
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-5 col-sm-12">
                <StatusCheck historyData={leaveData?.history} />
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};
export default LeaveRequestDetail;

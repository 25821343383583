import Translate from "../../../translation/utils/translateFunction";
import {
  CustomTitleCellRender,
  CustomDateTimeCellRender,
} from "../../common/CustomCellRender";
import {
  formatTimeTo12Hour,
  formatDateByToday,
} from "../../../common/Utils/DateFormater";

export const getNotificationColumns = (
  customSrCellRender,
  customActionCellRender
) => {
  const columns = [
    {
      field: "lineNo",
      headerName: Translate("common:srNoTitle"),
      align: "center",
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      renderCell: customSrCellRender,
    },
    {
      field: "senderName",
      headerName: Translate("notificationPage:senderNameTitle"),
      flex: 0.4,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => CustomTitleCellRender(params?.row?.senderName),
    },
    {
      field: "senderRole",
      headerName: Translate("notificationPage:roleTitle"),
      flex: 0.3,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "message",
      headerName: Translate("notificationPage:descriptionTitle"),
      flex: 0.8,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => CustomTitleCellRender(params?.row?.message),
    },
    {
      field: "formattedDate",
      headerName: Translate("notificationPage:dueDateAndTimeTitle"),
      flex: 0.5,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const { createdAt, formattedTime } = params.row;

        return CustomDateTimeCellRender(
          formatDateByToday(createdAt),
          formatTimeTo12Hour(formattedTime)
        );
      },
    },
    {
      field: "Action",
      headerName: Translate("vendors:actionTableHeading"),
      flex: 0.3,
      sortable: false,
      disableColumnMenu: true,
      hide: true,
      renderCell: customActionCellRender,
    },
  ];
  return columns;
};

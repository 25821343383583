import React, { useEffect, useRef } from "react";
import Select, { components } from "react-select";
import AsyncSelect from "react-select/async";
import {
  ErroLabelStyle,
  StyledTypography,
} from "../../../common/Utils/StyledComponents";
import Translate from "../../../translation/utils/translateFunction";
import { colors } from "../../../common/Theme/Colors";
import { useSelector } from "react-redux";

const SearchableDropDown = ({
  options,
  selected,
  onSelect,
  onChange,
  label,
  touched,
  error,
  isError,
  className,
  disabled = false,
  disableOption,
  required,
  placeholder,
  isSearchable = true,
  isTimeZone = false,
  isTimeZoneScreen = false,
  menuStyle,
  ...props
}) => {
  const ref = useRef(null);
  const { isRtl } = useSelector((state) => state.formanagement);

  const NoOptionsMessage = (props) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span className="custom-css-class">
          {Translate("common:noDataFoundMessage")}
        </span>
      </components.NoOptionsMessage>
    );
  };
  useEffect(() => {
    if (selected) {
      ref.current && ref.current.blur();
    }
  }, [selected]);
  return (
    <div>
      {label && (
        <StyledTypography className="labelStyling" component="span">
          {label}
          {required && (
            <StyledTypography sx={{ color: "red" }} component="span">
              {" *"}
            </StyledTypography>
          )}
        </StyledTypography>
      )}

      {isTimeZone ? (
        <AsyncSelect
          ref={ref}
          defaultOptions={options}
          value={selected}
          onChange={onSelect}
          loadOptions={onChange}
          placeholder={placeholder || label}
          className={className}
          classNamePrefix={"css-1s2u09g-control"}
          isOptionDisabled={(option) => (disableOption ? true : false)}
          isDisabled={disabled}
          components={{ NoOptionsMessage }}
          isSearchable={true}
          closeMenuOnSelect={selected && true}
          isRtl={isRtl}
          styles={{
            menu: (base) => ({ ...base, zIndex: 9999 }),
            container: (base) => ({ ...base, marginTop: "4px" }),
            option: (provided, state) => ({
              ...provided,
              backgroundColor: (
                !isTimeZoneScreen
                  ? state?.data?.value?.timezones?.find(
                      (time) => time?.zoneName === selected?.value?.zoneName
                    )
                  : state?.data?.value?.zoneName === selected?.value?.zoneName
              )
                ? colors.blue_005
                : colors.white,
              color: colors.black,
            }),
          }}
        />
      ) : (
        <Select
          options={options}
          value={selected}
          onChange={onSelect}
          onInputChange={onChange}
          placeholder={placeholder || label}
          className={className}
          classNamePrefix={"css-1s2u09g-control"}
          isOptionDisabled={disableOption}
          isDisabled={disabled}
          components={{ NoOptionsMessage }}
          isSearchable={isSearchable}
          isRtl={isRtl}
          styles={{
            menu: (base) => ({ ...base, zIndex: 9999, ...menuStyle }),
          }}
        />
      )}

      {touched && error && (
        <StyledTypography sx={ErroLabelStyle} component="span">
          {error}
        </StyledTypography>
      )}
    </div>
  );
};
export default SearchableDropDown;

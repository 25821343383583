import Translate from "../../../../translation/utils/translateFunction";
import {
  CustomTitleCellRender,
  CustomTagCellRender,
} from "../../../common/CustomCellRender";

export const getPerformanceReportColumns = (
  customActionCellRender,
  customSrCellRender
) => {
  const columns = [
    {
      field: "serialNumber",
      headerName: Translate("common:srNoTitle"),
      align: "center",
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      renderCell: customSrCellRender,
    },
    {
      field: "name",
      headerName: Translate("performanceReport:name"),
      flex: 0.5,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => CustomTitleCellRender(params?.row?.name),
    },
    {
      field: "companyName",
      headerName: Translate("performanceReport:companyName"),
      flex: 0.4,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => CustomTitleCellRender(params?.row?.companyName),
    },
    {
      field: "PerformanceDetails.totalAssigned",
      headerName: Translate("performanceReport:totalAssigned"),
      sortable: false,
      flex: 0.3,
      disableColumnMenu: true,
      headerAlign: "center",
      align: "center",
      renderCell: (params) =>
        CustomTagCellRender(
          params?.row?.PerformanceDetails?.totalAssigned,
          "tag-theme-blue"
        ),
    },
    {
      field: "PerformanceDetails.inProgress",
      headerName: Translate("performanceReport:inprogress"),
      flex: 0.3,
      sortable: false,
      disableColumnMenu: true,
      headerAlign: "center",
      align: "center",
      renderCell: (params) =>
        CustomTagCellRender(
          params?.row?.PerformanceDetails?.inProgress,
          "tag-azure"
        ),
    },
    {
      field: "PerformanceDetails.overDue",
      headerName: Translate("performanceReport:overdue"),
      sortable: false,
      flex: 0.3,
      disableColumnMenu: true,
      headerAlign: "center",
      align: "center",
      renderCell: (params) =>
        CustomTagCellRender(
          params?.row?.PerformanceDetails?.overDue,
          "tag-orange"
        ),
    },
    {
      field: "PerformanceDetails.completed",
      headerName: Translate("performanceReport:completed"),
      flex: 0.3,
      sortable: false,
      disableColumnMenu: true,
      headerAlign: "center",
      align: "center",
      renderCell: (params) =>
        CustomTagCellRender(
          params?.row?.PerformanceDetails?.completed,
          "tag-theme-blue"
        ),
    },
    {
      field: "PerformanceDetails.onTime",
      headerName: Translate("performanceReport:onTime"),
      flex: 0.3,
      sortable: false,
      disableColumnMenu: true,
      headerAlign: "center",
      align: "center",
      renderCell: (params) =>
        CustomTagCellRender(
          params?.row?.PerformanceDetails?.onTime,
          "tag-blue"
        ),
    },
    {
      field: "PerformanceDetails.beforeTime",
      headerName: Translate("performanceReport:beforeTime"),
      flex: 0.3,
      sortable: false,
      disableColumnMenu: true,
      headerAlign: "center",
      align: "center",
      renderCell: (params) =>
        CustomTagCellRender(
          params?.row?.PerformanceDetails?.beforeTime,
          "tag-teal"
        ),
    },
    {
      field: "PerformanceDetails.afterTime",
      headerName: Translate("performanceReport:afterTime"),
      flex: 0.3,
      sortable: false,
      disableColumnMenu: true,
      headerAlign: "center",
      align: "center",
      renderCell: (params) =>
        CustomTagCellRender(
          params?.row?.PerformanceDetails?.afterTime,
          "tag-red"
        ),
    },
    {
      field: "Action",
      headerName: Translate("common:actionTitle"),
      flex: 0.2,
      sortable: false,
      disableColumnMenu: true,
      headerAlign: "center",
      align: "center",
      renderCell: customActionCellRender,
    },
  ];
  return columns;
};

export const getPerformanceReportDetailsColumns = () => {
  const columns = [
    {
      field: "name",
      headerName: Translate("manageTask:priority"),
      sortable: false,
      flex: 0.2,
      disableColumnMenu: true,
    },
    {
      field: "totalAssigned",
      headerName: Translate("performanceReport:totalTasks"),
      sortable: false,
      flex: 0.2,
      disableColumnMenu: true,
      headerAlign: "center",
      align: "center",
      renderCell: (params) =>
        CustomTagCellRender(params?.row?.totalAssigned, "tag-theme-blue"),
    },
    {
      field: "assigned",
      headerName: Translate("performanceReport:assigned"),
      sortable: false,
      flex: 0.2,
      disableColumnMenu: true,
      headerAlign: "center",
      align: "center",
      renderCell: (params) =>
        CustomTagCellRender(params?.row?.assigned, "tag-gray"),
    },
    {
      field: "inProgress",
      headerName: Translate("performanceReport:inprogress"),
      flex: 0.2,
      sortable: false,
      disableColumnMenu: true,
      headerAlign: "center",
      align: "center",
      renderCell: (params) =>
        CustomTagCellRender(params?.row?.inProgress, "tag-azure"),
    },
    {
      field: "reopened",
      headerName: Translate("performanceReport:reopened"),
      flex: 0.25,
      sortable: false,
      disableColumnMenu: true,
      headerAlign: "center",
      align: "center",
      renderCell: (params) =>
        CustomTagCellRender(params?.row?.reopened, "tag-theme-blue"),
    },
    {
      field: "onTime",
      headerName: Translate("performanceReport:onTime"),
      flex: 0.2,
      sortable: false,
      disableColumnMenu: true,
      headerAlign: "center",
      align: "center",
      renderCell: (params) =>
        CustomTagCellRender(params?.row?.onTime, "tag-blue"),
    },
    {
      field: "beforeTime",
      headerName: Translate("performanceReport:beforeTime"),
      flex: 0.2,
      sortable: false,
      disableColumnMenu: true,
      headerAlign: "center",
      align: "center",
      renderCell: (params) =>
        CustomTagCellRender(params?.row?.beforeTime, "tag-teal"),
    },
    {
      field: "afterTime",
      headerName: Translate("performanceReport:afterTime"),
      flex: 0.2,
      sortable: false,
      disableColumnMenu: true,
      headerAlign: "center",
      align: "center",
      renderCell: (params) =>
        CustomTagCellRender(params?.row?.afterTime, "tag-red"),
    },
    {
      field: "resolved",
      headerName: Translate("performanceReport:resolved"),
      flex: 0.25,
      sortable: false,
      disableColumnMenu: true,
      headerAlign: "center",
      align: "center",
      renderCell: (params) =>
        CustomTagCellRender(params?.row?.resolved, "tag-info"),
    },
    {
      field: "overDue",
      headerName: Translate("performanceReport:overdue"),
      sortable: false,
      flex: 0.2,
      disableColumnMenu: true,
      headerAlign: "center",
      align: "center",
      renderCell: (params) =>
        CustomTagCellRender(params?.row?.overDue, "tag-orange"),
    },
  ];
  return columns;
};

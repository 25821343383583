import React from "react";

const NoMailSync = ({
  message = "We have successfully integrated your email account with For.management. Hold for few minutes to sync your email data. Once done you will see new data coming here.",
  isCenter = false,
  className = "NoComment",
  styleProps = {},
}) => {
  return isCenter ? (
    <div className={className}>{message}</div>
  ) : (
    <h4 className="mt-3" style={styleProps}>
      {message}
    </h4>
  );
};

export default NoMailSync;

import {
  jordanCountryCode,
  jordanCountryShortCode,
} from "../../../../common/Utils/ApiConstants";

export const InitialValues = {
  companyId: [],
  name: "",
  dob: "",
  designation: "",
  department: "",
  reportTo: "",
  countryCode: jordanCountryCode,
  mobile: "",
  mobileShortCode: jordanCountryShortCode,
  alternateMobile: "",
  alternateMobileShortCode: jordanCountryShortCode,
  alternateMobileCountryCode: jordanCountryCode,
  companyNumber: "",
  companyExtension: "",
  email: "",
  resAddress: "",
  resFlat: "",
  resCountry: "",
  resState: "",
  resCity: "",
  resZipCode: "",
  resLat: "",
  resLng: "",
  workAddress: "",
  workFlat: "",
  workLat: "",
  workLng: "",
  workCountry: "",
  workState: "",
  workCity: "",
  workZipCode: "",
  gender: "MALE",
  Male: false,
  Female: false,
};

export const radioOptions = ["Male", "Female"];

import React, { memo } from "react";
import { Stack } from "@mui/material";
import { StyledTypography } from "../../common/Utils/StyledComponents";
import { colors } from "../../common/Theme/Colors";

const HighlightCard = ({
  title,
  content,
  customClassName = "highlightCard",
}) => {
  return (
    <Stack
      flexDirection={"row"}
      alignItems={"center"}
      className={customClassName}
    >
      {title && (
        <StyledTypography
          mx={1}
          style={{
            fontSize: "0.9rem",
            color: colors.primary,
            fontWeight: "600",
          }}
        >
          {title}
        </StyledTypography>
      )}
      <StyledTypography sx={{ fontSize: "0.8rem" }}>{content}</StyledTypography>
    </Stack>
  );
};

export default memo(HighlightCard);

import { Grid } from "@mui/material";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { StyledCard } from "../../../common/Utils/StyledComponents";
import { showToast } from "../../../common/Utils/ToastMessage";
import {
  useGetInboxListMutation,
  useSetEmailLableAsMutation,
} from "../../../request/InboxModule";
import Translate from "../../../translation/utils/translateFunction";
import ListView from "../../ListView";
import InboxItem from "../Components/InboxItem";
import { getInboxListColumns } from "../Components/column.data";

import { useSelector } from "react-redux";
import { getMoment } from "../../../common/Utils/commonFunction";
import Searchbar from "../../common/Searchbar";
import LabelAsModal from "../Components/LabelAsModal";
import { ACTIONABLE, INFORMATIVE } from "../Components/constants";

const SentMailList = ({ fixNavbar }) => {
  const [loading, setLoading] = useState(true);
  const { userData, isRtl } = useSelector((state) => state.formanagement);

  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [search, setSearch] = useState("");

  const [sentMailList, setSentMailList] = useState();
  const date = getMoment().toDate();
  const newDate = moment(date).format("YYYY-MM-DD");
  const lastPage = useRef(0);
  const [emailFilter, setEmailFilter] = useState("SENT");
  const navigate = useNavigate();
  const [selectedItem, setSelectedItem] = useState();
  const [selectedLabel, setSelectedLabel] = useState("");
  const [showLabelAsModal, setShowLabelAsModal] = useState(false);
  const [label, setLabel] = useState(false);
  const [showMailSyncMessage, setShowMailSyncMessage] = useState(false);
  const [
    getInboxListApi,
    {
      data: inboxListData,
      isLoading: isloadinginboxList,
      isSuccess: isSuccessInboxList,
      isError: isErrorInboxList,
      error: errorInboxList,
    },
  ] = useGetInboxListMutation();

  const [
    setEmailLableAsApi,
    { data: emailLableAsData, isSuccess: isEmailLableAsSuccess },
  ] = useSetEmailLableAsMutation();
  useEffect(() => {
    if (inboxListData && isSuccessInboxList) {
      const { nodes, pageInfo } = inboxListData.data;
      setSentMailList(nodes);

      lastPage.current = pageInfo.lastPageNo;
      setLoading(false);
      if (
        (userData?.isGmailSettings || userData?.isOutlookSettings) &&
        !inboxListData?.data.isGmailSync &&
        !inboxListData?.data.isOutlookSync
      ) {
        setShowMailSyncMessage(true);
      }
    }
    if (isErrorInboxList && errorInboxList) {
      showToast({ message: "Failed to fetch the data", type: "error" });
      setLoading(false);
    }
  }, [isSuccessInboxList, inboxListData]);

  useEffect(() => {
    let requestObject = {
      pageNo: currentPageNo,
      action: emailFilter,
      searchText: search,
    };
    getInboxListApi(requestObject);
  }, [currentPageNo, emailFilter, search]);

  const rowsWithSerialNumbers =
    sentMailList &&
    sentMailList.map((row, index) => ({
      ...row,

      name: row?.to[0]?.name || row?.to[0]?.name,
    }));

  const handleSearchText = (value) => {
    setCurrentPageNo(1);
    setSearch(value);
  };

  const CustomCellRender = (params) => {
    return (
      <InboxItem
        item={params?.row}
        emailFilter={emailFilter}
        inboxList={sentMailList}
        newDate={newDate}
      />
    );
  };

  const filters = ["Inbox", "sentFilter"];

  const getFilterNavigate = (selectedFilter) => {
    if (selectedFilter === Translate("inboxPage:Inbox")) {
      setCurrentPageNo(1);
      setEmailFilter("INBOX");
      navigate("/inbox/inboxList");
    }
  };

  const customLabelCellRender = (params) => {
    return (
      <>
        <div className="mb-1">
          <a
            href="/#"
            className="nav-link dropdown-toggle "
            data-toggle="dropdown"
            role="button"
            aria-haspopup="true"
            aria-expanded="false">
            <i
              className="fa fa-ellipsis-v manageIconColor filterIconSize"
              data-toggle="tooltip"
              title={Translate("inboxPage:filter")}
            />
          </a>
          <div className="dropdown-menu dropdownAlignment">
            <div onClick={() => showLabelModal(params.row)}>
              <a className="dropdown-item">{Translate(`inboxPage:labelAs`)}</a>
            </div>
          </div>
        </div>
      </>
    );
  };

  const showLabelModal = (item) => {
    setSelectedItem(item);

    setSelectedLabel(
      item?.isActionable ? ACTIONABLE : item?.isInformative ? INFORMATIVE : ""
    );
    setShowLabelAsModal(true);
  };
  const hideLabelModal = () => setShowLabelAsModal(false);
  const onOkClick = () => {
    const ids = [selectedItem?._id];

    let requestObject;

    if (selectedLabel === ACTIONABLE) {
      requestObject = {
        emailId: ids,
        isActionable: true,
      };
    } else if (selectedLabel === INFORMATIVE) {
      requestObject = {
        emailId: ids,
        isInformative: true,
      };
    } else if (selectedLabel === null && label) {
      requestObject = {
        emailId: ids,
        isInformative: false,
        isActionable: false,
      };
    }
    label && setEmailLableAsApi(requestObject);
    hideLabelModal();
  };

  useEffect(() => {
    if (isEmailLableAsSuccess && emailLableAsData?.success) {
      getInboxListApi({
        pageNo: currentPageNo,
        action: emailFilter,
        searchText: search,
      });
    }
    setLabel(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEmailLableAsSuccess]);

  return (
    <Grid
      item
      xs={12}
      className={`section-body ${isRtl ? "rtlMargin" : "ltrMargin"} ${
        fixNavbar ? "marginTop" : ""
      } `}>
      <StyledCard variant="outlined">
        <div className="container-fluid p-4">
          <div className="tab-content">
            <div>
              <div className="card">
                <div className="card-header card-header-spacing">
                  <div className="page-title ml-4">
                    {Translate("inboxPage:Sent")}
                  </div>
                  <div className="card-options">
                    <div>
                      <div className="input-group">
                        <Searchbar
                          handleSearchText={handleSearchText}
                          setSearch={setSearch}
                          disabled={showMailSyncMessage}
                        />
                      </div>
                    </div>

                    <div className="d-flex ml-2">
                      <div className="mt-2 headerMargin">
                        <a
                          href="/#"
                          className="nav-link dropdown-toggle "
                          data-toggle="dropdown"
                          role="button"
                          aria-haspopup="true"
                          aria-expanded="false">
                          <i
                            className="fa fa-ellipsis-v manageIconColor filterIconSize"
                            data-toggle="tooltip"
                            title={Translate("inboxPage:filter")}
                          />
                        </a>
                        <div className="dropdown-menu dropdownAlignment">
                          {filters.map((filterItem, index) => (
                            <>
                              <div
                                onClick={() =>
                                  getFilterNavigate(
                                    Translate(`inboxPage:${filterItem}`)
                                  )
                                }>
                                <a
                                  className={`dropdown-item cursor-pointer ${
                                    filterItem === "sentFilter"
                                      ? "selected unclickable"
                                      : ""
                                  }`}>
                                  {Translate(`inboxPage:${filterItem}`)}
                                </a>
                              </div>
                              {filters.length - 1 !== index && (
                                <div className="dropdown-divider" />
                              )}
                            </>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <ListView
                    columns={getInboxListColumns(
                      CustomCellRender,
                      customLabelCellRender
                    )}
                    rows={rowsWithSerialNumbers}
                    autoHeight
                    onPaginationChange={setCurrentPageNo}
                    currentPage={currentPageNo}
                    showShimmer={isloadinginboxList}
                    showLoader={loading || isloadinginboxList}
                    totalPageCount={lastPage.current}
                    hideColumnHeaders={true}
                    className="cursor-pointer"
                    isInbox={showMailSyncMessage}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {showLabelAsModal && (
          <LabelAsModal
            show={showLabelAsModal}
            onCancel={hideLabelModal}
            onOkClick={onOkClick}
            selectedLabel={selectedLabel}
            setSelectedLabel={setSelectedLabel}
            onLabelSelect={(event) => setLabel(event)}
          />
        )}
      </StyledCard>
    </Grid>
  );
};
export default SentMailList;

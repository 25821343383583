export default {
  notification: "Notification",
  notificationHeader: "Notifications",
  contact: "Contact",
  senderNameTitle: "Sender name",
  descriptionTitle: "Description",
  roleTitle: "Role",
  dueDateAndTimeTitle: "Date and time",
  viewHeading: "View notification",
};

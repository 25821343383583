import { Grid, Stack } from "@mui/material";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CompanyHeader from "../../../common/CompanyHeader";
import { colors } from "../../../common/Theme/Colors";
import { formatFullMonthYear } from "../../../common/Utils/DateFormater";
import {
  StyledCard,
  StyledTypography,
} from "../../../common/Utils/StyledComponents";
import { showToast } from "../../../common/Utils/ToastMessage";
import { getMoment } from "../../../common/Utils/commonFunction";
import {
  useApproveDisapproveleaveMutation,
  useDisapproveleaveMutation,
  useGetLeaveRequestMutation,
} from "../../../request/LeaveRequest";
import Translate from "../../../translation/utils/translateFunction";
import AlertPermission from "../../AlertPermission";
import CompanyListing from "../../ContactRepository/component";
import IconifyIcon from "../../Icon";
import ListView from "../../ListView";
import ModalFormikForm from "../../ModalForm/Formik";
import { leaveStatusEnum } from "../../Profile/LeaveRequest/constants";
import Loader from "../../staff/Owner/LoadingComp";
import { getLeaveRequestColumns } from "./column.data";
import {
  DisapprovedReasonSchema,
  InitialValuesForRejected,
} from "./validation";

const LeaveRequestListing = ({ fixNavbar }) => {
  const { selectedCompanyList, userData, isRtl } = useSelector(
    (state) => state.formanagement
  );
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [leaveRequests, setLeaveRequests] = useState([]);
  const [navigationFlag, setNavigationFlag] = useState();
  const [dateRange, setDateRange] = useState();
  const [selectedRow, setSelectedRow] = useState([]);
  const [showDisapproveReasonbox, setShowDisapproveReasonbox] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [isApprove, setIsApprove] = useState(true);
  const lastPage = useRef(0);

  const [
    getLeaveRequestApi,
    {
      data: serverResponseData,
      isLoading: isLeaveRequestLoading,
      isSuccess: isLeaveRequestSuccess,
    },
  ] = useGetLeaveRequestMutation();
  const [
    approveDisapprove,
    {
      data: serverResponseApproveDisapprove,
      isSuccess: isApproveDisapproveSuccess,
      isLoading: isApproveDisapproveLoading,
    },
  ] = useApproveDisapproveleaveMutation();
  const [
    disapprove,
    {
      data: serverResponseDisapprove,
      isSuccess: isDisapproveSuccess,
      isLoading: isDisapproveLoading,
    },
  ] = useDisapproveleaveMutation();
  const requestObj = useMemo(() => {
    const companies = selectedCompanyList?.length
      ? selectedCompanyList?.map((item) => item?.value)
      : [];

    const finalReq = {
      companyId: companies,
      date: dateRange
        ? moment(dateRange).add("d", 1).toISOString()
        : getMoment().toDate(),
      searchText: searchText,
      pageNo: currentPage,
    };
    return companies?.length ? finalReq : null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, searchText, selectedCompanyList, dateRange]);

  useEffect(() => {
    getLeaveRequestApi(requestObj);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestObj]);
  useEffect(() => {
    if (isLeaveRequestSuccess && serverResponseData?.data) {
      const { pageInfo, record } = serverResponseData?.data;
      lastPage.current = pageInfo?.lastPageNo;
      setLeaveRequests(record);
      setNavigationFlag(false);
    }
  }, [isLeaveRequestSuccess]);
  useEffect(() => {
    if (isApproveDisapproveSuccess && serverResponseApproveDisapprove) {
      showToast({
        message: serverResponseApproveDisapprove?.message,
        type: "success",
      });
      getLeaveRequestApi(requestObj);
      setSelectedRow([]);
    }
  }, [isApproveDisapproveSuccess]);
  useEffect(() => {
    if (isDisapproveSuccess && serverResponseDisapprove) {
      showToast({
        message: serverResponseDisapprove?.message,
        type: "success",
      });
      getLeaveRequestApi(requestObj);
      setSelectedRow([]);
    }
  }, [isDisapproveSuccess]);
  const customActionCellRender = (params) => {
    return (
      <td>
        <i
          className="m-2 fa fa-eye cursor-pointer manageIconColor"
          data-toggle="tooltip"
          title={Translate("common:viewToolTip")}
          onClick={() =>
            navigate("/manageRequest/leaveRequest/view", {
              state: { leaveId: params?.row?._id },
            })
          }
        ></i>
      </td>
    );
  };

  const customSrCellRender = (params) => {
    return (
      <StyledTypography component="span" sx={{ color: colors.primary_009 }}>
        {params?.api?.getRowIndexRelativeToVisibleRows(params?.row?._id) +
          1 +
          (currentPage - 1) * 10}
      </StyledTypography>
    );
  };

  // const handleSearchText = (value) => {
  //   selectedCompanyList?.length > 0 && setCurrentPage(1);
  //   setSearchText(value);
  // };
  const onAcceptAlert = () => {
    isApprove ? handleApproveDisapprove() : setShowDisapproveReasonbox(true);
    setShowAlert(false);
  };
  const onCancelAlert = () => {
    setShowAlert(false);
  };
  const onCancelDisapproveReasonAlert = () => {
    setShowDisapproveReasonbox(false);
  };
  const onDisapproveReasonAlert = (value) => {
    handleApproveDisapprove(false, value.disapproveReason);
    setShowDisapproveReasonbox(false);
  };
  const handleApproveDisapprove = (isApprove = true, reason = "") => {
    const requestObj = {
      // companyId: selectedCompanyList?.map((item) => item?.value),
      leaveId: selectedRow,
      leaveStatus: isApprove
        ? leaveStatusEnum.approved
        : leaveStatusEnum.disapproved,
      ...(isApprove ? {} : { reason }),
    };
    isApprove ? approveDisapprove(requestObj) : disapprove(requestObj);
  };
  return (
    <Grid
      item
      xs={12}
      className={`section-body ${isRtl ? "rtlMargin" : "ltrMargin"} ${
        fixNavbar ? "marginTop" : ""
      } `}
    >
      <StyledCard variant="outlined">
        <div className="container-fluid">
          <div className="tab-content">
            <div className="card cardHeight">
              <div className="row">
                <div className="col-12">
                  <div className="card-header fixedHeight">
                    <CompanyHeader setShowModal={setShowModal} />
                    <Stack
                      flexDirection={"row"}
                      alignItems={"center"}
                      className="card-options"
                    >
                      <IconifyIcon
                        className="cursor-pointer"
                        icon={"ant-design:left-circle-outlined"}
                        fontSize="24"
                        style={{ color: colors.black }}
                        onClick={() =>
                          setDateRange(
                            moment(dateRange).subtract("month", 1).format()
                          )
                        }
                      />

                      <StyledTypography
                        variant="h6"
                        px={2}
                        color={colors.primary}
                        sx={{ fontWeight: "500" }}
                      >
                        {formatFullMonthYear(dateRange)}
                      </StyledTypography>

                      <IconifyIcon
                        className="cursor-pointer"
                        icon={"ant-design:right-circle-outlined"}
                        style={{ color: colors.black }}
                        fontSize="24"
                        onClick={() =>
                          setDateRange(
                            moment(dateRange).add("month", 1).format()
                          )
                        }
                      />
                    </Stack>

                    <div className="card-options">
                      <div className="sort_stat commentTextField">
                        <form
                          className="headerMargin"
                          style={{ display: "flex" }}
                        >
                          {/* <div className="input-group mr-2">
                            <Searchbar
                              handleSearchText={handleSearchText}
                              setSearch={setSearchText}
                            />
                          </div> */}
                          {selectedRow?.length > 0 && (
                            <div className="flexCenter" style={{ gap: "20px" }}>
                              <button
                                type="button"
                                className="btn taskAcceptBtn saveButton"
                                onClick={() => {
                                  setIsApprove(true);
                                  setShowAlert(true);
                                }}
                              >
                                {Translate("taskDetails:approveButtonLabel")}
                              </button>
                              <button
                                type="button"
                                className="btn taskRejectBtn saveButton"
                                onClick={() => {
                                  setIsApprove(false);
                                  setShowAlert(true);
                                }}
                              >
                                {Translate("taskDetails:disapproveButtonLabel")}
                              </button>
                            </div>
                          )}
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card-body">
                <ListView
                  columns={getLeaveRequestColumns(
                    customSrCellRender,
                    customActionCellRender
                  )}
                  rows={leaveRequests}
                  autoHeight
                  sortingMode="server"
                  checkboxSelection={true}
                  onRowSelectionModelChange={(newRowSelectionModel) => {
                    setSelectedRow(newRowSelectionModel);
                  }}
                  selectionModel={selectedRow}
                  disableRowSelectionOnClick
                  disableColumnSelector={false}
                  onPaginationChange={setCurrentPage}
                  currentPage={currentPage}
                  showShimmer={isLeaveRequestLoading}
                  showLoader={isLeaveRequestLoading}
                  totalPageCount={lastPage.current}
                  isRowSelectable={(params) =>
                    params?.row?.leaveStatus !== leaveStatusEnum.disapproved
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <CompanyListing
          handleModal={(value) => {
            setCurrentPage(1);
            setShowModal(value);
          }}
          showModal={showModal}
          selectedCompanyList={selectedCompanyList}
        />
        <AlertPermission
          show={showAlert}
          subTitle={
            isApprove
              ? Translate("leaveRequest:approveTaskAlert")
              : Translate("leaveRequest:disapproveTaskAlert")
          }
          okTitle={Translate("common:ok")}
          onOk={onAcceptAlert}
          onCanel={onCancelAlert}
        />
        {isApproveDisapproveLoading && (
          <Loader loading={isApproveDisapproveLoading} />
        )}
        <ModalFormikForm
          show={showDisapproveReasonbox}
          title={Translate("leaveRequest:reasonTitle")}
          fieldName={"disapproveReason"}
          onOk={onDisapproveReasonAlert}
          onCancel={onCancelDisapproveReasonAlert}
          btnLabel={Translate("taskDetails:saveButtonLabel")}
          placeholder={Translate("leaveRequest:reasonplaceholder")}
          schema={DisapprovedReasonSchema}
          InitialValues={InitialValuesForRejected}
        />
      </StyledCard>
    </Grid>
  );
};
export default LeaveRequestListing;

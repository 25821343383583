import { Methods, Urls } from "../../common/Utils/ApiConstants";

import { CreateApi } from "../CreateApi";
const ContactRepositoryListings = CreateApi.injectEndpoints({
  endpoints: (builder) => ({
    getPrivateListing: builder.query({
      query: ({ currentPageNo, contactType, debouncedSearch }) => {
        return {
          url: Urls.getContactRepoPrivateList,
          method: Methods.get,
          params: {
            pageNo: currentPageNo,
            contactType: contactType,

            isSortingEnabled: false,

            ...(debouncedSearch ? { searchValue: debouncedSearch } : null),
          },
        };
      },
    }),
    markAsPublic: builder.mutation({
      query: (body) => {
        return {
          url: `${Urls.markAsPublic}${body.contactType}`,
          method: Methods.put,
          body: body.body,
        };
      },
    }),
    getAccessLogs: builder.mutation({
      query: (requestBody) => {
        return {
          url: Urls.contactRepoAccessLogs,
          method: Methods.post,
          body: requestBody,
        };
      },
    }),
    viewBusinessCard: builder.mutation({
      query: (body) => {
        return {
          url: `${Urls.viewBusinessCard}${body}`,
          method: Methods.get,
        };
      },
    }),
    getSelectMemberList: builder.mutation({
      query: (body) => {
        return {
          url: Urls.getSelectMemberlist,
          method: Methods.post,
          body: body,
        };
      },
    }),
    addContact: builder.mutation({
      query: (body) => {
        return {
          url: Urls.addContact,
          method: Methods.post,
          body: body,
        };
      },
    }),
    editContact: builder.mutation({
      query: (body) => {
        return {
          url: `${Urls.editContact}${body.id}`,
          method: Methods.put,
          body: body.body,
        };
      },
    }),
    getPublicListing: builder.query({
      query: ({ currentPageNo, contactType, debouncedSearch, companies }) => {
        return {
          url: Urls.getContactRepoPublicList,
          method: Methods.get,
          params: {
            pageNo: currentPageNo,
            contactType: contactType,

            isSortingEnabled: false,

            ...(companies?.length <= 1 ? { companyIdFilter: companies } : null),

            ...(debouncedSearch ? { searchValue: debouncedSearch } : null),
          },
        };
      },
    }),
    deleteContact: builder.mutation({
      query: (body) => {
        return {
          url: Urls.deleteContact,
          body: body,
          method: Methods.delete,
        };
      },
    }),
    getShareContactInviteeLite: builder.mutation({
      query: (body) => {
        return {
          url: Urls.getShareContactInvitee,
          method: Methods.post,
          body: body,
        };
      },
    }),
    getShareContactRequest: builder.mutation({
      query: (body) => {
        return {
          url: Urls.getShareContact,
          method: Methods.post,
          body: body,
        };
      },
    }),
    getSharedList: builder.mutation({
      query: (body) => {
        return {
          url: Urls.getSharedList,
          method: Methods.post,
          body: body,
        };
      },
    }),
    viewSharedContactBusinessCard: builder.mutation({
      query: (body) => {
        return {
          url: `${Urls.viewSharedContactBusinessCard}${body}`,
          method: Methods.get,
        };
      },
    }),
    getSelectMemberPrivateContacts: builder.mutation({
      query: (body) => {
        return {
          url: Urls.selectMemberPrivateContacts,
          method: Methods.post,
          body: body,
        };
      },
    }),
    getSelectPaSharedContact: builder.mutation({
      query: (body) => {
        return {
          url: Urls.getShareContactPa,
          method: Methods.post,
          body: body,
        };
      },
    }),
    getSelectMemberShareListing: builder.mutation({
      query: (body) => {
        return {
          url: Urls.getShareRoleFilter,
          method: Methods.post,
          body: body,
        };
      },
    }),
    getContcatInviteeTreeList: builder.mutation({
      query: (body) => ({
        url: Urls.getContactInviteeTreeList,
        method: Methods.post,
        body: body,
      }),
    }),
    getrequestContactList: builder.mutation({
      query: (body) => ({
        url: Urls.requestContactList,
        method: Methods.post,
        body: body,
      }),
    }),
    getRequestContactApi: builder.mutation({
      query: (body) => ({
        url: Urls.requestContact,
        method: Methods.post,
        body: body,
      }),
    }),
  }),
});
export const {
  useGetPrivateListingQuery,
  useLazyGetPrivateListingQuery,
  useMarkAsPublicMutation,
  useGetAccessLogsMutation,
  useViewBusinessCardMutation,
  useGetSelectMemberListMutation,
  useAddContactMutation,
  useEditContactMutation,
  useGetPublicListingQuery,
  useDeleteContactMutation,
  useGetShareContactInviteeLiteMutation,
  useGetShareContactRequestMutation,
  useGetSharedListMutation,
  useViewSharedContactBusinessCardMutation,
  useGetSelectMemberPrivateContactsMutation,
  useGetSelectPaSharedContactMutation,
  useGetSelectMemberShareListingMutation,
  useGetContcatInviteeTreeListMutation,
  useGetrequestContactListMutation,
  useGetRequestContactApiMutation,
} = ContactRepositoryListings;

import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import VendorForm from "../Components/VendorForm";
import { showToast } from "../../../common/Utils/ToastMessage";
import { InitialValues } from "../Components/constants";
import {
  useEditVendorDataMutation,
  useDeleteVendorDataMutation,
} from "../../../request/VendorsSuppliers";
import {
  AddVendorSchema,
  validateAddressFields,
} from "../Components/validators";
import Translate from "../../../translation/utils/translateFunction";
import { Formik } from "formik";

const EditVendor = () => {
  const { state } = useLocation();
  const formikRef = useRef(null);
  const navigate = useNavigate();

  const [reportTo, setReportTo] = useState("");
  const [filesAdded, setFilesAdded] = useState([]);
  const [images, setImages] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const initialImage = [state?.profileImageDetails];
  const [
    editVendorApi,
    {
      data: vendorData,
      isSuccess: isVendorSuccess,
      isLoading: isVendorLoading,
      isError: isVendorError,
      error: vendorError,
    },
  ] = useEditVendorDataMutation();

  const [
    deleteVendorApi,
    {
      data: vendorDeleteData,
      isSuccess: isDeleteVendorSuccess,
      isLoading: isDeleteVendorLoading,
      isError: isDeleteVendorError,
      error: deleteVendorError,
    },
  ] = useDeleteVendorDataMutation();

  useEffect(() => {
    if (state) {
      formikRef.current.setFieldValue("companyId", state?.companyId[0]._id);
      setSelectedCompany(state?.companyId[0]._id);
      formikRef.current.setFieldValue("companyName", state?.companyName);
      formikRef.current.setFieldValue("name", state?.name);
      formikRef.current.setFieldValue("designation", state?.designation);
      formikRef.current.setFieldValue("department", state?.department);
      formikRef.current.setFieldValue(
        "mobile",
        `${state?.login?.countryCode}-${state?.login?.mobile}`
      );
      formikRef.current.setFieldValue(
        "mobileShortCode",
        state?.login.mobileShortCode
      );
      formikRef.current.setFieldValue("countryCode", state?.login.countryCode);
      // formikRef.current.setFieldValue(
      //   "alternateMobile",
      //   state?.alternateMobile
      // );
      // formikRef.current.setFieldValue(
      //   "alternateMobileCountryCode",
      //   state?.alternateMobileCountryCode
      //     ? "+" + state?.alternateMobileCountryCode
      //     : "+91"
      // );
      // formikRef.current.setFieldValue(
      //   "alternateMobileShortCode",
      //   state?.alternateMobileShortCode
      // );

      if (state?.alternateMobile) {
        formikRef.current.setFieldValue(
          "alternateMobile",

          `${state?.alternateMobileCountryCode}-${state?.alternateMobile}`
        );
      }
      formikRef.current.setFieldValue(
        "alternateMobileCountryCode",
        state?.alternateMobileCountryCode
          ? state?.alternateMobileCountryCode
          : "962"
      );
      formikRef.current.setFieldValue(
        "alternateMobileShortCode",
        state?.alternateMobileShortCode ? state?.alternateMobileShortCode : "JO"
      );
      formikRef.current.setFieldValue("companyNumber", state?.companyNumber);
      formikRef.current.setFieldValue(
        "companyExtension",
        state?.companyExtension
      );
      formikRef.current.setFieldValue("email", state?.login.email);
      formikRef.current.setFieldValue("flat", state?.workAddress.flat);
      formikRef.current.setFieldValue("state", state?.workAddress.state);
      formikRef.current.setFieldValue("country", state?.workAddress.country);
      formikRef.current.setFieldValue("city", state?.workAddress.city);
      formikRef.current.setFieldValue("zipCode", state?.workAddress.zipCode);
      formikRef.current.setFieldValue(
        "workAddress",
        state?.workAddress.address
      );
      formikRef.current.setFieldValue(
        "latitude",
        state?.workAddress.latlong.latitude
      );
      formikRef.current.setFieldValue(
        "longitude",
        state?.workAddress.latlong.longitude
      );
      formikRef.current.setFieldValue("gender", state?.gender);
      formikRef.current.setFieldValue("reportTo", state?.reportTo?._id);
      // if (state?.profileUrl) {
      //   const obj = {
      //     path: decodeURIComponent(state?.profileUrl).split("/").pop(),
      //     type: "image/png",
      //     url: state?.profileUrl,
      //   };
      //   setFilesAdded([...filesAdded, obj]);
      //   setImages([state?.profileUrl]);
      // }
      if (
        state?.profileImageDetails &&
        Object.keys(state?.profileImageDetails).includes("url")
      ) {
        setFilesAdded([state?.profileImageDetails]);
        setImages([state?.profileImageDetails?.url]);
      }
    }
  }, [state]);

  useEffect(() => {
    if (isVendorError && vendorError) {
      const vendorErrorObj = vendorError?.data;

      if (!vendorErrorObj) {
        showToast({
          message: Translate("common:unknownServerError"),
          type: "error",
        });
      } else {
        vendorErrorObj?.error?.length
          ? vendorErrorObj.error.map((errorItem) => {
              if (!InitialValues.hasOwnProperty(errorItem?.param)) {
                const tempParam = validateAddressFields(errorItem?.param);

                if (tempParam) {
                  return formikRef.current.setFieldError(
                    tempParam,
                    errorItem?.msg
                  );
                }

                return showToast({ message: errorItem?.msg, type: "error" });
              }

              return formikRef.current.setFieldError(
                errorItem?.param,
                errorItem?.msg
              );
            })
          : showToast({
              message:
                vendorErrorObj?.message ||
                Translate("common:unknownServerError"),
              type: "error",
            });
      }
    }

    if (isVendorSuccess && vendorData) {
      if (!vendorData?.success) {
        showToast({ message: vendorData?.message, type: "error" });
      } else {
        showToast({ message: vendorData?.message, type: "success" });
      }

      formikRef.current.resetForm();
      InitialValues.dob = "";
      setFilesAdded([]);
      setImages([]);

      navigate("/vendors");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendorData, vendorError]);

  useEffect(() => {
    if (deleteVendorError && isDeleteVendorError) {
      deleteVendorError.data?.error
        ? deleteVendorError.data.error.length > 0 &&
          deleteVendorError.data.error.map((errorItem) =>
            formikRef.current?.setFieldError(errorItem.param, errorItem.msg)
          )
        : showToast({
            message: deleteVendorError?.data?.message,
            type: "error",
          });
    }

    if (isDeleteVendorSuccess && vendorDeleteData) {
      showToast({
        message: vendorDeleteData.message,
        type: "success",
      });
      navigate("/vendors");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteVendorError, vendorDeleteData]);

  const onSubmit = (formValues) => {
    const {
      companyId,
      countryCode,
      alternateMobile,
      alternateMobileCountryCode,
    } = formValues;

    const updatedReportTo = { ...reportTo };

    if (updatedReportTo?.id) {
      delete updatedReportTo["label"];
      delete updatedReportTo["value"];
    }

    let reqObj = {
      companyId: [companyId],
      companyName: formValues?.companyName,
      name: formValues.name,
      gender: formValues?.gender.toUpperCase(),
      designation: formValues.designation,
      department: formValues.department,
      reportTo: updatedReportTo,
      countryCode: countryCode ? countryCode : "962",
      mobile: formValues?.mobile?.split("-")[1],
      mobileShortCode: formValues.mobileShortCode,
      // alternateMobile,
      // alternateMobileCountryCode: alternateMobile
      //   ? alternateMobileCountryCode
      //     ? alternateMobileCountryCode.slice(1)
      //     : "91"
      //   : "",
      // alternateMobileShortCode: formValues?.alternateMobileShortCode,
      companyExtension: formValues.companyExtension,
      companyNumber: formValues.companyNumber,
      email: formValues.email,
      workAddress: {
        latitude: formValues.latitude,
        longitude: formValues.longitude,
        latlong: {
          latitude: formValues.latitude,
          longitude: formValues.longitude,
        },
        flat: formValues.flat,
        address: formValues.workAddress,
        country: formValues.country,
        state: formValues.state,
        countryISO: "IN",
        city: formValues.city,
        zipCode: formValues.zipCode,
      },
      emailVerified: "YES",
      mobileVerified: "YES",
      primary: "NO",
      system: "NO",
      role: state?.role,
      profileUrl: "",
    };
    if (
      formValues?.alternateMobile?.length > 0 &&
      !formValues?.alternateMobile?.endsWith("-")
    ) {
      reqObj = {
        ...reqObj,
        alternateMobile: formValues?.alternateMobile?.split("-")[1],

        alternateMobileCountryCode: formValues?.alternateMobileCountryCode,

        alternateMobileShortCode: formValues?.alternateMobileShortCode,
      };
    } else {
      reqObj = {
        ...reqObj,
        alternateMobile: "",
        alternateMobileCountryCode: "",

        alternateMobileShortCode: "",
      };
    }

    // let b64Array;

    // if (filesAdded.length) {
    //   if (filesAdded[0]?.b64?.length) {
    //     b64Array = filesAdded[0].b64;
    //     reqObj["webProfileUrl"] = b64Array;
    //   } else {
    //     reqObj["profileUrl"] = state?.profileUrl;
    //   }
    // }
    if (filesAdded.length > 0) {
      if (filesAdded.every((value) => initialImage?.includes(value))) {
        reqObj = {
          ...reqObj,
          profileUrl: state?.profileUrl,
          profileImageDetails: state?.profileImageDetails,
        };
      } else {
        const b64Object = {
          ...filesAdded.map((ele) => ({
            url: ele?.b64,
            type: ele?.type,
            profileFileName: ele?.name,
            profileFileExt: ele?.name.split(".").pop(),
          }))[0],
        };

        reqObj = {
          ...reqObj,
          webProfileUrl: b64Object,
        };
      }
    } else {
      reqObj = {
        ...reqObj,
      };
    }

    editVendorApi({
      body: reqObj,
      id: state?._id,
    });
  };

  const deleteVendor = () => {
    setShowAlert(false);
    deleteVendorApi({ vendorId: state._id });
  };

  return (
    <Formik
      initialValues={InitialValues}
      innerRef={formikRef}
      validateOnMount
      onSubmit={onSubmit}
      validationSchema={AddVendorSchema}
    >
      {({ values, setFieldValue, handleSubmit }) => {
        return (
          <VendorForm
            formikRef={formikRef}
            isEdit
            isLoading={isDeleteVendorLoading || isVendorLoading}
            vendorData={state}
            values={values}
            setFieldValue={setFieldValue}
            handleSubmit={handleSubmit}
            reportTo={reportTo}
            setReportTo={setReportTo}
            filesAdded={filesAdded}
            setFilesAdded={setFilesAdded}
            images={images}
            setImages={setImages}
            selectedCompany={selectedCompany}
            setSelectedCompany={setSelectedCompany}
            deleteVendor={deleteVendor}
            showAlert={showAlert}
            setShowAlert={setShowAlert}
          />
        );
      }}
    </Formik>
  );
};

export default EditVendor;

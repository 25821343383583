import { Grid } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import { colors } from "../../../common/Theme/Colors";
import {
  StyledCard,
  StyledTypography,
} from "../../../common/Utils/StyledComponents";
import { showToast } from "../../../common/Utils/ToastMessage";
import {
  useGetAddAccessLogsMutation,
  useGetShareVoiceNotesMutation,
  useGetSharedWithMeListingMutation,
} from "../../../request/VoiceNotes";
import Translate from "../../../translation/utils/translateFunction";
import ListView from "../../ListView";
import ShareWithUser from "../../ShareWithUser";
import { shareModuleType } from "../../ShareWithUser/components/constants";
import VoiceNotes from "../Components/VoiceNotes";
import {
  action,
  documentCollection,
  documentDescription,
} from "../Components/constants";
import { getSharedWithMeVoiceNoteColumns } from "./column.data";
import { useSelector } from "react-redux";
import Searchbar from "../../common/Searchbar";

const SharedWithMe = ({ fixNavbar }) => {
  const { isRtl } = useSelector((state) => state.formanagement);

  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setLoading] = useState(true);
  const [lastPageNo, setLastPageNo] = useState(0);
  const [voiceNotes, setVoiceNotes] = useState([]);
  const [showShareModal, setShowShareModal] = useState(false);
  const [selectedVoiceNoteId, setSelectedVoiceNoteId] = useState();
  const [voiceNotePlay, setVoiceNotePlay] = useState(false);
  const [playedVoiceNoteId, setPlayedVoiceNoteId] = useState();
  const [selectedVoiceNote, setSelectedVoiceNote] = useState("");
  const ref = useRef(null);

  const [
    getSharedWithMe,
    {
      data,
      isSuccess: successfullVoiceNoteList,
      isLoading: loadingVoiceNotes,

      isError,
      error,
    },
  ] = useGetSharedWithMeListingMutation();

  useEffect(() => {
    if (successfullVoiceNoteList && data) {
      const { nodes, pageInfo } = data?.data;
      setLastPageNo(pageInfo?.lastPageNo);
      setVoiceNotes(nodes);
      setLoading(false);
    }
    if (isError && error) {
      setLoading(false);
      error?.originalStatus !== 500 &&
        showToast({
          message: Translate("VoiceNotes:apiFailedMessage"),
          type: "error",
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, successfullVoiceNoteList]);

  const [
    shareVoiceNoteApi,
    {
      data: shareVoiceNote,
      isError: isErrorShareVoiceNote,
      error: errorShareVoiceNote,
      isSuccess: isSuccessShareVoiceNote,
    },
  ] = useGetShareVoiceNotesMutation();

  const [
    addAccessLogsApi,
    {
      data: addAccessLogsVoiceNote,
      isError: isErrorAddAccessLogs,
      error: errorAddAccessLogs,
      isSuccess: isSuccessAddAccessLogs,
    },
  ] = useGetAddAccessLogsMutation();

  useEffect(() => {
    let requestObject = {
      documentId: playedVoiceNoteId,
      typeOfAction: action,
      documentCollection: documentCollection,
      documentDescription: documentDescription,
    };
    if (voiceNotePlay) {
      addAccessLogsApi(requestObject);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [voiceNotePlay, playedVoiceNoteId]);

  useEffect(() => {
    if (addAccessLogsVoiceNote && isSuccessAddAccessLogs) {
      setVoiceNotePlay(false);
    }
    if (errorAddAccessLogs && isErrorAddAccessLogs) {
      errorAddAccessLogs?.originalStatus !== 500 &&
        showToast({
          message:
            errorAddAccessLogs?.message ||
            Translate("document:apiFailedMessage"),
          type: "error",
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addAccessLogsVoiceNote, isSuccessAddAccessLogs, isErrorAddAccessLogs]);

  useEffect(() => {
    const finalRequestObject = {
      pageNo: currentPage,
      searchText: search,
    };
    if (shareVoiceNote && isSuccessShareVoiceNote) {
      showToast({ message: shareVoiceNote?.message, type: "success" });
      getSharedWithMe(finalRequestObject);
    }
    if (errorShareVoiceNote && isErrorShareVoiceNote) {
      errorShareVoiceNote?.originalStatus !== 500 &&
        showToast({
          message:
            errorShareVoiceNote?.message ||
            Translate("document:apiFailedMessage"),
          type: "error",
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shareVoiceNote, isSuccessShareVoiceNote, isErrorShareVoiceNote]);

  useEffect(() => {
    const finalRequestObject = {
      pageNo: currentPage,
      searchText: search,
    };

    if (finalRequestObject?.searchText?.length) {
      debouncedSearch();
    } else {
      getSharedWithMe(finalRequestObject);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, search]);

  const debouncedSearch = useDebouncedCallback(() => {
    const finalRequestObject = {
      pageNo: currentPage,
      searchText: search,
    };
    getSharedWithMe(finalRequestObject);
  }, 500);
  const onSearch = (value) => {
    setCurrentPage(1);

    setSearch(value);
  };
  const onCloseShareModal = () => setShowShareModal(false);

  const handleShareVoiceNote = (users) => {
    const reqObj = {
      voicenoteId: [selectedVoiceNoteId],
      users: users?.map((ele) => ele._id),
    };

    shareVoiceNoteApi(reqObj);
  };

  const customSrCellRender = (params) => {
    return (
      <StyledTypography component="span" sx={{ color: colors.primary_009 }}>
        {params?.api?.getRowIndexRelativeToVisibleRows(params?.row?._id) +
          1 +
          (currentPage - 1) * 10}
      </StyledTypography>
    );
  };
  const customCellRender = (params) => {
    return (
      <VoiceNotes
        voiceObj={{
          byteCode: params?.row?.buffer,
          timeLength: params?.row?.timeLength,
          src: params?.row?.link,
          createdAt: params?.row?.lastSharedTime,
          timeLengthInSec: params?.row?.timeLengthInSec?.toString(),
        }}
        className={"voiceNote_spacing "}
        isShared={params?.row?.hasShared}
        isVoiceNotesList={false}
        onVoiceNotePlay={(data) => {
          if (data) {
            setVoiceNotePlay(data);
            setPlayedVoiceNoteId(params?.row?._id);
          }
        }}
        params={params?.row?._id}
        currentVoiceNote={selectedVoiceNote && selectedVoiceNote}
      />
    );
  };
  // const customActionCellRender = (params) => {
  //   return (
  //     <>
  //       <i
  //         className={`fe fe-share-2 cursor-pointer m-2 manageIconColor `}
  //         onClick={() => {
  //           setShowShareModal(true);
  //           setSelectedVoiceNoteId(params?.row?._id);
  //         }}></i>
  //       <i
  //         className="fa fa-file-text selectColor cursor-pointer m-2"
  //         onClick={() =>
  //           navigate("/voiceNotes/sharedWithMe/accessLogs", {
  //             state: params?.row?._id,
  //           })
  //         }></i>
  //     </>
  //   );
  // };
  return (
    <Grid
      item
      xs={12}
      className={`section-body ${isRtl ? "rtlMargin" : "ltrMargin"} ${
        fixNavbar ? "marginTop" : ""
      } `}>
      <StyledCard variant="outlined">
        <div className="container-fluid p-4" ref={ref}>
          <div className="tab-content">
            <div>
              <div className="card">
                <div className="card-header card-header-spacing">
                  <div className="card-options">
                    <div className="input-group">
                      <Searchbar
                        handleSearchText={onSearch}
                        setSearch={setSearch}
                      />
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <ListView
                    columns={getSharedWithMeVoiceNoteColumns(
                      customSrCellRender,
                      customCellRender,
                      ref.current?.offsetWidth
                    )}
                    showDynamicRowHeight
                    rows={voiceNotes}
                    autoHeight={false}
                    sortingMode="server"
                    onPaginationChange={setCurrentPage}
                    currentPage={currentPage}
                    showShimmer={isLoading || loadingVoiceNotes}
                    showLoader={isLoading || loadingVoiceNotes}
                    totalPageCount={lastPageNo}
                    onCellClick={(params) => {
                      if (params?.field === "voiceNote") {
                        setSelectedVoiceNote(params?.row?._id);
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {showShareModal && (
          <ShareWithUser
            currentModule={shareModuleType?.MY_VOICE_NOTE}
            showModal={showShareModal}
            closeModal={onCloseShareModal}
            onSubmit={handleShareVoiceNote}
          />
        )}
      </StyledCard>
    </Grid>
  );
};
export default SharedWithMe;

import Translate from "../../../../translation/utils/translateFunction";

export const getBusinessCardListColumns = (
  customCellRender,
  customActionCellRender,
  CustomSrCellRender
) => {
  const columns = [
    {
      field: "_id",
      align: "center",
      headerAlign: "center",
      headerName: Translate("contacts:srNo"),
      sortable: false,
      disableColumnMenu: true,
      renderCell: CustomSrCellRender,
    },
    {
      field: "name",
      headerName: Translate("contacts:userName"),
      flex: 0.5,
      sortable: false,
      disableColumnMenu: true,
      renderCell: customCellRender,
    },
    {
      field: "mobile",
      headerName: Translate("contacts:mobile"),
      flex: 0.4,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "role",
      headerName: Translate("contacts:role"),
      flex: 0.4,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "Action",
      headerName: Translate("common:actionTitle"),
      flex: 0.2,
      sortable: false,
      disableColumnMenu: true,
      renderCell: customActionCellRender,
    },
  ];
  return columns;
};

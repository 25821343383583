export default {
  selectContacts: "جهات الإتصال",
  contactRepository: "بيانات جهات الاتصال",
  editCard: "تعديل بطاقة العمل",
  public: "عام",
  private: "خاص",
  shared: "تم المشاركة",
  markPrivate: "تعليم كخاص",
  markPublic: "تعليم كعام",
  delete: "حذف",
  deleteAlert: "هل أنت متأكد من حذف جهة الإتصال",
  owner: "املك",
  manager: "المدير",
  companySort: "ترتيب حسب الشركة",
  industrySort: "ترتيب حسب الصناعة",
  departSort: "ترتيب حسب القسم",
  sectorSort: "ترتيب حسب القطاع",
  fieldSort: "ترتيب حسب الحقل",
  sharedContacts: "جهات اتصال تم مشاركتها",
  shareContact_1: "تم مشاركة جهة الإتصال مع",
  shareContact_2: "الأشخاص",
  sharedWithMe: "تمت مشاركته معي",
  sharedByMe: "تمت مشاركته بواسطتي",
  tooltip: "تحميل بطاقة العمل",
  accessLogs: "سجلات الدخول",
  sortByCompany: "ترتيب حسب الشركة",
  sortByDepartment: "ترتيب حسب القسم",
  sortByManager: "ترتيب حسب المدير",
  createContact: "انشاء جهة اتصال",
  contacts: "جهات الإتصال",
  addPhoto: "أضف صورة",
  changePhoto: "تغيير الصورة",
  shareContactWith: "مشاركة جهة الإتصال مع",
  contactSyncing: "مزامنة جهات الإتصال",
  fetchingContacts: "جاري جلب جهات الاتصال من الهاتف",
  accessLogsHeading: "سجلات الدخول",
  apiFailedMessage: "فشل في جلب البيانات",
  Owner: "المالك",
  PA: "المساعد/ة الشخصي",
  OWNER: "المالك",
  GM: "المدير العام",
  MANAGER: "المدير",
  EMPLOYEE: "الموظف",
  generalManager: "المدير العام",
  Manager: "المدير",
  selectComapnyMessage: "الرجاء اختيار شركة واحدة على الأقل",
  markAsPublic: "تعليم كعام",
  share: "مشاركة",
  filter: "اختر",
  view: "عرض",
  accesslogs: "سجلات الدخول",
  selectMemberHeading: "اختر عضو",
  privateContacts: "جهات الإتصال الخاصة",
  name: "اسم جهة الإتصال",
  designation: "الوظيفة",
  company: "شركة",
  contactNumber: "رقم جهة الإتصال",
  contactEmail: "تواصل بالبريد الاكتروني",
  contactAddress: "العنوان",
  addContact: "إضافة جهة اتصال",
  selectCompany: "اسم الشركة",
  contactName: "اسم جهة الإتصال",
  contactNamePlaceholder: "اسم جهة الإتصال",
  contactdesignation: "الوظيفة",
  industry: "الصناعة/القطاع",
  department: "القسم",
  sector: "القطاع",
  field: "الحقل",
  workEmail: "ايميل العمل",
  contactNumberPlaceHolder: "0000 0000 00",
  alternateNumber: "رقم جهة اتصال بديل",
  address: "العنوان",
  contactNameError: "اسم جهة الإتصال حقل مطلوب",
  contactIndustryError: "الصناعة حقل مطلوب",
  contactSectorError: "القطاع حقل مطلوب",
  contactFieldError: "الحقل مطلوب",
  contactDesignationError: "الوظيفة حقل مطلوب",
  contactDepartmentError: "القسم حقل مطلوب",
  contactPhoneError: "رقم جهة الإتصال حقل مطلوب",
  contactAddressError: "العنوان حقل مطلوب",
  companyNameError: "الشركة حقل مطلوب",
  workEmailError_1: "ايميل العمل حقل مطلوب",
  workEmailError: "البريد الإلكتروني للعمل غير صالح",
  save: "حفظ",
  editContact: "تعديل جهة اتصال",
  markAsPrivate: "تعليم كخاص",
  deleteContact: "حذف اتصال",
  deleteSingleContactAlertMsg:
    "هل أنت متأكد أنك تريد حذف جهة (جهات) الاتصال هذه؟",
  shareContacts: "مشاركة جهة الإتصال مع",
  selectAll: "اختر الكل",
  loadMoreTitle: "تحميل المزيد",
  companyBusinessCard: "الشركة:",
  Sharedwithme: "التى تم مشاركتها معي",
  shareByMe: "تمت مشاركته بواسطتي",
  markAsPublicAlertMessage:
    "هل أنت متأكد أنك تريد وضع علامة على جهة الاتصال كعامة؟",
  edit: "يحرر",
  markAsPrivateAlertMessage:
    "هل أنت متأكد أنك تريد وضع علامة على جهة الاتصال كخاصة؟",
  srNo: "الأب رقم",
  userName: "الإسم",
  mobile: "رقم الموبايل.",
  action: "مطلوب",
  companyName: "اسم الشركة",
  dateTime: "التاريخ و الوقت",
  role: "دور",
  sourceTitle: "مصدر:",
  noContactsFound: "لم يتم العثور على جهات اتصال",
  requestContact: "طلب جهة الإتصال",
  requestContactModal: "طلب جهة الإتصال:",
  requestTo: "الى",
  sendRequest: "رسال الطلب",
  contactNeeded: "الاتصال مطلوب",
  requestToError: "مطلوب طلب إلى الميدان",
  contactNeededError: "حقل الاتصال المطلوب مطلوب",
  PA: "المساعد/ة الشخصي",
  businessCard: "بطاقة العمل",
  contactNumberError_1: "رقم الاتصال غير صالح",
  alternateContactNumberError_1: "رقم جهة اتصال بديل غير صالح",
  editContactNote:
    "ملاحظة - تتم مزامنة جهة الاتصال هذه من الهاتف المحمول، لذا نطلب منك تصحيح قيمة رمز البلد ورقم جهة الاتصال ورقم جهة الاتصال البديل.",
};

import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import HrOfficerForm from "../Components/HrOfficerForm";
import { showToast } from "../../../../common/Utils/ToastMessage";
import { InitialValues } from "../Components/constants";
import { useViewHrOfficerMutation } from "../../../../request/Staff/HrOfficer";
import { Formik } from "formik";

const ViewHrOfficer = () => {
  const { state } = useLocation();
  const formikRef = useRef(null);
  const [filesAdded, setFilesAdded] = useState([]);
  const [images, setImages] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState();

  const [
    getHrOfficerDetails,
    {
      data: hrOfficerData,
      isLoading: isHrOfficerLoading,
      isSuccess: isHrOfficerSuccess,
      isError: isHrOfficerError,
      error: hrOfficerError,
    },
  ] = useViewHrOfficerMutation();

  useEffect(() => {
    if (state) {
      getHrOfficerDetails(state);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getHrOfficerDetails, state]);

  useEffect(() => {
    if (isHrOfficerError && hrOfficerError) {
      showToast({
        message: hrOfficerError?.data?.message,
        type: "error",
      });
    }

    if (isHrOfficerSuccess && hrOfficerData) {
      const data = hrOfficerData?.data[0];

      if (data) {
        formikRef.current.setFieldValue(
          "companyId",
          data?.company?.map((item) => item?._id)
        );
        formikRef.current.setFieldValue("name", data?.name);
        formikRef.current.setFieldValue("staff", data?.role?.type);
        setSelectedStaff(data?.role?.type);
        formikRef.current.setFieldValue("dob", new Date(data?.dob));
        formikRef.current.setFieldValue("designation", data?.designation);
        formikRef.current.setFieldValue("department", data?.department);
        formikRef.current.setFieldValue(
          "mobile",
          `${data?.login?.countryCode}-${data?.login?.mobile}`
        );
        formikRef.current.setFieldValue(
          "mobileShortCode",
          data?.login.mobileShortCode
        );
        formikRef.current.setFieldValue("countryCode", data?.login.countryCode);
        if (data?.alternateMobile) {
          formikRef.current.setFieldValue(
            "alternateMobile",

            `${data?.alternateMobileCountryCode}-${data?.alternateMobile}`
          );
        }
        formikRef.current.setFieldValue(
          "alternateMobileCountryCode",
          data?.alternateMobileCountryCode
            ? data?.alternateMobileCountryCode
            : "962"
        );
        formikRef.current.setFieldValue(
          "alternateMobileShortCode",
          data?.alternateMobileShortCode
        );
        formikRef.current.setFieldValue("companyNumber", data?.companyNumber);
        formikRef.current.setFieldValue(
          "companyExtension",
          data?.companyExtension
        );
        formikRef.current.setFieldValue("email", data?.login.email);
        formikRef.current.setFieldValue("resFlat", data?.residenceAddress.flat);
        formikRef.current.setFieldValue(
          "resState",
          data?.residenceAddress.state
        );
        formikRef.current.setFieldValue(
          "resCountry",
          data?.residenceAddress.country
        );
        formikRef.current.setFieldValue("resCity", data?.residenceAddress.city);
        formikRef.current.setFieldValue(
          "resZipCode",
          data?.residenceAddress.zipCode
        );
        formikRef.current.setFieldValue(
          "resAddress",
          data?.residenceAddress.address
        );
        formikRef.current.setFieldValue(
          "resLat",
          data?.residenceAddress.latlong.latitude
        );
        formikRef.current.setFieldValue(
          "resLng",
          data?.residenceAddress.latlong.longitude
        );
        formikRef.current.setFieldValue("workFlat", data?.workAddress.flat);
        formikRef.current.setFieldValue("workState", data?.workAddress.state);
        formikRef.current.setFieldValue(
          "workCountry",
          data?.workAddress.country
        );
        formikRef.current.setFieldValue("workCity", data?.workAddress.city);
        formikRef.current.setFieldValue(
          "workZipCode",
          data?.workAddress.zipCode
        );
        formikRef.current.setFieldValue(
          "workAddress",
          data?.workAddress.address
        );
        formikRef.current.setFieldValue(
          "workLat",
          data?.workAddress.latlong.latitude
        );
        formikRef.current.setFieldValue(
          "workLng",
          data?.workAddress.latlong.longitude
        );
        formikRef.current.setFieldValue("gender", data?.gender);

        if (data?.profileImageDetails) {
          setFilesAdded([...filesAdded, data?.profileImageDetails]);
          setImages([data?.profileImageDetails?.url]);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hrOfficerData, hrOfficerError]);

  return (
    <Formik initialValues={InitialValues} innerRef={formikRef}>
      {({ values, setFieldValue, handleSubmit, errors }) => {
        return (
          <HrOfficerForm
            isView
            isLoading={isHrOfficerLoading}
            formikRef={formikRef}
            values={values}
            setFieldValue={setFieldValue}
            hrOfficerData={hrOfficerData?.data[0]}
            handleSubmit={handleSubmit}
            setReportTo={() => {}}
            filesAdded={filesAdded}
            setFilesAdded={setFilesAdded}
            images={images}
            setImages={setImages}
            errors={errors}
            selectedStaff={selectedStaff}
            setSelectedStaff={setSelectedStaff}
          />
        );
      }}
    </Formik>
  );
};

export default ViewHrOfficer;

import { Stack } from "@mui/material";
import React from "react";
import { StyledTypography } from "../../../../common/Utils/StyledComponents";
import { TextField } from "../../../TextField/TextField";
import { leaveStatusEnum } from "../../../Profile/LeaveRequest/constants";
import Translate from "../../../../translation/utils/translateFunction";
import { colors } from "../../../../common/Theme/Colors";

export const LeaveDetailCard = ({ leaveData, validations }) => {
  return (
    <Stack className="card p-4">
      <div className="row">
        <StyledTypography className="px-2 mb-3">
          {`${Translate("leaveRequest:leaveDur")}: ${leaveData?.duration} day`}
        </StyledTypography>
      </div>
      <div className="row">
        <div className="form-group col-md-6 col-sm-12">
          <TextField
            value={leaveData?.formattedFromDate}
            label={Translate("leaveRequest:from")}
            placeholder={Translate("leaveRequest:from")}
            autoComplete={"off"}
            maxLength={validations?.designationLength.MAX}
            disabled={true}
            className={"form-control textFieldHeight"}
          />
        </div>
        <div className="form-group col-md-6 col-sm-12">
          <TextField
            value={leaveData?.formattedEndDate}
            label={Translate("leaveRequest:to")}
            placeholder={Translate("leaveRequest:to")}
            autoComplete={"off"}
            maxLength={validations?.designationLength.MAX}
            disabled={true}
            className={"form-control textFieldHeight"}
          />
        </div>
      </div>
      <div className="row">
        <div className="form-group col-md-12 col-sm-12">
          <TextField
            value={leaveData?.leaveReason}
            label={Translate("leaveRequest:leaveReason")}
            placeholder={Translate("addOwner:leaveReason")}
            autoComplete={"off"}
            maxLength={validations?.designationLength.MAX}
            disabled={true}
            className={"form-control textFieldHeight"}
          />
        </div>
      </div>
      {leaveData?.leaveStatus === leaveStatusEnum.disapproved && (
        <div className="row">
          <StyledTypography className="px-2 mb-2" color={colors.red_001}>
            {Translate("leaveRequest:disapproveReason")}
          </StyledTypography>
          <StyledTypography className="px-2 mb-2">
            {leaveData?.disapprovedReason}
          </StyledTypography>
        </div>
      )}
    </Stack>
  );
};

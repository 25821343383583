import React from "react";
import Translate from "../../translation/utils/translateFunction";

const NoDataFound = ({
  message = Translate("common:noDataFoundMessage"),
  isCenter = false,
  className = "NoComment",
  styleProps = {},
}) => {
  return isCenter ? (
    <div className={className}>{message}</div>
  ) : (
    <h4 className="mt-3" style={styleProps}>
      {message}
    </h4>
  );
};

export default NoDataFound;

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { showToast } from "../../common/Utils/ToastMessage";
import { userTypes } from "../../common/users/userTypes";
import { useLoginMutation } from "../../request/Authentication";
import {
  setSelectTimeZoneAction,
  userDataAction,
  userTypeAction,
} from "../../store/Reducer";
import LoginwithEmail from "./components/LoginWithEmail";
import LoginWithOTP from "./components/LoginWithOTP";
import AlertPermission from "../AlertPermission";
import Translate from "../../translation/utils/translateFunction";
import { EncryptData } from "../../common/Utils/EncryptData";

const Login = () => {
  const [isLoginWithOTP, setIsLoginWithOTP] = useState(false);
  const [show, setShow] = useState(false);
  const [requestObj, setRequestObj] = useState();
  const dispatch = useDispatch();

  const onOkClick = () => {
    setShow(false);
    setRequestObj({ ...requestObj, isConsent: "accepted" });
  };
  const onCanelClick = () => setShow(false);

  const [
    loginUser,
    {
      isLoading: isLoadingLoginUser,
      isError: isErrorLoginUser,
      isSuccess: isLoginSuccess,
      error: LoginUserError,
      data: loginUserData,
    },
  ] = useLoginMutation();
  useEffect(() => {
    if (requestObj) {
      loginUser(requestObj);
    }
  }, [requestObj]);

  useEffect(() => {
    if (isLoginSuccess && loginUserData?.success) {
      showToast({ message: loginUserData?.message, type: "success" });
      localStorage.setItem("userData", JSON.stringify(loginUserData?.data));
      dispatch(userDataAction(loginUserData?.data));
      dispatch(
        setSelectTimeZoneAction({
          zoneName: loginUserData?.data?.login?.timezone,
          gmtOffsetName: loginUserData?.data?.login?.utcOffset,
        })
      );
      signInFunction(loginUserData?.data?.role?.type);
    } else if (isLoginSuccess && !loginUserData?.success) {
      showToast({ message: loginUserData?.message, type: "error" });
      loginUserData?.message === "Action required" && setShow(true);
    } else if (isErrorLoginUser) {
      if (LoginUserError?.data?.isPermissionIssue) {
        showToast({ message: LoginUserError?.data?.message, type: "error" });
      } else if (LoginUserError?.data?.error?.length) {
        LoginUserError.data.error.forEach((errorItem) => {
          showToast({ message: errorItem?.msg, type: "error" });
        });
      } else {
        showToast({ message: JSON.stringify(LoginUserError?.error || LoginUserError), type: "error" });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    LoginUserError,
    dispatch,
    isErrorLoginUser,
    isLoginSuccess,
    loginUserData,
    loginUserData?.message,
  ]);
  const signInFunction = async (value) => {
    dispatch(userTypeAction(undefined));
    if (value === userTypes.Owner.toUpperCase()) {
      dispatch(userTypeAction(userTypes.Owner));
    } else if (value === userTypes.Manager.toUpperCase()) {
      dispatch(userTypeAction(userTypes.Manager));
    } else if (value === userTypes.GeneralManager.toUpperCase()) {
      dispatch(userTypeAction(userTypes.GeneralManager));
    } else if (value === userTypes.persoalAssistant.toUpperCase()) {
      dispatch(userTypeAction(userTypes.persoalAssistant));
    } else if (value === userTypes.Employee.toUpperCase()) {
      dispatch(userTypeAction(userTypes.Employee));
    } else if (value === userTypes.Vendor.toUpperCase()) {
      dispatch(userTypeAction(userTypes.Vendor));
    } else {
      dispatch(userTypeAction(userTypes.Vendor));
    }
  };

  return (
    <>
      {isLoginWithOTP ? (
        <LoginWithOTP
          loginUser={setRequestObj}
          isLoading={isLoadingLoginUser}
          setIsLoginWithOTP={setIsLoginWithOTP}
          consent={"pending"}
        />
      ) : (
        <LoginwithEmail
          setLoginWithOTP={setIsLoginWithOTP}
          loginUser={setRequestObj}
          resetForm={loginUserData?.success}
          isLoading={isLoadingLoginUser}
          consent={"pending"}
        />
      )}
      <AlertPermission
        show={show}
        okTitle={Translate("common:continue")}
        title={Translate("login:consentTitle")}
        subTitle={`${Translate("login:consentDesc1")} ${
          loginUserData?.data
        }${Translate("login:consentDesc2")}`}
        onOk={onOkClick}
        onCanel={onCanelClick}
      />
    </>
  );
};

export default Login;

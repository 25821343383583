import React, { memo, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import NoDataFound from "../../components/ManageTask/NoDataFound";
import { useLazyGetCompanyListQuery } from "../../request/company";
import Translate from "../../translation/utils/translateFunction";

const CompanyFilter = ({
  handleModal,
  showModal,
  selectedCompanyList = [],
  moduleType,
  setCompanyList,
  isSingleSelect = false,
  onSelect,
}) => {
  const [allCompanyData, setAllCompanyData] = useState([]);
  const [checkedAll, setCheckedAll] = useState(false);
  const [checkedIdList, setCheckedIdList] = useState([...selectedCompanyList]);

  useEffect(() => {
    setCheckedIdList([...selectedCompanyList]);
  }, [selectedCompanyList]);

  const handleClose = (clearAll) => {
    filter("");
    setCheckedIdList([...selectedCompanyList]);
    handleModal && handleModal(false);
  };

  const [getCompanyList, { data: companyResponse }] =
    useLazyGetCompanyListQuery();

  useEffect(() => {
    getCompanyList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (allCompanyData?.length === checkedIdList?.length) {
      setCheckedAll(true);
    } else {
      setCheckedAll(false);
    }
  }, [checkedIdList, allCompanyData]);

  // ******************************** Company-Listing*********************************************
  useEffect(() => {
    if (companyResponse) {
      setCompanyResponse();
      companyResponse.length === selectedCompanyList.length &&
        setCheckedAll(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyResponse]);

  const setCompanyResponse = (
    companyResponseTemp = [],
    isFromSearch = false
  ) => {
    let companyData = [];
    isFromSearch
      ? companyResponseTemp.length > 0 &&
        companyResponseTemp.map((item) =>
          companyData.push({
            label: item.name,
            value: `${item._id}`,
          })
        )
      : companyResponse.map((item) =>
          companyData.push({
            label: item.name,
            value: `${item._id}`,
          })
        );
    setAllCompanyData(companyData);

    if (moduleType === "VENDOR") setCompanyList(companyData);
  };

  const filter = (searcValue) => {
    if (searcValue === "") setCompanyResponse();
    else {
      const currentFilterData = companyResponse.filter((item) =>
        item.name.toLowerCase().includes(searcValue.toLowerCase())
      );
      setCompanyResponse(currentFilterData, true);
    }
  };

  // *************************************SelectAll Checkbox***********************************
  const selectAll = (value) => {
    setCheckedAll(value);
    if (value) {
      companyResponse.map((item) => {
        const isPresent = checkedIdList.find((data) => data.value === item._id);

        (checkedIdList.length <= 0 || !isPresent) &&
          checkedIdList.push({
            label: item.name,
            value: item._id,
          });
      });
      setCheckedIdList([...checkedIdList]);
    } else
      setCheckedIdList(
        companyResponse?.length
          ? [
              {
                label: companyResponse[0]?.name,
                value: companyResponse[0]?._id,
              },
            ]
          : []
      );
  };

  const Checkbox = ({ item, onChangeInput, isChecked }) => {
    return (
      <>
        <div
          onClick={() => onChangeInput(item)}
          style={{ display: "inline-block" }}>
          <input
            type="checkbox"
            checked={isChecked}
            name={item.label}
            id={item.value}
            onChange={() => {}}
          />
          <label className="form-check-label">{item.label}</label>
        </div>
      </>
    );
  };

  const onChange = (item) => {
    const isCheckedId =
      checkedIdList.length > 0
        ? checkedIdList.findIndex((idItem) => idItem.value === item.value)
        : -1;
    isCheckedId === -1
      ? checkedIdList.push(item)
      : checkedIdList.splice(isCheckedId, 1);

    setCheckedIdList([...checkedIdList]);
  };

  return (
    <>
      <Modal
        show={showModal}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        scrollable={true}
        className="col-span-3 col-md-12">
        <Modal.Header closeButton>
          <Modal.Title> {Translate("selectCompany:head")}</Modal.Title>
        </Modal.Header>
        <div style={{ marginInline: "5%", marginTop: "3%" }}>
          <input
            type="text"
            className="form-control"
            placeholder={Translate("common:search")}
            name="s"
            onChange={(e) => {
              filter(e.target.value);
            }}
            autoComplete="off"
          />
        </div>
        <Modal.Body
          style={{
            minHeight: "calc(100vh - 400px)",
            maxHeight: "calc(100vh - 400px)",
          }}>
          {allCompanyData?.length > 1 && !isSingleSelect && (
            <div
              className="modalStyling"
              // onClick={() => selectAll(!checkedAll)}
            >
              <input
                type="checkbox"
                onChange={() => selectAll(!checkedAll)}
                checked={checkedAll}
                style={{ marginRight: "0.5rem" }}
              />

              <label onClick={() => selectAll(!checkedAll)}>
                {Translate("selectCompany:selectAll")}
              </label>
            </div>
          )}
          <div
            className="table-responsive todo_list over-flow-md scrollBox"
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}>
            <table className="table table-hover table-striped table-vcenter mb-0">
              <tbody>
                {allCompanyData.length > 0 ? (
                  allCompanyData.map((item, index) => {
                    const isAvailable =
                      checkedIdList.length > 0 &&
                      checkedIdList.find(
                        (idItem) => idItem.value === item.value
                      );
                    const isChecked = isAvailable || false;
                    return (
                      <tr key={`${index}_${item.value}`}>
                        <td key={index} className="tdStyling">
                          <Checkbox
                            item={item}
                            onChangeInput={onChange}
                            isChecked={isChecked}
                          />
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <div className="NoDataDisplayStyling mt-4">
                    <tr>
                      <td className="displayNodata tdStyling">
                        <span>
                          <NoDataFound
                            message={Translate("manageTask:noCompany")}
                          />
                        </span>
                      </td>
                    </tr>
                  </div>
                )}
              </tbody>
            </table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="btn btn-white addButton"
            onClick={() => {
              handleClose(true);
            }}>
            {Translate("common:close")}
          </Button>
          <Button
            variant={`primary saveButton`}
            // checkedIdList.length > 0 ? "" : "disabled"
            onClick={(event) => {
              if (checkedIdList.length > 0) {
                handleClose(false);
                onSelect([...checkedIdList], allCompanyData.length);
              }
            }}>
            {Translate("common:save")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default memo(CompanyFilter);

import * as yup from "yup";
import Translate from "../../../translation/utils/translateFunction";
import { validateMobileSchema } from "../../../common/Utils/commonFunction";

const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const AddContactSchema = () => {
  const schema = yup.object().shape({
    contactName: yup
      .string()
      .trim()
      .required(Translate("contacts:contactNameError")),
    contactIndustry: yup
      .string()
      .trim()
      .required(Translate("contacts:contactIndustryError")),
    contactSector: yup
      .string()
      .trim()
      .required(Translate("contacts:contactSectorError")),
    contactField: yup
      .string()
      .trim()
      .required(Translate("contacts:contactFieldError")),
    contactDesignation: yup
      .string()
      .trim()
      .required(Translate("contacts:contactDesignationError")),
    contactDepartment: yup
      .string()
      .trim()
      .required(Translate("contacts:contactDepartmentError")),
    contactAddress: yup
      .string()
      .trim()
      .required(Translate("contacts:contactAddressError")),
    companyName: yup
      .string()
      .trim()
      .required(Translate("contacts:companyNameError")),
    workEmail: yup
      .string()
      .matches(emailRegex, Translate("contacts:workEmailError"))
      .required(Translate("contacts:workEmailError_1")),
    ...validateMobileSchema(
      "contactPhone",
      Translate("contacts:contactPhoneError")
    ),
  });

  return schema;
};

export const RequestContactSchema = () => {
  const schema = yup.object().shape({
    requestTo: yup.string().required(Translate("contacts:requestToError")),
    contactNeeded: yup
      .string()
      .required(Translate("contacts:contactNeededError")),
  });
  return schema;
};

import Translate from "../../../../translation/utils/translateFunction";
import { CustomTitleCellRender } from "../../../common/CustomCellRender";

export const getHrOfficerColumns = (
  CustomActionCellRender,
  CustomSrCellRender
) => {
  const columns = [
    {
      field: "lineNo",
      headerName: Translate("addPersonalAssistant:srNoTableHeading"),
      align: "center",
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      renderCell: CustomSrCellRender,
    },
    {
      field: "name",
      headerName: Translate("addPersonalAssistant:nameTableHeading"),
      flex: 0.5,
      sortable: true,
      disableColumnMenu: true,
      renderCell: (params) => CustomTitleCellRender(params?.row?.name),
    },
    {
      field: "companyNumber",
      headerName: Translate("addPersonalAssistant:mobileTableHeading"),
      flex: 0.5,
      sortable: true,
      disableColumnMenu: true,
      valueGetter: (params) => params.row?.companyNumber || params.row?.mobile,
    },
    {
      field: "companyName",
      headerName: Translate("addPersonalAssistant:companyNameTableHeading"),
      flex: 0.6,
      sortable: true,
      disableColumnMenu: true,
      renderCell: (params) => CustomTitleCellRender(params?.row?.companyName),
    },
    {
      field: "email",
      headerName: Translate("addPersonalAssistant:emailTableHeading"),
      flex: 0.8,
      sortable: true,
      disableColumnMenu: true,
      renderCell: (params) => CustomTitleCellRender(params?.row?.email),
    },
    {
      field: "Action",
      headerName: Translate("addPersonalAssistant:actionTableHeading"),
      flex: 0.3,
      sortable: false,
      disableColumnMenu: true,
      hide: true,
      renderCell: CustomActionCellRender,
    },
  ];
  return columns;
};

export default {
  head: "Assign task",
  title: "Title",
  add: "Add",
  taskName: "Task name",
  description: "Description",
  descriptionPlaceholder: "Body or description of email",
  assignTo: "Assign to",
  startDate: "Start date",
  dueDate: "Due Date",
  attachFile: "Attach file",
  assignDropdownPlaceholder: "Name or email of user",
  companyDropdownPlaceholder: "Select company",
  dropdownPlaceholder: "Select item",
  companyError: "Company is required",
  titleError: "Title is required",
  nameError: "Name is required",
  addError: "Add task/subtask is required",
  descriptionError: "Description is required",
  assignToError: "Assign to is required",
  startDateError: "Start date is required",
  dueDateError: "Due date is required",
  priorityError: "Priority is required",
  reportToError: "Report to is required",
};

import React from "react";
import { colors } from "../../../../common/Theme/Colors";
import { StyledTypography } from "../../../../common/Utils/StyledComponents";
import Translate from "../../../../translation/utils/translateFunction";

export const getContactRepoAccessLogsColumn = (CustomCellRender) => {
  const columns = [
    {
      field: "lineNo",
      headerName: Translate("contacts:srNo"),
      sortable: false,
      disableColumnMenu: true,
      selectableRows: false,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <StyledTypography component="span" sx={{ color: colors.primary_009 }}>
          {params?.api?.getRowIndexRelativeToVisibleRows(params?.row?._id) + 1}
        </StyledTypography>
      ),
    },
    {
      field: "name",
      headerName: Translate("contacts:userName"),
      flex: 0.5,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "designation",
      headerName: Translate("contacts:designation"),
      flex: 0.5,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "company",
      headerName: Translate("contacts:companyName"),
      flex: 0.5,
      sortable: false,
      disableColumnMenu: true,
    },

    {
      field: "date",
      headerName: Translate("contacts:dateTime"),
      flex: 0.4,
      sortable: false,
      disableColumnMenu: true,

      renderCell: CustomCellRender,
    },
  ];
  return columns;
};

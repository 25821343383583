export default {
  head: "التقويم",
  createEvent: "انشاء حدث",
  viewCalendar: "عرض التقويم",
  today: "اليوم",
  events: "الأحداث",
  inviteMembers: "دعوة أعضاء",
  invitationSent: "تم ارسال الدعوة",
  selectMember: "اختر عضو",
  managers: "المدراء",
  employees: "الموظفين",
  createEvents: "انشاء أحداث",
  reminder: "تذكير",
  view: "عرض",
  invite: "يدعو",
  day: "يوم",
  month: "شهر",
  week: "أسبوع",
  today: "اليوم",
  year: "سنة",
  reminderMinute: "قبل دقائق",
  reminderHour: "قبل ساعات",
  reminderDay: "قبل يوم",
  reminderWeek: "قبل أسبوع",
  reminderNotification: "كإخطار",
  reminderMail: "كبريد",
};

export default {
  head: "Task report",
  assigned: "Assigned",
  complete: "Completed",
  inProgress: "In progress",
  resolved: "Resolved",
  reOpened: "Reopened",
  overdue: "Overdue",
  completedBefore: "Completed (Before)",
  completedAfter: "Completed (After)",
  priorityPlaceHolder: "Select priority",
  sortByPlaceHolder: "Select sort by",
  staffPlaceHolder: "Select staff",
  sortByLabel: "Sort by",
  staffLabel: "Staff",
  priorityLabel: "Priority",
  graphDataNotFound: "No data available for the chart.",
  exportTasKFileName: "FOM_TASK_REPORT",
  donutDataNotFound: "No data available for the graph.",
  exportAttendanceFileName: "FOM_ATTENDANCE_REPORT",
  exportPerformanceFileName: "FOM_PERFORMANCE_REPORT",
  exportWorkdloadFileName: "FOM_WORKLOAD_REPORT",
  recentTask: "Recent task",
  oldTask: "Old task",
  exportAttendanceReportDetails: "FOM_ATTENDANCE_REPORT_DETAILS",
};

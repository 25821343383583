export default {
  head: "Calendar",
  createEvent: "Create event",
  viewCalendar: "View calendar",
  today: "Today",
  events: "Events",
  inviteMembers: "Invite members",
  invitationSent: "Invitation Sent",
  selectMember: "Select member",
  managers: "Managers",
  employees: "Employees",
  createEvents: "Create events",
  reminder: "Reminder",
  view: "View",
  invite: "Invite",
  day: "Day",
  month: "Month",
  week: "Week",
  today: "Today",
  year: "Year",
  reminderMinute: "Minutes before",
  reminderHour: "Hours before",
  reminderDay: "Days before ",
  reminderWeek: "Week before ",
  reminderNotification: "As notification",
  reminderMail: "As mail",
};

import { toast } from "react-toastify";

export const showToast = ({ message = "", type = "success" }) => {
  const isRtl = localStorage.getItem("isRtl");
  const Position =
    isRtl === "true" ? toast.POSITION.TOP_LEFT : toast.POSITION.TOP_RIGHT;

  switch (type) {
    case "success":
      toast.success(message, {
        position: Position,
      });
      break;
    case "error":
      toast.error(message, {
        position: Position,
      });
      break;
    case "info":
      toast.info(message, {
        position: Position,
      });
      break;
    default:
      toast.success(message, {
        position: Position,
      });
      break;
  }
};

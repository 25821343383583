import React from "react";
import Translate from "../../../translation/utils/translateFunction";
import { CustomUserTitleCellRender } from "../../common/CustomCellRender";

export const getContactRepoColumns = (customActionCellRender) => {
  const columns = [
    {
      field: "serialNumber",
      headerName: Translate("contacts:srNo"),
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "contactName",
      flex: 0.5,
      headerName: Translate("contacts:userName"),
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => CustomUserTitleCellRender(params),
    },
    {
      field: "contactPhone",
      headerName: Translate("contacts:mobile"),
      flex: 0.4,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <div>
          <div className="text-info">
            {`${params?.row?.contactPhoneCountryCode || ""}${
              params?.row?.contactPhone
            }`}
          </div>
        </div>
      ),
    },
    {
      field: "Action",
      headerName: Translate("contacts:action"),
      flex: 0.4,
      sortable: false,
      disableColumnMenu: true,
      renderCell: customActionCellRender,
    },
  ];
  return columns;
};

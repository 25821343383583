import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as BrandLogo } from "../../../assets/images/FoM_logo.svg";
import Translate from "../../../translation/utils/translateFunction";
import { FormikTextField } from "../../FormikForm/component/FormikTextField";

import Fro1 from "../../../assets/images/Illustration_1.svg";
import Fro2 from "../../../assets/images/Illustration_2.svg";
import Fro3 from "../../../assets/images/Illustration_3.svg";
import Loader from "../../staff/Owner/LoadingComp";
import {
  ForgotPasswordInitialValues,
  SetNewPasswordInitialValues,
} from "../constants";
import { ForgotPasswordSchema, NewPasswordSchema } from "../utils";
import VerifyOTP from "./VerifyOtp";
import { useSetNewPasswordMutation } from "../../../request/Authentication";
import { showToast } from "../../../common/Utils/ToastMessage";
import { StyledTypography } from "../../../common/Utils/StyledComponents";
import { Stack } from "react-bootstrap";
import { colors } from "../../../common/Theme/Colors";

const SetNewPassword = ({
  setLoginWithOTP,
  loginUser,
  resetForm,
  isLoading,
  goBack,
  email,
  setVerifyEmail,
}) => {
  const navigate = useNavigate();
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const formikRef = useRef(null);

  const [
    setNewPasswordApi,
    {
      isLoading: isSetNewPasswordLoading,
      isError: isErrorSetNewPassword,
      isSuccess: isSuccessSetNewPassword,
      error: errorSetNewPassword,
      data: setNewPassword,
      // isFetching,
    },
  ] = useSetNewPasswordMutation();

  useEffect(() => {
    if (isSuccessSetNewPassword && setNewPassword?.success) {
      showToast({ message: setNewPassword?.message, type: "success" });
      navigate("/");
    } else if (isSuccessSetNewPassword && !setNewPassword?.success) {
      showToast({ message: setNewPassword?.message, type: "info" });
    } else if (isErrorSetNewPassword) {
      showToast({
        message: JSON.stringify(errorSetNewPassword),
        type: "error",
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessSetNewPassword, setNewPassword, errorSetNewPassword]);

  useEffect(() => {
    if (resetForm) {
      formikRef.current.resetForm();
    }
  }, [resetForm]);
  const onSubmit = (companyFormValues) => {
    let requestObject = {
      email: email,
      newPassword: companyFormValues?.newPass,
      confirmPassword: companyFormValues?.confirmPass,
    };

    setNewPasswordApi(requestObject);
  };
  const onGoBack = () => {
    setVerifyEmail(false);
    goBack(false);
  };
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = "";
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);
  return (
    <Formik
      initialValues={SetNewPasswordInitialValues}
      innerRef={formikRef}
      validateOnMount
      onSubmit={onSubmit}
      validationSchema={NewPasswordSchema}
      validateOnChange
    >
      {({ handleSubmit }) => {
        return (
          <div className="auth">
            <div className="auth_left">
              <div className="card" style={{ zIndex: 999 }}>
                <div className="text-center mb-30">
                  <Link className="header-brand" to="/">
                    <BrandLogo className="forLogo"></BrandLogo>
                  </Link>
                </div>

                <div className="card-body">
                  <div className="form-group col-md-12 ">
                    <FormikTextField
                      name="newPass"
                      label={Translate("forgotPassword:newPassword")}
                      placeholder={Translate("changePassword:newPass")}
                      autoComplete={"off"}
                      type={showNewPassword ? "text" : "password"}
                      RenderRightContainer={
                        showNewPassword ? (
                          <i
                            className="fe fe-eye-off cursor-pointer passwordIcon-alignement"
                            onClick={() => setShowNewPassword(false)}
                          />
                        ) : (
                          <i
                            className="fe fe-eye cursor-pointer passwordIcon-alignement"
                            onClick={() => setShowNewPassword(true)}
                          />
                        )
                      }
                      showPassword={showNewPassword}
                    />
                  </div>
                  <div className="form-group col-md-12 ">
                    <FormikTextField
                      name="confirmPass"
                      label={Translate("forgotPassword:confirmPassword")}
                      placeholder={Translate("changePassword:confirmPass")}
                      autoComplete={"off"}
                      type={showConfirmPassword ? "text" : "password"}
                      RenderRightContainer={
                        showConfirmPassword ? (
                          <i
                            className="fe fe-eye-off cursor-pointer passwordIcon-alignement"
                            onClick={() => setShowConfirmPassword(false)}
                          />
                        ) : (
                          <i
                            className="fe fe-eye cursor-pointer passwordIcon-alignement"
                            onClick={() => setShowConfirmPassword(true)}
                          />
                        )
                      }
                      showPassword={showConfirmPassword}
                    />
                  </div>

                  <div className="form-footer">
                    <button
                      type="submit"
                      className="btn btn-primary btn-block"
                      onClick={() => !isLoading && handleSubmit()}
                    >
                      {isLoading ? (
                        <Loader loading={isLoading} isListLoader message={""} />
                      ) : (
                        Translate("common:save")
                      )}
                    </button>
                    <StyledTypography
                      mt={"30px"}
                      fontWeight={"500"}
                      onClick={onGoBack}
                      className="text-center text-primary cursor-pointer"
                    >
                      {Translate("forgotPassword:backToForgotPassword")}
                    </StyledTypography>

                    <Stack style={{ marginTop: "1.5rem" }}>
                      <StyledTypography variant="subtitle2" className="display">
                        {Translate("login:termsAndConditions5")}
                      </StyledTypography>
                      <Stack flexDirection={"row"}>
                        <StyledTypography
                          className="cursor-pointer display"
                          variant="subtitle2"
                          sx={{ color: colors.primary_010 }}
                          // onClick={() => navigate("/termsConditions")}
                          onClick={() => navigate("/termsOfServices")}
                        >
                          {Translate("login:termsAndConditions2")}
                        </StyledTypography>
                        <StyledTypography
                          variant="subtitle2"
                          mx={"4px"}
                          className="display"
                        >
                          {Translate("login:termsAndConditions3")}
                        </StyledTypography>
                        <StyledTypography
                          className="cursor-pointer display"
                          variant="subtitle2"
                          sx={{ color: colors.primary_010 }}
                          // onClick={() => navigate("/privacy")}
                          onClick={() => navigate("/privacyPolicy")}
                        >
                          {Translate("login:termsAndConditions4")}
                        </StyledTypography>
                      </Stack>
                    </Stack>
                  </div>
                </div>
              </div>
            </div>
            <div className="auth_right">
              <div
                className="carousel slide"
                data-ride="carousel"
                data-interval={3000}
              >
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <img src={Fro1} className="img-fluid" alt="login page" />

                    <div className="px-4 mt-4">
                      {/* <h4>Fully Responsive</h4>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit.
                      </p> */}
                    </div>
                  </div>
                  <div className="carousel-item">
                    <img src={Fro2} className="img-fluid" alt="login page" />
                    <div className="px-4 mt-4">
                      {/* <h4>Quality Code and Easy Customizability</h4>
                      <p>
                        There are many variations of passages of Lorem Ipsum
                        available.
                      </p> */}
                    </div>
                  </div>
                  <div className="carousel-item">
                    <img src={Fro3} className="img-fluid" alt="login page" />
                    <div className="px-4 mt-4">
                      {/* <h4>Cross Browser Compatibility</h4>
                      <p>
                        Overview We're a group of women who want to learn
                        JavaScript.
                      </p> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }}
    </Formik>
  );
};
export default SetNewPassword;

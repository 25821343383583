import React, { useRef, useState, useEffect } from "react";
import { InitialValues } from "../Components/constants";
import { useLocation } from "react-router-dom";
import RenewalForm from "../Components/RenewalForm";
import { useGetRenewalDetailsMutation } from "../../../request/Renewals";
import { showToast } from "../../../common/Utils/ToastMessage";
import { Formik } from "formik";

const ViewRenewals = () => {
  const formikRef = useRef(false);
  const { state } = useLocation();

  const [filesAdded, setFilesAdded] = useState([]);
  const [images, setImages] = useState([]);

  const [
    viewRenewalApi,
    {
      data: renewalData,
      isLoading: isRenewalLoading,
      isSuccess: isRenewalSuccess,
      isError: isRenewalError,
      error: renewalError,
    },
  ] = useGetRenewalDetailsMutation();

  useEffect(() => {
    if (state?.renewalId) {
      viewRenewalApi(state?.renewalId);
    }
  }, [viewRenewalApi, state]);

  useEffect(() => {
    if (isRenewalError && renewalError) {
      showToast({
        message: renewalError?.data?.message,
        type: "error",
      });
    }

    if (isRenewalSuccess && renewalData) {
      const { data } = renewalData;

      InitialValues.companyId = data?.companyId?._id;
      formikRef.current.setFieldValue("companyId", data?.companyId?._id);
      formikRef.current.setFieldValue("name", data?.name);
      formikRef.current.setFieldValue(
        "documentCategory",
        data?.documentCategory
      );
      formikRef.current.setFieldValue("title", data?.title);
      formikRef.current.setFieldValue("isNotExpiry", data?.isNotExpiry);
      InitialValues.isNotExpiry = data?.isNotExpiry;
      formikRef.current.setFieldValue(
        "registrationDate",
        new Date(data?.registrationDate)
      );
      if (!data?.isNotExpiry) {
        formikRef.current.setFieldValue(
          "expiryDate",
          new Date(data?.expiryDate)
        );
      }

      if (data?.attachment[0]?.url) {
        const { url, type, renewalsFileName, renewalsFileExt } = data?.attachment[0];

        let fileType = type;

        if (!type.includes("/") && renewalsFileExt === "pdf") {
          fileType = `${type}/${renewalsFileExt}`;
        }

        const obj = {
          path: decodeURIComponent(url).split("/").pop(),
          type: fileType,
          url,
          name: renewalsFileName,
        };
        setFilesAdded([...filesAdded, obj]);
        setImages([url]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [renewalData, renewalError]);

  return (
    <Formik initialValues={InitialValues} innerRef={formikRef}>
      {({ values, setFieldValue }) => {
        return (
          <RenewalForm
            isView
            formikRef={formikRef}
            values={values}
            setFieldValue={setFieldValue}
            isLoading={isRenewalLoading}
            images={images}
            setImages={setImages}
            filesAdded={filesAdded}
            setFilesAdded={setFilesAdded}
            renewalData={renewalData?.data}
          />
        );
      }}
    </Formik>
  );
};

export default ViewRenewals;

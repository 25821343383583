import Translate from "../../../translation/utils/translateFunction";

export const InitialValues = {
  companyId: "",
  title: "",
  description: "",
};

export const sortingOptions = [
  {
    label: Translate("renewals:default"),
    value: "default",
  },
  {
    label: Translate("renewals:atoz"),
    value: "AtoZ",
  },
];

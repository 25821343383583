import { Methods, Urls } from "../../common/Utils/ApiConstants";
import { CreateApi } from "../CreateApi";

const HeaderCollection = CreateApi.injectEndpoints({
  endpoints: (builder) => ({
    trackChangeLanguage: builder.mutation({
      query: (body) => {
        return {
          url: Urls.trackChangeLanguage,
          method: Methods.put,
          body,
        };
      },
    }),
  }),
});

export const { useTrackChangeLanguageMutation } = HeaderCollection;

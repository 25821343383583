import Translate from "../../../../../translation/utils/translateFunction";

export const getLeaveRequestColumns = (
  CustomSrCellRender,
  CustomActionCellRender
) => {
  const columns = [
    {
      field: "lineNo",
      headerName: Translate("common:srNoTitle"),
      align: "center",
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      renderCell: CustomSrCellRender,
    },
    {
      field: "userName",
      headerName: Translate("activityLog:userTitle"),
      flex: 0.5,
      sortable: false,
      disableColumnMenu: true,
      valueGetter: (params) => params.row?.userName,
    },
    {
      field: "date",
      headerName: Translate("reports:appliedFor"),
      flex: 0.5,
      sortable: false,
      disableColumnMenu: true,
      valueGetter: (params) => params.row?.date,
    },
    {
      field: "action",
      headerName: Translate("common:actionTitle"),
      align: "center",
      headerAlign: "center",
      flex: 0.7,
      sortable: false,
      disableColumnMenu: true,
      renderCell: CustomActionCellRender,
    },
  ];

  return columns;
};
